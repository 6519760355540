<template>
  <div class="d-flex flex-column h-100">
    <div class="page-header">
      <div class="d-flex flex-column flex-fill">
        <div
          class="page-title d-sm-flex justify-content-between align-items-center"
        >
          <div class="d-flex justify-content-between justify-content-sm-start">
            <h1 class="mr-3">Shared Inboxes</h1>
            <button
              class="btn btn-primary"
              @click="togglePopover()"
              :id="`new-group`"
              :disabled="isLegacy ? isDisabledLegacy : isDisabled"
              v-show="registrationStep >= 2"
            >
              New <span class="d-none d-sm-inline">Shared&nbsp;</span>Inbox
            </button>
          </div>
          <div class="d-sm-flex flex-column align-items-end">
            <QuickTipButton class="quick-tip" :tipId="`-Lo2ANQFGvMvUZoLBqDr`" />
            <ServerSearch
              searchIconClass="search"
              cancelIconClass="times"
              placeholder="Search by Name"
              elementKey="inbox-search"
              class="search-bar"
              type="search"
              :searchText="searchTerm_"
              @search="handleSearch"
            />
          </div>
        </div>
        <div>
          <p v-if="!isLegacy" class="inbox-count">
            {{ inboxesTotal }} of {{ groupAccountLimit }} shared inboxes
          </p>
          <p v-if="isLegacy" class="user-count">
            {{ contract.accountsCount }} of {{ contract.accountLimit }} users
          </p>
          <BFormCheckbox
            v-if="isLegacy"
            v-model="showShared"
            v-on:change="toggleShowShared"
            aria-label="Show Shared Inboxes in all Teams"
            switch
            class="show-all-shared"
            >Show Shared Inboxes in all Teams</BFormCheckbox
          >
        </div>
        <div>
          <SharedNewModal
            :sharedInboxName="newInboxName_"
            :id="inboxId"
            confirmButtonText="Create"
            :saving="saving_"
            :show="showNewInboxModal_"
            :errorMessage="newErrorMessage_"
            @new-inbox-confirmed="handleNewInboxConfirmed"
            @cancelled="cancelModals()"
            :showAddUsersStep="registrationStep > 2"
          />
          <SharedEditModal
            :sharedInboxName="editInboxName_"
            :sharedInboxApi="editInboxApi_"
            :sharedInboxColor="editInboxColor_"
            :sharedInboxTeamId="editInboxTeamId_"
            :forwardingNumber="editInboxForwardingNumber_"
            :id="inboxId"
            confirmButtonText="Update"
            :saving="saving_"
            :show="showEditInboxModal_"
            :errorMessage="editErrorMessage_"
            :listItems="filterData.inboxes"
            :checkedItems="groupAccounts"
            @update="handleEditInboxConfirmed"
            @cancelled="cancelModals()"
            :showAddUsersStep="registrationStep > 2"
          />
          <SharedDeleteModal
            :sharedInboxName="deleteInboxName_"
            :id="inboxId"
            confirmButtonText="Delete"
            :saving="saving_"
            :show="showDeleteInboxModal_"
            :errorMessage="deleteErrorMessage_"
            @confirmed="handleDeleteInboxConfirmed"
            @cancelled="cancelModals()"
          />
        </div>
      </div>
    </div>

    <div class="page-content flex-grow-1 overflow-auto">
      <div class="card mb-5 col-lg-8 col-md-12" v-if="registrationStep < 4">
        <h2>
          <MgIcon name="shield-tick" scale="2" class="pr-2" />A2P Campaign
          Registration
        </h2>
        <ol class="progress-steps-line">
          <li class="active"><span>Create your Shared Inbox</span></li>
          <li
            :class="[
              registrationStep >= 2 ? 'active' : '',
              registrationStep >= 2 && hasRegistrationDates ? 'two-line' : '',
            ]"
          >
            <span class="top-text text-wrap"
              >We register you with carriers</span
            >
            <span
              class="bottom-text text-wrap"
              v-if="registrationStep >= 2 && hasRegistrationDates"
            >
              {{
                registrationStep === 2
                  ? `Began: ${formatA2pDate()}`
                  : `Complete: ${formatA2pDate()}`
              }}
            </span>
          </li>
          <li :class="registrationStep >= 3 ? 'active' : ''">
            <span>Invite your team</span>
          </li>
          <li :class="registrationStep >= 4 ? 'active' : ''">
            <span>Happy texting!</span>
          </li>
        </ol>
        <p class="m-0 mt-n3">
          A2P (application-to-person) campaign registration is required by
          carriers to help combat fraud. By registering your institution's
          messaging use cases, we monitor and prevent potential filtering and
          blocking.
          <br />
          <br />
          Creating your first shared inbox will start the team's registration
          process with a third-party. This process should take 7 days but may
          take 2-3 weeks. You will be notified once registration is complete and
          your team will be ready to text!
        </p>
        <div v-show="registrationStep < 2">
          <BButton class="mr-4" variant="primary" @click="togglePopover"
            >Get Started</BButton
          >
          <a
            href="//help.mongooseresearch.com/article/587-shared-inboxes-a2p-campaign-registration"
            target="_blank"
            rel="noopener"
            >Learn More</a
          >
        </div>
      </div>
      <div class="col-lg-4 col-md-0" />
      <SharedInboxList
        v-if="!this.loading_ && registrationStep >= 2"
        :groupAccounts="groupAccounts"
        :filterBySearchTerm="searchTerm_"
      />
      <SharedInboxEmptyState
        v-if="!this.loading_ && inboxesTotal === 0 && registrationStep > 1"
        :searchActive="searchActive_"
        @createInbox="togglePopover()"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import SharedInboxList from "./SharedInboxList";
import SharedInboxEmptyState from "./SharedInboxEmptyState";
import { FormSelectPlugin, BFormCheckbox } from "bootstrap-vue";
import { ACTIONS, GETTERS } from "@/util/constants";
import store from "@/store";
import QuickTipButton from "@/components/QuickTipButton";
import SharedNewModal from "./SharedNewModal";
import SharedEditModal from "./SharedEditModal";
import SharedDeleteModal from "./SharedDeleteModal";
import api from "@/util/api";
import eventBus from "@/util/eventBus";
import Toast from "@/components/Toast";
import _ from "lodash";
import analytics from "@/util/analytics";
import ServerSearch from "@/components/ServerSearch";
import MgIcon from "@/components/MgIcon";
import getProfile from "@/util/profile";
import { formatDate } from "../../util/dates";

Vue.use(FormSelectPlugin);

export default {
  name: "SharedInboxPage",
  store,

  components: {
    SharedInboxList,
    SharedInboxEmptyState,
    QuickTipButton,
    SharedNewModal,
    SharedDeleteModal,
    SharedEditModal,
    ServerSearch,
    BFormCheckbox,
    MgIcon,
  },
  data() {
    return {
      initialLoad: true,
      showNewInboxModal_: false,
      showEditInboxModal_: false,
      showDeleteInboxModal_: false,
      deleteErrorMessage_: [],
      editErrorMessage_: [],
      newErrorMessage_: [],
      deleteInboxName_: "",
      editInboxName_: "",
      editInboxApi_: "",
      editInboxColor_: "",
      editInboxTeamId_: 0,
      editInboxForwardingNumber_: "",
      newInboxName_: "",
      inboxId: null,
      saving_: false,
      loading_: false,
      checkedItems: [],
      searchActive_: false,
      searchTerm_: "",
      showShared: false,
      errorDescription:
        "Text messaging for Shared Inboxes will be available when the SMS campaign registration with carriers is complete.",
      errorLink: "https://help.mongooseresearch.com/",
    };
  },
  created() {
    eventBus.addEventListener("delete-confirm", this.showDeleteModal);
    eventBus.addEventListener("edit-inbox", this.showEditModal);
    this.$store.dispatch(ACTIONS.GET_SHARED_INBOX_CONFIG);
    if (this.isLegacy) {
      this.$store.dispatch(ACTIONS.GET_CONTRACT);
    }
    this.refreshInboxes();
  },
  destroyed() {
    eventBus.removeEventListener("delete-confirm");
    eventBus.removeEventListener("edit-inbox");
  },
  methods: {
    formatA2pDate() {
      const profile = getProfile();
      if (this.hasRegistrationDates && this.registrationStep === 2)
        return formatDate(
          profile.currentDepartment.campaignVerificationStartedOn,
          "MM/DD"
        );
      else if (this.hasRegistrationDates && this.registrationStep > 2)
        return formatDate(
          profile.currentDepartment.campaignVerifiedOn,
          "MM/DD"
        );
    },
    async refreshInboxes() {
      try {
        this.loading_ = true;
        await this.$store.dispatch(ACTIONS.GET_SHARED_INBOXES, {
          currentDepartmentOnly: true,
        });
        this.loading_ = false;
      } catch (err) {
        this.loading_ = false;
        this.errorToast("There was an error loading Shared Inboxes.");
        return err;
      }
    },
    handleSearch(event) {
      this.searchTerm_ = event.searchText;
    },

    togglePopover() {
      this.showNewInboxModal_ = !this.showNewInboxModal_;
    },
    toggleShowShared() {
      this.$store.dispatch(ACTIONS.GET_SHARED_INBOXES, {
        currentDepartmentOnly: !this.showShared,
      });
    },

    cancelModals() {
      this.showDeleteInboxModal_ = false;
      this.showEditInboxModal_ = false;
      this.showNewInboxModal_ = false;
    },

    // Delete Inbox
    showDeleteModal(event) {
      const inboxToDelete = this.groupAccounts.find(
        (inbox) => inbox.id === event.detail.inboxId
      );
      this.deleteInboxName_ = inboxToDelete.name;
      this.inboxId = inboxToDelete.id;
      this.showDeleteInboxModal_ = true;
    },

    async handleDeleteConfirmed(id) {
      try {
        this.saving_ = true;
        await api.delete(`admin/groupAccounts/${id}`);
        this.showDeleteInboxModal_ = false;
        this.successToast(this.deleteInboxName_ + " has been deleted.");
        this.$emit("state-reload");
        this.refreshInboxes();
        this.cancelModals();
        this.saving_ = false;
        analytics.track("Deleted Group Account", {
          "Group Name": this.deleteInboxName_,
        });
      } catch (err) {
        this.saving_ = false;
        this.deleteErrorMessage_ = ["There was an error deleting the data."];
        return err;
      }
    },

    async handleDeleteInboxConfirmed(data) {
      await this.handleDeleteConfirmed(data.inboxId);
    },

    // Edit Inbox
    showEditModal(event) {
      const inboxToEdit = this.groupAccounts.find(
        (inbox) => inbox.id === event.detail.inboxId
      );
      this.editInboxName_ = inboxToEdit.name;
      this.editInboxApi_ = inboxToEdit.apiCode;
      this.editInboxColor_ = inboxToEdit.color;
      this.editInboxForwardingNumber_ = inboxToEdit.forwardingNumber;
      this.editInboxTeamId_ = inboxToEdit.departmentId;
      this.inboxId = inboxToEdit.id;
      this.showEditInboxModal_ = true;
    },

    async handleEditConfirmed(inbox) {
      try {
        this.saving_ = true;
        await api.put(`admin/groupAccounts/${inbox.id}`, inbox);
        this.showEditInboxModal_ = false;
        this.successToast(this.editInboxName_ + " has been changed.");
        this.$emit("state-reload");
        this.refreshInboxes();
        this.cancelModals();
        this.saving_ = false;
        analytics.track("Edited Group Account", {
          "Group Name": this.editInboxName_,
        });
      } catch (err) {
        this.saving_ = false;
        this.editErrorMessage_ = ["There was an error changing the data."];
        return err;
      }
    },

    async handleEditInboxConfirmed(inbox) {
      await this.handleEditConfirmed(inbox);
    },

    // Add New Inbox
    async handleNewInboxConfirmed(inbox) {
      await this.handleNewConfirmed(inbox);
    },

    async handleNewConfirmed(newInbox) {
      this.loading_ = true;
      try {
        this.saving_ = true;
        await api.post(`admin/groupAccounts`, newInbox);
        this.showNewInboxModal_ = false;
        this.successToast(newInbox.name + " has been created.");
        this.$emit("state-reload");
        this.refreshInboxes();
        this.cancelModals();
        this.saving_ = false;
        analytics.track("Created Group Account", {
          "Group Name": newInbox.name,
          "Access Count": newInbox.selectedStaff.length,
        });
      } catch (err) {
        const response = _.get(err, "response.data");
        let numbersNotReserved =
          response.errors[0].indexOf("cannot be reserved") !== -1;
        if (numbersNotReserved) {
          this.newErrorMessage_ = [
            "The number could not be reserved. Please try again.",
          ];
        } else {
          this.newErrorMessage_ = [
            response.errors[0] + " Please go back and try again.",
          ];
        }
        this.loading_ = false;
        this.saving_ = false;
        return err;
      }
    },

    showAddModal(event) {
      const inboxToAdd = this.groupAccounts.find(
        (inbox) => inbox.id === event.detail.inboxId
      );
      this.newInboxName_ = inboxToAdd.name;
      this.inboxId = inboxToAdd.id;
      this.showNewInboxModal_ = true;
    },

    // util
    errorToast(message) {
      let toastBody = this.$createElement(Toast, {
        props: {
          message,
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "danger",
        noCloseButton: true,
        solid: true,
      });
    },
    successToast(message) {
      let toastBody = this.$createElement(Toast, {
        props: {
          message,
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "success",
        noCloseButton: true,
        solid: true,
        autoHideDelay: 3000,
      });
    },
  },
  computed: {
    isLegacy() {
      return this.$store.state.config.plan === "Legacy";
    },
    groupAccounts() {
      return this.$store.state.sharedInbox.admin.groupAccounts;
    },
    inboxesTotal() {
      return this.groupAccounts.length;
    },
    groupAccountLimit() {
      return this.$store.state.sharedInbox.configuration.groupAccountLimit;
    },
    filterData() {
      return {
        staffId: this.staffId,
        staffTeams: this.staffTeams,
      };
    },
    isDisabled() {
      return this.inboxesTotal >= this.groupAccountLimit;
    },
    isDisabledLegacy() {
      return this.contract.accountsCount >= this.contract.accountLimit;
    },
    contract() {
      return this.$store.state.sharedInbox.contract;
    },
    registrationStep() {
      const profile = getProfile();

      if (!this.$store.getters[GETTERS.FEATURE_ENABLED]("a2p-verification")) {
        return 4;
      }

      if (
        this.groupAccounts.length > 0 &&
        (profile.currentDepartment.campaignStatus === "NONE" ||
          profile.currentDepartment.campaignStatus === "FAILED")
      ) {
        return 2;
      }

      switch (profile.currentDepartment.campaignStatus) {
        case "NONE":
          return 1;
        case "IN_PROGRESS":
          return 2;
        case "FAILED":
          return 2;
        case "VERIFIED": {
          return profile.currentDepartment.a2PRegistrationSetup ? 4 : 3;
        }
        default:
          return 1;
      }
    },
    hasRegistrationDates() {
      const profile = getProfile();
      if (this.registrationStep === 2)
        return !_.isNull(
          profile.currentDepartment.campaignVerificationStartedOn
        );
      else return !_.isNull(profile.currentDepartment.campaignVerifiedOn);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
.page-header h1 {
  margin: 0;
  font-size: 1.88125rem;
}

@media (max-width: 576px) {
  .page-header h1 {
    font-size: 1.5rem;
  }
}
.page-content {
  padding-top: $grid-gutter-width/2;
}
@media (min-width: 576px) {
  .quick-tip {
    margin: -15px 3px 4px 0;
  }
}
@media (min-width: 768px) {
  .quick-tip {
    margin: -30px 3px 9px 0;
  }
}
.search-bar {
  position: relative;
  min-width: 170px;
  margin-left: auto;
}
@media (min-width: 576px) {
  .page-content .search-bar {
    width: 220px !important;
  }
}
@media (max-width: 576px) {
  .page-content .search-bar {
    margin-top: 0 !important;
  }
}
.search-bar .form-control {
  padding: 8px 5px 7px 0;
}

.user-count {
  color: $accent;
  margin-top: 10px;
}
.inbox-count {
  color: $accent;
  margin-bottom: 30px;
  margin-top: 10px;
}
.show-all-shared {
  ::v-deep label {
    font-weight: normal;
  }
}
.error-padding {
  padding: 0 30px 15px 30px;
}

.card {
  padding: 30px;
  gap: 40px;
}
.progress-steps-line {
  li {
    width: 25%;
    &.active {
      &:before,
      &:after {
        background: #318ce7;
      }
    }
    span {
      display: inline-block;
      position: relative;
      width: 137px;
      height: 42px;
      vertical-align: middle;
      top: -65px;
    }
    // I give up trying to get this one right
    &.two-line {
      .bottom-text {
        top: -34px;
        left: 0px;
        position: relative;
        color: #555;
        font-weight: 400;
      }

      .top-text {
        left: 0px;
        position: relative;
        top: -66px;
      }
    }
  }
}
</style>
