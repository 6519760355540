<template>
  <BModal
    :id="modalId"
    size="sm"
    title="Filter"
    :static="test"
    title-tag="h3"
    @close="cancel()"
  >
    <BForm class="d-flex flex-column">
      <BFormGroup>
        <template #label>
          <strong class="h4">Conversation Type</strong>
        </template>
        <BFormRadioGroup
          v-model="conversationTypeSelected_"
          :options="conversationTypeOptions_"
          name="conversation-type"
          stacked
          class="conversationTypeRadio"
          data-testid="conversation-type-radio"
        >
        </BFormRadioGroup>
      </BFormGroup>
      <BFormGroup>
        <template #label>
          <strong class="h4">Conversation Status</strong>
        </template>
        <BFormRadioGroup
          v-model="conversationStatusSelected_"
          :options="conversationStatusOptions_"
          name="conversation-status"
          stacked
          class="conversationStatusRadio"
          data-testid="conversation-status-radio"
        >
        </BFormRadioGroup>
      </BFormGroup>
    </BForm>
    <template v-slot:modal-footer>
      <div class="d-flex flex-row justify-content-end w-100">
        <button class="btn btn-link mr-2" @click="clearFilters()">
          Clear Filters
        </button>
        <button @click="confirm()" class="btn btn-primary">Apply</button>
      </div>
    </template>
  </BModal>
</template>
<script>
import { BForm, BModal, BFormGroup, BFormRadioGroup } from "bootstrap-vue";
import { WEBCHAT_FILTERS } from "@/util/constants";
import analytics from "@/util/analytics";

export default {
  name: "WebChatFilterModal",
  components: { BForm, BModal, BFormGroup, BFormRadioGroup },
  data() {
    return {
      conversationTypeOptions_: [
        { text: "User Assisted", value: WEBCHAT_FILTERS.USER_ASSISTED },
        { text: "Bot Only", value: WEBCHAT_FILTERS.BOT_ONLY },
      ],
      conversationTypeSelected_: "",
      conversationStatusOptions_: [
        { text: "Open", value: WEBCHAT_FILTERS.CONVERSATION_OPEN },
        { text: "Closed", value: WEBCHAT_FILTERS.CONVERSATION_CLOSED },
      ],
      conversationStatusSelected_: "",
    };
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    test: {
      type: Boolean,
      required: false,
      default: false,
    },
    /* custom events emitted:
        - confirmed
        - cancelled
    */
  },
  mounted() {
    this.$root.$on("bv::modal::hide", () => {
      this.cancel();
    });
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.showModal();
      } else if (!newVal && oldVal) {
        this.hideModal();
      }
    },
  },
  methods: {
    confirm() {
      this.$emit("confirmed", {
        selectedFilters: [
          this.conversationTypeSelected_,
          this.conversationStatusSelected_,
        ].filter((option) => typeof option === "string"),
      });
      analytics.track("Filtered chat conversations", {
        conversationType: this.conversationTypeSelected_,
        conversationStatus: this.conversationStatusSelected_,
      });
    },
    cancel() {
      this.$emit("cancelled");
    },
    showModal() {
      this.resetFilters();
      this.$bvModal.show(this.modalId);
    },
    hideModal() {
      this.$bvModal.hide(this.modalId);
    },
    resetFilters() {
      this.conversationTypeSelected_ = [
        WEBCHAT_FILTERS.USER_ASSISTED,
        WEBCHAT_FILTERS.BOT_ONLY,
      ].reduce((acc, cur) => (this.filters[cur] ? cur : acc), "");

      this.conversationStatusSelected_ = [
        WEBCHAT_FILTERS.CONVERSATION_OPEN,
        WEBCHAT_FILTERS.CONVERSATION_CLOSED,
      ].reduce((acc, cur) => (this.filters[cur] ? cur : acc), "");
    },
    clearFilters() {
      this.conversationTypeSelected_ = [];
      this.conversationStatusSelected_ = [];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

h4,
.h4 {
  font-weight: $font-weight-bold;
}

::v-deep .modal-header {
  padding-left: 1rem;
  padding-right: 1rem;
}

::v-deep .modal-body {
  padding-left: 1rem;
  padding-right: 1rem;
}

::v-deep .modal-footer {
  padding-left: 1rem;
  padding-right: 1rem;
}

::v-deep .custom-control {
  padding-top: 0.5rem;

  .custom-control-label {
    font-weight: $font-weight-normal;
  }
}

::v-deep fieldset {
  box-shadow: none;
  -webkit-box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}
</style>
