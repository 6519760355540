<template>
  <div class="popover-container">
    <button
      class="popover-button-text"
      :class="{ focused: isPopoverOpen, 'in-compose-modal': inComposeModal }"
      @click="togglePopover"
      :id="popoverId"
    >
      <span class="sr-only">Choose template</span>
      <div>TEMPLATE</div>
    </button>

    <BPopover
      :target="popoverId"
      placement="top"
      triggers="click, blur"
      :customClass="`search-popover`"
      ref="popover"
      :show.sync="isPopoverOpen"
    >
      <template v-slot:title>
        <div class="popover-fixed-header">
          <span>TEMPLATES</span>
          <button
            type="button"
            class="btn btn-light btn-sm create-new-button"
            @click="goToTemplatePage"
          >
            CREATE NEW
          </button>
        </div>

        <div
          v-if="items.length > 0"
          class="d-flex w-100 justify-content-center align-items-center bg-white template-types-container"
        >
          <BButtonGroup class="template-types" size="sm">
            <BButton :pressed.sync="personalSelected">MINE</BButton>
            <BButton :pressed.sync="sharedSelected">TEAM</BButton>
            <BButton :pressed.sync="allSelected">ALL</BButton>
          </BButtonGroup>
        </div>

        <BInputGroup v-if="items.length > 0" class="search-container">
          <template v-slot:prepend>
            <MgIcon
              class="d-flex align-self-center search-icon"
              name="search"
              scale=".75"
            />
          </template>
          <BFormInput
            class="search-input"
            type="search"
            placeholder="Search"
            autofocus
            v-model="search"
          >
          </BFormInput>
        </BInputGroup>
      </template>

      <div
        v-if="filteredItemList.length > 0"
        class="list-group"
        :style="{ 'max-height': '275px' }"
      >
        <button
          type="button"
          class="list-group-item list-group-item-action"
          @click="selectItem(i)"
          v-for="(i, index) in filteredItemList"
          :key="index"
        >
          <span v-html="highlightText(i.name)"></span>
          <span v-if="i.description" class="text-truncate desc">{{
            i.description
          }}</span>
          <span v-if="i.message" class="text-truncate desc">{{
            i.message
          }}</span>
        </button>
      </div>

      <div v-if="filteredItemList.length === 0" class="empty-state">
        <img src="/images/icon_copy.svg" width="40" height="40" />
        <div class="message">
          A starting point for ensuring consistency and efficiency in
          communication.
        </div>
        <BButton @click="goToTemplatePage" variant="primary"
          >New Template</BButton
        >
      </div>
    </BPopover>
  </div>
</template>

<script>
import MgIcon from "@/components/MgIcon";
import Vue from "vue";
import {
  BFormInput,
  BInputGroup,
  BPopover,
  BButtonGroup,
  BButton,
} from "bootstrap-vue";
import { VBTooltip } from "bootstrap-vue";
import { getSessionObject, updateSessionObject } from "@/util/session";

Vue.component("BFormInput", BFormInput);
Vue.directive("b-tooltip", VBTooltip);

export const TEMPLATE_TYPES = {
  TEXT: "Text",
  CHAT: "Chat",
};

export const TEMPLATE_PERMISSIONS = {
  PERSONAL: 0,
  SHARED: 1,
  ALL: 2,
};

export const TEMPLATE_PICKER_TYPE_KEY = "templatePickerType";

export default {
  name: "LegacyTemplatePicker",
  props: {
    items: {
      type: Array,
      required: true,
    },
    templateType: {
      type: String,
      required: true,
    },
    popoverId: {
      type: String,
      required: true,
    },
    inComposeModal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    BFormInput,
    BInputGroup,
    MgIcon,
    BPopover,
    BButtonGroup,
    BButton,
  },
  data() {
    return {
      search: "",
      isPopoverOpen: false,
      pickerType_:
        getSessionObject(TEMPLATE_PICKER_TYPE_KEY) ||
        TEMPLATE_PERMISSIONS.PERSONAL,
      PERSONAL: TEMPLATE_PERMISSIONS.PERSONAL,
      SHARED: TEMPLATE_PERMISSIONS.SHARED,
      ALL: TEMPLATE_PERMISSIONS.ALL,
    };
  },
  computed: {
    filteredItemList() {
      const filteredByPickerType = this.items.filter((template) => {
        switch (this.pickerType_) {
          case TEMPLATE_PERMISSIONS.PERSONAL:
            return !template.isShared && template.isEnabled;
          case TEMPLATE_PERMISSIONS.SHARED:
            return template.isShared && template.isEnabled;
          case TEMPLATE_PERMISSIONS.ALL:
            return template.isEnabled;
        }
      });

      return this.search
        ? filteredByPickerType.filter((item) => {
            return item.name.toLowerCase().includes(this.search.toLowerCase());
          })
        : filteredByPickerType;
    },

    personalSelected: {
      get() {
        return this.pickerType_ === TEMPLATE_PERMISSIONS.PERSONAL;
      },
      set(value) {
        if (value) {
          this.pickerType_ = TEMPLATE_PERMISSIONS.PERSONAL;
          updateSessionObject(
            TEMPLATE_PICKER_TYPE_KEY,
            TEMPLATE_PERMISSIONS.PERSONAL
          );
          this.$forceUpdate();
        }
      },
    },

    sharedSelected: {
      get() {
        return this.pickerType_ === TEMPLATE_PERMISSIONS.SHARED;
      },
      set(value) {
        if (value) {
          this.pickerType_ = TEMPLATE_PERMISSIONS.SHARED;
          updateSessionObject(
            TEMPLATE_PICKER_TYPE_KEY,
            TEMPLATE_PERMISSIONS.SHARED
          );
          this.$forceUpdate();
        }
      },
    },

    allSelected: {
      get() {
        return this.pickerType_ === TEMPLATE_PERMISSIONS.ALL;
      },
      set(value) {
        if (value) {
          this.pickerType_ = TEMPLATE_PERMISSIONS.ALL;
          updateSessionObject(
            TEMPLATE_PICKER_TYPE_KEY,
            TEMPLATE_PERMISSIONS.ALL
          );
          this.$forceUpdate();
        }
      },
    },
  },
  watch: {
    isPopoverOpen: function () {
      this.$emit("popover-state", this.isPopoverOpen);
    },
  },
  methods: {
    highlightText(matchedName) {
      if (this.search === "") {
        return matchedName;
      }
      const re = new RegExp(this.search, "gi");
      return matchedName.replace(re, `<strong>$&</strong>`);
    },

    togglePopover(e) {
      e.preventDefault();
      this.$root.$emit("bv::hide::tooltip");
      this.isPopoverOpen = !this.isPopoverOpen;
      this.pickerType_ =
        getSessionObject(TEMPLATE_PICKER_TYPE_KEY) ||
        TEMPLATE_PERMISSIONS.PERSONAL;
    },

    selectItem(item) {
      this.$root.$emit("bv::hide::popover");
      this.$emit("item-picked", item);
      this.search = "";
    },

    goToTemplatePage() {
      if (this.templateType === TEMPLATE_TYPES.TEXT) {
        window.location.href = "#/v2/admin/department/templates/text";
        this.$emit("close-modal");
      } else if (this.templateType === TEMPLATE_TYPES.CHAT) {
        window.location.href = "#/v2/admin/department/templates/chat";
        this.$emit("close-modal");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
.popover-container {
  display: inline;
}

.popover-button-text {
  background-color: inherit;
  border: none;
  border-radius: 2px;
  color: #555;
  font-family: $font-family-sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin-right: $grid-gutter-width/6;
  padding: 2px 6px;
  letter-spacing: 0.5px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    background-color: darken($panel-default-heading-bg, 10%);
  }

  &:focus,
  &.focus,
  &.focused {
    background-color: #dae1e7;
  }

  &.in-compose-modal {
    transform: translateY(0.5px);
  }
}

.create-new-button {
  border-color: #ced4da !important;
}

.search-icon {
  ::v-deep svg {
    width: 15px;
  }
}

.search-container {
  border-top: solid 1px #e7e8e8;
  padding: 0 15px;
}

.search-input {
  border: none;
  padding-right: 0;
}

::v-deep .popover-header {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  background-color: #fff;
}

.template-types-container {
  padding: 0.75rem 1rem;
}

.template-types {
  background-color: #f0f4f8;

  button {
    height: fit-content !important;
    padding: 3px 20px !important;
    background-color: $input-bg-disabled;
    border-color: #ced4da;
    color: $gray-900;

    &:hover {
      background-color: #dee2e6;
    }

    &.active {
      background-color: #dee2e6 !important;
      border-color: #ced4da !important;
      color: $gray-900 !important;
    }

    &.focus {
      background-color: #dee2e6 !important;
      border-color: #ced4da !important;
      color: $gray-900 !important;
    }
  }
}

.popover-fixed-header {
  background-color: #f0f4f8;
  border: 0;
  border-radius: 3px 3px 0 0;
  margin: 0;
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.empty-state {
  background-color: #fff;
  margin: 0;
  padding: 2.5rem 1rem;
  text-align: center;

  .message {
    width: 100%;
    padding: 0.75rem 0;
    font-size: 0.75rem;
  }
}
</style>
