<template>
  <BModal
    id="tag-confirm"
    :title="`${tagId ? 'Edit' : 'New'} Tag`"
    title-tag="h3"
    @close="cancel()"
  >
    <form @submit.prevent="confirm()">
      <div v-if="errorMessage.length" class="alert alert-danger">
        <b v-if="errorMessage[0] && errorMessage[0].length">{{
          errorMessage[0]
        }}</b>
        <span v-if="errorMessage.length === 2">&nbsp;&nbsp;</span>
        <span v-if="errorMessage[1] && errorMessage[1].length">{{
          errorMessage[1]
        }}</span>
      </div>
      <div class="form-group">
        <label for="tag-name">Name</label>
        <input
          v-model="tagName_"
          type="text"
          class="form-control"
          maxlength="255"
          id="tag-name"
        />
      </div>
      <div class="form-group">
        <label for="tag-description">Description</label>
        <textarea
          id="tag-description"
          name="tag-description"
          v-model="tagDescription_"
          class="form-control"
          maxlength="1000"
        ></textarea>
      </div>
    </form>
    <template v-slot:modal-footer>
      <button
        type="submit"
        @click.prevent="confirm()"
        class="btn btn-primary"
        id="save-tag"
        :disabled="!submitAllowed_ || saving"
      >
        {{ tagId ? "Update" : "Create" }}
      </button>
      <button @click="cancel()" class="btn btn-light" :disabled="saving">
        Cancel
      </button>
    </template>
  </BModal>
</template>
<script>
import { BModal } from "bootstrap-vue";

export default {
  name: "AddEditTagModal",
  components: { BModal },
  props: {
    tagId: {
      type: Number,
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    tagName: {
      type: String,
      required: false,
      default: "",
    },
    tagDescription: {
      type: String,
      required: false,
      default: "",
    },
    submitAllowedTest: {
      type: Function,
      required: false,
    },
    errorMessage: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    /* custom events emitted:
        -edit-tag-confirmed
        -new-tag-confirmed
        -tag-cancelled
    */
  },
  data() {
    return {
      tagName_: "",
      tagDescription_: "",
      submitAllowed_: false,
    };
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.tagName_ = this.tagName;
        this.tagDescription_ = this.tagDescription;
        this.showModal();
      } else if (!newVal && oldVal) {
        this.hideModal();
      }
    },
    tagName_(newVal) {
      if (!newVal) {
        this.submitAllowed_ = false;
      } else if (this.submitAllowedTest) {
        this.submitAllowed_ = this.submitAllowedTest(newVal);
      } else {
        this.submitAllowed_ = true;
      }
    },
  },
  mounted() {
    // in case they're sneaky and close by clicking outside the modal
    this.$root.$on("bv::modal::hide", () => {
      this.cancel();
    });
  },
  methods: {
    confirm() {
      if (!this.submitAllowed_) {
        return;
      }

      if (this.tagId) {
        this.$emit("edit-tag-confirmed", {
          tagId: this.tagId,
          tagName: this.tagName_,
          tagDescription: this.tagDescription_,
        });
      } else {
        this.$emit("new-tag-confirmed", {
          tagName: this.tagName_,
          tagDescription: this.tagDescription_,
        });
      }
    },
    cancel() {
      this.$emit("tag-cancelled");
    },
    showModal() {
      this.$bvModal.show("tag-confirm");
    },
    hideModal() {
      this.$bvModal.hide("tag-confirm");
    },
  },
};
</script>
<style lang="scss" scoped>
.strong-error-text {
  margin-right: 1em;
}
</style>
