<template>
  <BModal id="importConfigModal" ref="importConfigModal">
    <template #modal-header="{ close }">
      <h3 class="modal-title" v-if="isNew">Add Field</h3>
      <h3 class="modal-title" v-else>Edit Field</h3>
      <button
        class="close"
        @click="close()"
        aria-label="Close Import Configuration Modal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </template>
    <div class="renxt-modal-content">
      <div v-show="error.messages">
        <div class="alert alert-danger">
          <div class="d-flex flex-row">
            <div>
              <MgIcon name="exclamation-triangle" scale="1.25" class="mr-1" />
            </div>
            <div>
              <span class="font-weight-bolder">
                Please address the errors and update.
              </span>

              <ul class="mb-0 pl-3">
                <li v-for="error in error.messages" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3" v-if="!requiredFields">
        <label>Raiser&rsquo;s Edge NXT Field Source</label>
        <BFormRadioGroup v-model="isStandardNxtField">
          <BFormRadio :value="true">
            <span class="font-weight-normal"> Standard Fields </span>
          </BFormRadio>
          <BFormRadio :value="false">
            <span class="font-weight-normal">
              Constituent Specific Fields
            </span>
          </BFormRadio>
        </BFormRadioGroup>
      </div>

      <div
        v-if="
          (isStandardNxtField === undefined || isStandardNxtField) &&
          !phoneField
        "
      >
        <IntegrationsViewFieldSelect
          class="w-100"
          :field="currentField"
          :options="currentViewFields"
          :useRawValue="false"
          :isError="isError('crmField')"
          integrationName="Raiser's Edge NXT"
          @change="changeCurrentField"
          :disabled="isStandardNxtField === undefined"
          required
        />
        <span v-show="isError('crmField')" class="text-danger">
          Raiser&rsquo;s Edge NXT Field is Required
        </span>
      </div>

      <div class="" v-else>
        <div v-if="!phoneField">
          <label for="constituentCustomFields"
            >Constituent Custom Field Category</label
          >
          <BFormSelect
            id="constituentCustomFields"
            v-model="crmConstituentCustomFieldCategory_"
            :options="constituentFields"
          >
            <template #first>
              <BFormSelectOption :value="undefined" disabled>
                Select a Constituent Custom Field Category
              </BFormSelectOption>
            </template>
          </BFormSelect>
        </div>
      </div>

      <div v-if="phoneField">
        <label
          >Raiser&rsquo;s Edge NXT Field Source
          <p class="font-weight-regular pt-2">
            Choose Standard Fields to select the constituent's default phone
            number field. Select Constituent Phone Number Type to map phone
            types in sequence.
          </p>
          <div>
            <BFormRadioGroup v-model="isNXTPhoneField">
              <BFormRadio :value="true">
                <span class="font-weight-normal"> Standard Fields </span>
              </BFormRadio>
              <BFormRadio :value="false">
                <span class="font-weight-normal">
                  Constituent Specific Phone Types
                </span>
              </BFormRadio>
            </BFormRadioGroup>
          </div>
        </label>

        <div v-if="isNXTPhoneField">
          <IntegrationsViewFieldSelect
            class="w-100 mt-3"
            :field="currentField"
            :options="currentViewFields"
            :useRawValue="false"
            :isError="isError('crmField')"
            integrationName="Raiser's Edge NXT"
            @change="changeCurrentField"
            :disabled="isStandardNxtField === undefined"
            required
          />
          <span v-show="isError('crmField')" class="text-danger">
            Raiser&rsquo;s Edge NXT Field is Required
          </span>
        </div>

        <div v-else>
          <div
            class="d-flex"
            :key="i"
            v-for="(phone, i) in phoneTypeField.typePreferences"
          >
            <div v-if="i > 0" class="orPill flex-column mr-2">
              <span>OR</span>
            </div>
            <div class="mt-2 flex-column w-100">
              <label :for="phoneTypeField.typePreferences[i]"
                >Raiser&rsquo;s Edge NXT Phone Type

                <BFormSelect
                  :id="phoneTypeField.typePreferences[i]"
                  v-model="phoneTypeField.typePreferences[i]"
                  :options="phoneTypes"
                  class="mt-2"
                >
                  <template #first>
                    <BFormSelectOption value="" disabled>
                      Select Constituent Specific Phone Type
                    </BFormSelectOption>
                  </template>
                </BFormSelect>
              </label>
            </div>
            <div v-if="i > 0" class="closeIcon">
              <a
                @click="removePhoneFallback(i)"
                aria-label="Remove Phone Fallback Option"
                role="button"
                class="btn-icon close"
              >
                <MgIcon name="times"></MgIcon>
              </a>
            </div>
          </div>
        </div>

        <div v-if="!isNXTPhoneField">
          <div v-if="phoneTypeField.typePreferences.length < 3">
            <p class="mt-3 mb-2">
              If the above field is empty for a contact, select an alternate
              phone type to map and retrieve.
            </p>
            <button
              class="btn btn-outline mt-1"
              @click="addPhoneFallback()"
              type="submit"
            >
              <MgIcon :scale="iconScale_" name="plus" />Add Alternate
            </button>
          </div>
          <p class="mt-3">
            If none of the mapped phone types are available, specify your
            preferred action. Choosing Fail Contact will result in an error
            during import while Revert to Primary will use the primary number
            stored on the constituent record.
          </p>
          <label class="d-none">Fail or revert contact choice</label>
          <BFormRadioGroup v-model="phoneTypeField.fallbackOption">
            <BFormRadio value="FailRow">
              <span class="font-weight-normal"> Fail Contact </span>
            </BFormRadio>
            <BFormRadio value="PrimaryFallback">
              <span class="font-weight-normal"> Revert to Primary </span>
            </BFormRadio>
          </BFormRadioGroup>
        </div>
      </div>

      <div class="mt-3">
        <div>
          <label for="cadenceFieldInput">Cadence Field</label>
          <p v-show="fieldDescriptor !== ''">{{ fieldDescriptor }}</p>
          <input
            id="cadenceFieldInput"
            class="form-control"
            :class="isError('customField') ? 'is-invalid' : ''"
            v-model="currentField.customField.displayName"
            :disabled="requiredFields || isStandardNxtField === undefined"
            required
          />
          <span v-show="isError('customField')" class="text-danger">
            Cadence Field is Required
          </span>

          <div class="form-check form-check-inline w-100 mt-2">
            <BFormCheckbox
              class="mr-3"
              :disabled="requiredFields || isStandardNxtField === undefined"
              v-model="currentField.customField.isRequired"
            >
              <span class="font-weight-normal">Required</span>
            </BFormCheckbox>
            <BFormCheckbox
              class="mr-3"
              v-model="currentField.customField.canMailMerge"
              :disabled="requiredFields || isStandardNxtField === undefined"
            >
              <span class="font-weight-normal">Mergeable</span>
            </BFormCheckbox>
            <BFormCheckbox
              class="mr-3"
              :disabled="requiredFields || isStandardNxtField === undefined"
              v-model="currentField.customField.isVisible"
            >
              <span class="font-weight-normal">Visible to Users</span>
            </BFormCheckbox>
          </div>
          <div class="mt-2" v-if="!currentField.cadenceField">
            <p class="m-0">
              <MgIcon name="exclamation-circle" />
              The options for
              <span v-if="currentField.customField.isRequired"
                >Required Fields</span
              >
              <span v-else>Optional Fields</span>
              are predefined and are displayed for reference.
            </p>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-between">
        <button
          class="btn btn-primary"
          @click="handleAddNewField()"
          type="submit"
          v-if="isNew"
        >
          Add
        </button>
        <button
          class="btn btn-primary"
          @click="updateRequiredField()"
          :disabled="phoneFieldButtonDisabled"
          type="submit"
          v-else-if="field.crmField.id === ''"
        >
          Save
        </button>
        <button
          class="btn btn-primary"
          @click="updateRequiredField()"
          :disabled="phoneFieldButtonDisabled"
          type="submit"
          v-else
        >
          Update
        </button>
        <button class="btn btn-light ml-1" @click="cancel">Cancel</button>
      </div>
    </template>
  </BModal>
</template>

<script>
import {
  BFormCheckbox,
  BModal,
  BFormRadio,
  BFormRadioGroup,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import _ from "lodash";
import api from "@/util/api";
import IntegrationsViewFieldSelect from "@/components/IntegrationsViewFieldSelect";
import MgIcon from "@/components/MgIcon";
import store from "@/store";

export default {
  name: "RenxtImportFieldAddEditModal",
  store,
  components: {
    BFormCheckbox,
    BModal,
    BFormRadio,
    BFormRadioGroup,
    BFormSelect,
    BFormSelectOption,
    MgIcon,
    IntegrationsViewFieldSelect,
  },
  props: {
    field: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    cadenceField: {
      type: String,
      required: true,
    },
    currentViewFields: {
      //TODO -- change to `importFields`
      type: Array,
      required: true,
    },
    error: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    requiredFields: {
      //TODO -- change to singular
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      //TODO -- maybe rename to `isAddingANewFieldToCustomField`
      type: Boolean,
      required: false,
      default: false,
    },
    fieldDescriptor: {
      type: String,
      required: false,
      default: "",
    },
    phoneFallbackOption: {
      type: String,
      default: "ConstituentDefaultPhone",
    },
    phoneTypePreferences: {
      type: Array,
    },
  },
  data() {
    return {
      selectedId: "",
      selectedField: {},
      currentField: {
        customField: {},
      },
      phoneTypeField: {
        fallbackOption: "ConstituentDefaultPhone",
        typePreferences: [],
      },
      useRawValue: false,
      emptyIsOptedIn: false,
      formErrors: [],
      isNXTPhoneField: undefined,
      isStandardNxtField: undefined, // Will be a bool
      crmConstituentCustomFieldCategory_: undefined, // Will be string
      constituentFields: [],
      phoneTypes: [],
      iconScale_: "0.9",
    };
  },
  async created() {
    this.constituentFields = await api.get(
      "/RaisersEdgeNxt/constituent-custom-field-categories"
    );
    this.phoneTypes = await api.get("/RaisersEdgeNxt/phone-types");
    this.config = await api.get("/RaisersEdgeNxt/configuration");
  },
  mounted() {
    this.$root.$on("bv::modal::show", () => {
      if (this.isNew) {
        this.useRawValue = false;
        this.currentField = {
          cadenceField: {},
          customField: { displayName: "", isVisible: true, isRequired: true },
          crmField: {
            id: "",
            name: "",
            groupName: "",
            type: "",
            typeFormat: "",
          },
          crmConstituentCustomFieldCategory_: undefined,
        };
        this.selectedField = { id: 0 };
      } else {
        this.useRawValue = false;

        this.currentField = _.cloneDeep(this.field);

        this.crmConstituentCustomFieldCategory_ =
          this.field.crmConstituentCustomFieldCategory === ""
            ? undefined
            : this.field.crmConstituentCustomFieldCategory;
        if (!this.phoneTypeField.typePreferences.length) {
          this.isStandardNxtField = true;
        }
        this.isStandardNxtField = !this.field.crmConstituentCustomFieldCategory;
        this.isNXTPhoneField = !this.isStandardNxtField;

        if (!this.currentField.customField) {
          this.currentField = {
            customField: { displayName: "" },
            ...this.currentField,
          };
        }

        this.selectedField = _.cloneDeep(this.field.crmField);

        let selectedCadenceField = this.currentField.cadenceField;

        if (selectedCadenceField) {
          this.currentField.customField = {
            id: selectedCadenceField.id,
            displayName: selectedCadenceField.name,
            isVisible: selectedCadenceField.isVisible,
            canMailMerge: selectedCadenceField.canMailMerge,
            isRequired: selectedCadenceField.isRequired,
          };
        }

        this.phoneTypeField.fallbackOption = this.phoneFallbackOption;
        this.phoneTypeField.typePreferences = this.phoneTypePreferences;
      }

      if (this.requiredFields) {
        this.currentField.customField = {
          displayName: this.cadenceField,
        };

        this.currentField.customField.isRequired =
          this.currentField.customField.displayName !== "User Id";
        this.currentField.customField.canMailMerge =
          this.currentField.customField.displayName === "First Name" ||
          this.currentField.customField.displayName === "Last Name" ||
          this.currentField.customField.displayName === "Mobile Number";
        this.currentField.customField.isVisible = true;
        this.isStandardNxtField = true;
      }

      if (this.phoneField) {
        this.isNXTPhoneField =
          this.phoneTypeField.fallbackOption === "ConstituentDefaultPhone";

        if (!this.phoneTypeField.typePreferences.length) {
          this.phoneTypeField.typePreferences.push("");
        }
      }
    });

    this.$root.$on("bv::modal::hidden", () => {
      this.formErrors = [];
      this.selectedId = "";
      this.crmConstituentCustomFieldCategory_ = undefined;
      this.isStandardNxtField = undefined;

      this.currentField = {
        customField: {},
      };
      this.useRawValue = false;
      this.emptyIsOptedIn = false;
    });
  },

  computed: {
    phoneField() {
      return this.currentField.customField.displayName === "Mobile Number";
    },
    phoneFieldButtonDisabled() {
      if (this.phoneField) {
        if (this.isNXTPhoneField) {
          return false;
        } else if (
          !this.isNXTPhoneField &&
          this.phoneTypeField.typePreferences[0] !== "" &&
          this.phoneTypeField.fallbackOption !== "ConstituentDefaultPhone"
        ) {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    changeCurrentField(key) {
      this.selectedId = key;
      this.selectedField = _.find(this.currentViewFields, (o) => {
        return o.id === key;
      });

      this.useRawValue = false;
    },
    addPhoneFallback() {
      //add mixpanel here
      this.phoneTypeField.typePreferences.push("");
    },
    removePhoneFallback(index) {
      if (index === -1) return;
      this.phoneTypeField.typePreferences.splice(index, 1);
    },

    isError(field) {
      return _.includes(this.formErrors, field);
    },
    validate() {
      this.formErrors = [];
      if (this.isStandardNxtField) {
        if (this.selectedField.id === 0) {
          this.formErrors.push("crmField");
        }
      } else {
        if (this.crmConstituentCustomFieldCategory_ === "") {
          this.formErrors.push("crmField");
        }
      }

      if (
        !this.requiredFields &&
        this.currentField.customField.displayName === ""
      ) {
        this.formErrors.push("customField");
      }

      return this.formErrors.length === 0;
    },
    updateRequiredField() {
      if (!this.validate()) {
        return;
      }

      if (!this.isStandardNxtField) {
        this.field.crmField = {};
      } else if (this.selectedId !== "") {
        this.field.crmField = this.selectedField;
      }

      if (!this.requiredFields) {
        this.field.customField = this.currentField.customField;
        this.field.cadenceField.name = this.currentField.customField.displayName;
        this.field.cadenceField.isRequired = this.currentField.customField.isRequired;
        this.field.cadenceField.canMailMerge = this.currentField.customField.canMailMerge;
        this.field.cadenceField.isVisible = this.currentField.customField.isVisible;
        this.field.crmConstituentCustomFieldCategory = !this.isStandardNxtField
          ? this.crmConstituentCustomFieldCategory_
          : "";
      }

      this.field.useRawValue = false;

      if (this.phoneField) {
        if (this.isNXTPhoneField) {
          this.phoneTypeField.fallbackOption = "ConstituentDefaultPhone";
          this.phoneTypeField.typePreferences = [];
        }
        this.updatePhoneTypeField();
      }

      this.$emit("handleEditField", this.currentField.customField.displayName);
      this.$refs["importConfigModal"].hide();
    },
    updatePhoneTypeField() {
      this.$emit("handleEditPhoneField", this.phoneTypeField);
      this.$refs["importConfigModal"].hide();
    },
    handleAddNewField() {
      if (!this.validate()) {
        return;
      }
      this.$emit("handleAddNewCustomField", {
        customField: this.currentField.customField,
        crmConstituentCustomFieldCategory: !this.isStandardNxtField
          ? this.crmConstituentCustomFieldCategory_
          : "",
        crmField: this.isStandardNxtField ? this.selectedField : {},
        useRawValue: false,
      });
      this.$refs["importConfigModal"].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.renxt-modal-content {
  padding: 12px 0;
}
::v-deep .btn .icon svg {
  margin-right: 6px;
}
.btn-outline {
  background: #fff;
  color: #006ba8;
  border: 1px solid #006ba8;
}
.orPill {
  color: #035388;
  background-color: #e3f8ff;
  border: 1px solid #b4dff8;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  height: 25px;
  line-height: 15px;
  margin-top: 43px;
  padding: 4px 8px;
}
::v-deep .closeIcon .fa-icon {
  fill: #c00;
  line-height: 10px;
  margin-top: 48px;
  margin-left: 10px;
}
::v-deep .close {
  opacity: 1;
}
.font-weight-regular {
  font-weight: 400 !important;
}
</style>
