<template>
  <BModal id="customFieldInUse" ref="customFieldInUse">
    <template #modal-header="{ close }">
      <h3 class="modal-title">
        Remove
        {{ field.field.customField ? field.field.customField.displayName : "" }}
      </h3>
      <button class="close" @click="close()" aria-label="Close Modal">
        <span aria-hidden="true">&times;</span>
      </button>
    </template>
    <div class="integration-modal-content">
      <p>
        <span class="h4 error-list">This field is being used in the following segments and cannot be removed</span>
        <ul class="list-bulleted mt-4 ml-4">
                <li :key="s" v-for="s in segments">{{ s }}</li>
            </ul>
      </p>
    </div>
    <template #modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-between">
        <button class="btn btn-light" @click="cancel()" type="submit">
          Ok
        </button>
      </div>
    </template>
  </BModal>
</template>

<script>
import { BModal } from "bootstrap-vue";

export default {
  name: "IntegrationsCustomFieldInUseModal",
  components: {
    BModal,
  },
  data() {
    return {};
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    segments: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.integration-modal-content {
  padding: 12px 0;
}
</style>
