<template>
  <BFormTag
    class="mg-tag"
    :class="`bg-${bgColor}`"
    :no-remove="hoverClose || noRemoveButton"
    @remove="remove"
  >
    <template #default>
      <div class="d-flex">
        <MgIcon v-if="icon" :name="icon" scale=".75"></MgIcon>
        <span
          class="title text-truncate"
          :class="{
            shortTitle: hoverClose && hoverCloseActive && !noRemoveButton,
          }"
          >{{ title }}</span
        >
        <button
          v-if="hoverClose && !noRemoveButton"
          class="btn custom-close"
          :class="{ isHovered: hoverClose && hoverCloseActive }"
          v-on:click="remove"
        >
          <span class="sr-only">Remove</span>
          <MgIcon name="times" scale=".75" />
        </button>
      </div>
    </template>
  </BFormTag>
</template>

<script>
import { BFormTag } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";

export default {
  name: "MgTag",
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "blue",
    },
    hoverClose: {
      type: Boolean,
      default: true,
    },
    hoverCloseActive: {
      type: Boolean,
      default: false,
    },
    noRemoveButton: {
      type: Boolean,
    },
  },
  components: {
    BFormTag,
    MgIcon,
  },
  methods: {
    remove() {
      this.$emit("remove");
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";

.mg-tag {
  border-radius: $border-radius;
  display: inline-block;
  overflow: hidden;
  padding: 0;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
  font-size: 0.75rem;
  position: relative;

  &:focus-within {
    .title {
      width: calc(100% - 34px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .custom-close {
    position: absolute;
    right: 0;
    color: #fff;
    cursor: pointer;
    padding: 0 6px;
    display: flex;
    transition: opacity 200ms ease-out;
    height: 100%;
    align-items: center;
    opacity: 0;
    border: 0;
    border-radius: 0;
    .icon {
      padding: 0;
      margin-top: 2px;
    }
    &.isHovered,
    &:focus {
      opacity: 1;
    }
  }

  &.bg-blue {
    background-color: $blue;
    color: #fff;
    .custom-close.isHovered,
    .custom-close:focus {
      background-color: darken($blue, 15%);
    }
  }

  &.bg-purple {
    background-color: #975bb9;
    color: #fff;
    .custom-close.isHovered,
    .custom-close:focus {
      background-color: darken(#975bb9, 15%);
    }
  }

  &.bg-gray {
    background-color: #eee;
    color: #212529;
    .custom-close.isHovered,
    .custom-close:focus {
      background-color: darken(#eee, 15%);
    }
  }
  &.bg-yellow {
    background-color: yellow;
    color: #212529;
    .custom-close.isHovered,
    .custom-close:focus {
      background-color: darken(yellow, 15%);
    }
  }
  &.bg-gold {
    background-color: #ffc107;
    color: #212529;
    .custom-close.isHovered,
    .custom-close:focus {
      background-color: darken(#ffc107, 15%);
    }
  }
  &.bg-green {
    background-color: green;
    color: #fff;
    .custom-close.isHovered,
    .custom-close:focus {
      background-color: darken(green, 15%);
    }
  }
}

.icon {
  display: flex;
  align-items: center;
  padding: 2px 0 2px 6px;
  ::v-deep .fa-icon {
    vertical-align: inherit;
  }
}
.title {
  max-width: 240px;
  padding: 2px 6px;
  &.shortTitle {
    width: calc(100% - 34px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media (max-width: 370px) {
    max-width: 170px;
  }
}
</style>

<style lang="scss">
.mg-tag.b-form-tag {
  margin-right: 0;
  .b-form-tag-remove {
    margin-left: 0;
    padding-right: 6px;
  }
}
</style>
