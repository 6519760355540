import api from "@/util/api";
import FileSaver from "file-saver";
import eventBus from "@/util/eventBus";
import store from "@/store";

export function textBreakdownExport(analytics) {
  let startDate = analytics.startDate.slice(0, 10),
    endDate = analytics.endDate.slice(0, 10);

  if (analytics.breakdownType === "teams") {
    let body = {
      start: analytics.startDate,
      end: analytics.endDate,
      departments: analytics.filteredTeams,
      sort: analytics.sort,
      name: analytics.search,
    };
    api.post(`/reporting/messages/teams/export`, body).then(function (teams) {
      var data = new Blob([teams], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(
        data,
        "sms_teams_breakdown_" + startDate + "_" + endDate + ".csv"
      );
    });
  } else if (analytics.breakdownType === "users") {
    let body = {
      start: analytics.startDate,
      end: analytics.endDate,
      departments: analytics.filteredTeams,
      sort: analytics.sort,
      name: analytics.search,
    };
    api.post(`/reporting/messages/users/export`, body).then(function (users) {
      var data = new Blob([users], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(
        data,
        "sms_users_breakdown_" + startDate + "_" + endDate + ".csv"
      );
    });
  } else if (analytics.breakdownType === "inboxes") {
    let body = {
      start: analytics.startDate,
      end: analytics.endDate,
      departments: analytics.filteredTeams,
      sort: analytics.sort,
      name: analytics.search,
    };
    api.post(`/reporting/messages/inbox/export`, body).then(function (inboxes) {
      var data = new Blob([inboxes], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(
        data,
        "sms_inboxes_breakdown_" + startDate + "_" + endDate + ".csv"
      );
    });
  } else if (analytics.breakdownType === "campaigns") {
    let body = {
      start: analytics.startDate,
      end: analytics.endDate,
      departments: analytics.filteredTeams,
      sort: analytics.sort,
      name: analytics.search,
    };
    api
      .post(`/reporting/messages/campaigns/export`, body)
      .then(function (campaigns) {
        var data = new Blob([campaigns], {
          type: "text/csv;charset=utf-8",
        });
        FileSaver.saveAs(
          data,
          "sms_campaigns_breakdown_" + startDate + "_" + endDate + ".csv"
        );
      });
  }
}

export function chatBreakdownExport(analytics) {
  let startDate = analytics.startDate.slice(0, 10),
    endDate = analytics.endDate.slice(0, 10);

  if (analytics.breakdownType === "teams") {
    let body = {
      start: analytics.startDate,
      end: analytics.endDate,
      teams: analytics.filteredTeams,
      sort: analytics.breakdownTeamsSort,
      name: analytics.breakdownSearch,
    };
    api.post(`/reporting/chat/teams/export`, body).then(function (teams) {
      var data = new Blob([teams], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(
        data,
        "chat_teams_breakdown_" + startDate + "_" + endDate + ".csv"
      );
    });
  } else if (analytics.breakdownType === "users") {
    let body = {
      start: analytics.startDate,
      end: analytics.endDate,
      teams: analytics.filteredTeams,
      sort: analytics.breakdownUsersSort,
      name: analytics.breakdownSearch,
    };
    api.post(`/reporting/chat/users/export`, body).then(function (users) {
      var data = new Blob([users], { type: "text/csv;charset=utf-8" });
      FileSaver.saveAs(
        data,
        "chat_users_breakdown_" + startDate + "_" + endDate + ".csv"
      );
    });
  }
}

export function textUnreadExport(context, analytics) {
  let startDate = analytics.startDate.slice(0, 10),
    endDate = analytics.endDate.slice(0, 10);
  let body = {
    start: analytics.startDate,
    end: analytics.endDate,
    departments: context.teamId ? context.teamId : analytics.filteredTeams,
    staffId: context.staffId || null,
    highspeedinboxid: context.highspeedinboxid || null,
    sharedinboxid: context.sharedinboxid || null,
  };
  api.post(`/reporting/messages/unread/export`, body).then(function (unread) {
    var data = new Blob([unread], {
      type: "text/csv;charset=utf-8",
    });
    let name = context.name.replace(/[\W_]+/g, "_").toLowerCase();
    FileSaver.saveAs(
      data,
      name + "_sms_unread_" + startDate + "_" + endDate + ".csv"
    );
  });
}

export function marketplaceMessageExport(exportDetails) {
  if (
    exportDetails.title === "Export Message Activity" &&
    !exportDetails.multiTeams
  ) {
    let body = {
      startdate: exportDetails.startDate,
      enddate: exportDetails.endDate,
    };
    api.post(`adminexports/activity`, body);
  } else if (
    exportDetails.title === "Export Message Activity" &&
    exportDetails.multiTeams
  ) {
    let body = {
      startdate: exportDetails.startDate,
      enddate: exportDetails.endDate,
      scope: "SelectedTeams",
      selectedTeams: exportDetails.selectedTeams,
    };
    api.post(`adminexports/activity`, body);
  } else if (exportDetails.title === "Export Undelivered Messages") {
    let body = {
      startdate: exportDetails.startDate,
      enddate: exportDetails.endDate,
    };
    api.post(`adminexports/undelivered`, body);
  } else if (exportDetails.title === "Export High Speed Message Activity") {
    let body = {
      startdate: exportDetails.startDate,
      enddate: exportDetails.endDate,
    };
    api.post(`adminexports/highspeed`, body);
  } else if (exportDetails.title === "Export Contact Records") {
    let body = {
      startdate: exportDetails.startDate,
      enddate: exportDetails.endDate,
    };
    api.post(`adminexports/students`, body);
  } else if (exportDetails.title === "Export Opt-Out History") {
    let body = {
      startdate: exportDetails.startDate,
      enddate: exportDetails.endDate,
    };
    api.post(`adminexports/optouts`, body);
  } else if (
    exportDetails.title === "Export Lead & Activity (Manual and Automated)"
  ) {
    let body = {
      startDate: exportDetails.startDate,
      endDate: exportDetails.endDate,
    };
    let title = exportDetails.title;
    return handleMarketplaceChatExport(body, title);
  }
}

async function handleMarketplaceChatExport(body, title) {
  const response = await api.post(
    `${store.state.webChat.bot.id}/hitl/export`,
    body
  );
  if (response) {
    eventBus.dispatchEvent("call-cancel-modals");
    eventBus.dispatchEvent(
      "call-success-toast",
      "You will receive an email when the " + title + " export is complete."
    );
  } else {
    eventBus.dispatchEvent(
      "call-no-results-message",
      "There are no results for the Cadence Chat Lead & Activity Export for the specified timeframe"
    );
  }
}
