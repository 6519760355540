<template>
  <div></div>
</template>

<script>
import store from "@/store";
import { ACTIONS } from "@/util/constants";

export default {
  name: "StoreInbox",
  store,
  props: {
    activeInbox: {
      required: true,
    },
  },
  created() {
    this.$store.dispatch(ACTIONS.UPDATE_ACTIVE_INBOX, this.activeInbox);
  },
};
</script>
