<template>
  <MgTag
    :title="name"
    :icon="'bullhorn-solid'"
    :bgColor="'blue'"
    :hover-close="true"
    :hover-close-active="hoverCloseActive"
    :no-remove-button="noRemoveButton"
    @remove="removeCampaign"
  ></MgTag>
</template>

<script>
import store from "@/store";
import { GETTERS } from "@/util/constants";
import MgTag from "@/components/MgTag";

export default {
  name: "CampaignTag",
  store,
  components: {
    MgTag,
  },
  props: {
    campaignId: {
      required: false,
    },
    campaignName: {
      required: false,
    },
    hoverCloseActive: {
      type: Boolean,
      default: false,
    },
    noRemoveButton: {
      type: Boolean,
    },
  },
  computed: {
    name() {
      return this.campaignId ? this.getCampaignNameById : this.campaignName;
    },
    getCampaignNameById() {
      return this.$store.getters[GETTERS.GET_CAMPAIGN_NAME_BY_ID](
        this.campaignId
      );
    },
  },
  methods: {
    removeCampaign() {
      this.$emit("remove-campaign");
    },
  },
};
</script>
