<template>
  <div class="d-flex">
    <div
      class="gravatar rounded-circle bot-icon-circle"
      v-if="source === 'Bot'"
      :title="`${botName ? botName : 'Bot'}`"
      :alt="`${botName ? botName : 'Bot'}`"
    >
      <MgIcon name="robot-circle" scale="1.4" class="bot-icon" />
    </div>
    <div class="d-flex" v-if="source === 'Agent'">
      <img
        v-if="userAvatar"
        :src="userAvatar"
        height="36"
        width="36"
        class="rounded-circle user-avatar-image"
        :title="name"
        :alt="name"
      />
      <div class="user-avatar-container">
        <div
          :title="name"
          :alt="name"
          class="d-flex align-items-center justify-content-center gravatar rounded-circle avatar-cornwall-background avatar-small"
        >
          <span class="letter">{{ name.slice(0, 1) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MgIcon from "@/components/MgIcon";

export default {
  name: "WebChatUserAvatar",
  components: { MgIcon },
  props: {
    source: {
      type: String,
      required: true,
    },
    botName: {
      type: String,
      required: true,
      default: "",
    },
    userAvatar: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

.user-avatar-container {
  min-height: 37px;
}

.user-avatar-image {
  position: absolute;
  z-index: 1000;
}

.letter {
  font-size: 18px;
  color: $avatar-black;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  padding-bottom: 1px;
}

.avatar-small {
  width: 36px;
  height: 36px;
}

.bot-icon-circle {
  width: 36px;
  height: 36px;
  background-color: $accent;
}

.bot-icon {
  position: relative;
  top: 8px;
  left: 7px;
  color: #fff;
}
</style>
