<template>
  <Chart
    :seriesColors="seriesColors"
    :style="{
      paddingTop: '1rem',
      display: 'flex',
      'justify-content': 'center',
      height: '16rem',
    }"
  >
    <Tooltip
      :color="'#243B54'"
      :background="'#F2F4F6'"
      :padding="padding"
      :render="'defaultTooltipRender'"
    >
      <template v-slot:defaultTooltipRender="{ props }">
        <span>
          <strong> {{ props.point.series.name }}: </strong>
          {{ props.point.value }}
        </span>
      </template>
    </Tooltip>

    <Y-Axis>
      <Y-AxisItems
        :major-grid-lines="hidden"
        :line="hidden.line"
        :labels="hidden"
      />
    </Y-Axis>
    <X-Axis>
      <X-AxisItems :major-grid-lines="hidden" :line="hidden.line" />
    </X-Axis>
    <ChartDataPoints>
      <ChartDataPointItems
        :name="requests.title"
        :type="'column'"
        :visibleInLegend="false"
        :stack="{ type: '100%' }"
        :data-items="[requests.value]"
      />
      <ChartDataPointItems
        :name="remainingConversations.title"
        :visibleInLegend="false"
        :type="'column'"
        :data-items="[remainingConversations.value]"
      />
    </ChartDataPoints>
  </Chart>
</template>

<script>
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTooltip,
} from "@progress/kendo-vue-charts";

export default {
  name: "StackedBarChart",
  components: {
    Chart,
    Tooltip: ChartTooltip,
    ChartDataPoints: ChartSeries,
    ChartDataPointItems: ChartSeriesItem,
    "Y-Axis": ChartValueAxis,
    "Y-AxisItems": ChartValueAxisItem,
    "X-Axis": ChartCategoryAxis,
    "X-AxisItems": ChartCategoryAxisItem,
  },
  props: {
    seriesColors: {
      type: Array,
      required: true,
      default: () => [],
    },

    totalConvoValue: {
      type: Number,
      required: false,
      default: 0,
    },
    requestedValue: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: function () {
    return {
      hidden: {
        visible: false,
        line: {
          width: 0,
        },
      },
      padding: 10,
    };
  },
  computed: {
    requests() {
      return {
        title: "Requested Conversations",
        value: this.requestedValue,
      };
    },
    remainingConversations() {
      return {
        title: "Remaining Conversations",
        value: this.totalConvoValue - this.requestedValue,
      };
    },
  },
};
</script>
<style></style>
