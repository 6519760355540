<template>
  <div class="popover-container">
    <button
      v-if="icon"
      class="popover-button-icon"
      @click="togglePopover"
      :id="popoverId"
      v-b-tooltip.hover="'Add ' + iconText"
    >
      <MgIcon :name="icon" class="popover-icon"></MgIcon>
      <span class="sr-only">Add {{ iconText }}</span>
    </button>
    <button
      v-if="label"
      class="popover-button-text"
      @click="togglePopover"
      :id="popoverId"
    >
      <MgIcon v-if="icon" :name="icon" class="popover-icon"></MgIcon>
      <span class="sr-only">Add {{ iconText }}</span>
      <div class="btn btn-xs btn-light fade-in">
        {{ label }}
      </div>
    </button>
    <BPopover
      :target="popoverId"
      :placement="placement"
      triggers="click, blur"
      :customClass="`search-popover`"
      ref="popover"
      :show.sync="isPopoverOpen"
    >
      <template v-slot:title>
        <div v-if="fixedHeader" class="popover-fixed-header">
          <span>{{ headerText }}</span>
          <button
            type="button"
            class="btn btn-light btn-sm"
            @click="headerAction()"
          >
            {{ headerActionText }}
          </button>
        </div>
        <BInputGroup>
          <template v-slot:prepend>
            <MgIcon
              class="d-flex align-self-center search-icon"
              name="search"
              scale=".75"
            />
          </template>
          <BFormInput
            class="search-input"
            type="search"
            placeholder="Search"
            autofocus
            v-model="search"
          >
          </BFormInput>
        </BInputGroup>
      </template>
      <div
        class="list-group"
        :style="
          fixedHeader ? { 'max-height': '225px' } : { 'max-height': '275px' }
        "
      >
        <button
          type="button"
          class="list-group-item list-group-item-action"
          @click="selectItem(i)"
          v-for="(i, index) in filterItems()"
          :key="index"
        >
          <span v-html="highlightText(i.name)"></span>
          <span v-if="i.description" class="text-truncate desc">{{
            i.description
          }}</span>
          <span v-if="i.message" class="text-truncate desc">{{
            i.message
          }}</span>
          <span v-if="showCreatedBy" class="text-truncate desc">
            - Created by {{ i.createdByName }}</span
          >
        </button>
      </div>
    </BPopover>
  </div>
</template>

<script>
import { BFormInput, BInputGroup, BPopover } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";
import { VBTooltip } from "bootstrap-vue";
import Vue from "vue";

Vue.component("BFormInput", BFormInput);
Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "PopoverSearch",
  props: {
    items: {
      type: Array,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    iconText: {
      type: String,
      required: false,
    },
    popoverId: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      required: false,
      default: "auto",
    },
    label: {
      type: String,
      required: false,
    },
    fixedHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    headerText: {
      type: String,
      required: false,
    },
    headerActionText: {
      type: String,
      required: false,
    },
    headerActionUrl: {
      type: String,
      required: false,
    },
    showCreatedBy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    BFormInput,
    BInputGroup,
    MgIcon,
    BPopover,
  },
  data() {
    return {
      search: "",
      isPopoverOpen: false,
    };
  },
  watch: {
    isPopoverOpen: function () {
      this.$emit("popover-state", this.isPopoverOpen);
    },
  },
  methods: {
    highlightText(matchedName) {
      if (this.search === "") {
        return matchedName;
      }
      const re = new RegExp(this.search, "gi");
      return matchedName.replace(re, `<strong>$&</strong>`);
    },
    filterItems() {
      return this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    onClose() {
      this.search = "";
      this.isPopoverOpen = false;
    },
    togglePopover(e) {
      e.preventDefault();
      this.$root.$emit("bv::hide::tooltip");
      this.isPopoverOpen = !this.isPopoverOpen;
    },
    selectItem(item) {
      this.$root.$emit("bv::hide::popover");
      this.$emit("item-picked", item);
      this.search = "";
    },
    headerAction() {
      if (!this.headerActionUrl) {
        this.$emit("header-action");
      } else {
        window.history.pushState({}, "", this.headerActionUrl);
        this.$emit("close-modal");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
.popover-container {
  display: inline;
}
.popover-button-icon {
  background-color: transparent;
  border: none;
  color: $text-muted;
  cursor: pointer;
  height: 28px;
  width: 28px;
  line-height: 1;
  margin: 5px;
  overflow: hidden;
  transition: 150ms ease-in-out;
  padding: 0;

  &:focus,
  &:hover {
    color: $text-color;
    transform: scale(1.1);
  }
}
.popover-button-text {
  background-color: transparent;
  border: none;
  color: $text-muted;
  cursor: pointer;
  line-height: 1;
  overflow: hidden;
  transition: 150ms ease-in-out;
  padding: 0;

  &:focus,
  &:hover {
    color: $text-color;
  }
}
.search-icon {
  ::v-deep svg {
    width: 15px;
  }
}
.search-input {
  border: none;
  padding-right: 0;
}
</style>
<style lang="scss">
@import "../../scss/utilities";
.search-popover.popover.b-popover {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  &.bs-popover-bottom {
    .arrow:after {
      border-bottom-color: $popover-header-bg;
    }
  }

  .popover-header {
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: uppercase;
    background-color: #fff;
  }

  .popover-body {
    overflow: auto;
    padding: 0;

    .list-group {
      margin: 0;
    }

    .list-group-item {
      border-left: 0;
      border-right: 0;

      &:first-child {
        border-top: 0;
      }
      &:last-child {
        border-bottom: 0;
      }
      &:hover {
        .desc {
          color: $dark-gray-subtitle;
        }
      }
    }

    .title {
      display: block;
      margin: 0;
      font-size: 14px;
    }

    .desc {
      color: $text-muted;
      display: block;
      font-size: 12px;
      font-weight: $font-weight-light;
    }
  }
}

.popover-fixed-header {
  background-color: #f2f2f3;
  border: 0;
  border-radius: 3px 3px 0 0;
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
  margin: 0 -0.75rem 0;
  padding: 10px 15px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fixed-header-max-height {
  max-height: 225px;
}
</style>
