<template>
  <div class="d-flex flex-column" :class="{ 'h-100': !showEmptyState }">
    <div class="page-header">
      <div
        class="page-title d-sm-flex justify-content-between align-items-center"
      >
        <div class="d-flex justify-content-between justify-content-sm-start">
          <h1 class="mr-3">Tags</h1>
          <a
            id="new-tag"
            href
            @click.prevent="promptNewTag()"
            class="btn btn-primary"
            >New Tag</a
          >
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column flex-fill overflow-auto page-content"
      :class="{ 'h-100': !showEmptyState }"
    >
      <div
        class="d-lg-flex flex-row flex-fill justify-content-end align-items-center mb-3"
      >
        <div class="sm-filter-row sm-filter-row-1">
          <div class="d-flex align-items-center filter-text">Filter by:</div>
          <FilterDropdown
            :options="[{ displayName: 'All Users', id: 0 }, ...staff_]"
            :selectedKeyValue="selectedUser_.id"
            keyPrefix="tag-filter-user-"
            keyField="id"
            labelField="displayName"
            @click="handleUserFilterClick"
          />
          <FilterDropdown
            :options="[
              { displayText: 'Active', value: false },
              { displayText: 'Archived', value: true },
            ]"
            :selectedKeyValue="archiveFilter_"
            keyPrefix="tag-filter-archive-"
            keyField="value"
            labelField="displayText"
            class="pl-0"
            @click="handleArchiveFilterClick"
          />
        </div>
        <div class="sm-filter-row sm-filter-row-2">
          <ServerSearch
            searchIconClass="search"
            cancelIconClass="times"
            placeholder="Search"
            elementKey="tag-search"
            :searchText="searchText_"
            @search="handleSearch"
          />
        </div>
      </div>
      <div
        class="h-100"
        :class="{
          'mh-0': rowData_.length, // don't ask me why this works
          card: initialLoad_,
        }"
        v-if="!showEmptyState"
      >
        <TagListGrid
          v-show="!initialLoad_"
          :rowData="rowData_"
          :defaultSkip="defaultSkip_"
          :defaultSort="defaultSort_"
          @data-state-change="handleGridDataStateChange"
        />
      </div>
      <div v-else>
        <EmptyState
          :image="'/images/icon_tag.svg'"
          :imageWidth="96"
          :imageAltText="'Tag icon'"
          :description="'Identify which contacts need further follow up, an additional action taken, or continued tracking.'"
          :buttonText="'New Tag'"
          @emptyStateAction="promptNewTag()"
        ></EmptyState>
      </div>
    </div>
    <AddEditTagModal
      :tagId="addEditTagId_"
      :tagName="addEditTagName_"
      :tagDescription="addEditTagDescription_"
      :saving="saving_"
      :show="showAddEditTagModal_"
      :errorMessage="addEditTagErrorMessage_"
      @new-tag-confirmed="handleNewTagConfirmed"
      @edit-tag-confirmed="handleEditTagConfirmed"
      @tag-cancelled="cancelModals()"
    />
    <ConfirmationModal
      modalId="deleteTag-confirm"
      :keyValue="deleteTagId_"
      :title="`Delete ${deleteTagName_}`"
      confirmButtonText="Delete"
      :saving="saving_"
      :show="showDeleteTagModal_"
      :errorMessage="deleteTagErrorMessage_"
      @confirmed="handleDeleteTagConfirmed"
      @cancelled="cancelModals()"
    >
      <h4 class="error-list">Are you sure you want to delete this tag?</h4>
      <ul class="list-bulleted">
        <li>This tag will be removed from all Messages and Contacts.</li>
      </ul>
    </ConfirmationModal>
  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import store from "@/store";
import api from "@/util/api";
import analytics from "@/util/analytics";
import { ACTIONS, API_ERROR_MESSAGES } from "@/util/constants";
import { ToastPlugin } from "bootstrap-vue";
import AddEditTagModal from "@/components/AddEditTagModal";
import eventBus from "@/util/eventBus";
import TagListGrid from "@/pages/tags/TagListGrid";
import ServerSearch from "@/components/ServerSearch";
import FilterDropdown from "@/components/FilterDropdown";
import ConfirmationModal from "@/components/ConfirmationModal";
import Toast from "@/components/Toast";
import EmptyState from "@/components/EmptyState";

Vue.use(ToastPlugin);

export default {
  name: "TagListPage",
  store,
  components: {
    TagListGrid,
    ServerSearch,
    AddEditTagModal,
    FilterDropdown,
    EmptyState,
    ConfirmationModal,
  },
  props: {
    fromDetail: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      addEditTagDescription_: "",
      addEditTagErrorMessage_: [],
      addEditTagId_: 0,
      addEditTagName_: "",
      archiveFilter_: false,
      completeRowData_: [],
      defaultSkip_: 0,
      defaultSort_: [],
      deleteTagErrorMessage_: [],
      deleteTagId_: 0,
      deleteTagName_: "",
      filters_: {},
      initialLoad_: false,
      loading_: false,
      rowData_: [],
      saving_: false,
      searchText_: "",
      selectedUser_: { displayName: "All Users", id: 0 },
      showAddEditTagModal_: false,
      showDeleteTagModal_: false,
      staff_: [],
    };
  },
  computed: {
    showEmptyState() {
      let filteredByAllOrCurrentUser =
        this.selectedUser_.id === 0 ||
        this.selectedUser_.id === this.$store.state.config.userId;

      if (
        !this.initialLoad_ &&
        !this.loading_ &&
        !this.archiveFilter_ &&
        !this.searchText_ &&
        this.rowData_.length === 0 &&
        filteredByAllOrCurrentUser
      )
        return true;

      return false;
    },
  },
  watch: {
    archiveFilter_(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.storeFilters();
        this.refreshGrid();
      }
    },
    selectedUser_(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.storeFilters();
        this.rowData_ = this.filterTagsByOwner(this.completeRowData_);
      }
    },
    showAddEditTagModal_(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.addEditTagErrorMessage_ = [];
      }
    },
    showDeleteTagModal_(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.deleteTagErrorMessage_ = [];
      }
    },
  },
  async created() {
    try {
      this.initialLoad_ = true;
      this.filters_ = this.getFilters();
      this.selectedUser_ = this.filters_.selectedUser;
      this.archiveFilter_ = this.filters_.archiveFilter;
      this.defaultSkip_ = this.filters_.defaultSkip;
      this.defaultSort_ = this.filters_.defaultSort;

      // only want to keep their search if they're navigating back from the tag detail page
      if (this.fromDetail) {
        this.searchText_ = this.filters_.searchText;
      }
      const results = await Promise.all([this.fetchTags(), this.fetchStaff()]);
      this.completeRowData_ = results[0];
      this.staff_ = results[1].sort((userA, userB) =>
        userA.displayName.toUpperCase() > userB.displayName.toUpperCase()
          ? 1
          : -1
      );
      if (!this.$store.state.tag.tagSearch) {
        this.selectedUser_ = this.staff_.find(
          (user) => user.id === this.$store.state.config.userId
        );
      }
      this.rowData_ = this.filterTagsByOwner(this.completeRowData_);
      this.initialLoad_ = false;

      // menu actions
      eventBus.addEventListener("view-tag", this.handleViewTag);
      eventBus.addEventListener("archive-tag", this.handleArchiveTag);
      eventBus.addEventListener("de-archive-tag", this.handleArchiveTag);
      eventBus.addEventListener("delete-tag", this.handleDeleteTag);
      eventBus.addEventListener("edit-tag", this.handleEditTag);
    } catch (err) {
      this.initialLoad_ = false;
      this.errorToast("There was an error loading the page.");
      return err;
    }
  },
  destroyed() {
    eventBus.removeEventListener("view-tag", this.handleViewTag);
    eventBus.removeEventListener("archive-tag", this.handleArchiveTag);
    eventBus.removeEventListener("de-archive-tag", this.handleArchiveTag);
    eventBus.removeEventListener("delete-tag", this.handleDeleteTag);
    eventBus.removeEventListener("edit-tag", this.handleEditTag);
  },
  methods: {
    // fetch and load
    fetchTags() {
      const options = {
        params: { archived: this.archiveFilter_, query: this.searchText_ },
      };
      const endpoint = this.searchText_ ? "tags/search" : "tags";
      const tagPromise = api.get(endpoint, options);
      return tagPromise;
    },
    async fetchStaff() {
      return api.get("staff");
    },
    async refreshGrid() {
      try {
        this.loading_ = true;
        const tags = await this.fetchTags();
        this.loading_ = false;
        this.completeRowData_ = tags;
        this.rowData_ = this.filterTagsByOwner(this.completeRowData_);
      } catch (err) {
        this.loading_ = false;
        this.errorToast("There was an error loading tags.");
        return err;
      }
    },

    // archive or unarchive tag
    archiveTag(tagId) {
      return api.put(`tags/${tagId}`, { archive: !this.archiveFilter_ });
    },

    // delete tag
    promptDeleteTag(tagId) {
      const tagToDelete = this.rowData_.find((tag) => tag.id === tagId);
      this.deleteTagId_ = tagToDelete.id;
      this.deleteTagName_ = tagToDelete.name;
      this.showDeleteTagModal_ = true;
    },
    async deleteTagConfirmed(tagId) {
      try {
        this.saving_ = true;
        await api.delete(`tags/${tagId}`);
        this.$store.dispatch(ACTIONS.GET_TAGS);
        this.saving_ = false;
        this.showDeleteTagModal_ = false;
        analytics.track("Deleted Tag");
        this.refreshGrid();
      } catch (err) {
        this.saving_ = false;
        this.deleteTagErrorMessage_ = [
          "There was an error deleting the tag.",
          "",
        ];
        return err;
      }
    },

    // new tag
    promptNewTag() {
      this.addEditTagId_ = 0;
      this.addEditTagName_ = "";
      this.addEditTagDescription_ = "";
      this.showAddEditTagModal_ = true;
      analytics.track("Clicked New Tag from Tag List Page");
    },
    async newTagConfirmed(tagName, tagDescription) {
      try {
        this.saving_ = true;
        await api.post(`tags`, {
          name: tagName,
          description: tagDescription,
        });
        this.$store.dispatch(ACTIONS.GET_TAGS);
        this.saving_ = false;
        this.showAddEditTagModal_ = false;
        this.successToast("Your tag was created successfully!");
        analytics.track("Created Tag", { "Created Source": "Tag List Page" });
        this.refreshGrid();
      } catch (err) {
        const response = _.get(err, "response.data");
        this.saving_ = false;
        if (response === API_ERROR_MESSAGES.DUPLICATE_TAG) {
          this.addEditTagErrorMessage_ = [
            "There is already a tag with that name.",
            "Please choose a different name.",
          ];
        } else {
          this.addEditTagErrorMessage_ = [
            "There was an error creating the tag.",
            "",
          ];
        }
      }
    },

    // edit tag
    promptEditTag(tagId, tagName, tagDescription) {
      this.addEditTagId_ = tagId;
      this.addEditTagName_ = tagName;
      this.addEditTagDescription_ = tagDescription;
      this.showAddEditTagModal_ = true;
    },
    async editTagConfirmed(tagId, tagName, tagDescription) {
      try {
        this.saving_ = true;
        await api.put(`tags/${tagId}`, {
          name: tagName,
          description: tagDescription,
        });
        this.$store.dispatch(ACTIONS.GET_TAGS);
        this.saving_ = false;
        this.showAddEditTagModal_ = false;
        this.successToast("Your tag was successfully edited.");
        this.refreshGrid();
      } catch (err) {
        const response = _.get(err, "response.data");
        this.saving_ = false;
        if (response === API_ERROR_MESSAGES.DUPLICATE_TAG) {
          this.addEditTagErrorMessage_ = [
            "There is already a tag with that name.",
            "Please choose a different name.",
          ];
        } else {
          this.addEditTagErrorMessage_ = [
            "There was an error editing the tag.",
            "",
          ];
        }
      }
    },

    // cancel modal action
    cancelModals() {
      this.showAddEditTagModal_ = false;
      this.showDeleteTagModal_ = false;
    },

    // filter by user
    filterTagsByOwner(rowData) {
      if (!this.selectedUser_.id) {
        return rowData;
      }

      return rowData.filter(
        function (row) {
          return row.createdBy === this.selectedUser_.id;
        }.bind(this)
      );
    },

    // menu handlers (via eventBus)
    handleViewTag(event) {
      // have to ask Angular for routing
      this.$emit("viewTag", { tagId: event.detail.tagId });
    },
    handleEditTag(event) {
      const tagToEdit = this.rowData_.find(
        (tag) => tag.id === event.detail.tagId
      );

      this.promptEditTag(tagToEdit.id, tagToEdit.name, tagToEdit.description);
    },
    async handleArchiveTag(event) {
      try {
        await this.archiveTag(event.detail.tagId);
        this.$store.dispatch(ACTIONS.GET_TAGS);
        this.refreshGrid();
      } catch (err) {
        this.errorToast(
          `There was an error ${
            this.archiveFilter_ ? "un" : ""
          }archiving the tag.`
        );
        return err;
      }
    },
    handleDeleteTag(event) {
      this.promptDeleteTag(event.detail.tagId);
    },

    // page handlers
    handleSearch(data) {
      this.searchText_ = data.searchText;
      this.storeFilters();
      this.refreshGrid();
    },
    async handleEditTagConfirmed(data) {
      await this.editTagConfirmed(
        data.tagId,
        data.tagName,
        data.tagDescription
      );
    },
    async handleNewTagConfirmed(data) {
      await this.newTagConfirmed(data.tagName, data.tagDescription);
    },
    async handleDeleteTagConfirmed(data) {
      await this.deleteTagConfirmed(data.value);
    },
    handleArchiveFilterClick(data) {
      this.archiveFilter_ = data.selectedOption.value;
    },
    handleUserFilterClick(data) {
      this.selectedUser_ = data.selectedOption;
    },
    handleGridDataStateChange(data) {
      this.defaultSort_ = data.sort;
      this.defaultSkip_ = data.skip;
      this.storeFilters();
    },

    // util
    errorToast(message) {
      let toastBody = this.$createElement(Toast, {
        props: {
          message,
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "danger",
        noCloseButton: true,
        solid: true,
      });
    },
    successToast(message) {
      let toastBody = this.$createElement(Toast, {
        props: {
          message,
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "success",
        noCloseButton: true,
        solid: true,
        autoHideDelay: 3000,
      });
    },
    getFilters() {
      return (
        this.$store.state.tag.tagSearch || {
          selectedUser: this.selectedUser_,
          archiveFilter: this.archiveFilter_,
          searchText: this.searchText_,
          defaultSkip: this.defaultSkip_,
          defaultSort: this.defaultSort_,
        }
      );
    },
    storeFilters() {
      this.$store.dispatch(ACTIONS.UPDATE_TAG_LIST_FILTER, {
        selectedUser: this.selectedUser_,
        archiveFilter: this.archiveFilter_,
        searchText: this.searchText_,
        defaultSkip: this.defaultSkip_,
        defaultSort: this.defaultSort_,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.filter-text {
  color: $gray;
}

.sm-filter-row {
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
  }

  &.sm-filter-row-1 {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &.sm-filter-row-2 {
    @include media-breakpoint-up(lg) {
      position: relative;
      min-width: 35%;
    }
  }
}
</style>
