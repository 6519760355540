<template>
  <BModal
    no-close-on-backdrop
    :id="modalId"
    :title="fullName"
    :static="true"
    title-tag="h3"
    @close="cancel()"
  >
    <BForm class="d-flex flex-column">
      <BFormGroup>
        <template #label>
          <strong class="field-name">First Name</strong>
        </template>
        <BFormInput
          @focus="focus_ = true"
          @blur="focus_ = false"
          v-model="firstName"
          data-testid="first-name-input"
        ></BFormInput>
      </BFormGroup>
      <BFormGroup>
        <template #label>
          <strong class="field-name">Last Name</strong>
        </template>
        <BFormInput
          @focus="focus_ = true"
          @blur="focus_ = false"
          v-model="lastName"
          data-testid="last-name-input"
        ></BFormInput>
      </BFormGroup>
      <div class="d-flex flex-row">
        <BFormGroup class="w-75">
          <template #label>
            <strong class="field-name">City</strong>
          </template>
          <BFormInput
            @focus="focus_ = true"
            @blur="focus_ = false"
            v-model="city"
            data-testid="city-input"
          ></BFormInput>
        </BFormGroup>
        <BFormGroup class="w-25 region-dropdown-container">
          <template #label>
            <strong class="field-name">State/Region</strong>
          </template>
          <MgDropdown
            class="w-100"
            :options="regions"
            :selectedKeyValue="regionCode"
            keyPrefix="region-"
            keyField="regionCode"
            labelField="regionCode"
            menuAlignment="right"
            @click="selectRegion"
            ariaLabel="Select State or Region"
          />
        </BFormGroup>
      </div>
      <BFormGroup v-if="displayRegionName">
        <template #label>
          <strong class="field-name">Region Name</strong>
        </template>
        <BFormInput
          @focus="focus_ = true"
          @blur="focus_ = false"
          v-model="regionName"
          data-testid="region-name-input"
        ></BFormInput>
      </BFormGroup>
      <BFormGroup>
        <template #label>
          <strong class="field-name">Country</strong>
        </template>
        <BFormInput
          @focus="focus_ = true"
          @blur="focus_ = false"
          v-model="countryName"
          data-testid="country-name-input"
        ></BFormInput>
      </BFormGroup>
      <BFormGroup>
        <template #label>
          <strong class="field-name">Phone</strong>
        </template>
        <BFormInput
          @change="handleInput"
          maxLength="20"
          v-model="mobilePhone"
          data-testid="phone-input"
        ></BFormInput>
      </BFormGroup>
      <BFormGroup>
        <template #label>
          <strong class="field-name">Email</strong>
        </template>
        <BFormInput
          @focus="focus_ = true"
          @blur="focus_ = false"
          v-model="emailAddress"
          data-testid="email-input"
        ></BFormInput>
      </BFormGroup>
      <BFormGroup>
        <template #label>
          <strong class="field-name">Consent to Text</strong>
        </template>
        <BFormRadioGroup
          v-model="textOptIn"
          data-testid="consent-to-text-input"
          class="mt-2"
        >
          <BFormRadio :value="true">
            <span class="font-weight-normal">Yes</span>
          </BFormRadio>
          <BFormRadio :value="false">
            <span class="font-weight-normal">No</span>
          </BFormRadio>
        </BFormRadioGroup>
      </BFormGroup>
      <div v-for="customField in customFields" :key="customField.name">
        <BFormGroup>
          <template #label>
            <strong class="field-name">{{
              getCustomFieldName(customField.name)
            }}</strong>
          </template>
          <BFormInput
            v-model="customField.value"
            data-testid="student-type-input"
          ></BFormInput>
        </BFormGroup>
      </div>
    </BForm>
    <template v-slot:modal-footer>
      <div class="d-flex flex-row justify-content-between w-100">
        <BButton
          @click="update()"
          :disabled="!submitAllowed"
          type="submit"
          variant="primary"
          >Update</BButton
        >
        <BButton @click="cancel()" class="btn btn-light">Cancel</BButton>
      </div>
    </template>
  </BModal>
</template>
<script>
import {
  BForm,
  BModal,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BButton,
} from "bootstrap-vue";
import {
  visitorFirstName,
  visitorLastName,
  visitorFullName,
  visitorCity,
  visitorRegionCode,
  visitorCountry,
  visitorPhone,
  visitorEmail,
  visitorCustomFields,
  visitorConsentToText,
} from "@/util/webchat";
import MgDropdown from "@/components/MgDropdown";
import { formatPhoneNumber } from "@/util/phoneNumber";
import { US_REGION_LIST as regionList } from "@/util/constants";
import _ from "lodash";

export default {
  name: "WebChatFilterModal",
  components: {
    BForm,
    BModal,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    MgDropdown,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      city: "",
      countryName: "",
      regionCode: "",
      regionName: "",
      mobilePhone: null,
      emailAddress: "",
      customFields: [],
      textOptIn: null,
      focus_: false,
      displayRegionName: false,
    };
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    conversation: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    /* custom events emitted:
      - updated
      - cancelled
    */
  },
  created() {
    this.getDetails();
  },
  mounted() {
    this.$root.$on("bv::modal::hide", () => {
      this.cancel();
    });
  },
  unmounted() {
    this.clearDetails();
  },
  computed: {
    regions() {
      return regionList.concat([
        {
          regionCode: "Other",
          regionName: "",
        },
      ]);
    },
    fullName() {
      return visitorFullName(this.conversation) || "Visitor";
    },
    submitAllowed() {
      if (this.displayRegionName && this.regionName) {
        return true;
      } else if (this.displayRegionName && this.regionName === "") {
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.showModal();
      } else if (!newVal && oldVal) {
        this.hideModal();
      }
    },
  },
  methods: {
    getCustomFieldName(name) {
      let splitName = name.split("_");
      let capitalizedName = splitName.map((n) => _.capitalize(n));

      return capitalizedName.join(" ");
    },
    getDetails() {
      this.regionCode = visitorRegionCode(this.conversation);
      this.firstName = visitorFirstName(this.conversation);
      this.lastName = visitorLastName(this.conversation);
      this.city = visitorCity(this.conversation);
      this.countryName = visitorCountry(this.conversation);
      this.mobilePhone = formatPhoneNumber(
        visitorPhone(this.conversation)
      );
      this.emailAddress = visitorEmail(this.conversation);
      this.customFields = visitorCustomFields(this.conversation);
      this.textOptIn = visitorConsentToText(this.conversation);
    },
    handleInput(input) {
      let formattedNumber = formatPhoneNumber(input);
      this.mobilePhone = formattedNumber;
    },
    selectRegion(data) {
      let region = data.selectedOption;

      if (region.regionCode === "Other") {
        this.displayRegionName = true;
        this.regionCode = region.regionCode;
        this.regionName = region.regionName;
      } else {
        this.displayRegionName = false;
        this.regionCode = region.regionCode;
      }
    },
    update() {
      let visitorDetails = {};

      // backend requirement: add the timestamp for when user opts in or out of text
      if (this.textOptIn !== visitorConsentToText(this.conversation)) {
        visitorDetails["textOptInTimeStamp"] = new Date().toISOString();
      }

      // requirement: if other, regionName is required as it impacts what is displayed in the details sidemenu
      if (this.regionCode.toLowerCase() === "other") {
        visitorDetails["regionName"] = this.regionName;
      }

      // customFields in the database is an object with nested Objects
      let newCustomFields = {};
      this.customFields.forEach((field) => {
        // backend requirements: student_type, visitor_type, and date_of_birth need to be outside of custom fields when updating this endpoint
        if (field.name === "student_type" || field.name === "visitor_type" || field.name === "date_of_birth") {
          visitorDetails[_.camelCase(field.name)] = field.value;
        } else {
          newCustomFields[field.name] = field.value;
        }
      });

      visitorDetails["customFields"] = newCustomFields;
      visitorDetails["firstName"] = this.firstName;
      visitorDetails["lastName"] = this.lastName;
      visitorDetails["regionCode"] = this.regionCode;
      visitorDetails["countryName"] = this.countryName;
      visitorDetails["mobilePhone"] = this.mobilePhone;
      visitorDetails["emailAddress"] = this.emailAddress;
      visitorDetails["textOptIn"] = this.textOptIn;
      visitorDetails["city"] = this.city;

      this.$emit("updated", visitorDetails);
    },
    cancel() {
      this.$emit("cancelled");
    },
    showModal() {
      this.getDetails();
      this.$bvModal.show(this.modalId);
    },
    clearDetails() {
      this.firstName = "";
      this.lastName = "";
      this.regionCode = "";
      this.regionName = "";
      this.countryName = "";
      this.mobilePhone = null;
      this.emailAddress = "";
      this.customFields = [];
      this.textOptIn = false;
      this.city = "";
    },
    hideModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

::v-deep fieldset {
  box-shadow: none;
  -webkit-box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

::v-deep .dropdown-toggle {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $gray-900;
  border-color: #d9d9d9;
  font-size: 0.8rem;
  &:hover {
    background-color: #e0e0e0;
  }
}

::v-deep .dropdown-menu.show {
  min-width: 100%;
  @include media-breakpoint-up(xl) {
    min-width: 70%;
  }
}

::v-deep .dropdown-item {
  &:hover {
    background-color: #e0e0e0;
  }
}
.dropDownSelected {
  ::v-deep .dropdown-toggle {
    background-color: #e0e0e0;
  }
}
.isSelectedRegion {
  background-color: #e0e0e0;
  border-color: #d9d9d9;
  .dropdown-item {
    background-color: #e0e0e0;
    border-color: #d9d9d9;
    border-radius: 0;
  }
}
</style>
