<template>
  <div
    class="message-meta d-flex align-items-center"
    :class="{
      'flex-row-reverse': isOutgoing,
    }"
  >
    <div class="message-meta-source">
      <span class="sender">
        <span v-show="isOutgoing && sender">
          <span v-show="showInboxName">{{ item.inboxName }} &bull; </span>
          <span v-html="sender"></span>
        </span>
        <span v-show="isIncoming">
          <span v-show="showInboxName">{{ item.inboxName }} &bull; </span>
          <strong>{{ contactFirst }} {{ contactLast }}</strong>
        </span>
      </span>

      <span v-if="!hasTimeline">
        &bull; {{ item.timestamp | dateTimeShorthand() }}</span
      >
      <span v-if="item.error || item.cancelled">
        <span> &bull; </span>
        <span
          class="message-error"
          v-b-tooltip.hover.left="{
            title: item.error || 'Cancelled prior to delivery',
          }"
        >
          <MgIcon name="exclamation-circle" scale=".8125"></MgIcon
          >&nbsp;Not&nbsp;delivered
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import MgIcon from "@/components/MgIcon";
import { VBTooltip } from "bootstrap-vue";
import {
  INBOX_TYPES,
  MESSAGE_DIRECTIONS,
  HISTORY_MESSAGE_TYPES,
} from "@/util/constants";

Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "HistoryMessageMeta",
  components: {
    MgIcon,
  },
  data() {
    return {
      isIncoming:
        this.item.direction.toLowerCase() === MESSAGE_DIRECTIONS.INCOMING,
      isOutgoing:
        this.item.direction.toLowerCase() === MESSAGE_DIRECTIONS.OUTGOING,
    };
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    hasTimeline: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    showInboxName() {
      // Only if not filtered? (when that is available)
      return this.item.inboxType.toLowerCase() !== INBOX_TYPES.PERSONAL;
    },
    sender() {
      let source = "";
      let inboxType = this.item.inboxType.toLowerCase();

      if (
        this.item.outgoingMessageType ===
        HISTORY_MESSAGE_TYPES.KEYWORD_AUTO_REPLY
      ) {
        source = `Auto Reply`;
      } else {
        if (
          this.item.outgoingMessageType === HISTORY_MESSAGE_TYPES.AUTO_REPLY
        ) {
          source = `Auto Reply from <strong>${this.getSenderName()}</strong>`;
        } else if (
          this.item.outgoingMessageType ===
            HISTORY_MESSAGE_TYPES.OUT_OF_OFFICE &&
          inboxType === INBOX_TYPES.PERSONAL
        ) {
          source = `Out of Office from <strong>${this.getSenderName()}</strong>`;
        } else if (
          this.item.outgoingMessageType === HISTORY_MESSAGE_TYPES.QUESTION
        ) {
          source = `<strong>${this.getSenderName()}</strong>`;
        } else if (
          inboxType !== INBOX_TYPES.PERSONAL ||
          (inboxType === INBOX_TYPES.PERSONAL &&
            this.item.staffId !== this.item.senderId)
        ) {
          source = `<strong>${this.getSenderName()}</strong>`;
        }
      }

      return source;
    },
    contactFirst() {
      return this.$store.state.contact.details.firstName;
    },
    contactLast() {
      return this.$store.state.contact.details.lastName;
    },
  },
  methods: {
    getSenderName() {
      return this.item.staffName;
    },
  },
};
</script>
