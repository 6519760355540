const signalR = require("@microsoft/signalr");
const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;

signalR.connectToHub = (path) => {
  const token = window.localStorage.token;

  return new signalR.HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Information)
    .withUrl(`${apiBaseUrl}/${path}`, { accessTokenFactory: () => token })
    .configureLogging(signalR.LogLevel.Warning)
    .build();
};

export default signalR;
