<template>
  <div class="card">
    <div class="card-body has-empty-state">
      <div class="empty-state">
        <div
          class="d-flex flex-row align-items-center align-content-center justify-content-center"
        >
          <div class="empty-state-image">
            <img :width="imageWidth" :src="image" :alt="imageAltText" />
          </div>
          <div>
            <h2 v-if="headingText" class="empty-state-header-text">
              {{ headingText }}
            </h2>
            <div
              class="empty-state-text"
              v-if="description"
              v-html="description"
            ></div>
            <div class="empty-state-text" v-else><slot /></div>
            <div v-if="buttonText">
              <button
                href
                class="btn btn-primary"
                @click="emptyStateAction()"
                v-if="!buttonHref"
              >
                {{ buttonText }}
              </button>
              <a
                class="btn btn-primary"
                :href="buttonHref"
                @click="emptyStateAction()"
                v-else
              >
                {{ buttonText }}
              </a>
              &emsp;
              <a
                v-if="learnMoreLink"
                :href="learnMoreLink"
                class="text-weight-light"
                target="_blank"
                >Learn More</a
              >
            </div>
            <div v-else>
              <slot name="buttons" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
  props: {
    image: {
      type: String,
      required: true,
    },
    imageWidth: {
      type: Number,
      required: true,
    },
    imageAltText: {
      type: String,
      required: false,
    },
    headingText: {
      type: String,
      required: false,
      default: "",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    buttonText: {
      type: String,
      required: false,
      default: "",
    },
    buttonHref: {
      type: String,
      required: false,
      default: "",
    },
    learnMoreLink: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    emptyStateAction() {
      this.$emit("emptyStateAction");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
@import "src/scss/variables";

.empty-state {
  margin: $grid-gutter-width * 2 $grid-gutter-width;
}

.has-empty-state {
  background: #fafafa;
}

.empty-state-header-text {
  font-weight: $font-weight-normal;
  font-size: $font-size-lg;
}

.empty-state-text {
  font-size: $font-size-lg;
  font-weight: $font-weight-light;
  margin-bottom: $line-height-computed;

  h4,
  .h4 {
    font-weight: $font-weight-normal;
    margin-top: 0;
  }
}

.empty-state-image {
  margin: $line-height-computed $grid-gutter-width * 1.5 $line-height-computed 0;

  &.with-border {
    background: transparentize(#fff, 0.25);
    border: 1px solid $gray-lighter;
    border-radius: 3px;
    min-height: 90px;
    padding: $line-height-computed/2 $line-height-computed;
  }
}

.empty-state-subtext {
  font-style: italic;
  font-weight: 300;
  margin-bottom: 20px;
}
</style>
