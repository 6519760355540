<template>
  <div class="d-flex">
    <BDropdown ref="dropdown" class="date-dropdown" no-caret>
      <template #button-content>
        <div class="d-flex justify-content-between">
          <div d-flex>
            <MgIcon name="calendar-solid"></MgIcon>
            {{ displayDate }}
          </div>
          <div class="dropdown-toggle"></div>
        </div>
      </template>
      <BDropdownItem v-on:click="setTimeFrame('Last24Hours')"
        >Last 24 hours</BDropdownItem
      >
      <BDropdownItem v-on:click="setTimeFrame('Last7Days')"
        >Last 7 days</BDropdownItem
      >
      <BDropdownItem v-on:click="setTimeFrame('Last30Days')"
        >Last 30 days</BDropdownItem
      >
      <BDropdownItem v-on:click="setTimeFrame('Last3Months')"
        >Last 3 months</BDropdownItem
      >
      <BDropdownItem v-on:click="setTimeFrame('Last12Months')"
        >Last 12 months</BDropdownItem
      >
      <BDropdownDivider />
      <BDropdownText style="width: 240px">
        <DatePicker
          v-show="true"
          ref="datePicker"
          :mode="'range'"
          @date-picked="updateDateRange"
          :selected-date-range-value="sessionParams || defaultDateRange"
          :picker-only="true"
        >
          <template v-slot:picker-only-link-text>Custom...</template>
        </DatePicker>
      </BDropdownText>
    </BDropdown>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import MgIcon from "@/components/MgIcon";
import { ACTIONS } from "@/util/constants";
import { ACTIONS as CHAT_REPORT_ACTIONS } from "@/pages/reporting/chat/chatAnalyticsStore";
import {
  BDropdown,
  BDropdownDivider,
  BDropdownText,
  BDropdownItem,
} from "bootstrap-vue";
import store from "@/store";
import analytics from "@/util/analytics";

export default {
  name: "AnalyticsDatePicker",
  store,
  components: {
    DatePicker,
    BDropdown,
    BDropdownDivider,
    BDropdownText,
    BDropdownItem,
    MgIcon,
  },
  props: {
    analyticsType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      initialLoad: true,
      defaultDateRange: {},
      params: { start: "", end: "", timeInterval: "UnspecifiedTime" },
      daysDifference: null,
      timestamp: "",
      sessionParams: null,
      utcDate: { start: "", end: "" },
    };
  },
  mounted() {
    let today = new Date();
    this.defaultDateRange = {
      start: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 30
      ),
      end: today,
    };
    if (this.analyticsType === "chat") {
      this.sessionParams = JSON.parse(
        sessionStorage.getItem("chat-analytics-date-picker")
      );
    } else {
      this.sessionParams = JSON.parse(
        sessionStorage.getItem("text-analytics-date-picker")
      );
    }
  },
  computed: {
    teamId() {
      if (this.analyticsType === "chat") {
        return this.$store.state.chatAnalytics.teamId;
      } else {
        return this.$store.state.analytics.teamId;
      }
    },
    displayDate() {
      return (
        new Date(this.utcDate.start).toLocaleString("default", {
          month: "short",
        }) +
        " " +
        new Date(this.utcDate.start).getDate() +
        ", " +
        new Date(this.utcDate.start).getFullYear() +
        " - " +
        new Date(this.utcDate.end).toLocaleString("default", {
          month: "short",
        }) +
        " " +
        new Date(this.utcDate.end).getDate() +
        ", " +
        new Date(this.utcDate.end).getFullYear()
      );
    },
  },
  methods: {
    async setDefaultTimes() {
      let today = new Date();
      let prevDate = new Date();
      let defaultTimes = {};
      prevDate.setDate(prevDate.getDate() - 1);

      defaultTimes.day = {
        start: prevDate,
        end: today,
      };
      defaultTimes.week = {
        start: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 6
        ),
        end: today,
      };
      defaultTimes.month = {
        start: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 29
        ),
        end: today,
      };
      defaultTimes.quarter = {
        start: new Date(today.getFullYear(), today.getMonth() - 2, 1),
        end: today,
      };
      defaultTimes.year = {
        start: new Date(today.getFullYear(), today.getMonth() - 11, 1),
        end: today,
      };
      return defaultTimes;
    },
    trackInMixpanel(eventName) {
      if (this.initialLoad) {
        this.initialLoad = false;
      } else {
        analytics.track(eventName, {
          "Filter Interval": this.intervalDifference,
          "Filter Timespan": this.daysDifference,
        });
      }
    },
    setDate(selectedDate) {
      const startDate = new Date(selectedDate.start),
        endDate = new Date(selectedDate.end);

      this.params.start = startDate.toISOString().slice(0, -14);
      this.params.end = endDate.toISOString().slice(0, -14);
      this.utcDate.start = startDate.toISOString().slice(0, -8);
      this.utcDate.end = endDate.toISOString().slice(0, -8);
    },
    updateDateRange(selectedDate, range) {
      if (!range) {
        this.params.timeInterval = "UnspecifiedTime";
      }
      if (selectedDate) {
        this.$refs.dropdown.hide(true);
        this.setDate(selectedDate);

        if (this.initialLoad) {
          this.initialLoad = false;
        } else {
          let start = new Date(this.params.start);
          let end = new Date(this.params.end);

          // To calculate the time difference of two dates
          let Difference_In_Time = end.getTime() - start.getTime();

          // To calculate the no. of days between two dates
          let Difference_In_Days = Math.floor(
            Difference_In_Time / (1000 * 3600 * 24)
          );
          this.daysDifference = Difference_In_Days;

          analytics.track("Filtered Analytics by Time Period", {
            "Filter Interval": this.params.timeInterval,
            "Filter Timespan": this.daysDifference,
          });
        }

        if (this.analyticsType === "text") {
          sessionStorage.setItem(
            "text-analytics-date-picker",
            JSON.stringify(this.params)
          );

          let textType = JSON.parse(
            sessionStorage.getItem("analytics-text-type-filter")
          );

          if (textType) {
            this.params.hasMedia =
              textType.length > 1 ? null : textType.includes("MMS");
          }

          this.$store.dispatch(ACTIONS.GET_ANALYTICS_DATES, this.params);
          this.trackInMixpanel("Filtered Analytics by Time Period");
        } else if (this.analyticsType === "chat") {
          sessionStorage.setItem(
            "chat-analytics-date-picker",
            JSON.stringify(this.params)
          );
          this.$store.dispatch(
            CHAT_REPORT_ACTIONS.GET_FILTERED_REPORT_BY_DATES,
            this.params
          );
          this.$store.dispatch(CHAT_REPORT_ACTIONS.GET_HEATMAP, this.params);
          this.trackInMixpanel("Filtered Chat Analytics by Time Period");
        } else {
          this.$store.dispatch(ACTIONS.GET_INBOX_DETAILS_DATES, this.params);
          this.trackInMixpanel("Filtered Analytics by Time Period");
        }
      }
    },
    async setTimeFrame(timeFrame) {
      let defaultTimes = await this.setDefaultTimes();
      this.params.timeInterval = timeFrame;

      switch (timeFrame) {
        case "Last24Hours":
          this.updateDateRange(defaultTimes.day, timeFrame);
          break;
        case "Last7Days":
          this.updateDateRange(defaultTimes.week, timeFrame);
          break;
        case "Last30Days":
          this.updateDateRange(defaultTimes.month, timeFrame);
          break;
        case "Last3Months":
          this.updateDateRange(defaultTimes.quarter, timeFrame);
          break;
        case "Last12Months":
          this.updateDateRange(defaultTimes.year, timeFrame);
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
#analyticsBase {
  select {
    max-width: 30%;
    margin: 1% 0;
  }
  ::v-deep .date-picker {
    width: 210px;
  }
}

.page-content {
  padding-top: $grid-gutter-width/2;
}
.date-dropdown {
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  ::v-deep button {
    color: $text-color !important;
    background-color: #fff !important;
    border-color: $input-border !important;
    letter-spacing: 0;
    text-transform: capitalize;
    .icon {
      padding-right: 2px;
    }

    .dropdown-toggle::after {
      font-size: 0.875rem;
      position: relative;
      top: 1px;
    }
    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.6em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }
  .b-dropdown-text {
    font-weight: normal;
    cursor: pointer;
    &:hover,
    &:focus {
      color: #16181b;
      text-decoration: none;
      background-color: #e9ecef;
    }
  }
}
</style>
