<template>
  <div></div>
</template>

<script>
import store from "@/store";
import eventBus from "@/util/eventBus";
import appBus from "@/util/vuex/appBus";
import { ACTIONS } from "@/util/constants";

export default {
  name: "Store",
  store,
  props: {
    config: {
      required: true,
    },
  },
  created() {
    this.$store.dispatch(ACTIONS.GET_CONFIG, this.config);
    this.$store.dispatch(ACTIONS.GET_CAMPAIGNS);
    this.$store.dispatch(ACTIONS.GET_TAGS);
    this.$store.dispatch(ACTIONS.GET_USERS);
    appBus; // this needs to be initialized in case v2 needs to change state

    eventBus.addEventListener("campaignCreated", (event) => {
      this.$store.dispatch(ACTIONS.UPDATE_CAMPAIGNS, event.detail);
    });
  },
};
</script>
