// import { ACTIONS, GETTERS, MUTATIONS } from '@/util/constants';
import api from "@/util/api";
import getProfile from "@/util/profile";
import _ from "lodash";

export const ACTIONS = {
  GET_REPORT: "a/chatAnalytics/getReport",
  GET_FILTERED_REPORT_BY_DATES: "a/chatAnalytics/getFilteredReportByDates",
  GET_CURRENT_TEAM: "a/chatAnalytics/getCurrentTeam",
  CLEAR_REPORT: "a/chatAnalytics/clearReport",
  GET_METRICS_FILTER: "a/chatAnalytics/getMetricsFilter",
  GET_TEAMS: "a/chatAnalytics/getTeams",
  GET_TEAM_ID: "a/chatAnalytics/getTeamId",
  GET_HEATMAP: "a/chatAnalytics/getHeatMap",
  GET_ALL_DATA: "a/chatAnalytics/getAllData",
  GET_BREAKDOWN: "a/chatAnalytics/getBreakdown",
};

export const GETTERS = {
  TEAM_NAME_BY_ID: "g/chatAnalytics/teamNameById",
};

export const MUTATIONS = {
  SET_REPORT: "m/chatAnalytics/setReport",
  SET_REPORT_INTERVAL: "m/chatAnalytics/setReportInterval",
  SET_REPORT_DATES: "m/chatAnalytics/setReportDates",
  SET_CURRENT_TEAM_NAME: "m/chatAnalytics/setCurrentTeamName",
  SET_METRICS_FILTER: "m/chatAnalytics/setMetricsFilter",
  SET_TEAMS: "m/chatAnalytics/setTeams",
  SET_TEAM_ID: "m/chatAnalytics/setTeamId",
  SET_HEATMAP: "m/chatAnalytics/setHeatMap",
  SET_HEATMAP_FILTER: "m/chatAnalytics/setHeatmapFilter",
  SET_TEAMS_BREAKDOWN: "m/chatAnalytics/setTeamsBreakdown",
  SET_USERS_BREAKDOWN: "m/chatAnalytics/setUsersBreakdown",
  SET_BREAKDOWN_TYPE: "m/chatAnalytics/setBreakdownType",
  SET_BREAKDOWN_SORT: "m/chatAnalytics/setBreakdownSort",
  SET_BREAKDOWN_SKIP: "m/chatAnalytics/setBreakdownSkip",
  RESET_BREAKDOWN_SKIP: "m/chatAnalytics/resetBreakdownSkip",
  SET_BREAKDOWN_SEARCH: "m/chatAnalytics/setBreakdownSearch",
};

export default {
  state: () => ({
    metrics: [],
    series: {},
    documents: [],
    paging: {},
    startDate: "",
    endDate: "",
    skipDays: 0,
    timeInterval: "",
    interval: "day",
    currentTeamName: "",
    chatTeams: [],
    teamId: null,
    filteredTeams: [],

    // heat map
    heatMap: [],
    heatMapConversationType: "",
    heatMapFollowupRequested: null,

    // breakdown
    teamsBreakdown: [],
    usersBreakdown: [],
    breakdownSkip: 0,
    breakdownTake: 10,
    breakdownTeamsSort: "total desc",
    breakdownUsersSort: "liveChatsHosted desc",
    breakdownSearch: "",
    breakdownType: "teams",
  }),
  actions: {
    async [ACTIONS.GET_REPORT]({ state, commit }) {
      let body = {
        teams: state.filteredTeams,
        timeInterval: state.timeInterval,
        take: 0,
        skip: state.skipDays,
        start: state.startDate,
        end: state.endDate,
      };
      let chatReport = await api.post(`reporting/chat`, body);
      return commit(MUTATIONS.SET_REPORT, chatReport);
    },
    async [ACTIONS.GET_HEATMAP]({ state, commit }) {
      let body = {
        teams: state.filteredTeams,
        start: state.startDate,
        end: state.endDate,
      };

      if (state.heatMapConversationType) {
        body = { ...body, conversationType: state.heatMapConversationType };
      }

      if (state.heatMapFollowupRequested) {
        body = { ...body, followupRequested: state.heatMapFollowupRequested };
      }

      let heatMap = await api.post(`reporting/chat/timeline`, body);
      return commit(MUTATIONS.SET_HEATMAP, heatMap);
    },
    async [ACTIONS.GET_BREAKDOWN]({ state, commit }) {
      let sort;

      switch (state.breakdownType) {
        case "teams":
          sort = state.breakdownTeamsSort;
          break;
        case "users":
          sort = state.breakdownUsersSort;
          break;
      }

      let body = {
        skip: state.breakdownSkip,
        take: state.breakdownTake,
        sort: sort,
        start: state.startDate,
        end: state.endDate,
        teams: state.filteredTeams,
        name: state.breakdownSearch,
      };

      if (state.breakdownType === "teams") {
        let teamsBreakdown = await api.post(`/reporting/chat/teams`, body);
        return commit(MUTATIONS.SET_TEAMS_BREAKDOWN, teamsBreakdown);
      } else if (state.breakdownType === "users") {
        let usersBreakdown = await api.post(`/reporting/chat/users`, body);
        return commit(MUTATIONS.SET_USERS_BREAKDOWN, usersBreakdown);
      }
    },
    async [ACTIONS.GET_ALL_DATA]({ dispatch }) {
      dispatch(ACTIONS.GET_REPORT);
      dispatch(ACTIONS.GET_HEATMAP);
      dispatch(ACTIONS.GET_BREAKDOWN);
    },
    [ACTIONS.GET_METRICS_FILTER]({ commit, dispatch }, filter) {
      commit(MUTATIONS.SET_METRICS_FILTER, filter);
      dispatch(ACTIONS.GET_ALL_DATA);
    },
    [ACTIONS.GET_TEAM_ID]({ commit }) {
      let profile = JSON.parse(window.localStorage.profile);
      commit(MUTATIONS.SET_TEAM_ID, profile.currentDepartment.id);
    },
    [ACTIONS.GET_FILTERED_REPORT_BY_DATES]({ commit, dispatch }, params) {
      commit(MUTATIONS.SET_REPORT_DATES, params);
      dispatch(ACTIONS.GET_ALL_DATA);
    },
    [ACTIONS.GET_CURRENT_TEAM]({ commit }) {
      let profile = getProfile();
      commit(MUTATIONS.SET_CURRENT_TEAM_NAME, profile.currentDepartment.name);
    },
    [ACTIONS.CLEAR_REPORT]({ commit }) {
      commit(MUTATIONS.SET_REPORT, { metrics: [], series: {} });
    },
    [ACTIONS.GET_TEAMS]({ commit }) {
      let chatTeams = JSON.parse(window.localStorage.profile);
      chatTeams = _.filter(chatTeams.departments, (d) => d.teamHasChat);
      commit(MUTATIONS.SET_TEAMS, chatTeams);
    },
  },

  getters: {
    [GETTERS.TEAM_NAME_BY_ID]: (state) => (id) => {
      if (!state.chatTeams) return;
      let team = state.chatTeams.find((team) => team.id === id);
      return team ? team.name : "";
    },
  },

  mutations: {
    [MUTATIONS.SET_METRICS_FILTER](state, filter) {
      state.filteredTeams = filter;
    },
    [MUTATIONS.SET_TEAMS](state, teams) {
      state.chatTeams = teams;
    },
    [MUTATIONS.SET_TEAM_ID](state, team) {
      state.teamId = team;
      state.filteredTeams = [team];
    },
    [MUTATIONS.SET_REPORT](state, chatReport) {
      if (!chatReport) return;
      state.metrics = chatReport.metrics;
      state.series = chatReport.series;
    },
    [MUTATIONS.SET_REPORT_INTERVAL](state, timeInterval) {
      if (!timeInterval) return;
      state.timeInterval = timeInterval;
    },
    [MUTATIONS.SET_REPORT_DATES](state, params) {
      if (!params) return;
      state.startDate = params.start;
      state.endDate = params.end;
      state.timeInterval = params.timeInterval;
    },
    [MUTATIONS.SET_CURRENT_TEAM_NAME](state, name) {
      state.currentTeamName = name;
    },
    [MUTATIONS.SET_HEATMAP](state, heatMap) {
      if (!heatMap) return;
      state.heatMap = heatMap;
    },
    [MUTATIONS.SET_HEATMAP_FILTER](state, filter) {
      state.heatMapConversationType = filter.conversationType;
      state.heatMapFollowupRequested = filter.followupRequested;
    },
    [MUTATIONS.SET_TEAMS_BREAKDOWN](state, teamsBreakdown) {
      state.teamsBreakdown = teamsBreakdown;
    },
    [MUTATIONS.SET_USERS_BREAKDOWN](state, usersBreakdown) {
      state.usersBreakdown = usersBreakdown;
    },
    [MUTATIONS.SET_BREAKDOWN_TYPE](state, type) {
      state.breakdownType = type;
    },
    [MUTATIONS.SET_BREAKDOWN_SORT](state, { teamsSort, usersSort }) {
      state.breakdownTeamsSort = teamsSort;
      state.breakdownUsersSort = usersSort;
    },
    [MUTATIONS.SET_BREAKDOWN_SKIP](state, skip) {
      state.breakdownSkip = skip;
    },
    [MUTATIONS.RESET_BREAKDOWN_SKIP](state) {
      state.breakdownSkip = 0;
    },
    [MUTATIONS.SET_BREAKDOWN_SEARCH](state, search) {
      state.breakdownSearch = search;
    },
  },
};
