<template>
  <div>
    <b-modal
      id="lockModal"
      hide-header
      hide-footer
      size="sm"
      no-close-on-backdrop
      no-close-on-esc
      @shown="lockTimeoutModalShown"
    >
      <div class="text-center">
        <h3 class="my-4">
          <span class="emoji" :inner-html.prop="'👋' | twemoji"></span>
          Are you still here?
        </h3>
        <p>
          While you have this conversation locked, others can&rsquo;t respond.
        </p>
        <p class="h3 my-4">{{ timeRemaining }}</p>
        <button
          @click="userLockConfirmed()"
          class="btn btn-lg btn-primary mb-3"
        >
          I&rsquo;m still here
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { ModalPlugin } from "bootstrap-vue";
import moment from "moment";
import store from "@/store";
import { ACTIONS } from "@/util/constants";
import analytics from "@/util/analytics";
import eventBus from "@/util/eventBus";

Vue.use(ModalPlugin);

const idleTimeAllowedSeconds = 120;
const modalTimeSeconds = 30;
const idleCheckIntervalSeconds = 10;
let initialLoad = true;
let lockedCountDownTimeout;
let monitorLockInterval;
let lastActivityUtc;

export default {
  name: "LockModal",
  store,
  data() {
    return {
      timeRemaining: null,
    };
  },
  props: ["conversation"],
  created() {
    eventBus.addEventListener("isReplying", this.isReplying);
    eventBus.addEventListener("lockConversation", this.lockConversation);
  },
  destroyed() {
    eventBus.removeEventListener("isReplying", this.isReplying);
    eventBus.removeEventListener("lockConversation", this.lockConversation);
    if (monitorLockInterval) {
      clearInterval(monitorLockInterval);
    }
  },
  computed: {
    userId() {
      return this.$store.state.config.userId;
    },
  },
  methods: {
    lockConversation() {
      this.$store.dispatch(ACTIONS.LOCK_CONVERSATION).then((response) => {
        lastActivityUtc = response.lockDateUtc;
        this.monitorLock();
      });
    },
    monitorLock() {
      if (monitorLockInterval) {
        clearInterval(monitorLockInterval);
      }

      const tick = () => {
        const currentTimeUtc = moment()
          .utc()
          .add(store.state.config.serverTimeOffset, "ms");
        const timeSinceLastActivity = moment
          .duration(currentTimeUtc.diff(moment.utc(lastActivityUtc)))
          .asSeconds();
        const timeSinceLockDate = moment
          .duration(
            currentTimeUtc.diff(moment.utc(this.conversation.state.lockDate))
          )
          .asSeconds();
        const updateConversationLock =
          timeSinceLockDate > idleTimeAllowedSeconds &&
          timeSinceLastActivity < idleTimeAllowedSeconds;
        const userIdle = timeSinceLastActivity > idleTimeAllowedSeconds;

        if (updateConversationLock) {
          this.$store.dispatch(ACTIONS.LOCK_CONVERSATION, true);
        }
        if (userIdle) {
          this.showLockTimeoutModal();
          clearInterval(monitorLockInterval);
        }
      };

      monitorLockInterval = setInterval(tick, idleCheckIntervalSeconds * 1000);
    },
    lockTimeoutModalShown() {
      if (lockedCountDownTimeout) {
        clearTimeout(lockedCountDownTimeout);
      }

      this.timeRemaining = modalTimeSeconds;

      const tick = () => {
        if (this.timeRemaining > 0) {
          this.timeRemaining -= 1;
          lockedCountDownTimeout = setTimeout(tick, 1000);
        } else {
          this.timeRemaining = 0;
          this.$bvModal.hide("lockModal");
          this.$store.dispatch(ACTIONS.UNLOCK_CONVERSATION);
          analytics.track("Kicked out of Conversation");
        }
      };

      lockedCountDownTimeout = setTimeout(tick, 1000);
    },
    isReplying() {
      lastActivityUtc = moment.utc();
    },
    showLockTimeoutModal() {
      this.$bvModal.show("lockModal");
    },
    userLockConfirmed() {
      if (lockedCountDownTimeout) {
        clearTimeout(lockedCountDownTimeout);
      }
      this.$bvModal.hide("lockModal");
      this.$store.dispatch(ACTIONS.LOCK_CONVERSATION).then((response) => {
        lastActivityUtc = response.lockDateUtc;
        this.monitorLock();
      });
      analytics.track("Clicked Still Here in Conversation");
    },
  },
  watch: {
    conversation(newValue) {
      if (
        newValue &&
        newValue.state &&
        newValue.state.lockedForStaffId === this.userId
      ) {
        if (initialLoad) {
          this.monitorLock();
          lastActivityUtc = newValue.state.lockDate
            ? moment.utc(newValue.state.lockDate)
            : moment.utc();
          initialLoad = false;
        }
      } else {
        if (monitorLockInterval) {
          this.$bvModal.hide("lockModal");
          clearInterval(monitorLockInterval);
          lastActivityUtc = null;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";

#lockModal {
  .emoji ::v-deep img {
    height: 28px;
    margin: -4px 5px -2px 0;
    width: auto;
  }
}
</style>
