<template>
  <header class="d-flex align-items-center header">
    <div class="back">
      <a
        v-on:click="backHref === undefined ? goBack() : undefined"
        class="btn btn-light px-2"
        :href="backHref ? backHref : undefined"
        ><MgIcon name="back-arrow" /><span class="sr-only">Go Back</span></a
      >
    </div>
    <div class="d-flex flex-grow-1 mx-2 align-items-baseline">
      <h1 class="mb-0">
        <span v-html="truncWithEllipsis(headerTitle)"></span>
        <span class="ml-2 subtitle" v-if="headerSubtitle">
          <small>{{ truncWithEllipsis(headerSubtitle) }}</small>
        </span>
      </h1>
    </div>
    <div class="menu">
      <slot name="menu"></slot>
    </div>
  </header>
</template>

<script>
import MgIcon from "@/components/MgIcon";
import { truncWithEllipsis } from "@/util/filters";

export default {
  name: "Header",
  components: { MgIcon },
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  props: {
    headerTitle: {
      type: String,
      required: true,
      default: "",
    },
    headerSubtitle: {
      type: String,
      required: false,
      default: "",
    },
    backHref: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  methods: {
    goBack() {
      this.$parent.$emit("back");
    },
    truncWithEllipsis(value) {
      return truncWithEllipsis(value, 60);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";

.header {
  background-color: $gray-100;
  min-height: 60px;
  padding: 10px 20px 10px 15px;
  h1,
  .h1 {
    font-size: 20px;
    .subtitle small {
      color: $text-color;
      white-space: nowrap;
    }
  }
}

.back {
  .btn {
    border: none;
  }
}
.menu {
  ::v-deep svg {
    height: 24px !important;
    width: 8px;
    margin-top: 0 !important;
  }
}
</style>
