<template>
  <BModal id="recruitChangeViewModal" ref="recruitChangeViewModal">
    <template #modal-header="{ close }">
      <h3 class="modal-title">Update Saved View</h3>
      <button
        class="close"
        @click="close()"
        aria-label="Close Change Saved View Modal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </template>
    <div class="recruit-modal-content">
      <h4 class="text-danger">
        Are you sure you want to change the Saved View?
      </h4>
      <p>Changing the Saved View will clear all field mappings.</p>
    </div>
    <template #modal-footer="{ close }">
      <div class="w-100 d-flex justify-content-between">
        <button class="btn btn-danger" @click="handleSubmit()" type="submit">
          Update
        </button>
        <button class="btn btn-light ml-1" @click="close">Cancel</button>
      </div>
    </template>
  </BModal>
</template>

<script>
import { BModal } from "bootstrap-vue";

export default {
  name: "RecruitChangeViewModal",
  components: {
    BModal,
  },
  data() {
    return {};
  },
  props: {},
  mounted() {
    this.$root.$on("bv::modal::hidden", () => {
      this.$emit("viewChangeCancel");
    });
  },
  methods: {
    handleSubmit() {
      this.$emit("resetImportConfiguration");
      this.$refs["recruitChangeViewModal"].hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.recruit-modal-content {
  padding: 12px 0;
}
</style>
