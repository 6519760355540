<template>
  <td :id="'inbox-' + inboxId" class="menu">
    <b-dropdown
      right
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        <MgIcon class="menu-color header-menu" name="ellipsis-v" />
      </template>
      <b-dropdown-item @click="editInbox()">Edit</b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item
        variant="danger"
        data-test-id="delete-inbox"
        @click="deleteInbox()"
        >Delete</b-dropdown-item
      >
    </b-dropdown>
  </td>
</template>
<script>
import MgIcon from "@/components/MgIcon";
import store from "@/store";
import eventBus from "@/util/eventBus";
import analytics from "@/util/analytics";

export default {
  name: "SharedInboxListMenu",
  store,
  components: {
    MgIcon,
  },
  props: {
    inboxId: {
      type: Number,
      required: false,
    },
  },
  methods: {
    editInbox() {
      eventBus.dispatchEvent("edit-inbox", { inboxId: this.inboxId });
      analytics.track("Clicked Edit from Shared Inbox List Grid Menu");
    },
    deleteInbox() {
      eventBus.dispatchEvent("delete-confirm", { inboxId: this.inboxId });
      analytics.track("Clicked Delete from Shared Inbox List Grid Menu");
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  overflow: inherit;
}
td {
  padding: 10px 15px !important;
  margin-top: 5px;
}
.menu-color {
  color: #a1a8ae;
}
</style>
