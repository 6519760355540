<template>
  <td class="menu">
    <b-dropdown
      right
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        <MgIcon class="text-muted header-menu" name="ellipsis-v" />
      </template>
      <b-dropdown-item :href="'#/students/' + contact.Id">View</b-dropdown-item>
      <b-dropdown-item v-if="canMessage" @click="messageContact()"
        >Message</b-dropdown-item
      >
      <b-dropdown-item v-if="optedOut && !hasNoInboxes" disabled
        >Message (opted-out)</b-dropdown-item
      >
      <b-dropdown-divider v-if="isAdmin"></b-dropdown-divider>
      <b-dropdown-item v-if="isAdmin" variant="danger" @click="deleteContact()"
        >Delete</b-dropdown-item
      >
    </b-dropdown>
  </td>
</template>
<script>
import MgIcon from "@/components/MgIcon";
import store from "@/store";
import eventBus from "@/util/eventBus";
import analytics from "@/util/analytics";
import getProfile from "@/util/profile";

export default {
  name: "ContactsGridMenu",
  store,
  components: {
    MgIcon,
  },
  data() {
    return { profile: null };
  },
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  async created() {
    this.profile = getProfile();
  },
  computed: {
    canMessage() {
      return this.profile.inboxes.length > 0 && this.contact.OptedIn;
    },
    optedOut() {
      return this.profile.inboxes.length > 0 && !this.contact.OptedIn;
    },
    hasNoInboxes() {
      return this.profile.inboxes.length === 0
    },
    isAdmin() {
      return (
        this.profile.currentDepartment.isAdministrator || this.profile.isOwner
      );
    },
  },
  methods: {
    messageContact() {
      eventBus.dispatchEvent("message-compose", this.contact);
      analytics.track("Clicked Message from Student Search");
    },
    deleteContact() {
      eventBus.dispatchEvent("contact-delete", this.contact.Id);
      analytics.track("Clicked Delete from Student Search");
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  overflow: inherit;
}
</style>
