<template>
  <MgKendoCard orientation="horizontal" hideFooter>
    <template v-slot:header>
      <MgIcon
        :id="`field-icon-${iconName}`"
        class="field-icon"
        data-testid="field-icon"
        :name="iconName"
        :scale="scale"
      />
    </template>
    <template v-slot:body>
      <div class="d-flex flex-column">
        <strong class="field-name" :alt="fieldName" v-html="fieldName"></strong>
        <slot name="value"></slot>
      </div>
    </template>
  </MgKendoCard>
</template>
<script>
import MgIcon from "@/components/MgIcon";
import MgKendoCard from "@/components/MgKendoCard";

export default {
  name: "WebChatVisitorDetailsStandardField",
  components: { MgIcon, MgKendoCard },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    fieldName: {
      type: String,
      required: true,
    },
    scale: {
      type: String,
      required: false,
      default: "1.4",
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

.k-card {
  border: none;
}

::v-deep .k-card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding-right: 1rem;
  padding-left: 0;
}

::v-deep .k-card-body {
  padding-top: 0.25rem;
  padding-bottom: 0;
  padding-left: 0.125rem;
  border: none;
  background: none;
}

.field-icon {
  color: $purple-chat;
}

.field-name {
  margin-bottom: -3px;
}
</style>
