var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"conversation-item-component"},[(_vm.selectedConversationId)?_c('div',{staticClass:"conversation-item d-flex flex-column flex-fill"},[_c('div',{staticClass:"conversation-item-toolbar d-flex align-items-center py-3 px-4"},[_c('div',{staticClass:"back-arrow"},[_c('a',{staticClass:"p-2 mr-2 ml-n2",attrs:{"aria-label":"Back to Messages","role":"button"},on:{"click":function($event){return _vm.goBack()}}},[_c('MgIcon',{attrs:{"name":"arrow-left"}})],1)]),_c('div',{staticClass:"flex-fill"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.contactDisplayName),expression:"contactDisplayName"}]},[_c('h2',{staticClass:"h4 m-0 font-weight-normal"},[_vm._v(_vm._s(_vm.contactDisplayName))]),_c('small',[_vm._v("Last message "+_vm._s(_vm.lastMessageTimeAgo))])])]),(_vm.conversation)?_c('div',{staticClass:"d-flex align-items-center"},[(_vm.conversation.archived === false && !_vm.lockedByOtherUser)?_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({ title: 'Archive', delay: { show: 500, hide: 50 } }),expression:"{ title: 'Archive', delay: { show: 500, hide: 50 } }"}],staticClass:"btn-icon",attrs:{"href":"","role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.archive(true)}}},[_c('MgIcon',{attrs:{"name":"archive","scale":"1.1"}})],1):_vm._e(),(_vm.conversation.archived === true && !_vm.lockedByOtherUser)?_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            title: 'Move to Inbox',
            delay: { show: 500, hide: 50 },
          }),expression:"{\n            title: 'Move to Inbox',\n            delay: { show: 500, hide: 50 },\n          }"}],staticClass:"btn-icon unarchive",attrs:{"href":"","role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.archive(false)}}},[_c('MgIcon',{attrs:{"name":"unarchive","scale":"1.3"}})],1):_vm._e(),(_vm.conversation.unread === true && !_vm.lockedByOtherUser)?_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            title: 'Mark as Read',
            delay: { show: 500, hide: 50 },
          }),expression:"{\n            title: 'Mark as Read',\n            delay: { show: 500, hide: 50 },\n          }"}],staticClass:"btn-icon unread",attrs:{"href":"","role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.markAsRead(true)}}},[_c('MgIcon',{attrs:{"name":"envelope-open","scale":"1.15"}})],1):_vm._e(),(_vm.conversation.unread === false && !_vm.lockedByOtherUser)?_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            title: 'Mark as Unread',
            delay: { show: 500, hide: 50 },
          }),expression:"{\n            title: 'Mark as Unread',\n            delay: { show: 500, hide: 50 },\n          }"}],staticClass:"btn-icon",class:{ disabled: _vm.conversation.archived },attrs:{"href":"","role":"button","disabled":_vm.conversation.archived},on:{"click":function($event){$event.preventDefault();_vm.conversation.archived || _vm.markAsRead(false)}}},[_c('MgIcon',{attrs:{"name":"envelope","scale":"1.2"}})],1):_vm._e(),_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
            id: 'contact-details',
            title: 'Contact Details',
            delay: { show: 500, hide: 50 },
          }),expression:"{\n            id: 'contact-details',\n            title: 'Contact Details',\n            delay: { show: 500, hide: 50 },\n          }"}],staticClass:"btn-icon",attrs:{"href":"","role":"button"},on:{"click":function($event){$event.preventDefault();return _vm.toggleContactCard()}}},[_c('MgIcon',{attrs:{"name":"contact-card","scale":"1.2"}})],1)]):_vm._e()]),_c('div',{staticClass:"conversation-messages py-3",class:{ 'contact-card-open': _vm.showContactCard }},[_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.messages),function(item){return _c('Message',{key:item.messageId,attrs:{"item":item}})}),1),_c('div',{attrs:{"id":"cadence-ai-container"}}),_c('div',{attrs:{"id":"bottom"}})]),(_vm.showLockModal)?_c('LockModal',{attrs:{"conversation":_vm.conversation}}):_vm._e()],1):_vm._e(),(_vm.showEmptyState)?_c('div',{staticClass:"d-flex flex-column justify-content-center h-100"},[_vm._m(0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"empty"},[_c('h3',[_vm._v("Select a message to read")])])}]

export { render, staticRenderFns }