<template>
  <ResponsiveContainer :tag="null" :breakpoints="{ small: 576 }">
    <template slot-scope="container">
      <div id="webChatPage" class="d-flex flex-column h-100">
        <PageHeader
          class="d-flex page-header"
          headerTitle="Chat"
          tipId="c02febf9-9fc2-4025-af40-58557d8fa55f"
          :tipEvents="{
            flow_completed: handleAcceptNotifications,
          }"
        />
        <div class="d-flex flex-row web-chat-content h-100">
          <div
            class="conversation-list-pane"
            :class="{
              'show-mobile-conversation-view': showMobileConversationView,
              'hide-mobile-conversation-view': !showMobileConversationView,
            }"
          >
            <WebChatControls :filters="filters" class="d-flex" />
            <WebChatConversationList
              :selectedConversationId="selectedConversationId"
              :conversations="conversations"
              class="webChatConversationList"
            />
          </div>
          <WebChatConversation
            :botId="selectedBotId"
            :class="{
              'details-expanded': showVisitorDetails_,
              'details-collapsed': !showVisitorDetails_,
              'show-mobile-conversation-view': showMobileConversationView,
              'hide-mobile-conversation-view': !showMobileConversationView,
            }"
            :conversation="selectedConversation"
            :templateList="templateList"
            :messages="selectedMessages"
            class="conversation-pane"
            :showVisitorDetails="showVisitorDetails_"
            :isMobileView="container.size <= container.breakpoints.small"
          />
          <WebChatVisitorDetails
            v-if="showVisitorDetails_"
            :conversation="selectedConversation"
            class="visitor-details-pane"
          />
        </div>
      </div>
    </template>
  </ResponsiveContainer>
</template>
<script>
import _ from "lodash";
import eventBus from "@/util/eventBus";
import store from "@/store";
import api from "@/util/api";
import PageHeader from "@/components/PageHeader";
import WebChatControls from "@/components/WebChatControls";
import WebChatConversationList from "@/components/WebChatConversationList";
import WebChatConversation from "@/components/WebChatConversation";
import WebChatVisitorDetails from "@/components/WebChatVisitorDetails";
import ResponsiveContainer from "@/components/ResponsiveContainer";
import Toast from "@/components/Toast";
import { ACTIONS, GETTERS, MUTATIONS } from "@/util/constants";
import { formatPhoneNumber } from "@/util/phoneNumber";
import { requestNotificationPermission } from "@/util/notifications";
import analytics from "@/util/analytics";

export default {
  name: "WebChatPage",
  store,
  components: {
    PageHeader,
    WebChatControls,
    WebChatConversationList,
    WebChatConversation,
    WebChatVisitorDetails,
    ResponsiveContainer,
  },
  props: {
    selectedConversationId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      selectedBotId_: 0,
      showVisitorDetails_: false,
    };
  },
  async created() {
    // if we've already got a selected conversation in state but they got here through the base route,
    // we need to redirect them to the correct route or the AI assistant won't load correctly
    if (this.selectedConversation && !this.selectedConversationId) {
      this.$emit("conversationSelected", {
        conversationId: this.selectedConversation.conversationId,
      });
    }

    if (!localStorage.selectedBotId) {
      await this.resetChatState();
    }
    this.$store.dispatch(ACTIONS.GET_WEBCHAT_CONVERSATIONS);
    this.$store.dispatch(ACTIONS.GET_WEBCHAT_TEMPLATES);
    eventBus.addEventListener(
      "toggle-webchat-visitor-details",
      this.handleToggleWebChatVisitorDetails
    );
    eventBus.addEventListener(
      "close-webchat-conversation",
      this.handleCloseConversation
    );
    eventBus.addEventListener(
      "complete-webchat-follow-up",
      this.handleCompleteFollowUp
    );
    eventBus.addEventListener(
      "conversation-selected",
      this.handleConversationSelected
    );
    eventBus.addEventListener(
      "conversation-transferred",
      this.handleConversationTransferred
    );
    eventBus.addEventListener("sms-from-webchat", this.handleSMSFromWebChat);
    eventBus.addEventListener(
      "delete-chat-conversation",
      this.handleDeletingConversation
    );
  },
  destroyed() {
    eventBus.removeEventListener(
      "toggle-webchat-visitor-details",
      this.handleToggleWebChatVisitorDetails
    );
    eventBus.removeEventListener(
      "close-webchat-conversation",
      this.handleCloseConversation
    );
    eventBus.removeEventListener(
      "complete-webchat-follow-up",
      this.handleCompleteFollowUp
    );
    eventBus.removeEventListener(
      "conversation-selected",
      this.handleConversationSelected
    );
    eventBus.removeEventListener(
      "conversation-transferred",
      this.handleConversationTransferred
    );
    eventBus.removeEventListener("sms-from-webchat", this.handleSMSFromWebChat);
    eventBus.removeEventListener(
      "delete-chat-conversation",
      this.handleDeletingConversation
    );
  },
  computed: {
    conversations() {
      return this.$store.getters[GETTERS.GET_WEBCHAT_FILTERED_CONVERSATIONS];
    },
    filters() {
      return this.$store.state.webChat.filters;
    },
    selectedConversation() {
      return this.$store.state.webChat.selectedConversation;
    },
    selectedMessages() {
      return this.$store.state.webChat.selectedConversationMessages;
    },
    selectedBotId() {
      return _.get(this.$store.state.webChat.bot, "id") || this.selectedBotId_;
    },
    showMobileConversationView() {
      return this.$store.state.webChat.showMobileConversationView;
    },
    templateList() {
      return this.$store.state.webChat.templateList;
    },
  },
  methods: {
    async resetChatState() {
      await this.$store.commit(MUTATIONS.SET_AVAILABLE_TO_CHAT, false);
      await this.$store.dispatch(ACTIONS.START_WEBCHAT_HEARTBEAT);
      await this.$store.dispatch(ACTIONS.STOP_SIGNALR_WEBCHAT_CONNECTION);
      await this.$store.commit(MUTATIONS.SET_WEBCHAT_BOT, null);
      await this.$store.commit(MUTATIONS.SET_WEBCHAT_CONVERSATIONS, new Map());
      await this.$store.commit(
        MUTATIONS.SET_SELECTED_WEBCHAT_CONVERSATION,
        null
      );
      await this.$store.commit(MUTATIONS.SET_SELECTED_WEBCHAT_MESSAGES, []);
      localStorage.setItem("unreadWebChatConversationsCount", 0);
    },

    // Event handlers
    handleToggleWebChatVisitorDetails() {
      this.showVisitorDetails_ = !this.showVisitorDetails_;
    },

    async handleDeletingConversation(event) {
      let properties = {
        "Conversation Id": event.detail.conversationId,
      };

      try {
        await api.delete(
          `${this.$store.state.webChat.bot.id}/hitl/conversation/${event.detail.conversationId}`
        );
        analytics.track("Deleted chat conversation", properties);
      } catch (error) {
        this.errorToast("There was an error deleting the chat conversation.");
      } finally {
        this.showDeleteModal_ = false;
        this.showVisitorDetails_ = false;
        await this.$store.commit(
          MUTATIONS.SET_SELECTED_WEBCHAT_CONVERSATION,
          null
        );
        await this.$store.commit(MUTATIONS.SET_SELECTED_WEBCHAT_MESSAGES, []);
        await this.$store.dispatch(ACTIONS.GET_WEBCHAT_CONVERSATIONS);
        window.location.href = "#/chat";
      }
    },

    async handleCloseConversation(event) {
      const endpoint = `${this.$store.state.webChat.bot.id}/hitl/conversation/${event.detail.conversationId}/close`;
      let id = this.$store.state.config.userId;
      let properties = {
        "User Id": id,
        "User Name": this.$store.getters[GETTERS.USER_NAME_BY_ID](id),
      };

      await api.post(endpoint);
      eventBus.dispatchEvent("cadence-ai-close-chat-ai-dialog");

      if (event.detail.isMobileView) {
        analytics.track(
          "Closed chat conversation manually in mobile view",
          properties
        );
      } else {
        analytics.track("Closed chat conversation manually", properties);
      }
    },

    async handleCompleteFollowUp(event) {
      const endpoint = `${this.$store.state.webChat.bot.id}/hitl/conversation/${event.detail.conversationId}/followup`;
      let id = this.$store.state.config.userId;
      let properties = {
        "User Id": id,
        "User Name": this.$store.getters[GETTERS.USER_NAME_BY_ID](id),
      };

      await api.post(endpoint);
      if (event.detail.isMobileView) {
        analytics.track("Completed chat follow up in mobile view", properties);
      } else {
        analytics.track("Completed chat follow up", properties), {};
      }
    },

    handleConversationSelected(event) {
      this.$emit("conversationSelected", {
        conversationId: event.detail.conversationId,
      });
    },

    handleAcceptNotifications() {
      const permission = requestNotificationPermission();
      if (permission === "denied") {
        this.errorToast(
          "You have previously disabled Chat notifications. Please go to your Profile Settings to enable them."
        );
      }
    },

    handleConversationTransferred(event) {
      const transfer = event.detail;
      const endpoint = `${this.selectedBotId}/hitl/conversation/${transfer.conversationId}/transfer`;

      let body = {
        receivingTeamId: transfer.team.teamId,
      };

      if (transfer.note) {
        body = { ...body, transferNote: transfer.note };
      }

      if (transfer.staffId) {
        body = { ...body, staffId: transfer.staffId };
      }

      api.post(endpoint, body);
    },

    handleSMSFromWebChat(event) {
      const conversation = event.detail;
      this.showComposeModal(conversation);
    },

    // Work
    errorToast(message) {
      let toastBody = this.$createElement(Toast, {
        props: {
          message,
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "danger",
        noCloseButton: true,
        solid: true,
      });
    },

    async showComposeModal(conversation) {
      const body = {
        firstName: conversation.firstName,
        lastName: conversation.lastName,
        mobileNumber: conversation.mobilePhone,
        source: "Chat",
      };

      const modalTitle = "Follow Up from Chat";
      const isFollowUpSMS = true;
      try {
        let contact = await api.post("students/createIfNotExists", body);

        let contacts = [
          {
            id: contact.id,
            tagText: contact.firstName
              ? `${contact.firstName} ${contact.lastName}`
              : formatPhoneNumber(contact.mobileNumber),
          },
        ];

        let SMSfromChat = true;

        eventBus.dispatchEvent("open-compose-modal", {
          contacts,
          modalTitle,
          isFollowUpSMS,
          SMSfromChat,
        });
      } catch (err) {
        this.errorToast(
          "There was an error loading the contact info. Please try again."
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

#webChatPage {
  // Storybook Layout
  .sb-show-main & {
    margin: -1rem;
  }
}

.page-header {
  border-bottom: 1px solid $gray-pane-border;
}

.conversation-list-pane {
  &.show-mobile-conversation-view {
    @include media-breakpoint-down(md) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      width: 25%;
      border-right: 1px solid $gray-pane-border;
    }
  }

  &.hide-mobile-conversation-view {
    @include media-breakpoint-down(md) {
      width: 100%;
      height: auto;
      overflow: auto;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      width: 25%;
      border-right: 1px solid $gray-pane-border;
    }
  }
}

.conversation-pane {
  &.show-mobile-conversation-view {
    display: flex;
    flex-direction: column;
    height: 100%;
    border-right: 1px solid $gray-pane-border;

    @include media-breakpoint-down(md) {
      &.details-expanded {
        display: none;
      }

      &.details-collapsed {
        width: 100%;
      }
    }

    @include media-breakpoint-up(lg) {
      &.details-expanded {
        width: 55%;
      }

      &.details-collapsed {
        width: 75%;
      }
    }
  }

  &.hide-mobile-conversation-view {
    @include media-breakpoint-down(md) {
      display: none;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      height: 100%;
      border-right: 1px solid $gray-pane-border;

      &.details-expanded {
        width: 55%;
      }

      &.details-collapsed {
        width: 75%;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.visitor-details-pane {
  @include media-breakpoint-down(md) {
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    width: 20%;
  }
}
</style>
