import axios from "axios/index";
import eventBus from "./eventBus";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer ${window.localStorage.token}`;

    if (config.headers["x-disable-loading"]) {
      return config;
    }

    eventBus.dispatchEvent("incrementLoading");

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.config.headers["x-disable-loading"]) {
      return response.data;
    }

    eventBus.dispatchEvent("decrementLoading");

    return response.data;
  },
  (error) => {
    eventBus.dispatchEvent("decrementLoading");
    if (error.response.status === 401) {
      eventBus.dispatchEvent("authRequired");
    }
    return Promise.reject(error);
  }
);

export default api;
