export function arrayToHashMap(pArray, keyField) {
  const array = pArray || [];
  return new Map(array.map((item) => [item[keyField], item]));
}

export function hashMerge(map, key, value) {
  return map.set(key, value);
}

export function hashGet(map, key) {
  return map.get(key);
}

export function hashDelete(map, key) {
  return map.delete(key);
}

export function hashCloneDeep(map) {
  return new Map(JSON.parse(JSON.stringify(Array.from(map))));
}

export function hashKeys(map) {
  return Array.from(map.keys());
}

export function hashKeysIterator(map) {
  return map.keys();
}

export function hashValues(map) {
  return Array.from(map.values());
}

export function hashValuesIterator(map) {
  return map.values();
}

export function mapSize(map) {
  return map.size;
}
