<template>
  <MgKendoCard hideHeader hideFooter>
    <template v-slot:body>
      <form>
        <div class="d-flex flex-column">
          <strong class="d-flex flex-row h4 mb-3 title"
            >Assign to {{ recipient }}</strong
          >
          <div class="d-flex flex-row mb-1">
            <strong>Internal note</strong
            ><span class="text-muted">&nbsp;(optional)</span>
          </div>
          <textarea
            id="assignmentTextArea"
            class="w-100 mb-3 form-control assignment-textarea"
            aria-label="assignment note"
            rows="4"
            v-model="note_"
          ></textarea>
          <div class="d-flex flex-row justify-content-between">
            <button
              type="submit"
              @click.prevent="[confirm(), trackAssignment()]"
              class="btn btn-primary"
            >
              Assign
            </button>
            <button @click="cancel()" class="btn btn-light">Cancel</button>
          </div>
        </div>
      </form>
    </template>
  </MgKendoCard>
</template>
<script>
import MgKendoCard from "@/components/MgKendoCard";
import analytics from "@/util/analytics";
import store from "@/store";

export default {
  name: "WebChatAssignmentForm",
  store,
  components: { MgKendoCard },
  props: {
    conversationId: {
      type: String,
      required: true,
    },
    selectedUser: {
      type: Object,
      required: false,
      default: () => ({
        name: "",
        id: null,
      }),
    },
    team: {
      type: Object,
      required: true,
    },
    isFollowUp: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      note_: "",
      recipient: "",
    };
  },
  mounted() {
    this.recipient = this.selectedUser.id
      ? this.selectedUser.name
      : this.team.teamName;
  },
  watch: {
    team() {
      this.note_ = "";
    },
  },
  methods: {
    confirm() {
      this.$emit("confirmed", {
        conversationId: this.conversationId,
        team: this.team,
        note: this.note_,
        staffId: this.selectedUser.id,
      });
    },
    cancel() {
      this.$emit("cancelled");
    },
    trackAssignment() {
      analytics.track("Transferred Chat Conversation", {
        "Conversation Type": this.isFollowUp ? "Follow-Up" : "Live Chat",
        "Note Included": this.note_ === "" ? false : true,
        "Conversation ID": this.conversationId,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/variables";
@import "../../scss/utilities";
::v-deep .assignment-textarea {
  resize: none;
}

::v-deep .title {
  font-weight: $font-weight-normal;
}
</style>
