<template>
  <div>
    <VueTypeaheadBootstrap v-bind="$attrs" v-on="$listeners" />
  </div>
</template>

<script>
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";

export default {
  name: "Typeahead",
  inheritAttrs: false,
  components: {
    VueTypeaheadBootstrap
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";

::v-deep .vbt-autcomplete-list.list-group.shadow {
  box-shadow: none !important;
}
</style>
