<template>
    <BModal
      :id="modalId"
      title="Delete Chat Conversation"
      title-tag="h3"
      @close="cancel()"
    >
    <h1 class="h4 text-danger my-3">Are you sure you want to delete this chat converstion?</h1>
    <p>All conversation history will be deleted and this operation cannot be reversed.</p>
      <template v-slot:modal-footer>
        <div class="d-flex flex-row justify-content-between w-100">
          <BButton @click="confirmDelete()" type="submit" class="btn btn-danger"
            >Delete</BButton
          >
          <BButton @click="cancel()" class="btn btn-light">Cancel</BButton>
        </div>
      </template>
    </BModal>
  </template>

  <script>
  import { BModal, BButton } from "bootstrap-vue";
  import eventBus from "@/util/eventBus";
  export default {
    name: "WebChatDeleteModal",
    components: {
      BModal,
      BButton,
    },
    props: {
      modalId: {
        type: String,
        required: true,
      },
      conversationId: {
        type: String,
        required: false,
      },
      show: {
        type: Boolean,
        required: true,
      },
    },
    mounted() {
      this.$root.$on("bv::modal::hide", () => {
        this.cancel();
      });
    },
    watch: {
      show(newVal, oldVal) {
        if (newVal && !oldVal) {
          this.showModal();
        } else if (!newVal && oldVal) {
          this.hideModal();
        }
      },
    },
    methods: {
      showModal() {
        this.$bvModal.show(this.modalId);
      },
      hideModal() {
        this.$bvModal.hide(this.modalId);
      },
      cancel() {
        this.$emit("close");
      },
      confirmDelete() {
        eventBus.dispatchEvent("delete-chat-conversation", {
          "conversationId": this.conversationId,
        });
        this.$emit("close")
      },
    },
  };
  </script>

  <style></style>