<template>
  <div class="user-search d-flex align-items-center">
    <VueTypeaheadBootstrap
      inputClass="special-input-class"
      class="flex-grow-1 user-search-input"
      aria-labelledby="userSearchLabel"
      v-model="query"
      :data="users"
      :disabled="disabled"
      :serializer="(item) => item.email"
      :showAllResults="true"
      :minMatchingChars="minCharacters"
      :placeholder="'Search by name or email'"
      @hit="select($event)"
      @input="debouncedHandler"
    >
      <template slot="suggestion" slot-scope="{ data }">
        <div class="d-flex flex-row">
          <div
            class="gravatar-container d-flex flex-column align-items-stretch"
          >
            <div
              class="profile-letter-container"
              :style="{
                backgroundColor: '#396fb0',
                'text-transform': 'uppercase',
              }"
            >
              <div class="profile-letter">
                {{ data.name.slice(0, 1) }}
              </div>
            </div>
            <img
              class="gravatar rounded-circle"
              :src="`https://www.gravatar.com/avatar/${hashEmail(
                data.email
              )}?r=pg&d=blank`"
              alt="Name"
            />
          </div>
          <div
            class="d-flex flex-column align-self-center user-text d-inline-block text-truncate"
          >
            <p class="m-0 text-truncate">{{ data.name }}</p>
            <span class="text-truncate">{{ data.email }}</span>
          </div>
        </div>
      </template>
      <template v-slot:prepend>
        <BButton variant="outline" class="search-icon"
          ><strong><MgIcon name="search" scale=".75" /></strong
          ><span class="sr-only">Search</span></BButton
        >
      </template>
      <template v-slot:append>
        <div
          class="d-flex align-self-center mx-2 spinner-border spinner-border-sm text-muted"
          :class="{ visible: loading, invisible: !loading }"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </template>
    </VueTypeaheadBootstrap>
  </div>
</template>

<script>
import md5 from "md5";
import getProfile from "@/util/profile";
import api from "@/util/api";
import { BButton } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import debounce from "lodash.debounce";

export default {
  name: "UserSearch",
  components: {
    BButton,
    MgIcon,
    VueTypeaheadBootstrap,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    selectedUser: {
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      minCharacters: 1,
      query: "",
      selectedUser_: null,
      users: [],
    };
  },
  created() {
    const profile = getProfile();
    this.selectedTeamId = profile.currentDepartment.id;
    this.debouncedHandler = debounce(() => {
      this.search();
    }, 500);
  },
  beforeUnmount() {
    this.debouncedHandler.cancel();
  },
  methods: {
    search() {
      const endpoint = "inbox/assignedUsers";
      const body = {
        SearchText: this.query,
      };
      const headers = {
        "x-disable-loading": true,
      };
      this.loading = true;
      return api
        .post(endpoint, body, { headers })
        .then((response) => {
          this.users = response;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    select(option) {
      this.selectedUser_ = option;
      this.$emit("user-selected", this.selectedUser_);
      this.query = "";
    },
    hashEmail(email) {
      return md5(email);
    },
    handleEnter(event) {
      event.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
.search {
  background: $gray-light-bg;
  padding: 19px 15px;
}
.user-search-input {
  border: 1px solid $input-border;
  border-radius: $border-radius-base;
  .search-icon {
    cursor: default;
  }
  &.active {
    border: 1px solid $input-focus-border-color;
  }
}

.user-text {
  font-size: $font-size-base;
  span {
    font-size: $font-size-sm;
    font-weight: $font-weight-light;
    color: $text-muted;
  }
}

::v-deep .list-group-item {
  padding: 10px 15px;
  &:hover {
    span {
      color: $dark-gray-subtitle;
    }
  }
}
::v-deep .special-input-class {
  padding-left: 0;
  border: none;
}
::v-deep .vbt-autcomplete-list {
  padding-top: 0;
  overflow-x: hidden;
  margin-left: 0 !important;
  width: 300px !important;
}
.gravatar-container {
  float: left;
  margin: 3px 12px 0 4px;
  position: relative;
  min-width: 38px;
  height: 38px;
  .gravatar {
    width: 38px;
    height: 38px;
    position: relative;
  }
  .profile-letter-container {
    position: absolute;
    background-color: $blue;
    top: 0;
    left: 0;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    .profile-letter {
      color: rgba(255, 255, 255, 0.8);
      font-size: 18px;
      position: absolute;
      top: 5px;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
