<template>
  <form
    class="w-100"
    :id="`server-search-${elementKey}`"
    @submit.prevent="handleSearch"
  >
    <BInputGroup :class="{ active: focus_ }">
      <template v-if="searchIconClass" v-slot:prepend>
        <BButton
          variant="outline"
          type="submit"
          data-testid="server-search-submit-button"
          ><strong
            ><MgIcon :scale="iconScale_" :name="searchIconClass" /></strong
          ><span class="sr-only">Submit</span></BButton
        >
      </template>
      <div :id="`searchInputLabel-${elementKey}`" class="sr-only">
        {{ placeholder }}
      </div>
      <BFormInput
        @focus="focus_ = true"
        @blur="focus_ = false"
        v-model="searchText_"
        :placeholder="placeholder"
        :aria-labelledby="`searchInputLabel-${elementKey}`"
        data-testid="server-search-input"
      >
      </BFormInput>
      <template v-if="cancelIconClass && showCancel_" v-slot:append>
        <BButton
          variant="outline"
          @click="handleCancelClick()"
          data-testid="server-search-cancel"
        >
          <strong class="text-danger"
            ><MgIcon :scale="iconScale_" :name="cancelIconClass" /><span
              class="sr-only"
              >Reset Search</span
            ></strong
          >
        </BButton>
      </template>
    </BInputGroup>
  </form>
</template>

<script>
import { BButton, BFormInput, BInputGroup } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";

export default {
  name: "ServerSearch",
  data() {
    return {
      focus_: false,
      searchText_: this.searchText,
      showCancel_: !!this.searchText,
      iconScale_: "0.9",
    };
  },
  components: {
    BButton,
    BFormInput,
    BInputGroup,
    MgIcon,
  },
  props: {
    elementKey: {
      type: String,
      required: true,
    },
    searchIconClass: {
      type: String,
      required: false,
    },
    cancelIconClass: {
      type: String,
      required: false,
    },
    searchText: {
      type: String,
      required: false,
      default: "",
    },
    placeholder: {
      type: String,
      required: false,
    },
    clearSearchToggle: {
      type: Boolean,
      required: false,
    },
    /* custom events emitted:
        - search
    */
  },
  methods: {
    handleSearch() {
      if (!this.searchText_ && !this.showCancel_) {
        return;
      }

      this.showCancel_ = this.searchText_ && this.cancelIconClass;
      this.$emit("search", { searchText: this.searchText_ });
    },
    handleCancelClick() {
      this.searchText_ = "";
      this.showCancel_ = false;
      this.$emit("search", { searchText: this.searchText_ });
    },
  },
  watch: {
    clearSearchToggle() {
      this.handleCancelClick();
    },
    searchText_() {
      this.$emit("searchOnChange", { searchText: this.searchText_ });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/utilities";
@import "../scss/variables";

button {
  background-color: #fff;
}

button[type="submit"] {
  color: $gray-light;
  padding-left: 8px;
  padding-right: 6px;
}

.input-group {
  border: 1px solid $input-border;
  border-radius: $border-radius-base;
}

.form-control {
  border: 0;
  padding: $input-padding-y 1px;
}
</style>
