<template>
  <div id="textAnalyticsBreakdown" class="col-12">
    <BTabs v-model="currentTab">
      <div
        class="card-header d-flex justify-content-between align-items-center breakdown-toolbar"
      >
        <div class="breakdown-search pr-5">
          <ServerSearch
            searchIconClass="search"
            cancelIconClass="times"
            placeholder="Search by Name"
            elementKey="tab-search"
            @search="handleSearch"
            :searchText="searchText"
            :clearSearchToggle="clearSearchToggle_"
          />
        </div>
        <button
          type="button"
          class="btn btn-sm btn-light"
          v-on:click="handleExportBreakdown()"
        >
          Export <span v-show="isFiltered">Results</span>
        </button>
      </div>
      <BTab v-on:click="breakdownType('teams')" title="Teams">
        <template v-if="teams.length">
          <MgDataGrid
            :sortable="{ allowUnsort: false, mode: 'single' }"
            :rowData="teams"
            :columns="defaultColumns"
            :showToolbar="false"
            paginationMode="SERVER"
            :total="teamsTotal"
            :skip="breakdownSkip"
            :take="breakdownTake"
            :sort="sort_"
            @data-state-change="handleDataStateChange"
          >
          </MgDataGrid>
        </template>
        <template v-else-if="!loading">
          <AnalyticsBreakdownEmptyState
            :searchText="searchText"
            :name="'Teams'"
          />
        </template>
      </BTab>
      <BTab
        v-on:click="breakdownType('users')"
        v-if="isAdminAnyTeam"
        title="Users"
      >
        <template v-if="users.length">
          <MgDataGrid
            :sortable="{ allowUnsort: false, mode: 'single' }"
            :rowData="users"
            :columns="defaultColumns"
            :showToolbar="false"
            paginationMode="SERVER"
            :total="usersTotal"
            :skip="breakdownSkip"
            :take="breakdownTake"
            :sort="sort_"
            @data-state-change="handleDataStateChange"
          >
          </MgDataGrid>
        </template>
        <template v-else-if="!loading">
          <AnalyticsBreakdownEmptyState
            :searchText="searchText"
            :name="'Users'"
          />
        </template>
      </BTab>
      <BTab v-on:click="breakdownType('inboxes')" title="Inboxes">
        <template v-if="inboxes.length">
          <MgDataGrid
            :sortable="{ allowUnsort: false, mode: 'single' }"
            :rowData="inboxes"
            :columns="inboxColumns"
            :showToolbar="false"
            paginationMode="SERVER"
            :total="inboxesTotal"
            :skip="breakdownSkip"
            :take="breakdownTake"
            :sort="sort_"
            @data-state-change="handleDataStateChange"
          >
          </MgDataGrid>
        </template>
        <template v-else-if="!loading">
          <AnalyticsBreakdownEmptyState
            :searchText="searchText"
            :name="'Inboxes'"
          />
        </template>
      </BTab>
      <BTab v-on:click="breakdownType('campaigns')" title="Campaigns">
        <template v-if="campaigns.length">
          <MgDataGrid
            :sortable="{ allowUnsort: false, mode: 'single' }"
            :rowData="campaigns"
            :columns="campaignColumns"
            :showToolbar="false"
            paginationMode="SERVER"
            :total="campaignsTotal"
            :skip="breakdownSkip"
            :take="breakdownTake"
            :sort="sort_"
            @data-state-change="handleDataStateChange"
          >
          </MgDataGrid>
        </template>
        <template v-else-if="!loading">
          <AnalyticsBreakdownEmptyState
            :searchText="searchText"
            :name="'Campaigns'"
          />
        </template>
      </BTab>
    </BTabs>
  </div>
</template>

<script>
import ServerSearch from "@/components/ServerSearch";
import MgDataGrid from "@/components/MgDataGrid";
import MgIcon from "@/components/MgIcon";
import AnalyticsBreakdownEmptyState from "./AnalyticsBreakdownEmptyState";
import { BTabs, BTab } from "bootstrap-vue";
import { ACTIONS, MUTATIONS } from "@/util/constants";
import analytics from "@/util/analytics";
import store from "@/store";
import _ from "lodash";
import { textUnreadExport, textBreakdownExport } from "@/util/analyticsExports";

export default {
  name: "TextAnalyticsBreakdown",
  components: {
    MgDataGrid,
    AnalyticsBreakdownEmptyState,
    BTabs,
    BTab,
    ServerSearch,
  },
  created() {
    this.breakdownType("teams");
  },
  data() {
    return {
      currentTab: null,
      sort_: [{ field: "total", dir: "desc" }],
      clearSearchToggle_: false,
      loading: true,
      defaultColumns: [
        {
          field: "name",
          title: "NAME",
          type: "text",
          sortable: false,
        },
        { field: "total", title: "TOTAL", type: "number", format: "{0:n}" },
        { field: "sent", title: "SENT", type: "number", format: "{0:n}" },
        {
          field: "received",
          title: "RECEIVED",
          type: "number",
          format: "{0:n}",
        },
        {
          field: "delivered",
          title: "DELIVERED",
          type: "number",
          cell: (h, tdElement, props) => {
            let deliveredPctString =
              props.dataItem.deliveredPercentage &&
              props.dataItem.deliveredPercentage !== 0
                ? Math.round(props.dataItem.deliveredPercentage * 100) + "%"
                : "-";
            let deliveredString =
              props.dataItem.delivered && props.dataItem.delivered !== "0"
                ? "(" + props.dataItem.delivered.toLocaleString("en-US") + ")"
                : "";
            return (
              <td>
                <span>{deliveredPctString}</span>
                <span class="small-metric">{deliveredString}</span>
              </td>
            );
          },
        },
        {
          field: "replied",
          title: "REPLIED",
          type: "number",
          cell: (h, tdElement, props) => {
            let replyPctString =
              props.dataItem.repliedPercentage &&
              props.dataItem.repliedPercentage !== 0
                ? Math.round(props.dataItem.repliedPercentage * 100) + "%"
                : "-";
            let replyString =
              props.dataItem.replied && props.dataItem.replied !== "0"
                ? "(" + props.dataItem.replied.toLocaleString("en-US") + ")"
                : "";
            return (
              <td>
                <span>{replyPctString}</span>
                <span class="small-metric">{replyString}</span>
              </td>
            );
          },
        },
        { field: "reached", title: "REACHED", type: "number", format: "{0:n}" },
        {
          field: "unread",
          title: "UNREAD",
          type: "number",
          format: "{0:n}",
          cell: (h, tdElement, props) => {
            let handleExportUnread = function (e) {
              e.preventDefault();
              let context = {
                teamId:
                  store.state.analytics.breakdownType === "teams"
                    ? [props.dataItem.id]
                    : null,
                staffId:
                  store.state.analytics.breakdownType === "users"
                    ? props.dataItem.id
                    : null,
                name: props.dataItem.name,
              };
              textUnreadExport(context, store.state.analytics);
              let tabName =
                store.state.analytics.breakdownType === "teams"
                  ? "Teams"
                  : "Users";
              analytics.track("Export Unread Messages From Breakdown", {
                "Selected Tab": tabName,
              });
            };
            if (this.isAdminAnyTeam && props.dataItem.unread !== 0) {
              return (
                <td>
                  <a href v-on:click={handleExportUnread}>
                    <MgIcon name="download" class="on-row-hover mr-1"></MgIcon>
                    {props.dataItem.unread}
                  </a>
                </td>
              );
            } else {
              return <td>{props.dataItem.unread}</td>;
            }
          },
        },
      ],
      inboxColumns: [
        {
          field: "name",
          title: "NAME",
          type: "text",
          sortable: false,
          cell: (h, tdElement, props) => {
            let trackInbox = function () {
              analytics.track("Clicked on Analytics Inbox", {
                "Inbox Name": props.dataItem.name,
              });
            };
            return (
              <td>
                <a onclick={trackInbox} href={props.dataItem.link}>
                  {props.dataItem.name}
                </a>
                <div class="team-name">{props.dataItem.teamName}</div>
              </td>
            );
          },
        },
        { field: "total", title: "TOTAL", type: "number", format: "{0:n}" },
        { field: "sent", title: "SENT", type: "number", format: "{0:n}" },
        {
          field: "received",
          title: "RECEIVED",
          type: "number",
          format: "{0:n}",
        },
        {
          field: "delivered",
          title: "DELIVERED",
          type: "number",
          cell: (h, tdElement, props) => {
            let deliveredPctString =
              props.dataItem.deliveredPercentage &&
              props.dataItem.deliveredPercentage !== 0
                ? Math.round(props.dataItem.deliveredPercentage * 100) + "%"
                : "-";
            let deliveredString =
              props.dataItem.delivered && props.dataItem.delivered !== "0"
                ? "(" + props.dataItem.delivered.toLocaleString("en-US") + ")"
                : "";
            return (
              <td>
                <span>{deliveredPctString}</span>
                <span class="small-metric">{deliveredString}</span>
              </td>
            );
          },
        },
        {
          field: "replied",
          title: "REPLIED",
          type: "number",
          cell: (h, tdElement, props) => {
            let replyPctString =
              props.dataItem.repliedPercentage &&
              props.dataItem.repliedPercentage !== 0
                ? Math.round(props.dataItem.repliedPercentage * 100) + "%"
                : "-";
            let replyString =
              props.dataItem.replied && props.dataItem.replied !== "0"
                ? "(" + props.dataItem.replied.toLocaleString("en-US") + ")"
                : "";
            return (
              <td>
                <span>{replyPctString}</span>
                <span class="small-metric">{replyString}</span>
              </td>
            );
          },
        },
        { field: "reached", title: "REACHED", type: "number", format: "{0:n}" },
        {
          field: "unread",
          title: "UNREAD",
          type: "number",
          format: "{0:n}",
          cell: (h, tdElement, props) => {
            let handleExportUnread = function (e) {
              e.preventDefault();
              let context = {
                teamId: null,
                staffId: null,
                highspeedinboxid:
                  props.dataItem.inboxType === "HighSpeed"
                    ? props.dataItem.id
                    : null,
                sharedinboxid:
                  props.dataItem.inboxType !== "HighSpeed"
                    ? props.dataItem.id
                    : null,
                name: props.dataItem.name,
              };
              textUnreadExport(context, store.state.analytics);
              analytics.track("Export Unread Messages From Breakdown", {
                "Selected Tab": "Inboxes",
              });
            };
            if (props.dataItem.unread !== 0) {
              return (
                <td>
                  <a href v-on:click={handleExportUnread}>
                    <MgIcon name="download" class="on-row-hover mr-1"></MgIcon>
                    {props.dataItem.unread}
                  </a>
                </td>
              );
            } else {
              return <td>{props.dataItem.unread}</td>;
            }
          },
        },
      ],
      campaignColumns: [
        {
          field: "name",
          title: "NAME",
          type: "text",
          sortable: false,
          cell: (h, tdElement, props) => {
            let trackCampaign = function () {
              analytics.track("Clicked on Analytics Campaign", {
                "Campaign Name": props.dataItem.name,
              });
            };
            return this.currentTeamId === props.dataItem.teamId ? (
              <td>
                <a
                  onclick={trackCampaign}
                  href={props.dataItem.link}
                  class="campaignDetail"
                >
                  {props.dataItem.name}
                </a>
                <div class="team-name">{props.dataItem.teamName}</div>
              </td>
            ) : (
              <td>
                <span>{props.dataItem.name}</span>
                <div class="team-name">{props.dataItem.teamName}</div>
              </td>
            );
          },
        },
        { field: "total", title: "TOTAL", type: "number", format: "{0:n}" },
        { field: "sent", title: "SENT", type: "number", format: "{0:n}" },
        {
          field: "received",
          title: "RECEIVED",
          type: "number",
          format: "{0:n}",
        },
        {
          field: "delivered",
          title: "DELIVERED",
          type: "number",
          cell: (h, tdElement, props) => {
            let deliveredPctString =
              props.dataItem.deliveredPercentage &&
              props.dataItem.deliveredPercentage !== 0
                ? Math.round(props.dataItem.deliveredPercentage * 100) + "%"
                : "-";
            let deliveredString =
              props.dataItem.delivered && props.dataItem.delivered !== "0"
                ? "(" + props.dataItem.delivered.toLocaleString("en-US") + ")"
                : "";
            return (
              <td>
                <span>{deliveredPctString}</span>
                <span class="small-metric">{deliveredString}</span>
              </td>
            );
          },
        },
        {
          field: "replied",
          title: "REPLIED",
          type: "number",
          cell: (h, tdElement, props) => {
            let replyPctString =
              props.dataItem.repliedPercentage &&
              props.dataItem.repliedPercentage !== 0
                ? Math.round(props.dataItem.repliedPercentage * 100) + "%"
                : "-";
            let replyString =
              props.dataItem.replied && props.dataItem.replied !== "0"
                ? "(" + props.dataItem.replied.toLocaleString("en-US") + ")"
                : "";
            return (
              <td>
                <span>{replyPctString}</span>
                <span class="small-metric">{replyString}</span>
              </td>
            );
          },
        },
        { field: "reached", title: "REACHED", type: "number", format: "{0:n}" },
      ],
    };
  },
  methods: {
    setSortString(sort) {
      let sortColumn = _.get(sort, "[0].field"),
        sortDirection = _.get(sort, "[0].dir");

      if (sortColumn === "delivered") {
        sortColumn = "deliveredPercentage";
      } else if (sortColumn === "replied") {
        sortColumn = "repliedPercentage";
      }

      return sortColumn + " " + sortDirection;
    },
    breakdownType(type) {
      if (this.$store.state.analytics.startDate !== "") {
        this.$store.commit(MUTATIONS.SET_SORT, this.setSortString(this.sort_));
        this.$store.commit(MUTATIONS.RESET_BREAKDOWN_SKIP);
        this.$store.commit(MUTATIONS.SET_BREAKDOWN_TYPE, type);
        this.$store.commit(MUTATIONS.SET_SEARCH, "");
        this.clearSearchToggle_ = !this.clearSearchToggle_;
        analytics.track("Viewed Analytics Breakdown", {
          "Breakdown Type": type,
        });
      }
    },
    handleDataStateChange(data) {
      this.sort_ = data.sort;

      let sortString = this.setSortString(this.sort_);

      this.$store.commit(MUTATIONS.SET_SORT, sortString);
      this.$store.commit(MUTATIONS.SET_BREAKDOWN_SKIP, data.skip);

      let textType = JSON.parse(
        sessionStorage.getItem("analytics-text-type-filter")
      );
      let hasMedia = null;
      if (textType) {
        hasMedia = textType.length > 1 ? null : textType.includes("MMS");
      }
      this.$store.dispatch(ACTIONS.GET_ANALYTICS_BREAKDOWN, hasMedia);
    },
    async handleSearch(data) {
      this.$store.commit(MUTATIONS.SET_SEARCH, data.searchText);
      this.$store.commit(MUTATIONS.RESET_BREAKDOWN_SKIP);
      this.loading = true;
      let textType = JSON.parse(
        sessionStorage.getItem("analytics-text-type-filter")
      );
      let hasMedia = null;
      if (textType) {
        hasMedia = textType.length > 1 ? null : textType.includes("MMS");
      } else {
        hasMedia = null;
      }

      await this.$store.dispatch(ACTIONS.GET_ANALYTICS_BREAKDOWN, hasMedia);
      this.loading = false;
      analytics.track("Used search field", {
        "Selected Tab": this.$store.state.analytics.breakdownType,
      });
    },
    async handleExportBreakdown() {
      textBreakdownExport(this.$store.state.analytics);
      analytics.track("Exported breakdown", {
        "Selected Tab": this.$store.state.analytics.breakdownType,
      });
    },
  },

  computed: {
    isFiltered() {
      return !!this.searchText;
    },
    currentTeamId() {
      return this.$store.state.config.team.id;
    },
    breakdownSkip() {
      return this.$store.state.analytics.breakdownSkip;
    },
    breakdownTake() {
      return this.$store.state.analytics.take;
    },
    teamsTotal() {
      if (this.$store.state.analytics.teamsBreakdown.meta) {
        return this.$store.state.analytics.teamsBreakdown.meta.total;
      } else {
        return 0;
      }
    },
    usersTotal() {
      if (this.$store.state.analytics.usersBreakdown.meta) {
        return this.$store.state.analytics.usersBreakdown.meta.total;
      } else {
        return 0;
      }
    },
    inboxesTotal() {
      if (this.$store.state.analytics.inboxesBreakdown.meta) {
        return this.$store.state.analytics.inboxesBreakdown.meta.total;
      } else {
        return 0;
      }
    },
    campaignsTotal() {
      if (this.$store.state.analytics.campaignsBreakdown.meta) {
        return this.$store.state.analytics.campaignsBreakdown.meta.total;
      } else {
        return 0;
      }
    },
    teams() {
      let teams = [];
      let store = this.$store;
      if (store.state.analytics.teamsBreakdown.results) {
        store.state.analytics.teamsBreakdown.results.forEach(function (team) {
          let t = {
            delivered: team.delivered || 0,
            deliveredPercentage: team.deliveredPercentage || 0,
            name: team.name || "-",
            reached: team.reached || 0,
            received: team.received || 0,
            replied: team.replied || 0,
            repliedPercentage: team.repliedPercentage || 0,
            sent: team.sent || 0,
            total: team.total || 0,
            unread: team.unread || 0,
            id: team.id || null,
          };
          teams.push(t);
        });
      }

      return teams;
    },
    users() {
      let users = [];
      let store = this.$store;
      if (store.state.analytics.usersBreakdown.results) {
        store.state.analytics.usersBreakdown.results.forEach(function (user) {
          let u = {
            name: user.displayName || "-",
            delivered: user.delivered || 0,
            deliveredPercentage: user.deliveredPercentage || 0,
            reached: user.reached || 0,
            received: user.received || 0,
            replied: user.replied || 0,
            repliedPercentage: user.repliedPercentage || 0,
            sent: user.sent || 0,
            total: user.total || 0,
            unread: user.unread || 0,
            id: user.id || null,
          };
          users.push(u);
        });
      }

      return users;
    },
    inboxes() {
      let inboxes = [];
      let store = this.$store;
      if (store.state.analytics.inboxesBreakdown.results) {
        store.state.analytics.inboxesBreakdown.results.forEach(function (
          inbox
        ) {
          let i = {
            link: `#/reporting/${
              inbox.teamId
            }/${inbox.inboxType.toLowerCase()}/${inbox.id}`,
            name: inbox.name || "-",
            delivered: inbox.delivered || 0,
            deliveredPercentage: inbox.deliveredPercentage || 0,
            reached: inbox.reached || 0,
            received: inbox.received || 0,
            replied: inbox.replied || 0,
            repliedPercentage: inbox.repliedPercentage || 0,
            sent: inbox.sent || 0,
            total: inbox.total || 0,
            unread: inbox.unread || 0,
            teamName: inbox.teamName || 0,
            inboxType: inbox.inboxType,
            id: inbox.id,
          };
          inboxes.push(i);
        });
      }

      return inboxes;
    },
    campaigns() {
      let campaigns = [];
      let store = this.$store;
      if (store.state.analytics.campaignsBreakdown.results) {
        store.state.analytics.campaignsBreakdown.results.forEach(function (
          campaign
        ) {
          let c = {
            link: "/#/v2/reports/campaigns/" + campaign.id,
            name: campaign.name || "-",
            delivered: campaign.delivered || 0,
            deliveredPercentage: campaign.deliveredPercentage || 0,
            reached: campaign.reached || 0,
            received: campaign.received || 0,
            replied: campaign.replied || 0,
            repliedPercentage: campaign.repliedPercentage || 0,
            sent: campaign.sent || 0,
            total: campaign.total || 0,
            teamName: campaign.team.name || 0,
            teamId: campaign.team.id || null,
          };
          campaigns.push(c);
        });
      }

      return campaigns;
    },
    isAdminAnyTeam() {
      let isAdmin = false;
      if (store.state.analytics.currentTeams.length > 0) {
        store.state.analytics.currentTeams.forEach(function (team) {
          if (team.isAdministrator) {
            isAdmin = true;
          }
        });
      }
      return isAdmin;
    },
    searchText() {
      if (store.state.analytics.search) {
        return store.state.analytics.search;
      } else {
        return "";
      }
    },
    emptyType() {
      let type = "search";
      return type;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/utilities";
#textAnalyticsBreakdown {
  margin-top: 15px;
}
::v-deep {
  .nav-tabs {
    margin-bottom: 1px;
    border-bottom: none;
  }
  .nav-link {
    padding: 14px 26px 12px;
    background: #f9fafa;
    margin-right: 2px;
    font-size: 16px;
    color: #212529;
    text-align: center;
    font-weight: 300;
    border: none;
    &.active {
      background: $card-cap-bg;
      color: #212529;
      font-weight: 300;
      border-bottom: 2px solid $card-cap-bg;
      margin-bottom: -2px;
    }
  }
  .tab-content {
    border-radius: $border-radius;
    box-shadow: $panel-box-shadow;
  }
}

::v-deep .k-grid {
  border-top-left-radius: 0;
  box-shadow: none;
  th,
  td {
    text-align: right;
  }
  th:first-of-type,
  td:first-of-type {
    text-align: left;
  }
  th:last-of-type,
  td:last-of-type {
    padding-right: 20px;
  }
  .small-metric {
    margin-left: 5px;
    color: $gray-subtitle;
  }
  .team-name {
    color: $gray-subtitle;
  }
}
::v-deep .k-grid.hideSort {
  .k-i-sort-asc-sm {
    display: none !important;
  }
}
.breakdown-toolbar {
  .breakdown-search {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 30%;
    }
  }
}

::v-deep .k-grid-header .k-header > .k-link {
  display: flex;
  justify-content: end;
}
::v-deep .k-grid-header .k-header > .k-link > .k-icon.k-i-sort-asc-sm,
::v-deep .k-grid-header .k-header > .k-link > .k-icon.k-i-sort-desc-sm {
  position: relative;
  right: 4px;
  margin-left: 0px;
}
.breakdown-toolbar {
  .breakdown-search {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 30%;
    }
  }
  ::v-deep .btn.disabled {
    opacity: 1;
  }
}
</style>
