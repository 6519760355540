<template>
  <div id="renxtPage" class="d-flex flex-column h-100 overflow-auto">
    <div
      id="pageHeader"
      class="d-flex flex-row page-header justify-content-end"
    >
      <div class="flex-grow-1">
        <h1>Raiser&rsquo;s Edge NXT Integration</h1>
      </div>
      <div>
        <button
          class="btn btn-light mr-2"
          v-b-modal.renxtConfigModal
          v-show="isConfigured"
        >
          <MgIcon name="cog" scale=".8125" />
          Settings
        </button>
      </div>
    </div>
    <div v-show="!isLoading">
      <div v-if="isConfigured">
        <div class="nav-container sticky-top">
          <ul class="nav nav-tabs w-100">
            <li
              class="nav-item"
              :key="tab"
              :class="{ 'active-tab': currentTab === tab }"
              v-for="tab in tabs"
              v-on:click="handleTabChange(tab)"
            >
              <button class="text-uppercase border-0 nav-link active">
                {{ tab }}
              </button>
            </li>
          </ul>
        </div>
        <div class="h-100 page-content">
          <BAlert variant="success" :show="authResult === 'true'" dismissible>
            <MgIcon
              class="align-top text-success error-icon"
              scale="1.25"
              name="check-circle"
            />
            <h2 class="h4 alert-heading font-weight-bold">
              Authorization Successful
            </h2>
            <p class="mt-2">
              Team authorization has successfully been completed.
            </p>
            <p class="mt-2">
              <button
                type="button"
                class="btn btn-link p-0"
                v-b-modal.renxtConfigModal
              >
                Complete notification setup to receive emails.
              </button>
            </p>
          </BAlert>
          <div class="component-container">
            <component
              v-bind:is="currentTabComponent"
              :isExportConfigured="isExportConfigured"
              :isImportConfigured="isImportConfigured"
              :imports="imports"
              :exports="exports"
              :exportSummary="exportSummary"
            />
          </div>
        </div>
      </div>
      <div class="page-content" v-else>
        <BAlert variant="danger" :show="authResult === 'false'">
          <MgIcon
            class="align-top text-danger error-icon"
            scale="1.25"
            name="exclamation-triangle"
          />
          <h2 class="h4 alert-heading font-weight-bold">Authorization Error</h2>
          <p class="mt-2">
            An error occurred and team authorization was not successful. Contact
            your Raiser&rsquo;s Edge NXT organization admin for support.
          </p>
        </BAlert>
        <BAlert
          variant="warning"
          :show="authResult === 'cancelled'"
          dismissible
        >
          <MgIcon
            class="align-top text-warning error-icon"
            scale="1.25"
            name="exclamation-triangle"
          />
          <h2 class="h4 alert-heading font-weight-bold">
            Authorization Cancelled
          </h2>
          <p class="mt-2">Team authorization has been cancelled.</p>
        </BAlert>
        <EmptyState
          image="/images/icon_file-sync.svg"
          :imageWidth="98"
          imageAltText="File Sync Icon"
          headingText="Set Up Connection"
          buttonText="Connect"
          :buttonHref="authCallbackUri"
          @emptyStateAction="trackConnectionEvent"
        >
          <ul class="numbered-list">
            <li>
              In the Raiser&rsquo;s Edge Marketplace, install the
              <a
                href="//app.blackbaud.com/marketplace/applications/e3af7824-69f7-49c3-9375-f4630d37dc59"
                target="_blank"
                rel="noopener"
              >
                Mongoose Cadence Integration.
              </a>
            </li>
            <li>Connect Cadence to Raiser&rsquo;s Edge NXT.</li>
          </ul>
        </EmptyState>
      </div>
      <div class="card dev-panel-container" v-if="isDev">
        <div class="card-body">
          <span class="h3">Dev panel</span>
          <div class="mt-2">
            <BButton
              :pressed="isConfigured"
              @click="toggleConfig"
              variant="light"
              >Toggle Config</BButton
            >
          </div>

          <div class="mt-2">
            <BButtonGroup>
              <BButton variant="success" @click="toggleAuthSucces"
                >Auth Success</BButton
              >
              <BButton variant="warning" @click="toggleAuthCancel"
                >Auth Cancel</BButton
              >
              <BButton variant="danger" @click="toggleAuthFail"
                >Auth Fail</BButton
              >
              <BButton variant="outline-primary" @click="resetAuthState"
                >Reset</BButton
              >
            </BButtonGroup>
          </div>
        </div>
      </div>
    </div>

    <RenxtSettingsModal
      :authCallbackUri="authCallbackUri"
      :baseSettings="baseSettings"
    />
  </div>
</template>
<script>
import Vue from "vue";
import {
  BAlert,
  BButton,
  BButtonGroup,
  BModal,
  ToastPlugin,
} from "bootstrap-vue";
import _ from "lodash";

import { ACTIONS } from "./renxtStore";
import analytics from "@/util/analytics";

import RenxtImportsTab from "./RenxtImportsTab";
import RenxtDashboardTab from "./RenxtDashboardTab";
import RenxtExportsTab from "./RenxtExportsTab";
import RenxtSettingsModal from "./RenxtSettingsModal";

import EmptyState from "@/components/EmptyState";
import eventBus from "@/util/eventBus";
import MgIcon from "@/components/MgIcon";
import serializeProfile from "@/util/profile";
import store from "@/store";
import Toast from "@/components/Toast";

Vue.use(ToastPlugin);

/* eslint-disable */
Vue.component("tab-renxt-dashboard", RenxtDashboardTab);
Vue.component("tab-renxt-imports", RenxtImportsTab);
Vue.component("tab-renxt-exports", RenxtExportsTab);
/* eslint-enable */

export default {
  name: "RenxtPage",
  store,
  components: {
    BAlert,
    BButton,
    BButtonGroup,
    BModal,
    MgIcon,
    EmptyState,
    RenxtSettingsModal,
  },
  props: {
    currentTab: {
      type: String,
      required: false,
      default: "dashboard",
    },
    authResult: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      tabs: ["dashboard", "imports", "exports"],
    };
  },
  computed: {
    isDev() {
      return this.$store.state.renxt.id === "org-98-dept-2029";
    },
    isLoading() {
      return this.$store.state.renxt.isLoading;
    },
    isConfigured() {
      return this.$store.state.renxt.isConfigured;
    },
    authCallbackUri() {
      const profile = serializeProfile();
      const encodedUri = encodeURI(process.env.VUE_APP_API_BASE_URL);
      return (
        `//app.blackbaud.com/oauth/authorize?client_id=e3af7824-69f7-49c3-9375-f4630d37dc59&redirect_uri=${encodedUri}` +
        `/RaisersEdgeNxt/AuthorizationCallback&response_type=code` +
        `&state={'id':'${this.$store.state.renxt.id}','authorizedBy':'${profile.firstName} ${profile.lastName}'}`
      );
    },
    currentTabComponent() {
      return `tab-renxt-${this.currentTab}`;
    },
    baseSettings() {
      return _.cloneDeep(this.$store.state.renxt.base);
    },
    imports() {
      return this.$store.state.renxt.imports;
    },
    exports() {
      return this.$store.state.renxt.exports;
    },
    exportSummary() {
      return this.$store.state.renxt.exportSummary;
    },
    isImportConfigured() {
      return (
        this.$store.state.renxt.importSettings.constituentListId !==
        "00000000-0000-0000-0000-000000000000"
      );
    },
    isExportConfigured() {
      return (
        this.$store.state.renxt.exportSettings.enableMessagePush ||
        this.$store.state.renxt.exportSettings.enableOptOutPush
      );
    },
  },
  async created() {
    const currentDepartment = serializeProfile().currentDepartment;
    const generatedId = `org-${currentDepartment.organizationId}-dept-${currentDepartment.id}`;

    if (
      generatedId !== this.$store.state.renxt.id ||
      this.isConfigured === undefined
    ) {
      try {
        await this.$store.dispatch(ACTIONS.SET_RENXT_IS_CONFIGURED);
        await this.$store.dispatch(ACTIONS.GET_RENXT_CONFIG);
        await this.$store.dispatch(ACTIONS.GET_RENXT_IMPORTS);
        await this.$store.dispatch(ACTIONS.GET_RENXT_EXPORTS);
        await this.$store.dispatch(ACTIONS.GET_RENXT_EXPORT_SUMMARY);
      } catch {
        let toastBody = this.$createElement(Toast, {
          props: {
            message: "Something went wrong.",
          },
        });
        this.$bvToast.toast([toastBody], {
          variant: "danger",
          noCloseButton: true,
          solid: true,
        });
      }
    }

    this.$store.dispatch(ACTIONS.SET_RENXT_IS_LOADING, false);
    this.$root.$on("bv::modal::hidden", (bvevent, modalId) => {
      if (modalId === "renxtConfigModal") this.authResult = "";
    });
    eventBus.addEventListener("renxt-tab-selected", this.handleTabChange);
  },
  destroyed() {
    eventBus.removeEventListener("renxt-tab-selected", this.handleTabChange);
  },
  methods: {
    handleTabChange(t) {
      this.$emit("renxtTabSelected", {
        currentTab: t,
      });
    },
    trackConnectionEvent() {
      analytics.track("Clicked Integration Connect", {
        integrationName: "Renxt",
      });
    },

    // DEV PANEL METHODS
    toggleConfig() {
      this.$store.dispatch(ACTIONS.SET_RENXT_IS_CONFIGURED, !this.isConfigured);
    },
    toggleAuthSucces() {
      this.$store.dispatch(ACTIONS.SET_RENXT_IS_CONFIGURED, true);
      this.authResult = "true";
    },
    toggleAuthFail() {
      this.$store.dispatch(ACTIONS.SET_RENXT_IS_CONFIGURED, false);
      this.authResult = "false";
    },
    toggleAuthCancel() {
      this.$store.dispatch(ACTIONS.SET_RENXT_IS_CONFIGURED, false);
      this.authResult = "cancelled";
    },
    resetAuthState() {
      this.authResult = "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.page-content {
  padding: 16px 30px;
}

.btn-link {
  text-transform: none;
}

.error-icon {
  padding-top: 4px;
}

.numbered-list {
  list-style-type: decimal;
  padding-left: 20px;
}

.active-tab {
  border: unset;
  border-bottom: 4px solid $accent;
}

.nav-container {
  width: 100%;
  padding: 0 30px;
  z-index: 0;
  background-color: #fff;
}

.dev-panel-container {
  margin: 0 30px;
}
</style>
