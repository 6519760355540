<template>
  <div class="d-flex flex-column h-100 w-100">
    <div class="d-flex flex-column flex-fill overflow-auto page-content">
      <div v-if="showSmsExport">
        <h2 class="h3 mt-1 mb-2 section-title">Text Exports</h2>
        <div class="row">
          <div
            v-for="(card, index) in filteredMessageCards"
            :key="'message-card-' + index"
            class="col-md-4 my-2"
          >
            <MgCard
              class="h-100"
              :header="card.header"
              :description="card.description"
              :hasAutomatedOn="false"
              :button="card.button"
              @cardClicked="exportData(index, card)"
            />
          </div>
        </div>
        <div class="row row-spacing">
          <div
            v-for="(card, index) in contactCards"
            :key="'contact-card-' + index"
            class="col-md-4 my-2"
          >
            <MgCard
              class="h-100"
              :header="card.header"
              :description="card.description"
              :hasAutomatedOn="false"
              :button="card.button"
              @cardClicked="exportData(index, card)"
            />
          </div>
        </div>
      </div>
      <div v-if="showChatExport">
        <h2 class="h3 mt-1 mb-2 section-title">Chat Exports</h2>
        <div class="row">
          <div
            v-for="(card, index) in chatExportCards"
            :key="'chat-card-' + index"
            class="col-md-4 my-2"
          >
            <MgCard
              :header="card.header"
              :description="card.description"
              :infoBox="infoBox"
              :hasAutomatedOn="automatedExport.enabled"
              :hasAutomatedExport="automatedExport.createdOn"
              :automatedExportDetails="card.automatedExportDetails"
              :automatedExportDate="automatedExport.updatedOn"
              :automatedExportTab="automatedExportTab"
              :button="card.button"
              @cardClicked="exportData(index, card)"
            />
          </div>
        </div>
      </div>
      <ExportModal
        modalId="export-confirm"
        :keyValue="exportReportId_"
        :title="`Export ${cardName}`"
        confirmButtonText="Export"
        :show="showExportModal_"
        :errorMessage="exportErrorMessage_"
        :hasAutomatedExport="automatedExport.createdOn"
        :hasAutomatedOn="automatedExport.enabled"
        :automatedExportDetails="automatedExportDetails"
        :automatedExportDate="automatedExport.updatedOn"
        :infoBox="infoBox"
        :automatedExportTab="automatedExportTab"
        :manualExportTab="manualExportTab"
        @handleExportTabs="handleExportTabs"
        @toggleAutomaticExport="handleToggleAutomaticExport"
        @confirmed="handleExportConfirmed"
        @cancelled="cancelModals()"
      >
        <div v-if="onMessageActivity">
          <p v-html="modalBody" class="export-message"></p>
          <div class="export-box">
            <div v-if="onMultipleTeams">
              <p class="dropdown-title">Select Team</p>
            </div>
            <div v-if="!onMultipleTeams">
              <p class="dropdown-title">Team</p>
            </div>

            <div v-if="onMultipleTeams">
              <MetricsFilter
                :filterData="filterData"
                @submit-filter="handleFilterSubmit"
                :icon="'filter-solid'"
                :iconText="'Filter'"
                :popoverId="'metrics-filter'"
                class="mr-3"
                metricsType="exports"
              />
            </div>
            <div v-if="!onMultipleTeams">
              <p class="mb-0">{{ teamName }}</p>
            </div>
            <p class="dropdown-title pt-3">Select Date Range</p>
            <BDropdown
              ref="dropdown"
              class="date-dropdown"
              :class="{ alignRight: !onMultipleTeams }"
            >
              <template #button-content>
                <MgIcon name="calendar-solid"></MgIcon>
                {{ displayDate }}
              </template>
              <BDropdownItem v-on:click="setTimeFrame('day')"
                >Last 24 hours</BDropdownItem
              >
              <BDropdownItem v-on:click="setTimeFrame('week')"
                >Last 7 days</BDropdownItem
              >
              <BDropdownItem v-on:click="setTimeFrame('month')"
                >Last 30 days</BDropdownItem
              >
              <BDropdownItem v-on:click="setTimeFrame('quarter')"
                >Last 3 months</BDropdownItem
              >
              <BDropdownItem v-on:click="setTimeFrame('year')"
                >Last 12 months</BDropdownItem
              >
              <BDropdownDivider />
              <BDropdownText style="width: 240px">
                <DatePicker
                  ref="datePicker"
                  :mode="'range'"
                  @date-picked="updateDateRange"
                  :picker-only="true"
                >
                  <template v-slot:picker-only-link-text>Custom...</template>
                </DatePicker>
              </BDropdownText>
            </BDropdown>
          </div>
        </div>
        <div v-else>
          <p v-html="modalBody" class="export-message"></p>
          <div v-if="infoBox" class="info-box container-box">
            <div class="item">
              <MgIcon name="info" class="info-icon"></MgIcon>
            </div>
            <div class="item" v-html="infoBox" style="margin-left: 8px"></div>
          </div>
          <div v-if="infoBox">
            <BTabs>
              <BTab
                title="Manual Exports"
                active
                @click="handleExportTabs('manualExportTab')"
                value="manual"
              >
                <template>
                  <div class="export-box">
                    <div>
                      <p class="dropdown-title">Team</p>
                      <p class="mb-0">{{ teamName }}</p>
                    </div>
                    <p v-if="showDateDropdown" class="dropdown-title pt-3">
                      Select Date Range
                    </p>
                    <BDropdown
                      v-if="showDateDropdown"
                      ref="dropdown"
                      class="date-dropdown"
                    >
                      <template #button-content>
                        <MgIcon name="calendar-solid"></MgIcon>
                        {{ displayDate }}
                      </template>
                      <BDropdownItem v-on:click="setTimeFrame('day')"
                        >Last 24 hours</BDropdownItem
                      >
                      <BDropdownItem v-on:click="setTimeFrame('week')"
                        >Last 7 days</BDropdownItem
                      >
                      <BDropdownItem v-on:click="setTimeFrame('month')"
                        >Last 30 days</BDropdownItem
                      >
                      <BDropdownItem v-on:click="setTimeFrame('quarter')"
                        >Last 3 months</BDropdownItem
                      >
                      <BDropdownItem v-on:click="setTimeFrame('year')"
                        >Last 12 months</BDropdownItem
                      >
                      <BDropdownDivider />
                      <BDropdownText style="width: 240px">
                        <DatePicker
                          ref="datePicker"
                          :mode="'range'"
                          @date-picked="updateDateRange"
                          :picker-only="true"
                        >
                          <template v-slot:picker-only-link-text
                            >Custom...</template
                          >
                        </DatePicker>
                      </BDropdownText>
                    </BDropdown>
                  </div>
                </template>
              </BTab>
              <BTab
                class="nav-item"
                :cardName="cardName"
                @click="handleExportTabs('automatedExportTab', cardName)"
              >
                <template #title>
                  <StatusIcon :enabled="hasAutomatedOn" />
                  Automated Export {{ hasAutomatedOn ? "On" : "Off" }}
                </template>
                <div class="export-box">
                  {{ automatedExportDetails }}
                </div>
              </BTab>
            </BTabs>
          </div>
          <div v-else class="export-box">
            <div>
              <p class="dropdown-title">Team</p>
              <p class="mb-0">{{ teamName }}</p>
            </div>
            <p v-if="showDateDropdown" class="dropdown-title pt-3">
              Select Date Range
            </p>
            <BDropdown
              v-if="showDateDropdown"
              ref="dropdown"
              class="date-dropdown"
            >
              <template #button-content>
                <MgIcon name="calendar-solid"></MgIcon>
                {{ displayDate }}
              </template>
              <BDropdownItem v-on:click="setTimeFrame('day')"
                >Last 24 hours</BDropdownItem
              >
              <BDropdownItem v-on:click="setTimeFrame('week')"
                >Last 7 days</BDropdownItem
              >
              <BDropdownItem v-on:click="setTimeFrame('month')"
                >Last 30 days</BDropdownItem
              >
              <BDropdownItem v-on:click="setTimeFrame('quarter')"
                >Last 3 months</BDropdownItem
              >
              <BDropdownItem v-on:click="setTimeFrame('year')"
                >Last 12 months</BDropdownItem
              >
              <BDropdownDivider />
              <BDropdownText style="width: 240px">
                <DatePicker
                  ref="datePicker"
                  :mode="'range'"
                  @date-picked="updateDateRange"
                  :picker-only="true"
                >
                  <template v-slot:picker-only-link-text>Custom...</template>
                </DatePicker>
              </BDropdownText>
            </BDropdown>
          </div>
        </div>
      </ExportModal>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import api from "@/util/api";
import MgCard from "@/components/MgCard";
import MgIcon from "@/components/MgIcon";
import StatusIcon from "@/components/StatusIcon";
import ExportModal from "./ExportModal";
import DatePicker from "@/components/DatePicker";
import MetricsFilter from "@/components/MetricsFilter";
import { ACTIONS } from "@/util/constants";
import store from "@/store";
import { BDropdown } from "bootstrap-vue";
import _ from "lodash";
import getProfile from "@/util/profile";
import { BTab, BTabs, ToastPlugin } from "bootstrap-vue";
import Toast from "@/components/Toast";
import analytics from "@/util/analytics";
import { marketplaceMessageExport } from "@/util/analyticsExports";
import eventBus from "@/util/eventBus";

Vue.use(ToastPlugin);
Vue.component("BDropdown", BDropdown);

export default {
  name: "ExportPage",
  store,
  components: {
    BTab,
    BTabs,
    MgCard,
    MgIcon,
    StatusIcon,
    ExportModal,
    DatePicker,
    MetricsFilter,
    BDropdown,
  },

  data() {
    return {
      initialLoad: true,
      contacts: [],
      messageCards: [
        {
          header: "Message Activity",
          description:
            "Export text messages for record keeping, external reporting, or to review individual message content.",
          button: "Export",
          modalBody:
            "<p>Message activity exports provide details related to each text message in Cadence, including:<ul><li>Individual text messages</li><li>Message direction (incoming v. outgoing)</li><li>User</li><li>Contact name and mobile number</li><li>Date of message</li><li>Message-specific attributes</li></ul></p>",
        },
        {
          header: "Undelivered Messages",
          description:
            "Export a log of undelivered text messages in order to review and troubleshoot deliverability issues.",
          button: "Export",
          modalBody:
            "<p>Undelivered message exports provide details about any outgoing text message unable to be successfully delivered, including:<ul><li>Message sender</li><li>Intended recipient’s ID, name, and mobile number</li><li>Reason for unsuccessful delivery</li><li>Date of message</li></ul></p>",
        },
      ],
      contactCards: [
        {
          header: "Contact Records",
          description:
            "Export a set of contact data for a full view of what data and contacts currently exist in Cadence.",
          button: "Export",
          modalBody:
            "<p>Contact data exports provide contact-level data including:<ul><li>Contact ID, name, and mobile number</li><li>Opt out status</li><li>User assignment</li><li>Date of contact creation/modification</li><li>Mobile number validation status/details</li></ul></p>",
        },
        {
          header: "Opt-Out History",
          description:
            "Export a log of all keyword or user-triggered opt-out status changes that have occurred in Cadence.",
          button: "Export",
          modalBody:
            "<p>Opt-Out changes exports provide contact details for each specific opt-in or opt-out status change that occurs in Cadence, including:<ul><li>Contact ID, name, and mobile number</li><li>Opt out status change</li><li>Date of change</li></ul></p>",
        },
      ],
      chatExportCards: [
        {
          header: "Lead & Activity (Manual and Automated)",
          description:
            "Export visitor contact information, team-specific fields, and conversation history from Chat.",
          button: "Export",
          modalBody:
            "<p>Chat Lead & Activity Exports will show site visitor information including the following:<ul><li>Name</li><li>Email</li><li>Phone Number</li><li>Consent to Text Status and timestamp</li><li>IP Address</li><li>Conversation history</li><li>Any Team-Specific fields</li></ul></p>",
          infoBox:
            "Manually export Chat Lead & Activity at any time. Turn on the Automated Export to receive the export file nightly.",
          automatedExportDetails:
            "Automated Exports are scheduled nightly between 3am and 5am. You will receive an email when the export is ready to download.",
        },
      ],
      cardName: "",
      modalBody: "",
      infoBox: "",
      hasAutomatedOn: null,
      hasAutomatedExport: null,
      automatedExportDate: null,
      automatedExportDetails: "",
      showExportModal_: false,
      exportReportId_: 0,
      exportReportName_: "",
      exportErrorMessage_: [],
      day: null,
      week: null,
      month: null,
      quarter: null,
      year: null,
      selectedDates_: { start: "", end: "" },
      intervalDifference: "30d",
      daysDifference: null,
      timestamp: "",
      selectedTeams_: [],
      automatedExport: [],
      automatedExportTab: false,
      manualExportTab: true,
      bot: null,
    };
  },

  created() {
    this.$store.dispatch(ACTIONS.GET_TEAM_ID);
    let storedTeams = JSON.parse(
      sessionStorage.getItem("analytics-team-filter")
    );
    this.selectedTeams_ = storedTeams ? storedTeams : [this.teamId];
    this.getBotId();
    eventBus.addEventListener("call-cancel-modals", this.cancelModals);
    eventBus.addEventListener(
      "call-success-toast",
      this.handleCallSuccessToast
    );
    eventBus.addEventListener(
      "call-no-results-message",
      this.handleCallNoResultsMessage
    );
  },

  destroyed() {
    eventBus.removeEventListener("call-cancel-modals", this.cancelModals);
    eventBus.removeEventListener(
      "call-success-toast",
      this.handleCallSuccessToast
    );
    eventBus.removeEventListener(
      "call-no-results-message",
      this.handleCallNoResultsMessage
    );
  },

  mounted() {
    this.$store.dispatch(ACTIONS.GET_CURRENT_TEAMS);
    this.$store.dispatch(ACTIONS.GET_CURRENT_INBOXES);
    this.setDefaultTimes();
    this.updateDateRange(this.month);
  },

  computed: {
    teamName() {
      const profile = getProfile();
      if (profile) {
        return profile.currentDepartment.name;
      } else {
        return "";
      }
    },
    currentTeams() {
      return this.$store.state.analytics.currentTeams.length
        ? this.$store.state.analytics.currentTeams
        : [];
    },
    onMultipleTeams() {
      return this.$store.state.analytics.currentTeams.length > 1;
    },
    onMessageActivity() {
      return this.cardName == "Message Activity";
    },
    showDateDropdown() {
      return !["Contact Records", "Chat Leads"].includes(this.cardName);
    },
    showChatExport() {
      const profile = getProfile();
      return _.get(profile, "currentDepartment.teamHasChat");
    },
    showSmsExport() {
      const profile = getProfile();
      return _.get(profile, "currentDepartment.teamHasSms");
    },
    teamId() {
      return this.$store.state.analytics.teamId;
    },
    filterData() {
      return {
        teamId: this.teamId,
        currentTeams: this.currentTeams,
      };
    },
    displayDate() {
      return (
        new Date(this.selectedDates_.start).toLocaleString("default", {
          month: "short",
        }) +
        " " +
        new Date(this.selectedDates_.start).getDate() +
        ", " +
        new Date(this.selectedDates_.start).getFullYear() +
        " - " +
        new Date(this.selectedDates_.end).toLocaleString("default", {
          month: "short",
        }) +
        " " +
        new Date(this.selectedDates_.end).getDate() +
        ", " +
        new Date(this.selectedDates_.end).getFullYear()
      );
    },
    filteredMessageCards() {
      return this.messageCards.filter((card) =>
        card.hideFunction ? !this.$store.getters[card.hideFunction] : true
      );
    },
  },

  methods: {
    handleFilterSubmit(teams) {
      this.selectedTeams_ = teams;
    },
    async handleExportConfirmed(title) {
      let startDate =
        new Date(this.selectedDates_.start).getMonth() +
        1 +
        "/" +
        new Date(this.selectedDates_.start).getDate() +
        "/" +
        new Date(this.selectedDates_.start).getFullYear();
      let endDate =
        new Date(this.selectedDates_.end).getMonth() +
        1 +
        "/" +
        new Date(this.selectedDates_.end).getDate() +
        "/" +
        new Date(this.selectedDates_.end).getFullYear();
      try {
        this.saving_ = true;
        let exportDetails = {
          title: title,
          startDate: startDate,
          endDate: endDate,
          multiTeams: this.onMultipleTeams,
          selectedTeams: this.selectedTeams_,
        };
        if (title === "Export Lead & Activity (Manual and Automated)") {
          marketplaceMessageExport(exportDetails);
        } else {
          marketplaceMessageExport(exportDetails);
          this.successToast(
            "You will receive an email when the " +
              this.cardName +
              " export is complete."
          );
          this.cancelModals();
        }
      } catch (err) {
        this.saving_ = false;
        this.exportErrorMessage_ = [
          "There was an error exporting the data.",
          "",
        ];
      }

      let mixpanelProperties = {
        "User Id": this.$store.state.config.userId,
        "User Org Id": this.$store.state.config.orgId,
        "User Team Id": this.$store.state.config.team.id,
        "Start Date": startDate,
        "End Date": endDate,
        "On Multiple Teams": this.onMultipleTeams,
        "Selected Teams on Export": this.selectedTeams_,
        "Page Name": "Exports Tab",
      };
      //Mixpanel
      if (this.cardName === "Message Activity") {
        analytics.track(
          "Exported Sent Message Details Report",
          mixpanelProperties
        );
      } else if (this.cardName === "Opt-Out History") {
        analytics.track("Exported Opt Out Changes Report", mixpanelProperties);
      } else if (this.cardName === "Undelivered Messages") {
        analytics.track(
          "Exported Unelivered Messages Report",
          mixpanelProperties
        );
      } else if (this.cardName === "Contact Records") {
        analytics.track("Exported Contact Data Report", mixpanelProperties);
      } else if (this.cardName === "Lead & Activity (Manual and Automated)") {
        analytics.track(
          "Chat: Lead & Activity (Manual and Automated)",
          mixpanelProperties
        );
      }
    },
    // cancel modal action
    cancelModals() {
      this.showExportModal_ = false;
      this.automatedExportTab = false;
      this.exportErrorMessage_ = [];
    },

    // export data
    exportData(i, c) {
      this.cardName = c.header;
      this.modalBody = c.modalBody;
      this.showExportModal_ = true;
      this.infoBox = c.infoBox;
      this.hasAutomatedOn = this.automatedExport.enabled;
      this.hasAutomatedExport = this.automatedExport.createdOn;
      this.automatedExportDetails = c.automatedExportDetails;
      this.automatedExportDate = this.automatedExport.updatedOn;
      c.header === "Lead & Activity (Manual and Automated)"
        ? analytics.track("Clicked on Chat Lead & Activity card")
        : "";
    },
    setDefaultTimes() {
      let today = new Date();
      let prevDate = new Date();
      prevDate.setDate(prevDate.getDate() - 1);

      this.day = {
        start: prevDate,
        end: today,
      };
      this.week = {
        start: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 7
        ),
        end: today,
      };
      this.month = {
        start: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 30
        ),
        end: today,
      };
      this.quarter = {
        start: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 90
        ),
        end: today,
      };
      this.year = {
        start: new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 365
        ),
        end: today,
      };
    },
    setDate(selectedDate) {
      this.selectedDates_.start = selectedDate.start.toISOString();
      this.selectedDates_.end = selectedDate.end.toISOString();
    },

    updateDateRange(selectedDate) {
      if (selectedDate) {
        // this.$refs.dropdown.hide(true);
        this.setDate(selectedDate);
        this.exportErrorMessage_ = [];
      }
    },
    setTimeFrame(timeFrame) {
      switch (timeFrame) {
        case "day":
          this.intervalDifference = "24h";
          this.updateDateRange(this.day);
          break;
        case "week":
          this.intervalDifference = "7d";
          this.updateDateRange(this.week);
          break;
        case "month":
          this.intervalDifference = "30d";
          this.updateDateRange(this.month);
          break;
        case "quarter":
          this.intervalDifference = "90d";
          this.updateDateRange(this.quarter);
          break;
        case "year":
          this.intervalDifference = "1y";
          this.updateDateRange(this.year);
          break;
      }
    },
    handleCallSuccessToast(event) {
      this.successToast(event.detail);
    },
    handleCallNoResultsMessage(event) {
      this.exportErrorMessage_ = event.detail ? [event.detail] : [];
    },
    successToast(message) {
      let toastBody = this.$createElement(Toast, {
        props: {
          message,
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "success",
        noCloseButton: true,
        solid: true,
      });
    },
    errorToast(message) {
      let toastBody = this.$createElement(Toast, {
        props: {
          message,
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "danger",
        noCloseButton: true,
        solid: true,
      });
    },
    async getBotId() {
      await api
        .get(`/bot`)
        .then((response) => {
          return (this.bot = response);
        })
        .then((response) => {
          this.getAutomatedExportConfig(response);
        });
    },
    async getAutomatedExportConfig(bot) {
      await api
        .get(`botexport/${bot.id}/automaticExportConfig`)
        .then((response) => {
          return (this.automatedExport = response);
        });
    },
    async handleToggleAutomaticExport() {
      try {
        this.saving_ = true;
        await api
          .post(`botexport/${this.bot.id}/toggleAutomaticExport`)
          .then(() => {
            this.getAutomatedExportConfig(this.bot);
          });
        this.successToast(
          "Automated export successfully turned " +
            (this.hasAutomatedOn ? "off" : "on")
        );
        this.cancelModals();
      } catch (err) {
        this.saving_ = false;
        this.exportErrorMessage_ = [
          "There was an error setting the automated export.",
          "",
        ];
      }
    },
    handleExportTabs(activeTab, title) {
      if (activeTab === "automatedExportTab") {
        if (title === "Lead & Activity (Manual and Automated)") {
          analytics.track(
            "Interacted with the chat automated export on/off switch"
          );
        }
        return (this.automatedExportTab = true);
      } else {
        return (this.automatedExportTab = false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
.export-message {
  margin-top: 10px;
}

.export-box {
  background-color: $gray-light-bg;
  padding: 20px;
  margin: 20px 0 10px 0;
}

.info-box {
  border-radius: 2px;
  background-color: #e6f3fb;
  padding: 8px;
  margin: 8px 0;
}

.info-icon {
  color: #243b54;
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.container-box {
  display: flex;
  padding: 8px;
}

.item {
  margin: auto;
  padding: 8px;
}

.info-box {
  border-radius: 2px;
  background-color: #e6f3fb;
  padding: 8px;
  margin: 8px 0;
}

.info-icon {
  color: #243b54;
  width: 24px;
  height: 24px;
  margin-left: 8px;
}

.container-box {
  display: flex;
  padding: 8px;
}

.item {
  margin: auto;
  padding: 8px;
}

.dropdown-title {
  font-weight: 600;
  margin-bottom: 5px;
}

.date-dropdown {
  ::v-deep button {
    color: $text-color !important;
    background-color: #fff !important;
    border-color: $input-border !important;
    letter-spacing: 0;
    text-transform: capitalize;
    .icon {
      padding-right: 2px;
    }
  }

  .b-dropdown-text {
    font-weight: normal;
    cursor: pointer;
    &:hover,
    &:focus {
      color: #16181b;
      text-decoration: none;
      background-color: #e9ecef;
    }
  }
}

::v-deep .form-control {
  border: 0px;
}

::v-deep #metrics-filter .icon {
  top: 8px;
}

::v-deep .form-control {
  padding: 0.375rem;
}

::v-deep .date-picker {
  margin-left: 0 !important;
}

.row-spacing {
  margin-bottom: 30px;
}

::v-deep .nav-tabs .nav-link {
  border: none;
}

::v-deep .nav-tabs .nav-link.active {
  border-bottom: 4px solid rgb(51, 187, 149);
}
</style>
