<template>
  <div
    class="optout-settings my-3 p-3 d-flex justify-content-sm-between align-items-center"
  >
    <div
      v-if="contact && contact.departments && contact.departments.length > 1"
      class="d-flex"
    >
      <BDropdown
        id="opt-out-dropdown"
        text="Opt-out"
        variant="outline-dark"
        no-flip
      >
        <BDropdownGroup
          v-for="(department, index) in contact.departments"
          :key="index"
          class="opt-out-menu"
        >
          <div class="dept-name">{{ department.name }}</div>
          <BFormCheckbox
            v-model="department.optedOut"
            v-on:change="optOut(department)"
            v-bind:disabled="
              department.muted || department.autoOptOut || lockedByOtherUser
            "
            :aria-label="department.optedOut ? 'Opted-out' : 'Opt-out'"
            switch
            class="text-uppercase"
            >{{ department.optedOut ? "Opted-out" : "Opt-out" }}</BFormCheckbox
          >
        </BDropdownGroup>
      </BDropdown>
      <BDropdown
        id="block-dropdown"
        text="Block"
        variant="outline-dark"
        no-flip
      >
        <BDropdownGroup
          v-for="(department, index) in contact.departments"
          :key="index"
          class="block-menu"
        >
          <div class="dept-name">{{ department.name }}</div>
          <BFormCheckbox
            v-model="department.muted"
            v-on:change="block(department)"
            :aria-label="department.muted ? 'Blocked' : 'Block'"
            switch
            class="text-uppercase"
            >{{ department.muted ? "Blocked" : "Block" }}</BFormCheckbox
          >
        </BDropdownGroup>
      </BDropdown>
    </div>
    <div v-else-if="currentDepartment" class="d-flex">
      <BFormCheckbox
        v-model="currentDepartment.optedOut"
        v-on:change="optOut(currentDepartment)"
        v-bind:disabled="
          currentDepartment.muted ||
          currentDepartment.autoOptOut ||
          lockedByOtherUser
        "
        :aria-label="currentDepartment.optedOut ? 'Opted-out' : 'Opt-out'"
        switch
        class="text-uppercase opt-out"
        size="lg"
        >{{
          currentDepartment.optedOut ? "Opted-out" : "Opt-out"
        }}</BFormCheckbox
      >
      <BFormCheckbox
        v-model="currentDepartment.muted"
        v-on:change="block(currentDepartment)"
        v-bind:disabled="lockedByOtherUser"
        :aria-label="currentDepartment.muted ? 'Blocked' : 'Block'"
        switch
        class="text-uppercase block"
        size="lg"
        >{{ currentDepartment.muted ? "Blocked" : "Block" }}</BFormCheckbox
      >
    </div>
    <MgIcon
      v-b-tooltip="{ title: help.contact.optOutVsBlock }"
      name="info-circle"
    ></MgIcon>
    <b-modal
      id="block-confirm"
      title="Block Contact"
      title-tag="h3"
      @hide="blockHide"
    >
      <template v-slot:modal-header="{ cancel }">
        <h3 class="modal-title">Block Contact</h3>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="cancel()"
        >
          &times;
        </button>
      </template>
      <h4 class="error-list">Are you sure you want to block this contact?</h4>
      <p>
        Block is primarily used to deal with spam or inappropriate messages.
        Messages cannot be sent to or received from a blocked contact.
      </p>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button class="btn btn-danger" @click="ok()">Block</b-button>
        <b-button class="btn btn-light" @click="cancel()">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import api from "@/util/api";
import MgIcon from "@/components/MgIcon";
import {
  VBTooltip,
  BFormCheckbox,
  BDropdown,
  ModalPlugin,
} from "bootstrap-vue";
import eventBus from "../../util/eventBus";
import { ACTIONS, HELP } from "../../util/constants";
import _ from "lodash";
import analytics from "@/util/analytics";

Vue.use(ModalPlugin);
Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "ContactOptOut",
  store,
  components: {
    BDropdown,
    BFormCheckbox,
    MgIcon,
  },
  props: ["contact"],
  data() {
    return {
      blockDepartmentId: null,
      help: HELP,
    };
  },
  destroyed() {
    eventBus.dispatchEvent("resetUpdateContact");
  },
  computed: {
    conversation() {
      return this.$store.state.inbox.selectedConversation;
    },
    currentDepartment() {
      return _.find(this.contact.departments, (department) => {
        return department.id == this.$store.state.config.team.id;
      });
    },
    selectedConversationId() {
      return this.$store.state.inbox.selectedConversationId;
    },
    userId() {
      return this.$store.state.config.userId;
    },
    lockedByOtherUser() {
      if (!(this.conversation && this.conversation.state)) {
        return false;
      }
      return this.conversation.state.locked &&
        this.conversation.state.lockedForStaffId !== this.userId
        ? true
        : false;
    },
  },
  methods: {
    block(dept) {
      if (this.currentDepartment.muted === true) {
        this.$bvModal.show("block-confirm");
        this.blockDepartmentId = dept.id;
      } else {
        api
          .post(`/students/${this.selectedConversationId}/mute`, {
            departmentId: dept.id,
            muted: false,
          })
          .then(() => {
            this.$store.dispatch(ACTIONS.GET_CONTACT, {
              id: this.selectedConversationId,
            });
            eventBus.dispatchEvent("updateContact");
          });
        analytics.track(
          this.$parent.$options._componentTag === "ContactDetails"
            ? "Clicked Block from Contact Profile"
            : "Clicked Block from Conversation",
          {
            Blocked: false,
            /* eslint-disable */
            "Inbox Name": this.$store.state.inbox.activeInbox.name,
            "User Id": this.userId,
            "Inbox Name": this.$store.state.inbox.activeInbox.name,
            "User Id": this.userId,
            "Contact Name": this.conversation.contact,
            Caller: "Contact Details Pane",
            /* eslint-enable */
          }
        );
      }
    },
    blockHide(event) {
      if (event.trigger === "ok") {
        api
          .post(`/students/${this.selectedConversationId}/mute`, {
            departmentId: this.blockDepartmentId,
            muted: true,
          })
          .then(() => {
            this.$store.dispatch(ACTIONS.GET_CONTACT, {
              id: this.selectedConversationId,
            });
            eventBus.dispatchEvent("updateContact");
          });
        analytics.track(
          this.$parent.$options._componentTag === "ContactDetails"
            ? "Clicked Block from Contact Profile"
            : "Clicked Block from Conversation",
          {
            Blocked: true,
            /* eslint-disable */
            "Inbox Name": this.$store.state.inbox.activeInbox.name,
            "User Id": this.userId,
            "Inbox Name": this.$store.state.inbox.activeInbox.name,
            "User Id": this.userId,
            "Contact Name": this.conversation.contact,
            Caller: "Contact Details Pane",
            /* eslint-enable */
          }
        );
      } else {
        this.$store.dispatch(ACTIONS.GET_CONTACT, {
          id: this.selectedConversationId,
        });
      }

      this.blockDepartmentId = null;
    },
    optOut(dept) {
      api
        .post(`/students/${this.selectedConversationId}/optOut`, {
          departmentId: dept.id,
          optedOut: dept.optedOut,
        })
        .then(() => {
          eventBus.dispatchEvent("updateContact");
          analytics.track(
            this.$parent.$options._componentTag === "ContactDetails"
              ? "Clicked Opt Out from Contact Profile"
              : "Clicked Opt Out from Conversation",
            {
              /* eslint-disable */
              "Opted-out": dept.optedOut,
              "Inbox Name": this.$store.state.inbox.activeInbox.name,
              "User Id": this.userId,
              "Inbox Name": this.$store.state.inbox.activeInbox.name,
              "User Id": this.userId,
              "Contact Name": this.conversation.contact,
              /* eslint-enable */
            }
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
::v-deep .custom-switch.b-custom-control-lg .custom-control-label {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  &:hover {
    cursor: pointer;
  }
}

::v-deep .custom-switch.b-custom-control-lg .custom-control-label::before,
::v-deep .custom-switch .custom-control-label::before {
  top: 0;
}

::v-deep .custom-switch.b-custom-control-lg .custom-control-label::after,
::v-deep .custom-switch .custom-control-label::after {
  top: 2px;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  border-color: theme-color("secondary");
  background-color: theme-color("secondary");
}

::v-deep
  .custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: theme-color("secondary");
  opacity: 0.6;
}

.opt-out,
#opt-out-dropdown {
  margin-right: 15px;
}

.opt-out-menu + .opt-out-menu,
.block-menu + .block-menu {
  border-top: 1px solid $gray-lighter;
  display: block;
}

.optout-settings {
  height: 30px;
}
</style>
