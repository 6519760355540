<template>
  <MgFade class="d-flex flex-column w-100" :fade="fadeIn" :appear="true">
    <small class="d-flex flex-row w-100 subtitle justify-content-center">
      {{ message.payload.text }}&nbsp;&bull;&nbsp;{{
        message.sentOn | dateShorthand
      }}&#44;&nbsp;{{ message.sentOn | timeShorthand }}
    </small>
  </MgFade>
</template>

<script>
import MgFade from "@/components/MgFade";
export default {
  name: "WebChatLog",
  components: {
    MgFade,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    fadeIn: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";
.subtitle {
  color: $gray-subtitle;
  margin-bottom: 6px;
}
</style>
