<template>
  <div>
    <div class="d-flex toolbar flex-row mb-3" v-if="isImportConfigured">
      <div class="flex-grow-1">
        <BDropdown variant="light" text="Run Import" class="btn-light">
          <BDropdownItem class="dropdown-paragraph" @click="runImport(false)">
            <span class="font-weight-bold"> Import Changes </span>
            <p class="dropdown-description pt-1 mb-0">
              Imports records changed <br />
              since the last import
            </p>
          </BDropdownItem>
          <BDropdownItem class="dropdown-paragraph" @click="runImport(true)">
            <span class="font-weight-bold"> Import All </span>
            <p class="dropdown-description pt-1 mb-0">Import all records</p>
          </BDropdownItem>
        </BDropdown>
      </div>
      <div class="pt-2">
        <a
          href="/#/integrations/renxt/imports/settings"
          class="text-uppercase align-middle d-inline-block"
        >
          <MgIcon name="cog" scale=".75"></MgIcon> Import Settings
        </a>
      </div>
    </div>

    <BCard
      header="Import Log"
      header-tag="h2"
      no-body
      v-if="isImportConfigured"
    >
      <div class="empty-message" v-if="imports.totalItems === 0">
        <span class="empty-message-text"> No import history </span>
      </div>
      <div v-else>
        <IntegrationsImportTable
          :items="imports.items"
          @onFileDownload="onFileDownload"
        />
      </div>
    </BCard>

    <EmptyState
      class="flex-fill"
      image="/images/icon_upload.svg"
      :imageWidth="74"
      imageAltText="Import Icon"
      headingText="Imports"
      description="Automate contact imports"
      v-else
    >
      <template #buttons>
        <a href="#/integrations/renxt/imports/settings">
          Configure Imports&nbsp;»</a
        >
      </template>
    </EmptyState>
  </div>
</template>

<script>
import { BCard, BDropdown, BDropdownItem } from "bootstrap-vue";
import { ACTIONS } from "./renxtStore";
import analytics from "@/util/analytics";
import api from "@/util/api";
import EmptyState from "@/components/EmptyState";
import IntegrationsImportTable from "@/components/IntegrationsImportTable";
import MgIcon from "@/components/MgIcon";
import store from "@/store";
import Toast from "@/components/Toast";

export default {
  name: "RenxtImportsTab",
  store,
  components: {
    BCard,
    BDropdown,
    BDropdownItem,
    EmptyState,
    IntegrationsImportTable,
    MgIcon,
  },
  props: {
    isImportConfigured: {
      type: Boolean,
      required: true,
    },
    imports: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      polling: null,
    };
  },
  created() {
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    onFileDownload(fileType) {
      analytics.track("Downloaded Import File", {
        integrationName: "Renxt",
        type: fileType,
      });
    },
    pollData() {
      this.polling = setInterval(async () => {
        await this.$store.dispatch(ACTIONS.GET_RENXT_IMPORTS);
      }, 5000);
    },
    async runImport(fullImport = false) {
      const endpoint = `/raisersEdgeNxt/imports?fullImport=${fullImport}`;
      await api
        .post(endpoint)
        .then(() => {
          let toastBody = this.$createElement(Toast, {
            props: {
              message: "Import Queued",
            },
          });
          this.$bvToast.toast([toastBody], {
            variant: "success",
            noCloseButton: true,
            solid: true,
          });
        })
        .catch(() => {
          const toastBody = this.$createElement(Toast, {
            props: {
              message: "There was an error queuing your import.",
            },
          });
          this.$bvToast.toast([toastBody], {
            variant: "danger",
            noCloseButton: true,
            solid: true,
          });
        })
        .finally(() => {
          analytics.track("Queued Manual Import", {
            integrationName: "Renxt",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.no-item-container {
  background-color: $gray-light-bg;
  height: 274px;
}

.empty-message-text {
  font-size: 1.09375rem;
  font-style: italic;
  font-weight: 300;
  margin: 10px 0;
}

.dropdown-description {
  color: #6c757d;
}

.dropdown-paragraph {
  font-weight: 400;
  width: 219px;
}
</style>
