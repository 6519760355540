<template>
  <div>
    <ContactFields :fields="activityFields"></ContactFields>
  </div>
</template>

<script>
import store from "@/store";
import ContactFields from "../ContactFields";

export default {
  name: "ContactActivity",
  store: store,
  components: { ContactFields },
  data() {
    return {};
  },
  created() {},
  computed: {
    contactProfile() {
      return this.$store.state.contact.details;
    },
    activityFields() {
      if (!this.contactProfile) {
        return;
      }
      let fields = {};
      fields["Last Outgoing Message"] = this.convertTime(
        this.contactProfile.lastOutgoingMessage
      );
      fields["Last Incoming Message"] = this.convertTime(
        this.contactProfile.lastIncomingMessage
      );
      fields["Last Modified"] = this.convertTime(
        this.contactProfile.lastUpdated
      );
      fields["Created"] = this.convertTime(this.contactProfile.dateCreated);
      return fields;
    },
  },
  methods: {
    convertTime(time) {
      return this.$options.filters.utcToLocal(time, "M/D/YY, h:mm A");
    },
  },
};
</script>
