import {
  GETTERS,
  WEBCHAT_VISITOR_AVATAR_COLORS,
  WEBCHAT_FILTERS,
} from "@/util/constants";
import _ from "lodash";
import {
  chatRequestedSort,
  conversationCreatedSort,
  isMyConversation,
  isFollowUp,
  isOtherConversation,
  isUnassignedConversation,
  isRead,
  isUserAssisted,
  isClosed,
} from "@/util/webchat";
import { hashGet, hashValues, hashKeys } from "@/util/hash";

export default {
  [GETTERS.GET_WEBCHAT_CONVERSATION_BY_ID]: (state) => (id) => {
    return hashGet(state.conversations, id);
  },
  [GETTERS.GET_UNREAD_WEBCHAT_MY_CONVERSATIONS_COUNT]: (state, getters) => {
    return getters[GETTERS.GET_WEBCHAT_MY_CONVERSATIONS].filter(
      (conversation) => !isRead(conversation)
    ).length;
  },
  [GETTERS.GET_UNREAD_WEBCHAT_UNASSIGNED_CONVERSATIONS_COUNT]: (
    state,
    getters
  ) => {
    return state.availableToChat
      ? getters[GETTERS.GET_WEBCHAT_UNASSIGNED_CONVERSATIONS].filter(
          (conversation) => !isRead(conversation)
        ).length
      : 0;
  },
  [GETTERS.GET_NEXT_WEBCHAT_VISITOR_COLOR]: (state) => {
    return WEBCHAT_VISITOR_AVATAR_COLORS[state.visitorAvatarColorIndex];
  },
  [GETTERS.GET_WEBCHAT_MY_CONVERSATIONS]: (state) => {
    // These are immune to filters... can't have people accidentally filtering out the
    // visitors requesting an agent
    return hashValues(state.conversations)
      .filter((conversation) => isMyConversation(conversation))
      .sort(chatRequestedSort);
  },
  [GETTERS.GET_WEBCHAT_UNASSIGNED_CONVERSATIONS]: (state) => {
    // These are immune to filters... can't have people accidentally filtering out the
    // visitors requesting an agent
    return hashValues(state.conversations)
      .filter((conversation) =>
        isUnassignedConversation(conversation, state.bot)
      )
      .sort(chatRequestedSort);
  },
  [GETTERS.GET_WEBCHAT_FOLLOW_UPS]: (state, getters) => (filtered) => {
    if (filtered) {
      return getters[GETTERS.GET_WEBCHAT_FILTERED_CONVERSATIONS]
        .filter((conversation) => isFollowUp(conversation))
        .sort(conversationCreatedSort);
    } else {
      return hashValues(state.conversations)
        .filter((conversation) => isFollowUp(conversation))
        .sort(conversationCreatedSort);
    }
  },
  [GETTERS.GET_WEBCHAT_OTHER_CONVERSATIONS]: (state, getters) => (filtered) => {
    if (filtered) {
      return getters[GETTERS.GET_WEBCHAT_FILTERED_CONVERSATIONS]
        .filter((conversation) => isOtherConversation(conversation, state.bot))
        .sort(conversationCreatedSort);
    } else {
      return hashValues(state.conversations)
        .filter((conversation) => isOtherConversation(conversation, state.bot))
        .sort(conversationCreatedSort);
    }
  },
  [GETTERS.GET_WEBCHAT_FILTERED_CONVERSATIONS]: (state) => {
    return hashValues(state.conversations).filter((conversation) => {
      let conversationPasses = true;

      Object.keys(state.filters).forEach((filterKey) => {
        // Each filter set to true is a test that needs to be passed
        if (state.filters[filterKey]) {
          switch (filterKey) {
            case WEBCHAT_FILTERS.USER_ASSISTED:
              if (!isUserAssisted(conversation)) {
                conversationPasses = false;
              }
              break;
            case WEBCHAT_FILTERS.BOT_ONLY:
              if (isUserAssisted(conversation)) {
                conversationPasses = false;
              }
              break;
            case WEBCHAT_FILTERS.CONVERSATION_OPEN:
              if (isClosed(conversation)) {
                conversationPasses = false;
              }
              break;
            case WEBCHAT_FILTERS.CONVERSATION_CLOSED:
              if (!isClosed(conversation)) {
                conversationPasses = false;
              }
              break;
            default:
          }
        }
      });

      return conversationPasses;
    });
  },
  [GETTERS.GET_WEBCHAT_BOT_BOTPRESSID]: (state) => {
    return _.get(state, "bot.botpressBotId");
  },
  [GETTERS.GET_WEBCHAT_LAST_MESSAGE_SELECTED_CONVERSATION]: (state) => {
    return _.last(state.selectedConversationMessages);
  },
  [GETTERS.GET_WEBCHAT_TYPING_TEXT]: (state) => {
    const typers = hashKeys(state.typers);
    const numTypers = typers.length;
    let text = "";

    if (numTypers === 0) {
      return "";
    }

    text = typers.reduce((acc, cur, i) => {
      if (i === 0) {
        return cur;
      } else if (i === 1 && numTypers === 2) {
        return ` ${acc} and ${cur}`;
      } else if (i === numTypers - 1 && numTypers > 2) {
        // The Oxford Comma is a minor deity
        return ` ${acc}, and ${cur}`;
      } else {
        return ` ${acc}, ${cur}`;
      }
    }, text);

    text += ` ${numTypers > 1 ? "are" : "is"} typing...`;

    return text;
  },
  [GETTERS.IS_SELECTED_WEBCHAT_CONVERSATION]: (state) => (conversationId) => {
    return (
      _.get(state.selectedConversation, "conversationId") === conversationId
    );
  },
};
