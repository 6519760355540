<template>
  <div id="analyticsInboxDetailBreakdown" class="col-12">
    <div
      class="card-header d-flex justify-content-between align-items-center breakdown-toolbar"
    >
      <div class="breakdown-search pr-5">
        <ServerSearch
          searchIconClass="search"
          cancelIconClass="times"
          placeholder="Search by Name"
          elementKey="tab-search"
          @search="handleSearch"
          :searchText="searchText"
        />
      </div>
    </div>
    <template v-if="users.length">
      <MgDataGrid
        :sortable="{ allowUnsort: false, mode: 'single' }"
        :rowData="users"
        :columns="defaultColumns"
        :showToolbar="false"
        paginationMode="SERVER"
        :total="usersTotal"
        :skip="skip"
        :take="take"
        :sort="sort_"
        @data-state-change="handleDataStateChange"
      >
      </MgDataGrid>
    </template>
    <template v-else-if="!loading">
      <AnalyticsBreakdownEmptyState :searchText="searchText" :name="'Users'" />
    </template>
  </div>
</template>

<script>
import ServerSearch from "@/components/ServerSearch";
import MgDataGrid from "@/components/MgDataGrid";
import { ACTIONS, MUTATIONS } from "@/util/constants";
import store from "@/store";
import _ from "lodash";
import AnalyticsBreakdownEmptyState from "@/pages/analytics/AnalyticsBreakdownEmptyState";

export default {
  name: "AnalyticsInboxDetailBreakdown",
  components: {
    MgDataGrid,
    ServerSearch,
    AnalyticsBreakdownEmptyState,
  },
  data() {
    return {
      sort_: [{ field: "total", dir: "desc" }],
      loading: false,
      defaultColumns: [
        {
          field: "name",
          title: "NAME",
          type: "text",
          sortable: false,
        },
        { field: "total", title: "TOTAL", type: "number", format: "{0:n}" },
        { field: "sent", title: "SENT", type: "number", format: "{0:n}" },
        {
          field: "received",
          title: "RECEIVED",
          type: "number",
          format: "{0:n}",
        },
        {
          field: "delivered",
          title: "DELIVERED",
          type: "number",
          cell: (h, tdElement, props) => {
            let deliveredPctString =
              props.dataItem.deliveredPercentage &&
              props.dataItem.deliveredPercentage !== 0
                ? Math.round(props.dataItem.deliveredPercentage * 100) + "%"
                : "-";
            let deliveredString =
              props.dataItem.delivered && props.dataItem.delivered !== "0"
                ? "(" + props.dataItem.delivered.toLocaleString("en-US") + ")"
                : "";
            return (
              <td>
                <span>{deliveredPctString}</span>
                <span class="small-metric">{deliveredString}</span>
              </td>
            );
          },
        },
        {
          field: "replied",
          title: "REPLIED",
          type: "number",
          cell: (h, tdElement, props) => {
            let replyPctString =
              props.dataItem.repliedPercentage &&
              props.dataItem.repliedPercentage !== 0
                ? Math.round(props.dataItem.repliedPercentage * 100) + "%"
                : "-";
            let replyString =
              props.dataItem.replied && props.dataItem.replied !== "0"
                ? "(" + props.dataItem.replied.toLocaleString("en-US") + ")"
                : "";
            return (
              <td>
                <span>{replyPctString}</span>
                <span class="small-metric">{replyString}</span>
              </td>
            );
          },
        },
        { field: "reached", title: "REACHED", type: "number", format: "{0:n}" },
      ],
    };
  },
  methods: {
    setSortString(sort) {
      let sortColumn = _.get(sort, "[0].field"),
        sortDirection = _.get(sort, "[0].dir");

      if (sortColumn === "delivered") {
        sortColumn = "deliveredPercentage";
      } else if (sortColumn === "replied") {
        sortColumn = "repliedPercentage";
      }

      return sortColumn + " " + sortDirection;
    },
    handleDataStateChange(data) {
      this.sort_ = data.sort;

      let sortString = this.setSortString(this.sort_);

      this.$store.commit(MUTATIONS.SET_SORT, sortString);
      this.$store.commit(MUTATIONS.SET_INBOX_DETAIL_SKIP, data.skip);
      this.loading = true;
      this.$store.dispatch(ACTIONS.GET_INBOX_DETAILS_BREAKDOWN);
      this.loading = false;
    },
    handleSearch(data) {
      this.$store.commit(MUTATIONS.SET_INBOX_DETAIL_SEARCH, data.searchText);
      this.$store.commit(MUTATIONS.RESET_INBOX_DETAIL_SKIP);
      this.loading = true;
      this.$store.dispatch(ACTIONS.GET_INBOX_DETAILS_BREAKDOWN);
      this.loading = false;
    },
  },

  computed: {
    isFiltered() {
      return !!this.searchText;
    },
    currentTeamId() {
      return this.$store.state.config.team.id;
    },
    skip() {
      return this.$store.state.analytics.inboxDetailSkip;
    },
    take() {
      return this.$store.state.analytics.take;
    },
    usersTotal() {
      if (this.$store.state.analytics.inboxDetails.breakdown.meta) {
        return this.$store.state.analytics.inboxDetails.breakdown.meta.total;
      } else {
        return 0;
      }
    },
    users() {
      let users = [];
      let store = this.$store;
      if (store.state.analytics.inboxDetails.breakdown.results) {
        store.state.analytics.inboxDetails.breakdown.results.forEach(function (
          user
        ) {
          let u = {
            name: user.displayName || "-",
            delivered: user.delivered || 0,
            deliveredPercentage: user.deliveredPercentage || 0,
            reached: user.reached || 0,
            received: user.received || 0,
            replied: user.replied || 0,
            repliedPercentage: user.repliedPercentage || 0,
            sent: user.sent || 0,
            total: user.total || 0,
            unread: user.unread || 0,
            id: user.id || null,
          };
          users.push(u);
        });
      }

      return users;
    },
    isAdminAnyTeam() {
      let isAdmin = false;
      if (store.state.analytics.currentTeams.length > 0) {
        store.state.analytics.currentTeams.forEach(function (team) {
          if (team.isAdministrator) {
            isAdmin = true;
          }
        });
      }
      return isAdmin;
    },
    searchText() {
      if (store.state.analytics.inboxDetailSearch) {
        return store.state.analytics.inboxDetailSearch;
      } else {
        return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/utilities";
#AnalyticsBreakdown {
  margin-top: 15px;
}
::v-deep .nav-tabs {
  margin-bottom: 2px;
  border-bottom: none;
}
::v-deep .nav-link {
  padding: 15px 0;
  background: #f9fafa;
  margin-right: 2px;
  font-size: 16px;
  color: #212529;
  width: 100px;
  text-align: center;
  font-weight: 300;
  border: none;
}
::v-deep .nav-link.active {
  background: $card-cap-bg;
  color: #212529;
  font-weight: 300;
  border-bottom: 2px solid $card-cap-bg;
  margin-bottom: -2px;
}
::v-deep .nav-link.hover {
  border-bottom: 2px solid $card-cap-bg;
}
::v-deep .k-grid {
  border-top-left-radius: 0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  th,
  td {
    text-align: right;
  }
  th:first-of-type,
  td:first-of-type {
    text-align: left;
  }
  .small-metric {
    margin-left: 5px;
    color: $gray-subtitle;
  }
  .team-name {
    color: $gray-subtitle;
  }
}
::v-deep .k-grid.hideSort {
  .k-i-sort-asc-sm {
    display: none !important;
  }
}
.breakdown-toolbar {
  .breakdown-search {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 30%;
    }
  }
}
.breakdown-toolbar {
  .breakdown-search {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 30%;
    }
  }
  ::v-deep .btn.disabled {
    opacity: 1;
  }
}

::v-deep .download-unread {
  color: $link-color;
  cursor: pointer;
  span {
    padding-left: 5px;
    opacity: 0;
    transition: opacity linear 0.1s;
  }
}

::v-deep .download-unread:hover {
  span {
    opacity: 1;
  }
}

::v-deep .unread-none {
  padding-right: 21px;
}
</style>
