<template>
  <div class="container-fluid">
    <ResponsiveContainer :breakpoints="{ marginRight: 768, metricLabel: 768 }">
      <template slot-scope="container">
        <div
          class="row metrics"
          :class="{
            'mr-3': container.size < container.breakpoints.marginRight,
          }"
        >
          <div class="messages col-md-7 col-12 p-3 card">
            <div class="row px-2">
              <div class="col-12">
                <h2 class="h4">Messages</h2>
              </div>
            </div>
            <div class="row px-2">
              <div class="messages-cell col">
                <span class="large-metric">{{
                  findMetric("TotalMessages")
                }}</span>
                <label>Total</label>
              </div>
              <div class="messages-cell col">
                <span>{{ findMetric("Sent") }}</span>
                <label>Sent</label>
              </div>

              <div class="messages-cell col">
                <span>{{ findMetric("Received") }}</span>
                <label>Received</label>
              </div>
              <div class="messages-cell col">
                <span>{{ findMetric("DeliveredPercentage") }}</span>
                <span class="small-metric">{{ findMetric("Delivered") }}</span>
                <label>Delivered</label>
              </div>
            </div>
          </div>
          <div class="contacts col-12 col-md p-3 card">
            <div class="row px-2">
              <div class="col-12">
                <h2 class="h4">Contacts</h2>
              </div>
            </div>
            <div class="row px-2 align-items-end">
              <div class="contacts-cell col">
                <span class="large-metric">{{ findMetric("Reached") }}</span>
                <label>Reached</label>
              </div>
              <div class="contacts-cell col">
                <span>{{ findMetric("RepliedPercentage") }}</span>
                <span class="small-metric">{{ findMetric("Replied") }}</span>
                <label>Replied</label>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ResponsiveContainer>
  </div>
</template>
<script>
import ResponsiveContainer from "@/components/ResponsiveContainer";

export default {
  name: "MetricsRow",
  components: {
    ResponsiveContainer,
  },
  props: {
    metrics: {
      required: true,
    },
  },
  methods: {
    findMetric(metricName) {
      if (this.metrics) {
        let total = 0;
        this.metrics.forEach((metric) => {
          if (metric.value > 0) {
            total++;
          }
        });
        let foundMetric = this.metrics.find(
          (metric) => metric.name === metricName
        );
        if (total > 0) {
          if (
            foundMetric.name === "DeliveredPercentage" ||
            foundMetric.name === "RepliedPercentage"
          ) {
            return foundMetric
              ? Math.round(foundMetric.value * 100).toLocaleString() + "%"
              : "";
          } else {
            return foundMetric ? foundMetric.value.toLocaleString() : "";
          }
        } else {
          if (
            foundMetric.name !== "Delivered" &&
            foundMetric.name !== "Replied"
          ) {
            return "-";
          } else {
            return "";
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
@media screen and (max-width: 768px) {
}
.metrics {
  color: #555555;
  h4,
  .h4 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  span {
    font-size: 1.5em;
  }
  .small-metric {
    font-size: 1em;
    color: $gray-subtitle;
    margin-left: 5px;
  }
  label {
    display: block;
    font-weight: normal;
    font-size: 1em;
    color: $gray-subtitle;
    margin-bottom: 0;
  }
  .large-metric {
    font-size: 2em;
    line-height: 1;
  }
}
</style>
