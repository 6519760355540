<template>
  <div>
    <WebChatConversationHeader
      v-if="conversation"
      :conversation="conversation"
      :showVisitorDetails="showVisitorDetails"
      :isMobileView="isMobileView"
    />
    <WebChatConversationView
      v-if="conversation"
      :conversation="conversation"
      :messages="messages"
    />
    <WebChatConversationFooter
      v-if="conversation"
      :conversation="conversation"
      :templateList="templateList"
      :botId="botId"
    />
    <div v-else class="text-muted empty h-100">
      <div class="h3">Select a conversation</div>
    </div>
    <WebChatDeleteModal
      v-if="conversation && conversation.conversationId"
      modalId="delete-chat-conversation-modal"
      :show="showDeleteModal_"
      :conversationId="conversation.conversationId"
      @close="hideDeleteModal"
    />
  </div>
</template>
<script>
import WebChatConversationHeader from "./WebChatConversationHeader";
import WebChatConversationView from "./WebChatConversationView";
import WebChatConversationFooter from "./WebChatConversationFooter";
import WebChatDeleteModal from "@/components/WebChatDeleteModal";
import eventBus from "@/util/eventBus";
export default {
  name: "WebChatConversation",
  components: {
    WebChatConversationHeader,
    WebChatConversationView,
    WebChatConversationFooter,
    WebChatDeleteModal,
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
    botId: {
      type: Number,
      required: true,
    },
    conversation: {
      type: Object,
      required: false,
    },
    templateList: {
      type: Array,
      required: true,
    },
    showVisitorDetails: {
      type: Boolean,
      required: true,
    },
    isMobileView: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showDeleteModal_: false,
    };
  },
  async created() {
    eventBus.addEventListener("open-delete-modal", this.showDeleteModal);
  },
  destroyed() {
    eventBus.removeEventListener("open-delete-modal", this.showDeleteModal);
  },
  methods: {
    showDeleteModal() {
      this.showDeleteModal_ = true;
    },
    hideDeleteModal() {
      this.showDeleteModal_ = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
.empty {
  top: 50%;
  left: 62%;
  position: absolute;
}
</style>
