<template>
  <div class="campaign-picker">
    <button
      class="add-campaign"
      @click="togglePopover()"
      :id="`add-campaign-${message.messageId}`"
      v-b-tooltip.hover="'Add to Campaign'"
    >
      &plus;<span class="sr-only">Add Campaign</span>
    </button>
    <BPopover
      :target="`add-campaign-${message.messageId}`"
      placement="auto"
      triggers="click, blur"
      customClass="campaign-picker-popover"
      ref="popover"
      :show.sync="isPopoverOpen"
    >
      <template v-slot:title> Add to Campaign </template>
      <div class="list-group">
        <button
          type="button"
          class="list-group-item list-group-item-action"
          @click="addCampaign(campaign.id)"
          v-for="campaign in campaigns"
          :key="campaign.id"
        >
          <span class="title">{{ campaign.name }}</span>
          <span class="text-truncate desc">{{ campaign.description }}</span>
        </button>
      </div>
    </BPopover>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../store";
import api from "../util/api";
import { BPopover } from "bootstrap-vue";
import { ACTIONS, INBOX_TYPES } from "../util/constants";
import analytics from "@/util/analytics";
import { VBTooltip } from "bootstrap-vue";

Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "CampaignPicker",
  store,
  props: {
    message: {
      required: true,
    },
  },
  components: {
    BPopover,
  },
  data() {
    return {
      isPopoverOpen: false,
    };
  },
  computed: {
    campaigns() {
      return this.$store.state.campaigns.active;
    },
  },
  methods: {
    addCampaign(id) {
      let params = { campaignId: id },
        request;

      this.$root.$emit("bv::hide::popover");

      if (this.$store.state.inbox.activeInbox.type === INBOX_TYPES.HIGH_SPEED) {
        params = Object.assign(params, {
          messageId: this.message.messageId,
          contactId: this.$store.state.contact.details.id,
        });
        request = api.post(
          `/highspeedinbox/${this.$store.state.inbox.activeInbox.highSpeedId}/campaign`,
          params
        );
      } else {
        let direction = this.message.direction || "";

        if (direction.toLowerCase() === "incoming") {
          request = api.patch(
            `/incomingmessages/${this.message.messageId}`,
            params
          );
        } else {
          request = api.patch(
            `/outgoingmessage/${this.message.messageId}`,
            params
          );
        }
      }

      return request.then(() => {
        this.$store.dispatch(ACTIONS.UPDATE_CAMPAIGN, {
          campaignId: id,
          messageId: this.message.messageId,
        });
        analytics.track("Added Campaign to Message");
      });
    },
    onClose() {
      this.isPopoverOpen = false;
    },
    togglePopover() {
      this.$root.$emit("bv::hide::tooltip");
      this.isPopoverOpen = !this.isPopoverOpen;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "../scss/utilities";

.add-campaign {
  background-color: #e6e6e6;
  border: 1px solid $input-border;
  border-radius: 3px;
  color: #333;
  cursor: pointer;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  line-height: 1rem;
  overflow: hidden;
  padding: 2px 6px 3px;
  transition: 0.2s;
  vertical-align: middle;
}
</style>

<style lang="scss">
@import "../scss/utilities";

.campaign-picker-popover.popover.b-popover {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

  &.bs-popover-bottom {
    .arrow:after {
      border-bottom-color: $popover-header-bg;
    }
  }

  .popover-header {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    padding: 0.75rem 1.25rem;
    text-transform: uppercase;
  }

  .popover-body {
    max-height: 275px;
    overflow: auto;
    padding: 0;

    .list-group {
      margin: 0;
    }

    .list-group-item {
      border-left: 0;
      border-right: 0;

      &:first-child {
        border-top: 0;
      }
      &:last-child {
        border-bottom: 0;
      }
    }

    .title {
      display: block;
      margin: 0;
      font-size: 14px;
    }

    .desc {
      color: $text-muted;
      display: block;
      font-size: 12px;
      font-weight: $font-weight-light;
    }
  }
}
</style>
