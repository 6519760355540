<template>
  <div>
    <div class="alert alert-default" v-if="!oooFeatureFlagEnabled">
      <p>
        Configure automatic replies to incoming messages when they match a
        keyword.
        <span v-if="!isPersonal"
          >This feature is available to Administrators.&nbsp;</span
        >
        <a
          target="_blank"
          title="Learn more"
          href="https://help.mongooseresearch.com/article/527-auto-reply-overview"
          >Learn more</a
        >
      </p>
    </div>

    <div
      :class="
        oooFeatureFlagEnabled
          ? 'col-lg-8 col-md-12 px-0'
          : 'row flex-md-nowrap mt-4'
      "
    >
      <div class="col-md-auto" v-if="!oooFeatureFlagEnabled">
        <div class="status-card card">
          <div class="card-header">
            <h3 class="card-title">
              <StatusIcon :enabled="!settings.disabled" />
              Status
            </h3>
          </div>
          <div class="card-body">
            <div v-if="settings.disabled && settings.disabledOn">
              <div class="form-group mt-0">
                <div class="label-readonly">Off Since</div>
                {{ disabledOn }}
              </div>
              <div v-if="showModifiedBy(disabledBy)" class="form-group">
                <div class="label-readonly">Turned Off By</div>
                {{ disabledBy }}
              </div>
            </div>
            <div v-if="!settings.disabled && settings.enabledOn">
              <div class="form-group mt-0">
                <div class="label-readonly">On Since</div>
                {{ enabledOn }}
              </div>
              <div v-if="showModifiedBy(enabledBy)" class="form-group">
                <div class="label-readonly">Turned On By</div>
                {{ enabledBy }}
              </div>
            </div>

            <button
              type="button"
              class="btn btn-light"
              @click="toggle"
              :disabled="toggleDisabled"
            >
              <MgIcon name="power-off" scale=".75"></MgIcon>
              Turn
              <span v-if="settings.disabled">ON</span>
              <span v-else>OFF</span>
            </button>
          </div>
        </div>
      </div>

      <div v-else>
        <div class="toggle-box w-100" :class="{ 'active-border': enabled }">
          <div class="d-flex">
            <BFormCheckbox
              class="checkbox-switch"
              :disabled="toggleDisabled"
              v-model="enabled"
              size="lg"
              @change="toggle"
              switch
              ><span class="sr-only"
                >Turn out of office {{ enabled ? "off" : "on" }}</span
              ></BFormCheckbox
            >
            <span class="checkbox-label">Auto Reply</span>
          </div>
          <div class="mt-2">
            Turned
            <span class="text-uppercase" v-if="enabled">on</span>
            <span class="text-uppercase" v-else>off</span>
            <span>
              <span v-if="showModifiedBy(enabledBy)">
                {{ `by ${enabledBy}` }}
              </span>
              <span v-else-if="showModifiedBy(disabledBy)">
                {{ `by ${disabledBy}` }}
              </span>
              <span> {{ lastToggledString }}</span>
            </span>
          </div>
        </div>
      </div>

      <div
        :class="oooFeatureFlagEnabled ? 'h-100 flex-grow-0 w-100' : 'col w-50 '"
      >
        <div class="card mt-4 mt-md-0">
          <div class="card-header">
            <h2 class="h3 card-title">Auto Reply Message</h2>
          </div>
          <div class="card-body">
            <form @submit.prevent="save">
              <div class="form-group mt-0">
                <label for="keyword-tags" class="mb-1">Keywords</label>
                <div class="form-text small mb-2">
                  Type or paste keywords or phrases separated by commas and
                  press enter.
                </div>
                <b-form-tags
                  add-button-variant="primary"
                  :disabled="featureDisabled"
                  input-id="keyword-tags"
                  v-model="form.keywords"
                  :placeholder="!featureDisabled ? '...' : ''"
                  duplicate-tag-text="Duplicate keywords"
                  :invalid-tag-text="invalidKeywordText"
                  tag-remove-label="Remove keyword"
                  tag-removed-label="Keyword removed"
                  remove-on-delete
                  separator=","
                  tag-pills
                  tag-variant="blue"
                  size="lg"
                  :tag-validator="keywordValidator"
                  aria-label="Keywords"
                ></b-form-tags>
              </div>
              <div class="form-group">
                <label for="confirmationMessage">Confirmation Message</label>
                <div class="message-body-container mb-0">
                  <b-form-textarea
                    id="confirmationMessage"
                    v-model="form.confirmationMessage"
                    maxlength="306"
                    rows="3"
                    class="message-body"
                    :disabled="featureDisabled"
                    aria-label="Confirmation Message"
                  ></b-form-textarea>
                  <div class="character-progress">
                    <div
                      :style="{
                        width:
                          (confirmationMessageCharacterCount / 306) * 100 + '%',
                      }"
                      class="character-progress-bar"
                      :class="{
                        'max-character-count':
                          confirmationMessageCharacterCount === 306,
                      }"
                    ></div>
                    <div
                      v-if="confirmationMessageCharacterCount === 306"
                      class="max-character-text"
                    >
                      Character Limit Reached
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mt-0">
                <div class="form-row flex-nowrap align-items-center ml-0 mr-0">
                  <div class="d-flex">
                    <Checkbox v-model="form.tagContact">Apply Tag</Checkbox>
                    <MgIcon
                      name="info-circle"
                      class="ml-1"
                      v-b-tooltip.html="{
                        title:
                          'Create or select a tag to track contacts who have triggered this auto reply. Tags can be used in criteria-based segments as a way to follow up with these contacts in the future.',
                      }"
                    />
                  </div>
                  <div class="col w-25">
                    <TagPicker
                      v-model="form.tag"
                      class="ml-1"
                      :disabled="!form.tagContact"
                      :allowCreate="true"
                      :style="{
                        visibility: form.tagContact ? 'visible' : 'hidden',
                      }"
                    />
                  </div>
                </div>
              </div>
              <div>
                <button
                  class="btn btn-primary"
                  type="submit"
                  :disabled="formInvalid"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-0" v-if="oooFeatureFlagEnabled" />
  </div>
</template>

<script>
import Vue from "vue";
import Checkbox from "@/components/Checkbox";
import { BFormCheckbox } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";
import StatusIcon from "@/components/StatusIcon";
import TagPicker from "@/pages/tags/TagPicker";
import store from "@/store";
import analytics from "@/util/analytics";
import moment from "moment";
import { FormTagsPlugin, FormTextareaPlugin, ToastPlugin } from "bootstrap-vue";
import Toast from "@/components/Toast";
import { VBTooltip } from "bootstrap-vue";
import _ from "lodash";
import {
  ACTIONS,
  GETTERS,
  INBOX_TYPES,
  RESERVED_KEYWORDS,
} from "@/util/constants";
import { formatDate } from "../../util/dates";

Vue.use(FormTagsPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(ToastPlugin);
Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "AutomationSettings",
  store,
  components: {
    Checkbox,
    MgIcon,
    StatusIcon,
    TagPicker,
    BFormCheckbox,
  },
  created() {},
  data() {
    return {
      form: {
        keywords: [],
        confirmationMessage: "",
        tagContact: false,
        tag: null,
      },
      invalidKeywordText: "",
      enabled: false,
    };
  },
  computed: {
    disabledBy() {
      return this.$store.getters[GETTERS.USER_NAME_BY_ID](
        this.settings.disabledBy
      );
    },
    enabledBy() {
      return this.$store.getters[GETTERS.USER_NAME_BY_ID](
        this.settings.enabledBy
      );
    },
    disabledOn() {
      return moment
        .tz(
          moment.utc(this.settings.disabledOn),
          this.$store.state.config.timeZone
        )
        .format("M/D/YY, h:mm A");
    },
    enabledOn() {
      return moment
        .tz(
          moment.utc(this.settings.enabledOn),
          this.$store.state.config.timeZone
        )
        .format("M/D/YY, h:mm A");
    },
    formInvalid() {
      if (this.oooFeatureFlagEnabled) {
        let isTagEmpty = this.form.tagContact && !this.form.tag;
        let isFormEmpty =
          !this.form.confirmationMessage || !this.form.keywords.length;
        return this.featureDisabled || isTagEmpty || isFormEmpty;
      } else {
        // Too scared to touch this...
        return (
          this.featureDisabled ||
          (!this.settings.disabled &&
            (!this.form.confirmationMessage ||
              !this.form.keywords ||
              (this.form.tagContact && !this.form.tag)))
        );
      }
    },
    isPersonal() {
      return this.$store.state.inbox.activeInbox.type === INBOX_TYPES.PERSONAL;
    },
    featureDisabled() {
      if (this.oooFeatureFlagEnabled) {
        return false;
      } else {
        return (
          !this.$store.state.config.isAdmin &&
          this.$store.state.inbox.activeInbox.type !== INBOX_TYPES.PERSONAL
        );
      }
    },
    toggleDisabled() {
      return (
        this.featureDisabled ||
        !this.settings.keywords ||
        !this.settings.confirmationMessage
      );
    },
    settings() {
      return this.$store.state.automation.settings;
    },
    showModifiedBy() {
      return (user) =>
        !!user &&
        this.$store.state.inbox.activeInbox.type !== INBOX_TYPES.PERSONAL;
    },
    confirmationMessageCharacterCount() {
      return this.form.confirmationMessage
        ? this.form.confirmationMessage.length
        : 0;
    },
    oooFeatureFlagEnabled() {
      return this.$store.getters[GETTERS.FEATURE_ENABLED](
        "rollout-shared-inbox-out-of-office"
      );
    },
    lastToggledString() {
      if (
        !(
          _.isNull(this.settings.enabledOn) ||
          _.isUndefined(this.settings.enabledOn)
        )
      ) {
        return `• ${formatDate(this.settings.enabledOn, "MMMM D[,] h:mma")}`;
      } else if (
        !(
          _.isNull(this.settings.disabledOn) ||
          _.isUndefined(this.settings.disabledOn)
        )
      ) {
        return `• ${formatDate(this.settings.disabledOn, "MMMM D[,] h:mma")}`;
      } else {
        return "";
      }
    },
  },
  methods: {
    keywordValidator(keyword) {
      this.invalidKeywordText = "Invalid keywords";

      let isReserved = RESERVED_KEYWORDS.includes(keyword.toUpperCase());
      if (isReserved) {
        this.invalidKeywordText = "Reserved keywords";
        return false;
      }

      let keywords = [...this.form.keywords].map((k) => k.toLowerCase());
      let isDuplicate = keywords.includes(keyword.toLowerCase());
      if (isDuplicate) {
        this.invalidKeywordText = "Duplicate keywords";
        return false;
      }
      return true;
    },
    save() {
      return store
        .dispatch(ACTIONS.UPDATE_AUTOMATION_SETTINGS, this.form)
        .then((res) => {
          let toastBody = this.$createElement(Toast, {
            props: {
              message: "Auto-reply settings have been saved.",
            },
          });
          this.$bvToast.toast([toastBody], {
            variant: "success",
            noCloseButton: true,
            solid: true,
          });
          analytics.track("Updated Auto Reply Settings", {
            "Auto Reply Enabled": !this.settings.disabled,
            "Account Type": this.isPersonal ? "Personal" : "Shared",
            "Inbox Name": !this.isPersonal
              ? this.$store.state.inbox.activeInbox.name
              : undefined,
          });
          return res;
        })
        .catch((err) => {
          let toastBody = this.$createElement(Toast, {
            props: {
              message: "There was an error saving settings.",
            },
          });
          this.$bvToast.toast([toastBody], {
            variant: "danger",
            noCloseButton: true,
            solid: true,
          });
          return err;
        });
    },
    toggle() {
      let disableFeature = !this.settings.disabled;
      store
        .dispatch(ACTIONS.UPDATE_AUTOMATION_STATUS, disableFeature)
        .then((res) => {
          analytics.track(
            `Turned ${disableFeature ? "Off" : "On"} Auto Reply`,
            {
              "Account Type": this.isPersonal ? "Personal" : "Shared",
              "Inbox Name": !this.isPersonal
                ? this.$store.state.inbox.activeInbox.name
                : undefined,
            }
          );
          return res;
        })
        .catch((err) => {
          let toastBody = this.$createElement(Toast, {
            props: {
              message: "There was an error enabling/disabling automation.",
            },
          });
          this.$bvToast.toast([toastBody], {
            variant: "danger",
            noCloseButton: true,
            solid: true,
          });
          return err;
        });
    },
  },
  watch: {
    settings: {
      handler() {
        this.form.keywords = this.settings.keywords || [];
        this.form.confirmationMessage = this.settings.confirmationMessage;
        this.form.tagContact = !!(this.settings.tag && this.settings.tag.id);
        this.form.tag = this.settings.tag;
        this.enabled = !this.$store.state.automation.settings.disabled;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";

::v-deep {
  .invalid-feedback,
  .b-form-tags-input,
  .b-form-tag-content,
  small.form-text.text-muted {
    font-size: $font-size-base;
  }

  .form-text.text-muted {
    color: $danger !important;
    font-weight: normal;
  }

  .b-form-tag-content {
    letter-spacing: 0.4px;
  }
}

.status-card {
  width: 260px;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.card-title {
  color: #212529;
}

.label-readonly {
  color: $gray-700;
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
}

.character-progress-bar {
  position: relative;
  transition: width linear 0.3s;
}

.character-progress-bar:after {
  position: absolute;
  content: "";
  margin-top: -2px;
  width: calc(100% - 1px);
  margin-left: 1px;
  border-bottom: 2px solid $accent-alt;
  border-bottom-left-radius: 3px;
  height: 3px;
}

.max-character-text {
  color: theme-color(danger);
  font-size: $font-size-sm;
  margin-bottom: -10px;
  padding: 4px 2px 0;
}

.max-character-count:after {
  border-color: theme-color(danger);
  border-bottom-right-radius: 3px;
}

.toggle-box {
  border: 2px solid #b1bac4;
  border-radius: 4px;
  padding: 24px 30px;
  margin: 20px 0 40px 0;
  color: #212529;

  &.active-border {
    border-color: #318ce7;
  }
}

.checkbox-label {
  font-size: 18px;
  font-weight: 400;
}

.checkbox-switch {
  padding-top: 3px;
  width: 20px;
}

::v-deep .custom-control-input:checked {
  ~ .custom-control-label::before {
    background-color: #318ce7 !important;
    border-color: #318ce7 !important;
  }
}
</style>
