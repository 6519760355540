<template>
  <div class="card" :class="{ 'card-link': link }">
    <div class="mg-card h-100" @click="cardClicked()">
      <div v-if="borderedHeader" class="bordered-header card-title">
        <MgIcon
          v-if="borderedHeaderIcon"
          :name="borderedHeaderIcon"
          :scale="'1'"
        ></MgIcon>
        {{ borderedHeader }}
      </div>
      <div
        class="card-body d-flex flex-column h-100"
        :class="{
          'justify-content-center': horizontalLayout,
          'justify-content-between': !horizontalLayout,
        }"
      >
        <div>
          <div v-if="strongHeader" class="strong-header card-title">
            {{ strongHeader }}
          </div>

          <div
            :class="{ 'd-flex justify-content-space-evenly': horizontalLayout }"
          >
            <div
              v-if="bigHeader"
              class="h1 card-title"
              :class="{ 'd-flex align-items-center': horizontalLayout }"
            >
              {{ bigHeader }}
              <span
                v-if="bigSubHeader"
                class="big-sub-header"
                :class="{ 'ml-2': horizontalLayout }"
                >{{ bigSubHeader }}</span
              >
            </div>

            <div :class="{ 'horizontal-body': horizontalLayout }">
              <div v-if="header" class="label h5 card-title">
                {{ header }}
              </div>
              <div v-else-if="mediumHeader" class="h4 card-title">
                {{ mediumHeader }}
              </div>
              <p v-if="description" class="card-text">
                {{ description }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="hasAutomatedExport"
          class="d-flex align-items-center automated-export-container"
        >
          <div>
            <StatusIcon :enabled="hasAutomatedOn" />
          </div>
          <div class="status-message">
            <strong>Automated Export</strong>
            <p>
              {{ hasAutomatedOn ? "On" : "Off" }} since
              {{ formatDate(automatedExportDate) }}
            </p>
          </div>
        </div>
        <button
          v-if="button"
          class="btn export-btn"
          @click="buttonClicked()"
          style="margin-top: 8px"
        >
          Export
        </button>
        <a v-if="link" class="card-export"
          >{{ link }}&nbsp;&nbsp;<MgIcon
            class="mg-icon"
            name="long-arrow-right"
          ></MgIcon
        ></a>

        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import MgIcon from "@/components/MgIcon";
import StatusIcon from "@/components/StatusIcon";
import { formatDate } from "@/util/dates";
export default {
  name: "MgCard",
  components: {
    MgIcon,
    StatusIcon,
  },
  props: {
    strongHeader: {
      type: [String, Number],
      required: false,
      default: "",
    },
    bigHeader: {
      type: [String, Number],
      required: false,
      default: "",
    },
    bigSubHeader: {
      type: [String, Number],
      required: false,
      default: "",
    },
    borderedHeader: {
      type: [String, Number],
      required: false,
      default: "",
    },
    borderedHeaderIcon: {
      type: [String, Number],
      required: false,
      default: "",
    },
    header: {
      type: [String, Number],
      required: false,
      default: "",
    },
    mediumHeader: {
      type: [String, Number],
      required: false,
      default: "",
    },
    description: {
      type: [String, Number],
      required: false,
      default: "",
    },
    link: {
      type: String,
      required: false,
      default: "",
    },
    button: {
      type: String,
      required: false,
      default: "",
    },
    horizontalLayout: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasAutomatedOn: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasAutomatedExport: {
      type: String,
      required: false,
      default: "",
    },
    automatedExportDate: {
      type: String,
      required: false,
      default: "",
    },
  },

  methods: {
    cardClicked() {
      this.$emit("cardClicked", this);
    },
    buttonClicked() {
      this.$emit("buttonClicked", this);
    },
    formatDate(date) {
      return formatDate(date, "M/D/YY[,] h:mm A");
    },
  },

  data() {
    return {
      hover: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/_utilities";

.mg-card .card-body .h5.card-title {
  font-weight: $font-weight-bold;
}
.mg-card .card-body p {
  color: $gray-subtitle;
  font-size: $font-size-sm;
}
.mg-card .card-body a.card-export {
  color: #006ba8;
  display: block;
  font-size: $font-size-xs-ratio;
  font-weight: 600;
  opacity: 0;
  padding-top: 5px;
  text-decoration: none;
  text-transform: uppercase;
}
.mg-icon {
  line-height: 18px;
  vertical-align: middle;
}
.card-link:hover {
  box-shadow: 1px 1px 5px 3px #d4d4d4;
  cursor: pointer;
  .mg-card .card-body a.card-export {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
  }
}
.big-sub-header {
  color: $gray-light;
  word-spacing: -0.25rem;
}
.bordered-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: $card-cap-bg;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-size: 1.5em;
}
.bordered-header .icon {
  position: relative;
  margin-right: 5px;
  top: -2px;
}
.strong-header {
  font-size: 2em;
  font-weight: $font-weight-normal;
  line-height: 1;
}
.label {
  font-size: 1em;
}
.horizontal-body {
  width: 50%;

  @include media-breakpoint-up(lg) {
    width: 65%;
  }
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}
.status-message {
  padding: 8px;
  margin: auto 4px;
  height: 60px;
}
.automated-export-container {
  margin-top: 20px;
  border-top: 1px solid #e2e2e2;
  padding-top: 8px;
}
.export-btn {
  border-color: #dee2e6;
  background-color: #f9fafb;
  color: #212529;
  &:hover {
    background-color: #e2e7ec;
    border-color: #dae1e7;
  }
}
</style>
