<template>
  <div class="tag-picker d-flex align-items-center">
    <div v-if="selectedTag" class="tag mw-100">
      <MgTag
        :title="selectedTag.name"
        :icon="''"
        :bgColor="'gray'"
        :hoverClose="false"
        @remove="remove"
      ></MgTag>
    </div>
    <div v-if="!selectedTag" class="d-flex flex-grow-1">
      <Typeahead
        class="flex-grow-1"
        v-model="query"
        :data="tags"
        :disabled="disabled"
        :serializer="(item) => item.name"
        @hit="select"
        @input="search"
        :minMatchingChars="minCharacters"
        :placeholder="allowCreate ? 'Create or Select a Tag' : 'Select a Tag'"
      />
      <div class="d-flex align-items-center">
        <button
          v-if="showCreateButton"
          @click="create"
          type="button"
          class="btn btn-sm btn-primary ml-3"
        >
          Create
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Typeahead from "@/components/Typeahead";
import { ToastPlugin } from "bootstrap-vue";
import MgTag from "@/components/MgTag";
import api from "@/util/api";
import Toast from "@/components/Toast";
import analytics from "@/util/analytics";

Vue.use(ToastPlugin);

export default {
  name: "TagPicker",
  inheritAttrs: false,
  components: {
    Typeahead,
    MgTag,
  },
  props: {
    allowCreate: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      default: null,
    },
  },
  data() {
    return {
      minCharacters: 1,
      query: "",
      selectedTag: null,
      tags: [],
    };
  },
  computed: {
    showCreateButton() {
      if (!this.allowCreate) return false;

      let matchedTag = this.tags.find(
        (tag) => tag.name.toLowerCase() === this.query.toLowerCase()
      );
      return this.query && !matchedTag;
    },
  },
  methods: {
    create() {
      return api
        .post(`/tags`, {
          name: this.query,
        })
        .then((response) => {
          this.select(response);
          analytics.track("Created Tag", {
            "Created Source": "Auto Reply",
          });
        })
        .catch((err) => {
          const toastBody = this.$createElement(Toast, {
            props: {
              message: "There was an error creating the tag.",
            },
          });
          this.$bvToast.toast([toastBody], {
            variant: "danger",
            noCloseButton: true,
            solid: true,
          });
          return err;
        });
    },
    remove() {
      this.selectedTag = null;
    },
    select($event) {
      this.query = "";
      this.selectedTag = $event;
    },
    search() {
      let query = this.query;
      let headers = {
        "x-disable-loading": true,
      };
      return api
        .get(`/tags/search`, { query, headers })
        .then((response) => {
          this.tags = response;
        })
        .catch((err) => {
          const toastBody = this.$createElement(Toast, {
            props: {
              message: "There was an error searching tags.",
            },
          });
          this.$bvToast.toast([toastBody], {
            variant: "danger",
            noCloseButton: true,
            solid: true,
          });
          return err;
        });
    },
  },
  watch: {
    selectedTag(newVal) {
      this.$emit("input", newVal);
    },
    value: {
      handler(newVal) {
        this.selectedTag = newVal;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";

.tag-picker {
  min-height: $input-height;
}

.tag {
  font-size: $font-size-lg;
  min-height: 28px;

  .badge {
    background: #eee;
    font-size: $font-size-base;
  }
}
</style>
