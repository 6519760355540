import Vue from "vue";
import Vuex from "vuex";
import state from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import automationModule from "./modules/automation";
import scheduledModule from "./modules/scheduled";
import sharedInboxModule from "./modules/sharedInbox";
import contactModule from "./modules/contact";
import webChatModule from "./modules/webChat";
import inboxModule from "./modules/inbox";
import tagModule from "./modules/tag";
import analyticsModule from "./modules/analytics";
import chatAnalyticsModule from "../pages/reporting/chat/chatAnalyticsStore";
import recruitModule from "../pages/integrations/recruit/recruitStore";
import renxtModule from "../pages/integrations/renxt/renxtStore";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules: {
    automation: automationModule,
    scheduled: scheduledModule,
    contact: contactModule,
    tag: tagModule,
    webChat: webChatModule,
    recruit: recruitModule,
    renxt: renxtModule,
    inbox: inboxModule,
    analytics: analyticsModule,
    chatAnalytics: chatAnalyticsModule,
    sharedInbox: sharedInboxModule,
  },
  plugins: [
    createPersistedState({
      paths: ["inbox.inboxFilters"],
      key: "cadence.filters",
    }),
  ],
});

window.store = store;

export default store;
