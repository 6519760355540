<template>
  <table class="table table-striped table-borderless table-sm mb-0">
    <thead class="thead-light">
      <tr>
        <th scope="col">Status</th>
        <th scope="col">Started</th>
        <th scope="col">Completed</th>
        <th scope="col" class="text-right">Total</th>
        <th scope="col" class="text-right">Success</th>
        <th scope="col" class="text-right">Error</th>
      </tr>
    </thead>
    <tbody class="font-weight-light">
      <tr v-for="item in items" :key="item.id">
        <td>{{ item.status }}</td>
        <td>{{ formatDate(item.started) }}</td>
        <td>{{ formatDate(item.completed) }}</td>
        <td class="text-right">
          <a
            v-if="item.totalRecords > 0 && item.totalFile.length > 0"
            :href="item.totalFile"
            @click="onFileDownload('totalFile')"
          >
            <MgIcon
              name="download"
              alt="download icon"
              class="on-row-hover mr-n1"
            />
            <span>
              {{ item.totalRecords }}
            </span>
          </a>
          <span v-else>
            {{ item.totalRecords }}
          </span>
        </td>
        <td class="text-right">
          <a
            v-if="item.successRecords > 0 && item.successFile.length > 0"
            :href="item.successFile"
            @click="onFileDownload('successFile')"
          >
            <MgIcon
              name="download"
              alt="download icon"
              class="on-row-hover mr-n1"
            />

            <span>
              {{ item.successRecords }}
            </span>
          </a>
          <span v-else>
            {{ item.successRecords }}
          </span>
        </td>
        <td class="text-right">
          <a
            v-if="item.errorRecords > 0 && item.errorFile.length > 0"
            :href="item.errorFile"
            @click="onFileDownload('errorFile')"
          >
            <MgIcon
              name="download"
              alt="download icon"
              class="on-row-hover dl-icon"
            />
            <span>
              {{ item.errorRecords }}
            </span>
          </a>
          <span v-else>
            {{ item.errorRecords }}
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import MgIcon from "@/components/MgIcon";
import { formatDate } from "@/util/dates";

export default {
  name: "IntegrationsImportTable",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: { MgIcon },
  methods: {
    formatDate(date) {
      return formatDate(date, "M/D/YY[,] h:mm A");
    },
    onFileDownload(fileType) {
      this.$emit("onFileDownload", fileType);
    },
  },
};
</script>
