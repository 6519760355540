<template>
  <span>
    <StatusIcon :enabled="enabled" />
  </span>
</template>

<script>
import StatusIcon from "@/components/StatusIcon";
import serializeProfile from "@/util/profile";
import _ from "lodash";
import eventBus from "@/util/eventBus";
import api from "@/util/api";
import store from "@/store";

export default {
  name: "OutOfOfficeStatusIndicator",
  store,
  components: { StatusIcon },
  props: {
    inboxId: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      enabled: false,
    };
  },
  computed: {
    isPersonalInbox() {
      return _.isNull(this.inboxId) || _.isUndefined(this.inboxId);
    },
  },
  async created() {
    let oooConfig;
    const userProfile = serializeProfile();
    if (this.isPersonalInbox) {
      oooConfig = await api.get("v2/profile/outofoffice");
      if (oooConfig.isScheduled) {
        this.enabled = oooConfig.enabled && userProfile.outOfOfficeIsActive;
      }
    } else {
      oooConfig = await api.get(
        `v2/groupAccounts/${this.inboxId}/outofoffice`
      );
      if (oooConfig.isScheduled) {
        this.enabled = oooConfig.enabled && oooConfig.outOfOfficeIsActive;
      }
    }
    if (!oooConfig.isScheduled) {
      this.enabled = oooConfig.enabled;
    }

    eventBus.addEventListener(
      "toggle-out-of-office",
      this.toggleOutOfOfficeEvent
    );
  },
  destroyed() {
    eventBus.removeEventListener("toggle-out-of-office");
  },
  methods: {
    async toggleOutOfOffice() {
      if (this.isPersonalInbox) {
        const userProfile = serializeProfile();
        this.enabled = userProfile.outOfOfficeConfig
          ? userProfile.outOfOfficeConfig.enabled
          : false;
      } else {
        let config = await api.get(
          `groupAccounts/${this.inboxId}/outofoffice`,
          { headers: { "x-disable-loading": true } }
        );
        this.enabled = config.enabled;
      }
    },
    async toggleOutOfOfficeEvent() {
      setTimeout(this.toggleOutOfOffice, 500);
    },
  },
};
</script>

<style lang="scss" scoped></style>
