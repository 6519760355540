var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"optout-settings my-3 p-3 d-flex justify-content-sm-between align-items-center"},[(_vm.contact && _vm.contact.departments && _vm.contact.departments.length > 1)?_c('div',{staticClass:"d-flex"},[_c('BDropdown',{attrs:{"id":"opt-out-dropdown","text":"Opt-out","variant":"outline-dark","no-flip":""}},_vm._l((_vm.contact.departments),function(department,index){return _c('BDropdownGroup',{key:index,staticClass:"opt-out-menu"},[_c('div',{staticClass:"dept-name"},[_vm._v(_vm._s(department.name))]),_c('BFormCheckbox',{staticClass:"text-uppercase",attrs:{"disabled":department.muted || department.autoOptOut || _vm.lockedByOtherUser,"aria-label":department.optedOut ? 'Opted-out' : 'Opt-out',"switch":""},on:{"change":function($event){return _vm.optOut(department)}},model:{value:(department.optedOut),callback:function ($$v) {_vm.$set(department, "optedOut", $$v)},expression:"department.optedOut"}},[_vm._v(_vm._s(department.optedOut ? "Opted-out" : "Opt-out"))])],1)}),1),_c('BDropdown',{attrs:{"id":"block-dropdown","text":"Block","variant":"outline-dark","no-flip":""}},_vm._l((_vm.contact.departments),function(department,index){return _c('BDropdownGroup',{key:index,staticClass:"block-menu"},[_c('div',{staticClass:"dept-name"},[_vm._v(_vm._s(department.name))]),_c('BFormCheckbox',{staticClass:"text-uppercase",attrs:{"aria-label":department.muted ? 'Blocked' : 'Block',"switch":""},on:{"change":function($event){return _vm.block(department)}},model:{value:(department.muted),callback:function ($$v) {_vm.$set(department, "muted", $$v)},expression:"department.muted"}},[_vm._v(_vm._s(department.muted ? "Blocked" : "Block"))])],1)}),1)],1):(_vm.currentDepartment)?_c('div',{staticClass:"d-flex"},[_c('BFormCheckbox',{staticClass:"text-uppercase opt-out",attrs:{"disabled":_vm.currentDepartment.muted ||
        _vm.currentDepartment.autoOptOut ||
        _vm.lockedByOtherUser,"aria-label":_vm.currentDepartment.optedOut ? 'Opted-out' : 'Opt-out',"switch":"","size":"lg"},on:{"change":function($event){return _vm.optOut(_vm.currentDepartment)}},model:{value:(_vm.currentDepartment.optedOut),callback:function ($$v) {_vm.$set(_vm.currentDepartment, "optedOut", $$v)},expression:"currentDepartment.optedOut"}},[_vm._v(_vm._s(_vm.currentDepartment.optedOut ? "Opted-out" : "Opt-out"))]),_c('BFormCheckbox',{staticClass:"text-uppercase block",attrs:{"disabled":_vm.lockedByOtherUser,"aria-label":_vm.currentDepartment.muted ? 'Blocked' : 'Block',"switch":"","size":"lg"},on:{"change":function($event){return _vm.block(_vm.currentDepartment)}},model:{value:(_vm.currentDepartment.muted),callback:function ($$v) {_vm.$set(_vm.currentDepartment, "muted", $$v)},expression:"currentDepartment.muted"}},[_vm._v(_vm._s(_vm.currentDepartment.muted ? "Blocked" : "Block"))])],1):_vm._e(),_c('MgIcon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({ title: _vm.help.contact.optOutVsBlock }),expression:"{ title: help.contact.optOutVsBlock }"}],attrs:{"name":"info-circle"}}),_c('b-modal',{attrs:{"id":"block-confirm","title":"Block Contact","title-tag":"h3"},on:{"hide":_vm.blockHide},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
        var cancel = ref.cancel;
return [_c('h3',{staticClass:"modal-title"},[_vm._v("Block Contact")]),_c('button',{staticClass:"close",attrs:{"type":"button","aria-label":"Close"},on:{"click":function($event){return cancel()}}},[_vm._v("\n        ×\n      ")])]}},{key:"modal-footer",fn:function(ref){
        var ok = ref.ok;
        var cancel = ref.cancel;
return [_c('b-button',{staticClass:"btn btn-danger",on:{"click":function($event){return ok()}}},[_vm._v("Block")]),_c('b-button',{staticClass:"btn btn-light",on:{"click":function($event){return cancel()}}},[_vm._v("Cancel")])]}}])},[_c('h4',{staticClass:"error-list"},[_vm._v("Are you sure you want to block this contact?")]),_c('p',[_vm._v("\n      Block is primarily used to deal with spam or inappropriate messages.\n      Messages cannot be sent to or received from a blocked contact.\n    ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }