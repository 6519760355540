<template>
  <ResponsiveContainer :tag="null" :breakpoints="{ small: 576 }">
    <template slot-scope="container">
      <MgDataGrid
        :rowData="rowData"
        :columns="
          container.size >= container.breakpoints.small
            ? allColumns_
            : mobileColumns_
        "
        :skip="defaultSkip"
        :sort="defaultSort"
        :renderMenu="renderMenu_"
        @data-state-change="handleDataStateChange"
      />
    </template>
  </ResponsiveContainer>
</template>
<script>
import ResponsiveContainer from "@/components/ResponsiveContainer";
import MgDataGrid from "@/components/MgDataGrid";
import TagListGridMenu from "./TagListGridMenu";
import MgLink from "@/components/MgLink";
import { formatDate } from "@/util/dates";

export default {
  name: "TagListGrid",
  components: {
    ResponsiveContainer,
    MgDataGrid,
  },
  props: {
    rowData: {
      type: Array,
      required: true,
    },
    defaultSkip: {
      type: Number,
      required: true,
    },
    defaultSort: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      allColumns_: [
        {
          field: "name",
          title: "NAME",
          type: "text",
          cell: (h, tdElement, props) => {
            return (
              <td>
                <MgLink
                  globalEventName="view-tag"
                  value={{ tagId: props.dataItem.id }}
                >
                  {props.dataItem.name}
                </MgLink>
              </td>
            );
          },
        },
        { field: "createdByName", title: "OWNER", type: "text" },
        {
          field: "createdOn",
          title: "CREATED",
          type: "date",
          cell: (h, tdElement, props) => {
            return <td>{formatDate(props.dataItem.createdOn, "M/D/YYYY")}</td>;
          },
        },
        {
          field: "lastUsedOn",
          title: "LAST USED",
          type: "date",
          cell: (h, tdElement, props) => {
            return <td>{formatDate(props.dataItem.lastUsedOn, "M/D/YYYY")}</td>;
          },
        },
      ],
      mobileColumns_: [
        {
          field: "name",
          title: "NAME",
          type: "text",
          cell: (h, tdElement, props) => {
            return (
              <td>
                <MgLink
                  globalEventName="view-tag"
                  value={{ tagId: props.dataItem.id }}
                >
                  {props.dataItem.name}
                </MgLink>
              </td>
            );
          },
        },
      ],
      renderMenu_(h, tdElement, props) {
        return (
          <TagListGridMenu
            tagId={props.dataItem.id}
            archived={props.dataItem.archive}
            createdById={props.dataItem.createdBy}
          />
        );
      },
    };
  },
  methods: {
    handleDataStateChange(data) {
      this.$emit("data-state-change", data);
    },
  },
};
</script>
<style lang="scss" scoped></style>
