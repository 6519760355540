<template>
  <div v-if="chat.access || chatOnly" label="Individual radios" stacked>
    <div
      class="custom-border p-3 mt-4 mb-3"
      :class="{ 'active-background': chat.role === 'user' }"
    >
      <input
        type="radio"
        aria-describedby="userAccess"
        name="chat-access-radios"
        v-model="chat.role"
        value="user"
        id="chatUser"
      />
      <label for="chatUser"
        >User
        <p class="mb-0 font-weight-light" id="userAccess">
          Monitor and takeover bot conversations, live chat, follow up with site
          visitors, and view reports
        </p></label
      >
    </div>
    <div
      class="custom-border p-3"
      :class="{ 'active-background': chat.role === 'administrator' }"
    >
      <input
        type="radio"
        aria-describedby="adminAccess"
        name="chat-access-radios"
        v-model="chat.role"
        value="administrator"
        id="chatAdmin"
      /><label for="chatAdmin"
        >Administrator
        <p id="adminAccess" class="mb-0 font-weight-light">
          Includes user access plus team management and data exports
        </p></label
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAccessChat",
  data() {
    return {
      authorizedFeatures: [],
    };
  },
  props: {
    chat: {
      type: Object,
      required: false,
      default() {
        return {
          access: false,
          role: "user",
        };
      },
    },
    chatOnly: {
      type: Boolean,
    },
  },
  watch: {
    chat: {
      handler: "handleChat",
      deep: true,
    },
  },
  mounted() {
    if (this.chatOnly) {
      this.handleChat({
        access: null,
        role: "user",
      });
    }
  },
  methods: {
    populateAuthorizedFeatures(permissions) {
      let arr = [];
      if (
        permissions.chat.access &&
        permissions.chat.role !== "administrator"
      ) {
        arr.push("ChatAgent");
      }
      this.authorizedFeatures = arr;
    },
    handleChat(newVal) {
      if (this.chatOnly) {
        let permissions = {
          chat: { access: true, role: newVal.role },
          text: {
            access: false,
          },
        };
        this.populateAuthorizedFeatures(permissions);
        this.$emit("update-authorized-features-chat", this.authorizedFeatures);
        this.$emit("update-chat-permissions", permissions);
      } else {
        this.$emit("update-chat-permissions", newVal);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
::v-deep .custom-border {
  border: 1px solid $gray-lighter !important;
  border-radius: 4px;
  &.active-border {
    border-color: #318ce7;
  }
  &.active-background {
    background-color: $gray-lightest !important;
  }
}
::v-deep input[type="radio"] {
  vertical-align: top;
  margin-top: 5px;
  accent-color: #318ce7 !important;
  -ms-transform: scale(1.25); /* IE 9 */
  -webkit-transform: scale(1.25); /* Chrome, Safari, Opera */
  transform: scale(1.25);
  position: absolute;
}
::v-deep label {
  font-weight: 400;
  font-size: 16px;
  margin-left: 24px;
  margin-bottom: 0;
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
::v-deep .font-weight-light {
  font-size: 14px;
}
</style>
