<template>
  <span>
    <i class="status-icon" :class="{ enabled: enabled }"></i>
  </span>
</template>

<script>
export default {
  name: "StatusIcon",
  props: {
    enabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";

.status-icon {
  animation-duration: 400ms;
  animation-fill-mode: both;
  background: $gray-400;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  margin: -2px 2px 0;
  width: 10px;
  vertical-align: middle;

  &.enabled {
    animation-name: statusPulseIn;
    animation-timing-function: ease-in-out;
    background: #7ed321;
  }
}

@keyframes statusPulseIn {
  from {
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.6, 1.6, 1.6);
  }
  to {
    transform: scale3d(1.2, 1.2, 1.2);
  }
}
</style>
