<template>
  <MgDropdown
    :options="options"
    :selectedKeyValue="selectedKeyValue"
    :keyPrefix="keyPrefix"
    :keyField="keyField"
    :descriptionField="descriptionField"
    :iconField="iconField"
    :iconColorField="iconColorField"
    :toggleIconField="toggleIconField"
    :toggleIconColorField="toggleIconColorField"
    :labelField="labelField"
    :headerText="headerText"
    menuAlignment="left"
    @click="handleClick"
    ariaLabel="Chat Status"
  />
</template>
<script>
import MgDropdown from "@/components/MgDropdown";

export default {
  name: "BubbleDropdown",
  components: { MgDropdown },
  props: {
    options: {
      type: Array,
      required: true,
    },
    keyPrefix: {
      type: String,
      required: true,
    },
    keyField: {
      type: String,
      required: true,
    },
    labelField: {
      type: String,
      required: true,
    },
    descriptionField: {
      type: String,
      required: false,
      default: "",
    },
    iconField: {
      type: String,
      required: false,
      default: "",
    },
    iconColorField: {
      type: String,
      required: false,
      default: "",
    },
    toggleIconField: {
      type: String,
      required: false,
      default: "",
    },
    toggleIconColorField: {
      type: String,
      required: false,
      default: "",
    },
    headerText: {
      type: String,
      required: false,
      default: "",
    },
    selectedKeyValue: {
      required: false,
      default: "",
    },
  },
  methods: {
    handleClick(data) {
      this.$emit("click", { selectedOption: data.selectedOption });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/variables";
@import "../../scss/utilities";

::v-deep .dropdown-menu {
  width: 221px;
}

::v-deep .dropdown-item {
  white-space: initial;
}

::v-deep .dropdown-toggle {
  color: $gray;
  background-color: rgba(255, 255, 255, 0.9);
  text-transform: none;
  text-decoration: none;
  letter-spacing: inherit;
  padding-left: 0.75rem;
  padding-right: 0;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-right: 0;
  margin-bottom: 2px;
  border-radius: 1rem;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
}

::v-deep .dropdown-toggle::after {
  margin-left: 6px;
  margin-right: 0.75rem;
  top: 2px;
}

::v-deep .dropdown-toggle-icon {
  svg {
    transform: scale(1.5) translateY(1px);
  }
}

::v-deep .b-dropdown-text {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-bold;
  font-size: $font-size-sm;
  color: $text-muted;
}

::v-deep .dropdown-option-description {
  font-size: $font-size-sm;
  color: $text-muted;
}

::v-deep .dropdown-option-label {
  font-size: $font-size-sm;
  color: $gray;
}
</style>
