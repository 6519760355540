import { render, staticRenderFns } from "./WebChatConversation.vue?vue&type=template&id=170786a8&scoped=true&"
import script from "./WebChatConversation.vue?vue&type=script&lang=js&"
export * from "./WebChatConversation.vue?vue&type=script&lang=js&"
import style0 from "./WebChatConversation.vue?vue&type=style&index=0&id=170786a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "170786a8",
  null
  
)

export default component.exports