<template>
  <div id="chatAnalyticsBreakdown" class="col-12" ref="mainDiv">
    <BTabs v-model="currentTab_">
      <div
        class="card-header d-flex justify-content-between align-items-center breakdown-toolbar"
      >
        <div class="breakdown-search pr-5">
          <ServerSearch
            searchIconClass="search"
            cancelIconClass="times"
            placeholder="Search by Name"
            elementKey="tab-search"
            @search="handleSearch"
            :searchText="searchText"
            :clearSearchToggle="clearSearchToggle_"
          />
        </div>
        <button
          type="button"
          class="btn btn-sm btn-light"
          v-on:click="handleExportBreakdown()"
        >
          Export <span v-show="isFiltered">Results</span>
        </button>
      </div>
      <BTab v-on:click="setBreakdownType('teams')" title="Teams">
        <template v-if="teams.length">
          <MgDataGrid
            :sortable="{ allowUnsort: false, mode: 'single' }"
            :rowData="teams"
            :columns="teamColumns"
            :showToolbar="false"
            paginationMode="SERVER"
            :total="teamsTotal"
            :skip="breakdownSkip"
            :take="breakdownTake"
            :sort="teamsSort_"
            @data-state-change="handleDataStateChange"
          >
          </MgDataGrid>
        </template>
        <template v-else-if="!loading_">
          <AnalyticsBreakdownEmptyState
            :searchText="searchText"
            :name="'Teams'"
          />
        </template>
      </BTab>
      <BTab
        v-on:click="setBreakdownType('users')"
        v-if="isAdminAnyTeam"
        title="Users"
      >
        <template v-if="users.length">
          <MgDataGrid
            :sortable="{ allowUnsort: false, mode: 'single' }"
            :rowData="users"
            :columns="userColumns"
            :showToolbar="false"
            paginationMode="SERVER"
            :total="usersTotal"
            :skip="breakdownSkip"
            :take="breakdownTake"
            :sort="usersSort_"
            @data-state-change="handleDataStateChange"
          >
          </MgDataGrid>
        </template>
        <template v-else-if="!loading_">
          <AnalyticsBreakdownEmptyState
            :searchText="searchText"
            :name="'Users'"
          />
        </template>
      </BTab>
    </BTabs>
  </div>
</template>

<script>
import ServerSearch from "@/components/ServerSearch";
import MgDataGrid from "@/components/MgDataGrid";
import AnalyticsBreakdownEmptyState from "@/pages/analytics/AnalyticsBreakdownEmptyState";
import { BTabs, BTab } from "bootstrap-vue";
import {
  ACTIONS as CHAT_ACTIONS,
  MUTATIONS as CHAT_MUTATIONS,
} from "./chatAnalyticsStore";
import analytics from "@/util/analytics";
import store from "@/store";
import _ from "lodash";
import { chatBreakdownExport } from "@/util/analyticsExports";
import { formatDate, formatTime } from "@/util/dates";

export default {
  name: "ChatAnalyticsBreakdown",

  store,

  components: {
    MgDataGrid,
    AnalyticsBreakdownEmptyState,
    BTabs,
    BTab,
    ServerSearch,
  },

  created() {
    this.setBreakdownType("teams");
    window.addEventListener("resize", this.handleWindowResize);
  },

  mounted() {
    this.gridWidth = _.get(this.$refs.mainDiv, "offsetWidth");
  },

  unmounted() {
    window.removeEventListener("resize", this.handleWindowResize);
  },

  data() {
    return {
      currentTab_: null,
      teamsSort_: [{ field: "total", dir: "desc" }],
      usersSort_: [{ field: "liveChatsHosted", dir: "desc" }],
      clearSearchToggle_: false,
      loading_: true,
      gridWidth: 0,
    };
  },

  computed: {
    isFiltered() {
      return !!this.searchText;
    },
    breakdownSkip() {
      return this.$store.state.chatAnalytics.breakdownSkip;
    },
    breakdownTake() {
      return this.$store.state.chatAnalytics.breakdownTake;
    },
    teamsTotal() {
      return _.get(
        this.$store.state.chatAnalytics.teamsBreakdown.meta,
        "total",
        0
      );
    },
    usersTotal() {
      return _.get(
        this.$store.state.chatAnalytics.usersBreakdown.meta,
        "total",
        0
      );
    },
    teams() {
      let teams = [];
      const store = this.$store;
      if (store.state.chatAnalytics.teamsBreakdown.results) {
        store.state.chatAnalytics.teamsBreakdown.results.forEach(function (
          team
        ) {
          let t = {
            name: team.name || "-",
            total: team.total || 0,
            liveChat: team.liveChat || 0,
            botOnly: team.botOnly || 0,
            followUpsCompleted: team.followUpsCompleted || 0,
            id: team.id || null,
          };
          teams.push(t);
        });
      }

      return teams;
    },
    users() {
      let users = [];
      const store = this.$store;
      if (store.state.chatAnalytics.usersBreakdown.results) {
        store.state.chatAnalytics.usersBreakdown.results.forEach(function (
          user
        ) {
          let u = {
            displayName: user.displayName || "-",
            liveChatsHosted: user.liveChatsHosted || 0,
            followUpsCompleted: user.followUpsCompleted || 0,
            averageResponseTime: user.averageResponseTime || 0,
            averageAvailability: user.averageAvailability || 0,
            lastChat: user.lastChat,
            lastAvailable: user.lastAvailable,
            id: user.id || null,
          };
          users.push(u);
        });
      }

      return users;
    },
    isAdminAnyTeam() {
      let isAdmin = false;
      if (store.state.chatAnalytics.chatTeams.length > 0) {
        store.state.chatAnalytics.chatTeams.forEach(function (team) {
          if (team.isChatAdministrator) {
            isAdmin = true;
          }
        });
      }
      return isAdmin;
    },
    searchText() {
      return this.$store.state.chatAnalytics.breakdownSearch;
    },
    liveChatOnly() {
      return _.get(this.$store.state.webChat.bot, "type", false) === "LiveChat";
    },
    teamColumns() {
      return [
        {
          field: "name",
          title: "NAME",
          type: "text",
          sortable: false,
        },
        {
          field: "total",
          title: "TOTAL",
          type: "number",
          format: "{0:n}",
          width: this.getColumnWidthByPercent(10),
        },
        {
          field: "liveChat",
          title: "LIVE CHAT",
          type: "number",
          format: "{0:n}",
          width: this.getColumnWidthByPercent(10),
        },
        {
          field: "botOnly",
          title: this.liveChatOnly ? "UNASSISTED" : "BOT-ONLY",
          type: "number",
          format: "{0:n}",
          width: this.getColumnWidthByPercent(10),
        },
        {
          field: "followUpsCompleted",
          title: "FOLLOW UP COMPLETED",
          type: "number",
          format: "{0:n}",
        },
        {
          field: null,
          title: null,
          type: null,
          format: null,
          width: this.getColumnWidthByPercent(20),
        },
      ];
    },
    userColumns() {
      return [
        {
          field: "displayName",
          title: "NAME",
          type: "text",
          sortable: false,
        },
        {
          field: "liveChatsHosted",
          title: "LIVE CHAT HOSTED",
          type: "number",
          format: "{0:n}",
        },
        {
          field: "followUpsCompleted",
          title: "FOLLOW UP COMPLETED",
          type: "number",
          format: "{0:n}",
        },
        {
          field: "averageResponseTime",
          title: "AVG RESPONSE TIME",
          type: "number",
          cell: (h, tdElement, props) => {
            return (
              <td>
                {props.dataItem.averageResponseTime
                  ? formatTime(props.dataItem.averageResponseTime)
                  : "-"}
              </td>
            );
          },
        },
        {
          field: "averageAvailability",
          title: "AVG TIME AVAILABLE",
          type: "number",
          cell: (h, tdElement, props) => {
            return (
              <td>
                {props.dataItem.averageAvailability
                  ? formatTime(props.dataItem.averageAvailability)
                  : "-"}
              </td>
            );
          },
        },
        {
          field: "lastChat",
          title: "LAST CHAT",
          type: "date",
          cell: (h, tdElement, props) => {
            return <td>{formatDate(props.dataItem.lastChat) || "-"}</td>;
          },
        },
        {
          field: "lastAvailable",
          title: "LAST AVAILABLE",
          type: "date",
          cell: (h, tdElement, props) => {
            return <td>{formatDate(props.dataItem.lastAvailable) || "-"}</td>;
          },
        },
      ];
    },
  },

  methods: {
    setSortString(sort) {
      let sortColumn = _.get(sort, "[0].field"),
        sortDirection = _.get(sort, "[0].dir");

      return sortColumn + " " + sortDirection;
    },
    setBreakdownType(type) {
      if (this.$store.state.chatAnalytics.startDate) {
        this.$store.commit(CHAT_MUTATIONS.SET_BREAKDOWN_SORT, {
          teamsSort: this.setSortString(this.teamsSort_),
          usersSort: this.setSortString(this.usersSort_),
        });
        this.$store.commit(CHAT_MUTATIONS.RESET_BREAKDOWN_SKIP);
        this.$store.commit(CHAT_MUTATIONS.SET_BREAKDOWN_TYPE, type);
        this.$store.commit(CHAT_MUTATIONS.SET_BREAKDOWN_SEARCH, "");
        this.clearSearchToggle_ = !this.clearSearchToggle_;
        analytics.track("Viewed Analytics Breakdown", {
          "Breakdown Type": type,
        });
      }
    },
    handleDataStateChange(data) {
      switch (this.$store.state.chatAnalytics.breakdownType) {
        case "teams":
          this.teamsSort_ = data.sort;
          break;
        case "users":
          this.usersSort_ = data.sort;
          break;
      }

      this.$store.commit(CHAT_MUTATIONS.SET_BREAKDOWN_SORT, {
        teamsSort: this.setSortString(this.teamsSort_),
        usersSort: this.setSortString(this.usersSort_),
      });
      this.$store.commit(CHAT_MUTATIONS.SET_BREAKDOWN_SKIP, data.skip);
      this.$store.dispatch(CHAT_ACTIONS.GET_BREAKDOWN);
    },
    async handleSearch(data) {
      this.$store.commit(CHAT_MUTATIONS.SET_BREAKDOWN_SEARCH, data.searchText);
      this.$store.commit(CHAT_MUTATIONS.RESET_BREAKDOWN_SKIP);
      this.loading_ = true;
      await this.$store.dispatch(CHAT_ACTIONS.GET_BREAKDOWN);
      this.loading_ = false;
      analytics.track("Used search field", {
        "Selected Tab": this.$store.state.chatAnalytics.breakdownType,
      });
    },
    async handleExportBreakdown() {
      chatBreakdownExport(this.$store.state.chatAnalytics);
      analytics.track("Exported breakdown", {
        "Selected Tab": this.$store.state.chatAnalytics.breakdownType,
      });
    },
    getColumnWidthByPercent(pct) {
      return Math.round(this.gridWidth / 100) * pct;
    },
    handleWindowResize() {
      this.gridWidth = _.get(this.$refs.mainDiv, "offsetWidth");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/utilities";
#chatAnalyticsBreakdown {
  margin-top: 15px;
}
::v-deep {
  .nav-tabs {
    margin-bottom: 1px;
    border-bottom: none;
  }
  .nav-link {
    padding: 14px 26px 12px;
    background: #f9fafa;
    margin-right: 2px;
    font-size: 16px;
    color: #212529;
    text-align: center;
    font-weight: 300;
    border: none;
    &.active {
      background: $card-cap-bg;
      color: #212529;
      font-weight: 300;
      border-bottom: 2px solid $card-cap-bg;
      margin-bottom: -2px;
    }
  }
  .tab-content {
    border-radius: $border-radius;
    box-shadow: $panel-box-shadow;
  }
}

::v-deep .k-grid {
  border-top-left-radius: 0;
  box-shadow: none;
  th,
  td {
    text-align: right;
  }
  th:first-of-type,
  td:first-of-type {
    text-align: left;
  }
  th:last-of-type,
  td:last-of-type {
    padding-right: 20px;
  }
  .small-metric {
    margin-left: 5px;
    color: $gray-subtitle;
  }
  .team-name {
    color: $gray-subtitle;
  }
}
::v-deep .k-grid.hideSort {
  .k-i-sort-asc-sm {
    display: none !important;
  }
}
.breakdown-toolbar {
  .breakdown-search {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 30%;
    }
  }
}

::v-deep .k-grid-header .k-header > .k-link {
  display: flex;
  justify-content: end;
}
::v-deep .k-grid-header .k-header > .k-link > .k-icon.k-i-sort-asc-sm,
::v-deep .k-grid-header .k-header > .k-link > .k-icon.k-i-sort-desc-sm {
  position: relative;
  right: 4px;
  margin-left: 0px;
}
.breakdown-toolbar {
  .breakdown-search {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: 30%;
    }
  }
  ::v-deep .btn.disabled {
    opacity: 1;
  }
}
</style>
