<template>
  <BModal
    :id="modalId"
    :title="title"
    :static="test"
    title-tag="h3"
    @close="cancel()"
  >
    <div v-if="errorMessage.length" class="alert alert-danger">
      <b v-if="errorMessage[0] && errorMessage[0].length">{{
        errorMessage[0]
      }}</b>
      <span v-if="errorMessage.length === 2">&nbsp;&nbsp;</span>
      <span v-if="errorMessage[1] && errorMessage[1].length">{{
        errorMessage[1]
      }}</span>
    </div>
    <slot />
    <template v-slot:modal-footer>
      <button
        data-testid="confirmation-button"
        @click="confirm()"
        class="btn btn-primary"
        :disabled="saving"
      >
        {{ confirmButtonText }}
      </button>
      <button @click="cancel()" class="btn btn-light">Cancel</button>
    </template>
  </BModal>
</template>
<script>
import { BModal } from "bootstrap-vue";

export default {
  name: "ConfirmationModal",
  components: { BModal },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    keyValue: {
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    confirmButtonText: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    saving: {
      type: Boolean,
      required: true,
    },
    errorMessage: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    test: {
      type: Boolean,
      required: false,
      default: false,
    },
    /* custom events emitted:
        - confirmed
        - cancelled
    */
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.showModal();
      } else if (!newVal && oldVal) {
        this.hideModal();
      }
    },
  },
  methods: {
    confirm() {
      this.$emit("confirmed", { value: this.keyValue });
    },
    cancel() {
      this.$emit("cancelled");
    },
    showModal() {
      this.$bvModal.show(this.modalId);
    },
    hideModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
<style lang="scss" scoped></style>
