<template>
  <div class="page-container container-fluid overflow-auto h-100">
    <div class="col-lg-8 col-md-12">
      <div class="row">
        <div class="toggle-box w-100" :class="{ 'active-border': oooEnabled }">
          <div class="d-flex">
            <BFormCheckbox
              class="checkbox-switch"
              :disabled="!hasMessageBeenSaved"
              v-model="oooEnabled"
              size="lg"
              @change="save(true)"
              switch
              ><span class="sr-only"
                >Turn out of office {{ oooEnabled ? "off" : "on" }}</span
              ></BFormCheckbox
            >
            <span class="checkbox-label">Out of Office</span>
          </div>
          <div class="mt-2">
            Turned
            <span class="text-uppercase" v-if="oooEnabled">on</span>
            <span class="text-uppercase" v-else>off</span>
            <span v-if="lastToggledOn">
              <span v-if="!isPersonalInbox">
                {{ `by ${lastToggledBy}` }}
              </span>
              {{ `• ${lastToggledString}` }}
            </span>
          </div>
        </div>

        <BCard
          header="Out of Office Message"
          header-tag="h2"
          class="message-box h-100 flex-grow-0 w-100"
        >
          <ul class="pl-3 mb-4">
            <li v-if="isPersonalInbox && onMultipleTeams">
              Out of Office settings are applied to your Cadence Number across
              all Teams.
            </li>
            <li v-if="!isPersonalInbox">
              Out of Office settings apply to this inbox and should not be used
              to set the status of individual users.
            </li>
            <li v-if="!isPersonalInbox">
              Updating the Out of Office will affect all users within the Shared
              Inbox.
            </li>
            <li>
              Contacts that send a message will receive the Out of Office
              message once a day.
            </li>
            <li>
              Incoming messages that match an Auto Reply or Smart Message
              keywords will not receive the Out of Office reply.
            </li>
          </ul>

          <div class="ooo-message">
            <textarea
              class="w-100 ooo-textarea form-control"
              :maxlength="maxLength"
              v-model="messageText"
              placeholder="Enter your Out of Office message"
              aria-label="Enter your Out of Office message"
            />
            <div v-show="showWarning" class="fade-in">
              <p
                id="maxLengthWarning"
                :class="`m-0 my-1  ${
                  hitMax ? 'message-length-warning' : 'message-length-text'
                }`"
              >
                {{ warningMessage }}
              </p>
              <BProgress
                height="5px"
                :value="messageText.length"
                :max="maxLength"
                aria-labelledby="maxLengthWarning"
              />
            </div>
          </div>
          <button
            class="btn btn-primary"
            @click.prevent="save(false)"
            :disabled="!hasMessage"
          >
            Save
          </button>
        </BCard>
      </div>
      <div class="col-lg-4 col-md-0" />
    </div>
  </div>
</template>

<script>
import { BCard, BFormCheckbox, BProgress, ToastPlugin } from "bootstrap-vue";
import _ from "lodash";
import api from "@/util/api";
import { formatDate } from "@/util/dates";
import eventBus from "@/util/eventBus";
import Toast from "@/components/Toast";
import Vue from "vue";
import analytics from "@/util/analytics";

Vue.use(ToastPlugin);

export default {
  name: "OutOfOfficeTab",
  components: { BCard, BFormCheckbox, BProgress },
  props: {
    inboxId: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      oooEnabled: false,
      messageText: "",
      lastToggledOn: "",
      lastToggledBy: "",
      hasMessageBeenSaved: false,
      maxLength: 306,
      outOfOfficeConfig: {},
      groupAccount: {},
      onMultipleTeams: null,
    };
  },
  computed: {
    showWarning() {
      return this.messageText.length >= 160;
    },
    hitMax() {
      return this.messageText.length >= this.maxLength;
    },
    warningMessage() {
      if (_.inRange(this.messageText.length, 0, 240))
        return "You've reached 160 characters.";
      else if (_.inRange(this.messageText.length, 240, this.maxLength - 6))
        return "Heads up, this text is getting closer to the character limit.";
      else if (_.inRange(this.maxLength - 6, 100000))
        return "You've reached the character limit!";
      else return "";
    },
    hasMessage() {
      return this.messageText.trim().length > 0;
    },
    isPersonalInbox() {
      return !(!_.isNull(this.inboxId) && !_.isUndefined(this.inboxId));
    },
    lastToggledString() {
      return formatDate(this.lastToggledOn, "MMMM D[,] h:mma");
    },
  },
  methods: {
    disableOutOfOfficeEvent(event) {
      if (
        (this.isPersonalInbox &&
          event.detail.isPersonalInbox === this.isPersonalInbox) ||
        (!this.isPersonalInbox && event.detail.inboxId === this.inboxId)
      ) {
        this.oooEnabled = false;
        eventBus.dispatchEvent("toggle-out-of-office", {
          enabled: this.oooEnabled,
          isPersonalInbox: this.isPersonalInbox,
          inboxId: this.inboxId,
        });
      }
    },
    async reloadConfig() {
      let profile = await api.get("profile");
      window.localStorage.setItem("profile", JSON.stringify(profile));

      if (this.isPersonalInbox) {
        if (_.isNull(profile.outOfOfficeConfig)) {
          profile.outOfOfficeConfig = {};
        }

        this.outOfOfficeConfig = profile.outOfOfficeConfig;
        this.oooEnabled = profile.outOfOfficeConfig.enabled;
        this.messageText = profile.outOfOfficeConfig.replyMessage;
        this.lastToggledOn = profile.outOfOfficeConfig.lastToggledOn;
        this.onMultipleTeams = profile.departments.length > 1;
      } else {
        let config = await api.get(`groupAccounts/${this.inboxId}/outofoffice`);
        if (!_.isEmpty(config)) {
          this.outOfOfficeConfig = config;
          this.oooEnabled = config.enabled;
          this.messageText = config.replyMessage ? config.replyMessage : "";
          this.lastToggledOn = config.lastToggledOn;
          this.lastToggledBy = config.lastToggledByUserName;
          this.groupAccount = _.find(profile.groupAccounts, {
            id: this.inboxId,
          });
        }
      }
    },
    async save(toggleOOO) {
      try {
        if (this.isPersonalInbox) {
          await api.post("profile/outofoffice", {
            enabled: toggleOOO
              ? this.oooEnabled
              : this.outOfOfficeConfig.enabled,
            replyMessage: toggleOOO
              ? this.outOfOfficeConfig.replyMessage
              : this.messageText,
            id: this.outOfOfficeConfig.id,
            userId: this.outOfOfficeConfig.userId,
          });
          await this.reloadConfig();
        } else {
          await api.post(`groupAccounts/${this.inboxId}/outofoffice`, {
            enabled: toggleOOO
              ? this.oooEnabled
              : this.outOfOfficeConfig.enabled,
            replyMessage: toggleOOO
              ? this.outOfOfficeConfig.replyMessage
              : this.messageText,
          });
          await this.reloadConfig();
        }

        if (toggleOOO) {
          eventBus.dispatchEvent("toggle-out-of-office", {
            enabled: this.oooEnabled,
            isPersonalInbox: this.isPersonalInbox,
            inboxId: this.inboxId,
          });

          analytics.track(
            `Turned Out of Office ${this.oooEnabled ? "ON" : "OFF"}`,
            {
              "Account Type": this.isPersonalInbox ? "Personal" : "Shared",
              "Character Count": this.outOfOfficeConfig.replyMessage.length,
              "Inbox Name": !this.isPersonalInbox
                ? this.groupAccount.name
                : undefined,
              Via: "Page",
            }
          );
        } else {
          analytics.track("Saved Out of Office Message", {
            "Account Type": this.isPersonalInbox ? "Personal" : "Shared",
            "Character Count": this.outOfOfficeConfig.replyMessage.length,
            "Inbox Name": !this.isPersonalInbox
              ? this.groupAccount.name
              : undefined,
          });
        }

        this.hasMessageBeenSaved = true;
      } catch {
        let toastBody = this.$createElement(Toast, {
          props: {
            message: "Something went wrong.",
          },
        });
        this.$bvToast.toast([toastBody], {
          variant: "danger",
          noCloseButton: true,
          solid: true,
        });
      }
    },
  },
  async created() {
    try {
      await this.reloadConfig();

      if (_.isNull(this.messageText) || _.isUndefined(this.messageText))
        this.messageText = "";

      if (this.messageText.length > 0) this.hasMessageBeenSaved = true;

      eventBus.addEventListener(
        "toggle-out-of-office-dialog",
        this.disableOutOfOfficeEvent
      );
    } catch {
      let toastBody = this.$createElement(Toast, {
        props: {
          message: "Something went wrong.",
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "danger",
        noCloseButton: true,
        solid: true,
      });
    }
  },
  destroyed() {
    eventBus.removeEventListener("toggle-out-of-office-dialog");
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  padding: 40px 30px 16px;
  margin: 0;
}

.message-box {
  margin-top: 40px;

  ul {
    li {
      margin-bottom: 4px;
    }
  }
}

.toggle-box {
  border: 2px solid #b1bac4;
  border-radius: 4px;
  padding: 24px 30px;

  &.active-border {
    border-color: #318ce7;
  }
}

.checkbox-label {
  font-size: 18px;
  font-weight: 400;
}

.checkbox-switch {
  padding-top: 3px;
  width: 20px;
}

::v-deep .card-body {
  padding: 24px 30px;
}

::v-deep .progress-bar {
  background-color: #4abce2;
  border-radius: 0;
}

::v-deep .custom-control-input:checked {
  ~ .custom-control-label::before {
    background-color: #318ce7 !important;
    border-color: #318ce7 !important;
  }
}

.ooo-message {
  margin-bottom: 24px;
}

.ooo-textarea {
  height: 105px;
  resize: none;
}

.message-length-text {
  font-size: 12px !important;
  color: #555 !important;
}

.message-length-warning {
  font-size: 12px !important;
  color: #dc3545 !important;
}
</style>
