<template>
  <a
    v-if="title"
    v-b-tooltip.hover="tooltip"
    href
    role="button"
    @click.prevent="click()"
    ><slot
  /></a>
  <a v-else href role="button" @click.prevent="click()"><slot /></a>
</template>
<script>
import eventBus from "@/util/eventBus";

export default {
  name: "MgLink",
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    eventName: {
      type: String,
      required: false,
      default: "",
    },
    globalEventName: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    tooltip() {
      return {
        title: this.title,
        delay: { show: 500, hide: 50 },
      };
    },
  },
  methods: {
    click() {
      if (this.globalEventName) {
        eventBus.dispatchEvent(this.globalEventName, this.value);
      } else if (this.eventName) {
        this.$emit(this.eventName, this.value);
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
