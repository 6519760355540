import { ACTIONS, GETTERS, MUTATIONS } from "@/util/constants";
import api from "@/util/api";
import _ from "lodash";

const defaultHistoryState = {
  results: [],
  meta: {
    skip: 0,
    take: 100,
  },
};

export default {
  state: () => ({
    history: defaultHistoryState,
    details: {},
  }),
  actions: {
    async [ACTIONS.GET_CONTACT]({ commit }, { id, disableLoading }) {
      let contact = await api.get(`/students/${id}`, {
        headers: { "x-disable-loading": !!disableLoading },
      });
      return commit(MUTATIONS.SET_CONTACT, contact);
    },
    async [ACTIONS.GET_CONTACT_HISTORY]({ state, commit }, contactId) {
      const params = {
        skip: state.history.meta.skip,
        take: state.history.meta.take,
      };
      let history = await api.get(`/students/${contactId}/history`, {
        params,
      });
      history.results = _.sortByOrder(
        state.history.results.concat(history.results),
        ["timestamp"],
        ["asc"]
      );
      return commit(MUTATIONS.SET_CONTACT_HISTORY, history);
    },
    async [ACTIONS.GET_MORE_CONTACT_HISTORY]({ commit, dispatch, state }) {
      let skip = state.history.meta.skip + state.history.meta.take;
      commit(MUTATIONS.SET_CONTACT_HISTORY_SKIP, skip);
      dispatch(ACTIONS.GET_CONTACT_HISTORY, state.details.id);
    },
    [ACTIONS.RESET_CONTACT_HISTORY]({ commit }) {
      commit(MUTATIONS.SET_CONTACT_HISTORY, defaultHistoryState);
    },
  },
  getters: {
    [GETTERS.CONTACT_DISPLAY_NAME]: (state) => {
      if (!state.details) return "";
      return state.details.firstName
        ? `${state.details.firstName} ${state.details.lastName}`
        : state.details.mobileNumberFormatted;
    },
    [GETTERS.CONTACT_FIRST_NAME]: (state) => {
      if (!state.details) return "";
      return state.details.firstName
        ? state.details.firstName
        : state.details.mobileNumberFormatted;
    },
  },
  mutations: {
    [MUTATIONS.SET_CONTACT_HISTORY](state, history) {
      if (!history) return;
      state.history = history;
    },
    [MUTATIONS.SET_CONTACT_HISTORY_SKIP](state, skip) {
      state.history.meta.skip = skip;
    },
    [MUTATIONS.SET_CONTACT](state, contact) {
      state.details = contact;
    },
  },
};
