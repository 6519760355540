<template>
  <BModal
    id="renxtConfigModal"
    ref="renxtConfigModal"
    title="Raiser's Edge NXT Settings"
  >
    <template #modal-header="{ close }">
      <h2 class="modal-title">Raiser&rsquo;s Edge NXT Settings</h2>
      <button
        class="close"
        @click="close()"
        aria-label="Close Recruit Configuration Modal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </template>
    <div>
      <h4 class="mb-3">Authentication</h4>
      <p>
        Team authorization has been completed. This process will need to be
        performed once a year to maintain functionality with Cadence.
      </p>
      <div class="container">
        <div class="row">
          <div class="col-sm-5">
            <div class="row font-weight-light">Last Authorization Date</div>
            <div class="row">
              {{ formatDate(baseSettings.lastAuthorized.authorizedOn) }}
            </div>
          </div>
          <div class="col-sm-5">
            <div class="row font-weight-light">Authorized By</div>
            <div class="row last-authorized-by text-truncate d-inline-block">
              {{ baseSettings.lastAuthorized.authorizedBy }}
            </div>
          </div>
          <div class="col-sm-2 pt-1">
            <a :href="authCallbackUri" class="btn btn-light update-auth"
              >Update</a
            >
          </div>
        </div>
      </div>
      <hr />
      <div>
        <h4 class="mb-3">Notifications</h4>
        <p>
          Receive notification emails related to connection errors, and import
          and export activity.
        </p>
        <div class="form-group">
          <label for="renxt-failure" class="mb-1">Failure Notifications</label>
          <p class="mb-2">
            A notification email will be sent if an issue is encountered while
            attempting to communicate with Raiser&rsquo;s Edge NXT. This
            includes an expired password.
          </p>
          <textarea
            class="form-control"
            id="renxt-failure"
            v-model="baseSettings.failureEmailList"
          />
          <div>
            <small class="form-text"
              >Enter multiple email addresses, separate by commas
            </small>
          </div>
        </div>
        <div class="form-group">
          <label for="renxt-import" class="mb-1"
            ><strong>Import Notification</strong>
          </label>
          <p class="mb-2">
            A notification email summarizing the import's successes and errors
            will be sent every time an import runs.
          </p>
          <textarea
            class="form-control"
            id="renxt-import"
            v-model="baseSettings.activityEmailList"
          />
          <div>
            <small class="form-text"
              >Enter multiple email addresses, separate by commas
            </small>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-between">
        <button
          class="btn btn-primary"
          v-on:click="handleSave()"
          :disabled="isSaving"
          type="submit"
        >
          Save
        </button>
        <button class="btn btn-light ml-3" @click="cancel" :disabled="isSaving">
          Cancel
        </button>
      </div>
    </template>
  </BModal>
</template>

<script>
import Vue from "vue";

import _ from "lodash";
import { BModal } from "bootstrap-vue";
import { ToastPlugin } from "bootstrap-vue";

import analytics from "@/util/analytics";
import { ACTIONS } from "./renxtStore";
import { formatDate } from "@/util/dates";
import { itemsForPatch } from "@/util/apiHelpers";
import store from "@/store";
import Toast from "@/components/Toast";

Vue.use(ToastPlugin);

export default {
  name: "RenxtSettingsModal",
  store,
  components: { BModal },
  props: {
    authCallbackUri: {
      type: String,
      required: true,
    },
    baseSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
    };
  },
  computed: {
    cleanBaseSettings() {
      return _.cloneDeep(this.$store.state.renxt.base);
    },
  },
  methods: {
    formatDate(date) {
      return formatDate(date, "MMMM D[,] YYYY");
    },
    async handleSave() {
      this.isSaving = true;
      try {
        await this.$store.dispatch(ACTIONS.UPDATE_RENXT_CONFIG, {
          base: itemsForPatch(this.cleanBaseSettings, this.baseSettings),
        });
        let toastBody = this.$createElement(Toast, {
          props: {
            message: "Raiser's Edge NXT Settings Updated",
          },
        });
        this.$bvToast.toast([toastBody], {
          variant: "success",
          noCloseButton: true,
          solid: true,
        });

        this.$refs["renxtConfigModal"].hide();
      } catch {
        let toastBody = this.$createElement(Toast, {
          props: {
            message: "Something went wrong",
          },
        });
        this.$bvToast.toast([toastBody], {
          variant: "danger",
          noCloseButton: true,
          solid: true,
        });
      } finally {
        analytics.track("Updated Integration Settings", {
          integrationName: "Renxt",
        });
        this.isSaving = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.last-authorized-by {
  max-width: 200px;
}

.update-auth {
  @include media-breakpoint-up(sm) {
    float: right;
  }
}
</style>
