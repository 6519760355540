<template>
  <div class="visitor-avatar-container anonymous-background">
    <div
      v-if="name"
      :title="name"
      class="d-flex align-items-center justify-content-center gravatar rounded-circle"
      :class="`${color}-background avatar-small`"
    >
      <span class="letter">{{ name.slice(0, 1) }}</span>
    </div>
    <MgIcon v-else :class="color" name="user-circle" scale="2.3" />
  </div>
</template>
<script>
import MgIcon from "@/components/MgIcon";

export default {
  name: "WebChatVisitorAvatar",
  components: { MgIcon },
  props: {
    color: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

.visitor-avatar-container {
  min-height: 37px;
}

.letter {
  font-size: 18px;
  color: $avatar-black;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  padding-bottom: 1px;
}

.avatar-small {
  width: 36px;
  height: 36px;
}

.anonymous-background {
  background-color: #fff;
  border-radius: 50%;
}
</style>
