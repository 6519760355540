<template>
  <MgDataGrid
    v-if="inboxesTotal >= 1"
    :rowData="inboxes"
    :columns="!isLegacy ? defaultColumns : legacyColumns"
    :renderMenu="renderMenu_"
    :take="inboxesTotal"
    :search="filterBySearchTerm"
  >
  </MgDataGrid>
</template>

<script>
import MgDataGrid from "@/components/MgDataGrid";
import SharedInboxListMenu from "./SharedInboxListMenu";
import { formatPhoneNumberWithoutIntlCode } from "@/util/phoneNumber";
import store from "@/store";
import _ from "lodash";

export default {
  name: "SharedInboxList",
  store,
  components: {
    MgDataGrid,
    /* eslint-disable */
    SharedInboxListMenu,
    /* eslint-enable */
  },
  data() {
    return {
      config: {},
      defaultColumns: [
        {
          field: "name",
          title: "Name",
          cell: (h, tdElement, props) => {
            return (
              <td>
                <span
                  class="account-swatch"
                  style={this.swatchColor(props.dataItem.color)}
                ></span>
                {props.dataItem.name}
              </td>
            );
          },
        },
        {
          field: "numbers",
          title: "Number",
          type: "text",
          cell: (h, tdElement, props) => {
            let phoneNumbers = props.dataItem.numbers
              .map(formatPhoneNumberWithoutIntlCode)
              .join("\r\n");

            if (!phoneNumbers.length) phoneNumbers = "-";
            return <td style="white-space: pre-line">{phoneNumbers}</td>;
          },
        },
        {
          field: "code",
          title: "Code",
          type: "text",
        },
      ],
      legacyColumns: [
        {
          field: "name",
          title: "Name",
          cell: (h, tdElement, props) => {
            return (
              <td>
                <span
                  class="account-swatch"
                  style={this.swatchColor(props.dataItem.color)}
                ></span>
                {props.dataItem.name}
              </td>
            );
          },
        },
        {
          field: "numbers",
          title: "Number",
          type: "text",
          cell: (h, tdElement, props) => {
            let phoneNumbers = props.dataItem.numbers
              .map(formatPhoneNumberWithoutIntlCode)
              .join("\r\n");

            if (!phoneNumbers.length) phoneNumbers = "-";
            return <td style="white-space: pre-line">{phoneNumbers}</td>;
          },
        },
        {
          field: "code",
          title: "Code",
          type: "text",
        },
        {
          field: "team",
          title: "Team",
          type: "text",
          cell: (h, tdElement, props) => {
            return (
              <td style="white-space: pre-line">{props.dataItem.department}</td>
            );
          },
        },
      ],
      renderMenu_(h, tdElement, props) {
        return <SharedInboxListMenu inboxId={props.dataItem.id} />;
      },
    };
  },
  props: {
    groupAccounts: {
      type: Array,
      required: true,
    },
    filterBySearchTerm: {
      type: String,
      required: false,
    },
  },
  computed: {
    isLegacy() {
      return this.$store.state.config.plan === "Legacy";
    },
    inboxes() {
      let inboxes = [];

      this.groupAccounts.forEach(function (inbox) {
        let formattedInbox = {
          id: inbox.id,
          name: inbox.name || "-",
          color: inbox.color,
          numbers: inbox.virtualNumbers,
          code: inbox.apiCode || "-",
          department: inbox.department || "-",
        };
        inboxes.push(formattedInbox);
      });
      if (this.filterBySearchTerm) {
        inboxes = _.filter(inboxes, (i) => {
          return i.name
            .toLowerCase()
            .includes(this.filterBySearchTerm.toLowerCase());
        });
      }

      return inboxes;
    },
    inboxesTotal() {
      return this.groupAccounts.length;
    },
  },
  methods: {
    swatchColor(color) {
      return "background-color: " + color;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/utilities";
.k-grid {
  border-top-left-radius: 0;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  ::v-deep th,
  ::v-deep td {
    text-align: left;
  }
}
::v-deep .account-swatch {
  border-radius: $badge-border-radius;
  display: inline-block;
  height: 10px;
  margin-right: 6px;
  width: 10px;
}
::v-deep .k-grid-pager {
  display: none;
}
::v-deep td {
  padding: 20px 15px;
}
.col-12 {
  padding: 5px;
}
</style>
