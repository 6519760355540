var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-search d-flex align-items-center"},[_c('VueTypeaheadBootstrap',{staticClass:"flex-grow-1 user-search-input",attrs:{"inputClass":"special-input-class","aria-labelledby":"userSearchLabel","data":_vm.users,"disabled":_vm.disabled,"serializer":function (item) { return item.email; },"showAllResults":true,"minMatchingChars":_vm.minCharacters,"placeholder":'Search by name or email'},on:{"hit":function($event){return _vm.select($event)},"input":_vm.debouncedHandler},scopedSlots:_vm._u([{key:"suggestion",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"gravatar-container d-flex flex-column align-items-stretch"},[_c('div',{staticClass:"profile-letter-container",style:({
              backgroundColor: '#396fb0',
              'text-transform': 'uppercase',
            })},[_c('div',{staticClass:"profile-letter"},[_vm._v("\n              "+_vm._s(data.name.slice(0, 1))+"\n            ")])]),_c('img',{staticClass:"gravatar rounded-circle",attrs:{"src":("https://www.gravatar.com/avatar/" + (_vm.hashEmail(
              data.email
            )) + "?r=pg&d=blank"),"alt":"Name"}})]),_c('div',{staticClass:"d-flex flex-column align-self-center user-text d-inline-block text-truncate"},[_c('p',{staticClass:"m-0 text-truncate"},[_vm._v(_vm._s(data.name))]),_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(data.email))])])])]}},{key:"prepend",fn:function(){return [_c('BButton',{staticClass:"search-icon",attrs:{"variant":"outline"}},[_c('strong',[_c('MgIcon',{attrs:{"name":"search","scale":".75"}})],1),_c('span',{staticClass:"sr-only"},[_vm._v("Search")])])]},proxy:true},{key:"append",fn:function(){return [_c('div',{staticClass:"d-flex align-self-center mx-2 spinner-border spinner-border-sm text-muted",class:{ visible: _vm.loading, invisible: !_vm.loading },attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])]},proxy:true}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }