<template>
  <BModal id="removeFieldMappingModal" ref="removeFieldMappingModal">
    <template #modal-header="{ close }">
      <h3 class="modal-title">Remove Optional Field Data</h3>
      <button
        class="close"
        @click="close()"
        aria-label="Close Remove Field Mapping Modal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </template>
    <div class="remove-mapping-modal-content">
      <h4 class="text-danger">
        Are you sure you want to change the {{ cadenceFieldTitle() }} field
        mapping?
      </h4>
      <ul>
        <li>
          All contact data for this field will be removed in all instances
        </li>
        <li>This will impact your contact data imports and/or API usage</li>
        <li>This operation cannot be undone</li>
      </ul>
      <p>
        Depending on the amount of contacts in the system, it may take several
        minutes to complete this process when saving the settings.
      </p>
    </div>
    <template #modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-between">
        <button class="btn btn-danger" @click="handleSubmit()" type="submit">
          Remove
        </button>
        <button class="btn btn-light ml-1" @click="cancel">Cancel</button>
      </div>
    </template>
  </BModal>
</template>

<script>
import { BModal } from "bootstrap-vue";

export default {
  name: "IntegrationsConfirmRemoveMappingModal",
  components: {
    BModal,
  },
  data() {
    return {};
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("onRemove", this.field.field);
      this.$refs["removeFieldMappingModal"].hide();
    },
    cadenceFieldTitle() {
      return this.field.isOptOut ? "Opt Out" : "User ID";
    },
  },
};
</script>

<style lang="scss" scoped>
.remove-mapping-modal-content {
  padding: 12px 0;
}
</style>
