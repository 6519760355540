<template>
  <MgFade class="d-flex flex-column w-100" :fade="fadeIn" :appear="true">
    <div
      class="d-flex flex-row w-100"
      :class="{
        'justify-content-end': outgoing,
        'justify-content-start': !outgoing,
      }"
    >
      <MgKendoCard
        orientation="horizontal"
        :hideHeader="outgoing"
        :hideFooter="!outgoing"
        class="d-flex align-items-center"
        :class="{
          incoming: !outgoing,
          outgoing: outgoing,
          note: isNote,
          notNote: !isNote,
        }"
      >
        <template v-slot:header>
          <WebChatVisitorAvatar
            :color="conversation.visitorAvatarColor"
            :name="visitorName"
          />
        </template>
        <template v-slot:body>
          <div v-html="messageText"></div>
        </template>
        <template v-slot:footer>
          <WebChatUserAvatar
            :name="userName"
            :source="source"
            :userAvatar="isNote ? noteUserAvatar : nonNoteUserAvatar"
            :botName="botName"
          />
        </template>
      </MgKendoCard>
    </div>
    <small
      v-if="showDateTime"
      class="d-flex flex-row w-100 subtitle"
      :class="{
        outgoing: outgoing,
        'justify-content-end': outgoing,
        'justify-content-start': !outgoing,
        incoming: !outgoing,
      }"
    >
      <span class="font-weight-bold" v-if="isNote"
        >{{ sourceName }}&nbsp;&bull;&nbsp;Internal Note&nbsp;&bull;&nbsp;</span
      ><span class="font-weight-bold" v-else
        >{{ sourceName }}&nbsp;&bull;&nbsp;</span
      >{{ message.sentOn | dateShorthand }}&#44;&nbsp;{{
        message.sentOn | timeShorthand
      }}
    </small>
  </MgFade>
</template>
<script>
import MgKendoCard from "@/components/MgKendoCard";
import WebChatVisitorAvatar from "@/components/WebChatVisitorAvatar";
import WebChatUserAvatar from "@/components/WebChatUserAvatar";
import store from "@/store";
import { GETTERS } from "@/util/constants";
import {
  isMessageOutgoing,
  visitorFullName,
  messageUserAvatar,
  messageUserName,
  isDefaultAvatar,
  transformMarkdownLinksFromBotpress,
} from "@/util/webchat";
import MgFade from "@/components/MgFade";

export default {
  name: "WebChatMessage",
  store,
  components: {
    MgKendoCard,
    WebChatVisitorAvatar,
    WebChatUserAvatar,
    MgFade,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    showDateTime: {
      type: Boolean,
      required: false,
      default: false,
    },
    conversation: {
      type: Object,
      required: false,
    },
    fadeIn: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNote: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    outgoing() {
      return isMessageOutgoing(this.message);
    },
    source() {
      return this.message.payload.agent ? "Agent" : "Bot";
    },
    messageText() {
      return transformMarkdownLinksFromBotpress(this.message.payload.text)
    },
    nonNoteUserAvatar() {
      // we don't have default avatars on the Cadence side, just a letter-circle
      return isDefaultAvatar(messageUserAvatar(this.message))
        ? ""
        : messageUserAvatar(this.message);
    },
    noteUserAvatar() {
      return messageUserAvatar(this.message);
    },
    userName() {
      return messageUserName(this.message);
    },
    botName() {
      return this.$store.getters[GETTERS.GET_WEBCHAT_BOT_BOTPRESSID];
    },
    visitorName() {
      return visitorFullName(this.conversation);
    },
    sourceName() {
      if (this.source === "Bot" && this.outgoing) {
        return "Bot";
      } else {
        return this.outgoing ? this.userName : this.visitorName || "Visitor";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

.avatar-letter {
  font-size: 18px;
  font-weight: $font-weight-bold;
  color: $avatar-black;
  font-family: $font-family-sans-serif;
  top: 8px;
}

.bot-icon-circle {
  width: 36px;
  height: 36px;
  background-color: $accent;
}

.bot-icon {
  position: relative;
  top: 8px;
  left: 7px;
  color: #fff;
}

.k-card {
  width: fit-content;
  border: none;
}

::v-deep .k-card-header {
  display: flex;
  align-self: end;
  border: none;
  background: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 13px;
  padding-left: 0;
  overflow: visible;
}

::v-deep .k-card-body {
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 14px;
  line-height: 21px;
}

::v-deep .incoming .k-card-body {
  border: 1px solid $message-content-bg;
  background-color: $message-content-bg;
  border-bottom-left-radius: 0;
}

::v-deep .outgoing .k-card-body {
  border: 1px solid $outgoing-message-content-bg;
  background-color: $outgoing-message-content-bg;
  border-bottom-right-radius: 0;
}

::v-deep .outgoing.notNote .k-card-body {
  border: 1px solid $outgoing-message-content-bg;
  background-color: $outgoing-message-content-bg;
  border-bottom-right-radius: 0;
}

::v-deep .outgoing.note .k-card-body {
  border: 1px solid $outgoing-note-content-bg;
  background-color: $outgoing-note-content-bg;
  border-bottom-right-radius: 0;
}

::v-deep .k-card-footer {
  .k-card-title {
    margin: 0;
  }

  display: flex;
  align-self: end;
  border: none;
  background: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 13px;
}

.subtitle {
  color: $gray-subtitle;
  margin-top: 1px;

  &.outgoing {
    padding-right: 3rem;
  }

  &.incoming {
    padding-left: 3.063rem;
  }
}
</style>
