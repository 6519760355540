<template>
  <div class="d-flex flex-column h-100">
    <SubpageHeader
      :headerTitle="inboxContext.name"
      :headerSubtitle="`Team: ${inboxContext.teamName}`"
    ></SubpageHeader>
    <div class="d-flex flex-column flex-fill overflow-auto page-content">
      <AnalyticsDatePicker class="inbox-date-picker" analyticsType="sharedInboxes"/>
      <div class="panel-container">
        <MetricsRow :metrics="metrics" />
      </div>
      <div class="panel-container">
        <MetricsChart
          :title="'Messaging Activity'"
          :series="chartSeries"
          :seriesFieldNames="['Incoming', 'Outgoing']"
          :seriesDisplayNames="['Received', 'Sent']"
          :dates="dateParams"
        />
      </div>
      <div class="d-flex detail-title">
        <h4>Inbox Users</h4>
      </div>
      <div class="panel-container">
        <AnalyticsInboxDetailBreakdown />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import AnalyticsDatePicker from "@/pages/analytics/AnalyticsDatePicker";
import MetricsRow from "@/components/MetricsRow";
import MetricsChart from "@/components/MetricsChart";
import AnalyticsInboxDetailBreakdown from "./AnalyticsInboxDetailBreakdown";
import SubpageHeader from "@/components/SubpageHeader";
import { FormSelectPlugin } from "bootstrap-vue";
import { MUTATIONS, ACTIONS } from "@/util/constants";
import store from "@/store";
import _ from "lodash";

Vue.use(FormSelectPlugin);

export default {
  name: "AnalyticsInboxDetail",
  store,
  components: {
    AnalyticsDatePicker,
    MetricsRow,
    MetricsChart,
    AnalyticsInboxDetailBreakdown,
    SubpageHeader,
  },
  data() {
    return {
      initialLoad: true,
      contacts: [],
      day: null,
      week: null,
      month: null,
      quarter: null,
      year: null,
      params: { start: "", end: "" },
      skipDays: 1,
      intervalDifference: "30d",
      daysDifference: null,
      timestamp: "",
    };
  },
  props: {
    inboxContext: {
      required: true,
    },
  },
  created() {
    this.$store.dispatch(ACTIONS.GET_TEAM_ID);
  },
  mounted() {
    store.commit(MUTATIONS.SET_INBOX_DETAILS_CONTEXT, {
      type: this.inboxContext.type,
      id: this.inboxContext.id,
      departments: [this.inboxContext.teamId],
    });
  },
  computed: {
    dateParams() {
      return this.$store.state.analytics.startDate
        ? {
            start: this.$store.state.analytics.startDate,
            end: this.$store.state.analytics.endDate,
            skipDays: this.$store.state.analytics.skipDays,
          }
        : {};
    },
    currentTeams() {
      return this.$store.state.analytics.currentTeams.length
        ? this.$store.state.analytics.currentTeams
        : [];
    },
    chartSeries() {
      return _.get(this.$store.state.analytics.inboxDetails.metrics, "series");
    },
    messageData() {
      return this.$store.state.analytics.inboxDetails.metrics;
    },
    metrics() {
      return this.messageData
        ? this.messageData.metrics
        : [
            { name: "ContactsReached", value: 0 },
            { name: "TotalMessages", value: 0 },
            { name: "Delivered", value: 0 },
            { name: "DeliveredPercentage", value: "0%" },
            { name: "Sent", value: 0 },
            { name: "Received", value: 0 },
            { name: "Incoming", value: 0 },
          ];
    },
    teamId() {
      return this.$store.state.analytics.teamId;
    },
    users() {
      return [];
    },
    inboxes() {
      return [];
    },
    inboxesTotal() {
      if (this.$store.state.analytics.inboxesBreakdown.paging) {
        return this.$store.state.analytics.inboxesBreakdown.paging.total;
      } else {
        return 0;
      }
    },
    filterData() {
      return {
        teamId: this.teamId,
        currentTeams: this.currentTeams,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
#analyticsBase {
  select {
    max-width: 30%;
    margin: 1% 0;
  }
  ::v-deep .date-picker {
    width: 210px;
  }
}

.page-content {
  padding-top: $grid-gutter-width/2;
}

.date-dropdown {
  ::v-deep button {
    color: $text-color !important;
    background-color: #fff !important;
    border-color: $input-border !important;
    letter-spacing: 0;
    text-transform: capitalize;
    .icon {
      padding-right: 2px;
    }
  }
  .b-dropdown-text {
    font-weight: normal;
    cursor: pointer;
    &:hover,
    &:focus {
      color: #16181b;
      text-decoration: none;
      background-color: #e9ecef;
    }
  }
}

.date-dropdown.alignRight {
  margin-left: auto;
}

.detail-title {
  margin-top: 15px;
  font-weight: 300;
}

.inbox-date-picker {
  margin: 15px 0;
}
</style>
