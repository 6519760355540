export function createBrowserNotification(titleText, bodyText, iconPath) {
  // eslint-disable-next-line no-prototype-builtins
  if (!window.hasOwnProperty("Notification")) {
    return false;
  }

  return new Notification(titleText, {
    body: bodyText,
    icon: iconPath,
  });
}

export function requestNotificationPermission() {
  // eslint-disable-next-line no-prototype-builtins
  if (!window.hasOwnProperty("Notification")) {
    return false;
  }
  const permission = Notification.permission;

  if (["granted", "denied"].includes(permission)) {
    return permission;
  }

  return Notification.requestPermission();
}
