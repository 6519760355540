<template>
  <div class="row">
    <div class="col-lg-4">
      <ScheduledMessagesCalendar :inboxes="inboxes" />
    </div>
    <div class="col-lg-8 pl-lg-5 mt-5 mt-lg-0" v-if="scheduledMessages">
      <ScheduledMessagesList
        :scheduled-messages="scheduledMessages"
        v-on="$listeners"
      />
    </div>
    <div v-else>
      <span>No Scheduled Messages</span>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { ACTIONS, GETTERS } from "@/util/constants";
import ScheduledMessagesCalendar from "@/components/ScheduledMessagesCalendar";
import ScheduledMessagesList from "@/components/ScheduledMessagesList";
import _ from "lodash";

export default {
  name: "ScheduledMessages",
  store,
  components: {
    ScheduledMessagesCalendar,
    ScheduledMessagesList,
  },
  props: {
    inboxes: {
      type: Array,
      required: true,
    },
  },
  created() {
    if (this.$store.state.config.hasConnect) {
      this.$store.dispatch(ACTIONS.GET_CONNECT_FILTERS);
    }
    if (this.$store.state.config.hasRadius) {
      this.$store.dispatch(ACTIONS.GET_RADIUS_TARGETS);
    }
    if (_.get(this.$store.state.config, "integrations.raisersEdgeNxt")) {
      this.$store.dispatch(ACTIONS.GET_RE_NXT_LISTS);
    }
    if (_.get(this.$store.state.config, "integrations.slate")) {
      this.$store.dispatch(ACTIONS.GET_SLATE_QUERIES);
    }
  },
  computed: {
    scheduledMessages() {
      return this.$store.getters[GETTERS.SCHEDULED_MESSAGES_GROUPED_BY_DATE];
    },
  },
};
</script>

<style lang="scss" scoped></style>
