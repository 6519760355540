class eventBus {
    constructor() {
        this.bus = document.createElement('event-bus')
    }

    addEventListener(event, callback) {
        this.bus.addEventListener(event, callback)
    }

    removeEventListener(event, callback) {
        this.bus.removeEventListener(event, callback)
    }

    dispatchEvent(event, detail = {}) {
        this.bus.dispatchEvent(new CustomEvent(event, { detail }))
    }
}

const bus = new eventBus

window.eventBus = bus

export default bus