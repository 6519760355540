import api from "@/util/api";

export const ACTIONS = {
  GET_RENXT_CONFIG: "renxt/getRenxtConfig",
  UPDATE_RENXT_CONFIG: "renxt/updateRenxtConfig",
  GET_RENXT_CON_VALIDATION: "renxt/getRenxtConValid",
  GET_CRM_IMPORT_FIELDS: "renxt/getCrmImportFields",
  SET_RENXT_IS_LOADING: "renxt/setRenxtIsLoading",
  SET_RENXT_IS_CONFIGURED: "renxt/setRenxtIsConfigured",
  GET_CRM_CONSTITUENT_LISTS: "renxt/getCrmConstituentLists",
  GET_RENXT_IMPORTS: "renxt/getImports",
  GET_RENXT_EXPORTS: "renxt/getExports",
  GET_RENXT_EXPORT_SUMMARY: "renxt/getExportSummary",
};

export const MUTATIONS = {
  SET_RENXT_CON_VALIDATION: "renxt/setRenxtConfig",
  SET_RENXT_CONFIG: "renxt/mutRenxtConfig",
  SET_RENXT_IS_LOADING: "renxt/mutRenxtIsLoading",
  SET_RENXT_IS_CONFIGURED: "renxt/mutRenxtIsConfigured",
  SET_CRM_CONSTITUENT_LISTS: "renxt/setCrmLists",
  SET_CRM_IMPORT_FIELDS: "renxt/setCrmImportFields",
  SET_RENXT_IMPORTS: "renxt/setImports",
  SET_RENXT_EXPORTS: "renxt/setExports",
  SET_RENXT_EXPORT_SUMMARY: "renxt/setExportSummary",
};

export const defaultCrmField = {
  id: "",
  name: "",
  groupName: "",
  type: "",
  typeFormat: "",
};

const defaultBaseState = {
  activityEmailList: "",
  failureEmailList: "",
  lastAuthorized: {
    authorizedBy: "",
    authorizedOn: "",
  },
};

const defaultCrmState = {
  lists: [],
  importFields: [],
};

const defaultImportSettingsState = {
  customFields: [],
  optOut: {},
  constituentListId: "00000000-0000-0000-0000-000000000000",
  schedule: {},
  standardFields: {},
  crm: defaultCrmState,
};

const defaultExportSettingsState = {
  enableMessagePush: false,
  enableOptOutPush: false,
  exportToActions: false,
  exportToNotes: false,
  noteType: "",
  actionType: "",
};

const defaultApiListItemState = {
  items: [],
  skip: 0,
  take: 50,
  totalItems: 0,
};

const defaultExportSummaryState = {
  messages: {
    success: 0,
    fail: 0,
  },
  optOuts: {
    success: 0,
    fail: 0,
  },
};

export default {
  state: () => ({
    id: "",
    isLoading: true,
    isConfigured: undefined,
    base: defaultBaseState,
    importSettings: defaultImportSettingsState,
    exportSettings: defaultExportSettingsState,
    exportSummary: defaultExportSummaryState,
    imports: defaultApiListItemState,
    exports: defaultApiListItemState,
    crm: defaultCrmState,
  }),
  actions: {
    async [ACTIONS.GET_CRM_CONSTITUENT_LISTS]({ commit }) {
      let lists = await api.get(`/RaisersEdgeNxt/constituent-lists`);

      commit(MUTATIONS.SET_CRM_CONSTITUENT_LISTS, lists);
    },
    async [ACTIONS.GET_CRM_IMPORT_FIELDS]({ commit }) {
      let fields = await api.get(`/RaisersEdgeNxt/fields`);

      commit(MUTATIONS.SET_CRM_IMPORT_FIELDS, fields);
    },
    async [ACTIONS.GET_RENXT_CONFIG]({ commit }) {
      const config = await api.get("/RaisersEdgeNxt/configuration");
      commit(MUTATIONS.SET_RENXT_CONFIG, config);
    },
    async [ACTIONS.UPDATE_RENXT_CONFIG]({ commit }, value) {
      const config = await api.patch("/RaisersEdgeNxt/configuration", value);
      commit(MUTATIONS.SET_RENXT_CONFIG, config);
    },
    [ACTIONS.SET_RENXT_IS_LOADING]({ commit }, isLoading = false) {
      commit(MUTATIONS.SET_RENXT_IS_LOADING, isLoading);
    },
    async [ACTIONS.SET_RENXT_IS_CONFIGURED](
      { commit },
      isConfigured = undefined
    ) {
      if (isConfigured === undefined) {
        const isConfigured_ = await api.get("/RaisersEdgeNxt/authorized");
        commit(MUTATIONS.SET_RENXT_IS_CONFIGURED, isConfigured_);
      } else {
        commit(MUTATIONS.SET_RENXT_IS_CONFIGURED, isConfigured);
      }
    },
    async [ACTIONS.GET_RENXT_IMPORTS]({ commit }, skip = 0, take = 50) {
      let headers = {
        "x-disable-loading": true,
      };

      let imports = await api.get(
        `/RaisersEdgeNxt/data/imports?skip=${skip}?take=${take}`,
        {
          undefined,
          headers,
        }
      );

      commit(MUTATIONS.SET_RENXT_IMPORTS, imports);
    },
    async [ACTIONS.GET_RENXT_EXPORTS]({ commit }, skip = 0, take = 50) {
      let headers = {
        "x-disable-loading": true,
      };

      let exports = await api.get(
        `/RaisersEdgeNxt/data/exports/errors?skip=${skip}?take=${take}`,
        {
          undefined,
          headers,
        }
      );

      commit(MUTATIONS.SET_RENXT_EXPORTS, exports);
    },
    async [ACTIONS.GET_RENXT_EXPORT_SUMMARY]({ commit }) {
      let exportSummary = await api.get("/RaisersEdgeNxt/data/exports/summary");

      commit(MUTATIONS.SET_RENXT_EXPORT_SUMMARY, exportSummary);
    },
  },
  getters: {},
  mutations: {
    [MUTATIONS.SET_RENXT_CONFIG](state, config) {
      state.id = config.id;
      state.base = config.base;
      state.importSettings = config.imports;
      state.exportSettings = config.exports;
    },
    [MUTATIONS.SET_RENXT_IS_LOADING](state, isLoading) {
      state.isLoading = isLoading;
    },
    [MUTATIONS.SET_RENXT_IS_CONFIGURED](state, isConfigured) {
      state.isConfigured = isConfigured;
    },
    [MUTATIONS.SET_CRM_IMPORT_FIELDS](state, fields) {
      state.crm.importFields = fields;
    },
    [MUTATIONS.SET_CRM_CONSTITUENT_LISTS](state, lists) {
      state.crm.lists = lists;
    },
    [MUTATIONS.SET_RENXT_IMPORTS](state, imports) {
      state.imports = imports;
    },
    [MUTATIONS.SET_RENXT_EXPORTS](state, exports) {
      state.exports = exports;
    },
    [MUTATIONS.SET_RENXT_EXPORT_SUMMARY](state, exportSummary) {
      state.exportSummary = exportSummary;
    },
  },
};
