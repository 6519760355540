<template>
  <div
    id="webChatVisitorDetailsHeader"
    class="d-flex flex-row justify-content-between align-items-center m-0"
  >
    <div class="d-flex flex-column">
      <div class="h4 font-weight-normal m-0">Details</div>
      <small v-if="visitorName && !editUserDetailsFFEnabled" class="subtitle" v-html="visitorName"></small>
    </div>
    <MgLink
      class="btn-icon close"
      :value="{ value: 'toggle-webchat-visitor-details' }"
      globalEventName="toggle-webchat-visitor-details"
      alt="Close Details"
      title="Close Details"
    >
      <MgIcon name="times" scale="1.1" />
    </MgLink>
  </div>
</template>
<script>
import MgIcon from "@/components/MgIcon";
import MgLink from "@/components/MgLink";
// remove this import when rollout-revamp-chat-details-sidemenu ff is removed
import { GETTERS } from "@/util/constants";
import { visitorFullName } from "@/util/webchat";

export default {
  name: "WebChatVisitorDetailsHeader",
  components: { MgIcon, MgLink },
  props: {
    conversation: {
      type: Object,
      required: false,
    },
  },
  computed: {
    visitorName() {
      // remove this computed property when rollout-revamp-chat-details-sidemenu ff is removed
      return visitorFullName(this.conversation) || "Visitor";
    },
  },
  methods: {
    editUserDetailsFFEnabled() {
      return this.$store.getters[GETTERS.FEATURE_ENABLED](
        "rollout-revamp-chat-details-sidemenu"
      );
    },
  }
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

#webChatVisitorDetailsHeader {
  padding: 1rem;
  height: 69px;
  background-color: $gray-light-bg;
}

.subtitle {
  color: $dark-gray-subtitle;
  white-space: nowrap;
  margin-left: 1px;
}
</style>
