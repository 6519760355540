<template>
  <span>
    {{ email }}
    <button
      type="button"
      class="btn btn-sm btn-primary ml-3"
      v-b-modal="'authModal'"
    >
      Auth
    </button>
    <b-modal
      id="authModal"
      title="Auth"
      hide-footer
      title-tag="h3"
      @shown="focusElement"
    >
      <h5 v-show="error" class="text-danger">{{ error }}</h5>
      <form @submit.prevent="signIn">
        <div class="form-group">
          <label for="email">Email address</label>
          <input
            v-model="email"
            type="email"
            ref="email"
            class="form-control"
            id="email"
          />
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input
            v-model="password"
            type="password"
            ref="password"
            class="form-control"
            id="password"
          />
        </div>
        <div class="form-actions">
          <button type="submit" class="btn btn-primary" :disabled="signingIn">
            <div
              v-show="signingIn"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
            Sign In
          </button>
        </div>
      </form>
    </b-modal>
  </span>
</template>

<script>
import Vue from "vue";
import { ModalPlugin } from "bootstrap-vue";
import axios from "axios/index";
import api from "@/util/api";
import eventBus from "@/util/eventBus";

Vue.use(ModalPlugin);

export default {
  name: "Auth",
  data() {
    return {
      email: "",
      error: "",
      password: "",
      signingIn: false,

      selected: null,
      options: [
        {
          label: "Grouped options",
          options: [
            { value: { C: "3PO" }, text: "Option with object value" },
            { value: { R: "2D2" }, text: "Another option with object value" }
          ]
        }
      ]
    };
  },
  mounted() {
    let profile = window.localStorage.profile
      ? JSON.parse(window.localStorage.profile)
      : null;
    this.email = profile ? profile.emailAddress : "";

    eventBus.addEventListener("authRequired", this.openModal);
  },
  methods: {
    focusElement() {
      if (!this.email) {
        this.$refs.email.focus();
      } else {
        this.$refs.password.focus();
      }
    },
    openModal() {
      this.$bvModal.show("authModal");
    },
    signIn() {
      const params = new URLSearchParams();
      params.append("username", this.email);
      params.append("password", this.password);
      params.append("grant_type", "password");

      this.error = "";
      this.signingIn = true;

      return axios({
        method: "post",
        url: process.env.VUE_APP_TOKEN_URL,
        data: params,
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
      })
        .then(response => {
          const token = response.data.access_token;
          window.localStorage.setItem("token", token);

          return axios
            .all([api.get("/configuration"), api.get("/profile")])
            .then(response => {
              let config = response[0];
              let profile = response[1];
              window.localStorage.setItem("config", JSON.stringify(config));
              window.localStorage.setItem("profile", JSON.stringify(profile));
              window.localStorage.removeItem("activeInbox");
              window.location.reload();
            });
        })
        .catch(err => {
          this.error = err.response.data.invalid_grant;
        })
        .finally(() => {
          this.signingIn = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
