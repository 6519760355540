import { ACTIONS, MUTATIONS } from "@/util/constants";
import api from "@/util/api";
import _ from "lodash";

export default {
  state: () => ({
    admin: { groupAccounts: [] },
    breakdown: [],
    contract: {},
    departments: "",
    inboxes: [],
    reservedNumbers: [],
    phoneType: "",
    lookupNumber: "",
    availableNumbers: [],
    resultNumbers: {
      hasNumbers: "",
      number: "",
      areaCode: "",
    },
    staff: "",
    groupAccountLimit: "",
    configuration: {},
  }),
  actions: {
    async [ACTIONS.GET_SHARED_INBOXES]({ commit }, inboxParams) {
      let groups, departments;
      let options = { params: inboxParams };
      groups = await api.get(`/admin/groupAccounts`, options);
      departments = await api.get(`/departments/all`);
      groups.map(function (g) {
        g.department = _.find(departments, { id: g.departmentId }).name;
        return g;
      });

      commit(MUTATIONS.SET_SHARED_INBOXES, groups);
    },
    async [ACTIONS.GET_CONTRACT]({ commit }) {
      let contract = await api.get(`/contract`),
        userCount = await api.get(`admin/users/count`),
        groupCount = await api.get(`admin/groupAccounts`),
        contractUserCount = contract ? contract.users : "unlimited",
        accountsCount = userCount + groupCount.length;

      commit(MUTATIONS.SET_CONTRACT, {
        accountsCount: accountsCount,
        groupCount: groupCount,
        accountLimit: contractUserCount,
        accountLimitMet: accountsCount >= contractUserCount,
      });
    },
    async [ACTIONS.GET_SHARED_INBOX_NUMBERS]({ commit }, numberLookup) {
      commit(MUTATIONS.SET_LOOKUP_NUMBER, numberLookup);
      const endpoint = "/twilio/numberlookup";
      const options = {
        params: {
          areaCode: numberLookup.areaCode,
          prefix: numberLookup.prefix,
        },
        headers: {
          "x-disable-loading": true,
        },
      };
      const numbers = await api.get(endpoint, options);
      return commit(MUTATIONS.SET_SHARED_INBOX_NUMBERS, numbers);
    },
    async [ACTIONS.GET_SHARED_INBOX_RESERVED_NUMBERS]({ commit }) {
      const endpoint = "/twilio/reservednumbers";
      const options = {
        params: {
          includeLandlines: true,
        },
        headers: {
          "x-disable-loading": true,
        },
      };
      const reserved = await api.get(endpoint, options);
      return commit(MUTATIONS.SET_SHARED_INBOX_RESERVED_NUMBERS, reserved);
    },

    async [ACTIONS.GET_SHARED_INBOX_STAFF]({ commit }, departmentId) {
      let staff = await api.get(
        `/staff?departmentId=${departmentId}&HydrateDepartmentAccessInfo=true`
      );

      return commit(MUTATIONS.SET_SHARED_INBOX_STAFF, staff);
    },
    async [ACTIONS.GET_SHARED_INBOX_CONFIG]({ commit }) {
      const groupAccountLimit = await api.get("/configuration");

      return commit(MUTATIONS.SET_SHARED_INBOX_SETTINGS, groupAccountLimit);
    },
  },
  mutations: {
    [MUTATIONS.SET_SHARED_INBOXES](state, groups) {
      state.admin.groupAccounts = groups;
    },
    [MUTATIONS.SET_CONTRACT](state, contract) {
      state.contract = contract;
    },
    [MUTATIONS.SET_LOOKUP_NUMBER](state, lookupNumber) {
      state.lookupNumber = lookupNumber;
    },
    [MUTATIONS.SET_SHARED_INBOX_NUMBERS](state, numbers) {
      state.availableNumbers = _.chunk(numbers, Math.ceil(numbers.length / 2));
      state.resultNumbers = {
        hasNumbers: numbers.length > 0,
        number: numbers.length,
        areaCode: state.lookupNumber.areaCode,
      };
    },
    [MUTATIONS.SET_SHARED_INBOX_RESERVED_NUMBERS](state, reserved) {
      state.reservedNumbers = _.chunk(reserved, Math.ceil(reserved.length / 2));
      state.phoneType = state.reservedNumbers.length ? "reserved" : "new";
    },
    [MUTATIONS.SET_SHARED_INBOX_STAFF](state, staff) {
      state.staff = _.sortByOrder(
        staff,
        [(staff) => staff.firstName.toLowerCase()],
        ["asc"]
      );
    },
    [MUTATIONS.SET_SHARED_INBOX_SETTINGS](state, config) {
      state.configuration = config;
    },
    [MUTATIONS.CLEAR_SHARED_INBOX_AVAILABLE_NUMBERS](state) {
      state.availableNumbers = [];
    },
  },
};
