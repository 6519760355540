<template>
  <div @click="selectItem()" @keyup.enter="selectItem()">
    <MgKendoCard
      :class="{ selected, read }"
      hideFooter
      orientation="horizontal"
      data-testid="list-item-card"
      tabindex="0"
    >
      <template v-slot:header>
        <WebChatVisitorAvatar
          class="visitor-avatar"
          :class="{ 'header-selected': selected }"
          :color="conversation.visitorAvatarColor"
          :name="visitorName"
        />
      </template>
      <template v-slot:body>
        <div class="d-flex flex-row justify-content-between">
          <span
            class="title font-weight-bold text-truncate"
            :class="{ unread: !read }"
            v-html="
              visitorName ||
              (visitorLocation
                ? `Visitor&nbsp;&bull;&nbsp;${visitorLocation}`
                : 'Visitor')
            "
          ></span>
          <span class="sent-on text-nowrap" :class="{ unread: !read }">
            {{ lastMessageSentOn | fromNow }}
          </span>
        </div>
        <div class="d-flex flex-row justify-content-between align-items-center">
          <div
            class="last-message single-line-clamp"
            :class="{ unread: !read }"
            v-html="lastMessageText"
          ></div>
          <WebChatConversationListUserAvatar
            v-if="showUserAvatar"
            class="list-user-avatar"
            :name="userName"
            :source="source"
            :userAvatar="userAvatar"
            :assignedToCurrentUser="assignedToCurrentUser"
          />
        </div>
      </template>
    </MgKendoCard>
  </div>
</template>
<script>
import _ from "lodash";
import MgKendoCard from "@/components/MgKendoCard";
import WebChatVisitorAvatar from "@/components/WebChatVisitorAvatar";
import WebChatConversationListUserAvatar from "./WebChatConversationListUserAvatar";
import store from "@/store";
import eventBus from "@/util/eventBus";
import { ACTIONS, GETTERS } from "@/util/constants";
import {
  isMyConversation,
  isUnassignedConversation,
  isFollowUp,
  isRead,
  hasNewIncomingMessages,
  visitorFullName,
  lastMessageSentOn,
  lastMessageText,
  visitorLocation,
  gravatarUrlWithBlankImage,
  isUserAssisted,
  isConversationAssignedToCurrentUser,
  isConversationOwnedByCurrentTeam,
  transformMarkdownLinksFromBotpress,
} from "@/util/webchat";

export default {
  name: "WebChatConversationListItem",
  store,
  components: {
    MgKendoCard,
    WebChatVisitorAvatar,
    WebChatConversationListUserAvatar,
  },
  data() {
    return {
      incomingMessageSound_: null,
    };
  },
  props: {
    conversation: {
      type: Object,
      required: true,
    },
    bot: {
      type: Object,
      required: true,
    },
  },
  computed: {
    selected() {
      return (
        _.get(
          this.$store.state.webChat.selectedConversation,
          "conversationId"
        ) === this.conversation.conversationId
      );
    },
    read() {
      // read/unread only applies to My Conversations and Unassigned Conversations. read and
      // hasNewIncomingMessages are different so we can bold the list item without firing off
      // all the noises and lights when a new message comes in.
      return (
        (isRead(this.conversation) &&
          !hasNewIncomingMessages(this.conversation)) ||
        (!isMyConversation(this.conversation) &&
          !isUnassignedConversation(this.conversation, this.bot))
      );
    },
    visitorName() {
      return visitorFullName(this.conversation);
    },
    lastMessageSentOn() {
      return lastMessageSentOn(this.conversation);
    },
    lastMessageText() {
      let lastMessage = lastMessageText(this.conversation) || "";
      return transformMarkdownLinksFromBotpress(lastMessage);
    },
    visitorLocation() {
      return visitorLocation(this.conversation);
    },
    source() {
      return isUserAssisted(this.conversation) ? "Agent" : "Bot";
    },
    userAvatar() {
      return gravatarUrlWithBlankImage(
        this.$store.getters[GETTERS.USER_EMAIL_BY_ID](
          this.conversation.assignedStaffId
        )
      );
    },
    userName() {
      return this.$store.getters[GETTERS.USER_NAME_BY_ID](
        this.conversation.assignedStaffId
      );
    },
    assignedToCurrentUser() {
      return isConversationAssignedToCurrentUser(this.conversation);
    },
    showUserAvatar() {
      return (
        isConversationOwnedByCurrentTeam(this.conversation) &&
        !(isFollowUp(this.conversation) && this.source === "Bot")
      );
    },
  },
  methods: {
    selectItem() {
      this.$store.dispatch(
        ACTIONS.UPDATE_SELECTED_WEBCHAT_CONVERSATION,
        this.conversation
      );

      if (!this.read) {
        this.$store.dispatch(ACTIONS.MARK_WEBCHAT_CONVERSATION_READ, {
          conversationId: this.conversation.conversationId,
          post: true,
        });
      }

      eventBus.dispatchEvent("conversation-selected", {
        conversationId: this.conversation.conversationId,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";
.single-line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.k-card {
  border-radius: 0;
  background-color: inherit;
  border-top-color: $gray-pane-border;
  border-bottom-color: $gray-pane-border;
  border-top: none;
  cursor: pointer;

  @include transition(200ms ease background-color);
  @include transition(200ms ease box-shadow);
}

.read {
  background-color: $gray-100;
}

.unread {
  font-weight: $font-weight-bold !important;
  color: $text-color !important;
}

.title {
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 3px;
}

.sent-on {
  font-size: 0.75rem;
  font-weight: $font-weight-normal;
}

.last-message {
  font-size: 13px;
  line-height: 1.4;
}

.selected {
  position: relative;
  background-color: inherit;
  box-shadow: 0 0 8px rgba(129, 93, 93, 0.2);
  border-top: 0;
  border-right: 0;
  border-left: 5px solid transparent;
  border-left-color: $accent;
  z-index: 1;
}

.header-selected {
  margin-left: -4px;
}

.list-user-avatar {
  margin-left: 12px;
}

::v-deep .k-card-header {
  display: flex;
  align-items: center;
  padding-right: 2px;
  padding-left: 0.9rem;
  border: none;
  background-color: inherit;
  overflow: visible;
}

::v-deep .k-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 71px;
  color: #737373;
  a {
    pointer-events: none;
    color: inherit;
  }
}
</style>
