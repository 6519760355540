<template>
  <div>
    <form class="search" @submit.prevent="search">
      <BInputGroup :class="{ active: focus }">
        <template v-slot:prepend>
          <BButton variant="outline" class="search-icon" type="submit"
            ><strong><MgIcon name="search" scale=".75" /></strong
            ><span class="sr-only">Submit</span></BButton
          >
        </template>
        <div id="searchInputLabel" class="sr-only">{{ searchPlaceholder }}</div>
        <BFormInput
          @focus="focus = true"
          @blur="focus = false"
          @input="updateValue"
          v-on:keyup.esc="reset()"
          :value="searchText"
          :placeholder="searchPlaceholder"
          aria-labelledby="searchInputLabel"
        >
        </BFormInput>
        <template v-slot:append>
          <BButton variant="outline" @click="reset">
            <strong class="text-danger"
              ><MgIcon
                class="reset-search"
                :class="{ searched: searchText }"
                name="times"
                scale=".75"
              /><span class="sr-only">Reset Search</span></strong
            >
          </BButton>
        </template>
      </BInputGroup>
    </form>
  </div>
</template>

<script>
import { BButton, BFormInput, BInputGroup } from "bootstrap-vue";
import store from "@/store";
import { ACTIONS } from "@/util/constants";
import MgIcon from "@/components/MgIcon";

export default {
  name: "ConversationSearch",
  store,
  data() {
    return {
      focus: false,
      searchInput: null,
    };
  },
  components: {
    BButton,
    BFormInput,
    BInputGroup,
    MgIcon,
  },
  computed: {
    searchPlaceholder() {
      if (this.$store.state.inbox.isInboxView) {
        return "Search Inbox by Name, ID, or Mobile";
      }
      return "Search Archived by Name, ID, or Mobile";
    },
    searchText() {
      return this.$store.state.inbox.activeFilter.conversationSearch;
    },
  },
  methods: {
    reset() {
      if (this.searchText) {
        this.$store.dispatch(ACTIONS.UPDATE_ACTIVE_INBOX_SEARCH, null);
        this.searchInput = null;
        this.$emit("search");
      }
    },
    search() {
      if (!this.searchInput) {
        return this.reset();
      }
      this.$store.dispatch(
        ACTIONS.UPDATE_ACTIVE_INBOX_SEARCH,
        this.searchInput
      );
      this.$emit("search");
    },
    updateValue(value) {
      this.searchInput = value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
.search {
  background: $gray-light-bg;
  padding: 19px 15px;
}
.search-icon {
  padding-left: 8px;
  padding-right: 9px;
}
.input-group {
  border: 1px solid $input-border;
  border-radius: $border-radius-base;
  &.active {
    border: 1px solid $input-focus-border-color;
  }
}
.form-control {
  border: 0;
  padding: $input-padding-y 1px;
}
button {
  background: white;
  border: 0;
}
.reset-search {
  visibility: hidden;
  &.searched {
    visibility: visible;
  }
}
.contact-card-open {
  @media (min-width: $screen-md) and (max-width: $screen-lg) {
    .search {
      display: none;
    }
  }
}
</style>
