<template>
  <div>
    <label for="recruitFieldSelect">{{ integrationName }} Field</label>
    <BFormSelect
      id="recruitFieldSelect"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="field.crmField.id"
      :options="groupedOptions"
      :text-field="useRawValue ? 'value' : 'text'"
      :class="isError ? 'is-invalid' : ''"
    >
      <template #first>
        <BFormSelectOption value="" disabled>
          Select a {{ integrationName }} Field
        </BFormSelectOption>
      </template>
    </BFormSelect>
  </div>
</template>
<script>
import { BFormSelect } from "bootstrap-vue";
import _ from "lodash";

export default {
  name: "IntegrationsViewFieldSelect",
  props: {
    field: {
      type: Object,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    useRawValue: {
      type: Boolean,
      required: true,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    integrationName: {
      type: String,
      required: false,
      default: "Recruit",
    },
  },
  computed: {
    groupedOptions() {
      return _.map(
        _.groupBy(_.sortBy(this.options, "groupName"), "groupName"),
        (group) => {
          return {
            label: group[0].groupName,
            options: _.map(_.sortBy(group, "name"), (rField) => {
              return {
                value: rField.id,
                text: rField.name,
              };
            }),
          };
        }
      );
    },
  },
  components: {
    BFormSelect,
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";
</style>
