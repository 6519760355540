<template>
  <div :id="cellId" class="w-100 cell mx-1" :style="`opacity: ${heatOpacity};`">
    <BPopover
      :target="cellId"
      placement="auto"
      triggers="hover focus"
      ref="popover"
    >
      <template v-slot:title> {{ day }} </template>
      <strong>{{ time }}</strong>
      <div>{{ `${conversationTypeDisplay}: ${value}` }}</div>
    </BPopover>
  </div>
</template>
<script>
import { BPopover } from "bootstrap-vue";
import { conversationTypeDisplayText } from "@/util/webchat";

export default {
  name: "ChatAnalyticsHeatMapCell",
  components: { BPopover },
  props: {
    value: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    day: {
      type: String,
      required: true,
    },
    time: {
      type: String,
      required: true,
    },
    filterData: {
      type: Object,
      required: true,
    },
    liveChatOnly: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      baseOpacity: 0.1,
      cellId: crypto.randomUUID(),
    };
  },
  computed: {
    heatValue() {
      return this.value - this.min;
    },
    totalHeat() {
      return this.max - this.min;
    },
    heatPercent() {
      return this.totalHeat ? this.heatValue / this.totalHeat : 0;
    },
    heatOpacity() {
      return (
        this.baseOpacity +
        this.heatPercent * (1.0 - this.baseOpacity)
      ).toFixed(2);
    },
    conversationTypeDisplay() {
      return conversationTypeDisplayText(
        this.filterData.heatMapConversationType,
        this.filterData.heatMapFollowupRequested,
        this.liveChatOnly,
        true
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/variables";
@import "src/scss/utilities";

.cell {
  height: 32px;
  background-color: $accent-alt-a11y;
  transition: opacity 1.5s ease-in-out;

  @include media-breakpoint-up(md) {
    height: 20px;
    width: unset;
  }
}
</style>
