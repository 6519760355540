<template>
  <BModal
    :id="modalId"
    :title="title"
    :static="test"
    :hasAutomatedExport="hasAutomatedExport"
    :hasAutomatedOn="hasAutomatedOn"
    :automatedExportDate="automatedExportDate"
    :infoBox="infoBox"
    :automatedExportTab="automatedExportTab"
    title-tag="h3"
    @close="cancel()"
    @hide="cancel()"
  >
    <div v-if="errorMessage.length" class="alert alert-danger">
      <b v-if="errorMessage[0] && errorMessage[0].length" aria-live="polite">{{
        errorMessage[0]
      }}</b>
      <span v-if="errorMessage.length === 2">&nbsp;&nbsp;</span>
      <span
        v-if="errorMessage[1] && errorMessage[1].length"
        aria-live="polite"
        >{{ errorMessage[1] }}</span
      >
    </div>
    <slot />
    <template v-slot:modal-footer>
      <span v-if="automatedExportTab">
        <button @click="toggleAutomaticExport()" class="btn btn-primary">
          <MgIcon name="power-off" scale=".75"></MgIcon>
          Turn {{ hasAutomatedOn ? "Off" : "On" }}
        </button>
        <span v-if="hasAutomatedExport" class="align-text">
          {{ hasAutomatedOn ? "On" : "Off" }} since
          {{ formatDate(automatedExportDate) }}</span
        >
      </span>
      <span v-else>
        <button @click="confirm()" class="btn btn-primary">
          Request Export
        </button>
      </span>
      <button @click="cancel()" class="btn btn-light">Cancel</button>
    </template>
  </BModal>
</template>
<script>
import { BModal } from "bootstrap-vue";
import { formatDate } from "@/util/dates";
import MgIcon from "@/components/MgIcon";

export default {
  name: "ExportModal",
  components: {
    BModal,
    MgIcon,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    keyValue: {
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    errorMessage: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    test: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasAutomatedExport: {
      type: String,
      required: false,
      default: "",
    },
    hasAutomatedOn: {
      type: Boolean,
      required: false,
      default: false,
    },
    automatedExportDate: {
      type: String,
      required: false,
      default: "",
    },
    infoBox: {
      type: String,
      required: false,
      default: "",
    },
    automatedExportTab: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.showModal();
      } else if (!newVal && oldVal) {
        this.hideModal();
      }
    },
  },
  methods: {
    confirm() {
      this.$emit("confirmed", this.title);
    },
    cancel() {
      this.$emit("cancelled");
    },
    toggleAutomaticExport() {
      this.$emit("toggleAutomaticExport");
    },
    showModal() {
      this.$bvModal.show(this.modalId);
    },
    hideModal() {
      this.$bvModal.hide(this.modalId);
    },
    formatDate(date) {
      return formatDate(date, "M/D/YY[,] h:mm A");
    },
  },
};
</script>
<style lang="scss" scoped>
.align-text {
  vertical-align: middle;
  padding: 8px;
}
</style>
