<template>
  <div class="d-flex flex-column h-100">
    <SubpageHeader
      :headerTitle="tag_.name"
      :headerSubtitle="
        tag_.createdByName ? `Created by: ${tag_.createdByName}` : ''
      "
    >
      <template v-if="canModify" v-slot:menu>
        <TagDetailSubHeaderMenu
          :tagId="tag_.id"
          :archived="tag_.archive"
          @archive-tag="handleArchiveClick()"
          @de-archive-tag="handleArchiveClick()"
          @edit-tag="promptEditTag()"
          @delete-tag="promptDeleteTag()"
        />
      </template>
    </SubpageHeader>
    <div class="d-flex flex-column overflow-auto h-100 page-content">
      <div class="d-lg-flex flex-row align-items-center my-4">
        <div class="metric header-card">
          <span class="metric-value">{{ tagDetails_.messageCount }}</span
          ><span class="metric-label">Messages</span>
        </div>
        <div class="metric header-card middle-card">
          <span class="metric-value">{{ tagDetails_.contactCount }}</span
          ><span class="metric-label">Contacts</span>
        </div>
        <div class="header-card text-break-word">
          <div>
            <span class="text-muted">Status - </span
            >{{ tag_.archive ? "Archived" : "Active" }}
          </div>
          <div>
            <span class="text-muted">Created - </span>
            {{ formatDate(tag_.createdOn, "MM/DD/YYYY, h:mm A") }}
          </div>
          <div v-if="tag_.description">
            <span class="text-muted">Description - </span>
            {{ tag_.description }}
          </div>
        </div>
      </div>
      <BCard class="d-flex flex-column grid-card">
        <template #header>
          <div
            class="d-flex flex-row justify-content-between align-items-center"
          >
            <h3 class="card-title">Messages</h3>
            <BButton
              @click="exportHistory()"
              v-if="rowData_.length > 0"
              variant="light"
              size="sm"
              ref="exportButton"
              >Export <span v-if="isFiltered">Results</span></BButton
            >
          </div>
        </template>
        <div
          class="d-lg-flex flex-row justify-content-end align-items-center pl-3 pr-3 pb-3"
        >
          <div class="d-lg-flex w-100 align-items-center justify-content-start">
            <div class="sm-filter-row sm-filter-row-1 filter-text">
              <span>Filter by:</span>
              <FilterDropdown
                :options="[
                  { displayText: 'All Messages', field: '' },
                  { displayText: 'Tag Added Date', field: 'setOn' },
                ]"
                :selectedKeyValue="fieldFilter_"
                keyPrefix="tag-filter-field-"
                keyField="field"
                labelField="displayText"
                @click="handleFieldFilterClick"
              />
            </div>
            <div class="sm-filter-row sm-filter-row-2">
              <FilterDropdown
                v-if="fieldFilter_"
                :options="[
                  { displayText: 'is on', operator: 'equals' },
                  { displayText: 'is not on', operator: 'notEquals' },
                  {
                    displayText: 'is before or on',
                    operator: 'lessThanOrEqualedTo',
                  },
                  {
                    displayText: 'is on or after',
                    operator: 'greaterThanOrEqualedTo',
                  },
                ]"
                :selectedKeyValue="operatorFilter_"
                keyPrefix="tag-filter-date-"
                keyField="operator"
                labelField="displayText"
                @click="handleOperatorFilterClick"
              />
              <DatePicker
                v-if="fieldFilter_"
                class="ml-3"
                :class="{ 'date-error': showDateFeedback_ }"
                :selectedDateValue="dateFilter_"
                @date-picked="handleDateFilterPicked"
                :style="{ width: '100%' }"
              />
            </div>
          </div>
          <div class="sm-filter-row sm-filter-row-3">
            <ServerSearch
              searchIconClass="search"
              cancelIconClass="times"
              placeholder="Search by Name or Mobile"
              elementKey="tag-message-search"
              @search="handleSearch"
              :searchText="searchText_"
              :style="{
                'min-width': '170px',
                position: 'relative',
                width: '100%',
              }"
            />
          </div>
        </div>
      </BCard>
      <div
        class="h-100"
        :class="{
          'mh-0': rowData_.length,
          card: loading_,
        }"
      >
        <TagDetailGrid
          v-show="!loading_"
          :rowData="rowData_"
          :skip="skip_"
          :total="tagDetails_.meta.total"
          :sort="sort_"
          @data-state-change="handleGridDataStateChange"
          class="tag-detail-grid"
        />
      </div>
    </div>
    <AddEditTagModal
      :tagId="editTagId_"
      :tagName="editTagName_"
      :tagDescription="editTagDescription_"
      :saving="saving_"
      :show="showEditTagModal_"
      :errorMessage="editTagErrorMessage_"
      @edit-tag-confirmed="handleEditTagConfirmed"
      @tag-cancelled="cancelModals()"
    />
    <ConfirmationModal
      modalId="deleteTag-confirm"
      :keyValue="deleteTagId_"
      :title="`Delete ${deleteTagName_}`"
      confirmButtonText="Delete"
      :saving="saving_"
      :show="showDeleteTagModal_"
      :errorMessage="deleteTagErrorMessage_"
      @confirmed="handleDeleteTagConfirmed"
      @cancelled="cancelModals()"
    >
      <h4 class="error-list">Are you sure you want to delete this tag?</h4>
      <ul class="list-bulleted">
        <li>This tag will be removed from all Messages and Contacts.</li>
      </ul>
    </ConfirmationModal>
  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import api from "@/util/api";
import store from "@/store";
import eventBus from "@/util/eventBus";
import analytics from "@/util/analytics";
import { alphaNumeric } from "@/util/filters";
import { formatDate } from "@/util/dates";
import { ACTIONS, API_ERROR_MESSAGES } from "@/util/constants";
import { BCard, BButton, ToastPlugin } from "bootstrap-vue";
import SubpageHeader from "@/components/SubpageHeader";
import AddEditTagModal from "@/components/AddEditTagModal";
import ConfirmationModal from "@/components/ConfirmationModal";
import FilterDropdown from "@/components/FilterDropdown";
import TagDetailGrid from "./TagDetailGrid";
import TagDetailSubHeaderMenu from "./TagDetailSubHeaderMenu";
import ServerSearch from "@/components/ServerSearch";
import DatePicker from "@/components/DatePicker";
import Toast from "@/components/Toast";

Vue.use(ToastPlugin);

export default {
  name: "TagDetailPage",
  store,
  components: {
    SubpageHeader,
    TagDetailSubHeaderMenu,
    AddEditTagModal,
    ConfirmationModal,
    FilterDropdown,
    TagDetailGrid,
    ServerSearch,
    DatePicker,
    BCard,
    BButton,
  },
  props: {
    tagId: {
      type: String,
      required: true,
    },
    fromProfile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dateFilter_: "",
      skip_: 0,
      sort_: [{ field: "name", dir: "asc" }],
      deleteTagErrorMessage_: [],
      deleteTagId_: 0,
      deleteTagName_: "",
      editTagDescription_: "",
      editTagErrorMessage_: [],
      editTagId_: 0,
      editTagName_: "",
      fieldFilter_: "",
      filters_: {},
      loading_: false,
      operatorFilter_: "equals",
      rowData_: [],
      saving_: false,
      searchText_: "",
      showDeleteTagModal_: false,
      showEditTagModal_: false,
      showDateFeedback_: false,
      tagDetails_: { messageCount: 0, contactCount: 0, meta: { total: 0 } },
      tag_: {},
    };
  },
  computed: {
    canModify() {
      return this.tag_.createdBy === this.$store.state.config.userId;
    },
    isFiltered() {
      return this.dateFilter_ !== "" || this.searchText_ !== "";
    },
  },
  watch: {
    showEditTagModal_(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.editTagErrorMessage_ = [];
      }
    },
    showDeleteTagModal_(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.deleteTagErrorMessage_ = [];
      }
    },
    dateFilter_(newVal) {
      if (newVal) {
        this.showDateFeedback_ = false;
      }
    },
  },
  async created() {
    try {
      this.loading_ = true;
      this.filters_ = this.getFilters();
      this.sort_ = this.filters_.sort;
      this.skip_ = this.filters_.skip;
      this.fieldFilter_ = this.filters_.fieldFilter;
      this.operatorFilter_ = this.filters_.operatorFilter;
      this.dateFilter_ = this.filters_.dateFilter;

      if (this.fromProfile) {
        this.searchText_ = this.filters_.searchText;
      }
      const results = await Promise.all([
        this.fetchTag(),
        this.fetchTagDetails(),
      ]);

      this.tag_ = results[0];
      this.tagDetails_ = results[1];
      this.rowData_ = this.tagDetails_.results;
      this.loading_ = false;

      eventBus.addEventListener("view-tag-contact", this.handleViewTagContact);
    } catch (err) {
      this.loading_ = false;
      this.errorToast("There was an error loading the page.");
      return err;
    }
  },
  destroyed() {
    eventBus.removeEventListener("view-tag-contact", this.handleViewTagContact);
  },
  methods: {
    //fetch and load
    fetchTag() {
      return api.get(`tags/${this.tagId}`);
    },
    fetchTagDetails() {
      let options = {
        params: {
          nameOrPhone: alphaNumeric(this.searchText_),
          skip: this.skip_,
          take: 50,
          sortColumn: _.get(this.sort_, "[0].field"),
          sortDirection: _.get(this.sort_, "[0].dir"),
        },
      };

      if (this.dateFilterReady()) {
        this.showDateFeedback_ = false;
        options.params = {
          ...options.params,
          dateComparison: this.operatorFilter_,
          date: formatDate(this.dateFilter_, "M-D-YYYY"),
        };
      }

      return api.get(`tags/${this.tagId}/details`, options);
    },
    async refreshTag() {
      this.tag_ = await this.fetchTag();
    },
    async refreshGrid() {
      try {
        this.tagDetails_ = await this.fetchTagDetails();
        this.rowData_ = this.tagDetails_.results;
      } catch (err) {
        this.loading_ = false;
        this.errorToast("There was an error loading messages.");
        return err;
      }
    },
    // export contacts/messages with tag
    async exportHistory() {
      if (this.fieldFilter_ === "setOn" && !this.dateFilter_) {
        this.showDateFeedback_ = true;
      } else {
        this.showDateFeedback_ = false;
        try {
          await api.post(`tags/${this.tag_.id}/export`, {
            dateComparison: this.dateFilterReady()
              ? this.operatorFilter_
              : "none",
            nameOrPhone: alphaNumeric(this.searchText_),
            date: this.dateFilterReady()
              ? formatDate(this.dateFilter_, "M-D-YYYY")
              : "",
          });
          this.successToast(
            "You will receive the export via email when it is ready."
          );
        } catch (err) {
          this.errorToast("There was an error exporting tag history.");
        }
        analytics.track("Clicked Export in Tag Detail");
      }
      this.$refs.exportButton.blur();
    },

    // archive or unarchive tag
    archiveTag() {
      return api.put(`tags/${this.tag_.id}`, { archive: !this.tag_.archive });
    },

    // cancel modal action
    cancelModals() {
      this.showEditTagModal_ = false;
      this.showDeleteTagModal_ = false;
    },

    // edit tag
    promptEditTag() {
      this.editTagId_ = this.tag_.id;
      this.editTagName_ = this.tag_.name;
      this.editTagDescription_ = this.tag_.description;
      this.showEditTagModal_ = true;
    },
    async editTagConfirmed(tagId, tagName, tagDescription) {
      try {
        this.saving_ = true;
        await api.put(`tags/${tagId}`, {
          name: tagName,
          description: tagDescription,
        });
        this.$store.dispatch(ACTIONS.GET_TAGS);
        this.saving_ = false;
        this.showEditTagModal_ = false;
        this.refreshTag();
      } catch (err) {
        const response = _.get(err, "response.data");
        this.saving_ = false;
        if (response === API_ERROR_MESSAGES.DUPLICATE_TAG) {
          this.editTagErrorMessage_ = [
            "There is already a tag with that name.",
            "Please choose a different name.",
          ];
        } else {
          this.editTagErrorMessage_ = [
            "There was an error editing the tag.",
            "",
          ];
        }
      }
    },

    // delete tag
    promptDeleteTag() {
      this.deleteTagId_ = this.tag_.id;
      this.deleteTagName_ = this.tag_.name;
      this.showDeleteTagModal_ = true;
    },
    async deleteTagConfirmed(tagId) {
      try {
        this.saving_ = true;
        await api.delete(`tags/${tagId}`);
        this.$store.dispatch(ACTIONS.GET_TAGS);
        this.goBack();
      } catch (err) {
        this.saving_ = false;
        this.deleteTagErrorMessage_ = [
          "There was an error deleting the tag.",
          "",
        ];
        return err;
      }
    },

    // menu handlers
    async handleArchiveClick() {
      try {
        await this.archiveTag();
        await this.refreshTag();
      } catch (err) {
        this.errorToast(
          `There was an error ${
            this.tag_.archive ? "un" : ""
          }archiving the tag.`
        );
        return err;
      }
    },
    handleEditClick() {
      this.promptEditTag();
    },

    // eventBus handlers
    handleViewTagContact(event) {
      this.$emit("viewTagContact", { contactId: event.detail.contactId });
    },

    // page handlers
    async handleEditTagConfirmed(data) {
      await this.editTagConfirmed(
        data.tagId,
        data.tagName,
        data.tagDescription
      );
    },
    async handleDeleteTagConfirmed(data) {
      await this.deleteTagConfirmed(data.value);
    },
    async handleFieldFilterClick(data) {
      if (this.fieldFilter_ === "setOn" && data.selectedOption.field === "") {
        this.operatorFilter_ = "equals";
        this.dateFilter_ = "";
      }

      this.fieldFilter_ = data.selectedOption.field;
      this.storeFilters();
      if (!this.fieldFilter_ || this.dateFilterReady()) {
        await this.refreshGrid();
      }
    },
    async handleOperatorFilterClick(data) {
      this.operatorFilter_ = data.selectedOption.operator;
      this.storeFilters();
      if (this.dateFilterReady()) {
        await this.refreshGrid();
      }
    },
    async handleDateFilterPicked(selectedDate) {
      this.dateFilter_ = selectedDate;
      this.storeFilters();
      if (this.dateFilterReady()) {
        await this.refreshGrid();
      }
    },
    handleGridDataStateChange(data) {
      this.skip_ = data.skip;
      this.sort_ = data.sort;
      this.storeFilters();
      this.refreshGrid();
    },
    handleSearch(data) {
      this.searchText_ = data.searchText;
      this.storeFilters();
      this.refreshGrid();
    },

    // util
    errorToast(message) {
      let toastBody = this.$createElement(Toast, {
        props: {
          message,
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "danger",
        noCloseButton: true,
        solid: true,
      });
    },
    successToast(message) {
      let toastBody = this.$createElement(Toast, {
        props: {
          message,
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "success",
        noCloseButton: true,
        solid: true,
        autoHideDelay: 3000,
      });
    },
    getFilters() {
      return (
        this.$store.state.tag.tagDetailSearch || {
          searchText: this.searchText_,
          sort: this.sort_,
          skip: this.skip_,
          fieldFilter: this.fieldFilter_,
          operatorFilter: this.operatorFilter_,
          dateFilter: this.dateFilter_,
        }
      );
    },
    storeFilters() {
      this.$store.dispatch(ACTIONS.UPDATE_TAG_DETAIL_FILTER, {
        searchText: this.searchText_,
        sort: this.sort_,
        skip: this.skip_,
        fieldFilter: this.fieldFilter_,
        operatorFilter: this.operatorFilter_,
        dateFilter: this.dateFilter_,
      });
    },
    dateFilterReady() {
      return this.fieldFilter_ === "setOn" && this.dateFilter_;
    },
    formatDate(date, format) {
      return formatDate(date, format);
    },
    goBack() {
      this.$emit("back");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

::v-deep k-grid-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.filter-text {
  color: $gray;
}

.header-card {
  @include media-breakpoint-up(lg) {
    overflow: auto;
    max-height: 103px;
  }

  &.middle-card {
    @include media-breakpoint-up(lg) {
      margin-right: 20px;
      margin-left: 20px;
    }
  }

  flex: 1;
  margin-bottom: 20px;
}

.sm-filter-row {
  #tag-filter-date-dropdown {
    ::v-deep .dropdown-toggle {
      margin-left: 0px;
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;

    #tag-filter-date-dropdown {
      ::v-deep .dropdown-toggle {
        margin-left: 15px;
      }
    }
  }

  &.sm-filter-row-2 {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;

    @include media-breakpoint-up(lg) {
      margin-top: inherit;
      margin-bottom: inherit;
    }
  }

  &.sm-filter-row-3 {
    @include media-breakpoint-up(lg) {
      width: 35%;
    }
  }
}

.grid-card {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .card-body {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-top: 16px;
  }
}

.tag-detail-grid {
  min-height: 310px;
}
.date-error {
  ::v-deep .input-group {
    border-color: #ff0000;
  }
}
</style>
