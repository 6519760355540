<template>
  <div
    id="recruitIntegrationPage"
    class="d-flex flex-column h-100 overflow-auto"
  >
    <div
      id="pageHeader"
      class="d-flex flex-row page-header justify-content-end"
    >
      <div class="flex-grow-1">
        <h1>Recruit Integration</h1>
      </div>
      <div v-show="isConfigured">
        <button
          id="recruit-config-button"
          class="btn btn-light"
          v-b-modal.recruitConfigModal
        >
          <span v-show="conValidation.success">
            <MgIcon name="cog" scale=".8125" />
          </span>
          <span v-show="!conValidation.success" class="text-danger">
            <MgIcon name="exclamation-circle" scale=".8125" />
          </span>
          Recruit Settings
        </button>
        <BTooltip
          :target="!conValidation.success ? 'recruit-config-button' : ''"
          placement="left"
        >
          {{ `${conValidation.statusCode} - ${conValidation.message}` }}
        </BTooltip>
      </div>
    </div>
    <div v-show="!isLoading">
      <div v-if="isConfigured">
        <div class="nav-container sticky-top">
          <ul class="nav nav-tabs">
            <li
              class="nav-item"
              :key="tab"
              :class="{ 'active-tab': currentTab === tab }"
              v-for="tab in tabs"
              v-on:click="handleTabChange(tab)"
            >
              <button class="nav-link active">{{ tab }}</button>
            </li>
          </ul>
        </div>
        <div class="h-100 page-content">
          <div class="component-container">
            <component
              v-bind:is="currentTabComponent"
              :exportSummary="exportSummary"
              :exportErrors="exportErrors"
              :imports="imports"
              :isImportConfigured="isImportConfigured"
              :isExportConfigured="isExportConfigured"
            />
          </div>
        </div>
      </div>
      <div class="page-content has-empty-state-container" v-else>
        <div
          class="d-flex justify-content-center align-items-center has-empty-state-card"
        >
          <div class="container">
            <div class="row">
              <div class="col-md-4 d-flex justify-content-end">
                <img
                  alt=""
                  src="/images/icon_file-sync.svg"
                  width="98"
                  height="96"
                />
              </div>
              <div class="col-md-8">
                <div class="row has-empty-state-text">
                  Automate contact imports, send message activity & opt-out
                  status changes in real-time
                </div>
                <div class="row">
                  <button
                    class="btn btn-primary mr-2"
                    v-b-modal.recruitConfigModal
                  >
                    Configure
                  </button>
                  <a
                    class="has-empty-state-link"
                    role="button"
                    @click="showHelpArticle"
                    >Learn More</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RecruitConfigModal
      :baseSettings="baseSettings"
      :conValidation="conValidation"
      :isConfigured="isConfigured"
    />
  </div>
</template>
<script>
import { BTooltip } from "bootstrap-vue";
import { ToastPlugin } from "bootstrap-vue";
import { VBModal } from "bootstrap-vue";
import Vue from "vue";
import _ from "lodash";
import PageHeader from "@/components/PageHeader";
import eventBus from "@/util/eventBus";
import store from "@/store";
import MgIcon from "@/components/MgIcon";
import serializeProfile from "@/util/profile";
import Toast from "@/components/Toast";
import RecruitExportsTab from "./RecruitExportsTab";
import RecruitDashboardTab from "./RecruitDashboardTab";
import RecruitConfigModal from "./RecruitConfigModal";
import RecruitImportsTab from "./RecruitImportsTab";
import { ACTIONS } from "./recruitStore";

Vue.use(ToastPlugin);

/* eslint-disable */
Vue.component("tab-dashboard", RecruitDashboardTab);
Vue.component("tab-imports", RecruitImportsTab);
Vue.component("tab-exports", RecruitExportsTab);
/* eslint-enable */

export default {
  name: "RecruitPage",
  store,
  components: {
    PageHeader,
    RecruitDashboardTab,
    RecruitExportsTab,
    RecruitImportsTab,
    RecruitConfigModal,
    VBModal,
    BTooltip,
    MgIcon,
  },
  props: {
    currentTab: {
      type: String,
      required: true,
      default: "dashboard",
    },
  },
  data() {
    return {
      tabs: ["dashboard", "imports", "exports"],
    };
  },
  async created() {
    const currentDepartment = serializeProfile().currentDepartment;
    const generatedId = `org-${currentDepartment.organizationId}-dept-${currentDepartment.id}`;

    if (generatedId !== this.$store.state.recruit.id) {
      try {
        await this.$store.dispatch(ACTIONS.GET_RECRUIT_CONFIG);
        await this.$store.dispatch(ACTIONS.GET_RECRUIT_EXPORT_ERRORS);
        await this.$store.dispatch(ACTIONS.GET_RECRUIT_IMPORTS);
        await this.$store.dispatch(ACTIONS.GET_RECRUIT_EXPORT_SUMMARY);
        await this.$store.dispatch(ACTIONS.GET_RECRUIT_CON_VALIDATION);
      } catch (e) {
        let toastBody = this.$createElement(Toast, {
          props: {
            message: "Something went wrong.",
          },
        });
        this.$bvToast.toast([toastBody], {
          variant: "danger",
          noCloseButton: true,
          solid: true,
        });
      }
    }

    eventBus.addEventListener("recruit-tab-selected", this.handleTabChange);

    this.$store.dispatch(ACTIONS.SET_RECRUIT_IS_LOADING, false);
  },
  destroyed() {
    eventBus.removeEventListener("recruit-tab-selected", this.handleTabChange);
  },
  computed: {
    isLoading() {
      return this.$store.state.recruit.isLoading;
    },
    currentTabComponent() {
      return `tab-${this.currentTab}`;
    },
    baseSettings() {
      return _.cloneDeep(this.$store.state.recruit.base);
    },
    conValidation() {
      return this.$store.state.recruit.conValidation;
    },
    exportSummary() {
      return this.$store.state.recruit.exportSummary;
    },
    imports() {
      return this.$store.state.recruit.imports;
    },
    exportErrors() {
      return this.$store.state.recruit.exports;
    },
    isConfigured() {
      return _.isEmpty(this.baseSettings)
        ? false
        : !(
            this.baseSettings.username === "" ||
            this.baseSettings.password === "" ||
            this.baseSettings.adfsRootUrl === "" ||
            this.baseSettings.crmRootUrl === ""
          );
    },
    isImportConfigured() {
      return this.$store.state.recruit.importSettings.savedViewId !== "";
    },
    isExportConfigured() {
      return this.$store.state.recruit.exportSettings.activityCode !== "";
    },
  },
  methods: {
    handleTabChange(t) {
      this.$emit("recruitTabSelected", {
        currentTab: t,
      });
    },
    showHelpArticle() {
      // eslint-disable-next-line no-undef
      Beacon("article", "62a736e21e5b41737b38d573", { type: "modal" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.page-content {
  padding: 16px 30px;
}

.has-empty-state-container {
  background-color: #fafafa;
  margin: 15px 30px;
  border: 0;
  box-shadow: $panel-box-shadow;

  .has-empty-state-card {
    margin: 60px 30px;

    .has-empty-state-text {
      font-size: $font-size-lg;
      font-weight: 300;
      margin-bottom: 20px;
      color: #555;
    }

    .has-empty-state-link {
      padding-top: 8px;
    }
  }
}

.active-tab {
  border: unset;
  border-bottom: 4px solid $accent;
}

.nav-container {
  width: 100%;
  padding: 0 30px;
  z-index: 0;
  background-color: #fff;
}

.nav-link {
  border: unset;
  text-transform: uppercase;
}

.nav-tabs {
  width: 100%;
}
</style>
