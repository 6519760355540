<template>
  <div class="col">
    <div class="row">
      <div class="col">{{ min }}</div>
      <div class="col text-right">{{ max }}</div>
    </div>
    <div class="col cell" />
  </div>
</template>
<script>
export default {
  name: "ChatAnalyticsHeatMapLegend",
  props: {
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.cell {
  height: 20px;
  background-image: linear-gradient(to right, #e5f0f6, $accent-alt-a11y);
}
</style>
