<template>
  <form>
    <p>
      {{
        `The import process can be enabled to retrieve new and changed contact
      records${!single ? " up to four times a day" : ""}.`
      }}
    </p>

    <div class="form-group m-0" v-if="!single">
      <div class="form-check form-check-inline">
        <BFormCheckbox
          id="schedule-overnight"
          v-model="importSchedule.overnight"
        >
          <span class="checkbox-label">
            <span class="text-strong">Overnight</span>
            <span class="text-muted text-small">
              {{ `3am-5am ${orgTimezoneToShortString()}` }}
            </span>
          </span>
        </BFormCheckbox>
      </div>

      <div class="form-check form-check-inline">
        <BFormCheckbox id="schedule-morning" v-model="importSchedule.morning">
          <span class="checkbox-label">
            <span class="text-strong">Morning</span>
            <span class="text-muted text-small">
              {{ `10am-12pm ${orgTimezoneToShortString()}` }}
            </span>
          </span>
        </BFormCheckbox>
      </div>

      <div class="form-check form-check-inline">
        <BFormCheckbox
          id="schedule-afternoon"
          v-model="importSchedule.afternoon"
        >
          <span class="checkbox-label">
            <span class="text-strong">Afternoon</span>
            <span class="text-muted text-small"
              >{{ ` 2pm-4pm ${orgTimezoneToShortString()}` }}
            </span>
          </span>
        </BFormCheckbox>
      </div>
      <div class="form-check form-check-inline">
        <BFormCheckbox id="schedule-evening" v-model="importSchedule.evening">
          <span class="checkbox-label">
            <span class="text-strong">Evening</span>
            <span class="text-muted text-small">
              {{ `6pm-8pm ${orgTimezoneToShortString()}` }}
            </span>
          </span>
        </BFormCheckbox>
      </div>
    </div>

    <div class="form-group m-0" v-else>
      <div class="form-check form-check-inline">
        <BFormRadio
          id="schedule-overnight"
          @change="checkRadioButton"
          :checked="radioSelection"
          value="overnight"
        >
          <span class="checkbox-label">
            <span class="text-strong">Overnight</span>
            <span class="text-muted text-small">
              {{ `3am-5am ${orgTimezoneToShortString()}` }}
            </span>
          </span>
        </BFormRadio>
      </div>

      <div class="form-check form-check-inline">
        <BFormRadio
          id="schedule-morning"
          @change="checkRadioButton"
          :checked="radioSelection"
          value="morning"
        >
          <span class="checkbox-label">
            <span class="text-strong">Morning</span>
            <span class="text-muted text-small">
              {{ `10am-12pm ${orgTimezoneToShortString()}` }}
            </span>
          </span>
        </BFormRadio>
      </div>

      <div class="form-check form-check-inline">
        <BFormRadio
          id="schedule-afternoon"
          @change="checkRadioButton"
          :checked="radioSelection"
          value="afternoon"
        >
          <span class="checkbox-label">
            <span class="text-strong">Afternoon</span>
            <span class="text-muted text-small"
              >{{ ` 2pm-4pm ${orgTimezoneToShortString()}` }}
            </span>
          </span>
        </BFormRadio>
      </div>
      <div class="form-check form-check-inline">
        <BFormRadio
          id="schedule-evening"
          @change="checkRadioButton"
          :checked="radioSelection"
          value="evening"
        >
          <span class="checkbox-label">
            <span class="text-strong">Evening</span>
            <span class="text-muted text-small">
              {{ `6pm-8pm ${orgTimezoneToShortString()}` }}
            </span>
          </span>
        </BFormRadio>
      </div>
    </div>
  </form>
</template>
<script>
import { BFormCheckbox, BFormRadio } from "bootstrap-vue";
import moment from "moment";
import _ from "lodash";

export default {
  name: "IntegrationsImportScheduleCard",
  components: {
    BFormCheckbox,
    BFormRadio,
  },
  props: {
    importSchedule: {
      type: Object,
      required: true,
    },
    orgTimezone: {
      type: String,
      required: false,
    },
    single: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      radioSelection: "",
    };
  },
  created() {
    this.radioSelection = this.importSchedule.overnight
      ? "overnight"
      : this.importSchedule.morning
      ? "morning"
      : this.importSchedule.afternoon
      ? "afternoon"
      : this.importSchedule.evening
      ? "evening"
      : "";
  },
  methods: {
    orgTimezoneToShortString() {
      let tz = moment.tz.zone(this.orgTimezone);

      if (!this.orgTimezone || this.orgTimezone === null || tz === null)
        return "";

      return `${_.head(tz.abbrs[0])}${_.last(tz.abbrs[0])}`;
    },
    checkRadioButton(selection) {
      this.importSchedule.overnight = false;
      this.importSchedule.morning = false;
      this.importSchedule.afternoon = false;
      this.importSchedule.evening = false;

      this.importSchedule[selection] = true;
      this.radioSelection = selection;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.text-small {
  padding-top: 2px;
  font-size: 85%;
}

.text-strong {
  font-weight: 400;
  color: #555;
}

.card-content {
  padding: 12px 30px;
}

.checkbox-label {
  font-weight: 400;
  font-size: 0.875rem;
  padding-top: 2px;
}
</style>
