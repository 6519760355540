<template>
  <MgTag
    :title="name"
    :icon="'tag-solid'"
    :bgColor="'purple'"
    :hover-close="true"
    :hover-close-active="hoverCloseActive"
    :no-remove-button="noRemoveButton"
    @remove="removeTag"
  ></MgTag>
</template>

<script>
import MgTag from "@/components/MgTag";
import { GETTERS } from "@/util/constants";
import store from "@/store";

export default {
  name: "MessageTag",
  store,
  components: {
    MgTag,
  },
  props: {
    tagId: {
      required: false,
    },
    hoverCloseActive: {
      type: Boolean,
      default: false,
    },
    noRemoveButton: {
      type: Boolean,
    },
  },
  computed: {
    name() {
      return this.tagId ? this.getTagNameById : this.tagName;
    },
    getTagNameById() {
      return this.$store.getters[GETTERS.GET_TAG_NAME_BY_ID](this.tagId);
    },
  },
  methods: {
    removeTag() {
      this.$emit("remove-tag");
    },
  },
};
</script>
