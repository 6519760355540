<template>
  <div id="webChatControls" class="d-flex flex-column">
    <WebChatFilterModal
      modalId="webChatFilterModal"
      :show="showFilterModal_"
      :filters="filters"
      @cancelled="hideFilterModal"
      @confirmed="applyFilters"
    />
    <div>
      <ServerSearch
        searchIconClass="search"
        cancelIconClass="times"
        placeholder="Search by Name or Phone"
        elementKey="chat"
        @search="handleSearch"
      />
    </div>
    <div
      class="d-flex flex-row align-items-center justify-content-end chat-filter"
    >
      <MgLink
        class="btn-icon"
        eventName="click-filter-modal"
        @click-filter-modal="showFilterModal"
      >
        <div class="d-flex flex-row align-items-center justify-content-end">
          <MgIcon name="sliders-solid" />
          <strong class="ml-1">FILTER</strong>
        </div>
      </MgLink>
    </div>
  </div>
</template>
<script>
import ServerSearch from "@/components/ServerSearch";
import store from "@/store";
import MgIcon from "@/components/MgIcon";
import MgLink from "@/components/MgLink";
import WebChatFilterModal from "@/components/WebChatFilterModal";
import { ACTIONS } from "@/util/constants";
import analytics from "@/util/analytics";

export default {
  name: "WebChatControls",
  store,
  components: { ServerSearch, MgIcon, MgLink, WebChatFilterModal },
  data() {
    return {
      showFilterModal_: false,
    };
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleSearch(data) {
      this.$store.dispatch(ACTIONS.GET_WEBCHAT_CONVERSATIONS, data.searchText);
      analytics.track("Searched for a chat conversation", {
        searchedText: data.searchText,
      });
    },
    applyFilters(data) {
      this.$store.dispatch(
        ACTIONS.UPDATE_WEBCHAT_FILTERS,
        data.selectedFilters
      );
      this.hideFilterModal();
    },
    showFilterModal() {
      this.showFilterModal_ = true;
    },
    hideFilterModal() {
      this.showFilterModal_ = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

#webChatControls {
  padding-top: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: 0;
  background-color: $gray-light-bg;
  border-bottom: 1px solid $gray-pane-border;
}

.chat-filter {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-icon {
  opacity: 0.8;
  transition: 150ms ease-in-out;
  color: inherit;
  text-decoration: none;

  &:hover {
    opacity: 1;
  }
}
</style>
