var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Chart',{style:({
    paddingTop: '1rem',
    display: 'flex',
    'justify-content': 'center',
    height: '16rem',
  }),attrs:{"seriesColors":_vm.seriesColors}},[_c('Tooltip',{attrs:{"color":'#243B54',"background":'#F2F4F6',"padding":_vm.padding,"render":'defaultTooltipRender'},scopedSlots:_vm._u([{key:"defaultTooltipRender",fn:function(ref){
  var props = ref.props;
return [_c('span',[_c('strong',[_vm._v(" "+_vm._s(props.point.series.name)+": ")]),_vm._v("\n        "+_vm._s(props.point.value)+"\n      ")])]}}])}),_c('Y-Axis',[_c('Y-AxisItems',{attrs:{"major-grid-lines":_vm.hidden,"line":_vm.hidden.line,"labels":_vm.hidden}})],1),_c('X-Axis',[_c('X-AxisItems',{attrs:{"major-grid-lines":_vm.hidden,"line":_vm.hidden.line}})],1),_c('ChartDataPoints',[_c('ChartDataPointItems',{attrs:{"name":_vm.requests.title,"type":'column',"visibleInLegend":false,"stack":{ type: '100%' },"data-items":[_vm.requests.value]}}),_c('ChartDataPointItems',{attrs:{"name":_vm.remainingConversations.title,"visibleInLegend":false,"type":'column',"data-items":[_vm.remainingConversations.value]}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }