<template>
  <div class="emptyState d-flex justify-content-center">
    <div class="d-flex align-items-center" v-if="searchText.length">
      <div class="mr-4">
        <MgIcon name="magnifying-glass" scale="5"></MgIcon>
      </div>
      <div>
        <h2>No Results Found</h2>
        <p>Try adjusting your search terms</p>
      </div>
    </div>
    <div class="d-flex align-items-center" v-else>
      <div class="mr-4">
        <MgIcon name="magnifying-glass-list" scale="5"></MgIcon>
      </div>
      <div>
        <div v-if="name === 'Users'">
          <h2>
            The selected team(s) do not have any Users to display. This may be
            because there are no Users in the team(s) or you do not have Admin
            access in the team(s).
          </h2>
        </div>
        <div v-else-if="name === 'Campaigns'">
          <p>
            <a
              href="https://help.mongooseresearch.com/category/215-campaigns"
              target="_blank"
            >
              Learn more about Campaigns</a
            >.
          </p>
        </div>
        <div v-else-if="name === 'Inboxes'">
          <p>
            Learn more about
            <a
              href="https://help.mongooseresearch.com/category/229-group-accounts"
              target="_blank"
              >Shared Inboxes</a
            >
          </p>
        </div>
        <div v-else>
          <h2>The selected team(s) do not have any {{ name }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MgIcon from "@/components/MgIcon";
export default {
  name: "AnalyticsBreakdownEmptyState",
  props: {
    type: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    searchText: {
      type: String,
      required: false,
    },
  },
  components: {
    MgIcon,
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";

.emptyState {
  background: #fff;
  color: $gray-700;
  padding: 60px $card-spacer-x;
  width: 100%;
}
.emptyState ::v-deep svg {
  padding: 20px;
  border-radius: 50%;
  background: #f9fafa;
}
.emptyState h2,
.emptyState .h2 {
  font-size: $h5-font-size;
  font-weight: $font-weight-bold;
}
.emptyState p {
  font-size: $h5-font-size;
  font-weight: $font-weight-light;
  margin: 0;
}
</style>
