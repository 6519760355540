<template>
  <span
    v-show="!hideOnZero || displayNumber > 0"
    class="badge badge-nav fade-in-out-fast"
  >
    {{ displayNumber }}
  </span>
</template>
<script>
export default {
  name: "MgBadge",
  props: {
    displayNumber: {
      type: Number,
      required: true,
    },
    hideOnZero: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped></style>
