const serializeProfile = () => {
  let profileJSON;

  try {
    profileJSON = JSON.parse(window.localStorage.profile);
  } catch {
    profileJSON = null;
  }

  return profileJSON;
};

export default serializeProfile;
