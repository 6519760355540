<template>
  <MgKendoCard orientation="horizontal" hideHeader hideFooter data-testid="web-chat-page-history">
    <template v-slot:body>
      <div class="d-flex flex-column">
        <a data-testid="location-url" :href="location.pageUrl" class="text-truncate" target="_blank">{{
          location.pageUrl
        }}</a>
        <div class="d-flex flex-row justify-content-end">
          <span>{{ location.timestamp | fromNow }}</span>
        </div>
      </div>
    </template>
  </MgKendoCard>
</template>
<script>
import MgKendoCard from "@/components/MgKendoCard";

export default {
  name: "WebChatVisitorDetailsLocation",
  components: { MgKendoCard },
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

.k-card {
  margin-bottom: 1rem;
  border: none;
}

::v-deep .k-card-body {
  padding: 0.5rem;
  border-radius: 4px;
  background-color: $message-content-bg;
  overflow: auto;
  border: 1px solid $message-content-bg;
  color: #495050;
}
</style>
