import {
  ACTIONS,
  GETTERS,
  MUTATIONS,
  WEBCHAT_NOTIFICATION_TYPES,
  WEBCHAT_VISITOR_AVATAR_COLORS,
  WEBCHAT_FILTERS,
} from "@/util/constants";
import api from "@/util/api";
import _ from "lodash";
import signalR from "@/util/signalR";
import {
  insertWebChatMessage,
  transformSignalRWebChatMessage,
  isMyConversation,
  isMessageOutgoing,
  isMessageInternalLog,
  isNoAssignmentMode,
  visitorShortName,
} from "@/util/webchat";
import {
  arrayToHashMap,
  hashGet,
  hashMerge,
  hashCloneDeep,
  hashDelete,
  hashValues,
  hashValuesIterator,
  mapSize,
} from "@/util/hash";
import { createBrowserNotification } from "@/util/notifications";
import getProfile from "@/util/profile";

export default {
  async [ACTIONS.ADD_WEBCHAT_CONVERSATION_TO_LIST_BY_ID](
    { commit, state, dispatch, getters },
    { conversationId, setSelected }
  ) {
    // make sure we've got the bot
    await dispatch(ACTIONS.GET_WEBCHAT_BOT);

    // Fetch message history
    const conversationResponse = await api.get(
      `${state.bot.id}/hitl/conversation/${conversationId}`,
      {
        headers: {
          "x-disable-loading": true,
        },
      }
    );

    // Decorate
    const newConversation = {
      ...conversationResponse,
      visitorAvatarColor: getters[GETTERS.GET_NEXT_WEBCHAT_VISITOR_COLOR],
      lastMessage:
        conversationResponse.messages &&
        conversationResponse.messages.length >= 1
          ? conversationResponse.messages[
              conversationResponse.messages.length - 1
            ]
          : null,
    };
    dispatch(ACTIONS.INCREMENT_WEBCHAT_VISITOR_AVATAR_COLOR_INDEX);

    // Update list if it's not already in there
    if (!getters[GETTERS.GET_WEBCHAT_CONVERSATION_BY_ID](conversationId)) {
      commit(
        MUTATIONS.SET_WEBCHAT_CONVERSATIONS,
        hashMerge(
          hashCloneDeep(state.conversations),
          conversationId,
          newConversation
        )
      );
    }

    // Set conversation selected if it's being added due to route param
    if (setSelected) {
      dispatch(ACTIONS.UPDATE_SELECTED_WEBCHAT_CONVERSATION, newConversation);
    }

    // Fire notifications if necessary
    dispatch(ACTIONS.UPDATE_UNREAD_WEBCHAT_CONVERSATIONS_COUNT);
  },

  async [ACTIONS.DELETE_WEBCHAT_CONVERSATION_IN_LIST_BY_ID](
    { commit, state, dispatch, getters },
    { conversationId }
  ) {

    let newConversations = hashCloneDeep(state.conversations);
    hashDelete(newConversations, conversationId)

    // Delete in list if it's still in there
    if (getters[GETTERS.GET_WEBCHAT_CONVERSATION_BY_ID](conversationId)) {
      commit(
        MUTATIONS.SET_WEBCHAT_CONVERSATIONS,
        newConversations
      );
    }

    // Fire notifications if necessary
    dispatch(ACTIONS.UPDATE_UNREAD_WEBCHAT_CONVERSATIONS_COUNT);
  },

  async [ACTIONS.UPDATE_SELECTED_WEBCHAT_CONVERSATION](
    { state, commit },
    conversation
  ) {
    let conversationResponse = await api.get(
      `${state.bot.id}/hitl/conversation/${conversation.conversationId}`
    );

    commit(MUTATIONS.SET_SELECTED_WEBCHAT_CONVERSATION, conversation);
    commit(
      MUTATIONS.SET_SELECTED_WEBCHAT_MESSAGES,
      conversationResponse.messages
    );
    commit(MUTATIONS.SET_WEBCHAT_SHOW_MOBILE_CONVERSATION_VIEW, true);
  },

  [ACTIONS.SEND_WEBCHAT_SIGNALR_MESSAGE]({ state }, message) {
    if (message === "MarkUserAvailable") {
      localStorage.cadenceUserAvailability = true;
    } else if (message === "MarkUserUnavailable") {
      localStorage.cadenceUserAvailability = false;
    }
    state.signalRConnection.send(message, state.bot.id);
  },

  async [ACTIONS.LOAD_MORE_WEBCHAT_OTHER_CONVERSATIONS](
    { state, commit, dispatch },
    skip
  ) {
    await dispatch(ACTIONS.GET_WEBCHAT_BOT);

    let newConversations;

    newConversations = await api.get(
      `${state.bot.id}/hitl/conversations?followup=false&assignedToMe=false&skip=${skip}&take=20`
    );

    let updatedConversations = hashValues(hashCloneDeep(state.conversations));

    updatedConversations = [
      ...updatedConversations,
      ...(newConversations.results || []),
    ];

    commit(
      MUTATIONS.SET_WEBCHAT_CONVERSATIONS,
      arrayToHashMap(updatedConversations, "conversationId")
    );

    dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION_COLORS);
  },

  async [ACTIONS.LOAD_MORE_WEBCHAT_FOLLOWUP_CONVERSATIONS](
    { state, commit, dispatch },
    skip
  ) {
    await dispatch(ACTIONS.GET_WEBCHAT_BOT);

    let newConversations;

    newConversations = await api.get(
      `${state.bot.id}/hitl/conversations?followup=true&skip=${skip}&take=20`
    );

    let updatedConversations = hashValues(hashCloneDeep(state.conversations));

    updatedConversations = [
      ...updatedConversations,
      ...(newConversations.results || []),
    ];

    commit(
      MUTATIONS.SET_WEBCHAT_CONVERSATIONS,
      arrayToHashMap(updatedConversations, "conversationId")
    );

    dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION_COLORS);
  },

  async [ACTIONS.GET_WEBCHAT_CONVERSATIONS](
    { state, commit, dispatch },
    searchText
  ) {
    await dispatch(ACTIONS.GET_WEBCHAT_BOT);
    const noAssignmentMode = isNoAssignmentMode(state.bot);

    let promises = [
      // My Conversations
      api.get(
        `${state.bot.id}/hitl/conversations?assignedToMe=true&followup=false&closed=false&limit=1000`
      ),
      // Follow Ups
      api.get(
        `${state.bot.id}/hitl/conversations?followup=true&skip=0&take=20${
          searchText ? "&searchText=" + searchText : ""
        }`
      ),
      // Other Conversations
      api.get(
        `${
          state.bot.id
        }/hitl/conversations?followup=false&assignedToMe=false&skip=0&take=20${
          searchText ? "&searchText=" + searchText : ""
        }`
      ),
    ];

    if (noAssignmentMode) {
      promises = [
        ...promises,
        // Unassigned Conversations
        api.get(
          `${state.bot.id}/hitl/conversations?unassigned=true&closed=false&skip=0&limit=1000`
        ),
      ];
    }

    let conversationResponse = await Promise.all(promises);
    let results = [
      ...(conversationResponse[0].results || []), // My Conversations
      ...(conversationResponse[1].results || []), // Follow Ups
      ...(conversationResponse[2].results || []), // Other Conversations
    ];

    if (noAssignmentMode) {
      results = [...results, ...(conversationResponse[3].results || [])]; // Unassigned Conversations
    }

    commit(
      MUTATIONS.SET_WEBCHAT_CONVERSATIONS,
      arrayToHashMap(results, "conversationId")
    );

    dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION_COLORS);
    dispatch(ACTIONS.UPDATE_UNREAD_WEBCHAT_CONVERSATIONS_COUNT);
    dispatch(ACTIONS.UPDATE_WEBCHAT_PAGINATION_META, {
      followUpTotal: conversationResponse[1].meta.total,
      otherTotal: conversationResponse[2].meta.total,
    });
  },

  async [ACTIONS.GET_OPEN_WEBCHAT_CONVERSATIONS]({ state, commit, dispatch }) {
    if (!mapSize(state.conversations)) {
      await dispatch(ACTIONS.GET_WEBCHAT_BOT);
      const noAssignmentMode = isNoAssignmentMode(state.bot);

      let promises = [
        api.get(
          `${state.bot.id}/hitl/conversations?assignedToMe=true&followup=false&closed=false&limit=1000`
        ),
      ];

      if (noAssignmentMode) {
        promises = [
          ...promises,
          api.get(
            `${state.bot.id}/hitl/conversations?unassigned=true&closed=false&skip=0&limit=1000`
          ),
        ];
      }

      // Just the open ones for purposes of correctly setting the notification badges etc.
      let conversationResponse = await Promise.all(promises);
      let results = [
        ...(conversationResponse[0].results || []), // My Conversations
      ];

      if (noAssignmentMode) {
        results = [...results, ...(conversationResponse[1].results || [])]; // Unassigned Conversations
      }

      // Make sure the Chat page didn't load these up while we were waiting for the request to complete
      if (!mapSize(state.conversations)) {
        commit(
          MUTATIONS.SET_WEBCHAT_CONVERSATIONS,
          arrayToHashMap(results, "conversationId")
        );
        dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION_COLORS);
      }
      dispatch(ACTIONS.UPDATE_UNREAD_WEBCHAT_CONVERSATIONS_COUNT);
    }
  },

  [ACTIONS.UPDATE_WEBCHAT_CONVERSATION_COLORS]({
    state,
    commit,
    dispatch,
    getters,
  }) {
    commit(MUTATIONS.SET_WEBCHAT_VISITOR_AVATAR_COLOR_INDEX, 0);

    let updatedConversations = hashCloneDeep(state.conversations);

    for (let conversation of hashValuesIterator(updatedConversations)) {
      if (!_.get(conversation, "visitorAvatarColor")) {
        Object.assign(conversation, {
          visitorAvatarColor: getters[GETTERS.GET_NEXT_WEBCHAT_VISITOR_COLOR],
        });
        dispatch(ACTIONS.INCREMENT_WEBCHAT_VISITOR_AVATAR_COLOR_INDEX);
      }
    }

    commit(MUTATIONS.SET_WEBCHAT_CONVERSATIONS, updatedConversations);
  },

  [ACTIONS.INCREMENT_WEBCHAT_VISITOR_AVATAR_COLOR_INDEX]({ state, commit }) {
    const numColors = WEBCHAT_VISITOR_AVATAR_COLORS.length;
    const maxIndex = numColors - 1;

    commit(
      MUTATIONS.SET_WEBCHAT_VISITOR_AVATAR_COLOR_INDEX,
      state.visitorAvatarColorIndex + 1 > maxIndex
        ? 0
        : state.visitorAvatarColorIndex + 1
    );
  },

  async [ACTIONS.GET_WEBCHAT_BOT]({ state, commit }) {
    if (!state.bot) {
      const bot = await api.get(`/bot`);
      commit(MUTATIONS.SET_WEBCHAT_BOT, bot);
      localStorage.selectedBotId = _.get(bot, "id");
    }
  },

  [ACTIONS.UPDATE_WEBCHAT_CONVERSATION](
    { state, commit, dispatch },
    { conversationId, keyValues }
  ) {
    const conversationToUpdate = hashGet(state.conversations, conversationId);

    if (conversationToUpdate) {
      const updatedConversation = _.merge(
        _.cloneDeep(conversationToUpdate),
        keyValues
      );

      let updatedConversations = hashCloneDeep(state.conversations);
      hashMerge(updatedConversations, conversationId, updatedConversation);

      commit(MUTATIONS.SET_WEBCHAT_CONVERSATIONS, updatedConversations);
      dispatch(ACTIONS.UPDATE_UNREAD_WEBCHAT_CONVERSATIONS_COUNT);

      if (
        state.selectedConversation &&
        updatedConversation.conversationId ===
          state.selectedConversation.conversationId
      ) {
        commit(
          MUTATIONS.SET_SELECTED_WEBCHAT_CONVERSATION,
          updatedConversation
        );
      }
    } else {
      //console.error("Conversation to update not found.");
    }
  },

  [ACTIONS.ASSIGN_WEBCHAT_CONVERSATION](
    { getters, dispatch },
    { conversationId, attributes, isManuallyAssigned = false }
  ) {
    const assigned = getters[GETTERS.GET_WEBCHAT_CONVERSATION_BY_ID](
      conversationId
    );
    const profile = getProfile();
    const assignedToMe = attributes.assignedStaffId === profile.id;
    const suppressNotifications =
      isManuallyAssigned &&
      _.get(attributes, "assignedConversationRead", false);

    // if we assigned it to ourselves we don't need to get screamed at to look at the conversation
    if (assignedToMe && !suppressNotifications) {
      createBrowserNotification(
        "New Chat Conversation",
        `${visitorShortName(assigned)} is waiting for you!`,
        "/images/icon_mongoose_paw_notification.png"
      );
    }

    let keyValues = {
      assignedStaffId: attributes.assignedStaffId,
      chatRequested: !attributes.assignedStaffId, // this is necessary for no-assignment mode
      assignedOn: attributes.timeStamp,
      assignedConversationRead: suppressNotifications ? true : false, // this controls notifications
    };

    const assignedTeamId = _.get(attributes, "assignedTeamId");
    if (assignedTeamId) {
      keyValues = { ...keyValues, assignedTeamId };
    }

    dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION, {
      conversationId,
      keyValues,
    });
  },

  [ACTIONS.UNASSIGN_WEBCHAT_CONVERSATION]({ dispatch }, conversationId) {
    dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION, {
      conversationId,
      keyValues: {
        assignedStaffId: 0,
      },
    });
  },

  async [ACTIONS.MARK_WEBCHAT_CONVERSATION_READ](
    { state, dispatch },
    { conversationId, post }
  ) {
    dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION, {
      conversationId,
      keyValues: {
        assignedConversationRead: true,
        hasNewIncomingMessages: false,
      },
    });

    // sometimes we're marking this ourselves, sometimes we're getting notified
    // about someone else on the team marking it
    if (post) {
      await api.post(
        `${state.bot.id}/hitl/conversation/${conversationId}/read`
      );
    }
  },

  [ACTIONS.CLOSE_WEBCHAT_CONVERSATION]({ dispatch }, conversationId) {
    dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION, {
      conversationId,
      keyValues: {
        closed: true,
      },
    });
  },

  [ACTIONS.REOPEN_WEBCHAT_CONVERSATION]({ dispatch }, conversationId) {
    dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION, {
      conversationId,
      keyValues: {
        closed: false,
      },
    });
  },

  [ACTIONS.UPDATE_WEBCHAT_CONVERSATION_VISITOR_DETAILS]({ dispatch }, change) {
    dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION, {
      conversationId: change.conversationId,
      keyValues: change.conversation,
    });
  },

  [ACTIONS.UPDATE_UNREAD_WEBCHAT_CONVERSATIONS_COUNT]({
    state,
    commit,
    getters,
  }) {
    commit(
      MUTATIONS.SET_WEBCHAT_UNREAD_MY_CONVERSATIONS_COUNT,
      getters[GETTERS.GET_UNREAD_WEBCHAT_MY_CONVERSATIONS_COUNT]
    );
    if (isNoAssignmentMode(state.bot)) {
      commit(
        MUTATIONS.SET_WEBCHAT_UNREAD_UNASSIGNED_CONVERSATIONS_COUNT,
        getters[GETTERS.GET_UNREAD_WEBCHAT_UNASSIGNED_CONVERSATIONS_COUNT]
      );
    }
  },

  [ACTIONS.UPDATE_WEBCHAT_CONVERSATION_FOLLOWUP](
    { state, dispatch },
    { conversationId, followUp, assignedStaffId }
  ) {
    dispatch(ACTIONS.UPDATE_WEBCHAT_PAGINATION_META, {
      followUpTotal: state.totalFollowUps + followUp ? 1 : -1,
      otherTotal: state.totalOtherConversations + followUp ? -1 : 1,
    });

    let keyValues = {
      followUp,
    };

    if (assignedStaffId) {
      keyValues = { ...keyValues, assignedStaffId };
    }

    dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION, {
      conversationId,
      keyValues,
    });
  },

  [ACTIONS.START_WEBCHAT_HEARTBEAT]({ state, commit, dispatch }) {
    if (state.signalRHeartbeat && !state.availableToChat) {
      clearInterval(state.signalRHeartbeat);
      commit(MUTATIONS.SET_WEBCHAT_HEARTBEAT, null);
    }

    if (!state.signalRHeartbeat && state.availableToChat) {
      {
        commit(
          MUTATIONS.SET_WEBCHAT_HEARTBEAT,
          setInterval(() => {
            dispatch(ACTIONS.SEND_WEBCHAT_SIGNALR_MESSAGE, "KeepAlive");
          }, 30000)
        );
      }
    }
  },

  [ACTIONS.UPDATE_WEBCHAT_FILTERS]({ commit }, filters) {
    const allFilterKeys = Object.keys(WEBCHAT_FILTERS);

    const newFilters = allFilterKeys.reduce((acc, cur) => {
      if (filters.includes(WEBCHAT_FILTERS[cur])) {
        return {
          ...acc,
          [WEBCHAT_FILTERS[cur]]: true,
        };
      } else {
        return {
          ...acc,
          [WEBCHAT_FILTERS[cur]]: false,
        };
      }
    }, {});

    commit(MUTATIONS.SET_WEBCHAT_FILTERS, newFilters);
  },

  async [ACTIONS.START_SIGNALR_WEBCHAT_CONNECTION]({
    state,
    commit,
    dispatch,
  }) {
    if (
      !state.signalRConnection ||
      _.get(state.signalRConnection, "connectionState") !== "Connected"
    ) {
      // If we don't have the bot, we don't have nothin'
      await dispatch(ACTIONS.GET_WEBCHAT_BOT);

      const webChatSignalRConnection = signalR.connectToHub("hitlbotws");

      await webChatSignalRConnection.start();

      let groupName = `ChatBot_${state.bot.id}`;

      webChatSignalRConnection.send("joingroup", groupName);
      commit(
        MUTATIONS.SET_SIGNALR_WEBCHAT_CONNECTION,
        webChatSignalRConnection
      );

      webChatSignalRConnection.on("HitlNotification", (notification) =>
        dispatch(ACTIONS.HANDLE_WEBCHAT_NOTIFICATION, notification)
      );

      if (!state.signalRConnectionRetry) {
        commit(
          MUTATIONS.SET_SIGNALR_CONNECTION_RETRY,
          setInterval(async () => {
            if (
              _.get(state.signalRConnection, "connectionState") === "Connected"
            )
              return;

            // prevent duplicate callbacks
            webChatSignalRConnection.off("HitlNotification");

            await dispatch(ACTIONS.START_SIGNALR_WEBCHAT_CONNECTION);

            if (window.localStorage.cadenceUserAvailability === "true") {
              await dispatch(
                ACTIONS.SEND_WEBCHAT_SIGNALR_MESSAGE,
                "MarkUserAvailable"
              );
              commit(MUTATIONS.SET_AVAILABLE_TO_CHAT, true);

              await dispatch(ACTIONS.GET_WEBCHAT_CONVERSATIONS);
            }
          }, 5000)
        );
      }
    }
  },

  async [ACTIONS.STOP_SIGNALR_WEBCHAT_CONNECTION]({ state, commit }) {
    if (
      !state.signalRConnection ||
      _.get(state.signalRConnection, "connectionState") !== "Connected"
    )
      return;
    if (state.signalRConnectionRetry) {
      clearInterval(state.signalRConnectionRetry);
    }
    await state.signalRConnection.stop();
    commit(MUTATIONS.SET_SIGNALR_WEBCHAT_CONNECTION, null);
  },

  [ACTIONS.UPDATE_SELECTED_WEBCHAT_CONVERSATION_MESSAGE](
    { state, getters, commit, dispatch },
    notification
  ) {
    let change = notification.data;
    if (change.payload.text) {
      const message = transformSignalRWebChatMessage(change);
      if (
        // Message is for currently selected conversation
        state.selectedConversation &&
        change.threadId === state.selectedConversation.conversationId
      ) {
        // Update messages in conversation view
        const updatedMessages = insertWebChatMessage(
          state.selectedConversationMessages,
          message
        );
        commit(MUTATIONS.SET_SELECTED_WEBCHAT_MESSAGES, updatedMessages);
      }

      let keyValues = {};
      const conversationToUpdate = getters[
        GETTERS.GET_WEBCHAT_CONVERSATION_BY_ID
      ](change.threadId);

      // Only update conversation preview if it's not an internal log
      if (!isMessageInternalLog(message)) {
        keyValues = { ...keyValues, lastMessage: message };
      }

      // Only re-highlight the conversation if they get a new message from
      // the visitor or a new note from a colleague
      if (
        !isMessageOutgoing(message) &&
        !isMessageInternalLog(message) &&
        isMyConversation(conversationToUpdate)
      ) {
        state.incomingMessageSound.play();
        keyValues = { ...keyValues, hasNewIncomingMessages: true };
      }

      // Update last message in conversation list item
      dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION, {
        conversationId: change.threadId,
        keyValues,
      });
    }
  },

  [ACTIONS.HANDLE_WEBCHAT_NOTIFICATION]({ getters, dispatch }, notification) {
    switch (notification.type) {
      case WEBCHAT_NOTIFICATION_TYPES.CONVERSATION_CREATED:
        dispatch(ACTIONS.ADD_WEBCHAT_CONVERSATION_TO_LIST_BY_ID, {
          conversationId: notification.conversationId,
          setSelected: false,
        });
        break;
      case WEBCHAT_NOTIFICATION_TYPES.CONVERSATION_DELETED:
        dispatch(ACTIONS.DELETE_WEBCHAT_CONVERSATION_IN_LIST_BY_ID, {
          conversationId: notification.conversationId,
        });
        break;
      case WEBCHAT_NOTIFICATION_TYPES.CONVERSATION_ENDED:
      case WEBCHAT_NOTIFICATION_TYPES.CONVERSATION_TIMEOUT:
        dispatch(
          ACTIONS.CLOSE_WEBCHAT_CONVERSATION,
          notification.conversationId
        );
        break;
      case WEBCHAT_NOTIFICATION_TYPES.MANUAL_ASSIGNMENT:
        dispatch(ACTIONS.ASSIGN_WEBCHAT_CONVERSATION, {
          conversationId: notification.conversationId,
          attributes: notification.data,
          isManuallyAssigned: true,
        });
        break;
      case WEBCHAT_NOTIFICATION_TYPES.CONVERSATION_TRANSFERRED:
      case WEBCHAT_NOTIFICATION_TYPES.CHAT_REQUEST:
        dispatch(ACTIONS.ASSIGN_WEBCHAT_CONVERSATION, {
          conversationId: notification.conversationId,
          attributes: notification.data,
        });
        break;
      case WEBCHAT_NOTIFICATION_TYPES.CONVERSATION_READ:
        dispatch(ACTIONS.MARK_WEBCHAT_CONVERSATION_READ, {
          conversationId: notification.conversationId,
          post: false,
        });
        break;
      case WEBCHAT_NOTIFICATION_TYPES.CONVERSATION_UNASSIGNED:
        dispatch(
          ACTIONS.UNASSIGN_WEBCHAT_CONVERSATION,
          notification.conversationId
        );
        break;
      case WEBCHAT_NOTIFICATION_TYPES.MESSAGE_RECEIVED:
      case WEBCHAT_NOTIFICATION_TYPES.MESSAGE_SENT:
        dispatch(
          ACTIONS.UPDATE_SELECTED_WEBCHAT_CONVERSATION_MESSAGE,
          notification
        );
        break;
      case WEBCHAT_NOTIFICATION_TYPES.VISITOR_DATA_CAPTURED:
        dispatch(
          ACTIONS.UPDATE_WEBCHAT_CONVERSATION_VISITOR_DETAILS,
          notification
        );
        break;
      case WEBCHAT_NOTIFICATION_TYPES.CONVERSATION_REOPENED:
        dispatch(
          ACTIONS.REOPEN_WEBCHAT_CONVERSATION,
          notification.conversationId
        );
        break;
      case WEBCHAT_NOTIFICATION_TYPES.CONVERSATION_NEEDS_FOLLOWUP:
        dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION_FOLLOWUP, {
          conversationId: notification.conversationId,
          followUp: true,
          assignedStaffId: notification.data.assignedStaffId,
        });
        break;
      case WEBCHAT_NOTIFICATION_TYPES.CONVERSATION_FOLLOWUP_COMPLETE:
        dispatch(ACTIONS.UPDATE_WEBCHAT_CONVERSATION_FOLLOWUP, {
          conversationId: notification.conversationId,
          followUp: false,
          assignedStaffId: null,
        });
        break;
      case WEBCHAT_NOTIFICATION_TYPES.VISITOR_TYPING:
        if (
          getters[GETTERS.IS_SELECTED_WEBCHAT_CONVERSATION](
            notification.conversationId
          )
        ) {
          dispatch(ACTIONS.PULSE_WEBCHAT_TYPING_INDICATOR, "Visitor");
        }
        break;
      case WEBCHAT_NOTIFICATION_TYPES.AGENT_TYPING: {
        const profile = getProfile();

        if (
          getters[GETTERS.IS_SELECTED_WEBCHAT_CONVERSATION](
            notification.conversationId
          ) &&
          notification.data.agentName !== profile.displayName
        ) {
          dispatch(
            ACTIONS.PULSE_WEBCHAT_TYPING_INDICATOR,
            notification.data.agentName
          );
        }
        break;
      }
      default:
      //console.error(`Unknown Notification: ${notification.type}`);
    }
  },

  [ACTIONS.UPDATE_WEBCHAT_PAGINATION_META](
    { commit },
    { followUpTotal, otherTotal }
  ) {
    commit(MUTATIONS.SET_FOLLOW_UPS_TOTALS, followUpTotal);
    commit(MUTATIONS.SET_OTHER_CONVERSATIONS_TOTALS, otherTotal);
  },

  [ACTIONS.PULSE_WEBCHAT_TYPING_INDICATOR](
    { state, commit, dispatch, getters },
    name
  ) {
    const typerName = _.trim(name) || "Another agent";

    // Add or extend typer. Visitors are special and go to the front of the line :)
    if (typerName === "Visitor" && !hashGet(state.typers, "Visitor")) {
      commit(
        MUTATIONS.SET_WEBCHAT_TYPERS,
        arrayToHashMap(
          [{ name: "Visitor", extend: true }, ...hashValues(state.typers)],
          "name"
        )
      );
    } else {
      commit(
        MUTATIONS.SET_WEBCHAT_TYPERS,
        hashMerge(hashCloneDeep(state.typers), typerName, {
          name: typerName,
          extend: true,
        })
      );
    }

    // Update text
    commit(
      MUTATIONS.SET_WEBCHAT_TYPING_TEXT,
      getters[GETTERS.GET_WEBCHAT_TYPING_TEXT]
    );

    if (!state.incomingTypingTimer) {
      // Start the timer
      commit(
        MUTATIONS.SET_WEBCHAT_INCOMING_TYPING_TIMER,
        setInterval(() => {
          if (!hashValues(state.typers).find((typer) => typer.extend)) {
            // Nobody typed. Time to die, Mr. Timer
            dispatch(ACTIONS.CLEAR_WEBCHAT_INCOMING_TYPING_TIMER);
          }
          // Scrape out everyone who didn't type last round.
          // People who made it to this round lose their hall pass.
          commit(
            MUTATIONS.SET_WEBCHAT_TYPERS,
            arrayToHashMap(
              hashValues(state.typers)
                .filter((typer) => typer.extend)
                .map((typer) => {
                  return { name: typer.name, extend: false };
                }),
              "name"
            )
          );
          // update text
          commit(
            MUTATIONS.SET_WEBCHAT_TYPING_TEXT,
            getters[GETTERS.GET_WEBCHAT_TYPING_TEXT]
          );
        }, 3000)
      );
    }
  },

  [ACTIONS.CLEAR_WEBCHAT_INCOMING_TYPING_TIMER]({ state, commit }) {
    clearInterval(state.incomingTypingTimer);
    commit(MUTATIONS.SET_WEBCHAT_INCOMING_TYPING_TIMER, null);
  },

  [ACTIONS.CLEAR_WEBCHAT_OUTGOING_TYPING_TIMER]({ state, commit }) {
    clearInterval(state.outgoingTypingTimer);
    commit(MUTATIONS.SET_WEBCHAT_OUTGOING_TYPING_TIMER, null);
  },

  [ACTIONS.CLEAR_WEBCHAT_OUTGOING_TYPING_TIMER]({ state, commit }) {
    clearInterval(state.outgoingTypingTimer);
    commit(MUTATIONS.SET_WEBCHAT_OUTGOING_TYPING_TIMER, null);
  },

  async [ACTIONS.GET_WEBCHAT_TEMPLATES]({ commit }) {
    const templateList = await api.get("templates/all", {
      params: {
        visibility: "Chat",
      },
    });

    commit(MUTATIONS.SET_WEBCHAT_TEMPLATES, templateList);
  },
};
