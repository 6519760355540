<template>
  <div
    class="message-meta d-flex align-items-center"
    :class="{
      'flex-row-reverse': isOutgoing,
    }"
  >
    <div class="message-meta-source">
      <span class="sender">
        <span v-show="isOutgoing && sender">
          <span v-html="sender"></span>
          <span> &bull; </span>
        </span>
        <span v-show="isIncoming">
          <strong>{{ contactFirstName }}</strong>
          <span> &bull; </span>
        </span>
      </span>

      <span v-if="!item.pending">{{ item.date | dateTimeShorthand() }}</span>
      <span v-else>Sending...</span>

      <span v-if="item.error || item.cancelled">
        <span> &bull; </span>
        <span
          class="message-error"
          v-b-tooltip.hover.left="{
            title: item.error || 'Cancelled prior to delivery',
          }"
        >
          <MgIcon name="exclamation-circle" scale=".8125"></MgIcon
          >&nbsp;Not&nbsp;delivered
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import MgIcon from "@/components/MgIcon";
import { VBTooltip } from "bootstrap-vue";
import {
  GETTERS,
  INBOX_TYPES,
  MESSAGE_DIRECTIONS,
  MESSAGE_SOURCES,
  MESSAGE_TYPES,
} from "@/util/constants";

Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "MessageMeta",
  store,
  components: {
    MgIcon,
  },
  data() {
    return {
      isIncoming:
        this.item.direction.toLowerCase() === MESSAGE_DIRECTIONS.INCOMING,
      isOutgoing:
        this.item.direction.toLowerCase() === MESSAGE_DIRECTIONS.OUTGOING,
    };
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    messageHover: {
      type: Boolean,
    },
  },
  computed: {
    contactFirstName() {
      return this.$store.getters[GETTERS.CONTACT_FIRST_NAME];
    },
    sender() {
      let source = "";
      let inboxType = this.$store.state.inbox.activeInbox.type;

      if (this.item.messageType === MESSAGE_TYPES.KEYWORD_AUTO_REPLY) {
        source = `Auto Reply`;
      } else {
        if (inboxType === INBOX_TYPES.PERSONAL && this.item.automated) {
          source = `Out of Office`;
        } else if (inboxType === INBOX_TYPES.GROUP && this.item.automated) {
          source = `Auto Reply from <strong>${this.getSenderName()}</strong>`;
        } else if (
          this.item.source === MESSAGE_SOURCES.SMART_MESSAGE_AUTO_REPLY
        ) {
          source = `Auto Reply from <strong>${this.getSenderName()}</strong>`;
        } else if (
          inboxType !== INBOX_TYPES.PERSONAL ||
          (inboxType === INBOX_TYPES.PERSONAL &&
            this.item.senderId !== this.$store.state.config.userId)
        ) {
          source = `<strong>${this.getSenderName()}</strong>`;
        }
      }

      return source;
    },
  },
  methods: {
    getSenderName() {
      let name = "Unknown";
      if (this.item.senderId) {
        let user = this.$store.state.users.find(
          (user) => user.id === this.item.senderId
        );
        if (user) {
          name = user.displayName;
        }
      } else if (this.item.sender) {
        name = this.item.sender;
      } else if (this.item.staff) {
        name = this.item.staff;
      }
      return name;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";

.campaign-picker {
  position: absolute;
  right: -10px;
  top: 0;

  .outgoing & {
    left: -10px;
  }
}
</style>
