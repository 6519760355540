<template>
  <div v-if="isMultiTeam" v-click-outside="handleClickOutside">
    <MgDropdown
      :options="teams_"
      :class="{ 'assignment-active': assignmentActive }"
      keyPrefix="team-"
      keyField="teamId"
      descriptionField="description"
      descriptionIconField="descriptionIcon"
      descriptionIconColorField="descriptionIconColor"
      toggleIconField="toggleIcon"
      labelField="teamName"
      toggleButtonIconOnly
      showNextStepChevron
      hideSelected
      @click="handleTeamDropdownClick"
      :interceptToggleButtonClick="handleToggleButtonClick"
      v-b-tooltip.hover="{
        title: 'Assign',
        delay: { show: 500, hide: 50 },
      }"
      title="Assign"
      alt="Assign"
    />
    <MgFade
      id="user-dropdown-fade"
      key="user-dropdown-fade"
      v-if="showUserDropdown_"
      :appear="true"
      :fade="true"
      :transitionEnterDuration="200"
    >
      <WebChatMultiTeamUserDropdown
        :conversationId="conversationId"
        :assignedStaffId="assignedStaffId"
        :team="selectedTeam_"
        @optionSelected="handleMultiTeamUserDropdownClick"
      />
    </MgFade>
    <MgFade
      id="assignment-fade"
      key="assignment-fade"
      v-if="showAssignmentForm_"
      :appear="true"
      :fade="true"
      :transitionEnterDuration="200"
    >
      <WebChatAssignmentForm
        :conversationId="conversationId"
        :team="selectedTeam_"
        :selectedUser="selectedUser_"
        @cancelled="cancelAssignmentClick"
        @confirmed="handleAssignmentClick"
        :isFollowUp="isFollowUp"
      />
    </MgFade>
  </div>
  <div v-else-if="isSingleTeam" v-click-outside="handleClickOutside">
    <MgDropdown
      :options="singleTeamUsers_"
      :class="{ 'assignment-active': assignmentActive }"
      keyPrefix="single-team-user-"
      keyField="id"
      iconField="icon"
      iconColorField="iconColor"
      toggleIconField="toggleIcon"
      labelField="name"
      toggleButtonIconOnly
      showNextStepChevron
      hideSelected
      @click="handleSingleTeamUserDropdownClick"
      :interceptToggleButtonClick="handleToggleButtonClick"
      v-b-tooltip.hover="{
        title: 'Assign',
        delay: { show: 500, hide: 50 },
      }"
      title="Assign"
      alt="Assign"
    />
    <MgFade
      id="assignment-fade"
      v-if="showAssignmentForm_"
      :appear="true"
      :fade="true"
      :transitionEnterDuration="200"
    >
      <WebChatAssignmentForm
        :conversationId="conversationId"
        :team="selectedTeam_"
        :selectedUser="selectedUser_"
        @cancelled="cancelAssignmentClick"
        @confirmed="handleAssignmentClick"
        :isFollowUp="isFollowUp"
    /></MgFade>
  </div>
</template>
<script>
import MgDropdown from "@/components/MgDropdown";
import WebChatMultiTeamUserDropdown from "@/components/WebChatMultiTeamUserDropdown";
import WebChatAssignmentForm from "@/components/WebChatAssignmentForm";
import MgFade from "@/components/MgFade";
import api from "@/util/api";
import getProfile from "@/util/profile";
import eventBus from "@/util/eventBus";
import store from "@/store";
import _ from "lodash";
import Vue from "vue";

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

export default {
  name: "WebChatAssignmentDropdown",
  store,
  components: {
    MgDropdown,
    WebChatMultiTeamUserDropdown,
    WebChatAssignmentForm,
    MgFade,
  },
  props: {
    botId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: String,
      required: true,
    },
    isFollowUp: {
      type: Boolean,
      required: true,
    },
    assignedStaffId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      // this is a little jank but MgDropdown doesn't like being born without any options
      teams_: [
        {
          teamId: 0,
          teamName: "",
          description: "",
          descriptionIcon: "circle",
          descriptionIconColor: "#CED4DA",
          toggleIcon: "user-plus",
          agents: [],
        },
      ],
      singleTeamUsers_: [
        {
          name: "",
          id: "",
          online: false,
          icon: "circle",
          toggleIcon: "user-plus",
          iconColor: "#CED4DA",
        },
      ],
      selectedTeam_: {},
      showUserDropdown_: false,
      showAssignmentForm_: false,
      selectedUser_: {
        name: "",
        id: null,
      },
      profile: {},
      isAssigning: false,
      cancelNextClickOutsideEvent_: false,
    };
  },
  created() {
    this.profile = getProfile();
    this.refreshTeamData();
  },
  computed: {
    isMultiTeam() {
      return _.get(this.$store.state.webChat.bot, "isMultiTeam", false);
    },
    isSingleTeam() {
      return !_.get(this.$store.state.webChat.bot, "isMultiTeam", false);
    },
    assignmentActive() {
      return this.showUserDropdown_ || this.showAssignmentForm_;
    },
  },
  methods: {
    handleSingleTeamUserDropdownClick(data) {
      this.selectedUser_ = data.selectedOption;
      this.showAssignmentForm_ = true;
    },

    handleMultiTeamUserDropdownClick(data) {
      this.showUserDropdown_ = false;
      this.selectedUser_ = data;
      this.cancelNextClickOutsideEvent_ = true;
      this.showAssignmentForm_ = true;
    },

    handleTeamDropdownClick(data) {
      this.selectedTeam_ = data.selectedOption;
      this.showUserDropdown_ = true;
    },

    handleClickOutside() {
      if (!this.cancelNextClickOutsideEvent_) {
        this.showUserDropdown_ = false;
        this.showAssignmentForm_ = false;
      } else {
        this.cancelNextClickOutsideEvent_ = false;
      }
    },

    handleAssignmentClick(assign) {
      eventBus.dispatchEvent("conversation-transferred", assign);
      this.showAssignmentForm_ = false;
    },

    cancelAssignmentClick() {
      this.showAssignmentForm_ = false;
    },

    async handleToggleButtonClick() {
      this.showUserDropdown_ = false;
      this.showAssignmentForm_ = false;
      await this.refreshTeamData();
    },

    canUserBeAssigned(user) {
      // can't assign to myself on the same team
      return user.id !== this.assignedStaffId;
    },

    async refreshTeamData() {
      const endpoint = `${this.botId}/hitl/availability`;
      const headers = {
        "x-disable-loading": true,
      };
      const response = await api.get(endpoint, { headers });

      const myTeam = response.filter(
        (team) => team.teamId === this.profile.currentDepartment.id
      );
      const currentDepartmentId = this.profile.currentDepartment.id;
      const currentUserId = this.profile.id;

      const divider = {
        name: "dropdown-divider",
        type: "divider",
        id: null,
      };

      // SINGLE TEAM USER DATA
      if (this.isSingleTeam) {
        this.selectedTeam_ = myTeam[0];
        let updatedUsers = [];

        this.selectedTeam_.agents.forEach((user) => {
          if (user.id === currentUserId) {
            updatedUsers.unshift({
              ...user,
              name: "Me",
              icon: "circle",
              iconColor: user.online ? "#7ED321" : "#CED4DA",
              toggleIcon: "user-plus",
              disabled: !this.canUserBeAssigned(user),
              preDivider: true,
            });
          } else {
            updatedUsers.push({
              ...user,
              icon: "circle",
              toggleIcon: "user-plus",
              iconColor: user.online ? "#7ED321" : "#CED4DA",
              disabled: !this.canUserBeAssigned(user),
              preDivider: user.online,
            });
          }
        });

        const lastPreDividerIndex = updatedUsers.findLastIndex(
          (user) => user.preDivider
        );

        // only add a divider if it's not the last item in the list
        if (lastPreDividerIndex !== updatedUsers.length - 1) {
          updatedUsers.splice(lastPreDividerIndex + 1, 0, divider);
        }

        this.singleTeamUsers_ = updatedUsers;

        // TEAM DATA
      } else if (this.isMultiTeam) {
        let updatedTeams = [];

        response.forEach((team) => {
          let newProps = {
            toggleIcon: "user-plus",
            descriptionIcon: "circle",
            description: `${team.onlineUsers} users available`,
            descriptionIconColor: team.onlineUsers > 0 ? "#7ED321" : "#CED4DA",
          };
          if (team.teamId === currentDepartmentId) {
            updatedTeams.unshift({
              ...team,
              ...newProps,
              isMyTeam: true,
              preDivider: true,
            });
          } else {
            updatedTeams.push({
              ...team,
              ...newProps,
              isMyTeam: false,
              preDivider: false,
            });
          }
        });

        const lastPreDividerIndex = updatedTeams.findLastIndex(
          (team) => team.preDivider
        );
        updatedTeams.splice(lastPreDividerIndex + 1, 0, divider);

        this.teams_ = updatedTeams;

        // TEAM AVAILABILITY DATA
      } else {
        this.teams_ = myTeam.map((team) => {
          return {
            ...team,
            toggleIcon: "comment-arrow-right",
            description: `${team.onlineUsers} users available`,
            descriptionIcon: "circle",
            descriptionIconColor: team.onlineUsers > 0 ? "#7ED321" : "#CED4DA",
          };
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/variables";
@import "../../scss/utilities";

::v-deep #assignment-fade {
  position: absolute;
  z-index: 1201;
  transform: translateX(-346px);

  @include media-breakpoint-down(md) {
    width: 100%;
    left: 346px;
  }

  .k-animation-container {
    width: 380px;
    box-shadow: 0px 1px 2px 0px #0000001a;
    max-height: 287px;
    border-radius: 4px;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}

::v-deep #user-dropdown-fade {
  position: absolute;
  z-index: 1201;
  transform: translateX(-187px);
  .k-animation-container {
    max-height: 287px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
  }
}

::v-deep .dropdown-menu {
  width: 221px;
  top: -13px !important;
  left: 3px !important;
  // left: 4px !important;

  .dropdown-item {
    white-space: initial;

    svg {
      height: 10px;
      margin-bottom: 1px;
    }
  }

  .dropdown-option-description {
    font-size: $font-size-sm;
    color: $text-muted;
  }

  .dropdown-option-label {
    font-size: $font-size-sm;
    color: $gray;
  }
}

::v-deep #single-team-user-dropdown {
  .dropdown-item {
    border-bottom: none;
    .label-description-container {
      align-self: end;
    }
  }

  .dropdown-option-label {
    font-weight: 400;
    font-size: 14px;
    color: #212529;
    padding-left: 1px;
  }

  .dropdown-menu-icon {
    margin-right: 3px !important;
  }
}

::v-deep #team-dropdown,
::v-deep #single-team-user-dropdown {
  margin-bottom: 1px;
  padding: 8px 11px 0px;

  &.show,
  &.assignment-active {
    background-color: #e9ecef;
    border-radius: 3px;
  }

  & ul.dropdown-menu.dropdown-menu-right {
    transform: translate3d(-1px, 15px, 0px);
  }

  .dropdown-toggle-no-caret {
    opacity: 0.7;
    padding: 0 !important;
    border: none;
    transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
    color: inherit;
    transform: scale(2);

    .dropdown-toggle-icon {
      margin: 0 !important;
    }

    &:hover {
      opacity: 1;
      transform: scale(2.3);
    }
  }
}

::v-deep .dropdown-toggle::after {
  margin-left: 6px;
  margin-right: 0.75rem;
  top: 2px;
}

::v-deep #web-chat-status-dropdown__BV_toggle_:focus-visible {
  outline: 2px auto #006ba8;
}
</style>
