<template>
  <div v-if="showContactCard">
    <div class="contact-card-overlay" @click="toggleContactCard()"></div>
    <div v-if="contact" class="contact-card h-100 d-flex flex-column">
      <div
        class="contact-card-header d-flex align-items-center justify-content-between p-3"
      >
        <h2 class="h4 m-0 font-weight-normal">Details</h2>
        <a
          @click.prevent="toggleContactCard()"
          href
          aria-label="Close contact card"
          role="button"
          class="btn-icon close"
        >
          <MgIcon name="times" scale="1.1"></MgIcon>
        </a>
      </div>
      <div
        class="contact-card-body d-flex flex-column justify-content-start flex-fill"
      >
        <div>
          <ContactOptOut :contact="contact"></ContactOptOut>
          <hr class="mb-0" />
        </div>
        <div class="main-details my-3 px-3">
          <div>
            <strong>
              {{ contactDisplayName }}
            </strong>
          </div>
          <div>
            <strong>
              <a :href="'tel:+' + contact.mobileNumber">
                {{ contact.mobileNumberFormatted }}
              </a>
            </strong>
          </div>
          <div v-if="contact.campusUniqueId">
            ID:&nbsp;
            <strong class="text-break-word">
              {{ contact.campusUniqueId }}
            </strong>
          </div>
          <div>
            Assigned User:
            <strong>{{ getAssignedStaff }}</strong>
          </div>
        </div>
        <div class="activity-fields d-flex px-3 py-2">
          <div class="flex-fill">
            <div class="activity-label">Last Modified</div>
            <div>
              {{ contact.lastUpdated | utcToLocal("M/D/YY[,] h:mm A") }}
            </div>
          </div>
          <div class="flex-fill">
            <div class="activity-label">Created</div>
            <div>
              {{ contact.dateCreated | utcToLocal("M/D/YY[,] h:mm A") }}
            </div>
          </div>
        </div>
        <div class="my-2">
          <ContactFields
            class="px-3"
            :fields="contactAdditionalFields"
          ></ContactFields>
        </div>
      </div>

      <div class="profile-link">
        <a
          class="d-flex p-3"
          @click="trackMessageHistory()"
          :href="'#/students/' + this.contact.id"
          ><MgIcon class="d-flex mr-2" name="comments" scale="1.25"></MgIcon
          ><span>View Message History</span></a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import "@/util/filters";
import { ACTIONS, GETTERS, HELP } from "@/util/constants";
import _ from "lodash";
import { VBTooltip } from "bootstrap-vue";
import MgIcon from "./MgIcon";
import ContactFields from "./ContactFields";
import ContactOptOut from "./ContactOptOut";
import analytics from "@/util/analytics";

Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "ContactCard",
  store,
  components: {
    MgIcon,
    ContactFields,
    ContactOptOut,
  },
  data() {
    return {
      blockDepartmentId: null,
      help: HELP,
    };
  },
  computed: {
    contact() {
      return this.$store.state.contact.details;
    },
    conversation() {
      return this.$store.state.inbox.selectedConversation;
    },
    contactAdditionalFields() {
      let obj = this.$store.state.contact.details.additionalFields;
      for (var key in obj) {
        if (!obj[key]) delete obj[key];
      }

      return obj;
    },
    contactDisplayName() {
      return this.$store.getters[GETTERS.CONTACT_DISPLAY_NAME];
    },
    currentDepartment() {
      return this.$store.getters[GETTERS.GET_CURRENT_DEPARTMENT];
    },
    getAssignedStaff() {
      const staff = !_.isUndefined(this.currentDepartment)
        ? this.currentDepartment.staff
        : "";
      return !_.isEmpty(staff)
        ? staff.firstName + " " + staff.lastName
        : "None";
    },
    selectedConversationId() {
      return this.$store.state.inbox.selectedConversationId;
    },
    showContactCard() {
      return !!(
        this.$store.state.showContactCard &&
        this.$store.state.inbox.selectedConversationId
      );
    },
    userId() {
      return this.$store.state.config.userId;
    },
    lockedByOtherUser() {
      if (!(this.conversation && this.conversation.state)) {
        return false;
      }
      return this.conversation.state.locked &&
        this.conversation.state.lockedForStaffId !== this.userId
        ? true
        : false;
    },
  },
  methods: {
    toggleContactCard() {
      this.$store.dispatch(
        ACTIONS.SHOW_CONTACT_CARD,
        !this.$store.state.showContactCard
      );
    },
    trackMessageHistory() {
      analytics.track("Clicked View Contact from Conversation");
    },
  },
  watch: {
    selectedConversationId() {
      const body = document.getElementsByClassName("contact-card-body")[0];
      if (body) {
        body.scrollTop = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/utilities";

.contact-card {
  background: white;
  border-left: 1px solid $gray-lighter;
  max-width: 400px;
  min-width: 310px;
  right: 0;
  z-index: 5;

  @media (max-width: $screen-md) {
    position: fixed;
    top: $navbar-height;
  }
}

.contact-card-overlay {
  background-color: rgba(33, 33, 33, 1);
  position: fixed;
  height: 100%;
  opacity: 0.48;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;

  @media (min-width: $screen-md) {
    display: none;
  }
}

.contact-card-body {
  overflow: auto;

  @media (max-width: $screen-md) {
    padding-bottom: $navbar-height;
  }
}

::v-deep .btn-outline-dark:not(:disabled) {
  color: $nav-background;
  background-color: white;
  border-color: $gray-lighter;
  &:hover,
  &:active {
    background-color: $gray-lighter;
  }
}

::v-deep .btn-outline-dark:not(:disabled):not(.disabled):active,
::v-deep .show > .btn-outline-dark.dropdown-toggle {
  border-color: $gray-lighter;
  background-color: $gray-lighter;
  color: $nav-background;
}

::v-deep .dropdown-menu {
  flex-direction: column;
  align-items: flex-start;
  min-width: 140px;
  .dept-name {
    font-size: $font-size-sm;
    padding: 15px 15px 10px 15px;
    color: $gray-light;
  }
  .custom-switch {
    margin: 0 15px 10px 15px;
  }
  .custom-control-label {
    color: $gray-light;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
  }
}

.contact-card-header {
  background: $gray-light-bg;
  min-height: 74px;

  .btn-icon {
    margin: 0 2px;
    padding: 4px 8px;
  }
}

.main-details {
  padding: 0 15px;
}

.main-details {
  div {
    line-height: 1.5;
  }
}

.activity-fields {
  background: $light;
}

.activity-label {
  color: $gray-700;
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
}

.profile-link {
  background: $light;
  border-top: 1px solid $light;

  &:hover {
    a {
      text-decoration: none;
    }
    span {
      text-decoration: underline;
    }
  }
}
</style>
