<template>
  <div>
    <ContactFields
      :fields="additionalFields"
      v-if="additionalFields"
    ></ContactFields>
    <h4 class="empty my-4" v-if="!additionalFields">No custom fields</h4>
  </div>
</template>

<script>
import store from "@/store";
import ContactFields from "../ContactFields";
import _ from "lodash";

export default {
  name: "ContactCustomFields",
  store,
  components: { ContactFields },
  data() {
    return {};
  },
  created() {},
  computed: {
    additionalFields() {
      return !_.isEmpty(this.$store.state.contact.details.additionalFields)
        ? this.$store.state.contact.details.additionalFields
        : false;
    },
  },
  methods: {
    getLinkOrValue(value) {
      const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const telRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

      if (urlRegex.exec(value)) {
        value =
          value.toLowerCase().indexOf("http") > -1
            ? `<a href="${value}" target="_blank">${value}</a>`
            : `<a href="//${value}" target="_blank">${value}</a>`;
      } else if (emailRegex.exec(value)) {
        value = `<a href="mailto:${value}">${value}</a>`;
      } else if (telRegex.exec(value)) {
        value = `<a href="tel:${value}">${value}</a>`;
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
.contact-fields {
  font-size: $font-size-sm;
}
.empty {
  color: $text-muted;
  text-align: center;
}
</style>
