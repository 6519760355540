<template>
  <table class="table table-striped table-borderless table-sm mb-0">
    <thead class="thead-light">
      <tr>
        <th scope="col">Status</th>
        <th scope="col">Date</th>
        <th scope="col">Type</th>
        <th scope="col">Description</th>
      </tr>
    </thead>
    <tbody class="font-weight-light">
      <tr v-for="item in items" :key="item.id" class="text-nowrap">
        <td>
          {{ parseTitle(item.title) }}
        </td>
        <td>{{ formatDate(item.occurredOn) }}</td>
        <td>{{ item.type }}</td>
        <td class="text-wrap">
          {{ parseMessage(item.message) }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { formatDate } from "@/util/dates";

export default {
  name: "IntegrationsExportTable",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      return formatDate(date, "M/D/YY[,] h:mm A");
    },
    parseTitle(title) {
      return title !== "" ? title : "Unknown";
    },
    parseMessage(message) {
      return message !== "" ? message : "No Description Provided.";
    },
  },
};
</script>
