<template>
  <MgDropdown
    :options="options"
    :selectedKeyValue="selectedKeyValue"
    :keyPrefix="keyPrefix"
    :keyField="keyField"
    :descriptionField="descriptionField"
    :iconField="iconField"
    :iconColorField="iconColorField"
    :labelField="labelField"
    :headerText="headerText"
    @click="handleClick"
  />
</template>
<script>
import MgDropdown from "@/components/MgDropdown";

export default {
  name: "FilterDropdown",
  components: { MgDropdown },
  props: {
    options: {
      type: Array,
      required: true,
    },
    keyPrefix: {
      type: String,
      required: true,
    },
    keyField: {
      type: String,
      required: true,
    },
    labelField: {
      type: String,
      required: true,
    },
    descriptionField: {
      type: String,
      required: false,
      default: "",
    },
    iconField: {
      type: String,
      required: false,
      default: "",
    },
    iconColorField: {
      type: String,
      required: false,
      default: "",
    },
    headerText: {
      type: String,
      required: false,
      default: "",
    },
    selectedKeyValue: {
      required: false,
      default: "",
    },
  },
  methods: {
    handleClick(data) {
      this.$emit("click", { selectedOption: data.selectedOption });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/variables";

::v-deep .dropdown-toggle {
  color: $gray;
  text-transform: none;
  text-decoration: none;
  letter-spacing: inherit;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 4px;
  margin-left: 15px;
  margin-right: 0;
  margin-bottom: 2px;
  border-radius: 0;
}

::v-deep .dropdown-toggle::after {
  margin-left: 18px;
  margin-right: 6px;
}

::v-deep .dropdown-toggle:focus {
  border-bottom-color: rgb(0, 107, 168);
  border-bottom-width: 2px;
  padding-bottom: 3px;
}
</style>
