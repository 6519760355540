<template>
  <div>
    <div class="toggle" @click.prevent="toggleCollapse">
      <slot name="toggle"></slot>
    </div>
    <BCollapse v-model="expanded_"><slot name="body"></slot></BCollapse>
  </div>
</template>
<script>
import { BCollapse } from "bootstrap-vue";

export default {
  name: "MgCollapse",
  components: { BCollapse },
  props: {
    expanded: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      expanded_: this.expanded,
    };
  },
  watch: {
    expanded(newVal) {
      if (this.expanded_ !== newVal) {
        this.expanded_ = newVal;
        this.$emit("change-collapse-state", { expanded: this.expanded_ });
      }
    },
  },
  methods: {
    toggleCollapse() {
      this.expanded_ = !this.expanded_;
      this.$emit("change-collapse-state", { expanded: this.expanded_ });
    },
  },
};
</script>
<style lang="scss" scoped>
.toggle {
  cursor: pointer;
}
</style>
