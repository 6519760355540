<template>
  <ResponsiveContainer :tag="null" :breakpoints="{ small: 576 }">
    <template slot-scope="container">
      <MgDataGrid
        :rowData="rowData"
        :columns="
          container.size >= container.breakpoints.small
            ? allColumns_
            : mobileColumns_
        "
        :skip="skip"
        :total="total"
        :sort="sort"
        paginationMode="SERVER"
        @data-state-change="handleDataStateChange"
      />
    </template>
  </ResponsiveContainer>
</template>
<script>
import ResponsiveContainer from "@/components/ResponsiveContainer";
import MgDataGrid from "@/components/MgDataGrid";
import MgLink from "@/components/MgLink";
import { formatDate } from "@/util/dates";
import { formatPhoneNumberWithoutIntlCode } from "@/util/phoneNumber";

export default {
  name: "TagDetailGrid",
  components: {
    ResponsiveContainer,
    MgDataGrid,
  },
  props: {
    rowData: {
      type: Array,
      required: true,
    },
    skip: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    sort: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      allColumns_: [
        {
          field: "contactId",
          title: "CONTACT ID",
          type: "text",
        },
        {
          field: "contactName",
          title: "CONTACT NAME",
          type: "text",
          cell: (h, tdElement, props) => {
            return (
              <td>
                <MgLink
                  globalEventName="view-tag-contact"
                  value={{ contactId: props.dataItem.contactId }}
                >
                  {props.dataItem.contactName}
                </MgLink>
              </td>
            );
          },
        },
        {
          field: "contactPhone",
          title: "MOBILE NUMBER",
          type: "text",
          cell: (h, tdElement, props) => {
            return (
              <td>
                {formatPhoneNumberWithoutIntlCode(props.dataItem.contactPhone)}
              </td>
            );
          },
        },
        {
          field: "message",
          title: "MESSAGE",
          type: "text",
        },
        {
          field: "setByName",
          title: "ADDED BY",
          type: "text",
        },
        {
          field: "setOn",
          title: "DATE ADDED",
          type: "date",
          cell: (h, tdElement, props) => {
            return <td>{formatDate(props.dataItem.setOn, "M/D/YYYY")}</td>;
          },
        },
      ],
      mobileColumns_: [
        {
          field: "contactName",
          title: "NAME",
          type: "text",
          width: "120px",
          cell: (h, tdElement, props) => {
            return (
              <td>
                <MgLink
                  globalEventName="view-tag-contact"
                  value={{ contactId: props.dataItem.contactId }}
                >
                  {props.dataItem.contactName}
                </MgLink>
              </td>
            );
          },
        },
        {
          field: "message",
          title: "MESSAGE",
          type: "text",
        },
      ],
    };
  },
  methods: {
    handleDataStateChange(data) {
      this.$emit("data-state-change", data);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .k-grid-header {
  border-radius: 0;
}
</style>
