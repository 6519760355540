<template>
  <BModal id="recruitImportConfigModal" ref="recruitImportConfigModal">
    <template #modal-header="{ close }">
      <h3 class="modal-title" v-if="isNew">Add Field</h3>
      <h3 class="modal-title" v-else>Edit Field</h3>
      <button
        class="close"
        @click="close()"
        aria-label="Close Recruit Import Configuration Modal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </template>
    <div class="recruit-modal-content">
      <div v-show="error.messages">
        <div class="alert alert-danger">
          <div class="d-flex flex-row">
            <div>
              <MgIcon name="exclamation-triangle" scale="1.25" class="mr-1" />
            </div>
            <div>
              <span class="font-weight-bolder">
                Please address the errors and update.
              </span>

              <ul class="mb-0 pl-3">
                <li v-for="error in error.messages" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <IntegrationsViewFieldSelect
          class="w-100"
          :field="currentField"
          :options="currentViewFields"
          :useRawValue="canShowRawValue(selectedField) ? useRawValue : false"
          :isError="isError('crmField')"
          @change="changeCurrentField"
          required
        />
        <span v-show="isError('crmField')" class="text-danger">
          Recruit Field is Required
        </span>
        <BFormRadioGroup
          v-model="useRawValue"
          class="mt-2"
          v-if="!optOut"
          :disabled="!canShowRawValue(selectedField)"
        >
          <BFormRadio :value="false">
            <span class="font-weight-normal">Display Value</span>
          </BFormRadio>
          <BFormRadio :value="true">
            <span class="font-weight-normal">Reference Value</span>
          </BFormRadio>
        </BFormRadioGroup>
        <div class="mt-2">
          <p>
            <MgIcon name="exclamation-circle" />
            Display value is most commonly used. Reference value is the
            unformatted data value.
          </p>
        </div>
      </div>

      <div class="mt-3" v-if="optOut">
        <label>Treat Empty Value As</label>
        <BFormRadioGroup v-model="emptyIsOptedIn" class="mt-2">
          <BFormRadio :value="true"
            ><span class="font-weight-normal">Opted-in</span></BFormRadio
          >
          <BFormRadio :value="false"
            ><span class="font-weight-normal">Opted-Out</span></BFormRadio
          >
        </BFormRadioGroup>
      </div>

      <div class="mt-3">
        <div>
          <label for="cadenceFieldInput">Cadence Field</label>
          <input
            id="cadenceFieldInput"
            class="form-control"
            :class="isError('customField') ? 'is-invalid' : ''"
            v-model="currentField.customField.displayName"
            :disabled="requiredFields"
            required
          />
          <span v-show="isError('customField')" class="text-danger">
            Cadence Field is Required
          </span>

          <div class="form-check form-check-inline w-100 mt-2">
            <BFormCheckbox
              class="mr-3"
              :disabled="requiredFields"
              v-model="currentField.customField.isRequired"
            >
              <span class="font-weight-normal">Required</span>
            </BFormCheckbox>
            <BFormCheckbox
              class="mr-3"
              v-model="currentField.customField.canMailMerge"
              :disabled="requiredFields"
            >
              <span class="font-weight-normal">Mergeable</span>
            </BFormCheckbox>
            <BFormCheckbox
              class="mr-3"
              :disabled="requiredFields"
              v-model="currentField.customField.isVisible"
            >
              <span class="font-weight-normal"> Visible to Users </span>
            </BFormCheckbox>
          </div>
          <div class="mt-2" v-if="requiredFields">
            <p class="m-0">
              <MgIcon name="exclamation-circle" />
              The options for
              <span v-if="currentField.customField.isRequired"
                >Required Fields</span
              >
              <span v-else>Optional Fields</span>
              are predefined and are displayed
              for reference.
            </p>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-between">
        <button
          class="btn btn-primary"
          @click="handleAddNewField()"
          type="submit"
          v-if="isNew"
        >
          Add
        </button>
        <button
          class="btn btn-primary"
          @click="updateRequiredField()"
          type="submit"
          v-else
        >
          Update
        </button>
        <button class="btn btn-light ml-1" @click="cancel">Cancel</button>
      </div>
    </template>
  </BModal>
</template>

<script>
import {
  BFormCheckbox,
  BModal,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import _ from "lodash";
import IntegrationsViewFieldSelect from "@/components/IntegrationsViewFieldSelect";
import MgIcon from "@/components/MgIcon";

export default {
  name: "RecruitImportFieldAddEditModal",
  components: {
    BModal,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,
    MgIcon,
    IntegrationsViewFieldSelect,
  },
  data() {
    return {
      selectedId: "",
      selectedField: {},
      currentField: {
        customField: {},
      },
      useRawValue: false,
      emptyIsOptedIn: false,
      formErrors: [],
    };
  },
  props: {
    field: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
    cadenceField: {
      type: String,
      required: true,
    },
    currentViewFields: {
      type: Array,
      required: true,
    },
    error: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    requiredFields: {
      type: Boolean,
      required: false,
      default: false,
    },
    optOut: {
      type: Boolean,
      required: false,
      default: false,
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  mounted() {
    this.$root.$on("bv::modal::show", () => {
      if (this.isNew) {
        this.useRawValue = false;
        this.currentField = {
          customField: { displayName: "", isVisible: true, isRequired: true },
          crmField: {},
        };
        this.selectedField = { id: 0 };
      } else {
        this.useRawValue = _.clone(this.field.useRawValue);
        this.currentField = _.cloneDeep(this.field);

        if (!this.currentField.customField) {
          this.currentField = {
            customField: { displayName: "" },
            ...this.currentField,
          };
        }

        this.selectedField = _.cloneDeep(this.field.crmField);
      }

      if (this.requiredFields) {
        this.currentField.customField = {
          displayName: this.cadenceField,
        };

        if (this.optOut) {
          this.emptyIsOptedIn = _.clone(this.field.emptyIsOptedIn);
          this.currentField.customField.isRequired = false;
          this.currentField.customField.canMailMerge = false;
          this.currentField.customField.isVisible = true;
        } else {
          this.currentField.customField.isRequired =
            this.currentField.customField.displayName !== "User Id";
          this.currentField.customField.canMailMerge =
            this.currentField.customField.displayName === "First Name" ||
            this.currentField.customField.displayName === "Last Name" ||
            this.currentField.customField.displayName === "Mobile Number";
          this.currentField.customField.isVisible = true;
        }
      }
    });

    this.$root.$on("bv::modal::hidden", () => {
      this.formErrors = [];
      this.selectedId = "";
      this.currentField = {
        customField: {},
      };
      this.useRawValue = false;
      this.emptyIsOptedIn = false;
    });
  },
  methods: {
    changeCurrentField(key) {
      this.selectedId = key;
      this.selectedField = _.find(this.currentViewFields, (o) => {
        return o.id === key;
      });

      if (!this.canShowRawValue(this.selectedField)) {
        this.useRawValue = false;
      }
    },
    canShowRawValue(field) {
      if (!field || field.id === "") {
        this.useRawValue = false;
        return false;
      }
      return !(
        field.attributeType === "String" ||
        field.attributeType === "Uniqueidentifier" ||
        field.attributeType === "Memo"
      );
    },
    isError(field) {
      return _.includes(this.formErrors, field);
    },
    validate() {
      this.formErrors = [];
      if (this.selectedField.id === 0) this.formErrors.push("crmField");
      if (
        !this.requiredFields &&
        this.currentField.customField.displayName === ""
      )
        this.formErrors.push("customField");

      return this.formErrors.length === 0;
    },
    updateRequiredField() {
      if (this.validate()) {
        if (this.selectedId !== "") {
          this.field.crmField = this.selectedField;
        }
        if (this.optOut) {
          this.field.emptyIsOptedIn = this.emptyIsOptedIn;
        }

        if (!this.requiredFields) {
          this.field.customField = this.currentField.customField;
        }

        this.field.useRawValue = this.useRawValue;

        this.$emit(
          "handleEditField",
          this.currentField.customField.displayName
        );

        this.$refs["recruitImportConfigModal"].hide();
      }
    },
    handleAddNewField() {
      if (this.validate()) {
        this.$emit("handleAddNewCustomField", {
          customField: this.currentField.customField,
          crmField: this.selectedField,
          useRawValue: this.useRawValue,
        });

        this.$refs["recruitImportConfigModal"].hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.recruit-modal-content {
  padding: 12px 0;
}
</style>
