<template>
  <div id="renxtIntegrationDashboardTab" class="d-flex flex-column h-100">
    <div class="panel-container">
      <div class="row panel-row d-flex">
        <div class="col-md-6 col-sm-12 card-imports d-flex">
          <BCard
            header="Imports"
            header-tag="h2"
            class="flex-fill"
            v-if="isImportConfigured"
          >
            <div
              class="d-flex align-items-center justify-content-center"
              v-if="imports.totalItems === 0"
            >
              <span class="empty-message-text text-muted"
                >No import history</span
              >
            </div>
            <div v-else>
              <h3 class="card-header-label pb-3">
                <MgIcon name="clock" /> Latest Imports
              </h3>
              <div class="d-flex">
                <div class="w-100">
                  <div class="list-group">
                    <a
                      href="/#/integrations/renxt/imports/"
                      class="list-group-item list-group-item-action renxt-import-row border-0 mb-2"
                      v-for="item in topImports"
                      :key="item.id"
                    >
                      <div class="d-flex justify-content-between w-100">
                        <span class="font-weight-normal">
                          <span class="text-large">{{
                            item.errorRecords
                          }}</span>
                          errors
                          <span
                            class="font-weight-light"
                            v-if="!item.status.match(/queued for syncing/i)"
                          >
                            - {{ item.status }}
                          </span>
                        </span>
                        <span>{{ formatDate(item.started) }}</span>
                      </div>
                      <div>
                        Records: {{ item.totalRecords }} total,
                        {{ item.successRecords }} successful
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </BCard>
          <EmptyState
            class="flex-fill"
            image="/images/icon_upload.svg"
            :imageWidth="74"
            imageAltText="Import Icon"
            headingText="Imports"
            description="Automate contact imports"
            v-else
          >
            <template #buttons>
              <a href="#/integrations/renxt/imports/settings">
                Configure Imports&nbsp;»</a
              >
            </template>
          </EmptyState>
        </div>

        <div class="col-md-6 col-sm-12 card-exports d-flex">
          <BCard
            header="Exports"
            header-tag="h2"
            class="flex-fill"
            v-if="isExportConfigured"
          >
            <div
              class="d-flex align-items-center justify-content-center"
              v-if="exports.totalItems === 0"
            >
              <span class="empty-message-text text-muted"
                >No export history</span
              >
            </div>
            <div v-else>
              <h3 class="card-header-label pb-3">
                <MgIcon name="clock" /> Last 24 Hours
              </h3>
              <div>
                <div
                  class="container rounded position-relative export-total-container"
                >
                  <a
                    href="/#/integrations/renxt/exports/"
                    class="stretched-link"
                  >
                    <span class="sr-only">Go to exports</span>
                  </a>
                  <div class="h4 mt-1 mb-2 font-weight-light">Messages</div>
                  <div class="row">
                    <div class="col card-sub-header mb-1">
                      <span class="export-number font-weight-normal">{{
                        exportSummary.messages.fail
                      }}</span>
                      <span class="export-text font-weight-normal">
                        errors</span
                      >
                    </div>
                    <div class="col card-sub-header">
                      <span class="export-number font-weight-normal">{{
                        exportSummary.messages.success
                      }}</span>
                      <span class="export-text font-weight-normal">
                        successful</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="container rounded position-relative export-total-container mt-2"
                >
                  <a
                    href="/#/integrations/renxt/exports/"
                    class="stretched-link"
                  >
                    <span class="sr-only">Go to exports</span>
                  </a>
                  <div class="h4 mt-1 mb-2 font-weight-light">Opt-outs</div>
                  <div class="row">
                    <div class="col card-sub-header mb-1">
                      <span class="export-number font-weight-normal">{{
                        exportSummary.optOuts.fail
                      }}</span>
                      <span class="export-text font-weight-normal">
                        errors</span
                      >
                    </div>
                    <div class="col card-sub-header">
                      <span class="export-number font-weight-normal">{{
                        exportSummary.optOuts.success
                      }}</span>
                      <span class="export-text font-weight-normal">
                        successful</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BCard>
          <EmptyState
            class="flex-fill"
            image="/images/icon_download.svg"
            :imageWidth="74"
            imageAltText="Export Icon"
            headingText="Exports"
            description="Send message activity & opt-out status changes in real-time"
            v-else
          >
            <template #buttons>
              <a href="/#/integrations/renxt/exports/settings">
                Configure Exports&nbsp;»
              </a>
            </template>
          </EmptyState>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
import EmptyState from "@/components/EmptyState";
import { formatDate } from "@/util/dates";
import MgIcon from "@/components/MgIcon";

export default {
  name: "RenxtDashboardTab",
  components: {
    BCard,
    EmptyState,
    MgIcon,
  },
  props: {
    isExportConfigured: {
      type: Boolean,
      required: true,
    },
    isImportConfigured: {
      type: Boolean,
      required: true,
    },
    imports: {
      type: Object,
      required: true,
    },
    exports: {
      type: Object,
      required: true,
    },
    exportSummary: {
      type: Object,
      required: true,
    },
  },
  computed: {
    topImports() {
      return this.imports.items.slice(0, 3);
    },
  },
  methods: {
    formatDate(date) {
      return formatDate(date, "MM/DD/YY, hh:mm A");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.panel-row {
  padding: 0 15px;
}

.card-imports {
  @include media-breakpoint-up(sm) {
    padding: 0 10px 0 0;
  }
  @include media-breakpoint-down(sm) {
    padding: 0 0 10px 0;
  }
}

.card-exports {
  @include media-breakpoint-up(sm) {
    padding: 0 0 0 10px;
  }
  @include media-breakpoint-down(sm) {
    padding: 10px 0 0 0;
  }
}

.renxt-import-row {
  background-color: $gray-light-bg;
  border-radius: 3px;
  font-weight: 300;
}
.renxt-import-row:hover {
  background-color: darken($table-hover-bg, 1%);
}

.card-inner {
  min-height: 320px;

  .card-header-label {
    font-size: 17.5px;
  }
}

.export-total-container {
  max-width: unset;
}

.export-number {
  font-size: 17.5px;
}

.export-text {
  font-size: 14px;
}

.container {
  color: $gray;
  background-color: $gray-light-bg;
  padding: 12px 15px;
}

.container:hover {
  background-color: darken($table-hover-bg, 1%);
}

.empty-message-text {
  font-size: 1.09375rem;
  font-style: italic;
  font-weight: 300;
  margin: 10px 0;
}
</style>
