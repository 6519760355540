import api from "@/util/api";

export const ACTIONS = {
  SET_RECRUIT_IS_LOADING: "recruit/updateIsLoading",
  GET_RECRUIT_CONFIG: "recruit/getRecruitConfig",
  GET_RECRUIT_CON_VALIDATION: "recruit/getRecruitConValid",
  GET_RECRUIT_EXPORT_SUMMARY: "recruit/getRecruitExportSummary",
  GET_RECRUIT_EXPORT_ERRORS: "recruit/getExportErrors",
  GET_RECRUIT_IMPORTS: "recruit/getImports",
  GET_CRM_VIEWS: "recruit/getCrmViews",
  GET_CRM_CURRENT_VIEW_FIELDS: "recruit/getCrmCurrentViewFields",
  UPDATE_RECRUIT_CONFIG: "recruit/updateRecruitConfig",
};
export const GETTERS = {
  GET_RECRUIT_IS_LOADING: "recruit/getRecruitIsLoading",
};
export const MUTATIONS = {
  SET_RECRUIT_IS_LOADING: "recruit/setIsLoading",
  SET_RECRUIT_BASE_SETTINGS: "recruit/setBaseSettings",
  SET_RECRUIT_CON_VALIDATION: "recruit/setRecruitConfig",
  SET_RECRUIT_EXPORT_SUMMARY: "recruit/setExportSummary",
  SET_RECRUIT_EXPORT_SETTINGS: "recruit/setExportSettings",
  SET_RECRUIT_IMPORT_SETTINGS: "recruit/setImportSettings",
  SET_RECRUIT_EXPORT_ERRORS: "recruit/setExportErrors",
  SET_RECRUIT_IMPORTS: "recruit/setImports",
  SET_CRM_VIEWS: "recruit/setCrmViews",
  SET_CRM_CURRENT_VIEW_FIELDS: "recruit/setCrmCurrentViewFields",
  RESET_IMPORT_SETTINGS: "recruit/resetImportSettings",
};

const defaultBaseState = {
  username: "",
  password: "",
  adfsRootUrl: "",
  crmRootUrl: "",
  failureNotificationEmail: "",
  notificationEmail: "",
};

const defaultConValidationState = {
  success: true,
  statusCode: -1,
  message: "",
};

const defaultExportSummaryState = {
  messages: {
    success: 0,
    fail: 0,
  },
  optOuts: {
    success: 0,
    fail: 0,
  },
};

const defaultApiListItemState = {
  items: [],
  skip: 0,
  take: 10,
  totalItems: 0,
};

const defaultCrmState = {
  views: [],
  currentViewFields: [],
};

export const defaultImportStandardFields = {
  firstName: {
    useRawValue: true,
    crmField: {
      id: "",
      name: "",
      attributeType: "",
      groupId: "",
      groupName: "",
      lookupSuffix: "@OData.Community.Display.V1.FormattedValue",
    },
  },
  lastName: {
    useRawValue: true,
    crmField: {
      id: "",
      name: "",
      attributeType: "",
      groupId: "",
      groupName: "",
      lookupSuffix: "@OData.Community.Display.V1.FormattedValue",
    },
  },
  staffId: {
    useRawValue: true,
    crmField: {
      id: "",
      name: "",
      attributeType: "",
      groupId: "",
      groupName: "",
      lookupSuffix: "@OData.Community.Display.V1.FormattedValue",
    },
  },
  mobileNumber: {
    useRawValue: true,
    crmField: {
      id: "",
      name: "",
      attributeType: "",
      groupId: "",
      groupName: "",
      lookupSuffix: "@OData.Community.Display.V1.FormattedValue",
    },
  },
  modifiedOn: {
    useRawValue: true,
    crmField: {
      id: "",
      name: "",
      attributeType: "",
      groupId: "",
      groupName: "",
      lookupSuffix: "@OData.Community.Display.V1.FormattedValue",
    },
  },
  contactId: {
    useRawValue: true,
    crmField: {
      id: "",
      name: "",
      attributeType: "",
      groupId: "",
      groupName: "",
      lookupSuffix: "@OData.Community.Display.V1.FormattedValue",
    },
  },
};

export const defaultCrmField = {
  id: "",
  name: "",
  attributeType: "",
  groupId: "",
  groupName: "",
  lookupSuffix: "@OData.Community.Display.V1.FormattedValue",
};

export const defaultImportOptOut = {
  optedInValue: "false",
  optedOutValue: "true",
  emptyIsOptedIn: true,
  useRawValue: true,
  crmField: {
    id: "",
    name: "",
    attributeType: "",
    groupId: "",
    groupName: "",
    lookupSuffix: "@OData.Community.Display.V1.FormattedValue",
  },
};

export const defaultImportCustomFields = [
  {
    customField: {
      displayName: "OpportunityId",
    },
    crmField: {
      id: "",
      name: "",
      attributeType: "",
      groupId: "",
      groupName: "",
      lookupSuffix: "@OData.Community.Display.V1.FormattedValue",
    },
    useRawValue: true,
  },
];

const defaultExportSettingsState = {
  enabled: false,
  enableOptOutPush: false,
  activityCode: "",
  failureNotificationEmail: "",
};

const defaultImportSettingsState = {
  schedule: {},
  standardFields: defaultImportStandardFields,
  optOutField: defaultImportOptOut,
  customFields: defaultImportCustomFields,
  savedViewId: "",
};

export default {
  state: () => ({
    isLoading: true,
    id: "",
    base: defaultBaseState,
    conValidation: defaultConValidationState,
    exportSummary: defaultExportSummaryState,
    exportSettings: defaultExportSettingsState,
    importSettings: defaultImportSettingsState,
    imports: defaultApiListItemState,
    exports: defaultApiListItemState,
    crm: defaultCrmState,
  }),
  actions: {
    [ACTIONS.SET_RECRUIT_IS_LOADING]({ commit }, value) {
      commit(MUTATIONS.SET_RECRUIT_IS_LOADING, value);
    },
    async [ACTIONS.GET_RECRUIT_CONFIG]({ commit }) {
      const config = await api.get("/recruit/configuration");

      commit(MUTATIONS.SET_RECRUIT_BASE_SETTINGS, config);
      commit(MUTATIONS.SET_RECRUIT_EXPORT_SETTINGS, config.exports);
      commit(MUTATIONS.SET_RECRUIT_IMPORT_SETTINGS, config.imports);
    },
    async [ACTIONS.GET_RECRUIT_CON_VALIDATION]({ commit }) {
      const connectionValidation = await api.get(
        "/recruit/configuration/validate-connection-settings"
      );

      commit(MUTATIONS.SET_RECRUIT_CON_VALIDATION, connectionValidation);
    },
    async [ACTIONS.GET_RECRUIT_EXPORT_SUMMARY]({ commit }) {
      let exportSummary = await api.get("/recruit/exports/summary");

      commit(MUTATIONS.SET_RECRUIT_EXPORT_SUMMARY, exportSummary);
    },
    async [ACTIONS.GET_RECRUIT_EXPORT_ERRORS]({ commit }, skip = 0, take = 50) {
      let headers = {
        "x-disable-loading": true,
      };

      let errors = await api.get(
        `/recruit/exports/errors?skip=${skip}?take=${take}`,
        { undefined, headers }
      );

      commit(MUTATIONS.SET_RECRUIT_EXPORT_ERRORS, errors);
    },
    async [ACTIONS.GET_RECRUIT_IMPORTS]({ commit }, skip = 0, take = 50) {
      let headers = {
        "x-disable-loading": true,
      };

      let imports = await api.get(
        `/recruit/imports?skip=${skip}?take=${take}`,
        {
          undefined,
          headers,
        }
      );

      commit(MUTATIONS.SET_RECRUIT_IMPORTS, imports);
    },
    async [ACTIONS.GET_CRM_VIEWS]({ commit }, skip = 0, take = 1000) {
      let views = await api.get(
        `/recruit/crm/opportunity-views?skip=${skip}?take=${take}`
      );

      commit(MUTATIONS.SET_CRM_VIEWS, views.items);
    },
    async [ACTIONS.GET_CRM_CURRENT_VIEW_FIELDS](
      { commit },
      viewId,
      skip = 0,
      take = 1000
    ) {
      let fields = await api.get(
        `/recruit/crm/opportunity-views/${viewId}/fields?skip=${skip}?take=${take}`
      );

      commit(MUTATIONS.SET_CRM_CURRENT_VIEW_FIELDS, fields.items);
    },
    async [ACTIONS.UPDATE_RECRUIT_CONFIG]({ commit }, value) {
      let data = await api.patch("/recruit/configuration", value);

      commit(MUTATIONS.SET_RECRUIT_BASE_SETTINGS, data);
      commit(MUTATIONS.SET_RECRUIT_EXPORT_SETTINGS, data.exports);
      commit(MUTATIONS.SET_RECRUIT_IMPORT_SETTINGS, data.imports);
    },
  },
  getters: {
    [GETTERS.GET_RECRUIT_IS_LOADING](state) {
      // Getting around the weird page loading by watching this in state
      return state.isLoading;
    },
  },
  mutations: {
    [MUTATIONS.SET_RECRUIT_IS_LOADING](state, isLoading) {
      state.isLoading = isLoading;
    },
    [MUTATIONS.SET_RECRUIT_BASE_SETTINGS](state, config) {
      state.base = config.base;
      state.id = config.id;
    },
    [MUTATIONS.SET_RECRUIT_CON_VALIDATION](state, connection) {
      state.conValidation = connection;
    },
    [MUTATIONS.SET_RECRUIT_EXPORT_SUMMARY](state, exportSummary) {
      state.exportSummary = exportSummary;
    },
    [MUTATIONS.SET_RECRUIT_EXPORT_SETTINGS](state, settings) {
      state.exportSettings = settings;
    },
    [MUTATIONS.SET_RECRUIT_IMPORT_SETTINGS](state, settings) {
      state.importSettings = settings;
      if (state.importSettings.savedViewId === null)
        state.importSettings.savedViewId = "";
    },
    [MUTATIONS.SET_RECRUIT_EXPORT_ERRORS](state, exportErrors) {
      state.exports = exportErrors;
    },
    [MUTATIONS.SET_RECRUIT_IMPORTS](state, imports) {
      state.imports = imports;
    },
    [MUTATIONS.SET_CRM_VIEWS](state, views) {
      state.crm.views = views;
    },
    [MUTATIONS.SET_CRM_CURRENT_VIEW_FIELDS](state, fields) {
      state.crm.currentViewFields = fields;
    },
    [MUTATIONS.RESET_IMPORT_SETTINGS](state) {
      state.importSettings.standardFields = defaultImportStandardFields;
      state.importSettings.optOutField = defaultImportOptOut;
      state.importSettings.customFields = defaultImportCustomFields;
    },
  },
};
