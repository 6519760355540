<template>
  <BModal
    id="delete-confirm"
    :title="`Delete ${sharedInboxName}`"
    title-tag="h3"
    @close="cancel()"
  >
    <form @submit.prevent="confirm()">
      <div v-if="errorMessage.length" class="alert alert-danger">
        <b v-if="errorMessage[0] && errorMessage[0].length">{{
          errorMessage[0]
        }}</b>
        <span v-if="errorMessage.length === 2">&nbsp;&nbsp;</span>
        <span v-if="errorMessage[1] && errorMessage[1].length">{{
          errorMessage[1]
        }}</span>
      </div>
      <div>
        <h4 class="error-list">Are you sure you want to delete this Shared Inbox?</h4>
        <p>
          <ul class="list-bulleted">
            <li>Messages and conversations from this Shared Inbox will be deleted</li>
            <li>This Shared Inbox's phone number will be relinquished</li>
          </ul>
            If you are sure that you would like to delete this Shared Inbox, please enter the Shared Inbox's name in the box below: <strong>{{ this.sharedInboxName }}</strong>
        </p>
      </div>
        <div class="form-group">
        <label for="inbox-name">Enter Shared Inbox's name</label>
        <input
          v-model="inboxName_"
          type="text"
          class="form-control"
          id="inbox-name"
        />
        </div>
    </form>
    <template v-slot:modal-footer>
      <button
        @click="confirm()"
        data-test-id="delete-inbox"
        class="btn btn-primary btn-warning"
        :disabled="!submitAllowed_"
      >
        Delete
      </button>
      <button @click="cancel()" class="btn btn-light" :disabled="saving">
        Cancel
      </button>
    </template>
  </BModal>
</template>
<script>
import { BModal } from "bootstrap-vue";

export default {
  name: "SharedDeleteModal",
  components: { BModal },
  props: {
    id: {
      type: Number,
      required: false,
    },
    sharedInboxName: {
      type: String,
      required: false
    },
    saving: {
      type: Boolean,
      required: false,
    },
    show: {
      type: Boolean,
      required: true,
    },
    submitAllowedTest: {
      type: Function,
      required: false,
    },
    errorMessage: {
      type: Array,
      required: false,
      default() {
        return [];
      },

    },
  },
  data() {
    return {
      submitAllowed_: false,
      inboxName_: "",
    };
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.showModal();
      } else if (!newVal && oldVal) {
        this.hideModal();
      }
    },
    inboxName_() {
      const inputName = this.inboxName_.toLowerCase();
      if (inputName === this.sharedInboxName.toLowerCase() ) {
        this.submitAllowed_ = true;
      } else {
        this.submitAllowed_ = false;
      }
    },
  },
  mounted() {
    this.$root.$on("bv::modal::hide", () => {
      this.cancel();
    });
  },
  methods: {
    confirm() {
      if (!this.submitAllowed_) {
        return;
      }
      this.$emit("confirmed", {inboxId: this.id});
    },
    cancel() {
      this.hideModal()
    },
    showModal() {
      this.$bvModal.show("delete-confirm");
    },
    hideModal() {
      this.$emit("cancelled");
      this.$bvModal.hide("delete-confirm");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/variables";
.strong-error-text {
  margin-right: 1em;
}
.btn-warning {
  background-color: #c82333;
  border-color: #c82333;
  color: #fff;
}
</style>
