<template>
  <div
    id="pageHeader"
    class="d-flex flex-row flex-fill page-header justify-content-end"
  >
    <div class="d-flex flex-row flex-fill justify-content-start">
      <h1>{{ headerTitle }}</h1>
    </div>
    <QuickTipButton v-if="tipId" :tipId="tipId" :events="tipEvents" />
  </div>
</template>
<script>
import QuickTipButton from "@/components/QuickTipButton";

export default {
  name: "PageHeader",
  components: { QuickTipButton },
  props: {
    headerTitle: {
      type: String,
      required: false,
      default: "",
    },
    tipId: {
      type: String,
      required: false,
      default: "",
    },
    tipEvents: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped></style>
