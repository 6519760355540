import store from "@/store";
import _ from "lodash";
import { GETTERS } from "@/util/constants";

class appBus {
  constructor() {
    this.bus = window;
    this.APP_NAME = "legacy-sms-web";
    this.STATE_CHANGE_EVENT_NAME = "mg-state-change";
    this.PROP_SYNC_METHOD_NAME = "appBusPropSync";
    this.LISTENER_ATTRIBUTE = `app-listener-${this.APP_NAME}`;
    this.EVENT_TYPE = {
      ACTION: 1,
      MUTATION: 2,
    };
    this.initialize();
  }

  initialize() {
    if (!this.bus[this.LISTENER_ATTRIBUTE]) {
      this.addStateListener((event) => {
        if (event.detail.type === this.EVENT_TYPE.ACTION) {
          this.handleReceiveStateAction(
            event.detail.app,
            event.detail.name,
            event.detail.payload
          );
        } else if (event.detail.type === this.EVENT_TYPE.MUTATION) {
          this.handleReceiveStateMutation(
            event.detail.app,
            event.detail.name,
            event.detail.payload
          );
        }
      });
      this.bus[this.LISTENER_ATTRIBUTE] = true;
    }
  }

  addStateListener(callback) {
    this.bus.addEventListener(this.STATE_CHANGE_EVENT_NAME, callback);
  }

  handleReceiveStateAction(app, name, payload) {
    if (app !== this.APP_NAME) {
      if (typeof payload !== "undefined") {
        store.dispatch(name, payload);
      } else {
        store.dispatch(name);
      }
    }
  }

  // Vuex doesn't care about the module name since the mutations are
  // namespaced globally
  handleReceiveStateMutation(app, name, payload) {
    if (app !== this.APP_NAME) {
      store.commit(name, payload, false);
    }
  }

  // But Pinia will need a module name to find the right store to update
  dispatchStateChange(module, name, payload) {
    this.bus.dispatchEvent(
      new CustomEvent(this.STATE_CHANGE_EVENT_NAME, {
        detail: { app: this.APP_NAME, module, name, payload },
      })
    );
  }

  // sync properties between a Vuex store and a Pinia store
  syncProps(vuexModule, piniaStore, propsToSync) {
    const v2InboxEnabled = store.getters[GETTERS.FEATURE_ENABLED](
      "rollout-migrate-sms-inbox-to-vue-3"
    );

    if (v2InboxEnabled) {
      // pack it up
      const serialized = _.pick(store.state[vuexModule], propsToSync);
      // send it over
      this.dispatchStateChange(
        piniaStore,
        this.PROP_SYNC_METHOD_NAME,
        serialized
      );
    }
  }

  /* Probably don't use this function. This is the nuclear option, if we ever
  need to sync up a whole store. Just call dispatchStateChange() as needed
  instead.
  EDIT: Time for the nuclear option. */
  connectVuexMutations(vuexModule, piniaStore, defaultProps, mutations) {
    return Object.entries(mutations).reduce((acc, cur) => {
      return {
        ...acc,
        [cur[0]]: (state, value) => {
          const propsToSync = cur[1](state, value) || defaultProps;
          this.syncProps(vuexModule, piniaStore, propsToSync);
        },
      };
    }, {});
  }
}

const bus = new appBus();

window.appBus = bus;

export default bus;
