<template>
  <div class="timeline-item">
    <span>{{ time | utcToLocal("h:mm A") }}</span>
  </div>
</template>

<script>
export default {
  name: "ContactTimeline",
  props: ["time"],
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
.timeline-item {
  font-size: $font-size-sm;
  padding-right: 15px;
  position: relative;
  border-right: 2px solid transparentize($link-color, 0.6);
  width: 100px;
  text-align: right;
  &::before {
    content: "";
    border-radius: 50%;
    width: 11px;
    height: 11px;
    background-color: #fff;
    border: 2px solid $link-color;
    position: absolute;
    right: -6px;
    top: 50%;
    transform: translate(0.5px, -50%);
  }
  span {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
