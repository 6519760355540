<template>
  <div class="emptyState d-flex justify-content-center">
    <div class="d-flex align-items-center">
      <div>
        <div v-if="!searchActive">
          <h2>No Shared Inboxes yet!</h2>
          <button @click="togglePopover()" target="_blank" type="submit">
            Create one.
          </button>
        </div>
        <div v-if="searchActive">
          <h2>No results</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SharedInboxEmptyState",
  props: {
    type: {
      type: String,
      required: false,
    },
    searchActive: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    togglePopover() {
      this.$emit("createInbox");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";

.emptyState {
  background: #fff;
  color: $gray-700;
  padding: 60px $card-spacer-x;
  text-align: center;
  width: 100%;
}
.emptyState h2 {
  font-size: 1.3125rem;
  font-weight: 300;
}
.emptyState button {
  font-size: 1rem;
  font-weight: 400;
  border: 1px solid transparent;
  background-color: transparent;
  color: $link-color;
}
button[type="submit"]:hover {
  color: #003a5c;
  text-decoration: underline;
}
</style>
