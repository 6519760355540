<template>
  <LocalizationProvider language="en-EN" :style="containerStyle_">
    <IntlProvider locale="en" :style="containerStyle_">
      <grid
        :style="gridStyle_"
        :sortable="sortable"
        :pageable="pageable_"
        :data-items="dataResult"
        :skip="skip_"
        :take="take_"
        :sort="sort_"
        :total="total"
        :columns="compColumns"
        :class="{ hideSort: !sortable }"
        @datastatechange="handleDataStateChange"
        @pagechange="handlePageChange"
      >
        <toolbar v-if="showToolbar">
          <span
            v-if="showSearch"
            class="k-textbox k-grid-search k-display-flex"
          >
            <k-input
              :placeholder="'Search by Name'"
              :value="searchWord_"
              @input="onFilter"
            ></k-input>
          </span>
        </toolbar>
      </grid>
    </IntlProvider>
  </LocalizationProvider>
</template>
<script>
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { Input } from "@progress/kendo-vue-inputs";
import { process } from "@progress/kendo-data-query";
import {
  IntlProvider,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-vue-intl";
import "../../scss/mgKendo/MgDataGrid/_index.scss";

loadMessages(
  {
    grid: {
      pagerInfo: "{0:#,###} - {1:#,###} of {2:#,###}",
    },
  },
  "en-EN"
);

export default {
  name: "MgDataGrid",
  components: {
    grid: Grid,
    toolbar: GridToolbar,
    "k-input": Input,
    IntlProvider,
    LocalizationProvider,
  },
  data() {
    return {
      skip_: this.skip,
      take_: this.take,
      sort_: this.sort,
      pageable_: {
        buttonCount: 0,
        pageSizes: false,
      },
      gridStyle_: { height: "100%" },
      containerStyle_: { height: "100%" },
      rowData_: this.rowData,
      columns_: this.columns,
      searchWord_: "",
      filterData_: [],
    };
  },
  props: {
    rowData: {
      type: Array,
      required: true,
    },
    sortable: {
      type: Object,
      required: false,
      default() {
        return { allowUnsort: true, mode: "single" };
      },
    },
    columns: {
      type: Array,
      required: true,
    },
    sort: {
      type: Array,
      required: false,
    },
    paginationMode: {
      type: String,
      required: false,
      default: "CLIENT",
    },
    renderMenu: {
      type: Function,
      required: false,
    },
    gridStyle: {
      type: Object,
      required: false,
    },
    skip: {
      type: Number,
      required: false,
      default: 0,
    },
    take: {
      type: Number,
      required: false,
      default: 50,
    },
    total: {
      type: Number,
      required: false,
    },
    /* custom events emitted:
        - data-state-change
    */
    showToolbar: {
      type: Boolean,
      required: false,
    },
    showSearch: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    dataResult() {
      if (this.paginationMode === "CLIENT") {
        if (this.filterData_.length) {
          return process(this.rowData, {
            filter: {
              logic: "or",
              filters: this.filterData_,
            },
            skip: this.skip_,
            take: this.take_,
            sort: this.sort_,
          });
        } else {
          return process(this.rowData, {
            skip: this.skip_,
            take: this.take_,
            sort: this.sort_,
          });
        }
      } else if (this.paginationMode === "SERVER") {
        return this.rowData;
      } else {
        return [];
      }
    },
    compColumns() {
      if (this.renderMenu) {
        return [...this.columns, { cell: this.renderMenu, width: "60px" }];
      } else {
        return this.columns;
      }
    },
  },
  watch: {
    skip(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.skip_ = this.skip;
      }
    },
    take(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.take_ = this.take;
      }
    },
    sort(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.sort_ = this.sort;
      }
    },
  },
  created() {},
  methods: {
    createAppState: function (dataState) {
      this.skip_ = dataState.skip;
      this.take_ = dataState.take;
      this.sort_ = dataState.sort;
      this.$emit("data-state-change", dataState);
    },
    onFilter(e) {
      let inputValue = e.value;
      let tempFilter = [];
      this.searchWord_ = inputValue;

      this.compColumns.forEach(function (col) {
        let data = {
          field: col.field,
          operator: "contains",
          value: inputValue,
        };
        tempFilter.push(data);
      });

      this.filterData_ = tempFilter;
    },
    handleDataStateChange(event) {
      this.createAppState(event.data);
    },
    handlePageChange(event) {
      this.createAppState({
        skip: event.page.skip,
        take: event.page.take,
        sort: this.sort_,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.k-grid-search {
  position: absolute;
  right: 1%;
}
</style>
