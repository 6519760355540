import { ACTIONS, GETTERS, MUTATIONS } from "@/util/constants";
import api from "@/util/api";

export default {
  state: () => ({
    startDate: "",
    endDate: "",
    metrics: {},
    teamId: null,
    currentTeams: [],
    currentInboxes: [],
    breakdownType: "teams",
    exportType: "cardName",
    teamsBreakdown: [],
    usersBreakdown: [],
    inboxesBreakdown: [],
    campaignsBreakdown: [],
    timeInterval: "",
    breakdownSkip: 0,
    inboxDetailSkip: 0,
    filteredTeams: [],
    take: 10,
    sort: "total desc",
    search: "",
    inboxDetails: { breakdown: {}, metrics: {} },
    inboxDetailsContext: {},
    inboxDetailSearch: "",
    inboxDetailId: null,
  }),
  actions: {
    [ACTIONS.GET_TEAM_ID]({ commit }) {
      let profile = JSON.parse(window.localStorage.profile);
      commit(MUTATIONS.SET_TEAM_ID, profile.currentDepartment.id);
    },
    [ACTIONS.GET_CURRENT_TEAMS]({ commit }) {
      let profile = JSON.parse(window.localStorage.profile);
      commit(MUTATIONS.SET_CURRENT_TEAMS, profile.departments);
    },
    [ACTIONS.GET_CURRENT_INBOXES]({ commit }) {
      let profile = JSON.parse(window.localStorage.profile);
      commit(MUTATIONS.SET_CURRENT_INBOXES, profile.inboxes);
    },
    [ACTIONS.GET_ANALYTICS_DATES]({ commit, dispatch }, dates) {
      commit(MUTATIONS.SET_ANALYTICS_DATES, dates);
      dispatch(ACTIONS.GET_ANALYTICS_BREAKDOWN, dates.hasMedia);
      dispatch(ACTIONS.GET_METRICS, dates.hasMedia);
    },
    [ACTIONS.GET_INBOX_DETAILS_DATES]({ commit, dispatch }, dates) {
      commit(MUTATIONS.SET_ANALYTICS_DATES, dates);
      dispatch(ACTIONS.GET_INBOX_DETAILS_BREAKDOWN);
      dispatch(ACTIONS.GET_INBOX_DETAILS_METRICS);
    },
    [ACTIONS.GET_ANALYTICS_FILTER]({ commit, dispatch }, filteredTeams) {
      commit(MUTATIONS.SET_ANALYTICS_FILTER, filteredTeams);
      dispatch(ACTIONS.GET_ANALYTICS_BREAKDOWN);
      dispatch(ACTIONS.GET_METRICS);
    },
    async [ACTIONS.GET_METRICS]({ state, commit }, hasMedia = null) {
      if (
        state.timeInterval &&
        state.startDate &&
        state.endDate &&
        state.filteredTeams.length > 0
      ) {
        if (hasMedia == null) {
          let textType = JSON.parse(
            sessionStorage.getItem("analytics-text-type-filter")
          );
          if (textType) {
            hasMedia = textType.length > 1 ? null : textType.includes("MMS");
          }
        }
        let body = {
          timeInterval: state.timeInterval,
          take: 0,
          skip: 0,
          start: state.startDate,
          end: state.endDate,
          departments: state.filteredTeams,
          hasMedia: hasMedia,
        };
        let metrics = await api.post(`/reporting/messages`, body);
        return commit(MUTATIONS.SET_METRICS, metrics);
      }
    },
    async [ACTIONS.GET_ANALYTICS_BREAKDOWN](
      { commit, state },
      hasMedia = null
    ) {
      if (
        state.timeInterval &&
        state.startDate &&
        state.endDate &&
        state.filteredTeams.length > 0
      ) {
        if (hasMedia == null) {
          let textType = JSON.parse(
            sessionStorage.getItem("analytics-text-type-filter")
          );
          if (textType) {
            hasMedia = textType.length > 1 ? null : textType.includes("MMS");
          }
        }

        let body = {
          timeInterval: state.timeInterval,
          take: state.take,
          skip: state.breakdownSkip,
          start: state.startDate,
          end: state.endDate,
          departments: state.filteredTeams,
          sort: state.sort,
          name: state.search,
          hasMedia: hasMedia,
        };

        let endpoint;
        let mutation;

        switch (state.breakdownType) {
          case "teams":
            endpoint = `/reporting/messages/teams`;
            mutation = MUTATIONS.SET_TEAMS_BREAKDOWN;
            break;
          case "users":
            endpoint = "/reporting/messages/users";
            mutation = MUTATIONS.SET_USERS_BREAKDOWN;
            break;
          case "inboxes":
            endpoint = "/reporting/messages/inbox";
            mutation = MUTATIONS.SET_INBOXES_BREAKDOWN;
            break;
          case "campaigns":
            endpoint = "/reporting/messages/campaigns";
            mutation = MUTATIONS.SET_CAMPAIGNS_BREAKDOWN;
            break;
        }

        const breakdown = await api.post(endpoint, body);
        return commit(mutation, breakdown);
      }
    },
    async [ACTIONS.GET_INBOX_DETAILS_METRICS]({ commit, state }) {
      let metricsBody = {
        timeInterval: state.timeInterval,
        take: 0,
        skip: 0,
        start: state.startDate,
        end: state.endDate,
        departments: state.inboxDetailsContext.departments,
        highspeedinboxes:
          state.inboxDetailsContext.type === "HighSpeed"
            ? [state.inboxDetailsContext.id]
            : [],
        sharedinboxes:
          state.inboxDetailsContext.type !== "HighSpeed"
            ? [state.inboxDetailsContext.id]
            : [],
      };

      let metrics = await api.post(`/reporting/messages`, metricsBody);
      return commit(MUTATIONS.SET_INBOX_DETAILS_METRICS, metrics);
    },
    async [ACTIONS.GET_INBOX_DETAILS_BREAKDOWN]({ commit, state }) {
      let breakdownBody = {
        take: state.take,
        skip: state.inboxDetailSkip,
        start: state.startDate,
        end: state.endDate,
        departments: state.inboxDetailsContext.departments,
        sort: state.sort,
        name: state.inboxDetailSearch,
        highspeedinboxes:
          state.inboxDetailsContext.type === "HighSpeed"
            ? [state.inboxDetailsContext.id]
            : [],
        sharedinboxes:
          state.inboxDetailsContext.type !== "HighSpeed"
            ? [state.inboxDetailsContext.id]
            : [],
      };

      let breakdown = await api.post(
        `/reporting/messages/users`,
        breakdownBody
      );
      return commit(MUTATIONS.SET_INBOX_DETAILS_BREAKDOWN, breakdown);
    },
    [ACTIONS.CLEAR_METRICS]({ commit }) {
      commit(MUTATIONS.SET_METRICS, {});
    },
  },
  getters: {
    [GETTERS.TEAM_NAME_BY_ID]: (state) => (id) => {
      if (!state.currentTeams) return;
      let team = state.currentTeams.find((team) => team.id === id);
      return team ? team.name : "";
    },
    [GETTERS.EXPORTS_HIDE_HIGH_SPEED]: (state) => {
      return (
        state.currentInboxes.filter((inbox) => inbox.type === "high-speed")
          .length === 0
      );
    },
  },
  mutations: {
    [MUTATIONS.SET_ANALYTICS_DATES](state, dates) {
      state.startDate = dates.start;
      state.endDate = dates.end;
      state.timeInterval = dates.timeInterval;
    },
    [MUTATIONS.SET_METRICS](state, metrics) {
      state.metrics = metrics;
    },
    [MUTATIONS.SET_CURRENT_TEAMS](state, teams) {
      state.currentTeams = teams;
    },
    [MUTATIONS.SET_CURRENT_INBOXES](state, inboxes) {
      state.currentInboxes = inboxes;
    },
    [MUTATIONS.SET_TEAMS_BREAKDOWN](state, teamsBreakdown) {
      state.teamsBreakdown = teamsBreakdown;
    },
    [MUTATIONS.SET_USERS_BREAKDOWN](state, usersBreakdown) {
      state.usersBreakdown = usersBreakdown;
    },
    [MUTATIONS.SET_INBOXES_BREAKDOWN](state, inboxesBreakdown) {
      state.inboxesBreakdown = inboxesBreakdown;
    },
    [MUTATIONS.SET_CAMPAIGNS_BREAKDOWN](state, campaignsBreakdown) {
      state.campaignsBreakdown = campaignsBreakdown;
    },
    [MUTATIONS.SET_BREAKDOWN_TYPE](state, type) {
      state.breakdownType = type;
    },
    [MUTATIONS.SET_TEAM_ID](state, team) {
      state.teamId = team;
      state.filteredTeams = [team];
    },
    [MUTATIONS.SET_ANALYTICS_FILTER](state, teams) {
      state.filteredTeams = teams;
    },
    [MUTATIONS.SET_BREAKDOWN_SKIP](state, skip) {
      state.breakdownSkip = skip;
    },
    [MUTATIONS.RESET_BREAKDOWN_SKIP](state) {
      state.breakdownSkip = 0;
    },
    [MUTATIONS.SET_SORT](state, sort) {
      state.sort = sort;
    },
    [MUTATIONS.SET_SEARCH](state, search) {
      state.search = search;
    },
    [MUTATIONS.SET_INBOX_DETAILS_METRICS](state, metrics) {
      state.inboxDetails.metrics = metrics;
    },
    [MUTATIONS.SET_INBOX_DETAILS_BREAKDOWN](state, breakdown) {
      state.inboxDetails.breakdown = breakdown;
    },
    [MUTATIONS.SET_INBOX_DETAIL_SKIP](state, skip) {
      state.inboxDetailSkip = skip;
    },
    [MUTATIONS.RESET_INBOX_DETAIL_SKIP](state) {
      state.inboxDetailSkip = 0;
    },
    [MUTATIONS.SET_INBOX_DETAILS_CONTEXT](state, context) {
      state.inboxDetailsContext = context;
    },
    [MUTATIONS.SET_INBOX_DETAIL_SEARCH](state, search) {
      state.inboxDetailSearch = search;
    },
  },
};
