export default {
  campaigns: {
    active: [],
    names: [],
  },
  config: {
    areaCode: null,
    features: {},
    hasConnect: null,
    hasContacts: false,
    hasRadius: null,
    isAdmin: null,
    orgId: null,
    team: null,
    timeZone: null,
    userId: null,
    serverTimeOffset: null,
    integrations: null,
  },
  connectFilters: null,
  radiusTargets: null,
  slateQueries: null,
  reNxtLists: null,
  showContactCard: false,
  users: [],
};
