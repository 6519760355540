<template>
  <div class="credit-balance d-flex align-items-end">
    <div
      class="balance"
      :class="{ 'text-danger': isLow }"
      v-html="balance"
    ></div>
    <div class="credits text-small align-self-end">Credits</div>
    <MgIcon name="info-circle" v-b-tooltip.html="{ title: tooltipContent }" />
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import MgIcon from "@/components/MgIcon";
import { VBTooltip } from "bootstrap-vue";
import { ACTIONS } from "@/util/constants";

Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "CreditBalance",
  store,
  components: {
    MgIcon,
  },
  created() {
    if (!this.$store.state.inbox.creditBalance) {
      this.$store.dispatch(ACTIONS.GET_CREDIT_BALANCE);
    }
  },
  computed: {
    balance() {
      return this.$store.state.inbox.creditBalance
        ? this.$store.state.inbox.creditBalance.toLocaleString()
        : "&ndash;";
    },
    isLow() {
      return this.$store.state.inbox.creditBalance
        ? this.$store.state.inbox.creditBalance < 1
        : false;
    },
  },
  methods: {
    tooltipContent() {
      let content = `Credits are used at the rate of 1 credit per message.`;
      if (this.$store.state.config.isAdmin) {
        content += ` Contact your Client Success Manager to purchase more credits.`;
      }
      return content;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";

.balance {
  font-weight: $font-weight-bold;
}

.credits {
  color: $text-muted;
  margin: 0 0.5em;
}
</style>
