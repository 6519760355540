<template>
  <div>
    <transition name="fade">
      <div v-show="noResults" class="empty" v-html="noResultsMessage"></div>
    </transition>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";
import { ACTIONS, GETTERS, CONVERSATION_FILTERS } from "@/util/constants";
import twemoji from "twemoji";

export default {
  name: "NoResultsMessage",
  store: store,
  data() {
    return {
      noResultsMessage: "",
    };
  },
  computed: {
    ...mapState({
      emptyInboxMessages: (state) => state.inbox.emptyInboxMessages,
      emptyInboxMessagesIndex: (state) => state.inbox.emptyInboxMessagesIndex,
      activeFilter: (state) => state.inbox.activeFilter,
      isInboxView: (state) => state.inbox.isInboxView,
      isArchivedView: (state) => state.inbox.isArchivedView,
    }),
    noResults() {
      if (this.$store.getters[GETTERS.NO_CONVERSATIONS]) {
        this.getNoResultsMessage();
      }

      return this.$store.getters[GETTERS.NO_CONVERSATIONS];
    },
    getUserNameById() {
      return (id) => this.$store.getters[GETTERS.USER_NAME_BY_ID](id);
    },
    userId() {
      return this.$store.state.config.userId;
    },
  },
  methods: {
    getNoResultsMessage() {
      if (this.activeFilter.conversationSearch !== null) {
        return (this.noResultsMessage = `No results found for <em>${this.activeFilter.conversationSearch}</em>.<br>Try a new search.`);
      }
      let readStatus =
        this.activeFilter.conversationFilter.readStatus ===
        CONVERSATION_FILTERS.ALL
          ? ""
          : this.activeFilter.conversationFilter.readStatus;

      let userString =
        this.userId === this.activeFilter.conversationFilter.assignedTo
          ? "me"
          : this.getUserNameById(
              this.activeFilter.conversationFilter.assignedTo
            );

      if (this.activeFilter.conversationFilter.assignedTo > 0) {
        return (this.noResultsMessage = `No ${readStatus} messages assigned to ${userString}.`);
      } else if (this.activeFilter.conversationFilter.assignedTo === null) {
        return (this.noResultsMessage = `No ${readStatus} messages.`);
      }

      if (this.isInboxView) {
        return this.noResultsInboxMessage();
      }
      if (this.isArchivedView) {
        return (this.noResultsMessage = "No archived messages.");
      }
    },
    noResultsInboxMessage() {
      let message = this.emptyInboxMessages[this.emptyInboxMessagesIndex];
      this.$store.dispatch(ACTIONS.INCREMENT_EMPTY_INBOX_MESSAGES_INDEX);

      this.noResultsMessage = `<div>${message.text}</div>
                    <div class="animated ${message.animation}">${twemoji.parse(
        message.icon, {base: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/'}
      )}</div>`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";

.empty {
  color: $text-muted;
  font-size: $h4-font-size * $font-size-xs-ratio;
  font-weight: $font-weight-light;
  overflow: hidden;
  padding: 30px;
  text-align: center;

  @include media-breakpoint-up(sm) {
    font-size: $h4-font-size;
  }
}
.fade-enter-active,
.fade-enter-leave-active {
  transition: opacity 0.2s linear;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave {
  opacity: 1;
}
::v-deep .emoji {
  height: 2em;
  margin-top: 15px;
  width: 2em;
}
</style>
