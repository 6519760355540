<template>
  <div class="message-media">
    <span v-for="item in media" :key="item.url">
      <span v-if="getMediaType(item.mimeType) === 'image'">
        <a href @click="openPreview(item.url, $event)">
          <img class="message-media-thumbnail" :src="item.url" alt="" />
        </a>
      </span>
      <a v-else :href="item.url" class="message-attachment" target="_blank">
        <i class="fa" :class="mediaIconClass(item.mimeType)"></i>&ensp;<a
          :href="item.url"
          download
          >Download</a
        >
      </a>
    </span>
  </div>
</template>

<script>
import Vue from "vue";
import { ModalPlugin } from "bootstrap-vue";

Vue.use(ModalPlugin);

export default {
  name: "MessageMedia",
  props: ["media"],
  methods: {
    getMediaType(mimeType) {
      return new RegExp("^image*").test(mimeType)
        ? "image"
        : new RegExp("^audio*").test(mimeType)
        ? "audio"
        : new RegExp("^video*").test(mimeType)
        ? "video"
        : "other";
    },
    mediaIconClass(mimeType) {
      var icon = new RegExp("pdf").test(mimeType)
        ? "file-pdf-o"
        : new RegExp("vcard|directory").test(mimeType)
        ? "vcard-o"
        : new RegExp("csv|excel").test(mimeType)
        ? "file-excel-o"
        : new RegExp("image").test(mimeType)
        ? "file-image-o"
        : new RegExp("audio").test(mimeType)
        ? "file-audio-o"
        : new RegExp("video").test(mimeType)
        ? "file-video-o"
        : new RegExp("text").test(mimeType)
        ? "file-text-o"
        : "file-o";
      return "fa-" + icon;
    },
    openPreview(url, event) {
      event.preventDefault();

      const h = this.$createElement;
      const messageVNode = h("div", { class: "text-center" }, [
        h("img", {
          attrs: {
            alt: "",
            class: "img-fluid my-3",
            src: url
          }
        })
      ]);
      this.$bvModal.msgBoxOk([messageVNode], {
        title: "Message Media",
        footerClass: "d-none",
        hideHeaderClose: false,
        titleTag: "h3"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";

.message-media {
  a {
    display: inline-block;
    margin-right: 15px;

    .outgoing & {
      margin-right: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }
}

.message-media-thumbnail {
  max-height: 120px;
  max-width: 120px;
}

.message-attachment {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: inline-block;
  line-height: 30px;
  padding: 1px 8px 3px;

  .outgoing & {
    background: rgba(255, 255, 255, 0.2);

    a {
      &:hover {
        //color: lighten($accent, 4%);
      }
    }
  }

  i {
    color: $text-color;
    display: inline-block;
    font-size: 24px;
    vertical-align: middle;
  }

  a {
    font-size: 12px;
    vertical-align: middle;

    &:hover {
      //color: darken($accent, 4%);
    }
  }
}
</style>
