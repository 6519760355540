<template>
  <card :orientation="orientation" :type="type"
    ><cardHeader v-if="!hideHeader">
      <slot name="header"></slot>
      <cardTitle v-if="headerTitle">{{ headerTitle }}</cardTitle>
      <cardSubtitle v-if="headerSubtitle">{{ headerSubtitle }}</cardSubtitle>
    </cardHeader>
    <cardBody class="body">
      <slot name="body"></slot>
      <cardTitle v-if="bodyTitle">{{ bodyTitle }}</cardTitle>
      <cardSubtitle v-if="bodySubtitle">{{ bodySubtitle }}</cardSubtitle>
    </cardBody>
    <cardFooter v-if="!hideFooter">
      <slot name="footer"></slot>
      <cardTitle v-if="footerTitle">{{ footerTitle }}</cardTitle>
      <cardSubtitle v-if="footerSubtitle">{{ footerSubtitle }}</cardSubtitle>
    </cardFooter>
  </card>
</template>
<script>
import {
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardBody,
  CardFooter,
} from "@progress/kendo-vue-layout";
import "../../scss/mgKendo/MgKendoCard/_index.scss";

export default {
  name: "MgKendoCard",
  components: {
    card: Card,
    cardHeader: CardHeader,
    cardTitle: CardTitle,
    cardSubtitle: CardSubtitle,
    cardBody: CardBody,
    cardFooter: CardFooter,
  },
  props: {
    orientation: {
      type: String,
      required: false,
      default: "vertical",
    },
    type: {
      type: String,
      required: false,
      default: "default",
    },
    headerTitle: {
      type: String,
      required: false,
    },
    headerSubtitle: {
      type: String,
      required: false,
    },
    bodyTitle: {
      type: String,
      required: false,
    },
    bodySubtitle: {
      type: String,
      required: false,
    },
    footerTitle: {
      type: String,
      required: false,
    },
    footerSubtitle: {
      type: String,
      required: false,
    },
    hideHeader: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.k-card {
  z-index: 0;
}
</style>
