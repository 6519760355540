import Vue from "vue";
import twemoji from "twemoji";
import moment from "moment";
import store from "../store";

Vue.filter("displayName", function (contact) {
  if (!contact) return null;
  return contact.firstName
    ? `${contact.firstName} ${contact.lastName}`
    : contact.mobileNumberFormatted;
});

Vue.filter("twemoji", function (txt) {
  if (!txt) return "";
  return twemoji.parse(txt, {base: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/'});
});

Vue.filter("utcToLocal", function (value, format) {
  return !value
    ? null
    : moment.tz(moment.utc(value), store.state.config.timeZone).format(format);
});

Vue.filter("dateShorthand", function (value) {
  if (!value) return "";

  let today = moment.tz(store.state.config.timeZone),
    date = moment.tz(moment.utc(value), store.state.config.timeZone),
    yesterday = moment(today).subtract(1, "days");

  if (date.isSame(yesterday, "day")) return "Yesterday";
  if (date.isSame(today, "day")) return "Today";
  if (date.isSame(today, "year")) return date.format("dddd, MMMM Do");
  return date.format("dddd, MMMM Do YYYY");
});

Vue.filter("dateTimeShorthand", function (value) {
  if (!value) return "";

  let today = moment.tz(store.state.config.timeZone),
    date = moment.tz(moment.utc(value), store.state.config.timeZone),
    pastWeek = moment(today).subtract(6, "days");

  if (date.isSame(today, "day")) return date.format("h:mm A");

  if (date.isAfter(pastWeek)) return date.format("dddd, h:mm A");

  return date.format("ddd, M/D/YY, h:mm A");
});

export const timeShorthand = function (value) {
  if (!value) return "";

  let date = moment.tz(moment.utc(value), store.state.config.timeZone);

  return date.format("h:mm A");
};
Vue.filter("timeShorthand", timeShorthand);

export const alphaNumeric = (value) => {
  if (!value) return "";
  return String(value).replace(/[^a-zA-Z0-9']/g, "");
};
Vue.filter("alphaNumeric", alphaNumeric);

export const capitalize = (value) => {
  if (!value) return "";

  const text = String(value);
  let capitalized = text.charAt(0).toUpperCase();
  if (value.length > 1) {
    capitalized = `${capitalized}${text.substring(1)}`;
  }
  return capitalized;
};
Vue.filter("capitalize", capitalize);

export const decapitalize = (value) => {
  if (!value) return "";

  const text = String(value);
  let decapitalized = text.charAt(0).toLowerCase();
  if (value.length > 1) {
    decapitalized = `${decapitalized}${text.substring(1)}`;
  }
  return decapitalized;
};
Vue.filter("decapitalize", decapitalize);

export const fromNow = (value) => {
  if (!value) return "";

  if (moment(value).isSameOrAfter(moment.now())) {
    return "a few seconds ago";
  }
  return moment(value).fromNow();
};
Vue.filter("fromNow", fromNow);

export const snakeCaseToDisplay = (value) => {
  if (!value) return "";

  return value
    .split("_")
    .map((string) => capitalize(string))
    .join(" ");
};
Vue.filter("snakeCaseToDisplay", snakeCaseToDisplay);

export const truncWithEllipsis = (value, maxLength) => {
  if (!value) return "";

  const text = String(value);
  return text.length > maxLength ? `${text.substr(0, maxLength)}...` : text;
};
Vue.filter("truncWithEllipsis", truncWithEllipsis);
