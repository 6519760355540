<template>
  <div id="renxt-integration-import-settings" class="d-flex flex-column h-100">
    <SubpageHeader
      headerTitle="Raiser's Edge NXT Import Settings"
      backHref="/#/integrations/renxt/imports/"
      class="mb-2"
    />

    <div class="renxt-import-page-content overflow-auto" v-if="!isLoading">
      <div class="field-errors-container pb-4" v-show="errors.length > 0">
        <div class="alert alert-danger d-flex align-items-center p-4">
          <span class="text-danger">
            <MgIcon name="exclamation-triangle" scale="1.25" />
          </span>
          <span class="font-weight-normal">
            There was an error saving the settings. Please address the errors
            and try again.
          </span>
        </div>
      </div>

      <BCard header="Import Schedule" header-tag="h2">
        <div>
          <IntegrationsImportScheduleCard
            :importSchedule="importSettings.schedule"
            :orgTimezone="orgTimezone"
            single
          />
        </div>
      </BCard>

      <BCard header="Import Source" header-tag="h2" class="import-source">
        <div class="form-group m-0">
          <p>
            Imports of contact data require the creation of a saved list based
            on Contacts. This view can bring in additional fields from the
            Raiser&rsquo;s Edge NXT Constituent record.
          </p>
          <label for="savedListSelect">Constituent List</label>
          <div class="w-100">
            <BFormSelect
              id="savedListSelect"
              class="w-50"
              v-model="constituentListId"
              :options="constituentLists"
              text-field="name"
              label-field="name"
              value-field="id"
              @change="listChanged($event)"
            >
              <template #first>
                <BFormSelectOption
                  value="00000000-0000-0000-0000-000000000000"
                  disabled
                >
                  Select a List
                </BFormSelectOption>
              </template>
            </BFormSelect>
          </div>
        </div>
        <div v-if="importFields.length > 0">
          <hr />
          <h3 class="mb-2 mt-4">Field Mapping</h3>
          <p>
            There is a set of required standard fields that must be mapped in
            the import. If the required standard fields are not completed, the
            import can not be saved.
          </p>
          <div>
            <h4 class="mb-0 mt-4">Required Fields</h4>
            <BListGroupItem class="d-inline-flex w-100 border-0 mb-n2 pl-0">
              <div class="col-sm-5 pl-0">
                <span class="align-middle font-weight-bold renxt-header">
                  Raiser&rsquo;s Edge NXT Fields
                </span>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-3">
                <span class="align-middle font-weight-bold pl-2">
                  Cadence Fields
                </span>
              </div>
              <div class="col-sm-3 text-right"></div>
            </BListGroupItem>
            <BListGroup class="mt-1">
              <IntegrationsFieldListGroupItem
                :field="importSettings.standardFields.contactId"
                :error="getError('contactId')"
                displayName="Contact Id"
                standard
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.importConfigModal
                  @click="
                    selectField(
                      importSettings.standardFields.contactId,
                      'Contact Id',
                      true,
                      false,
                      false,
                      getError('contactId')
                    )
                  "
                >
                  {{
                    displayNameIsSet(importSettings.standardFields.contactId)
                      ? "Edit"
                      : "Select"
                  }}
                </button>
              </IntegrationsFieldListGroupItem>

              <IntegrationsFieldListGroupItem
                :field="importSettings.standardFields.firstName"
                :error="getError('firstName')"
                displayName="First Name"
                standard
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.importConfigModal
                  @click="
                    selectField(
                      importSettings.standardFields.firstName,
                      'First Name',
                      true,
                      false,
                      false,
                      getError('firstName')
                    )
                  "
                >
                  {{
                    displayNameIsSet(importSettings.standardFields.firstName)
                      ? "Edit"
                      : "Select"
                  }}
                </button>
              </IntegrationsFieldListGroupItem>

              <IntegrationsFieldListGroupItem
                :field="importSettings.standardFields.lastName"
                :error="getError('lastName')"
                displayName="Last Name"
                standard
              >
                <button
                  id="Last Name"
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.importConfigModal
                  @click="
                    selectField(
                      importSettings.standardFields.lastName,
                      'Last Name',
                      true,
                      false,
                      false,
                      getError('lastName')
                    )
                  "
                >
                  {{
                    displayNameIsSet(importSettings.standardFields.lastName)
                      ? "Edit"
                      : "Select"
                  }}
                </button>
              </IntegrationsFieldListGroupItem>

              <IntegrationsFieldListGroupItem
                :field="importSettings.standardFields.mobileNumber"
                :error="getError('mobileNumber')"
                :selectedPhoneTypes="selectedPhoneTypes_"
                displayName="Mobile Number"
                standard
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.importConfigModal
                  @click="
                    selectField(
                      importSettings.standardFields.mobileNumber,
                      'Mobile Number',
                      true,
                      false,
                      false,
                      getError('mobileNumber')
                    )
                  "
                >
                  {{
                    displayNameIsSet(
                      importSettings.standardFields.mobileNumber
                    ) || selectedPhoneTypePreferences.length > 0
                      ? "Edit"
                      : "Select"
                  }}
                </button>
              </IntegrationsFieldListGroupItem>

              <IntegrationsFieldListGroupItem
                :field="importSettings.standardFields.modifiedOn"
                :error="getError('modifiedOn')"
                displayName="Last Modified On"
                standard
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.importConfigModal
                  @click="
                    selectField(
                      importSettings.standardFields.modifiedOn,
                      'Modified On',
                      true,
                      false,
                      false,
                      getError('modifiedOn')
                    )
                  "
                >
                  {{
                    displayNameIsSet(importSettings.standardFields.modifiedOn)
                      ? "Edit"
                      : "Select"
                  }}
                </button>
              </IntegrationsFieldListGroupItem>
            </BListGroup>
          </div>
          <div class="mt-5">
            <h4 class="mb-0">Optional Fields</h4>
            <BListGroupItem class="d-inline-flex w-100 border-0 mb-n2 pl-0">
              <div class="col-sm-5 pl-0">
                <span class="align-middle font-weight-bold renxt-header">
                  Raiser&rsquo;s Edge NXT Fields
                </span>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-2">
                <span class="align-middle font-weight-bold pl-2">
                  Cadence Fields
                </span>
              </div>
              <div class="col-sm-3 text-right"></div>
            </BListGroupItem>
            <BListGroup class="mt-1">
              <IntegrationsFieldListGroupItem
                :field="importSettings.standardFields.userId"
                :error="getError('userId')"
                displayName="User Id"
                standard
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.importConfigModal
                  @click="
                    selectField(
                      importSettings.standardFields.userId,
                      'User Id',
                      true,
                      false,
                      false,
                      getError('userId')
                    )
                  "
                >
                  {{
                    displayNameIsSet(importSettings.standardFields.userId)
                      ? "Edit"
                      : "Select"
                  }}
                </button>
                <button
                  class="btn btn-outline-danger btn-sm ml-1"
                  v-if="displayNameIsSet(importSettings.standardFields.userId)"
                  v-b-modal.removeFieldMappingModal
                  @click="
                    selectField(importSettings.standardFields.userId, 'User Id')
                  "
                >
                  Remove
                </button>
              </IntegrationsFieldListGroupItem>
              <IntegrationsFieldListGroupItem
                :field="importSettings.optOut"
                :error="getError('optedOut')"
                :fieldName="importSettings.optOut.smsLinkedCategory"
                displayName="Opted Out"
                standard
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.renxtOptOutModal
                  @click="
                    selectOptOutField(
                      importSettings.optOut,
                      getError('optedOut')
                    )
                  "
                >
                  {{
                    displayNameOptOut(importSettings.optOut) ? "Edit" : "Select"
                  }}
                </button>
                <button
                  class="btn btn-outline-danger btn-sm ml-1"
                  v-if="displayNameOptOut(importSettings.optOut)"
                  v-b-modal.removeFieldMappingModal
                  @click="selectOptOutField(importSettings.optOut)"
                >
                  Remove
                </button>
              </IntegrationsFieldListGroupItem>
            </BListGroup>
          </div>
          <div class="mt-5">
            <h4>Custom Fields</h4>
            <p class="mb-0">
              Up to 25 additional custom fields can be included in your imports.
              If you would like to create any custom fields, you may map them as
              part of the import.
            </p>
            <BListGroupItem
              class="d-inline-flex w-100 border-0 mb-n2 pl-0"
              v-if="hasCustomFields()"
            >
              <div class="col-sm-5 pl-0">
                <span class="align-middle font-weight-bold renxt-header">
                  Raiser&rsquo;s Edge NXT Fields
                </span>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-2">
                <span class="align-middle font-weight-bold pl-2">
                  Cadence Fields
                </span>
              </div>
              <div class="col-sm-3 text-right"></div>
            </BListGroupItem>
            <BListGroup class="mt-1">
              <IntegrationsFieldListGroupItem
                v-for="field in getNonRemovedCustomFields()"
                :key="field.cadenceField.id"
                :field="field"
                :error="getError(field.cadenceField.name)"
                v-bind:displayName="field.cadenceField.name"
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.importConfigModal
                  @click="selectField(field, '', false)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-outline-danger btn-sm ml-1"
                  v-b-modal.removeFieldModal
                  @click="selectField(field)"
                >
                  Remove
                </button>
              </IntegrationsFieldListGroupItem>
              <IntegrationsFieldListGroupItem
                v-for="field in addedCustomFields"
                :key="field.key"
                :field="field"
                :error="getError(field.cadenceField.name)"
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.importConfigModal
                  @click="selectField(field, '', false)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-outline-danger btn-sm ml-1"
                  @click="removeCustomField(field)"
                >
                  Remove
                </button>
              </IntegrationsFieldListGroupItem>
            </BListGroup>
            <div class="mt-3">
              <button
                class="btn btn-light"
                v-b-modal.importConfigModal
                :disabled="
                  importSettings.customFields.length +
                    addedCustomFields.length >=
                  25
                "
                @click="
                  selectField(
                    { cadenceField: { name: '' } },
                    '',
                    false,
                    false,
                    true
                  )
                "
              >
                <MgIcon name="plus-circle" />
                Add Field
              </button>
            </div>
          </div>
        </div>
      </BCard>
      <div class="container-fluid form-actions sticky-bottom">
        <button
          class="btn btn-primary"
          v-on:click="updateImportSettings()"
          :disabled="canSave()"
        >
          Save
        </button>
        <a class="btn btn-light ml-1" href="/#/integrations/renxt/imports/">
          Cancel
        </a>
      </div>
    </div>
    <RenxtImportFieldAddEditModal
      :field="selectedField.field"
      :cadenceField="selectedField.cadenceField"
      :requiredFields="selectedField.isStandard"
      :currentViewFields="importFields"
      :optOut="selectedField.isOptOut"
      :isNew="selectedField.isNew"
      :error="selectedField.error"
      :fieldDescriptor="getFieldDescriptor()"
      :phoneTypePreferences="selectedPhoneTypePreferences"
      :phoneFallbackOption="fallbackOption"
      @handleAddNewCustomField="addCustomField"
      @handleEditField="removeError"
      @handleEditPhoneField="updatePhoneTypesField"
    />
    <RenxtOptOutModal
      :optOutField="importSettings.optOut"
      :consentChannelCategories="consentChannelCategories"
      @updateOptOutField="updateOptOutField"
    />
    <IntegrationsConfirmRemoveModal
      :field="selectedField"
      @onRemove="removeCustomField"
    />
    <IntegrationsConfirmRemoveMappingModal
      :field="selectedField"
      @onRemove="clearFieldMapping"
    />
    <IntegrationsCustomFieldInUseModal
      :field="selectedField"
      :segments="segmentInUse"
    />
  </div>
</template>

<script>
import Vue from "vue";
import {
  BCard,
  BFormSelect,
  BFormSelectOption,
  BListGroup,
  BListGroupItem,
  ToastPlugin,
} from "bootstrap-vue";
import store from "@/store";
import { ACTIONS, defaultCrmField } from "../../renxtStore";
import Toast from "@/components/Toast";
import SubpageHeader from "@/components/SubpageHeader";
import { itemsForPatch } from "@/util/apiHelpers";
import IntegrationsImportScheduleCard from "@/components/IntegrationsImportScheduleCard";
import RenxtImportFieldAddEditModal from "@/pages/integrations/renxt/imports/settings/RenxtImportFieldAddEditModal";
import _ from "lodash";
import IntegrationsFieldListGroupItem from "@/components/IntegrationsFieldListGroupItem";
import IntegrationsConfirmRemoveModal from "@/components/IntegrationsConfirmRemoveModal";
import IntegrationsConfirmRemoveMappingModal from "@/components/IntegrationsConfirmRemoveMappingModal";
import RenxtOptOutModal from "@/pages/integrations/renxt/imports/settings/RenxtOptOutModal";
import api from "@/util/api";
import MgIcon from "@/components/MgIcon";
import analytics from "@/util/analytics";
import IntegrationsCustomFieldInUseModal from "@/components/IntegrationsCustomFieldInUseModal";

Vue.use(ToastPlugin);

export default {
  name: "RenxtImportSettingsPage",
  store,
  components: {
    BCard,
    BFormSelect,
    BFormSelectOption,
    BListGroup,
    BListGroupItem,
    IntegrationsConfirmRemoveMappingModal,
    IntegrationsConfirmRemoveModal,
    IntegrationsFieldListGroupItem,
    IntegrationsImportScheduleCard,
    MgIcon,
    RenxtImportFieldAddEditModal,
    RenxtOptOutModal,
    SubpageHeader,
    IntegrationsCustomFieldInUseModal,
  },
  data() {
    return {
      selectedField: {
        isStandard: false,
        isOptOut: false,
        field: {},
        cadenceField: "",
        isNew: false,
        error: {},
        currentFieldName: "",
      },
      isLoading: true,
      constituentListId: "00000000-0000-0000-0000-000000000000",
      addedCustomFields: [],
      removedCustomFields: [],
      isSavedViewUnset: true,
      errors: [],
      consentChannelCategories: [],
      segmentInUse: [],
      selectedPhoneTypePreferences: [],
      fallbackOption: "ConstituentDefaultPhone",
    };
  },
  async created() {
    try {
      await this.$store.dispatch(ACTIONS.GET_RENXT_CONFIG);
      await this.$store.dispatch(ACTIONS.GET_CRM_CONSTITUENT_LISTS);
      await this.$store.dispatch(ACTIONS.GET_CRM_IMPORT_FIELDS);

      this.consentChannelCategories = await api.get(
        "/RaisersEdgeNxt/sms-linked-categories"
      );

      this.isLoading = false;
      this.constituentListId = this.importSettings.constituentListId;
      this.selectedPhoneTypePreferences = this.importSettings.phoneTypePreferences.typePreferences;
      this.selectedPhoneTypes_ = _.cloneDeep(this.selectedPhoneTypePreferences);
      this.fallbackOption = this.importSettings.phoneTypePreferences.fallbackOption;
    } catch {
      let toastBody = this.$createElement(Toast, {
        props: {
          message: "Something went wrong.",
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "danger",
        noCloseButton: true,
        solid: true,
      });
    }
  },
  computed: {
    constituentLists() {
      return this.$store.state.renxt.crm.lists;
    },
    importSettings() {
      return _.cloneDeep(this.$store.state.renxt.importSettings);
    },
    cleanImportSettings() {
      return _.cloneDeep(this.$store.state.renxt.importSettings);
    },
    importFields() {
      return this.$store.state.renxt.crm.importFields;
    },
    orgTimezone() {
      return this.$store.state.config.timeZone;
    },
  },
  methods: {
    getFieldDescriptor() {
      switch (this.selectedField.cadenceField) {
        case "Contact Id":
          return "This is the unique identifier for the contact in Cadence. We recommend mapping the Constituent ID to this field.";
        case "User Id":
          return " User ID has a functional purpose in Cadence, as it allows you to assign sub-groups of contacts to specific users.";
        case "Modified On":
          return "We automatically map this field to ensure that updated/changed records are imported.";
        default:
          return "";
      }
    },
    canSave() {
      let settings = this.importSettings;

      if (
        !settings ||
        !settings.standardFields ||
        !settings.standardFields.contactId
      ) {
        return false;
      }

      let fields = settings.standardFields,
        phoneFieldSet =
          this.selectedPhoneTypes_.length > 0 ||
          fields.mobileNumber.crmField.id !== "";

      return !(
        fields.contactId.crmField.id !== "" &&
        fields.firstName.crmField.id !== "" &&
        fields.lastName.crmField.id !== "" &&
        phoneFieldSet &&
        fields.modifiedOn.crmField.id !== "" &&
        this.constituentListId !== "00000000-0000-0000-0000-000000000000"
      );
    },
    clearFieldMapping(field) {
      if (field.crmField) {
        field.crmField = defaultCrmField;
      } else if (field.smsLinkedCategory) {
        field.smsLinkedCategory = "";
      }
    },
    displayNameIsSet(field) {
      if (!field.crmField) {
        return false;
      }
      return field.crmField.name !== "";
    },
    displayNameOptOut(field) {
      return !!field.smsLinkedCategory;
    },
    getNonRemovedCustomFields() {
      return this.importSettings.customFields.filter((c) => {
        return !_.includes(this.removedCustomFields, c.cadenceField.id);
      });
    },
    getSelectedPhoneTypePreferences() {
      return this.selectedPhoneTypePreferences;
    },
    getError(key) {
      return _.find(this.errors, (e) => {
        return e.key === key;
      });
    },
    fieldUpdated(key, field) {
      this.removeError(key);
      this.selectedField.field = field.cadenceField;
    },
    removeError(key) {
      this.errors = _.remove(this.errors, (e) => {
        return e.key === key;
      });
    },
    updatePhoneTypesField(phoneTypeData) {
      this.selectedPhoneTypePreferences = phoneTypeData.typePreferences;
      this.fallbackOption = phoneTypeData.fallbackOption;
      this.selectedPhoneTypes_ = _.cloneDeep(phoneTypeData.typePreferences);
    },
    hasCustomFields() {
      let nonRemovedCustomFields = this.getNonRemovedCustomFields();
      return nonRemovedCustomFields.length + this.addedCustomFields.length > 0;
    },

    // List Change Methods
    async listChanged() {
      analytics.track("Changed Import Saved List", {
        integrationName: "Renxt",
      });
    },
    // Add/Edit Modal Methods
    selectField(
      field,
      cadenceField = "",
      isStandard = false,
      isOptOut = false,
      isNew = false,
      error = undefined
    ) {
      this.selectedField.isStandard = isStandard;
      this.selectedField.isOptOut = isOptOut;
      this.selectedField.cadenceField = cadenceField;
      this.selectedField.field = field;
      this.selectedField.isNew = isNew;

      if (error) {
        this.selectedField.error = error;
      } else {
        const errorMessage = this.getError(
          cadenceField === "" ? field.cadenceField.name : cadenceField
        );
        this.selectedField.error = isNew ? {} : errorMessage;
      }
    },
    selectOptOutField(field, error = undefined) {
      this.selectedField.isStandard = true;
      this.selectedField.isOptOut = true;
      this.selectedField.cadenceField = "Opted Out";
      this.selectedField.field = field;

      this.selectedField.isNew = false;
      if (error) {
        this.selectedField.error = this.getError("optedOut");
      }
    },
    addCustomField(field) {
      this.addedCustomFields.push({
        key: `${field.customField.name}-${_.random(0, 99999)}`,
        cadenceField: {
          id: 0,
          name: field.customField.displayName,
          isRequired: field.customField.isRequired,
          isVisible: field.customField.isVisible,
          canMailMerge: field.customField.canMailMerge,
        },
        crmField: field.crmField,
        crmConstituentCustomFieldCategory:
          field.crmConstituentCustomFieldCategory,
      });
      analytics.track("Added Custom Field", {
        integrationName: "Renxt",
      });
    },
    updateOptOutField(optOutField) {
      this.importSettings.optOut.smsLinkedCategory =
        optOutField.smsLinkedCategory;
      this.importSettings.optOut = optOutField;
    },
    async removeCustomField(field) {
      if (field.cadenceField.id) {
        let inUse = await api.get(
          `customfields/isinuse/${field.cadenceField.id}`
        );
        if (inUse.inUse) {
          this.segmentInUse = inUse.segmentNames;
          this.$bvModal.show("customFieldInUse");
        } else {
          this.removedCustomFields.push(field.cadenceField.id);
        }
      } else {
        this.addedCustomFields = _.remove(this.addedCustomFields, (f) => {
          return f.key !== field.key;
        });
      }

      analytics.track("Removed Custom Field", {
        integrationName: "Renxt",
      });
    },

    // Field Validation Methods
    async validateFields(configuration) {
      let standardFieldErrors = _.map(
        _.filter(_.keys(this.importSettings.standardFields), (k) => {
          if (k !== "userId")
            return this.importSettings.standardFields[k].crmField.id === "";
        }),
        //Opt-Out optional part of it goes here
        (key) => {
          return {
            type: "standardField",
            key: key,
            messages: ["Raiser's Edge NXT Field is required"],
          };
        }
      );
      if (this.selectedPhoneTypePreferences.length > 0) {
        let hasMobileError = standardFieldErrors.find(
          (err) => err.key === "mobileNumber"
        );
        if (hasMobileError) {
          standardFieldErrors = [];
        }
      }
      let validationErrors = await api.post(
        "/RaisersEdgeNxt/configuration/validate",
        configuration
      );
      return [...standardFieldErrors, ...validationErrors];
    },

    async updateImportSettings() {
      let addedFieldNameOverlap = _.pluck(
        _.filter(this.addedCustomFields, (f) => {
          let removedFields = _.filter(
            this.importSettings.customFields,
            (c) => {
              return _.includes(this.removedCustomFields, c.cadenceField.id);
            }
          );

          return _.includes(
            _.pluck(removedFields, "cadenceField.name"),
            f.cadenceField.name
          );
        }),
        "cadenceField.name"
      );

      // Question -- is the custom field exclusion duplication required?
      //   the back end should be able to take in a full list of custom fields and validate that for you
      let configurationForValidation = {
        imports: {
          constituentListId: this.constituentListId,
          phoneTypePreferences: itemsForPatch(
            this.cleanImportSettings.phoneTypePreferences,
            {
              typePreferences: this.selectedPhoneTypePreferences,
              fallbackOption: this.fallbackOption,
            }
          ),
          schedule: itemsForPatch(
            this.cleanImportSettings.schedule,
            this.importSettings.schedule
          ),
          standardFields: this.importSettings.standardFields,
          customFields: [
            ...this.importSettings.customFields.filter((c) => {
              return !_.includes(this.removedCustomFields, c.cadenceField.id);
            }),
            ...this.addedCustomFields.filter((c) => {
              return !_.includes(addedFieldNameOverlap, c.cadenceField.name);
            }),
          ],
        },
      };
      this.errors = await this.validateFields(configurationForValidation);

      // Find added Field Duplicates
      let dupAddedFields = _.filter(addedFieldNameOverlap, (c, v, i) =>
        _.includes(c, v, i + 1)
      );

      if (dupAddedFields.length > 0) {
        _.forEach(dupAddedFields, (d) => {
          this.errors.push({
            type: "customField",
            key: d,
            messages: [`Another field with the name ${d} already exists`],
          });
        });
      }

      if (this.errors.length === 0) {
        try {
          let configuration = {
            imports: {
              constituentListId: this.constituentListId,
              schedule: this.importSettings.schedule,
              standardFields: this.importSettings.standardFields,
              optOut: this.importSettings.optOut,
              phoneTypePreferences: {
                typePreferences: this.selectedPhoneTypePreferences,
                fallbackOption: this.fallbackOption,
              },
              customFields: [
                ...this.importSettings.customFields.filter((c) => {
                  return !_.includes(
                    this.removedCustomFields,
                    c.cadenceField.id
                  );
                }),
                ...this.addedCustomFields,
              ],
            },
          };

          await this.$store.dispatch(
            ACTIONS.UPDATE_RENXT_CONFIG,
            configuration
          );

          this.errors = [];
          this.addedCustomFields = [];
          this.removedCustomFields = [];

          let toastBody = this.$createElement(Toast, {
            props: {
              message: "Import Settings Updated",
            },
          });
          this.$bvToast.toast([toastBody], {
            variant: "success",
            noCloseButton: true,
            solid: true,
          });

          analytics.track("Updated Import Settings", {
            integrationName: "Renxt",
          });
        } catch (error) {
          let toastBody = this.$createElement(Toast, {
            props: {
              message: "There was an error saving Import Settings.",
            },
          });
          this.$bvToast.toast([toastBody], {
            variant: "danger",
            noCloseButton: true,
            solid: true,
          });
        }
      } else {
        let fieldErrorsContainer = document.querySelector(
          ".field-errors-container"
        );
        if (fieldErrorsContainer) {
          setTimeout(function () {
            fieldErrorsContainer.scrollIntoView({ behavior: "smooth" });
          }, 500);
        }
      }
    },
  },
  watch: {
    removedCustomFields() {
      this.getNonRemovedCustomFields();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.renxt-import-page-content {
  padding: 20px 30px 0;
}

.renxt-header {
  margin-left: 22px;
}

.import-source {
  margin-top: 2em;
}
</style>
