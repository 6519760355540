<template>
  <div class="criteria">
    <div class="form-inline">
      <div class="my-1">
        Contacts meet
        <b-form-select
          v-model="mutableConjunction"
          aria-label="Conjunction"
          :disabled="readonly"
        >
          <b-form-select-option value="all">ALL</b-form-select-option>
          <b-form-select-option value="any">ANY</b-form-select-option>
        </b-form-select>
        of the following conditions:
      </div>
    </div>
    <div
      v-for="(item, index) in mutableConditions"
      :key="index"
      class="form-row align-items-center criterion"
    >
      <div
        v-show="mutableConditions.length > 1"
        class="col-auto conjunction-label align-items-center"
      >
        <div v-show="index > 0">
          {{ mutableConjunction == "all" ? "and" : "or" }}
        </div>
      </div>
      <div class="col-sm-3">
        <b-form-select
          class="field-selector"
          v-model="item.field"
          @change="dataTypeChange(item, index)"
          :options="groupedFields"
          aria-label="Field"
          :disabled="readonly"
          required
        >
          <template v-slot:first>
            <b-form-select-option :value="null"
              >Select Field</b-form-select-option
            >
          </template>
        </b-form-select>
      </div>
      <div v-if="item.field" class="col-sm-3">
        <b-form-select
          class="comparison-select"
          v-model="item.comparison"
          :disabled="!item.field || readonly"
          aria-label="Comparison"
        >
          <b-form-select-option
            v-for="comparison in filteredComparisons(item.field)"
            :key="comparison.value"
            :value="comparison"
            >{{
              item.field && item.field.isDateField
                ? comparison.dateText
                : comparison.text
            }}</b-form-select-option
          >
        </b-form-select>
      </div>
      <div v-if="showFieldValueInput(item)" class="col w-25">
        <div v-show="showTextInput(item)">
          <input
            v-model="item.value"
            type="text"
            class="form-control"
            :disabled="!item.field || readonly"
            maxlength="100"
            aria-label="Field Value"
            required
            v-b-tooltip.hover.right="'Max 100 characters'"
          />
        </div>
        <div v-if="showDatePicker(item)" class="date-picker-input">
          <DatePicker
            :selected-date-value="item.value"
            v-show="!readonly"
            v-on:date-picked="updateDateValue(index, ...arguments)"
            :showFeedback="false"
            mode="single"
          />
          <input
            v-show="readonly"
            v-model="item.value"
            type="text"
            class="form-control"
            :disabled="!item.field || readonly"
            maxlength="100"
            aria-label="Field Value"
            required
          />
        </div>
        <div v-if="showTagPicker(item)">
          <TagPicker v-model="item.value" :disabled="readonly" />
        </div>
      </div>
      <div class="col-auto">
        <button
          v-show="mutableConditions.length > 1 && !readonly"
          @click="remove(index)"
          type="button"
          class="btn remove"
          aria-label="Remove Condition"
        >
          <MgIcon name="times" class="text-danger" />
        </button>
      </div>
    </div>
    <div class="d-flex actions">
      <span
        v-show="mutableConditions.length > 1"
        class="conjunction-label"
      ></span>
      <button
        v-show="!readonly"
        @click="add"
        :disabled="criteriaInvalid"
        type="button"
        class="btn btn-link"
      >
        <MgIcon name="plus"></MgIcon> Add Condition
      </button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import api from "@/util/api";
import _ from "lodash";
import { FormSelectPlugin, VBTooltip } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";
import { COMPARISONS } from "@/util/constants";
import DatePicker from "@/components/DatePicker";
import TagPicker from "@/pages/tags/TagPicker";

Vue.use(FormSelectPlugin);
Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "SegmentCriteria",
  props: {
    conditions: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    conjunction: {
      type: String,
      required: false,
      default: () => {
        return "all";
      },
    },
    fields: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
  },
  components: {
    DatePicker,
    MgIcon,
    TagPicker,
  },
  data() {
    return {
      comparisons: [
        {
          text: "Equals",
          dateText: "Is On",
          value: COMPARISONS.EQUALS,
          isDateOption: true,
        },
        {
          text: "Does Not Equal",
          dateText: "Is Not On",
          value: COMPARISONS.NOT_EQUALS,
          isDateOption: true,
        },
        {
          text: "Starts With",
          value: COMPARISONS.STARTS_WITH,
        },
        {
          text: "Is Less Than or Equal To",
          dateText: "Is Before or On",
          value: COMPARISONS.IS_LESS_THAN,
          isDateOption: true,
        },
        {
          text: "Is Greater Than or Equal To",
          dateText: "Is On or After",
          value: COMPARISONS.IS_GREATER_THAN,
          isDateOption: true,
        },
        {
          text: "Is Empty",
          dateText: "Is Empty",
          value: COMPARISONS.IS_EMPTY,
          isDateOption: true,
        },
        {
          text: "Is Not Empty",
          dateText: "Is Not Empty",
          value: COMPARISONS.IS_NOT_EMPTY,
          isDateOption: true,
        },
        {
          text: "Equals",
          value: COMPARISONS.WITH,
          isTagOption: true,
        },
        {
          text: "Does Not Equal",
          value: COMPARISONS.WITHOUT,
          isTagOption: true,
        },
      ],
      mutableConditions: [...this.conditions],
      mutableConjunction: this.conjunction,
      groupedFields: null,
    };
  },
  computed: {
    criteriaInvalid() {
      return this.mutableConditions.some((c) => {
        if (!c.field || !c.comparison) return true;
        if (
          c.comparison.value !== COMPARISONS.IS_EMPTY &&
          c.comparison.value !== COMPARISONS.IS_NOT_EMPTY
        ) {
          return !c.value;
        }
      });
    },
  },
  mounted() {
    let fields = _.map(this.fields, (field) => {
      return {
        value: field,
        text: field.displayName,
      };
    });

    this.groupedFields = [
      {
        label: "Cadence Fields",
        options: _.filter(fields, (f) => {
          return f.value.isSystemField;
        }),
      },
      {
        label: "Custom Fields",
        options: _.filter(fields, (f) => {
          return !f.value.isSystemField;
        }),
      },
    ];

    if (this.mutableConditions.length === 0) {
      this.add();
    } else {
      this.mutableConditions = _.forEach(this.mutableConditions, (c) => {
        if (!c.field) return c;
        if (typeof c.field === "number") {
          c.field = fields.find((f) => f.value.id === c.field).value;
        }
        if (c.field.searchField === "Tags" && typeof c.value === "number") {
          this.getTagById(c.value).then((res) => {
            c.value = res;
          });
        }
        if (typeof c.comparison === "string") {
          c.comparison = this.comparisons.find(
            (comparison) => comparison.value === c.comparison
          );
        } else {
          c.comparison = this.comparisons.find(
            (comparison) => comparison.value === c.comparison.value
          );
        }
      });
    }
  },
  methods: {
    add() {
      const defaultCondition = {
        field: null,
        comparison: null,
        value: "",
      };
      this.mutableConditions.push(defaultCondition);
    },
    filteredComparisons(field) {
      if (!field) return null;
      if (field.searchField === "Tags") {
        return this.comparisons.filter((c) => c.isTagOption);
      }
      if (field.isDateField) {
        return this.comparisons.filter((c) => c.isDateOption);
      }
      return this.comparisons.filter((c) => !c.isTagOption);
    },
    getTagById(id) {
      return api.get(`/tags/${id}`);
    },
    onUpdate() {
      this.$emit("update", {
        conditions: this.mutableConditions,
        conjunction: this.mutableConjunction,
        criteriaInvalid: this.criteriaInvalid,
      });
    },
    remove(index) {
      this.mutableConditions.splice(index, 1);
    },
    showDatePicker(item) {
      return item.field.isDateField;
    },
    showTagPicker(item) {
      return item.field.searchField === "Tags";
    },
    showFieldValueInput(item) {
      return (
        item.field &&
        item.comparison &&
        item.comparison.value !== COMPARISONS.IS_EMPTY &&
        item.comparison.value !== COMPARISONS.IS_NOT_EMPTY
      );
    },
    showTextInput(item) {
      return (
        item.field &&
        !item.field.isDateField &&
        item.field.searchField !== "Tags"
      );
    },
    updateDateValue(index, value) {
      if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(value) && value !== "") {
        this.mutableConditions[index].value = value
          ? value.toLocaleDateString()
          : null;
      } else {
        this.mutableConditions[index].value = value;
      }
    },
    dataTypeChange(item, index) {
      if (!this.filteredComparisons(item.field)) return;
      const comparison = this.filteredComparisons(item.field)[0];

      this.mutableConditions[index].comparison = comparison;
      this.mutableConditions[index].value = "";
    },
  },
  watch: {
    mutableConditions: {
      handler: "onUpdate",
      deep: true,
    },
    mutableConjunction: {
      handler: "onUpdate",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";

.criteria {
  background: $light;
  padding: 15px 20px;
}

.criterion {
  margin: 15px 0;

  select {
    margin-right: 10px;
  }
}

.conjunction-label {
  font-size: $font-size-sm;
  margin-right: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 2.5em;
  max-width: 25%;
}

.actions {
  margin-left: -12px;
}

.remove {
  margin-left: -5px;
}

.field-selector {
  //width: 25%;
}

.comparison-select {
  //width: 25%;
}

@media (max-width: 575px) {
  .criteria {
    margin-top: 50px;
  }
}
</style>
