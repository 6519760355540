import api from "@/util/api";
import moment from "moment";
import "moment-timezone";
import { ACTIONS, MUTATIONS, GETTERS, INBOX_TYPES } from "@/util/constants";
import _ from "lodash";

export default {
  state: () => ({
    counts: [],
    messages: null,
    currentPage: 1,
    skip: 0,
    take: 25,
    selectedInbox: { type: "team", name: "Team" },
    paginationMeta: {},
    staticSegments: [],
    autoUpdateSegments: [],
    automatedSegments: [],
    selectedCalendarDate: {
      date: null,
      month: null,
      year: null,
      inboxType: "team",
    },
    selectedDate: {
      day: null,
      month: null,
      year: null,
      inboxType: "team",
    },
    day: null,
    month: null,
    year: null,
    calendarMonth: null,
    calendarYear: null,
  }),
  actions: {
    async [ACTIONS.GET_SCHEDULED_MESSAGES]({ commit, state }, params) {
      const filterType =
        params.inboxType === INBOX_TYPES.GROUP
          ? "sharedInbox"
          : params.inboxType;
      const groupId = params.groupId ? `&groupAccountId=${params.groupId}` : "";
      const response = await api.get(
        `/scheduledmessages/messages?scheduledMessageFilter=${filterType}&month=${params.month}&year=${params.year}&day=${params.day}${groupId}&skip=${state.skip}&take=${state.take}&includeDeletedSmartMessages=true`
      );
      commit(MUTATIONS.SET_SCHEDULED_MESSAGES, response.messages);
      commit(MUTATIONS.SET_PAGINATION_META, response.meta);
    },
    async [ACTIONS.GET_SCHEDULED_MESSAGES_COUNTS]({ commit }, params) {
      const filterType =
        params.inboxType === INBOX_TYPES.GROUP
          ? "sharedInbox"
          : params.inboxType;
      const groupId = params.groupId ? `&groupAccountId=${params.groupId}` : "";
      const scheduledCount = await api.get(
        `/scheduledmessages/counts?scheduledMessageFilter=${filterType}&month=${params.month}&year=${params.year}${groupId}`
      );
      commit(MUTATIONS.SET_SCHEDULED_MESSAGES_COUNTS, scheduledCount);
    },
    async [ACTIONS.SKIP_PAGE]({ commit, dispatch, state }, pageNum) {
      commit(MUTATIONS.SET_NEXT_PAGE, pageNum);
      commit(MUTATIONS.SET_CURRENT_PAGE, pageNum);
      dispatch(ACTIONS.GET_SCHEDULED_MESSAGES, state.selectedDate);
    },
    async [ACTIONS.RESET_PAGINATION]({ commit }) {
      commit(MUTATIONS.RESET_PAGINATION);
    },
    async [ACTIONS.UPDATE_SELECTED_INBOX]({ commit, dispatch }, inbox) {
      commit(MUTATIONS.SET_SELECTED_INBOX, inbox);
      dispatch(ACTIONS.UPDATE_SELECTED_DAY);
      dispatch(ACTIONS.UPDATE_SELECTED_CALENDAR_DAY);
    },
    [ACTIONS.UNSCHEDULE_MESSAGE]({ commit, state }, id) {
      let updatedMessages = state.messages.filter(
        (message) => message.id !== id
      );
      commit(MUTATIONS.SET_SCHEDULED_MESSAGES, updatedMessages);
    },
    async [ACTIONS.GET_SEGMENTS]({ commit }) {
      const staticSegments = await api.get("staticsegments");
      const autoSegments = await api.get("autoUpdateSegments");
      const automatedSegments = await api.get("segments/automated/all");
      let segments = { staticSegments, autoSegments, automatedSegments };
      commit(MUTATIONS.SET_SEGMENTS, segments);
    },
    [ACTIONS.UPDATE_SELECTED_CALENDAR_DAY]({ commit, dispatch }) {
      commit(MUTATIONS.SET_SELECTED_CALENDAR_DAY);
      dispatch(ACTIONS.RESET_PAGINATION);
    },
    [ACTIONS.UPDATE_SELECTED_DAY]({ commit, dispatch }) {
      commit(MUTATIONS.SET_SELECTED_DAY);
      dispatch(ACTIONS.RESET_PAGINATION);
    },
    [ACTIONS.UPDATE_SCHEDULED_DAY]({ commit }, day) {
      commit(MUTATIONS.SET_SCHEDULED_DAY, day);
    },
    [ACTIONS.UPDATE_SCHEDULED_MONTH]({ commit }, month) {
      commit(MUTATIONS.SET_SCHEDULED_MONTH, month);
    },
    [ACTIONS.UPDATE_SCHEDULED_YEAR]({ commit }, year) {
      commit(MUTATIONS.SET_SCHEDULED_YEAR, year);
    },
    [ACTIONS.UPDATE_SCHEDULED_CALENDAR_MONTH]({ commit }, month) {
      commit(MUTATIONS.SET_SCHEDULED_CALENDAR_MONTH, month);
    },
    [ACTIONS.UPDATE_SCHEDULED_CALENDAR_YEAR]({ commit }, year) {
      commit(MUTATIONS.SET_SCHEDULED_CALENDAR_YEAR, year);
    },
  },
  mutations: {
    [MUTATIONS.SET_SCHEDULED_MESSAGES](state, value) {
      state.messages = value;
    },
    [MUTATIONS.SET_SCHEDULED_MESSAGES_COUNTS](state, value) {
      state.counts = value;
    },
    [MUTATIONS.SET_NEXT_PAGE](state, value) {
      state.skip = (value - 1) * state.take;
    },
    [MUTATIONS.SET_CURRENT_PAGE](state, value) {
      state.currentPage = value;
    },
    [MUTATIONS.RESET_PAGINATION](state) {
      state.currentPage = 1;
      state.skip = 0;
    },
    [MUTATIONS.SET_SELECTED_INBOX](state, value) {
      state.selectedInbox = value;
    },
    [MUTATIONS.SET_PAGINATION_META](state, value) {
      state.paginationMeta = value;
    },
    [MUTATIONS.SET_SEGMENTS](state, value) {
      state.staticSegments = value.staticSegments;
      state.autoUpdateSegments = value.autoSegments;
      state.automatedSegments = value.automatedSegments;
    },
    [MUTATIONS.SET_SELECTED_DAY](state) {
      state.selectedDate = {
        day: state.day,
        month: state.month,
        year: state.year,
        groupId: state.selectedInbox.groupId,
        inboxType: state.selectedInbox.type,
      };
    },
    [MUTATIONS.SET_SELECTED_CALENDAR_DAY](state) {
      state.selectedCalendarDate = {
        day: state.day,
        month: state.calendarMonth,
        year: state.calendarYear,
        groupId: state.selectedInbox.groupId,
        inboxType: state.selectedInbox.type,
      };
    },
    [MUTATIONS.SET_SCHEDULED_DAY](state, day) {
      state.day = day;
    },
    [MUTATIONS.SET_SCHEDULED_MONTH](state, month) {
      state.month = month;
    },
    [MUTATIONS.SET_SCHEDULED_YEAR](state, year) {
      state.year = year;
    },
    [MUTATIONS.SET_SCHEDULED_CALENDAR_MONTH](state, month) {
      state.calendarMonth = month;
    },
    [MUTATIONS.SET_SCHEDULED_CALENDAR_YEAR](state, year) {
      state.calendarYear = year;
    },
  },
  getters: {
    [GETTERS.SCHEDULED_MESSAGES_GROUPED_BY_DATE]: (
      state,
      getters,
      rootState
    ) => {
      let messages = state.messages;

      if (!messages) {
        return {};
      }

      return _.chain(messages)
        .sortBy("scheduleDate")
        .map((m) => {
          m.day = moment
            .tz(moment.utc(m.scheduleDate), rootState.config.timeZone)
            .format("MM/DD/YYYY");
          return m;
        })
        .groupBy("day")
        .value();
    },
  },
};
