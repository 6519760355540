<template>
  <div class="conversation-item-component">
    <div
      v-if="selectedConversationId"
      class="conversation-item d-flex flex-column flex-fill"
    >
      <div
        class="conversation-item-toolbar d-flex align-items-center py-3 px-4"
      >
        <div class="back-arrow">
          <a
            class="p-2 mr-2 ml-n2"
            aria-label="Back to Messages"
            role="button"
            @click="goBack()"
          >
            <MgIcon name="arrow-left"></MgIcon>
          </a>
        </div>
        <div class="flex-fill">
          <div v-show="contactDisplayName">
            <h2 class="h4 m-0 font-weight-normal">{{ contactDisplayName }}</h2>
            <small>Last message {{ lastMessageTimeAgo }}</small>
          </div>
        </div>
        <div v-if="conversation" class="d-flex align-items-center">
          <a
            v-if="conversation.archived === false && !lockedByOtherUser"
            @click.prevent="archive(true)"
            href
            role="button"
            class="btn-icon"
            v-b-tooltip="{ title: 'Archive', delay: { show: 500, hide: 50 } }"
          >
            <MgIcon name="archive" scale="1.1"></MgIcon>
          </a>
          <a
            v-if="conversation.archived === true && !lockedByOtherUser"
            @click.prevent="archive(false)"
            href
            role="button"
            class="btn-icon unarchive"
            v-b-tooltip="{
              title: 'Move to Inbox',
              delay: { show: 500, hide: 50 },
            }"
          >
            <MgIcon name="unarchive" scale="1.3"></MgIcon>
          </a>
          <a
            v-if="conversation.unread === true && !lockedByOtherUser"
            @click.prevent="markAsRead(true)"
            href
            role="button"
            class="btn-icon unread"
            v-b-tooltip="{
              title: 'Mark as Read',
              delay: { show: 500, hide: 50 },
            }"
          >
            <MgIcon name="envelope-open" scale="1.15"></MgIcon>
          </a>
          <a
            v-if="conversation.unread === false && !lockedByOtherUser"
            @click.prevent="conversation.archived || markAsRead(false)"
            href
            role="button"
            class="btn-icon"
            :class="{ disabled: conversation.archived }"
            v-b-tooltip="{
              title: 'Mark as Unread',
              delay: { show: 500, hide: 50 },
            }"
            :disabled="conversation.archived"
          >
            <MgIcon name="envelope" scale="1.2"></MgIcon>
          </a>
          <a
            href
            role="button"
            class="btn-icon"
            @click.prevent="toggleContactCard()"
            v-b-tooltip="{
              id: 'contact-details',
              title: 'Contact Details',
              delay: { show: 500, hide: 50 },
            }"
          >
            <MgIcon name="contact-card" scale="1.2"></MgIcon>
          </a>
        </div>
      </div>
      <div
        class="conversation-messages py-3"
        :class="{ 'contact-card-open': showContactCard }"
      >
        <div class="d-flex flex-column">
          <Message
            v-for="item in messages"
            :item="item"
            :key="item.messageId"
          />
        </div>
        <div id="cadence-ai-container"></div>
        <div id="bottom"></div>
      </div>
      <LockModal v-if="showLockModal" :conversation="conversation"></LockModal>
    </div>
    <div
      v-if="showEmptyState"
      class="d-flex flex-column justify-content-center h-100"
    >
      <div class="empty">
        <h3>Select a message to read</h3>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "../../store";
import {
  ACTIONS,
  GETTERS,
  INBOX_TYPES,
  MESSAGE_DIRECTIONS,
} from "@/util/constants";
import "@/util/filters";
import * as firestore from "@/util/firestore";
import { VBTooltip, ModalPlugin } from "bootstrap-vue";
import Message from "@/components/Message";
import MgIcon from "@/components/MgIcon";
import LockModal from "./LockModal";
import eventBus from "@/util/eventBus";
import analytics from "@/util/analytics";

Vue.use(ModalPlugin);
Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "ConversationItem",
  store,
  components: {
    LockModal,
    Message,
    MgIcon,
  },
  data() {
    return {
      timeRemaining: null,
    };
  },
  props: {
    conversationId: {
      required: true,
    },
  },
  computed: {
    contact() {
      return this.$store.state.contact.details;
    },
    conversation() {
      return this.$store.state.inbox.selectedConversation;
    },
    contactDisplayName() {
      return this.$store.getters[GETTERS.CONTACT_DISPLAY_NAME];
    },
    lastMessageTimeAgo() {
      return this.$store.getters[GETTERS.GET_INBOX_LAST_MESSAGE_TIME_AGO];
    },
    messages() {
      return this.$store.state.inbox.selectedConversationMessages;
    },
    messageCount() {
      return this.$store.getters[GETTERS.MESSAGE_COUNT];
    },
    selectedConversationId() {
      return this.$store.state.inbox.selectedConversationId;
    },
    showContactCard() {
      return (
        this.$store.state.showContactCard &&
        this.$store.state.inbox.selectedConversationId
      );
    },
    showEmptyState() {
      return (
        this.$store.state.inbox.conversationsLoaded &&
        !this.$store.getters[GETTERS.NO_CONVERSATIONS] &&
        !this.$store.state.inbox.selectedConversationId
      );
    },
    showLockModal() {
      return this.$store.state.inbox.activeInbox.type !== INBOX_TYPES.PERSONAL;
    },
    userId() {
      return this.$store.state.config.userId;
    },
    lockedByOtherUser() {
      return this.conversation.state.locked &&
        this.conversation.state.lockedForStaffId !== this.userId
        ? true
        : false;
    },
  },
  created() {
    this.$store.dispatch(ACTIONS.SELECT_CONVERSATION, this.conversationId);
    firestore.onAuth().then(this.get);
  },
  destroyed() {
    this.$store.dispatch(ACTIONS.RESET_CONVERSATION);
  },
  methods: {
    archive(value) {
      this.$store.dispatch(ACTIONS.ARCHIVE_CONVERSATION, [
        value,
        [this.selectedConversationId],
      ]);
      this.selectNext();
      analytics.track(
        (value ? "Archived" : "Unarchived") + " from Conversation",
        {
          "Inbox Name": this.$store.state.inbox.activeInbox.name,
          "User Id": this.userId,
          "Contact Name": this.contact.firstName + " " + this.contact.lastName,
          Caller: "Conversation View",
        }
      );
    },
    get() {
      let id = this.conversationId;

      if (id) {
        this.$store.dispatch(ACTIONS.GET_CONTACT, { id: id });
        this.$store.dispatch(ACTIONS.BIND_CONVERSATION).then((conversation) => {
          if (conversation && conversation.unread) {
            this.markAsRead(true, true);
          }
        });
      } else {
        this.$store.dispatch(ACTIONS.RESET_CONVERSATION);
      }
    },
    goBack() {
      this.$store.dispatch(ACTIONS.RESET_CONVERSATION);
    },
    markAsRead(value, autoRead) {
      this.$store.dispatch(ACTIONS.MARK_AS_READ_CONVERSATION, [
        value,
        [this.selectedConversationId],
      ]);
      if (!value) {
        this.$store.dispatch(
          ACTIONS.UPDATE_SELECTED_CONVERSATION_MANUALLY_UNREAD,
          true
        );
      }
      if (!autoRead) {
        analytics.track(
          "Marked as " + (value ? "Read" : "Unread") + " from Conversation",
          {
            "Inbox Name": this.$store.state.inbox.activeInbox.name,
            "User Id": this.userId,
            "Contact Name":
              this.contact.firstName + " " + this.contact.lastName,
            Caller: "Conversation View",
          }
        );
      }
    },
    toggleContactCard() {
      this.$store.dispatch(
        ACTIONS.SHOW_CONTACT_CARD,
        !this.$store.state.showContactCard
      );
      this.$root.$emit("bv::hide::tooltip", "contact-details");
      analytics.track("Clicked View Contact Details from Conversation", {
        "Inbox Name": this.$store.state.inbox.activeInbox.name,
        "User Id": this.userId,
        "Contact Name": this.contact.firstName + " " + this.contact.lastName,
        Caller: "Conversation View",
      });
    },
    selectNext() {
      this.$store.dispatch(ACTIONS.SELECT_NEXT_CONVERSATION);
    },
  },
  watch: {
    conversationId() {
      this.get();
    },
    conversation(conversation) {
      if (conversation && conversation.state) {
        conversation.state.lockedForStaff = this.$store.getters[
          GETTERS.USER_NAME_BY_ID
        ](conversation.state.lockedForStaffId);
        this.$emit("locked-state", conversation.state);
      }
    },
    messageCount(newCount, oldCount) {
      const newMessageAdded = newCount === oldCount + 1;
      const initialLoad = !newMessageAdded && newCount > 0;

      if (initialLoad) {
        const jumpToBottom = () =>
          this.$el.querySelector("#bottom").scrollIntoView(false);
        return setTimeout(jumpToBottom);
      }
      if (newMessageAdded) {
        const isIncoming =
          this.messages[this.messages.length - 1].direction ===
          MESSAGE_DIRECTIONS.INCOMING;
        const scrollToBottom = () =>
          this.$el
            .querySelector("#bottom")
            .scrollIntoView({ behavior: "smooth", block: "nearest" });
        setTimeout(scrollToBottom);
        if (isIncoming) {
          this.$store.dispatch(ACTIONS.GET_CONTACT, {
            id: this.conversationId,
            disableLoading: true,
          });
          eventBus.dispatchEvent("updateContact");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.conversation-item-component {
  height: 100%;
}

.conversation-item {
  height: 100%;
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

.conversation-item-toolbar {
  background: $gray-light-bg;
  height: 74px;

  small {
    font-weight: $font-weight-light;
  }

  .back-arrow {
    color: $accent;
    cursor: pointer;
    @media (min-width: $screen-md) {
      display: none;
    }
  }

  .btn-icon {
    margin: 0 2px;
    opacity: 0.9;
    padding: 4px 8px;
    transition: 150ms ease-in-out;

    &:hover {
      opacity: 1;
      transform: scale(1.15);
    }

    &.disabled {
      cursor: default;
      opacity: 0.5;

      &:hover {
        transform: none;
      }
    }

    .icon {
      display: flex;
    }

    &.unread {
      margin-top: -2px;
    }

    &.unarchive {
      margin-top: -2px;
    }
  }
}

.conversation-messages {
  overflow: auto;
}

.conversation-message {
  position: relative;
  width: 90%;

  &:hover {
    ::v-deep .add-campaign {
      opacity: 1;
    }
  }

  &.pending {
    .message-bubble {
      opacity: 0.5;
    }
    .message-meta-source {
      font-style: italic;
      font-weight: 400;
    }
  }

  &.media-with-text {
    .message-media {
      margin-top: $line-height-computed/2;
    }
  }

  @include media-breakpoint-up(md) {
    width: 70%;

    .contact-card-open & {
      width: 80%;
    }
  }
}

.message-bubble {
  background: #f2f2f3;
  border-radius: 12px 12px 12px 0;
  color: $text-color;
  position: relative;
  transition: opacity 200ms ease-out;

  &:after {
    border-color: transparent transparent #f0f0f0 transparent;
    border-width: 0 0 10px 10px;
    border-style: solid;
    bottom: 0;
    content: "";
    left: -10px;
    height: 0;
    position: absolute;
    width: 0;
  }

  .outgoing & {
    background: #233c48;
    border-radius: 12px 12px 0 12px;
    color: #fff;
    text-align: right;

    &:after {
      border-color: transparent transparent transparent #233c48;
      border-width: 10px 0 0 10px;
      left: auto;
      right: -10px;
    }
  }
}

// .campaign-picker {
//     margin: 2px -10px 0 .5rem;

//     .outgoing & {
//         margin: 2px .5rem 0 -10px;
//     }
// }

.empty {
  color: $text-muted;
  text-align: center;
}
</style>

<style lang="scss">
.message-bubble {
  img.emoji {
    height: 22px;
    margin: -4px 0;
    width: auto;
    vertical-align: middle;
  }
}

#cadence-ai-container {
  scroll-margin-bottom: 2rem;
}
</style>
