var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ResponsiveContainer',{attrs:{"tag":null,"breakpoints":{ small: 576 }},scopedSlots:_vm._u([{key:"default",fn:function(container){return [_c('div',{staticClass:"d-flex flex-column h-100",attrs:{"id":"webChatPage"}},[_c('PageHeader',{staticClass:"d-flex page-header",attrs:{"headerTitle":"Chat","tipId":"c02febf9-9fc2-4025-af40-58557d8fa55f","tipEvents":{
          flow_completed: _vm.handleAcceptNotifications,
        }}}),_c('div',{staticClass:"d-flex flex-row web-chat-content h-100"},[_c('div',{staticClass:"conversation-list-pane",class:{
            'show-mobile-conversation-view': _vm.showMobileConversationView,
            'hide-mobile-conversation-view': !_vm.showMobileConversationView,
          }},[_c('WebChatControls',{staticClass:"d-flex",attrs:{"filters":_vm.filters}}),_c('WebChatConversationList',{staticClass:"webChatConversationList",attrs:{"selectedConversationId":_vm.selectedConversationId,"conversations":_vm.conversations}})],1),_c('WebChatConversation',{staticClass:"conversation-pane",class:{
            'details-expanded': _vm.showVisitorDetails_,
            'details-collapsed': !_vm.showVisitorDetails_,
            'show-mobile-conversation-view': _vm.showMobileConversationView,
            'hide-mobile-conversation-view': !_vm.showMobileConversationView,
          },attrs:{"botId":_vm.selectedBotId,"conversation":_vm.selectedConversation,"templateList":_vm.templateList,"messages":_vm.selectedMessages,"showVisitorDetails":_vm.showVisitorDetails_,"isMobileView":container.size <= container.breakpoints.small}}),(_vm.showVisitorDetails_)?_c('WebChatVisitorDetails',{staticClass:"visitor-details-pane",attrs:{"conversation":_vm.selectedConversation}}):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }