(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("_"), require("Vue"), require("firebase"), require("Appcues"));
	else if(typeof define === 'function' && define.amd)
		define(["_", "Vue", "firebase", "Appcues"], factory);
	else if(typeof exports === 'object')
		exports["cadence"] = factory(require("_"), require("Vue"), require("firebase"), require("Appcues"));
	else
		root["cadence"] = factory(root["_"], root["Vue"], root["firebase"], root["Appcues"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__60bb__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_a4d6__, __WEBPACK_EXTERNAL_MODULE_b905__) {
return 