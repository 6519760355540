<template>
  <div>
    <Fade
      v-if="fade"
      :appear="appear"
      :direction="direction"
      :transition-enter-duration="transitionEnterDuration"
    >
      <slot />
    </Fade>
    <slot v-else />
  </div>
</template>
<script>
import { Fade } from "@progress/kendo-vue-animation";

export default {
  name: "MgFade",
  components: { Fade },
  props: {
    appear: {
      type: Boolean,
      required: true,
    },
    fade: {
      type: Boolean,
      required: false,
      default: true,
    },
    direction: {
      type: String,
      required: false,
      default: "in",
    },
    transitionEnterDuration: {
      type: Number,
      required: false,
      default: 300,
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .k-animation-container {
  z-index: 0;
}
</style>
