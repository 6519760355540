<template>
  <div
    id="webChatConversationHeader"
    class="d-flex flex-row"
    data-testid="web-chat-conversation-header"
  >
    <div class="back-button" @click="returnToConversationListOnMobile()">
      <MgIcon name="arrow-left"></MgIcon>
    </div>
    <div class="d-flex flex-row header-details">
      <div class="d-flex flex-column mr-auto visitor-details">
        <div class="h3 truncate">
          <strong v-if="visitorName" v-html="visitorName"></strong>
          <span v-if="visitorLocation"
            >&nbsp;in&nbsp;<strong v-html="visitorLocation"></strong
          ></span>
        </div>
        <div class="subtitle truncate">
          <small v-if="conversation"
            >Active {{ lastMessageSentOn | fromNow }}</small
          >
        </div>
      </div>
      <div class="d-flex align-items-center controls">
        <MgLink
          v-if="conversation && !isClosed"
          class="btn-icon close-webchat-conversation-control"
          :value="{ conversationId }"
          eventName="click"
          @click="handleCloseConversation"
          alt="End Conversation"
          title="End Conversation"
        >
          <MgIcon name="comment-xmark" data-testid="close-icon" scale="1.25" />
        </MgLink>
        <MgLink
          v-if="isFollowUp"
          class="btn-icon complete-follow-up-control"
          :value="{ conversationId }"
          eventName="click"
          @click="handleCompleteFollowUp"
          alt="Complete Follow up"
          title="Complete Follow up"
        >
          <MgIcon
            name="new-user-check"
            data-testid="followup-icon"
            scale="1.36"
          />
        </MgLink>
        <MgLink
          v-if="canSMSFromChat && hasInboxes"
          class="btn-icon sms-from-chat-control"
          alt="Send Text Message"
          title="Send Text Message"
          eventName="click"
          @click="handleSMSIconClick"
          :class="{
            disabled: visitorTextOptIn !== 'Yes',
          }"
          href
          data-testid="text-from-chat-link"
        >
          <MgIcon name="message-sms" scale="1.2" />
        </MgLink>
        <WebChatAssignmentDropdown
          v-if="canBeAssigned"
          :botId="botId"
          :conversationId="conversationId"
          :assignedStaffId="assignedStaffId"
          :isFollowUp="isFollowUp"
          class="assign-webchat-conversation-control"
          :class="{
            disabled: !teamOwnsConversation,
          }"
        />
        <MgLink
          class="btn-icon details-control"
          :value="{ value: 'toggle-webchat-visitor-details' }"
          globalEventName="toggle-webchat-visitor-details"
          alt="Visitor Details"
          title="Visitor Details"
          :class="{ highlighted: showVisitorDetails }"
        >
          <MgIcon name="contact-card-no-opacity" scale="1.2" />
        </MgLink>
        <MgLink
          v-if="isDeleteFFEnabled && chatAdmin"
          class="btn-icon delete-conversation-control"
          alt="Delete Conversation"
          title="Delete Conversation"
          eventName="click"
          @click="handleDeleteClick"
          data-testid="delete-conversation-from-header"
        >
          <MgIcon name="trash-can" scale="1.2"/>
        </MgLink>
      </div>
    </div>
  </div>
</template>
<script>
import MgIcon from "@/components/MgIcon";
import MgLink from "@/components/MgLink";
import WebChatAssignmentDropdown from "@/components/WebChatAssignmentDropdown";
import {
  visitorFullName,
  visitorLocation,
  isFollowUp,
  isClosed,
  lastMessageSentOn,
  canSMSFromChat,
  visitorTextOptIn,
  isConversationOwnedByCurrentTeam,
} from "@/util/webchat";
import store from "@/store";
import { GETTERS, MUTATIONS } from "@/util/constants";
import analytics from "@/util/analytics";
import eventBus from "@/util/eventBus";
import getProfile from "@/util/profile";
import _ from "lodash";

export default {
  name: "WebChatConversationHeader",
  components: {
    MgIcon,
    MgLink,
    WebChatAssignmentDropdown,
  },
  store,
  props: {
    conversation: {
      type: Object,
      required: false,
    },
    showVisitorDetails: {
      type: Boolean,
      required: true,
    },
    isMobileView: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      profile: {},
    }
  },
  created() {
    this.profile = getProfile();
  },
  computed: {
    visitorName() {
      return this.conversation
        ? visitorFullName(this.conversation) || "Visitor"
        : "";
    },
    visitorLocation() {
      return visitorLocation(this.conversation);
    },
    isFollowUp() {
      return isFollowUp(this.conversation);
    },
    isClosed() {
      return isClosed(this.conversation);
    },
    lastMessageSentOn() {
      return lastMessageSentOn(this.conversation);
    },
    canSMSFromChat() {
      return canSMSFromChat(this.conversation);
    },
    visitorTextOptIn() {
      return visitorTextOptIn(this.conversation);
    },
    canBeAssigned() {
      return true;
    },
    teamOwnsConversation() {
      return isConversationOwnedByCurrentTeam(this.conversation);
    },
    conversationId() {
      return this.conversation.conversationId;
    },
    botId() {
      return _.get(this.$store.state.webChat.bot, "id", 0);
    },
    assignedStaffId() {
      return _.get(this.conversation, "assignedStaffId");
    },
    hasInboxes() {
      return this.profile.inboxes && this.profile.inboxes.length > 0
    },
    chatAdmin() {
      return this.profile.isChatAdministrator
    },
    isDeleteFFEnabled() {
      return this.$store.getters[GETTERS.FEATURE_ENABLED](
        "rollout-ability-to-delete-chat-conversations"
      );
    }
  },
  methods: {
    handleDeleteClick() {
      eventBus.dispatchEvent('open-delete-modal');
      analytics.track("Clicked delete conversation button in chat page header");
    },
    handleSMSIconClick() {
      eventBus.dispatchEvent("sms-from-webchat", this.conversation);
      analytics.track("Clicked send text button in chat page");
    },
    handleCompleteFollowUp() {
      eventBus.dispatchEvent("complete-webchat-follow-up", {
        ...this.conversation,
        isMobileView: this.isMobileView,
      });
    },
    handleCloseConversation() {
      eventBus.dispatchEvent("close-webchat-conversation", {
        ...this.conversation,
        isMobileView: this.isMobileView,
      });
    },
    returnToConversationListOnMobile() {
      this.$store.commit(
        MUTATIONS.SET_WEBCHAT_SHOW_MOBILE_CONVERSATION_VIEW,
        false
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

.back-button {
  @include media-breakpoint-down(md) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

#webChatConversationHeader {
  padding: 0.5rem 1rem;
  background-color: $gray-light-bg;
}

.subtitle {
  color: $dark-gray-subtitle;
  white-space: nowrap;
  margin-top: -10px;
  margin-left: 2px;
}

.visitor-details {
  padding-top: 0.5rem;
  overflow: hidden;
}

.controls {
  justify-content: end;
  @include media-breakpoint-down(xs) {
    padding-right: 1rem;
  }
}

.assign-webchat-conversation-control {
  margin-top: 0px;
  margin-left: 3px;
}

.btn-icon {
  margin: 0 2px;
  opacity: 0.7;
  padding: 4px 8px;
  transition: transform 150ms ease-in-out, opacity 150ms ease-in-out;
  color: inherit;

  &.close-webchat-conversation-control {
    margin-top: 2px;
  }

  &.complete-follow-up-control {
    margin: 0;
    padding: 4px 7px;
  }

  &.sms-from-chat-control {
    margin-top: 3px;
  }

  &.delete-conversation-control {
    padding: 4px 5px;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.15);
  }
}

.highlighted {
  background-color: #e9ecef;
  border-radius: 3px;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.truncate {
  @include media-breakpoint-down(md) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.header-details {
  width: 100%;
}
</style>
