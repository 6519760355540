<template>
  <BDropdown
    :id="`${keyPrefix}dropdown`"
    :right="menuAlignment === 'right'"
    variant="link"
    :disabled="disabled"
    :no-caret="toggleButtonIconOnly"
    @show="handleToggleButtonClick"
  >
    <template v-slot:button-content>
      <span :aria-label="ariaLabel">
        <span
          v-if="selectedOption_[toggleIconField]"
          class="mr-1 dropdown-toggle-icon"
        >
          <MgIcon
            :name="selectedOption_[toggleIconField]"
            :style="{ color: selectedOption_[toggleIconColorField] }"
          />
        </span>
        <span
          v-if="!toggleButtonIconOnly"
          >{{ selectedOption_[labelField] }}</span
        >
      </span>
    </template>
    <BDropdownText v-if="headerText">{{ headerText }}</BDropdownText>
    <div v-for="option in options" :key="option.id">
      <BDropdownDivider v-if="isDivider(option)"></BDropdownDivider>
      <BDropdownItem
        v-else
        :class="{ 'hide-selected': hideSelected }"
        :key="`${keyPrefix}${option[keyField]}`"
        :active="isSelected(option)"
        @click="select(option)"
        :disabled="isDisabled(option)"
      >
        <div class="d-flex flex-row">
          <div
            v-if="option[iconField]"
            class="d-flex flex-column mr-2 dropdown-menu-icon"
          >
            <MgIcon
              :name="option[iconField]"
              :style="{ color: option[iconColorField] }"
            />
          </div>
          <div class="d-flex flex-column mr-auto label-description-container">
            <div v-if="!option[descriptionField]" class="dropdown-option-label">
              {{ option[labelField] }}
            </div>
            <strong
              v-if="option[descriptionField]"
              class="dropdown-option-label"
              >{{ option[labelField] }}</strong
            >
            <div
              v-if="option[descriptionField]"
              class="dropdown-option-description"
            >
              <MgIcon
                v-if="option[descriptionIconField]"
                :name="option[descriptionIconField]"
                :style="{ color: option[descriptionIconColorField] }"
              />
              {{ option[descriptionField] }}
            </div>
          </div>
          <div
            v-if="showNextStepChevron"
            class="d-flex flex-column justify-content-center next-step-chevron"
          >
            <MgIcon name="chevron-right" />
          </div>
        </div>
      </BDropdownItem>
    </div>
  </BDropdown>
</template>
<script>
import { BDropdown, BDropdownItem, BDropdownText } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";
import _ from "lodash";

export default {
  name: "MgDropdown",
  components: { BDropdown, BDropdownItem, BDropdownText, MgIcon },
  data() {
    return {
      selectedKeyValue_:
        this.selectedKeyValue || this.options[0][this.keyField],
      selectedOption_: this.getSelectedOption(this.selectedKeyValue),
    };
  },
  watch: {
    selectedKeyValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedKeyValue_ = newVal;
        this.selectedOption_ = this.getSelectedOption(newVal);
      }
    },
    options(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedOption_ = this.getSelectedOption(this.selectedKeyValue_);
      }
    },
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    keyPrefix: {
      type: String,
      required: true,
    },
    keyField: {
      type: String,
      required: true,
    },
    labelField: {
      type: String,
      required: true,
    },
    descriptionField: {
      type: String,
      required: false,
      default: "",
    },
    descriptionIconField: {
      type: String,
      required: false,
      default: "",
    },
    descriptionIconColorField: {
      type: String,
      required: false,
      default: "",
    },
    iconField: {
      type: String,
      required: false,
      default: "",
    },
    iconColorField: {
      type: String,
      required: false,
      default: "",
    },
    toggleIconField: {
      type: String,
      required: false,
      default: "",
    },
    toggleIconColorField: {
      type: String,
      required: false,
      default: "",
    },
    headerText: {
      type: String,
      required: false,
      default: "",
    },
    selectedKeyValue: {
      required: false,
      default: "",
    },
    menuAlignment: {
      required: false,
      default: "right",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    toggleButtonIconOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    showNextStepChevron: {
      type: Boolean,
      required: false,
      default: false,
    },
    interceptToggleButtonClick: {
      type: Function,
      required: false,
      default: null,
    },
    hideSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    ariaLabel: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    isDivider(option) {
      return _.get(option, "type", false);
    },
    isSelected(option) {
      return this.selectedOption_[this.keyField] === option[this.keyField];
    },
    select(option) {
      this.selectedKeyValue_ = option[this.keyField];
      this.selectedOption_ = this.getSelectedOption(this.selectedKeyValue_);
      this.$emit("click", { selectedOption: this.selectedOption_ });
    },
    getSelectedOption(keyValue) {
      // might be worth hashing options on create if we end up with a lot of values
      return (
        this.options.find(
          function (option) {
            return option[this.keyField] === keyValue;
          }.bind(this)
        ) || this.options[0]
      );
    },
    async handleToggleButtonClick() {
      if (this.interceptToggleButtonClick) {
        await this.interceptToggleButtonClick();
      }
    },
    isDisabled(option) {
      return _.get(option, "disabled");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/variables";
@import "../../scss/utilities";

::v-deep .dropdown-menu {
  overflow-y: auto;
  max-height: 287px;
}

.dropdown-divider {
  margin: 0.5rem 0;
}
.dropdown-menu-icon {
  padding-top: 1px;
}

::v-deep .dropdown-toggle:focus-visible,
::v-deep ul.dropdown-menu.show:focus-visible,
::v-deep li:focus-visible {
  outline: 2px auto #006ba8;
}

::v-deep .hide-selected > .dropdown-item {
  &.active {
    background-color: inherit;
  }

  &:hover {
    background-color: $gray-lighter;
  }
}

::v-deep .dropdown-item.disabled {
  background-color: $gray-lighter !important;
}
</style>
