<template>
  <span>
    <BFormCheckbox
      v-bind="$attrs"
      v-bind:checked="value"
      v-on:input="update"
      v-on="$listeners"
    >
      <slot></slot>
    </BFormCheckbox>
  </span>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";

export default {
  name: "Checkbox",
  inheritAttrs: false,
  components: {
    BFormCheckbox
  },
  props: ["value"],
  methods: {
    update(value) {
      this.$emit("input", value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
</style>
