<template>
  <a class="quick-tip-button" href @click.prevent="showTip()">
    <i class="tooltip-icon"></i>
    <span>Show quick tips</span>
  </a>
</template>
<script>
import Appcues from "appcues";
export default {
  name: "QuickTipButton",
  props: {
    tipId: {
      type: String,
      required: true,
    },
    events: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  created() {
    if (Appcues) {
      for (const eventName of Object.keys(this.events)) {
        Appcues.on(eventName, this.events[eventName]);
      }
    }
  },
  destroyed() {
    if (Appcues) {
      for (const eventName of Object.keys(this.events)) {
        Appcues.off(eventName);
      }
    }
  },
  methods: {
    showTip() {
      if (!Appcues) return;

      Appcues.show(this.tipId);
    },
  },
};
</script>
<style lang="scss" scoped></style>
