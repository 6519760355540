<template>
  <div class="d-flex justify-content-between">
    <span class="message">{{ message }}</span>
    <button @click="hideToast()" class="btn-icon close" aria-label="Close">
      <MgIcon name="times" scale="1"></MgIcon>
    </button>
  </div>
</template>

<script>
import Vue from "vue";
import MgIcon from "@/components/MgIcon";
import { ToastPlugin } from "bootstrap-vue";

Vue.use(ToastPlugin);

export default {
  name: "Toast",
  props: {
    message: {
      type: String,
    },
  },
  components: {
    MgIcon,
  },
  methods: {
    hideToast() {
      this.$bvToast.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
.message {
  max-width: 300px;
  padding: 0;
  width: 100%;
}

::v-deep .icon {
  display: flex;
}
</style>
