<template>
  <div id="recruitIntegrationExportTab" class="d-flex flex-column h-100">
    <div class="d-flex toolbar justify-content-end mb-3 flex-row pt-2">
      <a
        href="/#/integrations/recruit/exports/settings"
        class="text-uppercase align-middle d-inline-block top-export"
      >
        <MgIcon name="cog" scale=".75"></MgIcon> Export Settings
      </a>
    </div>

    <BCard header="Error Log" header-tag="h2" no-body v-if="isExportConfigured">
      <div class="empty-message" v-if="exportErrors.totalItems === 0">
        <span class="empty-message-text"> No export error history </span>
      </div>
      <div class="table-responsive" v-else>
        <IntegrationsExportTable :items="exportErrors.items" />
      </div>
    </BCard>

    <EmptyState
      class="flex-fill"
      image="/images/icon_download.svg"
      :imageWidth="74"
      imageAltText="Export Icon"
      headingText="Exports"
      description="Send message activity & opt-out status changes in real-time"
      v-else
    >
      <template #buttons>
        <a href="/#/integrations/recruit/exports/settings">
          Configure Exports&nbsp;»
        </a>
      </template>
    </EmptyState>
  </div>
</template>
<script>
import { ACTIONS } from "./recruitStore";
import { BCard } from "bootstrap-vue";
import EmptyState from "@/components/EmptyState";
import IntegrationsExportTable from "@/components/IntegrationsExportTable";
import MgIcon from "@/components/MgIcon";
import store from "@/store";

export default {
  name: "RecruitExportsTab",
  store,
  components: {
    BCard,
    EmptyState,
    IntegrationsExportTable,
    MgIcon,
  },
  props: {
    exportErrors: {
      type: Object,
      required: true,
    },
    isExportConfigured: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      polling: null,
    };
  },
  created() {
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    pollData() {
      this.polling = setInterval(async () => {
        await this.$store.dispatch(ACTIONS.GET_RECRUIT_EXPORT_ERRORS);
      }, 5000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.toolbar {
  height: 40px;
}

.top-export {
  padding-top: 1px;
}
.empty-message-text {
  font-size: 1.09375rem;
  font-style: italic;
  font-weight: 300;
  margin: 10px 0;
}

.no-item-container {
  background-color: $gray-light-bg;
  height: 274px;
}
</style>
