<template>
  <div id="conversationList" class="d-flex overflow-auto flex-column">
    <MgCollapse
      :expanded="myConversationsExpanded_"
      @change-collapse-state="handleChangeMyConversations"
      data-testid="my-conversations"
    >
      <template v-slot:toggle>
        <WebChatConversationListGroupHeader
          :expanded="myConversationsExpanded_"
          :numberOfConversations="unreadMyConversationsCount"
          @change-collapse-state="handleChangeMyConversations"
          title="My Conversations"
        />
      </template>
      <template v-slot:body>
        <WebChatConversationListItem
          v-for="conversation in myConversations"
          :key="conversation.conversationId"
          :conversation="conversation"
          :bot="bot"
        />
      </template>
    </MgCollapse>
    <MgCollapse
      v-if="isUnassignedSectionEnabled"
      :expanded="unassignedConversationsExpanded_"
      @change-collapse-state="handleChangeUnassignedConversations"
      data-testid="unassigned-conversations"
    >
      <template v-slot:toggle>
        <WebChatConversationListGroupHeader
          :expanded="unassignedConversationsExpanded_"
          :numberOfConversations="unreadUnassignedConversationsCount"
          @change-collapse-state="handleChangeUnassignedConversations"
          title="Unassigned Conversations"
        />
      </template>
      <template v-slot:body>
        <WebChatConversationListItem
          v-for="conversation in unassignedConversations"
          :key="conversation.conversationId"
          :conversation="conversation"
          :bot="bot"
        />
      </template>
    </MgCollapse>
    <MgCollapse
      :expanded="followUpsExpanded_"
      @change-collapse-state="handleChangeFollowUps"
      data-testid="follow-up-conversations"
    >
      <template v-slot:toggle>
        <WebChatConversationListGroupHeader
          :expanded="followUpsExpanded_"
          @change-collapse-state="handleChangeFollowUps"
          title="Follow Ups"
        />
      </template>
      <template v-slot:body>
        <WebChatConversationListItem
          v-for="conversation in followUps"
          :key="conversation.conversationId"
          :conversation="conversation"
          :bot="bot"
        />
        <div
          v-if="showFollowUpLoadMore"
          @click="handleLoadMore('followUps')"
          class="load-more"
          tabindex="0"
          @keyup.enter="handleLoadMore('followUps')"
        >
          Load More Conversations
        </div>
      </template>
    </MgCollapse>
    <MgCollapse
      :expanded="otherConversationsExpanded_"
      @change-collapse-state="handleChangeOtherConversations"
      data-testid="other-conversations"
    >
      <template v-slot:toggle>
        <WebChatConversationListGroupHeader
          :expanded="otherConversationsExpanded_"
          @change-collapse-state="handleChangeOtherConversations"
          title="Other Conversations"
        />
      </template>
      <template v-slot:body>
        <WebChatConversationListItem
          v-for="conversation in otherConversations"
          :key="conversation.conversationId"
          :conversation="conversation"
          :bot="bot"
        />
        <div
          v-if="showOtherLoadMore"
          @click="handleLoadMore('other')"
          class="load-more"
          tabindex="0"
          @keyup.enter="handleLoadMore('other')"
        >
          Load More Conversations
        </div>
      </template>
    </MgCollapse>
  </div>
</template>
<script>
import store from "@/store";
import MgCollapse from "@/components/MgCollapse";
import WebChatConversationListGroupHeader from "./WebChatConversationListGroupHeader";
import WebChatConversationListItem from "./WebChatConversationListItem";
import { ACTIONS, GETTERS, WEBCHAT_PAGINATION } from "@/util/constants";
import { isNoAssignmentMode } from "@/util/webchat";
import analytics from "@/util/analytics";

export default {
  name: "WebChatConversationList",
  store,
  components: {
    MgCollapse,
    WebChatConversationListGroupHeader,
    WebChatConversationListItem,
  },
  props: {
    conversations: {
      type: Array,
      required: true,
    },
    selectedConversationId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      myConversationsExpanded_: false,
      unassignedConversationsExpanded_: false,
      followUpsExpanded_: true,
      otherConversationsExpanded_: true,
      conversationSelectedOnLoad_: false,
      take: WEBCHAT_PAGINATION.TAKE,
      skip: 0,
    };
  },
  computed: {
    isUnassignedSectionEnabled() {
      return isNoAssignmentMode(this.$store.state.webChat.bot);
    },
    myConversations() {
      return this.$store.getters[GETTERS.GET_WEBCHAT_MY_CONVERSATIONS];
    },
    unassignedConversations() {
      return this.$store.getters[GETTERS.GET_WEBCHAT_UNASSIGNED_CONVERSATIONS];
    },
    followUps() {
      return this.$store.getters[GETTERS.GET_WEBCHAT_FOLLOW_UPS](true);
    },
    followUpsUnfiltered() {
      return this.$store.getters[GETTERS.GET_WEBCHAT_FOLLOW_UPS](false);
    },
    unreadMyConversationsCount() {
      return this.$store.state.webChat.unreadMyConversationsCount;
    },
    unreadUnassignedConversationsCount() {
      return this.$store.state.webChat.unreadUnassignedConversationsCount;
    },
    otherConversations() {
      return this.$store.getters[GETTERS.GET_WEBCHAT_OTHER_CONVERSATIONS](true);
    },
    otherConversationsUnfiltered() {
      return this.$store.getters[GETTERS.GET_WEBCHAT_OTHER_CONVERSATIONS](
        false
      );
    },
    showOtherLoadMore() {
      return (
        this.$store.state.webChat.totalOtherConversations >
        this.otherConversations.length
      );
    },
    showFollowUpLoadMore() {
      return this.$store.state.webChat.totalFollowUps > this.followUps.length;
    },
    bot() {
      return this.$store.state.webChat.bot;
    },
  },
  watch: {
    conversations() {
      if (!this.conversationSelectedOnLoad_ && this.selectedConversationId) {
        this.$store.dispatch(ACTIONS.ADD_WEBCHAT_CONVERSATION_TO_LIST_BY_ID, {
          conversationId: this.selectedConversationId,
          setSelected: true,
        });
        this.conversationSelectedOnLoad_ = true;
      }
    },
    myConversations(newVal) {
      this.myConversationsExpanded_ = !!newVal.length;
    },
    unassignedConversations(newVal) {
      this.unassignedConversationsExpanded_ = !!newVal.length;
    },
  },
  methods: {
    handleChangeMyConversations(data) {
      this.myConversationsExpanded_ = data.expanded;
    },
    handleChangeUnassignedConversations(data) {
      this.unassignedConversationsExpanded_ = data.expanded;
    },
    handleChangeFollowUps(data) {
      this.followUpsExpanded_ = data.expanded;
    },
    handleChangeOtherConversations(data) {
      this.otherConversationsExpanded_ = data.expanded;
    },
    handleLoadMore(data) {
      switch (data) {
        case "other":
          this.otherConversations.length;
          this.$store.dispatch(
            ACTIONS.LOAD_MORE_WEBCHAT_OTHER_CONVERSATIONS,
            this.otherConversationsUnfiltered.length
          );
          analytics.track("Load more other conversations clicked");
          break;
        case "followUps":
          this.$store.dispatch(
            ACTIONS.LOAD_MORE_WEBCHAT_FOLLOWUP_CONVERSATIONS,
            this.followUpsUnfiltered.length
          );
          analytics.track("Load more follow up conversations clicked");
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
#conversationList {
  margin-bottom: 93px;
}
.load-more {
  cursor: pointer;
  background-color: transparent;
  color: #006ba8;
  border: none;
  padding: 0.75rem 1rem;
  height: 100%;
  width: 100%;
}
.load-more:hover {
  text-decoration: underline;
  background-color: rgba(238, 238, 238, 0.6);
}
</style>
