<template>
  <td class="menu">
    <b-dropdown
      right
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        <MgIcon class="text-muted header-menu" name="ellipsis-v" />
      </template>
      <b-dropdown-item @click="editTag()">Edit</b-dropdown-item>
      <b-dropdown-item @click="archiveTag()">{{
        archived ? "Unarchive" : "Archive"
      }}</b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item variant="danger" @click="deleteTag()"
        >Delete</b-dropdown-item
      >
    </b-dropdown>
  </td>
</template>
<script>
import Vue from "vue";
import { DropdownPlugin } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";
import analytics from "@/util/analytics";

Vue.use(DropdownPlugin);

export default {
  name: "TagDetailSubHeaderMenu",
  components: {
    MgIcon,
  },
  props: {
    tagId: {
      type: Number,
      required: true,
    },
    archived: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    editTag() {
      this.$emit("edit-tag", { tagId: this.tagId });
      analytics.track("Clicked Edit from Tag Detail Page");
    },
    archiveTag() {
      const eventName = this.archived ? "de-archive-tag" : "archive-tag";
      this.$emit(eventName, { tagId: this.tagId });
      analytics.track(
        `Clicked ${
          this.archived ? "Unarchive" : "Archive"
        } from Tag Detail Page`
      );
    },
    deleteTag() {
      this.$emit("delete-tag", { tagId: this.tagId });
      analytics.track("Clicked Delete from Tag Detail Page");
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  overflow: inherit;
}
</style>
