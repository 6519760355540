<template>
  <BModal id="recruitConfigModal" ref="recruitConfigModal">
    <template #modal-header="{ close }">
      <h3 class="modal-title">Recruit Settings</h3>
      <button
        class="close"
        @click="close()"
        aria-label="Close Recruit Configuration Modal"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </template>

    <div>
      <div class="alert-container mt-2 px-0">
        <div
          class="alert alert-danger"
          role="alert"
          v-show="isConfigured && !conValidation.success"
        >
          {{ `${conValidation.statusCode} - ${conValidation.message}` }}
        </div>
      </div>
      <h4>Authentication</h4>
      <div>
        An account in the Ellucian ADADMIN domain is used for authentication.
      </div>
    </div>
    <form>
      <div class="pl-0">
        <div class="form-group">
          <label for="recruit-username"> Username </label>
          <input
            type="text"
            :class="`form-control ${isError('username') ? 'is-invalid' : ''}`"
            id="recruit-username"
            v-model="baseSettings.username"
            required
          />
          <span v-show="isError('username')" class="text-danger">
            Required
          </span>
        </div>

        <div class="form-group">
          <label for="recruit-password"> Password </label>
          <input
            type="password"
            :class="`form-control ${isError('password') ? 'is-invalid' : ''}`"
            id="recruit-password"
            v-model="baseSettings.password"
            required
          />
          <span v-show="isError('password')" class="text-danger">
            Required
          </span>
        </div>

        <div class="form-group">
          <label for="recruit-adfsRootUrl"> ADFS Root URL </label>
          <input
            type="text"
            :class="`form-control ${
              isError('adfsRootUrl') ? 'is-invalid' : ''
            }`"
            id="recruit-adfsRootUrl"
            v-model="baseSettings.adfsRootUrl"
            required
          />
          <span v-show="isError('adfsRootUrl')" class="text-danger">
            Required
          </span>
        </div>

        <div class="form-group pb-4">
          <label for="recruit-crmRootUrl"> CRM Root URL </label>
          <input
            type="text"
            :class="`form-control ${isError('crmRootUrl') ? 'is-invalid' : ''}`"
            id="recruit-crmRootUrl"
            v-model="baseSettings.crmRootUrl"
            required
          />
          <span v-show="isError('crmRootUrl')" class="text-danger">
            Required
          </span>
        </div>
      </div>

      <div>
        <h4>Notifications</h4>

        <div class="form-group">
          <label for="recruit-failure" class="mb-1"
            >Failure Notifications</label
          >
          <p class="mb-2">
            A notification email will be sent if an issue is encountered while
            attempting to communicate with Recruit. This includes an expired
            authentication
          </p>
          <textarea
            class="form-control"
            id="recruit-failure"
            v-model="baseSettings.failureNotificationEmail"
          />
          <div>
            <small class="form-text"
              >Enter multiple email addresses, separate by commas
            </small>
          </div>
        </div>
        <div class="form-group">
          <label for="recruit-import" class="mb-1"
            ><strong>Import Notification</strong>
          </label>
          <p class="mb-2">
            A notification email summarizing the import's successes and errors
            will be sent every time an import runs.
          </p>
          <textarea
            class="form-control"
            id="recruit-import"
            v-model="baseSettings.notificationEmail"
          />
          <div>
            <small class="form-text"
              >Enter multiple email addresses, separate by commas
            </small>
          </div>
        </div>
      </div>
    </form>

    <template #modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-between">
        <button
          class="btn btn-primary"
          v-on:click="handleSave"
          :disabled="isSaving"
          type="submit"
        >
          Save
        </button>
        <button class="btn btn-light ml-3" @click="cancel" :disabled="isSaving">
          Cancel
        </button>
      </div>
    </template>
  </BModal>
</template>
<script>
import Vue from "vue";
import { ACTIONS } from "./recruitStore";
import store from "@/store";
import { BModal } from "bootstrap-vue";
import _ from "lodash";
import Toast from "@/components/Toast";
import { ToastPlugin } from "bootstrap-vue";
import { itemsForPatch } from "@/util/apiHelpers";
import analytics from "@/util/analytics";

Vue.use(ToastPlugin);

export default {
  name: "RecruitConfigModal",
  store,
  components: {
    BModal,
  },
  props: {
    baseSettings: {
      type: Object,
      required: true,
    },
    conValidation: {
      type: Object,
      required: true,
    },
    isConfigured: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isSaving: false,
      errors: [],
    };
  },
  computed: {
    cleanBaseSettings() {
      return _.cloneDeep(this.$store.state.recruit.base);
    },
  },
  methods: {
    async handleSave() {
      this.validate();
      if (this.errors.length === 0) {
        this.isSaving = true;
        try {
          await this.$store.dispatch(ACTIONS.UPDATE_RECRUIT_CONFIG, {
            base: itemsForPatch(this.cleanBaseSettings, this.baseSettings),
          });
          await this.$store.dispatch(ACTIONS.GET_RECRUIT_CON_VALIDATION);

          analytics.track("Updated Integration Settings", {
            integrationName: "Recruit",
          });

          if (this.$store.state.recruit.conValidation.success) {
            let toastBody = this.$createElement(Toast, {
              props: {
                message: "Recruit Connection Settings Updated",
              },
            });
            this.$bvToast.toast([toastBody], {
              variant: "success",
              noCloseButton: true,
              solid: true,
            });
            this.$refs["recruitConfigModal"].hide();
          }
        } catch {
          let toastBody = this.$createElement(Toast, {
            props: {
              message: "Something went wrong",
            },
          });
          this.$bvToast.toast([toastBody], {
            variant: "danger",
            noCloseButton: true,
            solid: true,
          });
          this.$refs["recruitConfigModal"].hide();
        }
        this.isSaving = false;
      }
    },
    validate() {
      this.errors = [];
      if (this.baseSettings.username === "") this.errors.push("username");
      if (this.baseSettings.password === "") this.errors.push("password");
      if (this.baseSettings.adfsRootUrl === "") this.errors.push("adfsRootUrl");
      if (this.baseSettings.crmRootUrl === "") this.errors.push("crmRootUrl");
    },
    isError(field) {
      return _.includes(this.errors, field);
    },
  },
};
</script>
<style lang="scss" scoped>
.alert-container {
  padding: 0 16px;
}
</style>
