<template>
  <div id="heatMapFilter">
    <button
      id="heatMapFilterButton"
      class="filter-dropdown btn py-0"
      @click="togglePopover()"
    >
      <MgIcon name="filter-solid"></MgIcon>
      <p id="filter-title">{{ filterTitle }}</p>
      <span class="dropdown-toggle"></span>
    </button>
    <div class="popover-container">
      <BPopover
        target="heatMapFilterButton"
        placement="bottom"
        triggers="click"
        :customClass="`search-popover`"
        ref="popover"
        :show.sync="isPopoverOpen"
      >
        <template v-slot:title>
          <BFormGroup>
            <template #label>
              <strong>Conversation Types</strong>
            </template>
            <BFormRadioGroup
              v-model="selectedConversationType_"
              :options="conversationTypeOptions_"
              name="conversation-type"
              stacked
              class="conversationTypeRadio"
              data-testid="conversation-type-radio"
            >
            </BFormRadioGroup>
          </BFormGroup>
        </template>
        <BFormCheckbox v-model="followupRequested_">
          Follow up requested
        </BFormCheckbox>
        <button
          v-on:click="submitFilter()"
          type="button"
          class="btn btn-primary ml-3 my-3"
        >
          Apply
        </button>
        <button
          v-on:click="cancel()"
          type="button"
          class="btn btn-secondary ml-2 my-3"
        >
          Cancel
        </button>
      </BPopover>
    </div>
  </div>
</template>

<script>
import { BPopover } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";
import {
  VBTooltip,
  BFormGroup,
  BFormRadioGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import Vue from "vue";
import store from "@/store";
import { conversationTypeDisplayText } from "@/util/webchat";
import { WEBCHAT_FILTERS } from "@/util/constants";

Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "ChatAnalyticsHeatMapFilter",
  store,
  props: {
    filterData: {
      type: Object,
      required: true,
    },
    liveChatOnly: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    MgIcon,
    BPopover,
    BFormGroup,
    BFormRadioGroup,
    BFormCheckbox,
  },
  data() {
    return {
      isPopoverOpen: false,
      selectedConversationType_: this.filterData.heatMapConversationType,
      conversationTypeOptions_: [
        {
          text: conversationTypeDisplayText(
            WEBCHAT_FILTERS.ALL,
            this.filterData.heatMapFollowupRequested,
            this.liveChatOnly,
            true
          ),
          value: WEBCHAT_FILTERS.ALL,
        },
        {
          text: conversationTypeDisplayText(
            WEBCHAT_FILTERS.USER_ASSISTED,
            this.filterData.heatMapFollowupRequested,
            this.liveChatOnly,
            true
          ),
          value: WEBCHAT_FILTERS.USER_ASSISTED,
        },
        {
          text: conversationTypeDisplayText(
            WEBCHAT_FILTERS.BOT_ONLY,
            this.filterData.heatMapFollowupRequested,
            this.liveChatOnly,
            true
          ),
          value: WEBCHAT_FILTERS.BOT_ONLY,
        },
      ],
      followupRequested_: this.filterData.heatMapFollowupRequested,
    };
  },
  computed: {
    filterTitle() {
      return conversationTypeDisplayText(
        this.filterData.heatMapConversationType,
        this.filterData.heatMapFollowupRequested,
        this.liveChatOnly
      );
    },
  },
  methods: {
    togglePopover() {
      this.$root.$emit("bv::hide::tooltip");
      this.isPopoverOpen = !this.isPopoverOpen;
    },
    submitFilter() {
      this.$root.$emit("bv::hide::popover");
      this.$emit("submit-filter", {
        conversationType: this.selectedConversationType_,
        followupRequested: this.followupRequested_ || null, // careful careful, null !== false in this endpoint
      });
    },
    cancel() {
      this.$root.$emit("bv::hide::tooltip");
      this.isPopoverOpen = !this.isPopoverOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";

#heatMapFilter {
  ::v-deep button {
    color: $text-color !important;
    background-color: #fff !important;
    border-color: $input-border !important;
    display: flex;
    span {
      position: relative;
      top: 6px;
    }
    input {
      border: none;
      height: auto;
      width: auto;
      display: inline;
      background: #fff;
      cursor: pointer;
    }
    .dropdown-toggle::after {
      font-size: 0.875rem;
      position: relative;
      top: 1px;
    }
    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }
}

#filter-title {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0px;
  text-transform: none;
  width: 242px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
}

.search-popover.popover.b-popover {
  top: -10px !important;
  left: -31px !important;
  width: 234px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.18);

  &.bs-popover-bottom {
    ::v-deep .arrow {
      display: none !important;
    }
    .arrow:after {
      border-bottom-color: $popover-header-bg;
    }
  }
  ::v-deep .btn.btn-secondary {
    background-color: #ffffff;
    border-color: #e0e0e0;
    color: #212529;
  }
  ::v-deep .popover-header {
    padding-left: 1rem;
    text-transform: none;
  }
  .popover-body {
    max-height: 275px;
    overflow: auto;
    padding: 0;

    .list-group-item {
      border: none;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}

::v-deep .custom-control {
  padding-top: 0.5rem;
  text-transform: none;

  &.custom-radio > .custom-control-label {
    padding-top: 3px;
    font-weight: $font-weight-normal;
  }

  &.custom-checkbox {
    margin-left: 1rem;

    .custom-control-label {
      padding-top: 2px;
      margin-top: 4px;
      font-weight: $font-weight-normal;
    }
  }
}

::v-deep fieldset {
  box-shadow: none;
  -webkit-box-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}
</style>
