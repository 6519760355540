<template>
  <div class="text-type-list">
    <BFormCheckbox
      :class="{ active: selectAll }"
      v-model="selectAll"
      class="selectAll"
      @change="toggleSelectAll()"
      >Select All</BFormCheckbox
    >
    <BFormCheckbox
      v-for="item in filterItems"
      v-model="checked"
      :value="item"
      :key="item"
      :class="{ active: checked.includes(item) }"
      class="teamCheckbox"
      ><span v-html="item"></span
    ></BFormCheckbox>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";

export default {
  name: "TextTypeFilterList",
  props: {
    listItems: {
      type: Array,
      required: true,
    },
    checkedItems: {
      type: Array,
      required: true,
    },
  },
  components: {
    BFormCheckbox,
  },
  data() {
    return {
      checked: this.checkedItems,
      selectAll: false,
    };
  },
  created() {
    this.updateSelectAll();
  },
  watch: {
    checked() {
      this.$emit("item-checked", this.checked);
      this.updateSelectAll();
    },
  },
  computed: {
    filterItems() {
      return this.listItems.filter((item) => {
        return item.toLowerCase();
      });
    },
  },
  methods: {
    toggleSelectAll() {
      this.checked = [];
      if (this.selectAll) {
        this.listItems.forEach((item) => {
          this.checked.push(item);
        });
      }
    },
    updateSelectAll() {
      this.selectAll = this.listItems.length === this.checked.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
.input-group {
  border-bottom: 1px solid $input-border;
  .input-group-prepend {
    background-color: #fff;
  }
}
.text-type-list {
  ::v-deep .custom-control {
    padding: 6px 0 6px 42px;
    background-color: white;
  }
  ::v-deep .custom-control.active,
  ::v-deep .custom-control:hover {
    background: #e5e5e5;
  }
  ::v-deep .custom-control-label {
    text-transform: none;
    font-weight: 400;
    font-size: 13px;
  }
  ::v-deep .custom-control-label::before,
  ::v-deep .custom-control-label::after {
    top: 0rem;
  }
  ::v-deep .text-type-list {
    height: fit-content;
    overflow-y: auto;
  }
}
</style>
