<template>
  <div class="d-flex">
    <div
      class="gravatar rounded-circle bot-icon-circle"
      v-if="source === 'Bot'"
      alt="Bot"
      v-b-tooltip.hover.right="{
        title: 'Assigned to Bot',
        delay: { show: 500, hide: 50 },
      }"
    >
      <MgIcon name="robot-circle" scale="1.11" class="bot-icon" />
    </div>
    <div
      class="d-flex"
      v-if="source === 'Agent'"
      v-b-tooltip.hover.right="{
        title: `Assigned to ${assignedName}`,
        delay: { show: 500, hide: 50 },
      }"
    >
      <img
        v-if="userAvatar"
        :src="userAvatar"
        height="24"
        width="24"
        class="rounded-circle user-avatar-image"
        :alt="name"
      />
      <div class="user-avatar-container">
        <div
          :alt="name"
          class="d-flex align-items-center justify-content-center gravatar rounded-circle avatar-cornwall-background avatar-small"
        >
          <span class="letter">{{ name.slice(0, 1) }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MgIcon from "@/components/MgIcon";

export default {
  name: "WebChatConversationListUserAvatar",
  components: { MgIcon },
  props: {
    source: {
      type: String,
      required: true,
    },
    assignedToCurrentUser: {
      type: Boolean,
      required: true,
    },
    userAvatar: {
      type: String,
      required: false,
      default: "",
    },
    name: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    assignedName() {
      return this.assignedToCurrentUser ? "you" : this.name;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

.user-avatar-container {
  min-height: 25px;
}

.user-avatar-image {
  position: absolute;
  z-index: 1000;
}

.letter {
  font-size: 14px;
  color: $avatar-black;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
}

.avatar-small {
  width: 24px;
  height: 24px;
}

.bot-icon-circle {
  width: 24px;
  height: 24px;
  background-color: $accent;
}

.bot-icon {
  position: relative;
  top: 4px;
  left: 3px;
  color: #fff;
}
</style>
