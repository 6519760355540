<template>
  <MgDataGrid
    :sortable="{ allowUnsort: false, mode: 'single' }"
    :rowData="contacts"
    :columns="columns_"
    :skip="skip_"
    :total="total_"
    :sort="sort_"
    paginationMode="SERVER"
    @data-state-change="handleDataStateChange"
    :renderMenu="renderMenu_"
    class="contacts-grid"
  />
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import analytics from "@/util/analytics";
import api from "@/util/api";
import store from "@/store";
import MgDataGrid from "@/components/MgDataGrid";
import { formatPhoneNumber } from "@/util/phoneNumber";
import { formatDate } from "@/util/dates";
import { GETTERS } from "@/util/constants";
import ContactsGridMenu from "./ContactsGridMenu";
import eventBus from "@/util/eventBus";
import { ToastPlugin } from "bootstrap-vue";
import Toast from "@/components/Toast";

Vue.use(ToastPlugin);

export default {
  name: "ContactsGrid",
  store,
  components: {
    MgDataGrid,
  },
  props: {
    params: {
      type: Object,
      required: true,
    },
    pagination: {
      type: Object,
      required: true,
    },
    customField: {
      type: Object,
    },
    allCustomFields: {
      type: Array,
    },
  },
  created() {
    eventBus.addEventListener("message-compose", this.handleMessageCompose);
    eventBus.addEventListener("contact-delete", this.handleContactDelete);
  },
  mounted() {
    if (!_.isEmpty(this.params)) {
      this.getContacts();
    }
  },
  destroyed() {
    eventBus.removeEventListener("message-compose", this.handleMessageCompose);
    eventBus.removeEventListener("contact-delete", this.handleContactDelete);
  },
  data() {
    return {
      loading_: false,
      skip_: 0,
      total_: 0,
      contacts: [],
      sort_: [],
      columns_: [
        {
          field: "FirstName",
          title: "FIRST NAME",
          type: "text",
          cell: (h, tdElement, props) => {
            let contactUrl = "#/students/" + props.dataItem.Id;
            return (
              <td>
                <a href={contactUrl}>{props.dataItem.FirstName}</a>
              </td>
            );
          },
        },
        {
          field: "LastName",
          title: "LAST NAME",
          type: "text",
        },
        {
          field: "MobileNumber",
          title: "MOBILE NUMBER",
          cell: (h, tdElement, props) => {
            return (
              <td> {this.formatMobileNumber(props.dataItem.MobileNumber)} </td>
            );
          },
        },
        {
          field: "StaffId",
          title: "ASSIGNED USER",
          type: "text",
          sortable: false,
          cell: (h, tdElement, props) => {
            return <td> {this.assignedUser(props.dataItem.StaffId)} </td>;
          },
        },
        {
          field: "LastUpdatedOn",
          title: "LAST MODIFIED",
          cell: (h, tdElement, props) => {
            return <td> {formatDate(props.dataItem.LastUpdatedOn)} </td>;
          },
        },
      ],
      renderMenu_(h, tdElement, props) {
        return <ContactsGridMenu contact={props.dataItem} />;
      },
    };
  },
  methods: {
    async getContacts() {
      try {
        this.loading_ = true;
        let combinedParams = {
          ...this.params,
          ...this.pagination,
        };
        if (combinedParams.sort.length) {
          let field = _.find(
            this.allCustomFields,
            "searchField",
            combinedParams.sort[0].field
          );
          field = field ? field : this.customField;
          combinedParams.orderBy = field.id;
          combinedParams.orderbydirection = combinedParams.sort[0].dir;
        }
        delete combinedParams.sort;
        let contacts = await api.post(`/students/fieldsearch`, combinedParams);
        this.contacts = contacts.Students;
        this.total_ = contacts.Total;
        this.skip_ = contacts.Skip;
        this.$emit("totalContacts", this.contacts.length);
        this.loading_ = false;
      } catch {
        this.loading_ = false;
        this.errorToast("There was an error loading the page.");
      }
    },
    formatMobileNumber(mobile) {
      if (mobile.charAt(0) === "1" && mobile.length === 11) {
        mobile = mobile.slice(1);
      }
      return formatPhoneNumber(mobile);
    },
    assignedUser(staffId) {
      return (
        this.$store.getters[GETTERS.USER_NAME_BY_ID](staffId) || "Unassigned"
      );
    },
    handleMessageCompose(event) {
      this.$emit("compose", event.detail);
    },
    handleContactDelete(event) {
      this.$emit("deleteContact", event.detail);
    },
    errorToast(message) {
      let toastBody = this.$createElement(Toast, {
        props: {
          message,
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "danger",
        noCloseButton: true,
        solid: true,
      });
    },
    isDefaultColumn(newCol) {
      let defaultColumns = [
        "First Name",
        "Last Name",
        "Mobile Number",
        "Assigned User",
        "Last Modified",
        "Full Name",
        "Contact Id",
      ];
      return _.includes(defaultColumns, newCol);
    },
    handleDataStateChange(data) {
      this.$emit("setPagination", data);
      analytics.track("Clicked Contact Grid Pagination", {
        "Button clicked": event.target.ariaLabel,
      });
    },
    handleCustomColumn(newVal) {
      if (!this.isDefaultColumn(newVal.displayName)) {
        let newCol = {
          field: newVal.displayName,
          title: newVal.displayName,
          cell: (h, tdElement, props) => {
            return <td> {props.dataItem[newVal.displayName]} </td>;
          },
        };
        if (this.columns_.length === 5) {
          this.columns_.splice(4, 0, newCol);
        } else {
          this.columns_.splice(4, 1, newCol);
        }
      } else {
        if (this.columns_.length === 6) {
          _.pullAt(this.columns_, 5);
        }
      }
    },
  },
  watch: {
    params(newVal) {
      if (!_.isEmpty(newVal)) {
        this.getContacts();
      }
    },
    pagination() {
      this.getContacts();
    },
    customField: {
      handler: "handleCustomColumn",
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .k-grid-header {
  border-radius: 0;
}
</style>
