<template>
  <div id="inboxConvo">
    <Store :config="config" />
    <StoreInbox :active-inbox="activeInbox" />
    <div id="nav" class="d-flex justify-content-between align-items-center">
      <div>
        <div class="d-flex">
          <a
            @click="isInboxView = true"
            :style="{ textDecoration: isInboxView ? 'underline' : 'none' }"
            >Inbox</a
          >
          |
          <a
            @click="isInboxView = false"
            :style="{ textDecoration: !isInboxView ? 'underline' : 'none' }"
            >Archived</a
          >
          |
          <AutomationStatus /> Auto Reply
        </div>
      </div>
      <select v-model="activeInbox" @change="selectInbox">
        <option v-for="(inbox, index) in inboxes" :value="inbox" :key="index">
          {{ inbox.name }} - {{ inbox.type }}
        </option>
      </select>
      <button
        class="btn btn-sm btn-info"
        type="button"
        v-show="activeInbox.type !== 'personal'"
        :disabled="!conversationId"
        @click="lockConversation()"
      >
        Lock
      </button>
      <CreditBalance v-if="isHighSpeed" />
      <div>
        <Auth />
      </div>
    </div>
    <div v-if="activeInbox" id="inbox" class="d-flex align-items-stretch">
      <ConversationList
        @bulk-reply="onBulkReply"
        @select-conversation="selectConversation"
        :is-inbox-view="isInboxView"
      />
      <div class="flex-fill flex-grow-1" style="width: 65%">
        <ConversationItem :conversation-id="conversationId" />
      </div>
      <ContactCard></ContactCard>
    </div>
  </div>
</template>

<script>
import Auth from "@/components/Auth";
import AutomationStatus from "@/components/AutomationStatus";
import ContactCard from "@/components/ContactCard";
import ConversationList from "./ConversationList";
import ConversationItem from "./ConversationItem";
import CreditBalance from "@/components/CreditBalance";
import Store from "@/components/Store";
import StoreInbox from "@/components/StoreInbox";
import { INBOX_TYPES } from "@/util/constants";
import eventBus from "@/util/eventBus";

export default {
  name: "InboxConvo",
  components: {
    Auth,
    AutomationStatus,
    ContactCard,
    ConversationList,
    ConversationItem,
    CreditBalance,
    Store,
    StoreInbox,
  },
  props: {},
  created() {
    if (!window.localStorage.config) return;
    let config = JSON.parse(window.localStorage.config);
    let profile = JSON.parse(window.localStorage.profile) || null;
    let inbox = window.localStorage.activeInbox
      ? JSON.parse(window.localStorage.activeInbox)
      : null;

    profile.isAdministrator =
      profile.currentDepartment.isAdministrator || profile.isOwner;

    this.config = {
      features: config.features,
      isAdmin: profile.isAdministrator,
      orgId: profile.currentDepartment.organizationId,
      team: profile.currentDepartment,
      timeZone: config.timeZone,
      userId: profile.id,
    };
    this.inboxes = profile.inboxes;
    this.activeInbox = inbox ? inbox : profile.inboxes[0];
    window.localStorage.setItem(
      "activeInbox",
      JSON.stringify(this.activeInbox)
    );
  },
  data() {
    return {
      activeInbox: null,
      config: {},
      conversationId: null,
      inboxes: [],
      isInboxView: true,
      timeZone: process.env.VUE_APP_SANDBOX_TIME_ZONE,
    };
  },
  computed: {
    isHighSpeed() {
      return this.activeInbox
        ? this.activeInbox.type === INBOX_TYPES.HIGH_SPEED
        : null;
    },
  },
  methods: {
    lockConversation() {
      eventBus.dispatchEvent("lockConversation");
    },
    onBulkReply(contacts) {
      alert(`Reply to ${contacts.length} contact(s)`);
    },
    selectConversation(id) {
      this.conversationId = id;
    },
    selectInbox() {
      window.localStorage.setItem(
        "activeInbox",
        JSON.stringify(this.activeInbox)
      );
      window.location.reload();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "src/scss/utilities";

#inboxConvo {
  height: 100%;

  // Storybook Layout
  .sb-show-main & {
    margin: -1rem;
  }
}

#nav {
  background: #f0f0f0;
  height: 45px;
  padding: 8px 1rem;
}

#inbox {
  bottom: 0;
  top: 45px;
  width: 100%;
}
.conversation-list-column {
  max-width: 400px;
  min-width: 275px;
  width: 35%;
  @include media-breakpoint-down(lg) {
    width: 64px;
    max-width: none;
    min-width: 64px;
  }
}
</style>
