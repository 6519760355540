<template>
  <div id="webChatVisitorDetailsFooter" class="d-flex flex-column">
    <button
      v-if="canSMSFromChat && hasInboxes"
      class="btn btn-blue mb-3"
      type="button"
      alt="Send SMS From Chat"
      @click.prevent="handleSendTextButtonClick"
      :disabled="visitorTextOptIn !== 'Yes'"
    >
      <MgIcon name="message-sms" class="mr-2" />Send Text
    </button>
    <button
      v-if="conversation && !isClosed"
      class="btn btn-light mb-3"
      type="button"
      alt="Close Conversation"
      @click.prevent="closeConversation()"
    >
      <MgIcon name="comment-xmark" class="mr-2" />Close Conversation
    </button>
    <button
      v-if="isFollowUp"
      class="btn btn-light"
      type="button"
      alt="Complete Follow Up"
      @click.prevent="completeFollowUp()"
    >
      <MgIcon name="user-check" class="mr-2" />Complete Follow Up
    </button>
    <button
      v-if="isDeleteFFEnabled && chatAdmin"
      class="btn btn-danger mt-3"
      type="button"
      alt="Delete Conversation"
      @click.prevent="deleteConversation()"
      data-testid="delete-conversation-from-details"
    >
      <MgIcon name="trash-can" id="delete-icon" stroke="#5D5D5D" class="mr-2" />Delete Conversation
    </button>
  </div>
</template>
<script>
import MgIcon from "@/components/MgIcon";
import eventBus from "@/util/eventBus";
import getProfile from "@/util/profile";
import { GETTERS } from "@/util/constants";
import store from "@/store";
import {
  canSMSFromChat,
  visitorTextOptIn,
  isFollowUp,
  isClosed,
} from "@/util/webchat";
import analytics from "@/util/analytics";

export default {
  name: "WebChatVisitorDetailsFooter",
  components: { MgIcon },
  store,
  props: {
    conversation: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      profile: {},
    }
  },
  created() {
    this.profile = getProfile();
  },
  computed: {
    isFollowUp() {
      return isFollowUp(this.conversation);
    },
    isClosed() {
      return isClosed(this.conversation);
    },
    canSMSFromChat() {
      return canSMSFromChat(this.conversation);
    },
    visitorTextOptIn() {
      return visitorTextOptIn(this.conversation);
    },
    hasInboxes() {
      return this.profile.inboxes && this.profile.inboxes.length > 0
    },
    chatAdmin() {
      return this.profile.isChatAdministrator
    },
    isDeleteFFEnabled() {
      return this.$store.getters[GETTERS.FEATURE_ENABLED](
        "rollout-ability-to-delete-chat-conversations"
      );
    }
  },
  methods: {
    closeConversation() {
      eventBus.dispatchEvent("close-webchat-conversation", {
        conversationId: this.conversation.conversationId,
      });
    },
    deleteConversation() {
      eventBus.dispatchEvent("open-delete-modal");
      analytics.track("Clicked delete conversation button in chat page details");
    },
    completeFollowUp() {
      eventBus.dispatchEvent("complete-webchat-follow-up", {
        conversationId: this.conversation.conversationId,
      });
    },
    handleSendTextButtonClick() {
      eventBus.dispatchEvent("sms-from-webchat", this.conversation);
      analytics.track("Clicked send text button in chat page");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

#webChatVisitorDetailsFooter {
  padding: 1rem;
}
</style>
