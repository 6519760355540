<template>
  <div class="filterList">
    <BFormCheckbox
      :class="{ active: selectAll }"
      v-model="selectAll"
      class="selectAll"
      @change="toggleSelectAll()"
      >Select All</BFormCheckbox
    >
    <div class="team-list">
      <BFormCheckbox
        v-for="item in filterItems"
        v-model="checked"
        :value="item.id"
        :key="item.id"
        :class="{ active: checked.includes(item.id) }"
        class="teamCheckbox"
        ><span v-html="highlightText(item.displayName)"></span
      ></BFormCheckbox>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";

export default {
  name: "SharedStaffList",
  props: {
    listItems: {
      type: Array,
      required: true,
    },
    checkedItems: {
      type: Array,
      required: true,
    },
  },
  components: {
    BFormCheckbox,
  },
  data() {
    return {
      search: "",
      checked: this.checkedItems,
      selectAll: false,
    };
  },
  watch: {
    checked() {
      this.$emit("item-checked", this.checked);
      this.updateSelectAll();
    },
  },
  computed: {
    filterItems() {
      return this.listItems.filter((item) => {
        return item.displayName
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    highlightText(matchedName) {
      if (this.search === "") {
        return matchedName;
      }
      const re = new RegExp(this.search, "gi");
      return matchedName.replace(re, `<strong>$&</strong>`);
    },
    toggleSelectAll() {
      this.checked = [];
      if (this.selectAll) {
        this.listItems.forEach((item) => {
          this.checked.push(item.id);
        });
      }
    },
    updateSelectAll() {
      this.selectAll = this.listItems.length === this.checked.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
.input-group {
  border-bottom: 1px solid $input-border;
  .input-group-prepend {
    background-color: #fff;
  }
}
.filterList {
  ::v-deep .custom-control {
    padding: 6px 0 6px 42px;
  }
  ::v-deep .custom-control.active,
  ::v-deep .custom-control:hover {
    background: #e5e5e5;
  }
  ::v-deep .custom-control-label {
    text-transform: none;
    font-weight: 400;
    font-size: 14px;
    span {
      padding-bottom: 2px;
    }
  }
  ::v-deep .team-list {
    max-height: 260px;
    overflow-y: auto;
    background: linear-gradient(#fff 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), #fff 70%) 0 100%,
      radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), transparent),
      radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 0.1),
          transparent
        )
        0 100%;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;
  }
  .selectAll {
    margin-bottom: 5px;
  }
  .teamCheckbox {
    padding-bottom: 3px;
    padding-top: 3px;
  }
}
</style>
