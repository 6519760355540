<template>
  <span id="legacyPicker">
    <button
      :id="popoverId_"
      type="button"
      class="btn btn-xs btn-light btn-icon btn-media"
      :class="{ active: open_ }"
      @click="open_ = true"
    >
      <i class="fa fa-smile-o"></i><span class="sr-only">Insert Emoji</span>
    </button>

    <BPopover
      :target="popoverId_"
      placement="top"
      triggers="click focus blur"
      customClass="popover-emoji-picker"
      :show.sync="open_"
    >
      <emoji-picker
        :id="pickerId"
        data-source="/cadenceWeb/json/emojiData.json"
        @emoji-click="selectEmoji"
      ></emoji-picker>
    </BPopover>
  </span>
</template>

<script>
import { BPopover, BootstrapVue } from "bootstrap-vue";
import { nextTick } from "vue";
import "emoji-picker-element";

/* eslint-disable */
Vue.use(BootstrapVue, {
  BPopover: { boundaryPadding: "25px" },
});
/* eslint-enable */

export default {
  name: "LegacyEmojiPickerComposeMenu",
  components: { BPopover },
  data() {
    return {
      open_: false,
      searchText_: "",
      popoverId_: "emojiPicker",
      pickerId: crypto.randomUUID(),
    };
  },
  methods: {
    selectEmoji(event) {
      this.$emit("select-emoji", event.detail);
      this.open_ = false;
    },
  },
  watch: {
    async open_(val) {
      if (val) {
        await nextTick();
        const picker = document.getElementById(this.pickerId);
        let style = document.createElement("style");
        style.textContent = `
        .picker{
          border-radius:4px;
        }
        .search-row {
          border-bottom: 1px solid #0000004d;
          padding-bottom:2px;
        }
        .search-row:before {
        position: relative;
        font-family: 'FontAwesome';
        font-size: 16px;
        -webkit-text-stroke: 0.6px white;
        color: #212529 !important;
        top: 0;
        left: 5px;
        margin-bottom: 2px;
        content: "\uf002";
        }
        input.search {
          width: 95%;
        }
        input.search:focus-visible {
          outline: 2px auto #006ba8;
        }
        .search {
          margin-left:10px;
        }
        .search::placeholder {
          letter-spacing: 0.1px;
        }
        .pad-top{
          height:2px;
        }
        .nav {
          margin-top:2px;
        }
        .favorites {
          border-top: 1px solid #e0e0e0;
        }
        `;
        picker.shadowRoot.appendChild(style);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/variables";
@import "src/scss/utilities";

#legacyPicker {
  display: none;

  @include media-breakpoint-up(sm) {
    display: inline;
  }
}

.btn-text {
  color: $text-color;
  line-height: 0;
  text-indent: 0;
  margin: 0.9px 0;
  vertical-align: -1em;
  transition: 150ms ease-in-out;
}

::v-deep .svg-inline--fa {
  height: 0.95em;
}

//force light mode
::v-deep emoji-picker {
  color-scheme: light !important;
  --background: #fff !important;
  --border-color: #e0e0e0 !important;
  --indicator-color: #385ac1 !important;
  --input-border-color: #999 !important;
  --input-font-color: rgb(85, 85, 85) !important;
  --input-font-size: 14px;
  --input-placeholder-color: rgba(85, 85, 85, 0.8) !important;
  --outline-color: #999 !important;
  --category-font-color: #111 !important;
  --button-active-background: #e6e6e6 !important;
  --button-hover-background: #d9d9d9 !important;

  //search bar styles
  --input-border-radius: 0;
  --input-border-size: 0;

  //match existing dropdown style
  --border-size: 0px;
  max-height: 318px;
  min-width: 76.1094px;
  border-radius: 4px;
}
</style>
