<template>
  <span>
    <StatusIcon
      :enabled="enabledMultiple"
    />
  </span>
</template>

<script>
import store from "@/store";
import { ACTIONS } from "@/util/constants";
import StatusIcon from "@/components/StatusIcon";
import { mapState } from "vuex";

export default {
  name: "AutomationStatus",
  store: store,
  components: {
    StatusIcon,
  },
  created() {
    this.$store.dispatch(ACTIONS.GET_MULTIPLE_AUTOMATION_SETTINGS);
    this.$store.dispatch(ACTIONS.GET_AUTOMATION_SETTINGS);
  },
  computed: {
    ...mapState({
      enabled: (state) => !state.automation.settings.disabled,
      enabledMultiple: (state) =>
        !!state.automation.multipleSettings.find((s) => s.enabledOn),
    }),
  },
};
</script>

<style lang="scss" scoped></style>
