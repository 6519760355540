import moment from "moment";
import store from "@/store";

export const formatDate = (rawDate, formatString, ignoreTimezone = false) => {
  if (!rawDate) {
    return "";
  }

  const _formatString = formatString || "M/D/YYYY[,] h:mm A";
  const utcDate = moment.utc(rawDate);

  const date = ignoreTimezone
    ? utcDate
    : moment.tz(utcDate, store.state.config.timeZone);
  return date.format(_formatString);
};

export const formatTime = (totalSeconds) => {
  let remaining = Math.round(totalSeconds);
  const numHours = Math.floor(remaining / 3600);
  remaining = remaining % 3600;
  const numMinutes = Math.floor(remaining / 60);
  remaining = remaining % 60;
  const numSeconds = remaining;

  return `${numHours ? numHours + " hr " : ""}${
    numMinutes ? numMinutes + " min " : ""
  }${numSeconds ? numSeconds + " sec" : "0"}`;
};
