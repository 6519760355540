<template>
  <div>
    <Chart :seriesColors="seriesColors">
      <ChartTitle :text="title" :position="'top'" :align="'left'" />
      <ChartTooltip :render="'defaultTooltipRender'">
        <template v-slot:defaultTooltipRender="{ props }">
          <div>
            <strong>{{ props.point.category }}</strong
            ><br />
            <span>{{ props.point.series.name }}: {{ props.point.value }}</span>
          </div>
        </template>
      </ChartTooltip>
      <ChartLegend
        :position="'top'"
        :align="'end'"
        :orientation="'horizontal'"
      />
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          :title="{
            text: categoryTitle,
          }"
          :categories="categories"
          :major-grid-lines="hidden"
          :labels="labelFormat"
        >
        </ChartCategoryAxisItem>
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem :major-grid-lines="hidden" />
      </ChartValueAxis>
      <ChartSeries>
        <ChartSeriesItem
          v-for="(s, index) in series"
          :name="seriesNames[index]"
          :key="'chart-' + index"
          :data-items="s"
          :type="'line'"
        />
      </ChartSeries>
    </Chart>
  </div>
</template>

<script>
import {
  Chart,
  ChartTitle,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-vue-charts";
import "../../scss/mgKendo/MgLineChart/_index.scss";

export default {
  components: {
    Chart,
    ChartTitle,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
  },
  props: {
    title: {
      type: String,
    },
    categories: {
      type: Array,
    },
    categoryTitle: {
      type: String,
    },
    series: {
      type: Array,
    },
    seriesColors: {
      type: Array,
    },
    seriesNames: {
      type: Array,
    },
  },
  data: function () {
    return {
      hidden: { visible: false },
      labelFormat: {
        format: "d",
        rotation: "auto",
      },
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep .k-chart-tooltip {
  line-height: 1;
}
</style>
