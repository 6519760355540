import { ACTIONS, MUTATIONS } from "../util/constants";
import api from "../util/api";

export default {
  async [ACTIONS.GET_CAMPAIGNS]({ commit }) {
    let activeRequest = () => {
        return api.get(`/campaigns?isActive=true`);
      },
      namesRequest = () => {
        return api.get(`/campaigns/names`);
      };

    const [active, names] = await Promise.all([
      activeRequest(),
      namesRequest(),
    ]);

    commit(MUTATIONS.SET_CAMPAIGNS, {
      active: active,
      names: names,
    });
  },

  [ACTIONS.UPDATE_CAMPAIGN]({ commit }, params) {
    commit(MUTATIONS.UPDATE_CAMPAIGN, params);
  },

  [ACTIONS.GET_CONFIG]({ commit }, config) {
    commit(MUTATIONS.SET_CONFIG, config);
  },

  async [ACTIONS.GET_RADIUS_TARGETS]({ commit }) {
    let targets = await api.get(`/radius/targets`);
    return commit(MUTATIONS.SET_RADIUS_TARGETS, targets);
  },

  [ACTIONS.UPDATE_CAMPAIGNS]({ commit }, campaign) {
    commit(MUTATIONS.UPDATE_CAMPAIGNS, campaign);
  },

  [ACTIONS.SHOW_CONTACT_CARD]({ commit }, value) {
    commit(MUTATIONS.SHOW_CONTACT_CARD, value);
  },

  async [ACTIONS.GET_USERS]({ commit }) {
    const response = await api.get(`/staff`);
    commit(MUTATIONS.SET_USERS, response);
  },

  async [ACTIONS.GET_CONNECT_FILTERS]({ commit }) {
    let filters = await api.get(`/connect/filters`);
    return commit(MUTATIONS.SET_CONNECT_FILTERS, filters);
  },

  async [ACTIONS.GET_RE_NXT_LISTS]({ commit }) {
    let lists = await api.get(`/raisersEdgeNxt/constituent-lists`);
    return commit(MUTATIONS.SET_RE_NXT_LISTS, lists);
  },

  async [ACTIONS.GET_SLATE_QUERIES]({ commit }) {
    let queries = await api.get(`/slateQueries`);
    return commit(MUTATIONS.SET_SLATE_QUERIES, queries);
  },
};
