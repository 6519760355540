<template>
  <div id="metricsChart">
    <div class="card p-3">
      <div class="col-12">
        <h2 class="h4">{{ title }}</h2>
        <MgLineChart
          :series="seriesArray"
          :seriesNames="seriesDisplayNames"
          :categories="categoryArray"
          :seriesColors="colorsArray"
        ></MgLineChart>
      </div>
    </div>
  </div>
</template>

<script>
import MgLineChart from "@/components/MgLineChart";

export default {
  name: "MetricsChart",
  components: { MgLineChart },
  props: {
    dates: {
      type: Object,
      required: true,
    },
    seriesFieldNames: {
      type: Array,
      required: true,
    },
    seriesDisplayNames: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    seriesColors: {
      type: Array,
      required: false,
    },
    series: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      seriesArray: [],
      categoryArray: [],
    };
  },
  mounted() {
    this.setArrays();
  },
  computed: {
    colorsArray() {
      if (this.seriesColors) {
        return this.seriesColors;
      } else {
        return ["#737373", "#396FB0"];
      }
    },
  },
  methods: {
    setSeries(arr) {
      //extract values from properties
      this.seriesArray = this.seriesFieldNames.reduce((acc, curr) => {
        return [...acc, arr[curr]];
      }, []);
    },
    setCategories(dates) {
      let textDates = [];
      dates.forEach((date) => {
        let newDate = new Date(date);
        if (
          this.dates.timeInterval === "Last24Hours" ||
          this.dates.start === this.dates.end
        ) {
          textDates.push(
            newDate.toLocaleString("en-us", {
              hour: "numeric",
              hour12: true,
            })
          );
        } else {
          let textString =
            newDate.toLocaleString("default", {
              month: "short",
            }) +
            " " +
            newDate.getDate();
          textDates.push(textString);
        }
      });
      this.categoryArray = textDates;
    },
    setArrays() {
      let arr = [];
      if (this.series) {
        let dates = [];
        // go through each property in series and construct respective arrays for the chart
        for (const property in this.series) {
          let propArr = [];
          // dates used for x-axis categories
          /*eslint-disable */
          dates = Object.keys(this.series[property]);
          for (let [key, value] of Object.entries(this.series[property])) {
            /*eslint-enable */
            // values used for chart points
            propArr.push(value);
          }
          arr[property] = propArr;
        }
        this.setSeries(arr);
        this.setCategories(dates);
      }
    },
  },
  watch: {
    series: function () {
      this.setArrays();
    },
  },
};
</script>

<style lang="scss" scoped>
h4,
.h4 {
  font-size: 16px;
}
</style>
