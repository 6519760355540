<template>
  <ResponsiveContainer class="h-100" :breakpoints="{ hideTimeline: 375 }">
    <template slot-scope="container">
      <div class="history-component d-flex">
        <transition name="fade">
          <div
            v-if="!loading"
            class="history-body d-flex flex-column flex-fill"
          >
            <div
              class="load-more py-3"
              v-if="showLoadMore && history.length > 0"
            >
              <BButton
                v-on:click="loadMore()"
                size="sm"
                variant="outline-primary"
              >
                <MgIcon class="mr-1" name="arrow-up" scale=".75"></MgIcon>
                Load Older Messages
              </BButton>
            </div>
            <div
              v-for="(item, index) in history"
              :key="item.messageId"
              class="message-container"
            >
              <strong
                class="history-timestamp"
                v-if="
                  history.length > 0 &&
                  showTimelineDate(item.timestamp, index, container)
                "
              >
                {{ item.timestamp | dateShorthand() }}
              </strong>
              <div class="d-flex flex-row" v-if="history.length > 0">
                <ContactTimeline
                  v-if="container.size >= container.breakpoints.hideTimeline"
                  :time="item.timestamp"
                ></ContactTimeline>
                <Message
                  :is-history="true"
                  :has-timeline="
                    container.size >= container.breakpoints.hideTimeline
                  "
                  class="flex-fill"
                  :item="item"
                />
              </div>
            </div>
            <div id="bottom"></div>
            <div v-if="history.length === 0" class="d-flex flex-column empty">
              <h3>{{ emptyHistoryMessage }}</h3>
            </div>
          </div>
        </transition>
      </div>
    </template>
  </ResponsiveContainer>
</template>

<script>
import store from "@/store";
import moment from "moment";
import { ACTIONS } from "@/util/constants";
import ResponsiveContainer from "@/components/ResponsiveContainer";
import Message from "@/components/Message";
import ContactTimeline from "@/components/ContactHistory/ContactTimeline";
import { BButton } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";
import analytics from "@/util/analytics";
import { viewportSize } from "@/mixins/viewportSize";

export default {
  name: "ContactHistory",
  store,
  components: {
    ResponsiveContainer,
    Message,
    ContactTimeline,
    BButton,
    MgIcon,
  },
  props: {
    contactId: {
      required: true,
    },
    profile: {
      required: true,
    },
  },
  mixins: [viewportSize],
  data() {
    return {
      loading: false,
      selectedConversation: null,
    };
  },
  async created() {
    this.loading = true;
    this.$store.dispatch(ACTIONS.RESET_CONTACT_HISTORY);
    await this.$store.dispatch(ACTIONS.GET_CONTACT_HISTORY, this.contactId);
    this.loading = false;
    const jumpToBottom = () => {
      if (window.innerWidth > 768) {
        this.$el.querySelector("#bottom").scrollIntoView(false);
      }
    };
    return setTimeout(jumpToBottom, 500);
  },
  computed: {
    history() {
      return (
        this.$store.state.contact.history &&
        this.$store.state.contact.history.results
      );
    },
    showLoadMore() {
      return this.$store.state.contact.history.meta.total >
        this.$store.state.contact.history.meta.skip +
          this.$store.state.contact.history.meta.take
        ? true
        : false;
    },
    emptyHistoryMessage() {
      return this.profile.currentDepartment.teamHasChat
        ? "No text history"
        : "No message history";
    },
  },
  methods: {
    selectConversation(message) {
      this.selectedConversation = message.direction;
    },
    showTimelineDate(current, index, container) {
      if (container.size < container.breakpoints.hideTimeline) return false;

      if (index === 0) return true;

      let previousItemDate = moment.tz(
        moment.utc(this.history[index - 1].timestamp),
        this.$store.state.config.timeZone
      );
      let currentItemDate = moment.tz(
        moment.utc(current),
        this.$store.state.config.timeZone
      );

      return !moment(currentItemDate).isSame(previousItemDate, "day");
    },
    loadMore() {
      this.$store.dispatch(ACTIONS.GET_MORE_CONTACT_HISTORY).then(() => {
        let skip = this.$store.state.contact.history.meta.skip,
          take = this.$store.state.contact.history.meta.count,
          total = this.$store.state.contact.history.meta.total,
          lastPage = total % take;
        const jumpToMessage = () => {
          let el = this.$el.getElementsByClassName("message-container");
          if (total - skip > take) {
            el[take - 1].scrollIntoView(true);
          } else {
            el[lastPage - 1].scrollIntoView(true);
          }
        };
        setTimeout(jumpToMessage, 250);
      });
      analytics.track("Clicked Load More in Message History");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
.history-component {
  height: 100%;
}
.message-container:first-of-type {
  padding-top: 15px;
}
.history-timestamp {
  border-right: 2px solid transparentize($link-color, 0.6);
  width: 100px;
  display: inline-block;
  text-align: right;
  padding-right: 15px;
}

.load-more {
  background: $light;
  margin: 0 -20px;
  text-align: center;
}

.empty {
  color: $text-muted;
  text-align: center;
  padding-top: 20px;

  @include media-breakpoint-up(md) {
    position: absolute;
    left: 42%;
    top: 47%;
    padding-top: 0;
  }
}
</style>
