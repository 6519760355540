<template>
  <div
    id="recruit-integration-export-settings"
    class="d-flex flex-column h-100"
  >
    <SubpageHeader
      headerTitle="Recruit Export Settings"
      backHref="/#/integrations/recruit/exports/"
      class="mb-3"
    />

    <div class="recruit-export-page-content">
      <BCard header="Export Settings" header-tag="h2">
        <div class="form-group m-0">
          <h3 class="mb-2">Enable Exports</h3>
          <p>
            Receive real-time updates in Recruit when text messages are sent and
            received or a contact's opt-out preferences change.
          </p>
          <div class="col">
            <div class="form-group w-100 m-0">
              <BFormCheckbox
                id="enableMsgActivity"
                v-model="exportSettings.enabled"
              >
                <span class="form-check-label"> Export Message Activity </span>
              </BFormCheckbox>
            </div>

            <div class="form-group w-100 m-0">
              <BFormCheckbox
                id="enableOptOutChanges"
                v-model="exportSettings.enableOptOutPush"
              >
                <span class="form-check-label">
                  Export opt-out status Changes
                </span>
              </BFormCheckbox>
            </div>
          </div>
        </div>
        <hr class="mt-4" />
        <div class="form-group m-0">
          <h3 class="mb-2 mt-4">Activity Code</h3>
          <p>
            Select an Activity Code for the texting activities that will be
            created.
          </p>
          <div class="w-100">
            <BFormSelect
              id="activityCode"
              v-model="exportSettings.activityCode"
              :options="crmActivityCodes.items"
              text-field="name"
              value-field="code"
              class="w-50"
            >
              <template #first>
                <BFormSelectOption value="" disabled>
                  Select Abbreviation
                </BFormSelectOption>
              </template>
            </BFormSelect>
          </div>
        </div>
      </BCard>

      <div class="container-fluid form-actions sticky-bottom">
        <button class="btn btn-primary" v-on:click="updateExportSettings()">
          Save
        </button>
        <a class="btn btn-light ml-1" href="/#/integrations/recruit/exports/">
          Cancel
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  BCard,
  BFormCheckbox,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
import SubpageHeader from "@/components/SubpageHeader";
import store from "@/store";
import { ACTIONS } from "../../recruitStore";
import Toast from "@/components/Toast";
import { ToastPlugin } from "bootstrap-vue";
import { itemsForPatch } from "@/util/apiHelpers";
import _ from "lodash";
import api from "@/util/api";
import analytics from "@/util/analytics";

Vue.use(ToastPlugin);

export default {
  name: "RecruitExportSettingsPage",
  store,
  components: {
    BCard,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption,
    SubpageHeader,
  },
  data() {
    return {
      crmActivityCodes: [],
    };
  },
  async created() {
    await this.$store.dispatch(ACTIONS.GET_RECRUIT_CONFIG);

    this.crmActivityCodes = await api.get("/recruit/crm/activity-codes");
  },
  computed: {
    exportSettings() {
      return _.cloneDeep(this.$store.state.recruit.exportSettings);
    },
    cleanExportSettings() {
      return _.cloneDeep(this.$store.state.recruit.exportSettings);
    },
  },
  methods: {
    async updateExportSettings() {
      try {
        await this.$store.dispatch(ACTIONS.UPDATE_RECRUIT_CONFIG, {
          exports: itemsForPatch(this.cleanExportSettings, this.exportSettings),
        });

        analytics.track("Updated Export Settings", {
          integrationName: "Recruit",
          messageActivityExport: this.exportSettings.enabled,
          optoutExport: this.exportSettings.enableOptOutPush,
        });

        let toastBody = this.$createElement(Toast, {
          props: {
            message: "Export Settings Updated",
          },
        });
        this.$bvToast.toast([toastBody], {
          variant: "success",
          noCloseButton: true,
          solid: true,
        });
      } catch {
        let toastBody = this.$createElement(Toast, {
          props: {
            message: "There was an error saving Export Settings.",
          },
        });
        this.$bvToast.toast([toastBody], {
          variant: "danger",
          noCloseButton: true,
          solid: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.recruit-export-page-content {
  padding: 20px 30px 15px;
}

.email-text-area {
  width: 280px;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.form-check-label {
  font-weight: 400;
  font-size: 0.875rem;
  padding-top: 2px;
}
</style>
