<template>
  <BCard class="h-100">
    <template #header>
      <div class="d-flex align-items-center">
        <MgIcon class="d-flex ml-n2 mr-2" name="comment" scale="1.0"></MgIcon>
        <h2 class="card-title">Chat History</h2>
      </div>
    </template>
    <div class="h-100" data-test-id="chat-history">
      <span v-if="chatHistory.length > 0">
        <div class="list-header flex-container">
          <div class="flex-item">
            <strong>Conversation Date and Time</strong>
          </div>
          <div class="flex-item text-right"><strong>Actions</strong></div>
        </div>
        <div class="d-flex flex-column w-100">
          <ul class="chat-history-list">
            <li
              :key="chat.id"
              v-for="chat in chatHistory"
              class="chat-history-item flex-container"
            >
              <div class="my-2 flex-item">
                {{ formatDate(chat.createdOn) }}
              </div>
              <div class="text-right my-2 flex-item">
                <a
                  class="btn btn-blue"
                  :aria-label="
                    'View Conversation from ' + formatDate(chat.createdOn)
                  "
                  :href="'#/chat/' + chat.conversationId"
                >
                  View
                </a>
              </div>
            </li>
          </ul>
        </div>
      </span>
      <div
        v-else
        class="h-100 d-flex justify-content-center align-items-center"
      >
        <div class="d-flex flex-column w-100 empty">
          <h3 class="text-center">No chat history</h3>
        </div>
      </div>
    </div>
  </BCard>
</template>

<script>
import { BCard } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";
import store from "@/store";
import { formatDate } from "@/util/dates";
import api from "@/util/api";

export default {
  name: "ContactChatHistory",
  store,
  components: {
    BCard,
    MgIcon,
  },
  props: {
    contactId: {
      required: true,
    },
  },
  data() {
    return {
      chatHistory: [],
    };
  },
  async created() {
    this.chatHistory = await this.contactChatHistory();
  },
  methods: {
    formatDate(date) {
      return formatDate(date, "M/D/YY[,] h:mm A");
    },
    async contactChatHistory() {
      return api.get(`/students/${this.contactId}/chat-history`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
.list-header {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 0.3rem;
}

.flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-container > .flex-item {
  flex: auto;
  align-items: center;
}

.chat-history-list {
  padding: 0;
  margin-bottom: 0;
}

.chat-history-item {
  list-style: none;
  border-bottom: 1px solid #e2e2e2;
}

.chat-history-item:last-child {
  border-bottom: none;
}

.empty {
  color: $text-muted;
}

.card-body {
  @include media-breakpoint-up(md) {
    overflow: auto;
  }
}
</style>
