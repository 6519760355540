<template>
  <div
    id="webChatConversationView"
    class="d-flex flex-fill overflow-auto flex-column k-card-list h-100"
  >
    <template v-for="message in validMessages">
      <WebChatLog
        v-if="isMessageInternalLog(message)"
        :message="message"
        :fadeIn="shouldFade(message)"
        :key="'log-' + message.id"
      />
      <WebChatMessage
        v-else-if="!isMessageInternalLog(message)"
        :conversation="conversation"
        :message="message"
        :showDateTime="message.showDateTime"
        :fadeIn="shouldFade(message)"
        :isNote="isMessageInternalNote(message)"
        :key="'message-' + message.id"
      />
    </template>
    <div id="cadence-ai-container" />
  </div>
</template>
<script>
import WebChatMessage from "@/components/WebChatMessage";
import WebChatLog from "@/components/WebChatLog";
import {
  isMessageOutgoing,
  isMessageInternalNote,
  isMessageInternalLog,
  messageUserName,
} from "@/util/webchat";
import _ from "lodash";

export default {
  name: "WebChatConversationView",
  components: {
    WebChatMessage,
    WebChatLog,
  },
  props: {
    messages: {
      type: Array,
      required: true,
    },
    conversation: {
      type: Object,
      required: false,
    },
  },
  mounted() {
    this.scrollToBottom();
  },
  updated() {
    this.scrollToBottom();
  },
  computed: {
    validMessages() {
      const filteredMessages = this.messages.filter(
        (message) => message.payload.text
      );

      return filteredMessages.map((message, i) =>
        // don't show for blocks of outgoing messages from the same agent except the last
        // message in the block
        i < filteredMessages.length - 1 &&
        !isMessageInternalLog(filteredMessages[i + 1]) &&
        isMessageInternalNote(message) ===
          isMessageInternalNote(filteredMessages[i + 1]) &&
        isMessageOutgoing(message) ===
          isMessageOutgoing(filteredMessages[i + 1]) &&
        messageUserName(message) === messageUserName(filteredMessages[i + 1])
          ? { ...message, showDateTime: false }
          : { ...message, showDateTime: true }
      );
    },
  },
  methods: {
    scrollToBottom() {
      let container = this.$el;
      container.scrollTop = container.scrollHeight;
    },
    shouldFade(message) {
      return _.get(message, "fadeIn");
    },
    isMessageInternalNote(message) {
      return isMessageInternalNote(message);
    },
    isMessageInternalLog(message) {
      return isMessageInternalLog(message);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

#webChatConversationView {
  padding-left: 1rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 92px;
  gap: 12px;
}

#cadence-ai-container {
  scroll-margin-bottom: 2rem;
}
</style>
