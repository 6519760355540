<template>
  <div>
    <p
      class="d-flex justify-content-between my-2"
      v-for="(value, name, index) in fields"
      :key="index"
    >
      <span class="text-break-word pr-3">{{ name }}</span>
      <strong
        v-html="getLinkOrValue(value) || '-'"
        class="text-right text-break-word"
      ></strong>
    </p>
  </div>
</template>

<script>
export default {
  name: "ContactFields",
  components: {},
  props: ["fields"],
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {
    getLinkOrValue(value) {
      const urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const telRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

      if (urlRegex.exec(value)) {
        value =
          value.toLowerCase().indexOf("http") > -1
            ? `<a href="${value}" target="_blank">${value}</a>`
            : `<a href="//${value}" target="_blank">${value}</a>`;
      } else if (emailRegex.exec(value)) {
        value = `<a href="mailto:${value}">${value}</a>`;
      } else if (telRegex.exec(value)) {
        value = `<a href="tel:${value}">${value}</a>`;
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
.contact-fields {
  font-size: $font-size-sm;
}
</style>
