<template>
  <div class="date-picker">
    <VueDatePicker
      :mode="mode"
      v-model="selectedDate"
      v-on:popoverDidHide="onPopoverHidden"
      :is-required="required"
      :popover="{ visibility: pickerVisibility }"
      :min-date="minDate"
      :max-date="maxDate"
    >
      <template slot-scope="props">
        <BInputGroup
          v-if="!pickerOnly"
          :state="inputState"
          :class="{ active: focus }"
        >
          <template v-slot:prepend>
            <div class="icon-container" @click="openDatePicker">
              <MgIcon name="calendar"></MgIcon>
            </div>
          </template>
          <label :for="id" class="sr-only">{{ label }}</label>
          <BFormInput
            :id="id"
            @focus="focus = true"
            @blur="focus = false"
            v-model="props.inputValue"
            @input="updateValue"
            :placeholder="'MM/DD/YYYY'"
            :state="inputState"
          ></BFormInput>
          <BFormInvalidFeedback v-if="showFeedback" :state="inputState">
            {{ errorMessage }}
          </BFormInvalidFeedback>
        </BInputGroup>
        <div @click="openDatePicker" v-else>
          <slot name="picker-only-link-text"></slot>
        </div>
      </template>
    </VueDatePicker>
  </div>
</template>

<script>
import VueDatePicker from "v-calendar/lib/components/date-picker.umd";
import MgIcon from "../MgIcon";
import { BInputGroup, BFormInput, BFormInvalidFeedback } from "bootstrap-vue";

let uid = 0;

export default {
  name: "DatePicker",
  components: {
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    VueDatePicker,
    MgIcon,
  },
  data() {
    return {
      // Data used by the date picker
      focus: false,
      id: null,
      pickerVisibility: "hidden",
      inputValue: "",
      selectedFromPicker: false,
      selectedDate: this.selectedDateValue
        ? new Date(this.selectedDateValue)
        : null,
      selectedDateRange: this.selectedDateRangeValue
        ? this.selectedDateRangeValue
        : null,
    };
  },
  mounted() {
    this.id = `datepicker-${uid.toString()}`;
    uid += 1;
  },
  watch: {
    selectedDate(newVal, oldVal) {
      if (new Date(newVal).getTime() !== new Date(oldVal).getTime()) {
        this.$emit("date-picked", this.selectedDate);
      }
    },
    selectedDateValue(value) {
      this.selectedDate = value ? new Date(value) : null;
    },
    selectedDateRangeValue(value) {
      this.selectedDate = value || null;
    },
  },
  props: {
    mode: {
      type: String,
      default: () => {
        return "single";
      },
    },
    required: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    label: {
      type: String,
      required: false,
      default: () => {
        return "Date Picker";
      },
    },
    showFeedback: {
      type: Boolean,
      required: false,
      default: () => {
        return false;
      },
    },
    selectedDateValue: {
      required: false,
    },
    selectedDateRangeValue: {
      required: false,
    },
    pickerOnly: {
      required: false,
    },
    minDate: {
      required: false,
    },
    maxDate: {
      required: false,
    },
  },
  computed: {
    errorMessage() {
      if (this.mode === "single") {
        return "Please enter a date (MM/DD/YYYY) or click the icon.";
      }
      if (this.mode === "multiple") {
        return "Please enter multiple dates (MM/DD/YYYY) comma seperated or click the icon.";
      }
      return "Please enter a range of dates (MM/DD/YYYY) seperated by a dash or click the icon.";
    },
    inputState() {
      if (this.inputValue.length === 0) {
        return true;
      }
      if (this.selectedFromPicker) {
        return true;
      }
      if (!isNaN(new Date(this.inputValue)) && this.inputValue.length === 10) {
        return true;
      }
      return false;
    },
  },
  methods: {
    updateValue(value) {
      this.inputValue = value;
      this.selectedFromPicker = false;
      if (this.inputState) {
        this.selectedDate = value;
      }
    },
    openDatePicker() {
      this.pickerVisibility =
        this.pickerVisibility === "hidden" ? "visible" : "hidden";
    },
    onPopoverHidden() {
      this.pickerVisibility = "hidden";
      if (this.selectedDate) {
        this.selectedFromPicker = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
.date-picker {
  .icon-container {
    cursor: pointer;
    align-self: center;
    padding-left: 8px;
    padding-right: 9px;
  }
  .input-group {
    border: 1px solid $input-border;
    border-radius: $border-radius-base;
    .input-group-prepend {
      background-color: #fff;
      border-radius: $border-radius-base;
    }
    input {
      border-radius: $border-radius-base;
    }
    &.active {
      border: 1px solid $input-focus-border-color;
    }
  }
  .form-control {
    border: 0;
    padding: $input-padding-y 1px;
  }
  .is-invalid,
  .is-valid {
    background-image: none;
  }
  .invalid-feedback {
    position: absolute;
    bottom: -20px;
    left: 10px;
  }
}
</style>
