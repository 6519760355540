<template>
  <MgBadge :displayNumber="unreadConversations" />
</template>
<script>
import store from "@/store";
import MgBadge from "@/components/MgBadge";
import { Howl } from "howler";

export default {
  name: "WebChatConversationCount",
  store,
  components: { MgBadge },
  data() {
    return {
      incomingSound_: null,
      chatRequestTimer_: null,
    };
  },
  created() {
    this.incomingSound_ = new Howl({
      src: ["/sounds/chat-alert.wav"],
      volume: 1.0,
    });
  },
  computed: {
    unreadConversations() {
      return (
        this.$store.state.webChat.unreadMyConversationsCount +
        this.$store.state.webChat.unreadUnassignedConversationsCount
      );
    },
  },
  watch: {
    unreadConversations(newVal) {
      this.updateTitle(newVal);

      if (newVal > 0 && !this.chatRequestTimer_) {
        this.incomingSound_.play();
        this.chatRequestTimer_ = setInterval(() => {
          this.incomingSound_.play();
        }, 10000);
      } else if (newVal === 0 && this.chatRequestTimer_) {
        clearInterval(this.chatRequestTimer_);
        this.chatRequestTimer_ = null;
      }
    },
  },
  methods: {
    updateTitle(count) {
      localStorage.unreadWebChatConversationsCount = count;
      this.$emit("updateTitle");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

.badge {
  background-color: $red-danger;
}
</style>
