<template>
  <MgSelectList
    class="web-chat-assignment-dropdown"
    :options="users_"
    :headerText="team.teamName.toUpperCase()"
    keyPrefix="web-chat-user"
    keyField="id"
    iconField="icon"
    iconColorField="iconColor"
    labelField="name"
    showNextStepChevron
    hideSelected
    @click="handleDropdownClick"
    title="Agent"
    alt="Agent"
  />
</template>

<script>
import MgSelectList from "@/components/MgSelectList/MgSelectList.vue";
import getProfile from "@/util/profile";
import store from "@/store";

export default {
  name: "WebChatMultiTeamUserDropdown",
  components: { MgSelectList },
  store,
  props: {
    conversationId: {
      type: String,
      required: true,
    },
    team: {
      type: Object,
      required: true,
    },
    assignedStaffId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selectedUser: {},
      profile: {},
      users_: [
        {
          name: "Anyone",
          id: null,
          online: true,
          icon: "",
          iconColor: "",
        },
      ],
    };
  },
  created() {
    this.profile = getProfile();
    this.refreshUserData();
  },
  methods: {
    handleDropdownClick(data) {
      this.$emit("optionSelected", data.selectedOption);
    },

    canUserBeAssigned(user) {
      // can't assign to myself on the same team
      return !this.team.isMyTeam || user.id !== this.assignedStaffId;
    },

    async refreshUserData() {
      if (this.team.agents.length === 0) return;

      const divider = {
        name: "dropdown-divider",
        type: "divider",
        id: null,
      };

      let updatedUsers = [];
      this.team.agents.forEach((user) => {
        if (user.id === this.profile.id) {
          updatedUsers.unshift({
            ...user,
            name: "Me",
            icon: "circle",
            iconColor: user.online ? "#7ED321" : "#CED4DA",
            disabled: !this.canUserBeAssigned(user),
            preDivider: true,
          });
        } else {
          updatedUsers.push({
            ...user,
            icon: "circle",
            iconColor: user.online ? "#7ED321" : "#CED4DA",
            disabled: !this.canUserBeAssigned(user),
            preDivider: user.online,
          });
        }
      });

      if (!this.team.isMyTeam)
        updatedUsers.unshift({
          name: "Anyone",
          id: null,
          online: true,
          icon: "",
          iconColor: "",
          preDivider: true,
        });

      const lastPreDividerIndex = updatedUsers.findLastIndex(
        (user) => user.preDivider
      );

      // only add a divider if it's not the last item in the list
      if (lastPreDividerIndex !== updatedUsers.length - 1) {
        updatedUsers.splice(lastPreDividerIndex + 1, 0, divider);
      }

      this.users_ = updatedUsers;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";
@import "../../scss/utilities";

.mg-select {
  width: 221px;
  max-height: 287px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  z-index: 1000;
}

::v-deep p.b-dropdown-text {
  background-color: white;
  color: #6c757d;
  font-weight: 600;
  font-size: 12.8px;
}

::v-deep .dropdown-item {
  white-space: initial;
  border-radius: 0;

  svg {
    height: 10px;
    margin-bottom: 1px;
  }

  &:hover {
    background-color: $gray-lighter;
  }

  .dropdown-menu-icon {
    height: 10px;
    width: 10px;
    margin-right: 9px !important;
  }
}

::v-deep .dropdown-option-label {
  font-weight: 400;
  color: #212529;
}
</style>
