var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message py-3 px-4",class:{
    outgoing: _vm.item.direction && _vm.item.direction.toLowerCase() === 'outgoing',
    pending: _vm.item.pending,
    'media-with-text':
      _vm.item.media &&
      _vm.item.media.length &&
      _vm.item.message &&
      _vm.item.message !== '<Multimedia Message>',
    'contact-history': _vm.isHistory,
    'no-timeline': !_vm.hasTimeline,
    'is-forwarded': _vm.item.isForwarding,
  },on:{"mouseenter":function($event){_vm.hoverActive = true},"mouseleave":function($event){_vm.hoverActive = false}}},[(_vm.item.isForwarding)?_c('div',{staticClass:"forwarded-text"},[_c('MgIcon',{attrs:{"name":"info"}}),_vm._m(0)],1):_vm._e(),(!_vm.isHistory)?_c('MessageMeta',{attrs:{"item":_vm.item}}):_vm._e(),(_vm.isHistory)?_c('HistoryMessageMeta',{attrs:{"item":_vm.item,"hasTimeline":_vm.hasTimeline}}):_vm._e(),_c('div',{staticClass:"d-flex",class:[
      {
        'flex-row-reverse':
          _vm.item.direction && _vm.item.direction.toLowerCase() === 'outgoing',
      } ]},[_c('div',{staticClass:"message-bubble text-break-word py-2 px-3"},[_c('span',{domProps:{"innerHTML":_vm._f("twemoji")(_vm.item.message)}}),(_vm.item.media && _vm.item.media.length)?_c('MessageMedia',{attrs:{"media":_vm.item.media}}):_vm._e()],1),(!_vm.hideMessageActions)?_c('MessageActions',{attrs:{"hover-active":_vm.hoverActive,"message":_vm.item}}):_vm._e()],1),_c('div',{staticClass:"message-meta-campaign d-flex flex-wrap align-items-center mt-2",class:{
      'flex-row-reverse': _vm.isOutgoing,
    }},[(_vm.item.campaignId)?_c('CampaignTag',{staticClass:"mr-1 mb-1",attrs:{"hover-close-active":_vm.hoverActive,"campaign-id":_vm.item.campaignId,"no-remove-button":_vm.isHistory},on:{"remove-campaign":function($event){return _vm.removeCampaign(_vm.item)}}}):_vm._e(),_vm._l((_vm.item.tags),function(tag){return _c('MessageTag',{key:tag,staticClass:"mr-1 mb-1",attrs:{"hover-close-active":_vm.hoverActive,"tag-id":tag,"no-remove-button":_vm.isHistory},on:{"remove-tag":function($event){return _vm.removeTag(_vm.item, tag)}}})})],2)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v("\n      This message was forwarded from another number. If replying, a new\n      conversation will start on this contact’s device so you should introduce\n      yourself to avoid confusion.\n      "),_c('a',{attrs:{"href":"https://help.mongooseresearch.com/article/570-single-number-shared-inbox-update","target":"_blank"}},[_vm._v("Learn more")])])}]

export { render, staticRenderFns }