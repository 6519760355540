<template>
  <BModal id="renxtOptOutModal" ref="renxtOptOutModal">
    <template #modal-header="{ close }">
      <h3
        class="modal-title"
        v-if="currentOptOutField.smsLinkedCategory === ''"
      >
        Add Field
      </h3>
      <h3 class="modal-title" v-else>Edit Field</h3>
      <button class="close" @click="close()" aria-label="Close Opt Out Modal">
        <span aria-hidden="true">&times;</span>
      </button>
    </template>
    <div>
      <p>
        Opt-out preferences are maintained by the most up to date SMS channel
        from the Constituent Record in Raiser&rsquo;s Edge NXT. Mapping this
        field will also enable automatic exports of contact preference changes
        from Cadence to Raiser&rsquo;s Edge NXT, keeping both systems in sync.
      </p>
      <div>
        <label for="renxtOptOutSelect">Consent Channel Category</label>
        <BFormSelect
          v-model="currentOptOutField.smsLinkedCategory"
          :options="consentChannelCategories"
        >
          <template #first>
            <BFormSelectOption value="" disabled>
              Select a Consent Channel Category
            </BFormSelectOption>
          </template>
        </BFormSelect>
      </div>

      <div class="mt-3">
        <label>Treat Empty Value As</label>
        <BFormRadioGroup
          v-model="currentOptOutField.emptyIsOptedIn"
          class="mt-2"
        >
          <BFormRadio :value="true"
            ><span class="font-weight-normal">Opted-In</span></BFormRadio
          >
          <BFormRadio :value="false"
            ><span class="font-weight-normal">Opted-Out</span></BFormRadio
          >
        </BFormRadioGroup>
      </div>

      <div class="mt-3">
        <div>
          <label for="cadenceFieldInput">Cadence Field</label>
          <input
            id="cadenceFieldInput"
            class="form-control"
            disabled
            value="Opted Out"
          />
          <div class="form-check form-check-inline w-100 mt-2">
            <BFormCheckbox class="mr-3" disabled :checked="false">
              <span class="font-weight-normal">Required</span>
            </BFormCheckbox>
            <BFormCheckbox class="mr-3" disabled :checked="false">
              <span class="font-weight-normal">Mergeable</span>
            </BFormCheckbox>
            <BFormCheckbox class="mr-3" disabled :checked="true">
              <span class="font-weight-normal">Visible to Users</span>
            </BFormCheckbox>
          </div>
          <div class="mt-2">
            <p class="m-0">
              <MgIcon name="exclamation-circle" />
              The options for Optional Fields are predefined and are displayed
              for reference.
            </p>
          </div>
        </div>
      </div>
    </div>

    <template #modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-between">
        <button
          class="btn btn-primary"
          type="submit"
          v-if="optOutField.smsLinkedCategory === ''"
          @click="updateOptOutField()"
        >
          Save
        </button>
        <button
          class="btn btn-primary"
          type="submit"
          @click="updateOptOutField()"
          v-else
        >
          Update
        </button>
        <button class="btn btn-light ml-1" @click="cancel">Cancel</button>
      </div>
    </template>
  </BModal>
</template>

<script>
import {
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";
import _ from "lodash";

export default {
  name: "RenxtOptOutModal",
  components: {
    BFormSelect,
    BFormSelectOption,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    MgIcon,
  },
  data() {
    return {
      currentOptOutField: {},
    };
  },

  props: {
    optOutField: {
      type: Object,
      required: true,
      default() {
        return {
          //emptyIsOptedIn: false,
          //smsLinkedCategory: "",
        };
      },
    },
    consentChannelCategories: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.$root.$on("bv::modal::show", () => {
      this.currentOptOutField = _.cloneDeep(this.optOutField);
    });
  },
  methods: {
    updateOptOutField() {
      this.$emit("updateOptOutField", {
        smsLinkedCategory: this.currentOptOutField.smsLinkedCategory,
        emptyIsOptedIn: this.currentOptOutField.emptyIsOptedIn,
      });
      this.$refs["renxtOptOutModal"].hide();
    },
  },
};
</script>

<style lang="scss" scoped></style>
