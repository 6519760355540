export const viewportSize = {
  methods: {
    isSize(condition) {
      return (
        window.getComputedStyle(
          document.getElementById("viewport-" + condition)
        ).display !== "none"
      );
    },
  },
};
