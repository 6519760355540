import { ACTIONS, GETTERS, MUTATIONS } from "@/util/constants";
import api from "@/util/api";

export default {
  state: () => ({
    tags: [],
    tagSearch: null,
  }),
  actions: {
    async [ACTIONS.GET_TAGS]({ commit }, hideOverlay = false) {
      let options = { headers: { "x-disable-loading": hideOverlay } };
      let tags = await api.get(`/tags`, options);
      return commit(MUTATIONS.SET_TAGS, tags);
    },
    [ACTIONS.UPDATE_TAG_LIST_FILTER]({ commit }, value) {
      commit(MUTATIONS.UPDATE_TAG_LIST_FILTER, value);
    },
    [ACTIONS.UPDATE_TAG_DETAIL_FILTER]({ commit }, value) {
      commit(MUTATIONS.UPDATE_TAG_DETAIL_FILTER, value);
    },
  },
  getters: {
    [GETTERS.GET_TAG_NAME_BY_ID]: (state) => (id) => {
      if (!state.tags) return;
      let tag = state.tags.find((tag) => parseInt(tag.id) === parseInt(id));
      return tag ? tag.name : "Tag Not Found";
    },
  },
  mutations: {
    [MUTATIONS.SET_TAGS](state, tags) {
      state.tags = tags;
    },
    [MUTATIONS.UPDATE_TAG_LIST_FILTER](state, value) {
      state.tagSearch = value || null;
    },
    [MUTATIONS.UPDATE_TAG_DETAIL_FILTER](state, value) {
      state.tagDetailSearch = value || null;
    },
  },
};
