import { ACTIONS, MUTATIONS, INBOX_TYPES } from "@/util/constants";
import api from "@/util/api";

export default {
  state: () => ({
    settings: {
      disabled: true,
      keywords: [],
      confirmationMessage: "",
    },
    multipleSettings: [],
  }),
  actions: {
    async [ACTIONS.GET_AUTOMATION_SETTINGS]({ commit, rootState }) {
      const requests = {
        [INBOX_TYPES.PERSONAL]: function () {
          return api.get(`automation`);
        },
        [INBOX_TYPES.GROUP]: function () {
          return api.get(
            `automation/shared/${rootState.inbox.activeInbox.groupId}`
          );
        },
        [INBOX_TYPES.HIGH_SPEED]: function () {
          return api.get(
            `automation/highspeed/${rootState.inbox.activeInbox.highSpeedId}`
          );
        },
      };

      try {
        let settings = await requests[rootState.inbox.activeInbox.type]();
        commit(MUTATIONS.SET_AUTOMATION_SETTINGS, settings);
        return settings;
      } catch (err) {
        return err;
      }
    },
    async [ACTIONS.GET_MULTIPLE_AUTOMATION_SETTINGS]({ commit, rootState }) {
      const requests = {
        [INBOX_TYPES.PERSONAL]: function () {
          return api.get(`automation/personal/${rootState.config.userId}/all`);
        },
        [INBOX_TYPES.GROUP]: function () {
          return api.get(
            `automation/shared/${rootState.inbox.activeInbox.groupId}/all`
          );
        },
      };
      try {
        let settings = await requests[rootState.inbox.activeInbox.type]();
        commit(MUTATIONS.SET_MULTIPLE_AUTOMATION_SETTINGS, settings);
        return settings;
      } catch (err) {
        return err;
      }
    },
    async [ACTIONS.UPDATE_AUTOMATION_SETTINGS](
      { state, commit, rootState },
      data
    ) {
      let postData = state.settings;
      postData.keywords = data.keywords;
      postData.confirmationMessage = data.confirmationMessage;
      postData.tag = data.tagContact && data.tag ? data.tag : null;

      try {
        let requests = {
          [INBOX_TYPES.PERSONAL]: function () {
            return api.post(`automation`, postData);
          },
          [INBOX_TYPES.GROUP]: function () {
            return api.post(
              `automation/shared/${rootState.inbox.activeInbox.groupId}`,
              postData
            );
          },
          [INBOX_TYPES.HIGH_SPEED]: function () {
            return api.post(
              `automation/highspeed/${rootState.inbox.activeInbox.highSpeedId}`,
              postData
            );
          },
        };

        let settings = await requests[rootState.inbox.activeInbox.type]();
        commit(MUTATIONS.SET_AUTOMATION_SETTINGS, settings);
        return settings;
      } catch (err) {
        return err;
      }
    },
    [ACTIONS.UPDATE_AUTOMATION_STATUS]({ commit, rootState }, disableFeature) {
      const path = disableFeature ? "disable" : "enable";
      const requests = {
        [INBOX_TYPES.PERSONAL]: function () {
          return api.put(`automation/${path}`);
        },
        [INBOX_TYPES.GROUP]: function () {
          return api.put(
            `automation/shared/${rootState.inbox.activeInbox.groupId}/${path}`
          );
        },
        [INBOX_TYPES.HIGH_SPEED]: function () {
          return api.put(
            `automation/highspeed/${rootState.inbox.activeInbox.highSpeedId}/${path}`
          );
        },
      };

      return requests[rootState.inbox.activeInbox.type]().then((settings) => {
        commit(MUTATIONS.SET_AUTOMATION_SETTINGS, settings);
        return settings;
      });
    },
  },
  mutations: {
    [MUTATIONS.SET_AUTOMATION_SETTINGS](state, settings) {
      state.settings = settings ? settings : { disabled: true };
    },
    [MUTATIONS.SET_MULTIPLE_AUTOMATION_SETTINGS](state, settings) {
      state.multipleSettings = settings ? settings : [];
    },
  },
};
