<template>
  <div class="page-content-header-full">
    <div class="d-flex flex-column-reverse flex-sm-row justify-content-between">
      <div>
        <div class="h5 text-normal mb-1">
          <a :href="'tel:' + contact.mobileNumber">{{
            contact.mobileNumberFormatted
          }}</a>
        </div>
        <div>
          <span class="text-break-word"
            >Contact ID: <strong>{{ contact.campusUniqueId }}</strong></span
          >
        </div>
        <div>
          <span class="text-break-word"
            >Assigned User:
            <strong>{{ getAssignedStaff(contact) }}</strong></span
          >
        </div>
      </div>
      <div
        class="d-flex align-items-sm-end flex-column justify-content-between pb-2"
      >
        <div>
          <ContactOptOut :contact="contact"></ContactOptOut>
        </div>
      </div>
    </div>
    <div v-if="contact.tags" class="row pt-2">
      <div class="col-md-8 col-sm-12">
        <MgTag
          v-for="(tag, index) in contactTags"
          :key="'tag' + index"
          class="mr-2 mb-2"
          :title="getTagNameById(tag.tagId)"
          :icon="'tag-solid'"
          :bgColor="'purple'"
          :hover-close="true"
          :hover-close-active="false"
          :no-remove-button="true"
        ></MgTag>
      </div>
      <div class="col-md"></div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import store from "@/store";
import { GETTERS } from "@/util/constants";
import ContactOptOut from "@/components/ContactOptOut";
import MgTag from "@/components/MgTag";

export default {
  name: "ContactDetails",
  store,
  components: { ContactOptOut, MgTag },
  data() {
    return {};
  },
  created() {},
  computed: {
    contact() {
      return this.$store.state.contact.details;
    },
    contactTags() {
      return this.contact.tags.results.filter(
        (tag, index, arr) =>
          arr.findIndex((t) => t.tagId === tag.tagId) === index
      );
    },
    currentTeam() {
      return this.$store.state.config.team;
    },
  },
  methods: {
    getAssignedStaff(contact) {
      let currentTeamId = this.currentTeam ? this.currentTeam.id : "";
      let currentDepartment = _.find(
          contact.departments,
          function (department) {
            return department.id === currentTeamId;
          }
        ),
        staff = !_.isUndefined(currentDepartment)
          ? currentDepartment.staff
          : "";
      return !_.isEmpty(staff)
        ? staff.firstName + " " + staff.lastName
        : "None";
    },
    getTagNameById(tagId) {
      return this.$store.getters[GETTERS.GET_TAG_NAME_BY_ID](tagId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";

.page-content-header-full {
  box-shadow: $panel-box-shadow;
  margin: $line-height-computed * -1 $grid-gutter-width/-2 $grid-gutter-width;
  padding: $line-height-computed $grid-gutter-width/2;

  @include media-breakpoint-up(md) {
    margin: $line-height-computed * -1 $grid-gutter-width * -1
      $grid-gutter-width;
    padding: 25px $grid-gutter-width;
  }
}

::v-deep .optout-settings {
  padding: 20px 0 !important;
  margin: 0 !important;
}

::v-deep .custom-switch {
  margin-right: 30px;
}
</style>
