<template>
  <div id="numberSelectionWrap">
    <div class="number-selection">
      <ul
        class="nav nav-tabs nav-tabs-default"
        v-if="reservedNumbers.length > 0 && !selectedSharedNumber"
      >
        <li role="presentation" class="nav-item">
          <a
            class="nav-link"
            href
            @click.prevent="updatePhoneType('reserved')"
            :class="{ active: phoneType === 'reserved' }"
          >
            Select from Reserved Numbers
          </a>
        </li>
        <li role="presentation" class="nav-item">
          <a
            class="nav-link"
            href
            @click.prevent="updatePhoneType('new')"
            :class="{ active: phoneType === 'new' }"
          >
            Select New Number<span v-if="!singleNumber">s</span>
          </a>
        </li>
      </ul>
      <div class="number-selection-body" v-show="numberSearch">
        <div v-if="phoneType === 'reserved'">
          <div class="number-search-results fade-in">
            <div class="row">
              <div
                class="col-sm-6"
                v-for="group in reservedNumbers"
                v-bind:key="group.postalCode"
              >
                <div
                  class="radio"
                  v-for="n in group"
                  v-bind:key="n.phoneNumber"
                >
                  <label>
                    <input
                      type="radio"
                      name="phoneNumber"
                      :value="n.phoneNumber"
                      @click="selectNumber(n)"
                    />
                    {{ n.friendlyName }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="phoneType === 'new'">
          <div class="number-search">
            <div class="label">Number Search</div>
            <div class="form-inline">
              <div class="form-group form-phone">
                <label for="phone-areaCode" class="sr-only">Area Code</label>
                (<input
                  id="phone-areaCode"
                  class="form-control phone-input"
                  type="text"
                  v-model="numberLookup.areaCode"
                  placeholder="XXX"
                  maxlength="3"
                />)
              </div>
              <div class="form-group form-phone">
                <label for="phone-prefix" class="sr-only">Prefix</label>
                <input
                  id="phone-prefix"
                  class="form-control phone-input"
                  type="text"
                  v-model="numberLookup.prefix"
                  placeholder="opt."
                  maxlength="3"
                />
              </div>
              <div class="phone-line-number-text">&ndash;&nbsp;XXXX</div>
              <button
                @click="lookupNumber()"
                type="button"
                class="btn btn-light search-btn"
                data-test-id="searchButton"
                :disabled="numberLookup.areaCode.length < 3"
              >
                <MgIcon
                  v-show="searchingNumbers == false"
                  name="magnifying-glass-dark"
                ></MgIcon>
                <MgIcon
                  v-show="searchingNumbers == true"
                  name="spinner-regular"
                  class="spin"
                ></MgIcon>

                Search <span v-if="availableNumbers.length > 0">Again</span>
              </button>
            </div>
          </div>

          <div
            class="number-search-results fade-in"
            v-show="availableNumbers.length > 0 || resultNumbers"
          >
            <div
              v-if="searchTitleView || availableNumbers.length > 0"
              class="row label search-label"
            >
              Search Results
            </div>
            <div class="row">
              <div
                class="col-sm-6"
                v-for="group in availableNumbers"
                v-bind:key="group.postalCode"
              >
                <div
                  class="radio"
                  v-for="n in group"
                  v-bind:key="n.phoneNumber"
                >
                  <label>
                    <input
                      type="radio"
                      name="phoneNumber"
                      @click="selectNumber(n)"
                      :value="n.phoneNumber"
                    />
                    {{ n.friendlyName }}
                  </label>
                </div>
              </div>
              <div class="col-sm-12" v-if="resultNumbers.hasNumbers === false">
                <div
                  class="form-group"
                  v-if="
                    resultNumbers.areaCode !== '212' &&
                    resultNumbers.areaCode !== '602'
                  "
                >
                  <div class="text-muted">
                    No numbers currently available for
                    {{ resultNumbers.areaCode }}. Try searching a different area
                    code.
                  </div>
                </div>
                <div
                  class="form-group"
                  v-if="
                    resultNumbers.areaCode === '212' ||
                    resultNumbers.areaCode === '602'
                  "
                >
                  <div class="text-muted">
                    <p>
                      Due to limited availability, numbers in area code
                      {{ resultNumbers.areaCode }} cannot be obtained.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="number-selected fade-in grey-bg" v-show="selectedSharedNumber">
      <div>
        <div class="form-group">
          <div>
            <b>Selected Number</b> &ensp; (<a href @click="resetNumber($event)"
              >change</a
            >)
            <p>{{ sharedNumber }}</p>
          </div>
        </div>
      </div>
      <div class="form-group" v-if="callForwardingEnabled && showVoiceForwarding && selectionComplete">
        <hr />
        <label for="forwardingNumberId">Voice Call Forwarding</label>
        <input
          id="forwardingNumberId"
          class="form-control phone-number-input-lg"
          type="text"
          v-model="inboxForwardingNumber_"
          @focus="callForwardActive = true"
          @blur="callForwardActive = false"
          :placeholder="callForwardActive ? '' : 'Optional'"
          @keypress="isNumber($event)"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
          @input="handleInput($event)"
          maxlength="14"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ACTIONS } from "@/util/constants";
import store from "@/store";
import getProfile from "@/util/profile";
import MgIcon from "@/components/MgIcon";

export default {
  name: "SharedNumberSelection",
  store,
  components: {
    MgIcon,
  },
  props: {
    numberCount: {
      type: Number,
      required: true,
    },
    selectedNumber: {
      type: Object,
      required: false,
    },
    forwardingNumber: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      group: {
        numbers: this.$store.state.sharedInbox.resultNumbers.number || [],
      },
      showVoiceForwarding: this.voiceForwarding != "false",
      numbers: null,
      numberLookup: {},
      searchingNumbers: false,
      singleNumber: false,
      numberSearch: true,
      selectedSharedNumber: false,
      sharedNumber: null,
      searchTitleView: false,
      callForwardActive: false,
      inboxForwardingNumber_: "",
    };
  },
  async created() {
    let config = this.$store.state.config;
    this.config = {
      features: config.features,
    };
    this.singleNumber = true;
    this.numberLookup = {
      areaCode: this.$store.state.sharedInbox.configuration.areaCode,
      prefix: "",
    };
    if (this.selectedNumber != null) {
      if (this.selectedNumber.phoneNumber) {
        this.numberSearch = false;
        this.selectedSharedNumber = true;
        this.sharedNumber = this.selectedNumber.friendlyName;
        this.inboxForwardingNumber_ = this.forwardingNumber;
        this.numbers = this.selectedNumber.phoneNumber;
      }
    }
    this.$emit("loadingNumbers", true);
    await this.$store.dispatch(ACTIONS.GET_SHARED_INBOX_RESERVED_NUMBERS);
    this.$emit("loadingNumbers", false);
  },
  computed: {
    callForwardingEnabled() {
      const profile = getProfile();
      return profile.currentDepartment.callForwardingEnabled
    },
    reservedNumbers() {
      return this.$store.state.sharedInbox.reservedNumbers;
    },
    phoneType() {
      return this.$store.state.sharedInbox.phoneType;
    },
    resultNumbers() {
      return this.$store.state.sharedInbox.resultNumbers;
    },
    availableNumbers() {
      return this.$store.state.sharedInbox.availableNumbers;
    },
    groupedNumbers() {
      return [];
    },
    selectionComplete() {
      return [];
    },
  },
  methods: {
    selectNumber(number) {
      this.numberSearch = false;
      this.selectedSharedNumber = true;
      this.sharedNumber = number.friendlyName;
      this.numbers = number.phoneNumber;
      this.$emit("selectedNumber", number);
    },

    resetNumber(event) {
      event.preventDefault();
      this.numberSearch = true;
      this.selectedSharedNumber = false;
      this.sharedNumber = null;
      this.numbers = null;
    },
    async lookupNumber() {
      this.searchingNumbers = true;
      this.$store
        .dispatch(ACTIONS.GET_SHARED_INBOX_NUMBERS, this.numberLookup)
        .then(() => {
          this.searchTitleView = true;
          this.searchingNumbers = false;
        });
      return this.$store.state.sharedInbox.lookupNumber;
    },
    updatePhoneType(type) {
      this.$store.state.sharedInbox.phoneType = type;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    handleInput() {
      var x = this.inboxForwardingNumber_
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.inboxForwardingNumber_ = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
      this.$emit("forwardingNumber", this.inboxForwardingNumber_);
    },
  },
};
</script>

<style>
.number-selection-body {
  background: #fafafa;
  padding: 15px;
}
.phone-input {
  font-size: 16px;
  letter-spacing: 1px;
  margin: 0 5px;

  width: 60px !important;
  display: inline-block;
  vertical-align: middle;
}
.phone-line-number-text {
  display: inline-block;
  letter-spacing: 2px;
  margin-right: 5px;
  padding: 3px 0;
  vertical-align: middle;
}
.form-phone {
  color: #6c757d;
  font-size: 20px;
  font-weight: 300;
  margin: 0;
}
.search-btn {
  margin-left: 15px;
}
@media (max-width: 575px) {
  .search-btn {
    margin-left: 10px;
    margin-top: 10px;
  }
}
.search-label {
  margin: 20px 0 0 0;
}
.number-search-results {
  background-color: #fafafa;
  max-height: 140px;
  overflow: auto;
  padding: 0 15px 10px;
}
.number-selected {
  margin: 0 0 15px;
  padding: 5px 30px 5px 15px;
}
.fade-in {
  opacity: 1;
}
.phone-number-input-lg {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.5px;
  width: 150px;
}
.number-selected a {
  font-weight: 300;
}
.number-selection-body .number-search-results {
  margin: 0 -15px;
}
.grey-bg {
  background-color: #fafafa;
}
::v-deep .spin svg {
  animation: spin 0.8s infinite linear;
}
</style>
