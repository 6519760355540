<template>
  <div>
    <div
      class="custom-border p-4 mb-4"
      :class="{ 'active-border': permissions.chat.access }"
    >
      <BFormCheckbox
        size="lg"
        v-model="permissions.chat.access"
        aria-label="Web Chat Access"
        switch
        >Web Chat</BFormCheckbox
      >
      <UserAccessChat
        v-on:update-chat-permissions="chatPermissionsHandler"
        :chat="permissions.chat"
      />
    </div>
    <div
      class="custom-border p-4"
      :class="{ 'active-border': permissions.text.access }"
    >
      <BFormCheckbox
        size="lg"
        v-model="permissions.text.access"
        aria-label="Text Messaging Access"
        switch
        @change="textingToggle"
        >Text Messaging</BFormCheckbox
      >
      <UserAccessText
        v-on:update-text-permissions="textPermissionsHandler"
        :text="permissions.text"
      />
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";
import UserAccessChat from "./UserAccessChat";
import UserAccessText from "./UserAccessText";

export default {
  name: "UserRolePicker",
  components: { UserAccessChat, UserAccessText, BFormCheckbox },
  data() {
    return {
      permissions: {
        text: {
          access: null,
          contactCreation: false,
          segments: false,
          role: "user",
        },
        chat: {
          access: null,
          role: "user",
        },
      },
      authorizedFeatures: [],
    };
  },
  mounted() {
    this.populatePermissionsAndFeatures();
  },
  watch: {
    authorizedFeatures() {
      this.$emit("authorized-features-updated", this.authorizedFeatures);
    },
    permissions: {
      handler: "handlePermissions",
      deep: true,
    },
    providedPermissions() {
      this.populatePermissionsAndFeatures();
    },
    providedAuthorizedFeatures() {
      this.populatePermissionsAndFeatures();
    },
  },
  props: {
    providedPermissions: {
      type: Object,
      required: false,
    },
    providedAuthorizedFeatures: {
      type: Array,
      required: false,
    },
  },
  methods: {
    populatePermissionsAndFeatures() {
      this.authorizedFeatures = this.providedAuthorizedFeatures
        ? this.providedAuthorizedFeatures
        : [];
      this.permissions = this.providedPermissions
        ? this.providedPermissions
        : {
            text: {
              access: null,
              contactCreation: false,
              segments: false,
              role: "user",
            },
            chat: {
              access: null,
              role: "user",
            },
          };
    },
    populateAuthorizedFeatures() {
      let arr = [];
      if (
        this.permissions.text.contactCreation &&
        this.permissions.text.role !== "administrator"
      ) {
        arr.push("ManualContactAdd");
      }
      if (
        this.permissions.text.segments &&
        this.permissions.text.role !== "administrator"
      ) {
        arr.push("Segments");
      }
      if (
        this.permissions.text.access &&
        this.permissions.text.role !== "administrator"
      ) {
        arr.push("Texting");
      }
      if (
        this.permissions.chat.access &&
        this.permissions.chat.role !== "administrator"
      ) {
        arr.push("ChatAgent");
      }
      this.authorizedFeatures = arr;
    },
    chatPermissionsHandler(chat) {
      this.permissions.chat = chat;
      this.populateAuthorizedFeatures();
    },
    textPermissionsHandler(text) {
      this.permissions.text = text;
      this.populateAuthorizedFeatures();
    },
    handlePermissions() {
      this.$emit("permissions-updated", this.permissions);
    },
    textingToggle() {
      this.$emit("texting-toggle", this.permissions.text.access);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
::v-deep .custom-border {
  border: 2px solid $gray-lighter;
  border-radius: 4px;
  &.active-border {
    border-color: #318ce7;
  }
  &.active-background {
    background-color: $gray-lightest;
    border: 1px solid $gray-lighter;
  }
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #318ce7 !important;
  background-color: #318ce7 !important;
}

::v-deep .custom-control-label {
  font-weight: 400;
}
</style>
