var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MgFade',{staticClass:"d-flex flex-column w-100",attrs:{"fade":_vm.fadeIn,"appear":true}},[_c('div',{staticClass:"d-flex flex-row w-100",class:{
      'justify-content-end': _vm.outgoing,
      'justify-content-start': !_vm.outgoing,
    }},[_c('MgKendoCard',{staticClass:"d-flex align-items-center",class:{
        incoming: !_vm.outgoing,
        outgoing: _vm.outgoing,
        note: _vm.isNote,
        notNote: !_vm.isNote,
      },attrs:{"orientation":"horizontal","hideHeader":_vm.outgoing,"hideFooter":!_vm.outgoing},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('WebChatVisitorAvatar',{attrs:{"color":_vm.conversation.visitorAvatarColor,"name":_vm.visitorName}})]},proxy:true},{key:"body",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.messageText)}})]},proxy:true},{key:"footer",fn:function(){return [_c('WebChatUserAvatar',{attrs:{"name":_vm.userName,"source":_vm.source,"userAvatar":_vm.isNote ? _vm.noteUserAvatar : _vm.nonNoteUserAvatar,"botName":_vm.botName}})]},proxy:true}])})],1),(_vm.showDateTime)?_c('small',{staticClass:"d-flex flex-row w-100 subtitle",class:{
      outgoing: _vm.outgoing,
      'justify-content-end': _vm.outgoing,
      'justify-content-start': !_vm.outgoing,
      incoming: !_vm.outgoing,
    }},[(_vm.isNote)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.sourceName)+" • Internal Note • ")]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.sourceName)+" • ")]),_vm._v(_vm._s(_vm._f("dateShorthand")(_vm.message.sentOn))+", "+_vm._s(_vm._f("timeShorthand")(_vm.message.sentOn))+"\n  ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }