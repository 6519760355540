import { decapitalize } from "@/util/filters";
import mapKeysDeep from "map-keys-deep-lodash";
import moment from "moment";
import getProfile from "@/util/profile";
import _ from "lodash";
import { formatDate } from "@/util/dates";
import {
  DEFAULT_CHAT_AVATAR_URLS,
  WEBCHAT_FILTERS,
  WEBCHAT_USER_CUSTOMFIELD_DENYLIST,
} from "@/util/constants";
import md5 from "md5";
import api from "@/util/api";

export function transformMarkdownLinksFromBotpress(text) {
  // botpress sends links as markdown (ex: [here](https://yourecool.com/))
  // this regex transforms this format into an anchor link instead
  return text.replace(
    /\[([^[]+)\](\(([^)]*))\)/gim,
    '<a href="$3" target="_blank">$1</a>'
  );
}

export function transformSignalRWebChatMessage(change) {
  let message = {
    authorId: change.target,
    conversationId: change.threadId,
    direction: change.direction === "incoming" ? "in" : "out",
    id: change.id,
    sentOn: change.createdOn,
    payload: change.payload,
  };

  // this is still stupid
  message = mapKeysDeep(message, (value, key) => {
    return decapitalize(key);
  });

  return message;
}

export function camelCaseProperties(obj)
{
  return mapKeysDeep(obj, (value, key) => {
    return decapitalize(key);
  })
}

// assume messages are sorted by timestamp ascending on the way in.
export function insertWebChatMessage(messages, newMessage) {
  // have them fade in as they appear while chatting
  const messageToInsert = {
    ...newMessage,
    fadeIn: true,
  };

  // empty? not anymore
  if (messages.length === 0) {
    return [messageToInsert];
  }
  let lastIndex = messages.length - 1;
  let messageToInsertTime = moment(messageToInsert.ts);

  for (let i = lastIndex; i >= 0; i--) {
    let currentMessageTime = moment(messages[i].ts);

    // new message later than last message? append to messages
    if (
      i === lastIndex &&
      messageToInsertTime.isSameOrAfter(currentMessageTime)
    ) {
      return [...messages, messageToInsert];
    }
    // somewhere in the middle
    else if (messageToInsertTime.isSameOrAfter(currentMessageTime)) {
      return [
        ...messages.slice(0, i),
        messageToInsert,
        ...messages.slice(i + 1, lastIndex),
      ];
    }
  }

  // at the start. if we got here, the timestamp's probably screwed up, so
  // just stick it at the end.
  return [...messages, messageToInsert];
}

export function lastMessageSort(a, b) {
  if (
    moment(_.get(a, "lastMessage.sentOn")).isBefore(
      moment(_.get(b, "lastMessage.sentOn"))
    )
  ) {
    return 1;
  } else if (
    moment(_.get(a, "lastMessage.sentOn")).isAfter(
      moment(_.get(b, "lastMessage.sentOn"))
    )
  ) {
    return -1;
  } else {
    return 0;
  }
}

export function conversationCreatedSort(a, b) {
  if (moment(a.createdOn).isBefore(moment(b.createdOn))) {
    return 1;
  } else if (moment(a.createdOn).isAfter(moment(b.createdOn))) {
    return -1;
  } else {
    return 0;
  }
}

export function chatRequestedSort(a, b) {
  if (moment(a.assignedOn).isBefore(moment(b.assignedOn))) {
    return 1;
  } else if (moment(a.assignedOn).isAfter(moment(b.assignedOn))) {
    return -1;
  } else {
    return 0;
  }
}

export function isMessageOutgoing(message) {
  return (
    message.direction === "out" ||
    message.payload.agent ||
    message.authorId === "00000000-0000-0000-0000-000000000000"
  );
}

export function visitorFullName(conversation) {
  const firstName = _.get(conversation, "firstName");
  const lastName = _.get(conversation, "lastName");

  return (
    (firstName || "") + (firstName && lastName ? " " : "") + (lastName || "")
  );
}

export function visitorCustomFields(conversation) {
  // the customFields property is being modified into an array of objects for iterability purposes
  let customFields = _.get(conversation, "additionalFields");

  if (!customFields) {
    return [];
  } else {
    const customFieldKeys = Object.keys(customFields).filter(
      (key) => !WEBCHAT_USER_CUSTOMFIELD_DENYLIST.find((el) => el === key)
    );

    return customFieldKeys.map((key) => {
      const customFieldValue = customFields[key];
      // this regex checks that the value is an ISO 8601 datetime string
      const iso8601Format = /^(\d{4})-0?(\d+)-0?(\d+)[T ]0?(\d+):0?(\d+):0?(\d+)[.]0?(\d+)Z$/;

      const filteredValues = function () {
        if (iso8601Format.test(customFieldValue)) {
          return formatDate(customFieldValue, "MM/DD/YYYY", true);
        } else {
          return customFieldValue;
        }
      };

      return { name: key, value: filteredValues() };
    });
  }
}

export function studentType(conversation) {
  return _.get(conversation, "studentType");
}

export function visitorType(conversation) {
  return _.get(conversation, "visitorType");
}

export function visitorDateOfBirth(conversation) {
  return formatDate(_.get(conversation, "dateOfBirth"), "MM/DD/YYYY", true);
}

export function visitorFirstName(conversation) {
  return _.get(conversation, "firstName");
}

export function visitorLastName(conversation) {
  return _.get(conversation, "lastName");
}

export function visitorShortName(conversation) {
  const firstName = _.get(conversation, "firstName");
  const lastName = _.get(conversation, "lastName");

  return (
    (firstName || "") +
    (firstName && lastName ? " " : "") +
    ((lastName && `${lastName.slice(0, 1)}.`) || "")
  );
}

export function visitorCity(conversation) {
  return _.get(conversation, "city");
}

export function visitorRegionCode(conversation) {
  return _.get(conversation, "regionCode");
}

export function visitorRegionName(conversation) {
  return _.get(conversation, "regionName");
}

export function visitorCountry(conversation) {
  return _.get(conversation, "countryName");
}

export function visitorLocation(conversation) {
  let city = visitorCity(conversation);
  let regionCode = visitorRegionCode(conversation);
  let regionName = visitorRegionName(conversation);

  if (city && city !== "null" && regionCode.toLowerCase() !== "other") {
    return `${city}, ${regionCode}`;
  } else if (city && city !== "null" && regionCode.toLowerCase() === "other") {
    return `${city}, ${regionName}`;
  } else {
    return "";
  }
}

export function visitorLocationHistory(conversation) {
  let entryUrl = _.get(conversation, "entryUrl");
  let createdOn = _.get(conversation, "createdOn");
  return [{ pageUrl: entryUrl, timestamp: createdOn }];
}

export function visitorPhone(conversation) {
  return _.get(conversation, "mobilePhone");
}

export function visitorEmail(conversation) {
  return _.get(conversation, "emailAddress");
}

export function visitorConsentToText(conversation) {
  return _.get(conversation, "textOptIn", false);
}

export function visitorTextOptIn(conversation) {
  let textOptInTimestamp = _.get(conversation, "textOptInTimestamp", null);
  if (!textOptInTimestamp && !visitorConsentToText(conversation))
    return "Unknown";

  return visitorConsentToText(conversation) ? "Yes" : "No";
}

export function messageUserAvatar(message) {
  return _.get(message, "payload.channel.web.avatarUrl");
}

export function gravatarUrlWithBlankImage(email) {
  return email
    ? `https://www.gravatar.com/avatar/${md5(email)}?r=pg&d=blank`
    : "";
}

export async function gravatarUrlWithDefaultImage(email, botId) {
  let response = await api.get(`${botId}/hitl/avatar?emailHash=${md5(email)}`);
  return response.avatarUrl;
}

export function messageUserName(message) {
  return _.get(message, "payload.channel.web.userName");
}

export function isUserAssisted(conversation) {
  return _.get(conversation, "assignedStaffId") > 0;
}

export function isClosed(conversation) {
  return _.get(conversation, "closed");
}

export function isRead(conversation) {
  return _.get(conversation, "assignedConversationRead");
}

export function hasNewIncomingMessages(conversation) {
  return _.get(conversation, "hasNewIncomingMessages");
}

export function lastMessageSentOn(conversation) {
  return _.get(conversation, "lastMessage.sentOn");
}

export function lastMessageText(conversation) {
  return _.get(conversation, "lastMessage.payload.text");
}

export function isMyConversation(conversation) {
  return (
    isConversationAssignedToCurrentUser(conversation) &&
    !isClosed(conversation) &&
    !isFollowUp(conversation)
  );
}

export function isChatRequested(conversation) {
  return _.get(conversation, "chatRequested", false);
}

export function isAssigned(conversation) {
  let assignedStaffId = _.get(conversation, "assignedStaffId");
  return !!assignedStaffId;
}

export function isFollowUp(conversation) {
  return _.get(conversation, "followUp");
}

export function isUnassignedConversation(conversation, bot) {
  return (
    isNoAssignmentMode(bot) &&
    isChatRequested(conversation) &&
    isConversationOwnedByCurrentTeam(conversation) &&
    !isClosed(conversation) &&
    !isAssigned(conversation) &&
    !isFollowUp(conversation)
  );
}

export function isOtherConversation(conversation, bot) {
  return (
    !isUnassignedConversation(conversation, bot) &&
    !isMyConversation(conversation) &&
    !isFollowUp(conversation)
  );
}

export function canSMSFromChat(conversation) {
  const profile = getProfile();
  return (
    _.get(profile, "currentDepartment.teamHasSms", false) &&
    _.get(profile, "currentDepartment.userHasTexting", false) &&
    visitorPhone(conversation)
  );
}

export function isDefaultAvatar(url) {
  return DEFAULT_CHAT_AVATAR_URLS.includes(url);
}

export function isMessageInternalNote(message) {
  return _.get(message, "payload.type", "").toLowerCase() === "note";
}

export function isMessageInternalLog(message) {
  return _.get(message, "payload.type", "").toLowerCase() === "log";
}

export function isConversationAssignedToCurrentUser(conversation) {
  return _.get(conversation, "assignedStaffId") === _.get(getProfile(), "id");
}

export function isConversationOwnedByCurrentTeam(conversation) {
  const profile = getProfile();
  return (
    _.get(profile, "currentDepartment.id") ===
    _.get(conversation, "assignedTeamId")
  );
}

export function isAutomaticAssignmentMode(bot) {
  return _.get(bot, "assignmentType", "Automatic") === "Automatic";
}

export function isNoAssignmentMode(bot) {
  return !isAutomaticAssignmentMode(bot);
}

export function conversationTypeDisplayText(
  conversationType,
  followUpRequested,
  liveChatOnly,
  isTooltip
) {
  const base = {
    [WEBCHAT_FILTERS.ALL]: "All",
    [WEBCHAT_FILTERS.USER_ASSISTED]: "Live Chat",
    [WEBCHAT_FILTERS.BOT_ONLY]: liveChatOnly ? "Unassisted" : "Bot-Only",
  }[conversationType];

  return `${base}${!isTooltip ? " conversations" : ""}${
    followUpRequested ? " with Follow up requested" : ""
  }`;
}
