<template>
  <div id="renxt-integration-export-settings" class="d-flex flex-column h-100">
    <SubpageHeader
      headerTitle="Raiser's Edge NXT Export Settings"
      backHref="/#/integrations/renxt/exports/"
      class="mb-3"
    />

    <div class="renxt-export-page-content overflow-auto">
      <div v-show="!isLoading">
        <BAlert variant="warning" :show="!isLoading && !isImportsConfigured">
          <MgIcon
            class="align-top text-warning error-icon"
            scale="1.25"
            name="exclamation-triangle"
          />
          <h2 class="h4 alert-heading font-weight-bold">
            Imports Unconfigured
          </h2>
          <p class="my-2">
            To ensure that Cadence and Raiser&rsquo;s Edge NXT data stays in
            sync, we recommend configuring Imports prior and completing Required
            Field mapping, as well as Opt Out field mapping before configuring
            exports.
          </p>
          <a href="/#/integrations/renxt/imports/settings">Import Settings</a>
        </BAlert>

        <BCard
          header="Enable Export Message Activity"
          header-tag="h2"
          class="mb-4"
        >
          <p>
            Receive real-time updates in Raiser&rsquo;s Edge NXT when text
            messages are sent and received.
          </p>
          <div class="form-group w-100 m-0">
            <BFormCheckbox
              id="enableMsgActivity"
              :disabled="!isImportsConfigured"
              v-model="enableMessagePush"
              switch
            >
              <span class="form-check-label font-weight-normal">
                Export Message Activity
              </span>
            </BFormCheckbox>
          </div>
          <transition name="fade">
            <div v-show="enableMessagePush">
              <hr />
              <h3>Export Destination</h3>
              <div class="form-group w-100 m-0">
                <BFormCheckbox
                  id="enableExportToActions"
                  :disabled="!isImportsConfigured"
                  v-model="exportToActions"
                >
                  <span class="form-check-label font-weight-normal">
                    Export to Actions
                  </span>
                </BFormCheckbox>
              </div>
              <transition name="fade">
                <div class="pl-4 my-3" v-show="exportToActions">
                  <label class="mb-1">Action Category</label>
                  <BFormRadioGroup
                    stacked
                    v-model="exportSettings.actionCategory"
                  >
                    <BFormRadio value="phone"
                      ><span class="font-weight-normal"
                        >Phone Call</span
                      ></BFormRadio
                    >
                    <BFormRadio value="task"
                      ><span class="font-weight-normal">Task</span></BFormRadio
                    >
                  </BFormRadioGroup>
                  <label for="actionType" class="form-group w-100 m-0 mt-3 mb-2"
                    >Action Type</label
                  >
                  <div class="w-100">
                    <BFormSelect
                      id="actionType"
                      class="w-25"
                      v-model="exportSettings.actionType"
                      :options="actionTypes"
                    >
                      <template #first>
                        <BFormSelectOption value="" disabled>
                          Select Action Type
                        </BFormSelectOption>
                      </template>
                    </BFormSelect>
                  </div>
                  <div class="message-field w-100 mt-3">
                    <h4>Message Fields</h4>
                    <p>
                      The message text, sender, and date are included in the
                      export.
                    </p>
                    <div>
                      <label
                        for="messageCampaign"
                        class="form-group w-100 m-0 mb-2"
                      >
                        Message Campaign
                      </label>
                      <BFormSelect
                        id="messageCampaign"
                        class="w-25"
                        v-model="exportSettings.actionMessageCampaignName"
                        :options="customFieldCategories"
                      >
                        <template #first>
                          <BFormSelectOption value="" disabled>
                            Select Custom Field Category
                          </BFormSelectOption>
                        </template>
                      </BFormSelect>
                    </div>
                    <div class="mt-3">
                      <label for="messageTag" class="form-group w-100 m-0 mb-2">
                        Message Tag
                      </label>
                      <BFormSelect
                        id="messageTag"
                        class="w-25"
                        v-model="exportSettings.actionMessageTag"
                        :options="customFieldCategories"
                      >
                        <template #first>
                          <BFormSelectOption value="" disabled>
                            Select Custom Field Category
                          </BFormSelectOption>
                        </template>
                      </BFormSelect>
                    </div>
                  </div>
                  <hr class="ml-n4" />
                </div>
              </transition>

              <div class="form-group w-100 m-0">
                <BFormCheckbox
                  id="enableExportToNotes"
                  :disabled="!isImportsConfigured"
                  v-model="exportToNotes"
                >
                  <span class="form-check-label font-weight-normal">
                    Export to Notes
                  </span>
                </BFormCheckbox>
              </div>

              <transition name="fade">
                <div
                  class="form-group w-100 m-0 mt-3 pl-4"
                  v-show="exportToNotes"
                >
                  <label for="noteType">
                    Note Type
                    <span class="text-muted ml-1">(required)</span>
                  </label>
                  <div class="w-100">
                    <BFormSelect
                      id="noteType"
                      class="w-25"
                      v-model="noteType"
                      :options="noteTypes"
                      required
                    >
                      <template #first>
                        <BFormSelectOption value="" disabled>
                          Select Note Type
                        </BFormSelectOption>
                      </template>
                    </BFormSelect>
                  </div>
                </div>
              </transition>
            </div>
          </transition>
        </BCard>
        <BCard header="Enable Opt-Out Status Changes" header-tag="h2">
          <p>
            Opt-out preferences are maintained by the most up to date SMS
            channel from the Constituent Record in Raiser&rsquo;s Edge NXT. This
            selection occurs in Import Settings field mapping which enables
            automatic exports of contact's opt-out preference changes from
            Cadence to Raiser&rsquo;s Edge NXT, keeping both systems in sync.
            Changes to the Consent Category Channel, must be done in Import
            Settings. If the field is unmapped, an export will not run.
          </p>
          <div class="form-group w-100 m-0 mb-3">
            <BFormCheckbox
              id="enableOptOutChanges"
              :disabled="!isImportsConfigured || !isSmsLinkedCategoryMapped"
              v-model="enableOptOutPush"
              switch
            >
              <span class="form-check-label font-weight-normal">
                Export Opt-Out Status Changes
              </span>
            </BFormCheckbox>
          </div>
          <div class="form-group m-0">
            <label for="cadenceFieldInput">Consent Channel Category</label>
            <input
              id="cadenceFieldInput"
              class="form-control w-25"
              v-model="smsLinkedCategory"
              disabled
            />
          </div>
        </BCard>
      </div>
      <div
        class="container-fluid form-actions sticky-bottom"
        v-show="!isLoading"
      >
        <button
          class="btn btn-primary"
          :disabled="!isImportsConfigured || isSaveDisabled"
          @click="updateExportSettings"
        >
          Save
        </button>
        <a class="btn btn-light ml-1" href="/#/integrations/renxt/exports/">
          Cancel
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  BAlert,
  BCard,
  BFormCheckbox,
  ToastPlugin,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ACTIONS } from "../../renxtStore";
import analytics from "@/util/analytics";
import api from "@/util/api";
import MgIcon from "@/components/MgIcon";
import store from "@/store";
import SubpageHeader from "@/components/SubpageHeader";
import Toast from "@/components/Toast";
import Vue from "vue";

Vue.use(ToastPlugin);

export default {
  name: "RenxtExportSettingsPage",
  store,
  components: {
    BAlert,
    BCard,
    BFormCheckbox,
    MgIcon,
    SubpageHeader,
    BFormRadio,
    BFormRadioGroup,
  },
  data() {
    return {
      isLoading: true,
      exportToNotes: false,
      exportToActions: false,
      enableMessagePush: false,
      enableOptOutPush: false,
      noteType: "",
      noteTypes: [],
      actionTypes: [],
      actionCategory: "",
      customFieldCategories: [],
    };
  },
  async created() {
    await this.$store.dispatch(ACTIONS.GET_RENXT_CONFIG);
    this.noteTypes = await api.get("/RaisersEdgeNxt/note-types");
    this.actionTypes = await api.get("/RaisersEdgeNxt/action-types");
    this.customFieldCategories = await api.get(
      "/RaisersEdgeNxt/action-custom-field-categories"
    );
    this.isLoading = false;
  },
  computed: {
    exportSettings() {
      return _.cloneDeep(this.$store.state.renxt.exportSettings);
    },
    isSmsLinkedCategoryMapped() {
      return (
        this.$store.state.renxt.importSettings.optOut.smsLinkedCategory !== ""
      );
    },
    smsLinkedCategory() {
      return this.isSmsLinkedCategoryMapped
        ? this.$store.state.renxt.importSettings.optOut.smsLinkedCategory
        : "Field Not Mapped";
    },
    isImportsConfigured() {
      return (
        this.$store.state.renxt.importSettings.constituentListId !==
        "00000000-0000-0000-0000-000000000000"
      );
    },
    isSaveDisabled() {
      return this.exportToNotes && this.noteType === "";
    },
  },
  methods: {
    async updateExportSettings() {
      try {
        await this.$store.dispatch(ACTIONS.UPDATE_RENXT_CONFIG, {
          exports: {
            ...this.exportSettings,
            exportToNotes: this.exportToNotes,
            exportToActions: this.exportToActions,
            enableMessagePush: this.enableMessagePush,
            enableOptOutPush: this.enableOptOutPush,
            noteType: this.noteType,
          },
        });

        analytics.track("Updated Export Settings", {
          integrationName: "Renxt",
          messageActivityExport: this.exportSettings.enableMessagePush,
          exportToNotes: this.exportToNotes,
          exportToActions: this.exportToActions,
          enableMessagePush: this.enableMessagePush,
          actionCategory: this.exportSettings.actionCategory,
          enableOptOutPush: this.exportSettings.enableOptOutPush,
        });

        let toastBody = this.$createElement(Toast, {
          props: {
            message: "Export Settings Updated",
          },
        });
        this.$bvToast.toast([toastBody], {
          variant: "success",
          noCloseButton: true,
          solid: true,
        });
      } catch {
        let toastBody = this.$createElement(Toast, {
          props: {
            message: "Something went wrong",
          },
        });
        this.$bvToast.toast([toastBody], {
          variant: "danger",
          noCloseButton: true,
          solid: true,
        });
      }
    },
  },
  destroyed() {},
  watch: {
    exportSettings() {
      // exportToNotes is the only attribute that needs to be reactive due to the note type dropdown
      this.exportToNotes = this.exportSettings.exportToNotes;
      this.exportToActions = this.exportSettings.exportToActions;
      this.enableMessagePush = this.exportSettings.enableMessagePush;
      this.enableOptOutPush = this.exportSettings.enableOptOutPush;
      this.noteType = this.exportSettings.noteType;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.renxt-export-page-content {
  padding: 20px 30px 0;
}

.form-check-label {
  font-size: $font-size-base;
}

.message-field {
  padding: 20px;
  background-color: $gray-light-bg;
}
</style>
