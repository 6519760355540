<template>
  <div id="webChatVisitorDetailsLocationHistory" class="d-flex flex-column">
    <strong class="mb-2">Page History</strong>
    <WebChatVisitorDetailsLocation :location="firstLocation" />
  </div>
</template>
<script>
import WebChatVisitorDetailsLocation from "./WebChatVisitorDetailsLocation";
import { visitorLocationHistory } from "@/util/webchat";

export default {
  name: "WebChatVisitorDetailsLocationHistory",
  components: { WebChatVisitorDetailsLocation },
  props: {
    conversation: {
      type: Object,
      required: false,
    },
  },
  computed: {
    firstLocation() {
      return visitorLocationHistory(this.conversation)[0];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

#webChatVisitorDetailsLocationHistory {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid $gray-pane-border;
}
</style>
