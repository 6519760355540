<template>
  <BListGroup>
    <InboxRow
      :inbox="personalInbox"
      v-if="personalInbox.enabled"
      @click:disable-ooo="afterOOODisabled"
      isPersonalInbox
    />

    <InboxRow
      v-for="inbox in enabledSharedInboxes"
      :key="inbox.id"
      :inbox="inbox"
      @click:disable-ooo="afterOOODisabled"
    />
  </BListGroup>
</template>

<script>
import serializeProfile from "@/util/profile";
import { BListGroup } from "bootstrap-vue";
import InboxRow from "./InboxRow.vue";
import _ from "lodash";
import api from "@/util/api";
import eventBus from "@/util/eventBus";

export default {
  name: "OutOfOfficeDialogV2",
  props: {},
  components: { InboxRow, BListGroup },
  data() {
    return { checked: true, profile: {} };
  },
  computed: {
    personalInbox() {
      if (_.isNull(this.profile.outOfOfficeConfig)) {
        return { enabled: false };
      } else {
        return this.profile.outOfOfficeIsActive
          ? { enabled: true }
          : { enabled: false };
      }
    },
    enabledSharedInboxes() {
      if (_.isNull(this.profile.sharedInboxes)) {
        return [];
      } else {
        let sharedInboxes = _.get(this.profile, "groupAccounts", []);
        return _.filter(sharedInboxes, {
          outOfOfficeIsActive: true,
        });
      }
    },
  },
  methods: {
    async toggleOutOfOffice(event) {
      if (!event.detail.enabled) {
        await this.afterOOODisabled();
      } else {
        this.profile = serializeProfile();
      }
    },
    async afterOOODisabled() {
      let p = await api.get("profile", {
        headers: { "x-disable-loading": true },
      });
      window.localStorage.setItem("profile", JSON.stringify(p));
      this.profile = serializeProfile();

      if (
        !this.personalInbox.enabled &&
        this.enabledSharedInboxes.length === 0
      ) {
        eventBus.dispatchEvent("close-ooo-dialog");
      }
    },
  },
  created() {
    this.profile = serializeProfile();

    eventBus.addEventListener("toggle-out-of-office", this.toggleOutOfOffice);
  },
  destroyed() {
    eventBus.removeEventListener("toggle-out-of-office");
  },
};
</script>

<style lang="scss" scoped>
::v-deep .list-group-item {
  padding: 20px;
}

::v-deep .list-group-item:first-child {
  padding-top: 0;
}

::v-deep .list-group-item:last-child {
  padding-bottom: 0;
}
</style>
