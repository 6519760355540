<template>
  <div v-if="text.access || textOnly" label="Individual radios" stacked>
    <div
      class="custom-border p-3 mt-4 mb-3"
      :class="{ 'active-background': text.role === 'user' }"
    >
      <input
        type="radio"
        aria-describedby="userAccess"
        name="text-access-radios"
        v-model="text.role"
        value="user"
        id="textUser"
      />
      <label for="textUser">
        User
        <p id="userAccess" class="mb-0 font-weight-light">
          Send and receive text messages, customizable access to features, no
          access to team metrics or user and data management
        </p>
      </label>

      <BFormCheckbox
        v-model="text.contactCreation"
        aria-describedby="contactCreation"
        v-if="text.role !== 'administrator'"
        class="mt-3 ml-4 checkbox-label"
        >Contact Creation
        <p
          id="contactCreation"
          v-if="text.role !== 'administrator'"
          class="font-weight-light"
        >
          Manually add contacts that have not been imported
        </p>
      </BFormCheckbox>

      <BFormCheckbox
        v-model="text.segments"
        aria-describedby="segmentsAccess"
        v-if="text.role !== 'administrator'"
        class="ml-4 checkbox-label"
        >Segments
        <p
          id="segmentsAccess"
          v-if="text.role !== 'administrator'"
          class="mb-0 font-weight-light"
        >
          Create, edit, and send to a group of contacts
        </p>
      </BFormCheckbox>
    </div>
    <div
      class="custom-border p-3"
      :class="{ 'active-background': text.role === 'administrator' }"
    >
      <input
        type="radio"
        aria-describedby="adminAccess"
        name="text-access-radios"
        v-model="text.role"
        value="administrator"
        id="textAdmin"
      />
      <label for="textAdmin"
        >Administrator
        <p id="adminAccess" class="mb-0 font-weight-light">
          Send and receive text messages, manage users and data, and view
          advanced reporting and team metrics
        </p>
      </label>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from "bootstrap-vue";

export default {
  name: "UserAccessText",
  components: { BFormCheckbox },
  data() {
    return {
      authorizedFeatures: [],
    };
  },
  props: {
    text: {
      type: Object,
      required: false,
      default() {
        return {
          contactCreation: true,
          segments: true,
          role: "user",
        };
      },
    },
    textOnly: {
      type: Boolean,
    },
  },
  watch: {
    text: {
      handler: "handleText",
      deep: true,
    },
  },
  mounted() {
    if (this.textOnly) {
      this.handleText({
        access: null,
        contactCreation: true,
        segments: true,
        role: "user",
      });
    }
  },
  methods: {
    populateAuthorizedFeatures(permissions) {
      let arr = [];
      if (
        permissions.text.contactCreation &&
        permissions.text.role !== "administrator"
      ) {
        arr.push("ManualContactAdd");
      }
      if (
        permissions.text.segments &&
        permissions.text.role !== "administrator"
      ) {
        arr.push("Segments");
      }
      if (
        permissions.text.access &&
        permissions.text.role !== "administrator"
      ) {
        arr.push("Texting");
      }
      this.authorizedFeatures = arr;
    },
    handleText(newTextVal) {
      if (this.textOnly) {
        let permissions = {
          chat: { access: null },
          text: {
            access: true,
            role: newTextVal.role,
            contactCreation: newTextVal.contactCreation,
            segments: newTextVal.segments,
          },
        };
        this.populateAuthorizedFeatures(permissions);
        this.$emit("update-authorized-features-text", this.authorizedFeatures);
        this.$emit("update-text-permissions", permissions);
      } else {
        this.$emit("update-text-permissions", newTextVal);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
::v-deep .custom-border {
  border: 1px solid $gray-lighter !important;
  border-radius: 4px;
  &.active-border {
    border-color: #318ce7;
  }
  &.active-background {
    background-color: $gray-lightest;
  }
}
::v-deep input[type="radio"] {
  vertical-align: top;
  margin-top: 5px;
  accent-color: #318ce7 !important;
  -ms-transform: scale(1.25); /* IE 9 */
  -webkit-transform: scale(1.25); /* Chrome, Safari, Opera */
  transform: scale(1.25);
  position: absolute;
}
::v-deep label {
  font-weight: 400;
  font-size: 16px;
  margin-left: 24px;
  margin-bottom: 0;
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
::v-deep .font-weight-light {
  font-size: 14px;
}

::v-deep .checkbox-label .custom-control-label {
  font-size: 14px !important;
}

::v-deep .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #318ce7;
  background-color: #318ce7;
}
</style>
