import { WEBCHAT_FILTERS } from "@/util/constants";
import { Howl } from "howler";
import actions from "@/store/modules/webChat/actions";
import getters from "@/store/modules/webChat/getters";
import mutations from "@/store/modules/webChat/mutations";

export default {
  state: () => ({
    bot: null,
    selectedConversation: null,
    selectedConversationMessages: [],
    conversations: new Map(),
    signalRConnection: null,
    signalRHeartbeat: null,
    signalRConnectionRetry: null,
    unreadMyConversationsCount: 0,
    unreadUnassignedConversationsCount: 0,
    totalFollowUps: 0,
    totalOtherConversations: 0,
    visitorAvatarColorIndex: 0,
    availableToChat: false,
    showMobileConversationView: false,
    filters: {
      [WEBCHAT_FILTERS.USER_ASSISTED]: false,
      [WEBCHAT_FILTERS.BOT_ONLY]: false,
      [WEBCHAT_FILTERS.CONVERSATION_OPEN]: false,
      [WEBCHAT_FILTERS.CONVERSATION_CLOSED]: false,
    },
    typingText: "",
    typers: new Map(),
    incomingTypingTimer: null,
    outgoingTypingTimer: null,
    incomingMessageSound: new Howl({
      src: ["/sounds/message-alert.wav"],
      volume: 1.0,
    }),
    templateList: [],
  }),
  actions,
  getters,
  mutations,
};
