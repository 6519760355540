<template>
  <MgCard
    class="mt-3 mb-3 ml-md-3 mr-md-3"
    :class="{ 'first-card': firstCard, 'last-card': lastCard }"
    :strongHeader="strongHeaderValue ? value || '0' : ''"
    :bigHeader="!strongHeaderValue ? value || '0' : ''"
    :bigSubHeader="percent ? `| ${percent}%` : ''"
    :header="title"
    :description="description"
    :horizontalLayout="horizontalLayout"
    ><slot />
  </MgCard>
</template>

<script>
import MgCard from "@/components/MgCard";

export default {
  name: "SimplePercentMetrics",
  components: {
    MgCard,
  },
  props: {
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },
    strongHeaderValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    percent: {
      type: Number,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    firstCard: {
      type: Boolean,
      required: false,
      default: false,
    },
    lastCard: {
      type: Boolean,
      required: false,
      default: false,
    },
    horizontalLayout: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/variables";
@import "src/scss/utilities";

.card {
  width: 100%;
  color: $gray;

  @include media-breakpoint-up(lg) {
    &.first-card {
      margin-left: 0 !important;
    }

    &.last-card {
      margin-right: 0 !important;
    }
  }
}

.value {
  font-weight: $font-weight-normal;
  font-size: $h1-font-size;
}

.header-value {
  font-size: 3rem;
  font-weight: $font-weight-bold;
}

.title {
  font-weight: $font-weight-bold;
}
</style>
