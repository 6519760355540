import { GETTERS } from "../util/constants";
import _ from "lodash";

export default {
  [GETTERS.GET_CURRENT_DEPARTMENT]: (state) => {
    if (!state.contact.details) return "";
    return _.find(state.contact.details.departments, (department) => {
      return department.id == state.config.team.id;
    });
  },
  [GETTERS.GET_CAMPAIGN_NAME_BY_ID]: (state) => (id) => {
    if (!state.campaigns && !state.campaigns.names) return;
    let campaign = state.campaigns.names.find(
      (campaign) => parseInt(campaign.id) === parseInt(id)
    );
    return campaign ? campaign.name : "Campaign Not Found";
  },
  [GETTERS.FEATURE_ENABLED]: (state) => (feature) => {
    return state.config.features[feature];
  },
  [GETTERS.RADIUS_TARGET_BY_ID]: (state) => (id) => {
    if (!state.radiusTargets) return;
    let target = state.radiusTargets.find((t) => t.entityId === id);
    return target ? target.name : null;
  },
  [GETTERS.USER_NAME_BY_ID]: (state) => (id) => {
    if (!state.users) return;
    let user = state.users.find((user) => parseInt(user.id) === parseInt(id));
    return user ? user.displayName : "";
  },
  [GETTERS.USER_EMAIL_BY_ID]: (state) => (id) => {
    if (!state.users) return;
    let user = state.users.find((user) => parseInt(user.id) === parseInt(id));
    return user ? user.emailAddress : "";
  },
  [GETTERS.CONNECT_FILTER_BY_ID]: (state) => (id) => {
    if (!state.connectFilters) return;
    let filter = state.connectFilters.find((f) => f.id === id);
    return filter ? filter.name : null;
  },
  [GETTERS.SLATE_QUERY_BY_ID]: (state) => (id) => {
    if (!state.slateQueries) return;
    let query = state.slateQueries.find((f) => f.id === id);
    return query ? query.name : null;
  },
  [GETTERS.RE_NXT_LIST_BY_ID]: (state) => (id) => {
    if (!state.reNxtLists) return;
    let list = state.reNxtLists.find((f) => f.id === id);
    return list ? list.name : null;
  },
};
