import { render, staticRenderFns } from "./AnalyticsInboxDetailBreakdown.vue?vue&type=template&id=cdfc964a&scoped=true&"
import script from "./AnalyticsInboxDetailBreakdown.vue?vue&type=script&lang=js&"
export * from "./AnalyticsInboxDetailBreakdown.vue?vue&type=script&lang=js&"
import style0 from "./AnalyticsInboxDetailBreakdown.vue?vue&type=style&index=0&id=cdfc964a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdfc964a",
  null
  
)

export default component.exports