<template>
  <MgKendoCard orientation="horizontal" hideHeader hideFooter>
    <template v-slot:body>
      <div class="d-flex flex-column">
        <strong class="field-name" :alt="fieldName">{{ fieldName }} </strong>
        <slot name="value"></slot>
      </div>
    </template>
  </MgKendoCard>
</template>
<script>
import MgKendoCard from "@/components/MgKendoCard";

export default {
  name: "WebChatVisitorDetailsCustomField",
  components: { MgKendoCard },
  props: {
    fieldName: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/utilities";
@import "../../scss/variables";

.k-card {
  border: none;
}

::v-deep .k-card-header {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  padding-right: 1rem;
  padding-left: 0;
}

::v-deep .k-card-body {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0.125rem;
  border: none;
  background: none;
}

.field-icon {
  color: $purple-chat;
}

.field-name {
  margin-bottom: -3px;
}
</style>
