import TextAnalyticsPage from "./analytics/TextAnalyticsPage";
import AnalyticsInboxDetail from "./analytics/inbox-details/AnalyticsInboxDetail";
import AutomationSettings from "../components/AutomationSettings";
import AutomationStatus from "../components/AutomationStatus";
import CampaignPicker from "../components/CampaignPicker";
import CampaignTag from "../components/CampaignTag";
import ChatAnalyticsPage from "./reporting/chat/ChatAnalyticsPage";
import ContactCard from "../components/ContactCard";
import ContactDetails from "../components/ContactDetails";
import ContactProfile from "../components/ContactProfile";
import ContactsGrid from "./contacts/ContactsGrid";
import InboxConvo from "./inbox/InboxConvo";
import ConversationItem from "./inbox/ConversationItem";
import CreditBalance from "../components/CreditBalance";
import ConversationList from "./inbox/ConversationList";
import ExportPage from "./analytics/export/ExportPage";
import PopoverSearch from "../components/PopoverSearch";
import SubpageHeader from "../components/SubpageHeader";
import MgIcon from "../components/MgIcon";
import OutOfOfficeDialogV2 from "../components/OutOfOfficeDialog";
import OutOfOfficeStatusIndicator from "../components/OutOfOfficeStatusIndicator";
import OutOfOfficeTab from "./inbox/outofoffice";
import RenxtPage from "./integrations/renxt";
import RenxtImportSettingsPage from "./integrations/renxt/imports/settings";
import RenxtExportSettingsPage from "./integrations/renxt/exports/settings";
import RecruitPage from "./integrations/recruit";
import RecruitExportSettingsPage from "./integrations/recruit/exports/settings";
import RecruitImportSettingsPage from "./integrations/recruit/imports/settings";
import SegmentCriteria from "../components/SegmentCriteria/SegmentCriteria";
import ScheduledMessages from "../components/ScheduledMessages";
import InboxSentTab from "../components/InboxSentTab";
import SharedInboxPage from "./sharedInbox/SharedInboxPage";
import Store from "../components/Store";
import StoreInbox from "../components/StoreInbox";
import TagListPage from "./tags/TagListPage";
import TagDetailPage from "./tags/details/TagDetailPage";
import UserAccessChat from "../components/UserRolePicker/UserAccessChat";
import UserAccessChatOnly from "../components/UserAccessChatOnly";
import UserAccessText from "../components/UserRolePicker/UserAccessText";
import UserAccessTextOnly from "../components/UserAccessTextOnly";
import UserRolePicker from "../components/UserRolePicker";
import LegacyEmojiPickerComposeMenu from "../components/LegacyEmojiPickerComposeMenu";
import LegacyTemplatePicker from "../components/LegacyTemplatePicker";
import WebChatPage from "../components/WebChatPage";
import WebChatAvailabilityDropdown from "../components/WebChatAvailabilityDropdown";
import WebChatConversationCount from "../components/WebChatConversationCount";

import "@/scss/production.scss";

export default {
  TextAnalyticsPage,
  AnalyticsInboxDetail,
  AutomationSettings,
  AutomationStatus,
  CampaignPicker,
  CampaignTag,
  ChatAnalyticsPage,
  ContactCard,
  ContactDetails,
  ContactsGrid,
  ContactProfile,
  ConversationItem,
  ConversationList,
  CreditBalance,
  InboxConvo,
  ExportPage,
  OutOfOfficeDialogV2,
  OutOfOfficeStatusIndicator,
  OutOfOfficeTab,
  PopoverSearch,
  SubpageHeader,
  MgIcon,
  RecruitPage,
  RecruitExportSettingsPage,
  RecruitImportSettingsPage,
  RenxtPage,
  RenxtImportSettingsPage,
  RenxtExportSettingsPage,
  ScheduledMessages,
  SegmentCriteria,
  InboxSentTab,
  SharedInboxPage,
  Store,
  StoreInbox,
  TagListPage,
  TagDetailPage,
  UserAccessChat,
  UserAccessChatOnly,
  UserAccessText,
  UserAccessTextOnly,
  UserRolePicker,
  LegacyEmojiPickerComposeMenu,
  LegacyTemplatePicker,
  WebChatPage,
  WebChatAvailabilityDropdown,
  WebChatConversationCount,
};
