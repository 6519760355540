var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VueCalendar',{ref:"calendar",staticClass:"scheduled-messages-calendar",attrs:{"masks":_vm.masks,"theme":_vm.themeStyles},scopedSlots:_vm._u([{key:"header",fn:function(props){return [_c('div',{staticClass:"header-container"},[_c('div',{staticClass:"month-navigation mb-3 d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"current-month",domProps:{"innerHTML":_vm._s(props.title)}}),_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"change-wrapper mr-4 d-flex justify-content-between"},[_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.changeMonth(-1, props)}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Previous Month")]),_c('MgIcon',{attrs:{"name":"chevron-left"}})],1),_c('button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.changeMonth(1, props)}}},[_c('span',{staticClass:"sr-only"},[_vm._v("Next Month")]),_c('MgIcon',{attrs:{"name":"chevron-right"}})],1)]),_c('BButton',{staticClass:"btn btn-light text-dark",on:{"click":function($event){return _vm.getToday()}}},[_vm._v("Today")])],1)]),_c('BButtonGroup',{staticClass:"inbox-filter mb-2 d-flex"},[_c('BButton',{staticClass:"filter-button flex-fill",class:{ isSelectedInbox: _vm.selectedFilter === 'team' },on:{"click":function($event){return _vm.selectInbox({ type: 'team', name: 'Team' })}}},[_vm._v("Team")]),[_c('b-button',{staticClass:"filter-button flex-fill",class:{ isSelectedInbox: _vm.selectedFilter === 'mine' },on:{"click":function($event){return _vm.selectInbox({ type: 'mine', name: 'Mine'})}}},[_vm._v("Mine")])],(_vm.sharedInboxes.length)?_c('BDropdown',{staticClass:"filter-button flex-fill",class:{ dropDownSelected: _vm.selectedFilter === 'sharedInbox' },attrs:{"right":"","text":"Shared Inbox"}},[_vm._l((_vm.sharedInboxes),function(inbox){return [_c('BDropdownItem',{key:inbox.id,class:{
                isSelectedInbox:
                  _vm.selectedFilter === 'sharedInbox' &&
                  inbox.name === _vm.selectedInbox.name,
              },on:{"click":function($event){return _vm.selectInbox(inbox)}}},[_vm._v(_vm._s(inbox.name))])]})],2):_vm._e()],2)],1)]}},{key:"day-content",fn:function(props){return [_c('div',{staticClass:"day-cell d-flex flex-column align-items-center",class:{
        isToday: props.day.isToday,
        isPast: _vm.today > Date.parse(props.day.date) && !props.day.isToday,
        isSelectedDay: props.day.date - _vm.selectedDay === 0,
      },on:{"click":function($event){return _vm.selectDay(props.day.date)}}},[_c('span',{staticClass:"day d-flex text-align-center justify-content-center align-items-center"},[_vm._v(_vm._s(props.day.day))]),(_vm.getCount(props.day.date))?_c('div',{domProps:{"innerHTML":_vm._s(_vm.getCount(props.day.date))}}):_vm._e()])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }