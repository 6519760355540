<template>
  <div class="web-chat-availability-dropdown">
    <BubbleDropdown
      :options="webChatStatuses_"
      :selectedKeyValue="available"
      key-prefix="web-chat-status-"
      key-field="value"
      label-field="name"
      description-field="description"
      icon-field="icon"
      icon-color-field="iconColor"
      toggle-icon-field="toggleIcon"
      header-text="CHAT STATUS"
      @click="handleClick"
      class="availability-dropdown"
    />
    <MgSelectList
      :options="webChatStatuses_"
      :selectedKeyValue="available"
      key-prefix="web-chat-status-"
      key-field="value"
      label-field="name"
      description-field="description"
      icon-field="icon"
      icon-color-field="iconColor"
      header-text="Chat Status"
      @click="handleClick"
      class="availability-dropdown-mobile"
    />
  </div>
</template>
<script>
import store from "@/store";
import BubbleDropdown from "@/components/BubbleDropdown";
import { ACTIONS, MUTATIONS } from "@/util/constants";
import getProfile from "@/util/profile";
import _ from "lodash";
import MgSelectList from "@/components/MgSelectList/MgSelectList.vue";
import analytics from "@/util/analytics";

export default {
  name: "WebChatAvailabilityDropdown",
  store,
  components: { BubbleDropdown, MgSelectList },
  data() {
    return {
      bot_: null,
      webChatStatuses_: [
        {
          name: "Available",
          value: true,
          icon: "check-circle",
          iconColor: "#2DAD00",
          toggleIcon: "comment-check",
          description: "Ready to chat with site visitors",
        },
        {
          name: "Away",
          value: false,
          icon: "minus-circle",
          iconColor: "#7C7979",
          toggleIcon: "comment-minus",
          description: "Not available to chat",
        },
      ],
    };
  },
  computed: {
    available() {
      return this.$store.state.webChat.availableToChat;
    },
  },
  watch: {
    async available(newVal, oldVal) {
      if (newVal === oldVal) return;
      await this.setChatAvailability(newVal);
    },
  },
  async created() {
    let profile = getProfile();
    // Fire this on initial load, or after Department Change
    // We can't rely on destroyed due to timing issues (thanks, AngularJS)
    if (!localStorage.selectedBotId) {
      await this.resetChatState();
    }
    let teamHasChat = _.get(profile, "currentDepartment.teamHasChat", false);
    let userIsAgent = _.get(
      profile,
      "currentDepartment.userIsChatAgent",
      false
    );

    if (!teamHasChat || !userIsAgent) return;

    // Let this component is the source of truth for seeding the initial data
    await this.$store.dispatch(ACTIONS.GET_OPEN_WEBCHAT_CONVERSATIONS);
    await this.$store.dispatch(ACTIONS.START_SIGNALR_WEBCHAT_CONNECTION);

    // User was online and refreshed or came back to Cadence
    if (window.localStorage.cadenceUserAvailability === "true") {
      await this.setChatAvailability(true);
    }
  },
  methods: {
    async handleClick(data) {
      await this.setChatAvailability(data.selectedOption.value);
      analytics.track("Interacted with the chat away/available dropdown", {
        selectedOption: data.selectedOption.value,
      });
    },
    async setChatAvailability(available) {
      window.localStorage.cadenceUserAvailability = available;

      this.$store.dispatch(ACTIONS.UPDATE_UNREAD_WEBCHAT_CONVERSATIONS_COUNT);
      await this.$store.dispatch(
        ACTIONS.SEND_WEBCHAT_SIGNALR_MESSAGE,
        available ? "MarkUserAvailable" : "MarkUserUnavailable"
      );

      this.$emit("updateWebChatAvailability");
      this.$store.commit(MUTATIONS.SET_AVAILABLE_TO_CHAT, available);
      this.$store.dispatch(ACTIONS.START_WEBCHAT_HEARTBEAT);
    },
    async resetChatState() {
      await this.$store.commit(MUTATIONS.SET_AVAILABLE_TO_CHAT, false);
      await this.$store.dispatch(ACTIONS.START_WEBCHAT_HEARTBEAT);
      await this.$store.dispatch(ACTIONS.STOP_SIGNALR_WEBCHAT_CONNECTION);
      await this.$store.commit(MUTATIONS.SET_WEBCHAT_BOT, null);
      await this.$store.commit(MUTATIONS.SET_WEBCHAT_CONVERSATIONS, new Map());
      await this.$store.commit(
        MUTATIONS.SET_SELECTED_WEBCHAT_CONVERSATION,
        null
      );
      await this.$store.commit(MUTATIONS.SET_SELECTED_WEBCHAT_MESSAGES, []);
      localStorage.setItem("unreadWebChatConversationsCount", 0);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

::v-deep .dropdown-menu {
  overflow-y: auto;
  max-height: 287px;
  .b-dropdown-text {
    font-weight: 600;
    color: #6c757d;
    background-color: none;
  }
}
.dropdown-menu-icon {
  padding-top: 1px;
}

.availability-dropdown {
  @include media-breakpoint-down(md) {
    display: none;
  }
}
::v-deep .availability-dropdown-mobile {
  @include media-breakpoint-up(lg) {
    display: none;
  }

  background-color: none;
  .b-dropdown-text {
    font-weight: normal;
    color: white;
  }
  .select-items {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.18);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    background-color: white;

    li:first-child {
      border-top-right-radius: 0.25rem;
      border-top-left-radius: 0.25rem;
    }

    li:last-child {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }
}
</style>
