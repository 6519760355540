<template>
  <div
    id="recruit-integration-import-settings"
    class="d-flex flex-column h-100"
  >
    <SubpageHeader
      headerTitle="Recruit Import Settings"
      backHref="/#/integrations/recruit/imports/"
      class="mb-3"
    />

    <div class="recruit-import-page-content overflow-auto">
      <div class="field-errors-container pb-4" v-show="errors.length > 0">
        <div class="alert alert-danger d-flex align-items-center p-4">
          <span class="text-danger">
            <MgIcon name="exclamation-triangle" scale="1.25" />
          </span>
          <span class="font-weight-normal">
            There was an error saving the settings. Please address the errors
            and try again.
          </span>
        </div>
      </div>

      <BCard header="Import Schedule" header-tag="h2">
        <div>
          <IntegrationsImportScheduleCard
            :importSchedule="importSettings.schedule"
            :orgTimezone="orgTimezone"
          />
        </div>
      </BCard>

      <BCard header="Contact Source" header-tag="h2" class="mt-5">
        <div class="form-group m-0">
          <p>
            Imports of contact data require the creation of a saved view based
            on Opportunities. This view can bring in additional fields from
            Contacts and other Objects in Recruit.
          </p>
          <label for="savedViewSelect"> Saved View </label>
          <div class="w-100">
            <BFormSelect
              id="savedViewSelect"
              class="w-50"
              v-model="savedViewId"
              :options="viewSelectOptions"
              text-field="name"
              label-field="name"
              value-field="id"
              @change="viewChanged($event)"
            >
              <template #first>
                <BFormSelectOption value="" disabled>
                  Select a View
                </BFormSelectOption>
              </template>
            </BFormSelect>
          </div>
        </div>
        <div
          v-if="
            currentViewFields.length > 0 && importSettings.savedViewId !== ''
          "
        >
          <hr />
          <h3 class="mb-3 mt-4">Field Mapping</h3>
          <p>
            There is a set of required standard fields that must be mapped in
            the import. If the required standard fields are not completed, the
            import can not be saved.
          </p>
          <div>
            <h4 class="mb-0 mt-4">Required Fields</h4>
            <BListGroupItem class="d-inline-flex w-100 border-0 mb-n2 pl-0">
              <div class="col-sm-5 recruit-header">
                <span class="align-middle font-weight-bold pl-0">
                  Recruit Fields
                </span>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-3">
                <span class="align-middle font-weight-bold pl-2">
                  Cadence Fields
                </span>
              </div>
              <div class="col-sm-3 text-right"></div>
            </BListGroupItem>
            <BListGroup class="mt-1">
              <IntegrationsFieldListGroupItem
                :field="importSettings.standardFields.contactId"
                :error="getError('contactId')"
                displayName="Contact Id"
                standard
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.recruitImportConfigModal
                  @click="
                    selectField(
                      importSettings.standardFields.contactId,
                      'Contact Id',
                      true,
                      false,
                      false,
                      getError('contactId')
                    )
                  "
                >
                  {{
                    displayNameIsSet(importSettings.standardFields.contactId)
                      ? "Edit"
                      : "Select"
                  }}
                </button>
              </IntegrationsFieldListGroupItem>

              <IntegrationsFieldListGroupItem
                :field="importSettings.standardFields.firstName"
                :error="getError('firstName')"
                displayName="First Name"
                standard
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.recruitImportConfigModal
                  @click="
                    selectField(
                      importSettings.standardFields.firstName,
                      'First Name',
                      true,
                      false,
                      false,
                      getError('firstName')
                    )
                  "
                >
                  {{
                    displayNameIsSet(importSettings.standardFields.firstName)
                      ? "Edit"
                      : "Select"
                  }}
                </button>
              </IntegrationsFieldListGroupItem>

              <IntegrationsFieldListGroupItem
                :field="importSettings.standardFields.lastName"
                :error="getError('lastName')"
                displayName="Last Name"
                standard
              >
                <button
                  id="Last Name"
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.recruitImportConfigModal
                  @click="
                    selectField(
                      importSettings.standardFields.lastName,
                      'Last Name',
                      true,
                      false,
                      false,
                      getError('lastName')
                    )
                  "
                >
                  {{
                    displayNameIsSet(importSettings.standardFields.lastName)
                      ? "Edit"
                      : "Select"
                  }}
                </button>
              </IntegrationsFieldListGroupItem>

              <IntegrationsFieldListGroupItem
                :field="importSettings.standardFields.mobileNumber"
                :error="getError('mobileNumber')"
                displayName="Mobile Number"
                standard
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.recruitImportConfigModal
                  @click="
                    selectField(
                      importSettings.standardFields.mobileNumber,
                      'Mobile Number',
                      true,
                      false,
                      false,
                      getError('mobileNumber')
                    )
                  "
                >
                  {{
                    displayNameIsSet(importSettings.standardFields.mobileNumber)
                      ? "Edit"
                      : "Select"
                  }}
                </button>
              </IntegrationsFieldListGroupItem>

              <IntegrationsFieldListGroupItem
                :field="importSettings.standardFields.modifiedOn"
                :error="getError('modifiedOn')"
                displayName="Modified On"
                standard
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.recruitImportConfigModal
                  @click="
                    selectField(
                      importSettings.standardFields.modifiedOn,
                      'Modified On',
                      true,
                      false,
                      false,
                      getError('modifiedOn')
                    )
                  "
                >
                  {{
                    displayNameIsSet(importSettings.standardFields.modifiedOn)
                      ? "Edit"
                      : "Select"
                  }}
                </button>
              </IntegrationsFieldListGroupItem>

              <IntegrationsFieldListGroupItem
                :field="opportunityField"
                displayName="Opportunity Id"
                standard
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.recruitImportConfigModal
                  @click="selectField(opportunityField, 'Opportunity Id', true)"
                >
                  {{ displayNameIsSet(opportunityField) ? "Edit" : "Select" }}
                </button>
              </IntegrationsFieldListGroupItem>
            </BListGroup>
          </div>
          <div class="mt-5">
            <h4 class="mb-0">Optional Fields</h4>
            <BListGroupItem class="d-inline-flex w-100 border-0 mb-n2 pl-0">
              <div class="col-sm-5 pl-0">
                <span class="align-middle font-weight-bold renxt-header">
                  Recruit Fields
                </span>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-2">
                <span class="align-middle font-weight-bold pl-2">
                  Cadence Fields
                </span>
              </div>
              <div class="col-sm-3 text-right"></div>
            </BListGroupItem>
            <BListGroup class="mt-1">
              <IntegrationsFieldListGroupItem
                :field="importSettings.standardFields.staffId"
                :error="getError('staffId')"
                displayName="User Id"
                standard
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.recruitImportConfigModal
                  @click="
                    selectField(
                      importSettings.standardFields.staffId,
                      'User Id',
                      true,
                      false,
                      false,
                      getError('staffId')
                    )
                  "
                >
                  {{
                    displayNameIsSet(importSettings.standardFields.staffId)
                      ? "Edit"
                      : "Select"
                  }}
                </button>
                <button
                  class="btn btn-outline-danger btn-sm ml-1"
                  v-if="displayNameIsSet(importSettings.standardFields.staffId)"
                  v-b-modal.removeFieldMappingModal
                  @click="
                    selectField(
                      importSettings.standardFields.staffId,
                      'User Id'
                    )
                  "
                >
                  Remove
                </button>
              </IntegrationsFieldListGroupItem>
              <IntegrationsFieldListGroupItem
                :field="importSettings.optOutField"
                :error="getError('optOut')"
                displayName="Opted Out"
                standard
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.recruitImportConfigModal
                  @click="
                    selectField(
                      importSettings.optOutField,
                      'Opted Out',
                      true,
                      true,
                      false,
                      false,
                      getError('optOut')
                    )
                  "
                >
                  {{
                    displayNameIsSet(importSettings.optOutField)
                      ? "Edit"
                      : "Select"
                  }}
                </button>
                <button
                  class="btn btn-outline-danger btn-sm ml-1"
                  v-if="displayNameIsSet(importSettings.optOutField)"
                  v-b-modal.removeFieldMappingModal
                  @click="selectOptOutField(importSettings.optOutField)"
                >
                  Remove
                </button>
              </IntegrationsFieldListGroupItem>
            </BListGroup>
          </div>
          <div class="mt-5">
            <h4>Custom Fields</h4>
            <p>
              Up to 24 additional Custom Fields can be included in your imports.
              If you would like to create any custom fields, you may map them as
              part of the import.
            </p>
            <BListGroupItem
              class="d-inline-flex w-100 border-0 mb-n2 pl-0"
              v-if="hasCustomFields()"
            >
              <div class="col-sm-5 recruit-header">
                <span class="align-middle font-weight-bold pl-0">
                  Recruit Fields
                </span>
              </div>
              <div class="col-sm-2"></div>
              <div class="col-sm-2">
                <span class="align-middle font-weight-bold pl-2">
                  Cadence Fields
                </span>
              </div>
              <div class="col-sm-3 text-right"></div>
            </BListGroupItem>
            <BListGroup class="mt-1">
              <IntegrationsFieldListGroupItem
                v-for="field in getNonRemovedCustomFields()"
                :key="field.customField.id"
                :field="field"
                :error="getError(field.customField.displayName)"
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.recruitImportConfigModal
                  @click="selectField(field, '', false)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-outline-danger btn-sm ml-1"
                  v-b-modal.removeFieldModal
                  @click="selectField(field)"
                >
                  Remove
                </button>
              </IntegrationsFieldListGroupItem>
              <IntegrationsFieldListGroupItem
                v-for="field in addedCustomFields"
                :key="field.key"
                :field="field"
                :error="getError(field.customField.displayName)"
              >
                <button
                  class="btn btn-outline-primary btn-sm"
                  v-b-modal.recruitImportConfigModal
                  @click="selectField(field, '', false)"
                >
                  Edit
                </button>
                <button
                  class="btn btn-outline-danger btn-sm ml-1"
                  @click="removeCustomField(field)"
                >
                  Remove
                </button>
              </IntegrationsFieldListGroupItem>
            </BListGroup>
            <div class="mt-3">
              <button
                class="btn btn-light"
                v-b-modal.recruitImportConfigModal
                :disabled="
                  importSettings.customFields.length +
                    addedCustomFields.length >=
                  25
                "
                @click="selectField({}, '', false, false, true)"
              >
                <MgIcon name="plus-circle" />
                Add Field
              </button>
            </div>
          </div>
        </div>
      </BCard>
      <div class="container-fluid form-actions sticky-bottom">
        <button
          class="btn btn-primary"
          v-on:click="updateImportSettings()"
          :disabled="canSave()"
        >
          Save
        </button>
        <a class="btn btn-light ml-1" href="/#/integrations/recruit/imports/">
          Cancel
        </a>
      </div>
    </div>
    <RecruitImportFieldAddEditModal
      :field="selectedField.field"
      :cadenceField="selectedField.cadenceField"
      :requiredFields="selectedField.isStandard"
      :currentViewFields="currentViewFields"
      :optOut="selectedField.isOptOut"
      :isNew="selectedField.isNew"
      :error="selectedField.error"
      @handleAddNewCustomField="addCustomField"
      @handleEditField="removeError"
    />
    <IntegrationsConfirmRemoveModal
      :field="selectedField"
      @onRemove="removeCustomField"
    />
    <IntegrationsConfirmRemoveMappingModal
      :field="selectedField"
      @onRemove="clearFieldMapping"
    />
    <RecruitChangeViewModal
      @resetImportConfiguration="confirmViewChangeId"
      @viewChangeCancel="cancelViewChanged"
    />
    <IntegrationsCustomFieldInUseModal
      :field="selectedField"
      :segments="segmentInUse"
    />
  </div>
</template>

<script>
import Vue from "vue";
import {
  BFormSelect,
  BFormSelectOption,
  BListGroup,
  BListGroupItem,
  BCard,
} from "bootstrap-vue";
import store from "@/store";
import {
  ACTIONS,
  defaultImportStandardFields,
  defaultImportOptOut,
  defaultCrmField,
} from "../../recruitStore";
import Toast from "@/components/Toast";
import { ToastPlugin } from "bootstrap-vue";
import SubpageHeader from "@/components/SubpageHeader";
import { itemsForPatch } from "@/util/apiHelpers";
import IntegrationsImportScheduleCard from "@/components/IntegrationsImportScheduleCard";
import RecruitImportFieldAddEditModal from "@/pages/integrations/recruit/imports/settings/RecruitImportFieldAddEditModal";
import _ from "lodash";
import IntegrationsFieldListGroupItem from "@/components/IntegrationsFieldListGroupItem";
import IntegrationsConfirmRemoveModal from "@/components/IntegrationsConfirmRemoveModal";
import IntegrationsConfirmRemoveMappingModal from "@/components/IntegrationsConfirmRemoveMappingModal";
import api from "@/util/api";
import MgIcon from "@/components/MgIcon";
import RecruitChangeViewModal from "@/pages/integrations/recruit/imports/settings/RecruitChangeViewModal";
import analytics from "@/util/analytics";
import IntegrationsCustomFieldInUseModal from "@/components/IntegrationsCustomFieldInUseModal";

Vue.use(ToastPlugin);

export default {
  name: "RecruitImportSettingsPage",
  store,
  components: {
    BFormSelect,
    BFormSelectOption,
    BListGroup,
    BListGroupItem,
    BCard,
    SubpageHeader,
    IntegrationsImportScheduleCard,
    RecruitImportFieldAddEditModal,
    IntegrationsFieldListGroupItem,
    IntegrationsConfirmRemoveModal,
    IntegrationsConfirmRemoveMappingModal,
    MgIcon,
    RecruitChangeViewModal,
    IntegrationsCustomFieldInUseModal,
  },
  data() {
    return {
      selectedField: {
        isStandard: false,
        isOptOut: false,
        field: {},
        cadenceField: "",
        isNew: false,
        error: {},
      },
      savedViewId: "",
      nextSavedViewId: "",
      addedCustomFields: [],
      removedCustomFields: [],
      isSavedViewUnset: true,
      errors: [],
      segmentInUse: [],
    };
  },
  async created() {
    try {
      await this.$store.dispatch(ACTIONS.GET_RECRUIT_CONFIG);
      await this.$store.dispatch(ACTIONS.GET_CRM_VIEWS);

      this.isSavedViewUnset =
        this.$store.state.recruit.importSettings.savedViewId === "";
    } catch {
      let toastBody = this.$createElement(Toast, {
        props: {
          message: "Something went wrong.",
        },
      });
      this.$bvToast.toast([toastBody], {
        variant: "danger",
        noCloseButton: true,
        solid: true,
      });
    }
  },
  computed: {
    importSettings() {
      return _.cloneDeep(this.$store.state.recruit.importSettings);
    },
    cleanImportSettings() {
      return _.cloneDeep(this.$store.state.recruit.importSettings);
    },
    viewSelectOptions() {
      return this.$store.state.recruit.crm.views;
    },
    currentViewFields() {
      return this.$store.state.recruit.crm.currentViewFields;
    },
    opportunityField() {
      return this.importSettings.customFields.find(
        (c) => c.customField.displayName === "OpportunityId"
      );
    },
    orgTimezone() {
      return this.$store.state.config.timeZone;
    },
  },
  methods: {
    canSave() {
      return !(
        this.importSettings.standardFields.firstName.crmField.id !== "" &&
        this.importSettings.standardFields.lastName.crmField.id !== "" &&
        this.importSettings.standardFields.mobileNumber.crmField.id !== "" &&
        this.importSettings.standardFields.modifiedOn.crmField.id !== "" &&
        this.importSettings.standardFields.contactId.crmField.id !== "" &&
        this.opportunityField.crmField.id !== ""
      );
    },
    clearFieldMapping(field) {
      if (field.crmField) {
        field.crmField = defaultCrmField;
      }
    },
    displayNameIsSet(field) {
      if (!field.crmField) return false;
      return field.crmField.name !== "";
    },
    getNonRemovedCustomFields() {
      return this.importSettings.customFields.filter((c) => {
        return (
          c.customField.displayName !== "OpportunityId" &&
          !_.includes(this.removedCustomFields, c.customField.id)
        );
      });
    },
    getError(key) {
      return _.find(this.errors, (e) => {
        return e.key === key;
      });
    },
    removeError(key) {
      this.errors = _.remove(this.errors, (e) => {
        return e.key === key;
      });
    },
    hasCustomFields() {
      let nonRemovedCustomFields = this.getNonRemovedCustomFields();
      return nonRemovedCustomFields.length + this.addedCustomFields.length > 0;
    },

    // View Change Methods
    async viewChanged(viewId) {
      this.nextSavedViewId = viewId;
      if (this.isSavedViewUnset) {
        this.importSettings.standardFields = defaultImportStandardFields;
        this.importSettings.optOutField = defaultImportOptOut;
        this.importSettings.savedViewId = this.nextSavedViewId;
        this.isSavedViewUnset = false;

        await this.$store.dispatch(
          ACTIONS.GET_CRM_CURRENT_VIEW_FIELDS,
          this.nextSavedViewId
        );
      } else {
        this.$bvModal.show("recruitChangeViewModal");
      }
    },
    async confirmViewChangeId() {
      this.importSettings.standardFields = defaultImportStandardFields;
      this.importSettings.optOutField = defaultImportOptOut;
      this.importSettings.savedViewId = this.nextSavedViewId;

      _.forEach(this.importSettings.customFields, (f) => {
        f.crmField = defaultCrmField;
      });
      _.forEach(this.addedCustomFields, (f) => {
        f.crmField = defaultCrmField;
      });
      await this.$store.dispatch(
        ACTIONS.GET_CRM_CURRENT_VIEW_FIELDS,
        this.nextSavedViewId
      );
      analytics.track("Changed Import Saved View", {
        integrationName: "Recruit",
      });
    },
    cancelViewChanged() {
      this.savedViewId = this.importSettings.savedViewId;
    },

    // Add/Edit Modal Methods
    selectField(
      field,
      cadenceField = "",
      isStandard = false,
      isOptOut = false,
      isNew = false,
      error = undefined
    ) {
      this.selectedField.isStandard = isStandard;
      this.selectedField.isOptOut = isOptOut;
      this.selectedField.cadenceField = cadenceField;
      this.selectedField.field = field;
      this.selectedField.isNew = isNew;
      if (error) {
        this.selectedField.error = error;
      } else {
        this.selectedField.error = isNew
          ? {}
          : cadenceField === ""
          ? this.getError(field.customField.displayName)
          : this.getError(cadenceField);
      }
    },
    selectOptOutField(field, error = undefined) {
      this.selectedField.isStandard = true;
      this.selectedField.isOptOut = true;
      this.selectedField.cadenceField = "Opted Out";
      this.selectedField.field = field;

      this.selectedField.isNew = false;
      if (error) {
        this.selectedField.error = this.getError("optedOut");
      }
    },
    addCustomField(field) {
      this.addedCustomFields.push({
        key: `${field.customField.displayName}-${_.random(0, 99999)}`,
        ...field,
      });

      analytics.track("Added Custom Field", {
        integrationName: "Recruit",
      });
    },
    async removeCustomField(field) {
      if (field.customField.id) {
        let inUse = await api.get(
          `customfields/isinuse/${field.customField.id}`
        );

        if (inUse.inUse) {
          this.segmentInUse = inUse.segmentNames;
          this.$bvModal.show("customFieldInUse");
        } else {
          this.removedCustomFields.push(field.customField.id);
        }
      } else {
        this.addedCustomFields = _.remove(this.addedCustomFields, (f) => {
          return f.key !== field.key;
        });
      }

      analytics.track("Removed Custom Field", {
        integrationName: "Recruit",
      });
    },

    // Field Validation Methods
    async validateFields(configuration) {
      let standardFieldErrors = _.map(
        _.filter(_.keys(this.importSettings.standardFields), (k) => {
          if (k !== "staffId")
            return this.importSettings.standardFields[k].crmField.id === "";
        }),
        (key) => {
          return {
            type: "standardField",
            key: key,
            messages: ["Recruit Field is required"],
          };
        }
      );
      let customFieldErrors = await api.post(
        "/recruit/configuration/validate",
        configuration
      );

      return [...standardFieldErrors, ...customFieldErrors];
    },

    async updateImportSettings() {
      let addedFieldNameOverlap = _.pluck(
        _.filter(this.addedCustomFields, (f) => {
          let removedFields = _.filter(
            this.importSettings.customFields,
            (c) => {
              return _.includes(this.removedCustomFields, c.customField.id);
            }
          );

          return _.includes(
            _.pluck(removedFields, "customField.displayName"),
            f.customField.displayName
          );
        }),
        "customField.displayName"
      );

      let configurationForValidation = {
        imports: {
          savedViewId: this.importSettings.savedViewId,
          schedule: itemsForPatch(
            this.cleanImportSettings.schedule,
            this.importSettings.schedule
          ),
          standardFields: this.importSettings.standardFields,
          optOutField: this.importSettings.optOutField,
          customFields: [
            ...this.importSettings.customFields.filter((c) => {
              return !_.includes(this.removedCustomFields, c.customField.id);
            }),
            ...this.addedCustomFields.filter((c) => {
              return !_.includes(
                addedFieldNameOverlap,
                c.customField.displayName
              );
            }),
          ],
        },
      };

      this.errors = await this.validateFields(configurationForValidation);

      // Find added Field Duplicates
      let dupAddedFields = _.filter(addedFieldNameOverlap, (c, v, i) =>
        _.includes(c, v, i + 1)
      );

      if (dupAddedFields.length > 0) {
        _.forEach(dupAddedFields, (d) => {
          this.errors.push({
            type: "customField",
            key: d,
            messages: [`Another field with the name ${d} already exists`],
          });
        });
      }

      if (this.errors.length === 0) {
        try {
          let configuration = {
            imports: {
              savedViewId: this.importSettings.savedViewId,
              schedule: this.importSettings.schedule,
              standardFields: this.importSettings.standardFields,
              optOutField: this.importSettings.optOutField,
              customFields: [
                ...this.importSettings.customFields.filter((c) => {
                  return !_.includes(
                    this.removedCustomFields,
                    c.customField.id
                  );
                }),
                ...this.addedCustomFields,
              ],
            },
          };

          await this.$store.dispatch(
            ACTIONS.UPDATE_RECRUIT_CONFIG,
            configuration
          );

          this.errors = [];
          this.addedCustomFields = [];
          this.removedCustomFields = [];

          let toastBody = this.$createElement(Toast, {
            props: {
              message: "Import Settings Updated",
            },
          });
          this.$bvToast.toast([toastBody], {
            variant: "success",
            noCloseButton: true,
            solid: true,
          });

          analytics.track("Updated Import Settings", {
            integrationName: "Recruit",
          });
        } catch {
          let toastBody = this.$createElement(Toast, {
            props: {
              message: "There was an error saving Import Settings.",
            },
          });
          this.$bvToast.toast([toastBody], {
            variant: "danger",
            noCloseButton: true,
            solid: true,
          });
        }
      } else {
        let fieldErrorsContainer = document.querySelector(
          ".field-errors-container"
        );
        if (fieldErrorsContainer) {
          setTimeout(function () {
            fieldErrorsContainer.scrollIntoView({ behavior: "smooth" });
          }, 500);
        }
      }
    },
  },
  watch: {
    async importSettings() {
      if (this.importSettings.savedViewId !== "") {
        this.savedViewId = this.importSettings.savedViewId;
        await this.$store.dispatch(
          ACTIONS.GET_CRM_CURRENT_VIEW_FIELDS,
          this.importSettings.savedViewId
        );
      }
    },
    removedCustomFields() {
      this.getNonRemovedCustomFields();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.recruit-import-page-content {
  padding: 20px 30px 0px;
}

.recruit-header {
  padding-left: 20px;
}
</style>
