import { MUTATIONS } from "../util/constants";

export default {
  [MUTATIONS.SET_CAMPAIGNS](state, campaigns) {
    state.campaigns = campaigns;
  },

  [MUTATIONS.SET_CONFIG](state, config) {
    state.config = config;
  },

  [MUTATIONS.SET_RADIUS_TARGETS](state, targets) {
    state.radiusTargets = targets;
  },

  [MUTATIONS.UPDATE_CAMPAIGN](state, params) {
    let messages = state.inbox.selectedConversationMessages.find(
      (m) => m.messageId === params.messageId
    );
    if (messages) {
      messages.campaignId = params.campaignId;
    }
  },

  [MUTATIONS.UPDATE_CAMPAIGNS](state, campaign) {
    state.campaigns.active.push(campaign);
    state.campaigns.names.push(campaign);
  },

  [MUTATIONS.SHOW_CONTACT_CARD](state, value) {
    state.showContactCard = value;
  },

  [MUTATIONS.SET_USERS](state, users) {
    state.users = users;
  },

  [MUTATIONS.SET_CONNECT_FILTERS](state, filters) {
    state.connectFilters = filters;
  },

  [MUTATIONS.SET_RE_NXT_LISTS](state, lists) {
    state.reNxtLists = lists;
  },

  [MUTATIONS.SET_SLATE_QUERIES](state, queries) {
    state.slateQueries = queries;
  },
};
