import firebase from "firebase";
import eventBus from "@/util/eventBus";

let authPromise;

export const app = firebase
  .initializeApp({
    apiKey: process.env.VUE_APP_FIRESTORE_KEY,
    databaseURL: process.env.VUE_APP_FIRESTORE_URL,
    projectId: process.env.VUE_APP_FIRESTORE_PROJECT_ID
  })
  .firestore();

export function auth() {
  let profile = window.localStorage.profile;
  let firestoreToken = JSON.parse(profile).firestoreToken;

  if (firebase.auth().currentUser) {
    return (authPromise = Promise.resolve());
  }
  return (authPromise = firebase
    .auth()
    .signInWithCustomToken(firestoreToken)
    .catch(() => {
      eventBus.dispatchEvent("authRequired");
    }));
}

export function onAuth() {
  if (authPromise) return authPromise;

  return auth();
}
