<template>
  <div
    class="message py-3 px-4"
    :class="{
      outgoing: item.direction && item.direction.toLowerCase() === 'outgoing',
      pending: item.pending,
      'media-with-text':
        item.media &&
        item.media.length &&
        item.message &&
        item.message !== '<Multimedia Message>',
      'contact-history': isHistory,
      'no-timeline': !hasTimeline,
      'is-forwarded': item.isForwarding,
    }"
    @mouseenter="hoverActive = true"
    @mouseleave="hoverActive = false"
  >
    <div class="forwarded-text" v-if="item.isForwarding">
      <MgIcon name="info" />
      <span>
        This message was forwarded from another number. If replying, a new
        conversation will start on this contact’s device so you should introduce
        yourself to avoid confusion.
        <a
          href="https://help.mongooseresearch.com/article/570-single-number-shared-inbox-update"
          target="_blank"
          >Learn more</a
        ></span
      >
    </div>
    <MessageMeta v-if="!isHistory" :item="item"></MessageMeta>
    <HistoryMessageMeta
      v-if="isHistory"
      :item="item"
      :hasTimeline="hasTimeline"
    ></HistoryMessageMeta>
    <div
      class="d-flex"
      :class="[
        {
          'flex-row-reverse':
            item.direction && item.direction.toLowerCase() === 'outgoing',
        },
      ]"
    >
      <div class="message-bubble text-break-word py-2 px-3">
        <span :inner-html.prop="item.message | twemoji"></span>
        <MessageMedia
          v-if="item.media && item.media.length"
          :media="item.media"
        ></MessageMedia>
      </div>
      <MessageActions
        v-if="!hideMessageActions"
        :hover-active="hoverActive"
        :message="item"
      ></MessageActions>
    </div>
    <div
      class="message-meta-campaign d-flex flex-wrap align-items-center mt-2"
      :class="{
        'flex-row-reverse': isOutgoing,
      }"
    >
      <CampaignTag
        v-if="item.campaignId"
        class="mr-1 mb-1"
        :hover-close-active="hoverActive"
        :campaign-id="item.campaignId"
        :no-remove-button="isHistory"
        @remove-campaign="removeCampaign(item)"
      />
      <MessageTag
        class="mr-1 mb-1"
        :hover-close-active="hoverActive"
        :tag-id="tag"
        :key="tag"
        v-for="tag in item.tags"
        :no-remove-button="isHistory"
        @remove-tag="removeTag(item, tag)"
      />
    </div>
  </div>
</template>

<script>
import MessageActions from "@/components/MessageActions";
import api from "@/util/api";
import MessageMedia from "@/components/MessageMedia";
import HistoryMessageMeta from "@/components/HistoryMessageMeta";
import MessageMeta from "@/components/MessageMeta";
import CampaignTag from "@/components/CampaignTag";
import MessageTag from "@/components/MessageTag";
import MgIcon from "@/components/MgIcon";
import "@/util/filters";
import store from "@/store";
import { ACTIONS, INBOX_TYPES, MESSAGE_DIRECTIONS } from "@/util/constants";
import analytics from "@/util/analytics";

export default {
  name: "Message",
  store,
  components: {
    MessageActions,
    HistoryMessageMeta,
    MessageMedia,
    MessageMeta,
    CampaignTag,
    MessageTag,
    MgIcon,
  },
  data() {
    return {
      isIncoming:
        this.item.direction.toLowerCase() === MESSAGE_DIRECTIONS.INCOMING,
      isOutgoing:
        this.item.direction.toLowerCase() === MESSAGE_DIRECTIONS.OUTGOING,
      hoverActive: false,
    };
  },
  props: {
    item: {
      required: true,
      type: Object,
    },
    isHistory: {
      default: false,
      required: false,
      type: Boolean,
    },
    hasTimeline: {
      required: false,
      type: Boolean,
    },
  },
  computed: {
    hasCampaign() {
      return this.item.campaignId;
    },
    hasTag() {
      return this.item.tags && this.item.tags.length > 0;
    },
    hideMessageActions() {
      return (
        this.isHistory ||
        (this.isOutgoing && this.hasCampaign) ||
        (this.hasCampaign && this.hasTag)
      );
    },
    isHighSpeed() {
      return (
        this.$store.state.inbox.activeInbox.type === INBOX_TYPES.HIGH_SPEED
      );
    },
  },
  methods: {
    removeCampaign(message) {
      let params = { campaignId: null };
      let inbox = this.$store.state.inbox.activeInbox;
      let request;

      this.$root.$emit("bv::hide::tooltip");

      if (inbox.type === INBOX_TYPES.HIGH_SPEED) {
        params = Object.assign(params, {
          messageId: message.messageId,
          contactId: this.$store.state.contact.details.id,
        });
        request = api.post(
          `/highspeedinbox/${inbox.highSpeedId}/campaign`,
          params
        );
      } else {
        let direction = message.direction || "";

        if (direction.toLowerCase() === "incoming") {
          request = api.patch(`/incomingmessages/${message.messageId}`, params);
        } else {
          request = api.patch(`/outgoingmessage/${message.messageId}`, params);
        }
      }

      return request.then(() => {
        this.$store.dispatch(ACTIONS.UPDATE_CAMPAIGN, {
          campaignId: null,
          messageId: message.messageId,
        });
        analytics.track("Removed Campaign from Message");
      });
    },
    removeTag(message, tagId) {
      if (this.isHighSpeed) {
        const params = {
            tagId,
            contactId: this.$store.state.contact.details.id,
          },
          request = api.put(
            `/HighSpeedMessages/${this.$store.state.inbox.activeInbox.highSpeedId}/${message.messageId}/untag`,
            params
          );

        return request.then(() => {
          this.$store.dispatch(ACTIONS.BIND_CONVERSATION);
          analytics.track("Removed High-Speed Tag from Message");
        });
      } else {
        let params = { TagId: tagId };
        let request = api.put(
          `/IncomingMessages/${message.messageId}/untag`,
          params
        );
        return request.then(() => {
          analytics.track("Removed Tag from Message");
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";

.message {
  float: none;
  position: relative;
  width: auto;

  ::v-deep .message-meta {
    margin-bottom: 5px;
  }

  ::v-deep .add-campaign,
  ::v-deep .remove-campaign {
    opacity: 0;

    &:focus {
      opacity: 1;
    }
  }

  &:hover,
  &:focus-within,
  &.is-forwarded {
    background: $gray-light-bg;

    &:not(.outgoing) {
      .message-bubble {
        background: $gray-lighter;

        &:after {
          border-color: transparent transparent $gray-lighter transparent;
        }
      }
    }

    ::v-deep .add-campaign,
    ::v-deep .remove-campaign {
      opacity: 1;
    }

    ::v-deep .message-meta {
      color: $gray-700;
    }
  }
  &.is-forwarded {
    background-color: #fef2de;
  }
  .forwarded-text {
    display: flex;
    align-items: center;
    font-style: italic;
    line-height: 1.2em;
    font-size: 0.9em;
    padding-bottom: 10px;
    a {
      text-decoration: underline;
    }
    ::v-deep .icon {
      margin-right: 10px;
      svg {
        width: 1em;
        height: 1em;
        fill: #f88f00;
      }
    }
  }

  &.pending {
    .message-bubble {
      opacity: 0.5;
    }
    .message-meta-source {
      font-style: italic;
      font-weight: 400;
    }
  }

  &.media-with-text {
    .message-media {
      margin-top: $line-height-computed/2;
    }
  }
}

.message.contact-history {
  &:hover {
    background: none;
    ::v-deep .message-meta {
      color: $text-muted;
    }

    &:not(.outgoing) {
      .message-bubble {
        background: $message-content-bg;

        &:after {
          border-color: transparent transparent $message-content-bg transparent;
        }
      }
    }
  }
}

.message-bubble {
  background: $message-content-bg;
  border-radius: 12px 12px 12px 0;
  color: $text-color;
  position: relative;
  transition: opacity 200ms ease-out;

  &:after {
    border-color: transparent transparent $message-content-bg transparent;
    border-width: 0 0 8px 8px;
    border-style: solid;
    bottom: 0;
    content: "";
    left: -8px;
    height: 0;
    position: absolute;
    width: 0;
  }

  .outgoing & {
    background: theme-color("primary");
    border-radius: 12px 12px 0 12px;
    color: #fff;
    text-align: right;

    &:after {
      border-color: transparent transparent transparent theme-color("primary");
      border-width: 8px 0 0 8px;
      left: auto;
      right: -8px;
    }
  }
}

::v-deep .message-actions {
  margin: 2px -15px 0 0.5rem;

  .outgoing & {
    margin: 2px 0.5rem 0 -15px;
  }
}

::v-deep .message-meta {
  color: $text-muted;
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
}

::v-deep .message-error {
  color: #f00;
  cursor: pointer;
  display: inline-block;

  .icon {
    position: relative;
    top: 1px;
  }
}

.contact-history {
  max-width: calc(100% - 100px);
  &.no-timeline {
    max-width: 100%;
  }
}

.remove-campaign {
  background-color: #e6e6e6;
  border: 1px solid $input-border;
  border-radius: 3px;
  cursor: pointer;
  color: #333;
  font-size: $font-size-lg;
  font-weight: $font-weight-bold;
  line-height: 1rem;
  margin: -2px 0 -2px 6px;
  opacity: 0;
  overflow: hidden;
  padding: 2px 6px 3px;
  transition: 0.2s;
  vertical-align: middle;
}

.outgoing {
  .message-meta-campaign.flex-row-reverse {
    .remove-campaign {
      margin-left: 0;
      margin-right: 6px;
    }
  }
}
</style>
