<template>
  <td class="menu">
    <b-dropdown
      right
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        <MgIcon class="text-muted header-menu" name="ellipsis-v" />
      </template>
      <b-dropdown-item @click="viewTag()">View</b-dropdown-item>
      <b-dropdown-item
        v-if="canModify"
        data-testid="edit-tag-button"
        @click="editTag()"
        >Edit</b-dropdown-item
      >
      <b-dropdown-item v-if="canModify" @click="archiveTag()">{{
        archived ? "Unarchive" : "Archive"
      }}</b-dropdown-item>
      <b-dropdown-divider v-if="canModify"></b-dropdown-divider>
      <b-dropdown-item
        v-if="canModify"
        data-testid="delete-tag-button"
        variant="danger"
        @click="deleteTag()"
        >Delete</b-dropdown-item
      >
    </b-dropdown>
  </td>
</template>
<script>
import MgIcon from "@/components/MgIcon";
import store from "@/store";
import eventBus from "@/util/eventBus";
import analytics from "@/util/analytics";

export default {
  name: "TagListGridMenu",
  store,
  components: {
    MgIcon,
  },
  props: {
    tagId: {
      type: Number,
      required: true,
    },
    archived: {
      type: Boolean,
      required: true,
    },
    createdById: {
      type: Number,
      required: true,
    },
  },
  computed: {
    canModify() {
      return this.createdById === this.$store.state.config.userId;
    },
  },
  methods: {
    viewTag() {
      eventBus.dispatchEvent("view-tag", { tagId: this.tagId });
      analytics.track("Clicked View from Tag List Grid Menu");
    },
    editTag() {
      eventBus.dispatchEvent("edit-tag", { tagId: this.tagId });
      analytics.track("Clicked Edit from Tag List Grid Menu");
    },
    archiveTag() {
      const eventName = this.archived ? "de-archive-tag" : "archive-tag";
      eventBus.dispatchEvent(eventName, { tagId: this.tagId });
      analytics.track(
        `Clicked ${
          this.archived ? "Unarchive" : "Archive"
        } from Tag List Grid Menu`
      );
    },
    deleteTag() {
      eventBus.dispatchEvent("delete-tag", { tagId: this.tagId });
      analytics.track("Clicked Delete from Tag List Grid Menu");
    },
  },
};
</script>
<style lang="scss" scoped>
.menu {
  overflow: inherit;
}
</style>
