<template>
  <span class="ai-button-container">
    <button
      type="button"
      :class="{ active: dialogOpen_ }"
      @click="toggleAiDialog()"
    ></button>
  </span>
</template>
<script>
import eventBus from "@/util/eventBus";

export default {
  name: "WebChatAiButton",
  props: {
    botId: {
      type: Number,
      required: true,
    },
    conversationId: {
      type: String,
      required: true,
    },
  },
  mounted() {
    eventBus.addEventListener(
      "legacy-close-chat-ai-dialog",
      this.handleAiDialogCancelClick
    );
    eventBus.addEventListener("legacy-chat-ai-scroll", this.scrollToAiDialog);
    eventBus.addEventListener("legacy-chat-use-ai-reply", this.useAiReply);
  },
  unmounted() {
    eventBus.removeEventListener(
      "legacy-close-chat-ai-dialog",
      this.handleAiDialogCancelClick
    );
    eventBus.removeEventListener(
      "legacy-chat-ai-scroll",
      this.scrollToAiDialog
    );
    eventBus.removeEventListener("legacy-chat-use-ai-reply", this.useAiReply);
  },
  data() {
    return {
      dialogOpen_: false,
    };
  },
  watch: {
    conversationId() {
      this.resetAiDialog();
    },
  },
  methods: {
    toggleAiDialog() {
      eventBus.dispatchEvent("cadence-ai-toggle-chat-ai-dialog", {
        botId: this.botId,
        conversationId: this.conversationId,
      });
      this.dialogOpen_ = !this.dialogOpen_;
    },

    handleAiDialogCancelClick() {
      // this only gets triggered by the close icon in the dialog, so it doesn't need an event
      this.dialogOpen_ = false;
    },

    scrollToAiDialog() {
      if (this.dialogOpen_) {
        let aiDialog = document.getElementById("cadence-ai-container");
        aiDialog.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    },

    useAiReply(event) {
      const replyText = event.detail.replyText;
      this.$emit("use-ai-reply", replyText);
    },

    resetAiDialog() {
      this.dialogOpen_ = false;
      eventBus.dispatchEvent("cadence-ai-close-chat-ai-dialog");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../scss/variables";
@import "../../scss/utilities";

.ai-button-container {
  display: flex;
  align-items: center;
  border-right: 2px solid $gray-lighter;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 3px;

  button {
    height: 24px;
    width: 24px;
    padding: 0;
    border: none;
    border-radius: 2px;
    background: inherit;
    background-image: url("/images/icon-stars.svg");
    background-repeat: no-repeat, no-repeat;
    background-position: center;
    transition: background-image 200ms ease;

    &:hover {
      background-image: url("/images/icon-stars-solid-light.svg");
    }

    &.active {
      background-image: url("/images/icon-stars-solid.svg");
    }

    img {
      transform: translateY(-2px);
    }
  }
}
</style>
