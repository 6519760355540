<template>
  <BListGroupItem class="border-0 row m-0 d-flex justify-content-between">
    <div class="flex-grow-1 row align-items-center">
      <img
        src="/images/outOfOffice/alarm-clock-duoTone.svg"
        class="alarm-icon"
        alt="alarm clock"
        aria-hidden="true"
      />
      <div class="ml-3">
        <span class="row m-0 name-font">{{
          isPersonalInbox ? "Personal Inbox" : inbox.name
        }}</span>
        <span
          >Out of Office is currently
          <span class="text-uppercase">{{
            isOOOEnabled ? "on." : "off."
          }}</span></span
        >
      </div>
    </div>
    <div class="checkbox-container mr-2">
      <BFormCheckbox
        size="lg"
        v-model="isOOOEnabled"
        @change="disableOOO"
        switch
      >
        <span class="sr-only"
          >Turn Out Of Office Off For&nbsp;
          {{ isPersonalInbox ? "your personal inbox" : inbox.name }}</span
        >
      </BFormCheckbox>
    </div>
  </BListGroupItem>
</template>

<script>
import { BFormCheckbox, BListGroupItem } from "bootstrap-vue";
import api from "@/util/api";
import eventBus from "@/util/eventBus";
import analytics from "@/util/analytics";

export default {
  name: "InboxRow",
  props: {
    inbox: {
      type: Object,
      required: true,
    },
    isPersonalInbox: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return { isOOOEnabled: true };
  },
  components: { BFormCheckbox, BListGroupItem },
  methods: {
    toggleOutOfOfficeEvent(event) {
      if (
        event.detail.isPersonalInbox === this.isPersonalInbox ||
        event.detail.inboxId === this.inbox.id
      ) {
        this.oooEnabled = false;
        this.$emit("click:disable-ooo", {
          isPersonalInbox: this.isPersonalInbox,
          inboxId: event.detail.inboxId,
        });
      }
    },
    async disableOOO() {
      if (this.isPersonalInbox) {
        let profile = await api.get("profile");
        await api.post(
          "v2/profile/outofoffice",
          {
            ...profile.outOfOfficeConfig,
            enabled: false,
          },
          { headers: { "x-disable-loading": true } }
        );
      } else {
        let oooConfig = await api.get(
          `v2/groupAccounts/${this.inbox.id}/outofoffice`
        );
        await api.post(
          `v2/groupAccounts/${this.inbox.id}/outofoffice`,
          {
            ...oooConfig,
            enabled: false,
          },
          { headers: { "x-disable-loading": true } }
        );
      }

      eventBus.dispatchEvent("toggle-out-of-office-dialog", {
        isPersonalInbox: this.isPersonalInbox ? true : false,
        inboxId: this.isPersonalInbox ? undefined : this.inbox.id,
      });

      this.$emit("click:disable-ooo", {
        isPersonalInbox: this.isPersonalInbox,
      });

      analytics.track(`Turned Out of Office OFF`, {
        "Account Type": this.isPersonalInbox ? "Personal" : "Shared",
        "Inbox Name": !this.isPersonalInbox ? this.inbox.name : undefined,
        Via: "Dialog",
      });
    },
  },
  created() {
    if (this.isPersonalInbox) this.isOOOEnabled = this.inbox.enabled;
    else this.isOOOEnabled = this.inbox.outOfOfficeIsActive;
    eventBus.addEventListener(
      "toggle-out-of-office",
      this.toggleOutOfOfficeEvent
    );
  },
  destroyed() {
    eventBus.removeEventListener("toggle-out-of-office");
  },
};
</script>

<style lang="scss" scoped>
.alarm-icon {
  width: 32px;
  height: 32px;
  margin-top: 2px;
}

.checkbox-container {
  padding-top: 12px;
  padding-left: 15px;
}

.name-font {
  font-size: 18px;
  max-width: 300px;
}

::v-deep .custom-control-input:checked {
  ~ .custom-control-label::before {
    background-color: #318ce7 !important;
    border-color: #318ce7 !important;
  }
}
</style>
