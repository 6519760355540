<template>
  <div class="row w-100">
    <div class="col col-y-label">
      <div
        class="d-flex justify-content-end y-label"
        v-for="(time, index) in timesOfDay"
        :key="index"
      >
        {{ time.label }}
      </div>
    </div>
    <div class="col col-heat-map" v-for="(day, i) in days" :key="i">
      <ChatAnalyticsHeatMapCell
        class="my-1"
        v-for="(avgNumConversations, j) in Object.values(day.values)"
        :key="j"
        :value="avgNumConversations"
        :min="min"
        :max="max"
        :day="day.name"
        :time="timesOfDay[j].tooltip"
        :filterData="filterData"
        :liveChatOnly="liveChatOnly"
      />
      <div class="d-flex justify-content-center pt-1">
        <div class="x-label">{{ day.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import ChatAnalyticsHeatMapCell from "./ChatAnalyticsHeatMapCell";

export default {
  name: "ChatAnalyticsHeatMap",
  components: { ChatAnalyticsHeatMapCell },
  props: {
    days: {
      type: Array,
      required: false,
      default: () => [],
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 0,
    },
    filterData: {
      type: Object,
      required: true,
    },
    liveChatOnly: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      timesOfDay: [
        { label: "12:00 AM", tooltip: "12:00 - 1:00 AM" },
        { label: "", tooltip: "1:00 - 2:00 AM" },
        { label: "2:00 AM", tooltip: "2:00 - 3:00 AM" },
        { label: "", tooltip: "3:00 - 4:00 AM" },
        { label: "4:00 AM", tooltip: "4:00 - 5:00 AM" },
        { label: "", tooltip: "5:00 - 6:00 AM" },
        { label: "6:00 AM", tooltip: "6:00 - 7:00 AM" },
        { label: "", tooltip: "7:00 - 8:00 AM" },
        { label: "8:00 AM", tooltip: "8:00 - 9:00 AM" },
        { label: "", tooltip: "9:00 - 10:00 AM" },
        { label: "10:00 AM", tooltip: "10:00 - 11:00 AM" },
        { label: "", tooltip: "11:00 AM - 12:00 PM" },
        { label: "12:00 PM", tooltip: "12:00 - 1:00 PM" },
        { label: "", tooltip: "1:00 - 2:00 PM" },
        { label: "2:00 PM", tooltip: "2:00 - 3:00 PM" },
        { label: "", tooltip: "3:00 - 4:00 PM" },
        { label: "4:00 PM", tooltip: "4:00 - 5:00 PM" },
        { label: "", tooltip: "5:00 - 6:00 PM" },
        { label: "6:00 PM", tooltip: "6:00 - 7:00 PM" },
        { label: "", tooltip: "7:00 - 8:00 PM" },
        { label: "8:00 PM", tooltip: "8:00 - 9:00 PM" },
        { label: "", tooltip: "9:00 - 10:00 PM" },
        { label: "10:00 PM", tooltip: "10:00 - 11:00 PM" },
        { label: "", tooltip: "11:00 PM - 12:00 AM" },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/utilities";

.col-heat-map {
  padding-left: 2px;
  padding-right: 2px;
}

.col-y-label {
  max-width: 100px;

  .y-label {
    height: 32px;
    margin-top: 4px;
    margin-bottom: 4px;

    @include media-breakpoint-up(md) {
      height: 20px;
    }
  }
}

.x-label {
  max-width: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-breakpoint-up(md) {
    max-width: unset;
    white-space: unset;
    overflow: unset;
  }
}
</style>
