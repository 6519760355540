<template>
  <div id="recruitIntegrationDashboardTab" class="d-flex flex-column h-100">
    <div class="panel-container">
      <div class="row panel-row d-flex">
        <div class="col-md-6 col-sm-12 card-imports flex-fill">
          <BCard
            header="Imports"
            header-tag="h2"
            class="m-0 h-100"
            v-if="isImportConfigured"
          >
            <div
              class="d-flex align-items-center justify-content-center"
              v-if="imports.totalItems === 0"
            >
              <span class="empty-message-text text-muted font-weight-light"
                >No import history</span
              >
            </div>
            <div v-else>
              <h3 class="card-header-label pb-3">
                <MgIcon name="clock" /> Latest Imports
              </h3>
              <div class="d-flex">
                <div class="w-100">
                  <div class="list-group">
                    <a
                      href="/#/integrations/recruit/imports/"
                      class="list-group-item list-group-item-action recruit-import-row border-0 mb-2"
                      v-for="item in topImports"
                      :key="item.id"
                    >
                      <div class="d-flex justify-content-between w-100">
                        <span class="font-weight-normal">
                          <span class="text-large">{{
                            item.errorRecords
                          }}</span>
                          errors
                          <span
                            class="font-weight-light"
                            v-if="!item.status.match(/queued for syncing/i)"
                          >
                            - {{ item.status }}
                          </span>
                        </span>
                        <span>{{ formatDate(item.started) }}</span>
                      </div>
                      <div>
                        Records: {{ item.totalRecords }} total,
                        {{ item.successRecords }} successful
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </BCard>
          <BCard
            class="no-item-container d-flex justify-content-center align-items-center h-100 m-0"
            no-body
            v-else
          >
            <div class="row my-5">
              <div class="col-md-4 col-sm-12 d-flex justify-content-end">
                <img
                  class="mr-2"
                  alt=""
                  src="/images/icon_upload.svg"
                  width="74"
                  height="64"
                />
              </div>
              <div class="col-md-8 col-sm-12">
                <div class="row">
                  <h2 class="h4 has-empty-state-header">Imports</h2>
                </div>
                <div class="row mr-1">
                  <span class="has-empty-state-text">
                    Automate contact imports
                  </span>
                </div>
                <div class="row mt-2">
                  <a href="#/integrations/recruit/imports/settings">
                    Configure Imports&nbsp;»
                  </a>
                </div>
              </div>
            </div>
          </BCard>
        </div>
        <div class="col-md-6 col-sm-12 card-exports flex-fill">
          <BCard
            header="Exports"
            header-tag="h2"
            class="m-0 h-100"
            v-if="isExportConfigured"
          >
            <div
              class="empty-message d-flex justify-content-center align-items-center"
              v-if="exportErrors.totalItems === 0"
            >
              <span class="empty-message-text text-muted font-weight-light"
                >No export history</span
              >
            </div>
            <div v-else>
              <h3 class="card-header-label pb-3">
                <MgIcon name="clock" /> Last 24 Hours
              </h3>
              <div>
                <div
                  class="container rounded position-relative export-total-container"
                >
                  <a
                    href="/#/integrations/recruit/exports/"
                    class="stretched-link"
                  >
                    <span class="sr-only">Go to exports</span>
                  </a>
                  <div class="h4 mt-1 mb-2 font-weight-light">Messages</div>
                  <div class="row">
                    <div class="col card-sub-header mb-1">
                      <span class="export-number font-weight-normal">{{
                        exportSummary.messages.fail.toLocaleString()
                      }}</span>
                      <span class="export-text font-weight-normal">
                        errors</span
                      >
                    </div>
                    <div class="col card-sub-header">
                      <span class="export-number font-weight-normal">{{
                        exportSummary.messages.success.toLocaleString()
                      }}</span>
                      <span class="export-text font-weight-normal">
                        successful</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="container rounded position-relative export-total-container mt-2"
                >
                  <a
                    href="/#/integrations/recruit/exports/"
                    class="stretched-link"
                  >
                    <span class="sr-only">Go to exports</span>
                  </a>
                  <div class="h4 mt-1 mb-2 font-weight-light">Opt-outs</div>
                  <div class="row">
                    <div class="col card-sub-header mb-1">
                      <span class="export-number font-weight-normal">{{
                        exportSummary.optOuts.fail.toLocaleString()
                      }}</span>
                      <span class="export-text font-weight-normal">
                        errors</span
                      >
                    </div>
                    <div class="col card-sub-header">
                      <span class="export-number font-weight-normal">{{
                        exportSummary.optOuts.success.toLocaleString()
                      }}</span>
                      <span class="export-text font-weight-normal">
                        successful</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BCard>

          <BCard
            class="no-item-container d-flex justify-content-center align-items-center h-100 m-0"
            no-body
            v-else
          >
            <div class="row my-5">
              <div class="col-md-4 col-sm-12 d-flex justify-content-end">
                <img
                  class="mr-2"
                  alt=""
                  src="/images/icon_download.svg"
                  width="74"
                  height="64"
                />
              </div>
              <div class="col-md-8 col-sm-12">
                <div class="row">
                  <h2 class="h4 has-empty-state-header">Exports</h2>
                </div>
                <div class="row mr-1">
                  <span class="has-empty-state-text">
                    Send message activity & opt-out status changes in real-time
                  </span>
                </div>
                <div class="row mt-2">
                  <a href="#/integrations/recruit/exports/settings">
                    Configure Exports&nbsp;»
                  </a>
                </div>
              </div>
            </div>
          </BCard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BCard } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";
import { formatDate } from "@/util/dates";

export default {
  name: "RecruitDashboardTab",
  components: {
    BCard,
    MgIcon,
  },
  props: {
    exportErrors: {
      type: Object,
      required: true,
    },
    exportSummary: {
      type: Object,
      required: true,
    },
    imports: {
      type: Object,
      required: true,
    },
    isImportConfigured: {
      type: Boolean,
      required: true,
    },
    isExportConfigured: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    topImports() {
      return this.imports.items.slice(0, 3);
    },
  },
  methods: {
    formatDate(date) {
      return formatDate(date, "MM/DD/YY, hh:mm A");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";
@import "src/scss/variables";

.panel-row {
  padding: 0 30px;
}

.empty-message-text {
  font-size: 1.09375rem;
  font-style: italic;
  margin: 10px 0;
}

.card-imports {
  @include media-breakpoint-up(sm) {
    padding: 0 15px 0 0;
  }
  @include media-breakpoint-down(sm) {
    padding: 0 0 15px 0;
  }
}

.card-exports {
  @include media-breakpoint-up(sm) {
    padding: 0 0 0 15px;
  }
  @include media-breakpoint-down(sm) {
    padding: 15px 0 0 0;
  }
}

.export-total-container {
  max-width: unset;
}

.export-number {
  font-size: 17.5px;
}

.export-text {
  font-size: 14px;
}

.recruit-import-row {
  background-color: $gray-light-bg;
  border-radius: 3px;
  font-weight: 300;
}
.recruit-import-row:hover {
  background-color: darken($table-hover-bg, 1%);
}

.container {
  color: $gray;
  background-color: $gray-light-bg;
  padding: 12px 15px;
}

.container:hover {
  background-color: darken($table-hover-bg, 1%);
}

.card-inner {
  min-height: 320px;

  .card-header-label {
    font-size: 17.5px;
  }
}

.has-empty-state-text {
  font-size: $font-size-lg;
  font-weight: $font-weight-light;
}

.has-empty-state-header {
  font-weight: $font-weight-normal;
  font-size: $font-size-lg;
}

.no-item-container {
  background-color: $gray-light-bg;
}
</style>
