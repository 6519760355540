<template>
  <div class="btn-group">
    <button
      type="button"
      class="btn btn-sm btn-light"
      @click="refresh"
      :disabled="!hasUpdates"
    >
      ↻
    </button>
    <button type="button" class="btn btn-sm btn-light" @click="toggleLive">
      {{ isLive ? "ii" : "&gt;" }}
    </button>
  </div>
</template>

<script>
import store from "@/store";
import { ACTIONS } from "@/util/constants";

export default {
  name: "InboxControls",
  store,
  computed: {
    hasUpdates() {
      return this.$store.state.inbox.inboxStatus.hasUpdates;
    },
    isLive() {
      return this.$store.state.inbox.inboxStatus.isLive;
    },
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
    toggleLive() {
      if (this.isLive) {
        this.$store.dispatch(ACTIONS.PAUSE_LIVE_INBOX);
      } else {
        this.$store.dispatch(ACTIONS.RESUME_LIVE_INBOX);
        this.refresh();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
</style>
