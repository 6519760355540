<template>
  <div class="filterList">
    <BInputGroup class="mb-2 p-0">
      <template v-slot:prepend>
        <MgIcon
          class="d-flex align-self-center search-icon"
          name="search-regular"
          scale=".75"
        />
      </template>
      <BFormInput
        class="search-input p-0"
        type="search"
        placeholder="Search Teams"
        autofocus
        v-model="search"
      >
      </BFormInput>
    </BInputGroup>
    <div class="team-list">
      <BFormCheckbox
        :class="{ active: selectAll }"
        v-model="selectAll"
        class="selectAll"
        @change="toggleSelectAll()"
        >Select All</BFormCheckbox
      >
      <BFormCheckbox
        v-for="item in filterItems"
        v-model="checked"
        :value="item.id"
        :key="item.id"
        :class="{ active: checked.includes(item.id) }"
        class="teamCheckbox"
        ><span v-html="highlightText(item.name)"></span
      ></BFormCheckbox>
    </div>
  </div>
</template>

<script>
import { BInputGroup, BFormCheckbox, BFormInput } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";

export default {
  name: "MetricsFilterList",
  props: {
    listItems: {
      type: Array,
      required: true,
    },
    checkedItems: {
      type: Array,
      required: true,
    },
  },
  components: {
    BInputGroup,
    BFormInput,
    BFormCheckbox,
    MgIcon,
  },
  data() {
    return {
      search: "",
      checked: this.checkedItems,
      selectAll: false,
    };
  },
  created() {
    this.updateSelectAll();
  },
  watch: {
    checked() {
      this.$emit("item-checked", this.checked);
      this.updateSelectAll();
    },
  },
  computed: {
    filterItems() {
      return this.listItems.filter((item) => {
        return item.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    highlightText(matchedName) {
      if (this.search === "") {
        return matchedName;
      }
      const re = new RegExp(this.search, "gi");
      return matchedName.replace(re, `<strong>$&</strong>`);
    },
    toggleSelectAll() {
      this.checked = [];
      if (this.selectAll) {
        this.listItems.forEach((item) => {
          this.checked.push(item.id);
        });
      }
    },
    updateSelectAll() {
      this.selectAll = this.listItems.length === this.checked.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
.search-icon {
  padding: 0 8px 0 16px;
  ::v-deep svg {
    width: 15px;
  }
}
.search-input {
  font-size: 14px;
  border: none;
  padding: 12px 0;
}
.input-group {
  border-bottom: 1px solid $input-border;
  .input-group-prepend {
    background-color: #fff;
  }
}
.filterList {
  ::v-deep .custom-control {
    padding: 6px 0 6px 42px;
  }
  ::v-deep .custom-control.active,
  ::v-deep .custom-control:hover {
    background: #e5e5e5;
  }
  ::v-deep .custom-control-label {
    text-transform: none;
    font-weight: 400;
    font-size: 13px;
  }
  ::v-deep .custom-control-label::before,
  ::v-deep .custom-control-label::after {
    top: 0rem;
  }
  ::v-deep .team-list {
    height: 250px;
    overflow-y: auto;
  }
}
</style>
