import { MUTATIONS } from "@/util/constants";

export default {
  [MUTATIONS.SET_FOLLOW_UPS_TOTALS](state, totalFollowUps) {
    state.totalFollowUps = totalFollowUps;
  },
  [MUTATIONS.SET_OTHER_CONVERSATIONS_TOTALS](state, totalOtherConversations) {
    state.totalOtherConversations = totalOtherConversations;
  },
  [MUTATIONS.SET_WEBCHAT_CONVERSATIONS](state, conversations) {
    state.conversations = conversations;
  },
  [MUTATIONS.SET_SELECTED_WEBCHAT_CONVERSATION](state, conversation) {
    state.selectedConversation = conversation;
  },
  [MUTATIONS.SET_SELECTED_WEBCHAT_MESSAGES](state, messages) {
    state.selectedConversationMessages = messages;
  },
  [MUTATIONS.SET_SIGNALR_WEBCHAT_CONNECTION](state, connection) {
    state.signalRConnection = connection;
  },
  [MUTATIONS.SET_WEBCHAT_BOT](state, bot) {
    state.bot = bot;
  },
  [MUTATIONS.SET_WEBCHAT_UNREAD_MY_CONVERSATIONS_COUNT](
    state,
    unreadMyConversationsCount
  ) {
    state.unreadMyConversationsCount = unreadMyConversationsCount;
  },
  [MUTATIONS.SET_WEBCHAT_UNREAD_UNASSIGNED_CONVERSATIONS_COUNT](
    state,
    unreadUnassignedConversationsCount
  ) {
    state.unreadUnassignedConversationsCount = unreadUnassignedConversationsCount;
  },
  [MUTATIONS.SET_WEBCHAT_VISITOR_AVATAR_COLOR_INDEX](state, index) {
    state.visitorAvatarColorIndex = index;
  },
  [MUTATIONS.SET_WEBCHAT_HEARTBEAT](state, heartbeat) {
    state.signalRHeartbeat = heartbeat;
  },
  [MUTATIONS.SET_SIGNALR_CONNECTION_RETRY](state, connectionRetry) {
    state.signalRConnectionRetry = connectionRetry;
  },
  [MUTATIONS.SET_AVAILABLE_TO_CHAT](state, available) {
    state.availableToChat = available;
  },
  [MUTATIONS.SET_WEBCHAT_FILTERS](state, filters) {
    state.filters = filters;
  },
  [MUTATIONS.SET_WEBCHAT_SHOW_MOBILE_CONVERSATION_VIEW](
    state,
    showMobileConversationView
  ) {
    state.showMobileConversationView = showMobileConversationView;
  },
  [MUTATIONS.SET_WEBCHAT_TYPING_TEXT](state, typingText) {
    state.typingText = typingText;
  },
  [MUTATIONS.SET_WEBCHAT_TYPERS](state, typers) {
    state.typers = typers;
  },
  [MUTATIONS.SET_WEBCHAT_INCOMING_TYPING_TIMER](state, timer) {
    state.incomingTypingTimer = timer;
  },
  [MUTATIONS.SET_WEBCHAT_OUTGOING_TYPING_TIMER](state, timer) {
    state.outgoingTypingTimer = timer;
  },
  [MUTATIONS.SET_WEBCHAT_TEMPLATES](state, list) {
    state.templateList = list;
  },
};
