<template>
  <div
    class="conversation-list-component d-flex flex-column"
    :class="{
      'contact-card-open': showContactCard,
      'conversation-selected': selectedConversationId,
    }"
  >
    <div>
      <ConversationSearch v-on:search="getConversations"></ConversationSearch>
      <b-form-tags
        v-if="filterTags.length > 0"
        v-model="filterTags"
        no-outer-focus
        class="filter-tags"
        size="lg"
      >
        <template v-slot="{ tags }">
          <b-form-tag
            v-for="tag in tags"
            @remove="removeFilterTag(tag)"
            :key="tag"
            :title="tag"
            variant="blue"
            class="mr-1"
            >{{ tag }}</b-form-tag
          >
        </template>
      </b-form-tags>
      <ResponsiveContainer :breakpoints="{ longLabel: 370, noLabel: 325 }">
        <template slot-scope="container">
          <div
            class="conversation-list-toolbar d-flex flex-row align-items-center justify-content-between"
          >
            <div class="d-flex align-items-center">
              <BFormCheckbox v-model="allSelected">
                <span class="sr-only">Select All Conversations</span>
              </BFormCheckbox>
              <b-dropdown
                right
                text="Actions"
                variant="link"
                :disabled="!selected.length"
                class="actions"
              >
                <b-dropdown-item v-if="isInboxView" @click="archive(true)"
                  >Archive</b-dropdown-item
                >
                <b-dropdown-item v-else @click="archive(false)"
                  >Move to Inbox</b-dropdown-item
                >
                <b-dropdown-item v-if="isInboxView" @click="markAsRead(true)"
                  >Mark as Read</b-dropdown-item
                >
                <b-dropdown-item v-else @click="markAsRead(true)" disabled
                  >Mark as Read</b-dropdown-item
                >
                <b-dropdown-item v-if="isInboxView" @click="markAsRead(false)"
                  >Mark as Unread</b-dropdown-item
                >
                <b-dropdown-item v-else @click="markAsRead(false)" disabled
                  >Mark as Unread</b-dropdown-item
                >
                <b-dropdown-item @click="reply">Reply</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="optOut">Opt-out</b-dropdown-item>
              </b-dropdown>
            </div>
            <InboxControls
              v-if="showInboxControls"
              v-on:refresh="getConversations"
            />
            <div class="d-flex">
              <div class="d-flex">
                <div id="filter-wrapper">
                  <b-dropdown
                    text="Filters"
                    variant="link"
                    class="filter mr-n2"
                    id="filter-dropdown"
                    ref="filterDropdown"
                    :disabled="isNotSharedInbox && isArchivedView"
                  >
                    <b-dropdown-group>
                      <b-dropdown-item
                        @click="setFilter('unread')"
                        :disabled="isArchivedView"
                        :class="{
                          active: conversationFilter.readStatus === 'unread',
                        }"
                      >
                        Unread
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="setFilter('read')"
                        :disabled="isArchivedView"
                        :class="{
                          active: conversationFilter.readStatus === 'read',
                        }"
                      >
                        Read
                      </b-dropdown-item>
                    </b-dropdown-group>
                    <b-dropdown-divider
                      v-if="showMyContactsFilter"
                    ></b-dropdown-divider>
                    <b-dropdown-group v-if="showMyContactsFilter">
                      <b-dropdown-item
                        @click="setFilter('assigned to me', userId)"
                        :class="{
                          active: isAssignedTo === 'me',
                        }"
                      >
                        Assigned to me
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="showUserSearch()"
                        :class="{
                          active: isAssignedTo === 'user',
                        }"
                      >
                        Assigned to...
                      </b-dropdown-item>
                    </b-dropdown-group>
                  </b-dropdown>
                  <b-dropdown
                    ref="userSearchDropdown"
                    id="user-search-dropdown"
                  >
                    <b-dropdown-form @submit.stop>
                      <div id="userSearchLabel">Assigned to</div>
                      <UserSearch v-on:user-selected="userSelectedHandler" />
                    </b-dropdown-form>
                  </b-dropdown>
                </div>
                <div
                  class="dropdown-label"
                  v-if="container.size > container.breakpoints.noLabel"
                >
                  Sort
                  <span v-if="container.size > container.breakpoints.longLabel"
                    >by</span
                  >:
                </div>
              </div>
              <b-dropdown right variant="link" class="sort pl-0">
                <template v-slot:button-content
                  >{{ sortLabel
                  }}<span
                    v-if="container.size > container.breakpoints.longLabel"
                    >est</span
                  ></template
                >
                <b-dropdown-item
                  @click="setSort('desc')"
                  :active="conversationSort === 'desc'"
                  >New<span
                    v-if="container.size > container.breakpoints.longLabel"
                    >est</span
                  ></b-dropdown-item
                >
                <b-dropdown-item
                  @click="setSort('asc')"
                  :active="conversationSort === 'asc'"
                  >Old<span
                    v-if="container.size > container.breakpoints.longLabel"
                    >est</span
                  ></b-dropdown-item
                >
              </b-dropdown>
            </div>
            <b-modal
              id="optOut-confirm"
              title="Opt-out Contacts"
              title-tag="h3"
              @close="optOutCancelled()"
            >
              <h4>
                Are you sure you want to opt-out
                {{ selected.length > 1 ? "these contacts" : "this contact" }}?
              </h4>
              <p>
                Opt-out prevents contacts from receiving messages from this
                team.
              </p>
              <template v-slot:modal-footer>
                <button @click="optOutConfirmed()" class="btn btn-primary">
                  Opt-out
                </button>
                <button @click="optOutCancelled()" class="btn btn-light">
                  Cancel
                </button>
              </template>
            </b-modal>
          </div>
        </template>
      </ResponsiveContainer>
      <div
        v-show="queueCount"
        :class="{ 'collapsed-view': showContactCard }"
        class="queue"
      >
        <a href @click.prevent="loadQueue()">
          <MgIcon name="sync" scale=".75"></MgIcon>
          {{ queueCount > 20 ? "20+" : "" + queueCount }}
          <span class="load-message-text">Load new message</span
          >{{ queueCount !== 1 && !showContactCard ? "s" : "" }}
        </a>
      </div>
    </div>
    <transition name="fade">
      <ul
        v-if="conversations.length"
        id="conversation-list"
        class="conversation-list"
        @scroll.passive="onScroll"
      >
        <transition-group name="slide-fade">
          <li
            class="conversations-item d-flex align-items-center"
            tabindex="0"
            v-for="item in conversations"
            :key="item.id"
            :id="item.id"
            :class="{
              active: item.id === selectedConversationId,
              unread: item.unread,
              removed: item.removed,
            }"
            @click="selectConversation(item.id)"
            v-on:keyup.enter="selectConversation(item.id)"
          >
            <div class="" @click.stop>
              <BFormCheckbox
                v-model="selected"
                :disabled="lockedByOtherUser(item.state)"
                :value="item.id"
              >
                <span class="sr-only"
                  >Select {{ item.contact }} Conversation</span
                >
              </BFormCheckbox>
            </div>
            <div class="message-wrap flex-grow-1">
              <div class="d-flex mt-1 mb-1">
                <div
                  class="contact-name font-weight-bold text-truncate flex-grow-1"
                >
                  {{ item.contact }}
                </div>
                <div class="contact-initials font-weight-bold flex-grow-1">
                  {{ contactInitials(item.contact) }}
                </div>
                <small
                  class="date text-nowrap"
                  v-text="getDate(item.date)"
                ></small>
              </div>
              <div
                v-if="
                  item.state &&
                  item.state.locked &&
                  getUserNameById(item.state.lockedForStaffId)
                "
                class="message-locked"
              >
                <i class="fa fa-commenting-o lock-icon"></i>
                <div class="is-responding">
                  <i class="fa fa-commenting-o"></i>&nbsp;{{
                    getUserNameById(item.state.lockedForStaffId)
                  }}
                  is responding&hellip;
                </div>
              </div>
              <div v-else class="message-preview d-flex">
                <div
                  v-if="item.media && item.media.length"
                  class="d-flex align-items-center"
                >
                  <i class="fa fa-picture-o"></i>&ensp;<em
                    class="text-muted text-light"
                    >Multimedia&ensp;</em
                  >
                </div>
                <div
                  class="flex-grow-1 text-truncate"
                  :inner-html.prop="item.message | twemoji"
                ></div>
                <div
                  v-if="item.error || item.cancelled"
                  class="message-error"
                  v-b-tooltip.hover.left="{
                    title: item.error || 'Cancelled prior to delivery',
                  }"
                >
                  <MgIcon name="exclamation-circle"></MgIcon>
                </div>
              </div>
            </div>
          </li>
        </transition-group>
        <div v-if="loadNextConversations" class="d-flex justify-content-center">
          <b-spinner type="grow" label="Loading..."></b-spinner>
        </div>
      </ul>
    </transition>
    <NoResultsMessage></NoResultsMessage>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import api from "@/util/api";
import * as firestore from "@/util/firestore";
import {
  ACTIONS,
  CONVERSATION_FILTERS,
  GETTERS,
  INBOX_TYPES,
} from "@/util/constants";
import "@/util/filters";
import _ from "lodash";
import moment from "moment";
import "moment-timezone";
import {
  BFormCheckbox,
  DropdownPlugin,
  ModalPlugin,
  SpinnerPlugin,
} from "bootstrap-vue";
import InboxControls from "@/components/InboxControls";
import MgIcon from "@/components/MgIcon";
import analytics from "@/util/analytics";
import ConversationSearch from "./ConversationSearch";
import NoResultsMessage from "./NoResultsMessage";
import ResponsiveContainer from "@/components/ResponsiveContainer";
import UserSearch from "@/components/UserSearch";

Vue.use(DropdownPlugin);
Vue.use(ModalPlugin);
Vue.use(SpinnerPlugin);

export default {
  name: "ConversationList",
  store,
  props: {
    isInboxView: {
      required: true,
    },
  },
  components: {
    BFormCheckbox,
    InboxControls,
    MgIcon,
    ConversationSearch,
    NoResultsMessage,
    ResponsiveContainer,
    UserSearch,
  },
  computed: {
    activeInboxType() {
      return this.$store.state.inbox.activeInbox.type;
    },
    conversation() {
      return this.$store.state.inbox.selectedConversation;
    },
    conversations() {
      return this.$store.getters[GETTERS.SORTED_CONVERSATIONS];
    },
    activeFilter() {
      return this.$store.state.inbox.activeFilter;
    },
    conversationFilter() {
      return this.activeFilter.conversationFilter;
    },
    conversationSearch() {
      return this.activeFilter.conversationSearch;
    },
    conversationSort() {
      return this.activeFilter.conversationSort;
    },
    getUserNameById() {
      return (id) => this.$store.getters[GETTERS.USER_NAME_BY_ID](id);
    },
    queueCount() {
      return this.$store.state.inbox.conversationQueue.length;
    },
    loadNextConversations() {
      return this.$store.state.inbox.loadNextConversations;
    },
    selectedConversationId() {
      return this.$store.state.inbox.selectedConversationId;
    },
    showContactCard() {
      return (
        this.$store.state.showContactCard &&
        this.$store.state.inbox.selectedConversationId
      );
    },
    showInboxControls() {
      return (
        this.$store.state.inbox.activeInbox.type === INBOX_TYPES.HIGH_SPEED &&
        this.$store.getters[GETTERS.FEATURE_ENABLED]("inbox-controls")
      );
    },
    showMyContactsFilter() {
      return this.$store.state.inbox.activeInbox.type === INBOX_TYPES.GROUP;
    },
    sortLabel() {
      const labels = {
        desc: "New",
        asc: "Old",
      };
      return labels[this.conversationSort];
    },
    timeZone() {
      return this.$store.state.config.timeZone;
    },
    userId() {
      return this.$store.state.config.userId;
    },
    filterTags() {
      let filterArr = [];
      if (this.conversationFilter.readStatus !== "all") {
        filterArr.push(this.conversationFilter.readStatus);
      }
      if (this.conversationFilter.assignedTo) {
        if (this.userId === this.conversationFilter.assignedTo) {
          filterArr.push("assigned to me");
        } else {
          let userDisplayName =
            this.getUserNameById(this.conversationFilter.assignedTo) !== ""
              ? this.getUserNameById(this.conversationFilter.assignedTo)
              : "user";
          filterArr.push("assigned to " + userDisplayName);
        }
      }
      return filterArr;
    },
    isNotSharedInbox() {
      return this.$store.state.inbox.activeInbox.type !== INBOX_TYPES.GROUP;
    },
    isArchivedView() {
      return this.$store.state.inbox.isArchivedView;
    },
    isAssignedTo() {
      if (
        this.conversationFilter.assignedTo &&
        this.conversationFilter.assignedTo === this.userId
      ) {
        return "me";
      } else if (
        this.conversationFilter.assignedTo &&
        this.conversationFilter.assignedTo !== this.userId
      ) {
        return "user";
      } else {
        return "unselected";
      }
    },
  },
  created() {
    this.$store.dispatch(ACTIONS.SHUFFLE_EMPTY_INBOX_MESSAGES);
    this.$store.dispatch(ACTIONS.UPDATE_ACTIVE_INBOX_SEARCH, null);
    this.$store.dispatch(ACTIONS.UPDATE_INBOX_VIEW, this.isInboxView);
    if (!this.isInboxView) {
      this.$store.dispatch(
        ACTIONS.UPDATE_ACTIVE_INBOX_FILTER,
        CONVERSATION_FILTERS.ALL
      );
    }
    if (this.$store.state.inbox.activeInbox.type === INBOX_TYPES.HIGH_SPEED) {
      this.getConversations();
      this.$store.dispatch(ACTIONS.GET_CREDIT_BALANCE);
    } else {
      firestore.onAuth().then(this.getConversations);
    }
  },
  data() {
    return {
      conversationActions: [],
      initialLoad: true,
      selected: [],
      allSelected: false,
      conversationList: null,
      desktopFirst: false,
      assignedTo: null,
    };
  },
  destroyed() {
    this.$store.dispatch(ACTIONS.RESET_CONVERSATIONS);
  },
  methods: {
    archive(value) {
      this.$store.dispatch(ACTIONS.ARCHIVE_CONVERSATION, [
        value,
        this.selected,
      ]);
      if (
        this.selected.includes(this.$store.state.inbox.selectedConversationId)
      ) {
        this.$store.dispatch(ACTIONS.RESET_CONVERSATION);
      }
      analytics.track((value ? "Archived" : "Unarchived") + " Messages", {
        "Message Count": this.selected.length,
        "Inbox Name": this.$store.state.inbox.activeInbox.name,
        "User Id": this.userId,
        "Contact Id": this.selected,
        Caller: "Conversation Menu View",
      });
      this.resetBulkSelection();
    },
    contactInitials(contact) {
      if (!contact) return;

      if (
        contact.startsWith("(") &&
        Number.isInteger(parseInt(contact[contact.length - 1]))
      ) {
        return "#";
      } else {
        let contactName = contact.split(" ");
        return (
          contactName[0].charAt(0) +
          contactName[contactName.length - 1].charAt(0)
        );
      }
    },
    getConversations() {
      if (!this.initialLoad) {
        this.$store.dispatch(ACTIONS.RESET_CONVERSATION);
      }
      this.initialLoad = false;
      this.$store.dispatch(ACTIONS.RESET_CONVERSATIONS);
      this.$store.dispatch(ACTIONS.BIND_CONVERSATIONS);
    },
    getDate(messageDate) {
      let today = new Date(),
        date = moment.tz(moment.utc(messageDate), this.timeZone);

      if (date.isSame(today, "day")) {
        return date.format("h:mm A");
      } else if (date.isSame(today, "year")) {
        return date.format("MMM D");
      } else {
        return date.format("MM/DD/YY");
      }
    },
    getNext: _.debounce(function () {
      let lastConversation = this.conversations[this.conversations.length - 1]
        .date;
      this.$store.dispatch(ACTIONS.BIND_NEXT_CONVERSATIONS, lastConversation);
    }, 200),
    loadQueue() {
      this.$store.dispatch(ACTIONS.LOAD_CONVERSATION_QUEUE);
    },
    markAsRead(value) {
      this.$store.dispatch(ACTIONS.MARK_AS_READ_CONVERSATION, [
        value,
        this.selected,
      ]);
      if (!value) {
        this.$store.dispatch(
          ACTIONS.UPDATE_SELECTED_CONVERSATION_MANUALLY_UNREAD,
          true
        );
      }
      analytics.track("Marked as " + (value ? "Read" : "Unread"), {
        "Message Count": this.selected.length,
        "Inbox Name": this.$store.state.inbox.activeInbox.name,
        "User Id": this.userId,
        "Contact Id": this.selected,
        Caller: "Conversation Menu View",
      });
      this.resetBulkSelection();
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      let scrollPosition = scrollTop + clientHeight;
      if (
        scrollPosition >= scrollHeight * 0.7 &&
        scrollPosition < scrollHeight * 0.95
      ) {
        this.getNext();
      }
    },
    optOut() {
      this.$bvModal.show("optOut-confirm");
    },
    optOutCancelled() {
      this.$bvModal.hide("optOut-confirm");
    },
    optOutConfirmed() {
      this.$bvModal.hide("optOut-confirm");
      api
        .post(`/inbox/${this.userId}/optout`, {
          studentIds: this.selected,
          departmentId: this.$store.state.config.team.id,
          optedOut: true,
        })
        .then(() => {
          analytics.track("Bulk Opt-out", {
            "Student Count": this.selected.length,
            "Inbox Name": this.$store.state.inbox.activeInbox.name,
            "User Id": this.userId,
            Caller: "Conversation Menu View",
          });
          this.resetBulkSelection();
        });
    },
    reply() {
      let contacts = this.selected.map((id) => {
        let item = this.conversations.find(
            (conversation) => conversation.id === id
          ),
          name = item.contact;

        if (!name) return;

        return {
          id: id,
          tagText: name,
        };
      });
      this.$emit("bulk-reply", contacts);
      analytics.track("Clicked Reply from Inbox", {
        "Message Count": contacts.length,
        "Inbox Name": this.$store.state.inbox.activeInbox.name,
        "User Id": this.userId,
        "Contact Id": this.selected,
        Caller: "Conversation Menu View",
      });
      this.resetBulkSelection();
    },
    resetBulkSelection() {
      this.selected = [];
      this.conversationActions = [];
      this.allSelected = false;
    },
    selectConversation(conversationId) {
      let activeId =
        conversationId !== this.selectedConversationId ? conversationId : null;
      this.$store.dispatch(ACTIONS.SELECT_CONVERSATION, activeId);
    },
    setFilter(value, id, remove) {
      let filterUserId = id ? id : "";
      this.$store.dispatch(ACTIONS.UPDATE_ACTIVE_INBOX_FILTER, {
        value,
        filterUserId,
      });
      if (!remove) {
        analytics.track("Assigned Filter", {
          "Filter Value": value,
          "Filtered User Id": filterUserId,
          "Inbox Type": this.$store.state.inbox.activeInbox.type,
          "Inbox Name": this.$store.state.inbox.activeInbox.name,
        });
      }
      this.$refs.filterDropdown.hide(true);
      this.getConversations();
    },
    setSort(value) {
      this.$store.dispatch(ACTIONS.UPDATE_ACTIVE_INBOX_SORT, value);
      this.getConversations();
    },
    lockedByOtherUser(conversationState) {
      if (!conversationState) {
        return false;
      }
      return (
        conversationState.locked &&
        conversationState.lockedForStaffId !== this.userId
      );
    },
    toggleAll() {
      this.selected = [];
      if (this.allSelected) {
        for (let item in this.conversations) {
          if (!this.lockedByOtherUser(this.conversations[item].state)) {
            this.selected.push(this.conversations[item].id);
          }
        }
      }
    },
    removeFilterTag(tag) {
      let filterObject = {};
      if (tag.includes("assigned to")) {
        if (tag.indexOf(" me", tag.length - " me".length) !== -1) {
          this.setFilter(tag, this.userId, true);
          filterObject = {
            "Filter Value": tag,
            "Filtered User Id": this.getUserNameById(this.assignedTo),
            "Inbox Type": this.$store.state.inbox.activeInbox.type,
            "Inbox Name": this.$store.state.inbox.activeInbox.name,
          };
        } else {
          this.setFilter("assigned to", null, true);
          filterObject = {
            "Filter Value": tag,
            "Filtered User Id": this.getUserNameById(this.userId),
            "Inbox Type": this.$store.state.inbox.activeInbox.type,
            "Inbox Name": this.$store.state.inbox.activeInbox.name,
          };
        }
      } else {
        this.setFilter(tag);
        filterObject = {
          "Filter Value": tag,
          "Filtered User Id": "",
          "Inbox Type": this.$store.state.inbox.activeInbox.type,
          "Inbox Name": this.$store.state.inbox.activeInbox.name,
        };
      }
      analytics.track("Removed Filter", filterObject);
    },
    showUserSearch() {
      this.$refs.filterDropdown.hide(true);
      this.$refs.userSearchDropdown.show();
    },
    userSelectedHandler(user) {
      this.assignedTo = user.id;
      this.setFilter("assigned to", this.assignedTo);
      this.$refs.userSearchDropdown.hide(true);
    },
  },
  watch: {
    allSelected() {
      this.toggleAll();
    },
    isInboxView() {
      this.$store.dispatch(ACTIONS.UPDATE_INBOX_VIEW, this.isInboxView);
      if (!this.isInboxView) {
        this.$store.dispatch(
          ACTIONS.UPDATE_ACTIVE_INBOX_FILTER,
          CONVERSATION_FILTERS.ALL
        );
      }
      this.resetBulkSelection();
      this.getConversations();
    },
    selectedConversationId(id) {
      this.$emit("select-conversation", id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/scss/utilities";

.pagination {
  display: flex;
  height: 100px;
  justify-content: space-between;
  align-items: center;
}

.conversation-list-component {
  border-right: 1px solid #e2e2e2;
  max-width: none;
  min-width: auto;
  width: 100%;
  @media (min-width: $screen-md) {
    max-width: 400px;
    min-width: 310px;
    width: 30%;
  }
  &.conversation-selected {
    display: none !important;
    @media (min-width: $screen-md) {
      display: flex !important;
    }
  }
}

.conversation-list-toolbar {
  background: #fff;
  border-bottom: 1px solid #e2e2e2;
  padding: 5px 15px;
}

.queue {
  background: #fafafa;
  font-size: 0.9rem;
  text-align: center;
  text-transform: uppercase;

  a {
    display: block;
    padding: 5px 10px;
  }
}

.actions ::v-deep .dropdown-toggle {
  padding-left: 0;
}

::v-deep .dropdown-toggle {
  font-size: 13px;
}

.sort ::v-deep .dropdown-toggle {
  padding-left: 5px;
  padding-right: 0;
}

.sort ::v-deep .filter {
  margin-right: 10px;
}

.conversation-list {
  list-style: none;
  margin: 0 0 -12px;
  overflow: auto;
  padding: 0 0 12px;
}

.conversations-item {
  background-color: $light;
  border-bottom: 1px solid #e2e2e2;
  border-left: 5px solid transparent;
  color: #737373;
  cursor: pointer;
  height: 71px;
  padding: 10px 15px 10px 10px;
  transition: all 0.2s ease;

  &.active {
    background-color: #fff;
    border-left-color: theme-color("accent");
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    position: relative;
  }

  &.unread {
    background-color: #fff;
    color: #444;
    font-weight: $font-weight-bold;

    small {
      font-weight: $font-weight-bold;
    }
  }

  &.slide-fade-enter-active {
    opacity: 0;
  }

  &.slide-fade-leave-to,
  &.removed {
    border-bottom: 0;
    box-shadow: none;
    height: 0;
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.custom-checkbox {
  padding-right: 0.35rem;
}

.message-wrap {
  min-width: 0;
}

.message-preview {
  font-size: 13px;
  line-height: 1.4;
  margin-bottom: 3px;
  .message-error {
    color: #f00;
    cursor: pointer;
    margin-left: 5px;
  }

  ::v-deep img.emoji {
    height: 20px;
    margin: -2px 0 0;
    width: auto;
    vertical-align: middle;
  }

  ::v-deep img.icon {
    margin-bottom: 1px;
  }
}

.message-locked {
  font-size: 13px;
  line-height: 1.4;
  font-style: italic;
  margin-bottom: 3px;
}

.lock-icon {
  display: none;
}

.contact-initials {
  display: none;
}

.contact-card-open {
  @media (min-width: $screen-md) and (max-width: $screen-lg) {
    &.conversation-list-component {
      width: 90px;
      max-width: none;
      min-width: 90px;
    }
    .conversation-list-toolbar {
      display: none !important;
    }
    .custom-checkbox,
    .message-preview,
    .date,
    .contact-name {
      display: none;
    }
    .contact-initials {
      display: flex;
      justify-content: center;
      position: relative;
    }
    .unread .contact-initials::before {
      content: "\2022";
      font-size: 20px;
      color: $gray-lighter;
      top: -15px;
      position: absolute;
      left: -5px;
    }
    .lock-icon {
      display: block;
      text-align: center;
    }
    .is-responding {
      display: none;
    }
  }
}
.collapsed-view {
  @media (min-width: $screen-md) and (max-width: $screen-lg) {
    .load-message-text {
      display: none;
    }
    &.queue {
      padding: 5x;
      font-size: 1rem;
    }
  }
}

.dropdown-label {
  padding: 0.375rem 0 0.375rem 0.5rem;
  text-transform: uppercase;
  color: #6c757d;
}

.custom-filter-item {
  margin: 1rem;
  display: flex;
  .btn {
    display: inline-flex;
    letter-spacing: 0;
    text-transform: capitalize;
    .icon {
      ::v-deep svg {
        height: auto;
        margin-right: 6px;
        margin-top: -2px;
      }
    }
  }
}

::v-deep .custom-filter-tooltip {
  .tooltip-inner {
    min-width: 240px;
  }
}

.filter-tags {
  border: none;
  background: $gray-light-bg;
  margin-top: -7px;
  padding: 0 15px 12px 15px;
}

.b-form-tag {
  border-radius: $border-radius-base;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-transform: capitalize;

  > ::v-deep button.b-form-tag-remove {
    position: relative;
    right: -1px;
    bottom: -1px;
  }
}

::v-deep .conversation-list-toolbar .dropdown-menu li.active {
  background: #e5e5e5;
  .dropdown-item {
    color: #555;
  }
}

#filter-wrapper {
  position: relative;
}

::v-deep #user-search-dropdown {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  button.dropdown-toggle {
    z-index: -1;
    position: absolute;
  }
}
::v-deep #user-search-dropdown.show {
  width: 300px;
  display: block !important;
}
::v-deep #user-search-dropdown.show .dropdown-menu {
  width: 300px;
}

#userSearchLabel {
  margin-bottom: 4px;
}
.b-dropdown-form {
  padding: 10px 15px 20px;
}
</style>
