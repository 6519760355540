<template>
  <BListGroupItem class="d-flex">
    <div class="container m-0 field-container flex-grow-1">
      <div class="row">
        <div class="col-sm-5 col-xs-12 pl-0 align-self-center">
          <div class="d-inline-flex">
            <div class="text-danger align-self-center mr-3" v-show="hasError">
              <MgIcon
                name="exclamation-triangle"
                scale="1.25"
                :aria-describedby="`${fieldId}-error-text`"
              />
            </div>
            <div class="flex-grow-1">
              <span v-if="hasCrmConstituentCustomFieldCategory(field)">
                {{ field.crmConstituentCustomFieldCategory }}
              </span>
              <span
                v-else-if="displayNameIsSet(field) || displayNameIsPhone(field)"
              >
                <span v-if="displayNameIsPhone(field) && standard">
                  {{ phoneConfiguration }}
                </span>
                <span v-else> {{ getSelectedFieldName(field) }}</span>
              </span>
              <span
                class="align-middle font-italic text-muted select-field"
                v-else
              >
                Select Field
              </span>
              <div
                class="text-danger"
                v-if="hasError && error.messages.length > 0"
                :id="`${fieldId}-error-text`"
                role="alert"
              >
                Edit {{ field.crmField.name }} to fix errors
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-1 align-self-center">
          <MgIcon name="arrow-right" class="ml-4 pt-2" />
        </div>
        <div class="col-sm-1"></div>
        <div class="col-sm-2 col-xs-12 align-self-center">
          <span v-if="standard">
            {{ displayName }}
          </span>
          <span v-else>
            {{ getFieldName(field) }}
          </span>
        </div>
        <div class="col-sm-3 col-xs-12 text-right pr-0 align-self-center">
          <slot />
        </div>
      </div>
    </div>
  </BListGroupItem>
</template>
<script>
import _ from "lodash";
import { BListGroupItem } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";

export default {
  name: "IntegrationsFieldListGroupItem",
  components: {
    BListGroupItem,
    MgIcon,
  },
  data() {
    return {
      hasError: false,
    };
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    standard: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayName: {
      type: String,
      required: false,
      default: "",
    },
    error: {
      type: Object,
      required: false,
      default: undefined,
    },
    fieldName: {
      type: String,
      required: false,
      default: "",
    },
    selectedPhoneTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    fieldId() {
      return `${this.getFieldName(this.field)}-${_.random(0, 99999)}`;
    },
    phoneConfiguration() {
      let displayText = "Phone Number (Constituent)";
      switch (this.selectedPhoneTypes.length) {
        case 1:
          displayText = this.selectedPhoneTypes[0] + " (Phone Type)";
          break;
        case 2:
          displayText = this.selectedPhoneTypes.join(" or ") + " (Phone Type)";
          break;
        case 3:
          displayText =
            this.selectedPhoneTypes[0] +
            ", " +
            this.selectedPhoneTypes[1] +
            " or " +
            this.selectedPhoneTypes[2] +
            " (Phone Type)";
          break;
      }
      return displayText;
    },
  },
  methods: {
    displayNameIsSet(field) {
      if (field.crmField) {
        return field.crmField.name !== "";
      }
      return !!field.smsLinkedCategory;
    },
    displayNameIsPhone(field) {
      if (
        (field.crmField && field.crmField.name === "Phone Number") ||
        this.selectedPhoneTypes.length > 0
      ) {
        return true;
      }
    },
    getFieldName(field) {
      if (field.customField) {
        return field.customField.displayName;
      }

      if (field.cadenceField) {
        return field.cadenceField.name;
      }

      return "";
    },
    hasCrmConstituentCustomFieldCategory(field) {
      if (field.crmConstituentCustomFieldCategory) {
        return field.crmConstituentCustomFieldCategory !== "";
      }
    },
    fieldHasError() {
      return this.hasError ? "field-error" : "";
    },
    getSelectedFieldName(field) {
      if (
        field.crmConstituentCustomFieldCategory &&
        field.crmConstituentCustomFieldCategory !== ""
      ) {
        return field.crmConstituentCustomFieldCategory;
      }

      if (this.fieldName !== "") {
        return `${this.fieldName}`;
      }
      return `${field.crmField.name} (${field.crmField.groupName})`;
    },

    generateErrorMessage() {
      if (this.error && this.error.messages.length > 1) {
        return `${this.error.messages[0].replace(".", "")} + ${
          this.error.messages.length - 1
        } more`;
      } else {
        return this.error.messages[0];
      }
    },
  },
  watch: {
    error() {
      // The prop doesn't seem entirely reactive?
      this.hasError = this.error !== undefined;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/scss/variables";

.field-error {
  border: 1px solid $error;
}

.field-container {
  max-width: unset;
}
</style>
