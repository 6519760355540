<template>
  <div id="text-type-filter" @click="togglePopover()">
    <button
      :id="popoverId"
      class="filter-dropdown btn py-0 d-flex align-items-center justify-content-between"
    >
      <div class="d-flex w-100">
        <MgIcon :name="icon"></MgIcon>
        <p class="filter-title">
          {{ filterText ? filterText : "Filter Text Type" }}
        </p>
      </div>
      <div class="dropdown-toggle"></div>
    </button>
    <div class="popover-container">
      <BPopover
        :target="popoverId"
        :placement="placement"
        triggers="click"
        :customClass="`checkbox-popover`"
        ref="popover"
        :show.sync="isPopoverOpen"
      >
        <template v-slot:title>
          <div>
            <TextTypeFilterList
              @item-checked="checkedTypes"
              :listItems="textType"
              :checkedItems="checkedItems"
            />
          </div>
        </template>
        <button
          @click="submitFilter()"
          type="button"
          class="btn btn-primary my-3"
        >
          Apply
        </button>
        <button @click="cancel()" type="button" class="btn btn-secondary my-3">
          Cancel
        </button>
      </BPopover>
    </div>
  </div>
</template>

<script>
import { BPopover } from "bootstrap-vue";
import MgIcon from "@/components/MgIcon";
import TextTypeFilterList from "./TextTypeFilterList.vue";
import { VBTooltip } from "bootstrap-vue";
import { ACTIONS } from "@/util/constants";
import Vue from "vue";
import store from "@/store";

Vue.directive("b-tooltip", VBTooltip);

export default {
  name: "TextTypeFilter",
  store,
  props: {
    textType: {
      type: Array,
      required: true,
    },
    iconText: {
      type: String,
      required: false,
    },
    icon: {
      type: String,
      required: false,
    },
    popoverId: {
      type: String,
      required: true,
    },
    placement: {
      type: String,
      required: false,
      default: () => {
        return "bottom";
      },
    },
    metricsType: {
      type: String,
      required: true,
    },
  },
  components: {
    MgIcon,
    TextTypeFilterList,
    BPopover,
  },
  data() {
    return {
      isPopoverOpen: false,
      sessionFilter: null,
      checkedItems: this.textType,
      filterText: "Filter Type",
    };
  },
  watch: {
    isPopoverOpen() {
      this.$emit("popover-state", this.isPopoverOpen);
    },
    textType() {
      if (
        (this.metricsType === "textAnalytics" &&
          this.$store.state.analytics.startDate !== "") ||
        this.$store.state.analytics.endDate !== ""
      ) {
        this.$store.dispatch(ACTIONS.GET_METRICS, this.hasMedia);
        this.$store.dispatch(ACTIONS.GET_ANALYTICS_BREAKDOWN, this.hasMedia);
      }
    },
    sessionFilter(newVal) {
      this.checkedItems = newVal;
      this.setFilterText();
      if (
        (this.metricsType === "textAnalytics" &&
          this.$store.state.analytics.startDate !== "") ||
        this.$store.state.analytics.endDate !== ""
      ) {
        this.$store.dispatch(ACTIONS.GET_METRICS, this.hasMedia);
        this.$store.dispatch(ACTIONS.GET_ANALYTICS_BREAKDOWN, this.hasMedia);
      }
    },
  },
  mounted() {
    this.filterText = this.textType.length > 1 ? "SMS & MMS" : this.textType[0];
    this.sessionFilter = JSON.parse(
      sessionStorage.getItem("analytics-text-type-filter")
    );
  },
  computed: {
    filter() {
      return this.$store.state.analytics.textType;
    },
    hasMedia() {
      return this.checkedItems.length > 1
        ? null
        : this.checkedItems.includes("MMS");
    },
  },
  methods: {
    onClose() {
      this.isPopoverOpen = false;
    },
    togglePopover() {
      this.$root.$emit("bv::hide::tooltip");
      this.isPopoverOpen = !this.isPopoverOpen;
    },
    checkedTypes(items) {
      this.checkedItems = items.length ? items : this.textType;
    },
    setFilterText() {
      if (this.checkedItems.length > 1) {
        this.filterText = "SMS & MMS";
      } else {
        this.filterText = this.checkedItems[0];
      }
    },
    submitFilter() {
      this.setFilterText();
      this.$root.$emit("bv::hide::popover");
      this.$emit("submit-filter", this.checkedItems);
    },
    cancel() {
      this.$root.$emit("bv::hide::tooltip");
      this.isPopoverOpen = !this.isPopoverOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/utilities";
#text-type-filter {
  @include media-breakpoint-down(md) {
    margin-right: 0px;
  }

  ::v-deep button {
    color: $text-color !important;
    background-color: #fff !important;
    border-color: $input-border !important;
    display: flex;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    span {
      position: relative;
      top: 6px;
    }
    input {
      border: none;
      height: auto;
      width: auto;
      display: inline;
      background: #fff;
      cursor: pointer;
    }
    .dropdown-toggle::after {
      font-size: 0.875rem;
      position: relative;
      top: 1px;
    }
    .dropdown-toggle::after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }
}

.popover-container {
  width: 200px;
}
.icon-container {
  cursor: pointer;
  align-self: center;
  padding-left: 8px;
  padding-right: 9px;
}
.input-group {
  border: 1px solid $input-border;
  border-radius: $border-radius-base;
  .input-group-prepend {
    background-color: #fff;
    border-radius: $border-radius-base;
  }
  input {
    border-radius: $border-radius-base;
  }
  &.active {
    border: 1px solid $input-focus-border-color;
  }
}
.is-invalid,
.is-valid {
  background-image: none;
}
.invalid-feedback {
  position: absolute;
  bottom: -20px;
  left: 10px;
}
.popover-button {
  background-color: transparent;
  border: none;
  color: $text-muted;
  cursor: pointer;
  height: 28px;
  width: 28px;
  line-height: 1;
  overflow: hidden;
  transition: 150ms ease-in-out;
  padding: 0;

  &:focus,
  &:hover {
    color: $text-color;
    transform: scale(1.1);
  }
}
.list-group {
  height: 200px;
  overflow-y: auto;
  margin: 0;
}
.filterItem {
  label {
    font-weight: 400;
    padding-left: 5px;
    margin: 0;
  }
}
.filter-title {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0px;
  display: flex;
  width: 182px;
}
.checkbox-popover.popover.b-popover {
  top: -9px !important;
  left: 1px !important;
  width: 235px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.18);

  &.bs-popover-bottom {
    ::v-deep .arrow {
      display: none !important;
    }
    .arrow:after {
      border-bottom-color: $popover-header-bg;
    }
  }

  ::v-deep .popover-header {
    margin-top: 0.5rem;
    border-bottom: none;
    padding: 0;
    .input-group {
      padding: 0.5rem 0.75rem;
    }
  }
  ::v-deep .btn.btn-secondary {
    background-color: #ffffff;
    border-color: #e0e0e0;
    color: #212529;
  }

  ::v-deep .popover-body {
    max-height: 275px;
    overflow: auto;
    padding: 0;
    width: auto;
    display: flex;
    justify-content: space-evenly;

    .list-group-item {
      border: none;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }
}
</style>
